import React, { useEffect, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { useTranslation } from '@hooks';
import { Label, LineChart } from '@components';

import NoDataBlock from '../../../Components/NoDataBlock';
import { TOTAL_ENTRY, UNIQUE_ENTRY } from '../constants';

const LineChartByTime = ({ dataForCongestion }) => {
    const t = useTranslation('HourlyCongestionStatus');

    const [chartData, setChartData] = useState({ datasets: [] });
    useEffect(() => {
        setChartData({
            labels: dataForCongestion.map(({ time }) => time),
            datasets: [
                {
                    label: t(TOTAL_ENTRY),
                    data: dataForCongestion.map(({ totalEntry }) => totalEntry),
                },
                {
                    label: t(UNIQUE_ENTRY),
                    data: dataForCongestion.map(({ uniqueEntry }) => uniqueEntry),
                },
            ],
        });
    }, [dataForCongestion, t]);

    return (
        <div className={'flx-col'}>
            <Label name={t('Hourly congestion status')} labelValueClassName={'pnt-txt txt-bold txt-dot'} />
            <div className={'h-100 bg-depth-2'}>
                {!!dataForCongestion && dataForCongestion.length ? (
                    <ReactResizeDetector refreshMode="debounce" refreshRate={100} handleHeight handleWidth>
                        <LineChart
                            data={chartData}
                            options={{
                                scales: {
                                    y: {
                                        ticks: { padding: 8 },
                                    },
                                },
                                datasets: {
                                    line: { tension: 0.4 },
                                },
                            }}
                            type={'area'}
                        />
                    </ReactResizeDetector>
                ) : (
                    <NoDataBlock />
                )}
            </div>
        </div>
    );
};

export default LineChartByTime;
