import React, { useContext, useRef } from 'react';
import WidgetCard from '../../Components/WidgetCard';
import OutpatientBloodDrawItemContainer from './OutpatientBloodDrawItemContainer';
import outpatientBloodDrawStatusSlice from './OutpatientBloodDrawStatusSlice';
import ContextProvider from '@components/ContextProvider';

export const OutpatientBloodDrawStatusStateContext = React.createContext();
export const OutpatientBloodDrawStatusDispatchContext = React.createContext();
export const useOutpatientBloodDrawStatusContext = () => useContext(OutpatientBloodDrawStatusStateContext);
export const useOutpatientBloodDrawStatusDispatch = () => useContext(OutpatientBloodDrawStatusDispatchContext);
const OutpatientBloodDrawStatus = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();

    return (
        <ContextProvider
            StateContext={OutpatientBloodDrawStatusStateContext}
            DispatchContext={OutpatientBloodDrawStatusDispatchContext}
            slice={outpatientBloodDrawStatusSlice}
        >
            <WidgetCard ref={widgetRef} widgetInfo={widgetInfo} {...restProps}>
                <OutpatientBloodDrawItemContainer widgetInfo={widgetInfo} />
                {children}
            </WidgetCard>
        </ContextProvider>
    );
};

export default OutpatientBloodDrawStatus;
