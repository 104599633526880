import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, TextInput } from '@components';
import { loginErrorMsg } from '@components/style';
import cx from 'classnames';
import { useAsync, useTranslation } from '@util/hooks';
import LoginTitle from './LoginTitle';
import { fetchApiLogin, fetchOAuthToken } from '@api/common/login';
import { setOauthInfo } from '@reducer/UserInfo';
import { useDispatch, useSelector } from 'react-redux';
import { initFindStep, setShowErrorMsg } from '../reducer';
import { LoginDispatchContext, LoginStateContext } from '../index';

import envKeys from '../../../../environment';
import { decodeInfo, encodeInfo } from '@util/common/util';
import useMoreUserInfo from '../hooks/useMoreUserInfo';
import { getApiURL } from '@api/index';

const { savedId } = envKeys;

const LoginForm = () => {
    const storeDispatch = useDispatch();
    const t = useTranslation('Login');
    const formRef = useRef();
    const { userInfo } = useSelector(state => state.UserInfo);
    const dispatch = useContext(LoginDispatchContext);
    const { showErrorMsg } = useContext(LoginStateContext);
    const [saveId, setSaveId] = useState(false);
    const [showPw, setShowPw] = useState(false);
    const [errorMessageKey, setErrorMessageKey] = useState(null);

    const { promise: initialLogin } = useAsync({
        promise: fetchApiLogin,
        resolve: res => {
            // 2. 로그인 인포가 보내지고 access token이 받아져왔을때
            if (res.scope) {
                storeDispatch(setOauthInfo(res));
                getMoreUserInfo();
            }
        },
        reject: () => {
            dispatch(setShowErrorMsg(true));
            setErrorMessageKey('Please check ID and Password');
        },
    });

    const { promise: oAuthLogin } = useAsync({
        promise: fetchOAuthToken,
        resolve: res => {
            // 2. 로그인 인포가 보내지고 access token이 받아져왔을때
            if (res.scope) {
                storeDispatch(setOauthInfo(res));
                getMoreUserInfo();
            }
        },
        reject: () => {
            dispatch(setShowErrorMsg(true));
            setErrorMessageKey('Please check ID and Password');
        },
    });

    const getMoreUserInfo = useMoreUserInfo();

    // 1. 로그인 버튼 클릭시 user가 입력한 ID와 Password와 함께 initialLogin 호출
    const handleLogin = e => {
        e.preventDefault();
        const form = formRef.current;

        if (!form.userID.value || !form.userPW.value) {
            dispatch(setShowErrorMsg(true));
            setErrorMessageKey('Please enter your ID and Password');
            return;
        }

        getApiURL().then(key => {
            if (key.hasOwnProperty('companyUUID') && key.hasOwnProperty('companyToken')) {
                initialLogin({ userID: form.userID.value, userPW: form.userPW.value });
            } else {
                oAuthLogin({ userID: form.userID.value, userPW: form.userPW.value });
            }
        });
    };

    const handleEnter = e => {
        if (e.keyCode === 13) {
            handleLogin(e);
        }
    };

    useEffect(() => {
        const lsSavedId = localStorage.getItem(savedId);
        if (lsSavedId) {
            formRef.current.userID.value = decodeInfo(lsSavedId);
            setSaveId(true);
        }
    }, []);

    useEffect(() => {
        if (userInfo && userInfo.companyInfo && userInfo.companyInfo.timeZone) {
            if (saveId) {
                localStorage.setItem(savedId, encodeInfo(formRef.current.userID.value));
            } else {
                localStorage.removeItem(savedId);
            }
        }
    }, [userInfo]);

    return (
        <>
            <LoginTitle title={t('Login')} subTitle={t('Please enter your ID and Password')} />
            <div className="cont-box">
                {/* 로그인 실패시 뜨는 에러 */}
                <div className="flx-col gap-3">
                    <div className={'cont--error'} style={!showErrorMsg ? loginErrorMsg : {}}>
                        <p>* {t(errorMessageKey)}</p>
                    </div>

                    <div className="cont--input flx-col flx-full">
                        <form className="flx-col" ref={formRef} onSubmit={handleLogin}>
                            {/* ID 입력창 */}
                            <TextInput
                                className={'w-100'}
                                type={'text'}
                                name={'userID'}
                                placeholder={t('ID')}
                                inputGroupClassName={'w-100'}
                            />

                            {/* Password 입력창 */}
                            <TextInput
                                className={'w-100'}
                                type={!showPw ? `password` : `text`}
                                name={'userPW'}
                                placeholder={t('Password')}
                                inputGroupClassName={'w-100 btn-on-right'}
                                handleKeyUp={handleEnter}
                                handleKeyDown={e => {
                                    if (e.keyCode === 13) {
                                        e.preventDefault();
                                    }
                                }}
                                // buttonClassName={'icon-filter-white'}
                                buttonClassName={'icon-filter-white opacity-6'}
                                buttonIconType={'outlined'}
                                buttonIcon={!showPw ? 'visibility_off' : 'visibility'}
                                buttonHandleClick={e => {
                                    e.preventDefault();
                                    setShowPw(!showPw);
                                }}
                            />
                        </form>
                    </div>
                    {/* 아이디 저장 */}
                    <label className={cx('pnt-checkbox', saveId && 'check-checked')} htmlFor="saveId">
                        <input type="checkbox" id="saveId" checked={saveId} onChange={() => setSaveId(!saveId)} />
                        <span className="checkbox-effect" />
                        <p>{t('Save ID')}</p>
                    </label>

                    <div className="cont--btn">
                        <Button className={'btn-brand w-100 form-h-big'} onClick={handleLogin}>
                            {t('Login')}
                        </Button>
                    </div>
                </div>
            </div>
            <div className="bottom-box">
                <div className="bottom-box__wrap">
                    <div className="find-pw">
                        <Button
                            className="btn-trans"
                            onClick={() => {
                                dispatch(initFindStep());
                            }}
                        >
                            {t('Forgot your Password?')}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginForm;
