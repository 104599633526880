import React from 'react';
import styled from 'styled-components';
import InnerItemCard from '@components/Card/InnerItemCard';
import useTranslation from '@hooks/useTranslation';
import { MEDICAL_WASTE } from '../../../constants';
import { Bar, Line } from 'react-chartjs-2';
import { CHART_COLORS } from '@components/Charts/config';
import moment from 'moment';

const barChartOptions = {
    plugins: {
        legend: {
            position: 'bottom',
        },
    },
    scales: {
        y: {
            ticks: {
                stepSize: 10,
            },
        },
    },
};

const lineChartOptions = {
    plugins: {
        legend: {
            position: 'bottom',
            labels: {
                boxHeight: 1,
            },
        },
    },
    scales: {
        x: {
            offset: true,
        },
        y: {
            beginAtZero: true,
            ticks: {
                stepSize: 10,
            },
        },
    },
};

const LABEL_LIST = [
    {
        labelName: 'Billed(Isolated medical)',
        dataName: 'billed',
    },
    {
        labelName: 'Total collection',
        dataName: 'total',
    },
];

const WASTE_LABEL_LIST = [
    {
        labelName: 'Isolated medical',
        dataName: 'isolatedMedical',
    },
    {
        labelName: 'Normal medical',
        dataName: 'normalMedical',
    },
    {
        labelName: 'Tissue',
        dataName: 'tissue',
    },
    {
        labelName: 'Damage',
        dataName: 'damage',
    },
    {
        labelName: 'Pathology',
        dataName: 'pathology',
    },
    {
        labelName: 'Biochemistry',
        dataName: 'biochemistry',
    },
    {
        labelName: 'Blood contamination',
        dataName: 'bloodContamination',
    },
    {
        labelName: 'Human tissue',
        dataName: 'humanTissue',
    },
];

const ChartList = ({ data }) => {
    const t = useTranslation(MEDICAL_WASTE);

    const barChartData = {
        labels: data.map(
            ele =>
                `${moment.unix(ele.startDate).format('YYYY-MM-DD')}~${moment.unix(ele.endDate).format('YYYY-MM-DD')}`,
        ),
        datasets: LABEL_LIST.map(({ labelName, dataName }, i) => ({
            label: t(labelName),
            data: data.map(ele => ele[dataName]),
            backgroundColor: CHART_COLORS[i],
        })),
    };

    const lineChartData = {
        labels: data.map(
            ele =>
                `${moment.unix(ele.startDate).format('YYYY-MM-DD')}~${moment.unix(ele.endDate).format('YYYY-MM-DD')}`,
        ),
        datasets: WASTE_LABEL_LIST.map(({ labelName, dataName }, i) => ({
            label: t(labelName),
            data: data.map(ele => ele[dataName]),
            borderColor: CHART_COLORS[i],
            backgroundColor: CHART_COLORS[i],
        })),
    };

    return (
        <GraphContainer className="grid-container gap-3 mt-3">
            <InnerItemCard
                header={{
                    title: t('Billed, collection volume and weight by state'),
                }}
            >
                <Bar options={barChartOptions} data={barChartData} />
            </InnerItemCard>
            <InnerItemCard
                header={{
                    title: t('Waste generation by type'),
                }}
            >
                <Line options={lineChartOptions} data={lineChartData} />
            </InnerItemCard>
        </GraphContainer>
    );
};

const GraphContainer = styled.div`
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 1rem), auto));
`;

export default ChartList;
