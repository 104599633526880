import { Button, LineChart } from '@components';
import InnerItemCard from '@components/Card/InnerItemCard';
import { useTranslation } from '@hooks';
import React from 'react';
import styled from 'styled-components';
import { OUTPATIENT_REPORT, OUTPATIENT_USAGE_CHART_TITLE, TIME_LABELS } from '../../constants';

const Chart = ({ data, onDownloadClick }) => {
    const t = useTranslation(OUTPATIENT_REPORT);

    const lineData = {
        labels: TIME_LABELS,
        datasets: data.map(({ departmentName, kioskCount }) => ({
            label: departmentName,
            data: kioskCount,
        })),
    };

    return (
        <InnerItemCard
            className={'m-2 h-90'}
            bodyClassName={'h-100'}
            header={{ title: t(OUTPATIENT_USAGE_CHART_TITLE) }}
        >
            <div className={'h-90 flx-row position-relative'}>
                <div className={'w-100 h-100 '}>
                    <LineChart
                        data={lineData}
                        options={{
                            scales: {
                                y: {
                                    beginAtZero: true,
                                    suggestedMax: 10,
                                },
                            },
                        }}
                    />
                </div>
                <DownloadButton className={'btn-secondary ml-3'} onClick={onDownloadClick}>
                    {t('Download')}
                </DownloadButton>
            </div>
        </InnerItemCard>
    );
};

const DownloadButton = styled(Button)`
    position: absolute;
    top: 0;
    right: 0;
`;

export default Chart;
