import React, { useContext, useState } from 'react';
import { editWidgetSettings } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import { HiddenMapTile, SingleFloor } from '../../Components/WidgetSettingOptions';
import WidgetSettingModal from '../../Components/WidgetSettingModal';
import useTranslation from '@hooks/useTranslation';
import { ScreenEditDispatchContext } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit';

const GeofenceStatusSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('GeofenceStatus');
    const { config } = widgetInfo;
    const { settings: customSettings } = config;
    const dispatch = useContext(ScreenEditDispatchContext);

    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    const handleFloorChange = selected => {
        if (selected) {
            setUpdatedWidgetInfo(prevState => {
                return { ...prevState, floor: selected.floorId };
            });
        }
    };

    const handleSubmitClick = data => {
        const checkedSettings = customSettings
            ? { ...customSettings, ...data, ...updatedWidgetInfo }
            : { ...data, ...updatedWidgetInfo };

        const updatedWidgetSettings = {
            id: widgetInfo.id,
            settings: checkedSettings,
        };

        dispatch(editWidgetSettings(updatedWidgetSettings));
    };

    const handleHiddenMapTile = checkedHidden => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, hiddenMapTile: checkedHidden };
        });
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    return (
        <WidgetSettingModal
            headerTitle={t('Geofence Status Setting')}
            widgetInfo={widgetInfo}
            socketWidget
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <HiddenMapTile
                updateWidgetInfo={updatedWidgetInfo}
                customSettings={customSettings}
                onChange={handleHiddenMapTile}
            />
            <SingleFloor
                value={
                    updatedWidgetInfo.floor
                        ? updatedWidgetInfo.floor
                        : customSettings && customSettings.floor
                        ? customSettings.floor
                        : ''
                }
                onChange={handleFloorChange}
            />
        </WidgetSettingModal>
    );
};

export default GeofenceStatusSetting;
