import React from 'react';
import CountUp from 'react-countup';
import useTranslation from '@hooks/useTranslation';
import { useOutpatientCareStatusContext, useOutpatientCareStatusDispatch } from '../../../index';
import { setSelectedOutpatientStatus } from '../../../OutpatientCareStatusMonitoringSlice';

const DATA_LABEL = {
    total: {
        value: null,
        label: 'All Reservations',
    },
    arrival: { value: 'arrival', label: 'Arrived' },
    nonArrival: { value: 'nonArrival', label: 'Non-arrival' },
    waitingCare: { value: 'waitingCare', label: 'Waiting for care' },
    nonWaitingCare: { value: 'nonWaitingCare', label: 'Unregistered for care' },
    doneCare: { value: 'doneCare', label: 'Complete care' },
    nonDoneCare: { value: 'nonDoneCare', label: 'Incomplete care' },
};

const ItemCount = ({ start = 0, end = 0, label }) => {
    const t = useTranslation('OutpatientCareStatus');
    const { selectedOutpatientCareStatus } = useOutpatientCareStatusContext();
    const dispatch = useOutpatientCareStatusDispatch();

    const handleLabelClick = () => {
        dispatch(setSelectedOutpatientStatus(DATA_LABEL[label].value));
    };

    const selectedStyleClass = selectedOutpatientCareStatus === DATA_LABEL[label].value ? 'selected' : 'nonSelected';

    return (
        <div className={`flex-center col ${selectedStyleClass}`} onClick={handleLabelClick}>
            <CountUp
                className="item-num"
                decimals={0}
                start={start}
                end={end}
                decimal=","
                duration="1"
                useEasing={false}
                separator=""
                prefix=""
                suffix=""
            />
            <div className={'flx-row'}>
                {t(DATA_LABEL[label].label)}
                {selectedOutpatientCareStatus === DATA_LABEL[label].value ? (
                    <span className="material-icons-round">arrow_drop_down</span>
                ) : (
                    <span className="material-icons-round">arrow_right</span>
                )}
            </div>
        </div>
    );
};

export default ItemCount;
