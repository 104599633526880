import React from 'react';
import styled from 'styled-components';
import { useVitalSignsRealTimePartronContext } from '../index';
import Nameplate from '@components/Nameplate';
import VitalSignsCard from './VitalSignsCard';
import useTranslation from '@hooks/useTranslation';

/**
 * 병실/병상, 환자 상태 카드 리스트 컴포넌트
 *
 * @since 2022-12-20
 * @author kunwoong-kim
 * */
const VitalSignsCardList = () => {
    const t = useTranslation('VitalSignsRealTimePartron');
    const { vitalSignsList } = useVitalSignsRealTimePartronContext();

    return (
        <>
            {Object.entries(vitalSignsList).length ? (
                <div className="grid-container item-grid-700">
                    {Object.entries(vitalSignsList).map(([wardNum, rooms]) => {
                        return Object.entries(rooms).map(([roomNum, beds], idx) => {
                            return (
                                <div key={`${wardNum}_${roomNum}`}>
                                    <IndexContainer>
                                        {idx === 0 && <span>{wardNum}</span>}
                                        <Nameplate className="ml-2" icon="apartment" name={roomNum} />
                                    </IndexContainer>
                                    <VitalSignsCard contents={beds} wardNum={wardNum} />
                                </div>
                            );
                        });
                    })}
                </div>
            ) : (
                <NoDataWrapper>
                    <NoDataComponent>{t('No data', 'Table')}</NoDataComponent>
                </NoDataWrapper>
            )}
        </>
    );
};

const IndexContainer = styled.div`
    display: flex;
    align-items: center;
    min-height: 32px;
    margin-bottom: 0.5rem;

    & .cont-wrap {
        font-size: 0.75rem;
        font-weight: bold;
    }
`;

const NoDataWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NoDataComponent = styled.span`
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.1);
`;

export default VitalSignsCardList;
