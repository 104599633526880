import React, { useEffect, useRef, createContext, useReducer, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import MetisMenu from 'react-metismenu';
import { setEnableMobileMenu } from '@reducer/ThemeOptions';
import { CustomNav, SettingsNav } from './NavItems';
import { useTranslation, useAppSelector } from '@hooks';
import CustomLink from './CustomLink';

const chgMenuLabelByLanguage = (menus, t, setting) => {
    return menus.map(v => {
        const copyV = { ...v };
        if (copyV.content) {
            copyV.content = chgMenuLabelByLanguage(copyV.content, t, setting);
        }
        if (setting) {
            copyV.label = t(`${copyV.label}`);
        }
        return copyV;
    });
};

export const SidebarStateContext = createContext();

const Nav = ({ location, setting, onUpdateSize }) => {
    const [state] = useReducer(null, { onUpdateSize });
    const dispatch = useDispatch();
    const t = useTranslation('Menu');

    const { enableMobileMenu } = useAppSelector(state => state.ThemeOptions);
    const { menuList } = useAppSelector(state => state.CustomMenu);
    const homeMenus = useMemo(() => [...chgMenuLabelByLanguage([...CustomNav, ...menuList], t, false)], [menuList, t]);
    const settingMenus = useMemo(() => [...chgMenuLabelByLanguage(SettingsNav, t, true)], [t]);
    const menus = setting ? settingMenus : homeMenus;
    const navRef = useRef();

    const toggleMobileSidebar = () => {
        dispatch(setEnableMobileMenu(!enableMobileMenu));
    };

    // const isPathActive = path => {
    //     return location.pathname.startsWith(path);
    // };

    const forcedActiveUpdate = () => {
        //window.location.hash === # + location.pathname
        const activePath = window.location.hash || `#${location.pathname}`;
        if (activePath.indexOf('dashboards/edit') >= 0) {
            navRef.current.changeActiveLinkTo(activePath.replace('/edit', ''));
            // navRef.current.changeActiveLinkTo('#/dashboards/list');
        }
        const queryString = activePath.split('?');
        if (queryString[1]) {
            navRef.current.changeActiveLinkTo(activePath.replace(`?${queryString[1]}`, ''));
        }
        navRef.current.changeActiveLinkTo(activePath);
    };

    useEffect(() => {
        forcedActiveUpdate();
    }, [location, menus, setting]);

    return (
        <SidebarStateContext.Provider value={state}>
            <MetisMenu
                content={menus}
                onSelected={toggleMobileSidebar}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="fa fa-angle-down"
                classNameItemHasActiveChild="nav-child-active"
                ref={navRef}
                LinkComponent={CustomLink}
            />
        </SidebarStateContext.Provider>
    );
};

export default withRouter(Nav);
