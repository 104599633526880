import Widget, { SERVICE_CODE, WIDGET_CATEGORY } from '../staticInfo';
import EmergencyAlertHistory from './EmergencyAlertHistory';
import VitalSignsRealTimePartronPoC from './VitalSignsRealTimePartronPoC';
import VitalSignsRealTimePartronPoCSetting from './VitalSignsRealTimePartronPoC/VitalSignsRealTimePartronPoCSetting';
import VitalSignsMonitoringForHiCardiPlus from './VitalSignsMonitoringForHiCardiPlus';
import VitalSignMonitoringForHiCardiPlusSetting from './VitalSignsMonitoringForHiCardiPlus/VitalSignsMonitoringForHiCardiPlusSetting';
import RealTimeEnvironmentalSensor from './RealTimeEnvironmentalSensor';
import IotItemStatusForSecurity from './IotItemStatusForSecurity';
import IotItemStatusSettingForSecurity from './IotItemStatusForSecurity/IotItemStatusSetting';

// 국립암센터 PoC 위급상황 알림 이력
export const emergencyAlertHistory: Widget = {
    type: 'emergencyAlertHistory',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Critical Care Alert History',
    desc: 'It shows the emergency alert history of the integrated nursing care unit.',
    thumbnailClassName: 'w_search_table',
    Component: EmergencyAlertHistory,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Critical Care Alert History' } },
    layout: {
        minW: 10,
        w: 12,
        maxW: 12,
        minH: 36,
        h: 36,
        maxH: 40,
    },
};

// 실시간 활력징후 파트론 PoC 용
export const vitalSignsRealTimePartronPoC = {
    type: 'vitalSignsRealTimePartronPoC',
    category: WIDGET_CATEGORY.MONITORING,
    name: 'Smart Band (Partron)',
    desc: 'It shows the status of vital signs of the Partron device worn by the patient in real time.',
    thumbnailClassName: 'w_filter_cardmonitor',
    Component: VitalSignsRealTimePartronPoC,
    SettingComponent: VitalSignsRealTimePartronPoCSetting,
    config: { defaultSettings: { name: 'Real-time monitoring of mildly ill patients(Partron)' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 14,
        h: 40,
        maxH: 64,
    },
};

// 실시간 활력징후 (HiCardi Plus)
export const vitalSignsMonitoringForHiCardiPlus = {
    type: 'vitalSignsMonitoringForHiCardiPlus',
    category: WIDGET_CATEGORY.MONITORING,
    name: 'Smart Patch (HiCardi)',
    desc: 'It shows the status of vital signs of the HiCardi Plus device worn by the patient in real time.',
    thumbnailClassName: 'w_filter_cardmonitor',
    Component: VitalSignsMonitoringForHiCardiPlus,
    SettingComponent: VitalSignMonitoringForHiCardiPlusSetting,
    config: { defaultSettings: { name: 'Vital Signs Monitoring (HiCardi Plus)' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 14,
        h: 40,
        maxH: 64,
    },
};

// 환경센서 모니터링 위젯
export const realTimeEnvironmentalSensor = {
    type: 'realTimeEnvironmentalSensor',
    category: WIDGET_CATEGORY.MONITORING,
    name: 'Real Time Environmental Sensor',
    desc: 'Shows a real-time environmental sensor status screen.',
    thumbnailClassName: 'w_filter_cardmonitor',
    Component: RealTimeEnvironmentalSensor,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Real Time Environmental Sensor' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 14,
        h: 40,
        maxH: 64,
    },
};

// 자산 현황 - 보안요원용
export const iotItemStatusForSecurity: Widget = {
    type: 'iotItemStatusForSecurity',
    category: WIDGET_CATEGORY.MONITORING,
    name: 'Iot Item Status For Security Guards',
    desc:
        'It shows the current status of the assets selecting all of them or some of them by their status(for security guards).',
    thumbnailClassName: 'w_chart_table',
    Component: IotItemStatusForSecurity,
    SettingComponent: IotItemStatusSettingForSecurity,
    config: {
        defaultSettings: {
            name: 'Iot Item Status For Security Guards',
            intervalTime: 10000,
            categoryCode: 'ASSET',
        },
    },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};

const commonWidgetList: Record<string, Widget> = {
    // POC 응급알림
    emergencyAlertHistory,
    // POC 하이카디
    vitalSignsMonitoringForHiCardiPlus,
    vitalSignsRealTimePartronPoC,

    // 더자인 병원
    realTimeEnvironmentalSensor,

    // 세종병원
    iotItemStatusForSecurity,
};

for (const widget in commonWidgetList) {
    if (commonWidgetList.hasOwnProperty(widget)) {
        commonWidgetList[widget].serviceCode = SERVICE_CODE.COMMON;
    }
}

export default commonWidgetList;
