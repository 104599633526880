import React from 'react';
import useTranslation from '@hooks/useTranslation';
import WidgetSettingModal from '../../Components/WidgetSettingModal';

const IVInjectionMonitoringSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('IVInjectionMonitoring');
    return (
        <WidgetSettingModal headerTitle={t('IV Injection Monitoring Setting')} widgetInfo={widgetInfo} {...restProps} />
    );
};

export default IVInjectionMonitoringSetting;
