import React from 'react';
import styled from 'styled-components';
import OutpatientAllBloodDrawCounts from './Components/OutpatientAllBloodDrawCounts';
import OutpatietnWaitingBloodDrawCounts from './Components/OutpatietnWaitingBloodDrawCounts';
import OutpatientCompleteBloodDrawCounts from './Components/OutpatientCompleteBloodDrawCounts';

const OutpatientBloodDrawStatusCount = ({ bloodDrawCount }) => {
    const { total, nonProcessingStat, inspectCountStat } = bloodDrawCount;
    return (
        <Container>
            <OutpatientAllBloodDrawCounts total={total} />
            <OutpatietnWaitingBloodDrawCounts nonProcessingStat={nonProcessingStat} />
            <OutpatientCompleteBloodDrawCounts inspectCountStat={inspectCountStat} />
        </Container>
    );
};
const Container = styled.div`
    display: flex;
    width: 100%;
    & > div {
        &:nth-child(1) {
            flex: 1;
        }
        flex: 2;
    }
    margin-bottom: 5px;
`;
export default OutpatientBloodDrawStatusCount;
