import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    keyword: '',
    searchOption: '',
};

const iotItemMappingLogSlice = createSlice({
    name: 'iotItemInfoLog',
    initialState,
    reducers: {
        setKeyword: (state, action) => {
            state.keyword = action.payload;
        },
        setSearchOption: (state, action) => {
            state.searchOption = action.payload;
        },
        reset: (state, action) => {
            const initState = { ...initialState, ...action.payload };
            for (let key in initState) {
                state[key] = initState[key];
            }
        },
    },
});

export const { setKeyword, setSearchOption, reset } = iotItemMappingLogSlice.actions;
export default iotItemMappingLogSlice;
