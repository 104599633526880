import React, { useEffect, useRef, useState } from 'react';
import { useAsync, useInterval, useTranslation } from '@hooks';
import { Button, ConfirmModal, DatePicker, Select as ReactSelect } from '@components';
import { SelectGroup } from '../../../../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import SearchButtonArea from '../../../../../../../MainPages/Components/FilterSearchGroup/Components/SearchButtonArea';
import SearchGroup from '../../../../../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import FilterSearchGroup from '../../../../../../../MainPages/Components/FilterSearchGroup';
import { useSensorStatusContext, useSensorStatusDispatch } from '../../../../index';
import { INTERVAL_TIME, setReportType, setSensorDetailsList } from '../../../../sensorStatusSlice';
import {
    fetchExcelLogApi,
    fetchSensorLogList,
    getMappedSensorMonthlyReportApi,
    getSensorMonthlyReportApi,
} from '@api/common/log';
import SensorLineGraph from '../SensorLineGraph';
import moment from 'moment';
import { excelExport } from '../../../../../../util/excelExport';
import SensorInfo from './Components/SensorInfo';
import InnerItemCard from '@components/Card/InnerItemCard';

/**
 * 센서 일별, 월별 리포트
 *
 * @author created by 정진범
 * */
const getDayStartStamp = startDate => moment(startDate).startOf('day').unix();
const getDayEndStamp = endDate => moment(endDate).endOf('day').unix();
const SensorReport = () => {
    const t = useTranslation('SensorStatus');
    const dispatch = useSensorStatusDispatch();
    const {
        selectedSensor,
        sensingTypeOptions,
        reportType,
        sensorDetailsList,
        sensorLineChartData,
    } = useSensorStatusContext();

    const cardRef = useRef();

    const timeOptions = [
        { value: 300, label: t('5min') },
        { value: 3600, label: t('1hr') },
        { value: 7200, label: t('2hrs') },
        { value: 10800, label: t('3hrs') },
    ];

    const [selectedTimeOption, setSelectedTimeOption] = useState(timeOptions[0]);
    const [selectedSensingType, setSelectedSensingType] = useState({});
    const [selectedSensorSensingTypeOptions, setSelectedSensorSensingTypeOptions] = useState([]);
    const [excelInfo, setExcelInfo] = useState(null);
    const [modal, setModal] = useState({
        noData: false,
        excelResult: false,
        dateSearchResult: false,
    });

    useEffect(() => {
        // 선택한 선택자의 센싱타입 옵션과 전체 센싱타입을 비교해서 옵션 Select에 들어갈 옵션값 생성
        if (Object.keys(selectedSensor).length) {
            const selectorOption = sensingTypeOptions.filter(sensorItem =>
                selectedSensor.sensorItems.find(sensor => sensor.sensingType === sensorItem.sensingType),
            );

            // 다국어 처리 시 기존에 선택한 옵션값을 유지하기 위한 로직
            if (Object.keys(selectedSensingType).length) {
                setSelectedSensingType(
                    selectorOption.find(({ sensingType }) => selectedSensingType.sensingType === sensingType),
                );
            } else {
                setSelectedSensingType(selectorOption[0]);
            }
            setSelectedSensorSensingTypeOptions(selectorOption);
        }

        //다국어 처리를 위한 인자
    }, [sensingTypeOptions]);

    useEffect(() => {
        // 엑셀 다운로드를 눌러서 엑셀 정보가 들어왔을 때 다운 받는 api 호출
        if (excelInfo) {
            toggleDelay();
        }
    }, [excelInfo]);

    const toggleDelay = useInterval({
        callback: param => {
            fetchExcelLog(param);
        },
        delay: INTERVAL_TIME,
        params: excelInfo ? { reqNum: excelInfo.reqNum } : {},
        initial: false,
    });

    const { promise: fetchSensorLog, state: sensorLogInfo } = useAsync({
        promise: fetchSensorLogList,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            const sensingType = selectedSensingType.sensingType;
            const chartData = res.rows
                .sort((a, b) => a.regDate - b.regDate)
                .map(element => {
                    return {
                        ...element,
                        data: JSON.parse(element.data)[sensingType],
                        regDate: moment.unix(element.regDate).format('HH:mm:ss'),
                        date: element.regDate,
                    };
                })
                .filter(element => element.data);

            if (!res.rows.length || !chartData.length) {
                handleModal('noData');
                dispatch(
                    setSensorDetailsList({
                        data: [],
                        loading: false,
                        sensorLogInfo: {},
                        chartData: [],
                    }),
                );
            } else {
                dispatch(
                    setSensorDetailsList({ data: res, chartData, loading: sensorLogInfo.isLoading, sensorLogInfo }),
                );
            }
        },
    });

    const { promise: getMappedSensorMonthlyReport, state: sensorMonthlyReportLogInfo } = useAsync({
        promise: getMappedSensorMonthlyReportApi,
        resolve: res => {
            const sensingType = selectedSensingType.sensingType;
            const chartData = res.rows[0].sensorLogs.map(element => {
                return {
                    ...element,
                    data: JSON.parse(element.data)[sensingType],
                    date: moment.unix(element.regDate).format('DD dd'),
                };
            });

            if (!res.rows[0] || !chartData.length) {
                handleModal('noData');
                dispatch(
                    setSensorDetailsList({
                        data: [],
                        loading: false,
                        sensorLogInfo: {},
                        chartData: [],
                    }),
                );
            } else {
                dispatch(
                    setSensorDetailsList({ data: res, chartData, loading: sensorLogInfo.isLoading, sensorLogInfo }),
                );
            }
        },
    });

    const { promise: getSensorMonthlyReport } = useAsync({
        promise: getSensorMonthlyReportApi,
        fixedParam: {
            excelDownloadRequest: {
                zipFileName: 'Sensor_Status_Monthly_Log',
                fileName: 'Sensor_Status_Monthly_Log',
                columnMetas: [
                    {
                        key: 'targetName',
                        name: t('Target Name', 'CommonColumn'),
                    },
                    {
                        key: 'deviceId',
                        name: t('Sensor Mac Address', 'CommonColumn'),
                    },
                    {
                        key: 'data',
                        name: t('Value', 'CommonColumn'),
                    },
                    {
                        key: 'floorId',
                        name: t('Floor', 'CommonColumn'),
                    },
                    {
                        key: 'regDate',
                        name: t('Registered Date', 'CommonColumn'),
                        isDateFormat: 'Y',
                    },
                ],
            },
        },
        resolve: res => {
            const { filePath: url, reqNum } = res;
            if (url) {
                setExcelInfo({
                    url,
                    reqNum,
                });
            }
        },
    });

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            const { dataSettingStatusType, filePath: url, fileName } = res;
            if (dataSettingStatusType === 'COMPLETED') {
                excelExport(url, fileName);
            } else if (dataSettingStatusType === 'FAILED') {
                handleModal('excelResult');
            }
            setExcelInfo(null);
            toggleDelay();
        },
    });

    const [startDate, setStartDate] = useState(moment().valueOf());
    const [endDate, setEndDate] = useState(moment().valueOf());
    const handleSearchClick = () => {
        if (reportType === 'monthly') {
            // 검색 가능 최대 주기는 31일
            if (moment(endDate).diff(moment(startDate), 'days') > 31) {
                handleModal('dateSearchResult');
            } else {
                getMappedSensorMonthlyReport({
                    list: [
                        {
                            deviceId: selectedSensor.sensorId,
                            targetId: selectedSensor.targetId,
                            targetName: selectedSensor.targetName,
                            deviceNum: selectedSensor.sensorNum,
                            sensingType: selectedSensingType.sensingType,
                        },
                    ],
                    startDate: getDayStartStamp(startDate),
                    endDate: getDayEndStamp(endDate),
                });
            }
        } else {
            fetchSensorLog({
                sensingType: selectedSensingType.sensingType,
                startDate: getDayStartStamp(startDate),
                endDate: getDayEndStamp(startDate),
                stepTime: selectedTimeOption.value,
                targetId: selectedSensor.targetId,
            });
        }
    };

    const handleMonthlyReportClick = () => {
        dispatch(setReportType('monthly'));
        setStartDate(moment().subtract(1, 'months').valueOf());
        setEndDate(moment().valueOf());
        dispatch(
            setSensorDetailsList({
                data: [],
                loading: false,
                sensorLogInfo: {},
                chartData: [],
            }),
        );
    };

    const handleSensingTypeSelectChange = selected => {
        setSelectedSensingType(selected);
    };

    const handleExportButtonClick = () => {
        const { request } = sensorMonthlyReportLogInfo;
        if (!sensorLineChartData.length || !request) {
            handleModal('noData');
        } else {
            const sensingType = request.list[0].sensingType;
            delete request.list[0].sensingType;
            getSensorMonthlyReport({ targetDeviceLogSearchParam: { sensingType, ...request } });
        }
    };

    const handleModal = name => {
        setModal(prev => {
            return {
                ...prev,
                [name]: !prev[name],
            };
        });
    };
    const handleStartDate = selected => {
        if (endDate > selected) {
            setStartDate(selected);
        }
    };
    const handleEndDate = selected => {
        setEndDate(selected);
    };
    return (
        <>
            <InnerItemCard
                innerRef={cardRef}
                className={'sensor-report-card inner-item'}
                header={{
                    title: reportType === 'daily' ? t('Daily Sensor Report') : t('Monthly Sensor Report'),
                }}
            >
                <FilterSearchGroup className={'p-0'}>
                    <SearchGroup className={'bg-brand'}>
                        <SelectGroup>
                            <DatePicker
                                key={`startDate_${startDate}_${endDate}`}
                                value={startDate}
                                handleChange={handleStartDate}
                                valueType={'ms'}
                                maxDate={moment().valueOf()}
                                withoutTime
                            />
                        </SelectGroup>
                        {reportType === 'monthly' && (
                            <SelectGroup>
                                <DatePicker
                                    key={`endDate${startDate}_${endDate}`}
                                    value={endDate}
                                    handleChange={handleEndDate}
                                    valueType={'ms'}
                                    minDate={startDate}
                                    maxDate={moment().valueOf()}
                                    withoutTime
                                />
                            </SelectGroup>
                        )}
                        <SelectGroup>
                            <ReactSelect
                                name={'sensingTypeSelect'}
                                value={selectedSensingType}
                                options={selectedSensorSensingTypeOptions}
                                valueKey={'sensingType'}
                                labelKey={'language'}
                                onChange={handleSensingTypeSelectChange}
                                customControlStyles={{
                                    width: '100%',
                                }}
                                customMenuStyles={{ width: '100%' }}
                                customOptionStyles={{ width: '100%' }}
                            />
                        </SelectGroup>
                        {reportType === 'daily' && (
                            <SelectGroup>
                                <ReactSelect
                                    name={'selectInput'}
                                    value={selectedTimeOption}
                                    options={timeOptions}
                                    onChange={selected => setSelectedTimeOption(selected)}
                                    customControlStyles={{
                                        width: '100%',
                                    }}
                                    customMenuStyles={{ width: '100%' }}
                                    customOptionStyles={{ width: '100%' }}
                                />
                            </SelectGroup>
                        )}

                        <SearchButtonArea>
                            <Button className={'btn-secondary'} onClick={handleSearchClick}>
                                {t('Search', 'Search')}
                            </Button>
                        </SearchButtonArea>

                        <div className={'widget-header-right'}>
                            {reportType === 'daily' ? (
                                <Button
                                    className={'btn-brand btn-icon'}
                                    iconName="menu"
                                    onClick={handleMonthlyReportClick}
                                >
                                    {t('Monthly Report')}
                                </Button>
                            ) : (
                                <Button className={'btn-lightgray btn-icon'} onClick={handleExportButtonClick}>
                                    <span className={'material-icons-round'}>file_upload</span>
                                    {t('Export', 'Button')}
                                </Button>
                            )}
                        </div>
                    </SearchGroup>
                </FilterSearchGroup>

                <div className={'sensor-report-container'}>
                    <div
                        style={{
                            width: '72.5rem',
                            minWidth: '52.5rem',
                            maxWidth: '82.5rem',
                            height: '15rem',
                        }}
                        className={'sensor-report-item'}
                    >
                        {!sensorDetailsList.rows.length ? (
                            <>
                                <span className={'icon-search'} />
                                {t('Click the search button after selecting the date and sensor type')}
                            </>
                        ) : (
                            <SensorLineGraph
                                dataKey={reportType === 'monthly' ? 'date' : 'regDate'}
                                selectedSensingType={selectedSensingType.sensingType}
                            />
                        )}
                    </div>
                    <SensorInfo />
                </div>
            </InnerItemCard>

            <ConfirmModal
                initModal={modal.noData}
                toggleModal={() => handleModal('noData')}
                header={{ title: t('Alert') }}
                confirmText={<span>{t('There is no data')}</span>}
                removeCancel={true}
            />
            <ConfirmModal
                initModal={modal.excelResult}
                toggleModal={() => handleModal('excelResult')}
                header={{ title: t('Result', 'ConfirmModal') }}
                confirmText={<span>{t('Failed to download the excel.', 'ConfirmModal')}</span>}
                removeCancel={true}
            />
            <ConfirmModal
                initModal={modal.dateSearchResult}
                toggleModal={() => handleModal('dateSearchResult')}
                header={{ title: t('Result', 'ConfirmModal') }}
                confirmText={<span>{t('The maximum searchable period is 31 days.', 'ConfirmModal')}</span>}
                removeCancel={true}
            />
        </>
    );
};

export default SensorReport;
