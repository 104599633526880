import React, { useState } from 'react';
import { FileInput } from '@components/Input/FileInput';
import { Button, ConfirmModal, Label, QuestionTooltip, TextInput } from '@components';
import useTranslation from '@hooks/useTranslation';
import {
    refreshManualList,
    toggleRegisterModal,
    useAssetManualManagementDispatchContext,
    useAssetManualManagementStateContext,
} from '../slice';
import SingleTreeSelect from '@components/Select/SingleTreeSelect';
import { useAppSelector, useAsync } from '@hooks';
import cx from 'classnames';
import { ModalFooter } from 'reactstrap';
import { Nullable, NullableString } from '@util/type/util';
import { SingleValue } from 'react-select';
import { OptionType } from '@components/type';
import { registerAssetManualApi } from '@api/am/assetManual';
import { CATEGORY_CODE } from '@util/mappInfo';
import { CategoryInfo } from '@api/common/category';
import Loader from 'react-loaders';
import styled from 'styled-components';

type ManualData = {
    file?: Nullable<File>;
    categoryCode?: NullableString;
    description?: NullableString;
};

const initialManualData = {
    file: null,
    categoryCode: '',
    description: '',
};

const RegisterModal = ({ open }: { open: boolean }) => {
    const t = useTranslation('AssetManualManagement');
    const categoryList = useAppSelector(state => state.CategoryInfo.categoryList);
    const dispatch = useAssetManualManagementDispatchContext()!;
    const { manualCategoryList } = useAssetManualManagementStateContext();
    const [manualData, setManualData] = useState<ManualData>(initialManualData);

    const toggleModal = () => {
        dispatch(toggleRegisterModal());
        setManualData(initialManualData);
    };

    const handleCategoryChange = (selected: SingleValue<OptionType>) => {
        setManualData({ ...manualData, categoryCode: selected?.categoryCode });
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.currentTarget;
        const files = (target.files as FileList)[0];
        setManualData({ ...manualData, file: files });
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setManualData({ ...manualData, description: e.currentTarget.value });
    };

    const {
        promise: registerAssetManual,
        state: { isLoading },
    } = useAsync({
        promise: registerAssetManualApi,
        resolve: res => {
            dispatch(refreshManualList());
            toggleModal();
        },
    });

    const handleSubmit = () => {
        const formData = new FormData();
        for (let key in manualData) {
            const value = manualData[key as keyof ManualData];
            if (typeof value === 'string' || value instanceof Blob) {
                formData.append(key, value);
            }
        }
        registerAssetManual({ formData });
    };

    return (
        <>
            <ConfirmModal
                header={{ title: t('Register manual') }}
                initModal={open}
                toggleModal={toggleModal}
                bodyText={
                    <div className={'flx-col gap-3 pnt-label-5'}>
                        <Label
                            name={
                                <>
                                    {t('Category')}
                                    <QuestionTooltip contents={t('You can only submit one file per category')} />
                                </>
                            }
                            value={
                                <SingleTreeSelect
                                    className={'form-must'}
                                    isModalSelect
                                    data={categoryList.reduce((acc, category) => {
                                        if (
                                            category.categoryCodePath.indexOf(CATEGORY_CODE.ASSET + '>') === 0 &&
                                            !(manualCategoryList as string[]).includes(category.categoryCode)
                                        ) {
                                            if (category.parentCode === CATEGORY_CODE.ASSET) {
                                                acc.push({ ...category, parentCode: null });
                                            } else {
                                                acc.push(category);
                                            }
                                        }
                                        return acc;
                                    }, [] as CategoryInfo[])}
                                    title={'Category'}
                                    valueKey={'categoryCode'}
                                    labelKey={'categoryName'}
                                    parentKey={'parentCode'}
                                    selected={manualData?.categoryCode}
                                    onChange={handleCategoryChange}
                                />
                            }
                        />
                        <Label
                            name={t('Manual File')}
                            value={
                                <>
                                    <FileInput
                                        className={'form-must'}
                                        accept={'application/pdf'}
                                        handleChange={handleFileChange}
                                    />
                                    <span className={'pnt-txt s-10'}>{`* ${t(
                                        'Only PDF files can be registered',
                                    )}`}</span>
                                </>
                            }
                        />
                        <Label
                            name={t('Description')}
                            value={
                                <TextInput
                                    inputGroupClassName={'w-100'}
                                    maxlength={30}
                                    name={'description'}
                                    placeholder={t('You can write up to 30 characters')}
                                    value={manualData?.description || ''}
                                    handleChange={handleDescriptionChange}
                                />
                            }
                        />
                    </div>
                }
                modalFooter={
                    <ModalFooter>
                        <Button className={'btn-gray'} onClick={toggleModal}>
                            {t('Cancel')}
                        </Button>
                        <Button
                            disabled={!manualData?.categoryCode || !manualData.file || isLoading}
                            className={cx('btn-secondary')}
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            {isLoading ? <StyledLoader active type="ball-pulse" /> : t('OK')}
                        </Button>
                    </ModalFooter>
                }
            />
        </>
    );
};

const StyledLoader = styled(Loader)`
    & .ball-pulse {
        display: flex;
    }
    & .ball-pulse > div {
        width: 10px;
        height: 10px;
    }
`;

export default RegisterModal;
