import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import AuthorizationGroupSubMenu from './Components/AuthorizationGroupSubMenu';
import { useAsync, useTranslation } from '@hooks';
import { Button, Card, Label, LoadingBlock, Page, Table } from '@components';
import { fetchAuthGroupList } from '@api/common/authGroup';
import { A_PATH_AUTHORIZATION_GROUP_CREATE } from '../../../../Components/Router/path';
import reducer, { initialState, setGroupList } from './reducer';

export const AuthorizationGroupListContext = createContext();
export const AuthorizationGroupListDispatchContext = createContext();

const AuthorizationGroupList = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { groupListInfo } = state;
    const t = useTranslation('AuthorizationGroup');
    const history = useHistory();

    const columns = useMemo(() => {
        return [
            {
                Header: t('Group Name'),
                className: 'd-flex flx-center text-break',
                accessor: 'groupName',
            },
            {
                Header: t('Group Description'),
                className: 'd-flex flex-start text-break',
                accessor: 'description',
            },
            {
                Header: t('Group Application Screen'),
                className: 'pl-3 text-ellipsis',
                accessor: 'screenList',
                formatTitle: ({ value }) =>
                    value[0]
                        ? `${value[0].menuName} > ${value[0].screenName}${
                              value[1] ? ` ${t('other')} ${value.length - 1}` : ''
                          }`
                        : '',
                Cell: ({ value }) =>
                    value.map((data, idx) => (
                        <Label
                            key={`${data.menuName}_${data.screenName}_${idx}`}
                            name={`${data.menuName} > ${data.screenName}`}
                            labelValueClassName={`pnt-txt txt-dot txt-bold ${value.length !== idx + 1 ? 'mb-2' : ''}`}
                        />
                    )),
                style: {
                    maxHeight: '150px',
                    overflow: 'auto',
                },
            },
            {
                Header: t('Modified Date'),
                accessor: 'modDate',
                width: 100,
                className: 'd-flex align-center flx-center',
                Cell: ({ value, row: { original } }) => {
                    const modDate = value || original.regDate;
                    return modDate ? moment(modDate * 1000).format('YYYY-MM-DD HH:mm:ss') : '';
                },
            },
            {
                Header: t('Action'),
                id: 'subMenu',
                width: 70,
                className: 'flex-center overflow-visible',
                Cell: AuthorizationGroupSubMenu,
            },
        ];
    }, [t]);

    const { state: groupListReqState, promise: getGroupList } = useAsync({
        promise: fetchAuthGroupList,
        fixedParam: { isAll: 'Y' },
        resolve: response => {
            dispatch(setGroupList(response.rows ?? []));
        },
    });

    useEffect(() => {
        getGroupList();
    }, []);

    return (
        <AuthorizationGroupListDispatchContext.Provider value={dispatch}>
            <AuthorizationGroupListContext.Provider value={state}>
                <Page
                    className="h-100"
                    menuPath={[t('Authorization Group Management', 'Menu'), t('Authorization Group List', 'Menu')]}
                >
                    <Card
                        className="h-100 mb-0"
                        header={{
                            title: t('Authorization Group List'),
                            action: (
                                <Button
                                    className={'btn-icon btn-secondary'}
                                    iconName="add"
                                    onClick={() => history.push(A_PATH_AUTHORIZATION_GROUP_CREATE)}
                                >
                                    {t('Create a Group')}
                                </Button>
                            ),
                        }}
                    >
                        <LoadingBlock blocking={groupListReqState.isLoading}>
                            <Table columns={columns} data={groupListInfo} paging={false} dynamicRowHeight />
                        </LoadingBlock>
                    </Card>
                </Page>
            </AuthorizationGroupListContext.Provider>
        </AuthorizationGroupListDispatchContext.Provider>
    );
};

export default AuthorizationGroupList;
