import React from 'react';
import useTranslation from '@hooks/useTranslation';
import WidgetSettingModal from '../../Components/WidgetSettingModal';

const VitalSignsRealTimePartronSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('VitalSignsRealTimePartron');
    return (
        <WidgetSettingModal
            headerTitle={t('Vital Signs Monitoring(Partron) Setting')}
            widgetInfo={widgetInfo}
            {...restProps}
        />
    );
};

export default VitalSignsRealTimePartronSetting;
