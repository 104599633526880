import React, { useMemo } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { initUUIDv4 } from '@util/common/util';
import styled from 'styled-components';

const ExpandableTooltip = styled(UncontrolledTooltip)`
    .tooltip-inner {
        max-width: ${({ $isFitContent }) => ($isFitContent ? 'fit-content' : '200px')};
    }
`;

type Placement =
    | 'auto-start'
    | 'auto'
    | 'auto-end'
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-end'
    | 'bottom'
    | 'bottom-start'
    | 'left-end'
    | 'left'
    | 'left-start';

interface TooltipProps {
    tooltipId?: string;
    contents: JSX.Element | string;
    placement?: Placement;
    className?: string;
    Icon?: ({ id, className }: { id: string; className: string }) => JSX.Element;
    isAlert?: boolean;
    isFitContent?: boolean;
}

export const AlertTooltip = ({ tooltipId, contents, placement = 'auto', className = 'ml-2' }: TooltipProps) => {
    const id = useMemo(() => {
        return tooltipId ?? `tooltip_${initUUIDv4()}`;
    }, []);
    return (
        <>
            <span id={id} className="material-icons-round md-18 cursor-default">
                info
            </span>
            <UncontrolledTooltip placement={placement} target={id}>
                {contents}
            </UncontrolledTooltip>
        </>
    );
};

export const QuestionTooltip = ({
    tooltipId,
    contents,
    placement = 'auto',
    className = 'ml-2',
    isFitContent = false,
}: TooltipProps) => {
    const id = useMemo(() => {
        return tooltipId ?? `tooltip_${initUUIDv4()}`;
    }, []);
    return (
        <>
            <span id={id} className="material-icons-round md-18 cursor-default">
                help
            </span>
            <ExpandableTooltip placement={placement} target={id} $isFitContent={isFitContent}>
                {contents}
            </ExpandableTooltip>
        </>
    );
};

const Tooltip = ({ tooltipId, contents, isAlert, Icon, placement = 'auto', className = 'ml-2' }: TooltipProps) => {
    const id = useMemo(() => {
        return tooltipId ?? `tooltip_${initUUIDv4()}`;
    }, []);
    return Icon ? (
        <>
            <Icon id={id} className={className} />
            <UncontrolledTooltip placement={placement} target={id}>
                {contents}
            </UncontrolledTooltip>
        </>
    ) : isAlert ? (
        <AlertTooltip tooltipId={id} contents={contents} placement={placement} className={className} />
    ) : (
        <QuestionTooltip tooltipId={id} contents={contents} placement={placement} className={className} />
    );
};

export default Tooltip;
