import { useSelector } from 'react-redux';

const useTopFloorGroup = ({ targetFloorId } = {}) => {
    // 전체 층 정보
    const allFloors = useSelector(state => state.FloorInfo.floorList);
    // 최상위 층
    const topFloorList = allFloors.filter(floor => floor.levelNo === 0);
    // 최상위 층을 제외한 층
    const containedFloorList = allFloors.filter(floor => floor.levelNo !== 0);

    // floorId 층 정보
    const targetFloor = allFloors.find(floor => floor.floorId === targetFloorId);

    // floorId 층 정보가 없는 경우
    if (!targetFloor) {
        const topFloor = topFloorList && topFloorList.length && topFloorList[0];
        return {
            topFloor,
            floor: topFloor && containedFloorList.find(floor => floor.upperFloorId === topFloor.floorId),
        };
    }

    // 최상위 층이 아닌 경우 최상위 층 탐색
    const topFloor = !targetFloor.upperFloorId
        ? targetFloor
        : (function findTopFloor(userFloor) {
              return userFloor.upperFloorId
                  ? findTopFloor(allFloors.find(floor => floor.floorId === userFloor.upperFloorId))
                  : userFloor;
          })(targetFloor);

    return {
        topFloor,
        floor: topFloor.floorId !== targetFloor.floorId ? targetFloor : null,
    };
};

export default useTopFloorGroup;
