import React from 'react';
import useTranslation from '@hooks/useTranslation';
import ToggleCheckbox from '@components/Input/ToggleCheckbox';
import Label from '@components/Label';

/**
 * @param {string} value 선택한 알람 타입
 * @param {function} onChange 알람타입 변경 함수
 *
 * @author created bu 정진범 2022-12-08
 * */
const NotificationSettings = ({ value, onChange }) => {
    const t = useTranslation('UserBox');

    return (
        <Label
            name={t('Notification settings')}
            value={
                <>
                    {Object.entries(value).map(([key, value], idx) => (
                        <ToggleCheckbox
                            key={`${key}_${idx}`}
                            id={key}
                            name={t(key)}
                            checked={value}
                            handleChecked={onChange}
                            className="mb-0"
                        />
                    ))}
                </>
            }
        />
    );
};

export default NotificationSettings;
