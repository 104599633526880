import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import { HashRouter } from 'react-router-dom';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './assets/base.scss';
import Main from './Components/MainPages';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import './i18n';
import '@material-design-icons/font';
import 'loaders.css';

export const store = configureStore();
const rootElement = document.getElementById('root');

const renderApp = (Component, appInfo) => {
    ReactDOM.render(
        <Provider store={store}>
            <HashRouter>
                <Component appInfo={appInfo} />
            </HashRouter>
        </Provider>,
        rootElement,
    );
};

const getAppInfo = async function (Main) {
    return await fetch(`${process.env.PUBLIC_URL}/config/app.json`)
        .then(response => {
            return response.json();
        })
        .then(config => {
            renderApp(Main, config);
            return config;
        })
        .catch(err => {
            renderApp(Main);
            console.log(err);
        });
};

getAppInfo(Main);

if (module.hot) {
    module.hot.accept('./Components/MainPages', () => {
        const NextApp = require('./Components/MainPages').default;
        renderApp(NextApp);
    });
}
serviceWorker.unregister();
