import React, { useState } from 'react';

import { Table } from '@components';
import { useAppSelector, useAsync, useColumns, useTranslation } from '@hooks';
import { CATEGORY_CODE } from '@util/mappInfo';
import * as mainCols from '../../../MainPages/util/grid/column';

import WidgetCard from '../../Components/WidgetCard';
import Search, { DateRange } from './Components/Search';
import moment from 'moment';
import { WidgetProps } from '../../staticInfo';
import { getTestTargetPatientAlertHistoryApi } from '@api/sh/examPatientMonitoring';
import { UnixTimestamp } from '@util/type/util';
import { getEndOfDate, getStartOfDate } from '@util/date';
import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';
import ExcelExport from './Components/ExcelExport';

const EmergencyAlertHistory = ({ children, widgetInfo, ...restProps }: WidgetProps) => {
    const t = useTranslation('ExamPatientsEmergencyAlertHistory');
    const mode = useAppSelector(state => state.ScreenInfo.mode);

    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: getStartOfDate(),
        endDate: getEndOfDate(),
    });
    const [subjectNum, setSubjectNum] = useState('');

    const wardMapInfo = useAppSelector(state => state.DepartmentsInfo.wardMapInfo);

    // 알림 이력 조회
    const [history, setHistory] = useState({ rows: [] });

    const { state, promise } = useAsync({
        fixedParam: {
            categoryCode: CATEGORY_CODE.PATIENT,
            pageSize: 50,
        },
        param: {
            ...dateRange,
        },
        promise: getTestTargetPatientAlertHistoryApi,
        resolve: res => setHistory(res),
        immediate: true,
    });

    const columns = useColumns(
        [
            {
                Header: 'Study Number',
                accessor: 'subjectNum',
                width: 110,
            },
            mainCols.sensorName({
                Header: 'Device Name',
                className: 'text-center',
                accessor: 'deviceName',
                width: 110,
            }),
            {
                Header: 'Vital Signs',
                className: 'text-ellipsis',
                accessor: 'sensingType',
                width: 110,
                Cell: ({ value }: { value: 'string' }) => <span>{t(value)}</span>,
            },
            {
                Header: 'Vital Signs Value',
                accessor: 'currentValue',
                width: 90,
                Cell: ({ value }: { value: number }) => <span>{value ?? '-'}</span>,
            },
            {
                Header: 'Recognition Time',
                className: 'text-ellipsis',
                accessor: 'regDate',
                width: 150,
                Cell: ({ value }: { value: number }) => moment(value * 1000).format('YYYY-MM-DD HH:mm:ss'),
            },
        ],
        t,
        [wardMapInfo],
    );

    const handlePageChange = (pageIndex: number) => {
        const param = state.request;
        promise({ ...param, page: pageIndex });
    };

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            headerAction={
                ![SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW].includes(mode ?? '') && (
                    <ExcelExport param={state.request ?? {}} />
                )
            }
            searchFilter={
                <Search
                    dateRange={dateRange}
                    handleDateRange={setDateRange}
                    subjectNum={subjectNum}
                    handleSubjectNum={setSubjectNum}
                    handleReset={() => {
                        const initialDate = {
                            startDate: getStartOfDate(),
                            endDate: getEndOfDate(),
                        };
                        setDateRange(initialDate);
                        setSubjectNum('');
                        promise({ ...initialDate });
                    }}
                    handleSearchClick={() => {
                        const param: { startDate: UnixTimestamp; endDate: UnixTimestamp; subjectNum?: number } = {
                            ...dateRange,
                        };
                        if (subjectNum.trim() && !isNaN(Number(subjectNum))) {
                            param.subjectNum = Number(subjectNum);
                        }
                        promise(param);
                    }}
                />
            }
            bodyClassName={'flx-col h-100 gap-0'}
            {...restProps}
        >
            <Table
                columns={columns}
                data={{ ...history, pageSize: 50 }}
                onPageChange={handlePageChange}
                noDataMessage={t('No history of emergency.')}
                loading={state.isLoading}
            />
            <span className="pnt-txt txt-bold">
                *{' '}
                {t(
                    'Regardless of the emergency notification window, you can check all the history of warning and danger.',
                )}
            </span>
            {children}
        </WidgetCard>
    );
};

export default EmergencyAlertHistory;
