import { getOwnershipListApi } from '@api/common/asset';
import { useAppSelector, useAsync } from '@util/hooks';
import { useState } from 'react';

const useSingleWardOptions = () => {
    const { userInfo } = useAppSelector(state => state.UserInfo);
    const { groupNums } = userInfo as { groupNums: number[] }; // TODO userInfo 타이핑

    const { state: wardState } = useAsync({
        promise: getOwnershipListApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        reject: err => console.error(err),
    });
    const filteredLoginGroupList =
        wardState.response?.rows.filter(({ groupNum }: { groupNum: number }) => groupNums.includes(groupNum)) ?? [];
    const options = filteredLoginGroupList.map(({ groupName, groupNum }: { groupNum: number; groupName: string }) => ({
        value: groupNum,
        label: groupName,
    }));

    const [selectedWard, setSelectedWard] = useState<{ value: number; label: string }>();
    if (!selectedWard && options.length > 0) {
        setSelectedWard(options[0]);
    }

    const handleChange = ({ value, label }: { value: number; label: string }) => {
        setSelectedWard({ label, value });
    };

    return {
        value: selectedWard,
        options,
        onChange: handleChange,
    };
};

export default useSingleWardOptions;
