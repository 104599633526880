import React, { useContext, useReducer, useRef } from 'react';
import WidgetCard from '../../Components/WidgetCard';
import outpatientEquipmentStatusReducer, { initialState } from './OutpatientEquipmentStatusSlice';
import OutpatientEquipmentContainer from './OutpatientEquipmentContainer';
import ContextProvider from '@components/ContextProvider';
import OutpatientEquipmentStatusSlice from './OutpatientEquipmentStatusSlice';

export const OutpatientEquipmentStatusDispatchContext = React.createContext();
export const OutpatientEquipmentStatusStateContext = React.createContext();
export const useOutpatientEquipmentContext = () => useContext(OutpatientEquipmentStatusStateContext);
export const useOutpatientEquipmentDispatch = () => useContext(OutpatientEquipmentStatusDispatchContext);

const OutpatientEquipmentStatus = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();

    return (
        <ContextProvider
            StateContext={OutpatientEquipmentStatusStateContext}
            DispatchContext={OutpatientEquipmentStatusDispatchContext}
            slice={OutpatientEquipmentStatusSlice}
        >
            <WidgetCard ref={widgetRef} widgetInfo={widgetInfo} {...restProps}>
                <OutpatientEquipmentContainer widgetInfo={widgetInfo} />
                {children}
            </WidgetCard>
        </ContextProvider>
    );
};

export default OutpatientEquipmentStatus;
