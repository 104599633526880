import React, { useMemo, useState } from 'react';
import useRangeDate from '../hooks/useRangeDate';
import { Button, Label, Modal, QuestionTooltip, Select } from '@components';
import DatePickerGroupAM from '../../Components/DatePickerGroupAM';
import useMultiOptionsByRole from '../../util/useMultiOptionsByRole';
import lentImage from '@asset/images/asset-lent.png';
import returnImage from '@asset/images/asset-return.png';
import styled from 'styled-components';
import { getOwnerList, postAssetRental } from '@api/am/management';
import { useAppSelector, useAsync, useTranslation } from '@util/hooks';
import useModal from '../../../util/useModal';
import moment from 'moment';

const RentalRequest = ({ onSuccess = () => {} }) => {
    const t = useTranslation('AssetManagement');
    const [isOpen, handleToggle, handleOpen] = useModal();

    // 대여부서
    const { userWardList } = useMultiOptionsByRole();
    const userWardOptions = userWardList.map(({ groupNum, groupName }) => ({
        value: groupNum,
        label: groupName,
    }));
    const [selectedUserWard, setSelectedUserWard] = useState(userWardOptions[0]);

    // 카테고리
    const { categoryList } = useAppSelector(state => state.CategoryInfo);
    const categoryOptions = useMemo(
        () =>
            categoryList
                .filter(category => category.parentCode === 'ASSET') // 최상위의 하위 카테고리 추출
                .map(category => ({
                    label: category.categoryName,
                    value: category.categoryCode,
                })),
        [categoryList],
    );
    const [selectedCategory, setSelectedCategory] = useState(null);

    // 소유부서
    const { state } = useAsync({
        promise: getOwnerList,
        deps: [selectedCategory],
        immediate: selectedCategory,
        param: {
            categoryCode: selectedCategory?.value,
        },
    });
    const rows = state.response ? state.response.rows : [];
    const wardOptions = rows.map(({ ownDepNum, ownDepName }) => ({
        value: ownDepNum,
        label: ownDepName,
    }));
    const [selectedWard, setSelectedWard] = useState();

    // 대여기간
    const { startDate, handleStartChange, endDate, handleEndChange, handleDateReset } = useRangeDate({
        subStart: 0,
        subEnd: -1,
    });

    // 저장
    const { promise } = useAsync({
        promise: postAssetRental,
        param: {},
        resolve: () => {
            onSuccess();
        },
    });

    const handleSaveClick = () => {
        if (selectedUserWard.value === selectedWard.value) {
            handleOpen();
            return;
        }
        promise({
            categoryCode: selectedCategory.value,
            requestStartDate: startDate,
            requestEndDate: endDate,
            applyDepNum: selectedUserWard.value,
            ownDepNum: selectedWard.value,
        });
    };

    return (
        <>
            <Label
                name={t('Ward')}
                labelGroupClassName="mb-3"
                value={
                    <Select
                        value={selectedUserWard}
                        options={userWardOptions}
                        onChange={selected => {
                            setSelectedUserWard(selected);
                        }}
                        disabled={userWardOptions.length === 1}
                    />
                }
            />
            <Label
                name={t('Category Name')}
                labelGroupClassName="mb-3"
                value={
                    <Select
                        value={selectedCategory}
                        options={categoryOptions}
                        onChange={selected => {
                            setSelectedCategory(selected);
                            setSelectedWard(null);
                            handleDateReset();
                        }}
                    />
                }
            />
            <Label
                name={
                    <>
                        <span>{t('Owner Ward')}</span>
                        <QuestionTooltip
                            contents={
                                <div className="text-start">
                                    {t(
                                        'Displays the departments in which the selected category assets are available for rental.',
                                    )}
                                </div>
                            }
                        />
                    </>
                }
                labelGroupClassName="mb-3"
                value={
                    <Select
                        value={selectedWard}
                        options={wardOptions}
                        onChange={selected => {
                            setSelectedWard(selected);
                        }}
                        disabled={!selectedCategory}
                    />
                }
            />
            {selectedCategory && wardOptions.length === 0 && (
                <Label
                    labelGroupClassName="mb-3"
                    value={
                        <span className="pnt-txt s-9 color-primary">
                            {t('There are no departments available for rental.')}
                        </span>
                    }
                />
            )}
            <Label
                name={t('Rental Period')}
                labelGroupClassName="mb-3"
                value={
                    <DatePickerGroupAM
                        startDate={startDate}
                        handleStartChange={handleStartChange}
                        endDate={endDate}
                        handleEndChange={handleEndChange}
                        minStartDate={moment().valueOf()}
                    />
                }
            />
            <div className="d-flex justify-content-center">
                <Button className="btn-gray" disabled={!selectedWard} onClick={handleSaveClick}>
                    {t('Save')}
                </Button>
            </div>
            <ImageContainer>
                <img src={lentImage} alt="lent-example" />
                <img src={returnImage} alt="return-exmaple" />
            </ImageContainer>
            {isOpen && (
                <Modal
                    headerTitle={t('Notification')}
                    removeCancel
                    initModal={isOpen}
                    toggleModal={handleToggle}
                    bodyText={t('The application department and the owned department cannot be the same.')}
                />
            )}
        </>
    );
};

const ImageContainer = styled.div`
    width: 100%;
    margin-top: 2rem;
    display: flex;

    & > img {
        width: 50%;
    }
`;

export default RentalRequest;
