import React, { useState } from 'react';
import TabCardHeader from './Components/TabCardHeader';
import TabCardBody from './Components/TabCardBody';
import WidgetCard from '../../Components/WidgetCard';
import { MEDICAL_WASTE_TAB_LIST } from '../../constants';

// 의료 폐기물 처리 현황
const MedicalWasteTreatmentStatus = ({ children, widgetInfo, ...restProps }) => {
    const [selectedTab, setSelectedTab] = useState(MEDICAL_WASTE_TAB_LIST[0].name);

    return (
        <WidgetCard widgetInfo={widgetInfo} bodyClassName="p-0" {...restProps}>
            <TabCardHeader selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            <TabCardBody selectedTab={selectedTab} key={selectedTab} />
            {children}
        </WidgetCard>
    );
};

export default MedicalWasteTreatmentStatus;
