import React from 'react';
import useTranslation from '@hooks/useTranslation';
import WidgetSettingModal from '../../Components/WidgetSettingModal';

const RefrigeratorSensorSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('RefrigeratorSensor');
    return <WidgetSettingModal headerTitle={t('Refrigerator Sensor Setting')} widgetInfo={widgetInfo} {...restProps} />;
};

export default RefrigeratorSensorSetting;
