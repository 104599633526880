import React, { createContext, useContext } from 'react';
import ContextProvider from '@components/ContextProvider';
import ScannerStatusLogContainer from './ScannerStatusLogContainer';
import scannerStatusLogSlice from './scannerStatusLogSlice';

/**
 * 스캐너 상태 로그 위젯
 *
 * 추후 useAsync 커스텀 후 리팩토링 예정
 *
 * @author jinbeom-jung
 * */
export const ScannerStatusLogStateContext = createContext(null);
export const ScannerStatusLogDispatchContext = createContext(null);

export const useScannerStatusLogContext = () => useContext(ScannerStatusLogStateContext);
export const useScannerStatusLogDispatch = () => useContext(ScannerStatusLogDispatchContext);

const ScannerStatusLog = ({ children, widgetInfo, ...restProps }) => {
    return (
        <ContextProvider
            StateContext={ScannerStatusLogStateContext}
            DispatchContext={ScannerStatusLogDispatchContext}
            slice={scannerStatusLogSlice}
        >
            <ScannerStatusLogContainer widgetInfo={widgetInfo} children={children} {...restProps} />
        </ContextProvider>
    );
};

export default ScannerStatusLog;
