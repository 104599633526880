import React from 'react';
import { FilterList } from '../../../MainPages/Components/FilterSearchGroup/Components/Part';
import FilterGroup from '../../../MainPages/Components/FilterSearchGroup/Components/FilterGroup';
import FilterSearchGroup from '../../../MainPages/Components/FilterSearchGroup';
import { Button, DatePicker, SearchableSelect, Select } from '@components';
import { useTranslation } from '@hooks';
import cx from 'classnames';
import DatePickerGroupAM from './DatePickerGroupAM';
import { ASSET_MANAGEMENT } from '../../constants';
import { CalendarContainer } from 'react-datepicker';
import styled from 'styled-components';

const CalendarBottomGuide = ({ className, children }) => {
    const t = useTranslation(ASSET_MANAGEMENT);

    return (
        <div>
            <CalendarContainer className={className}>
                <div style={{ position: 'relative' }}>{children}</div>
            </CalendarContainer>
            <GuideWrapper className={'bg-depth-1'}>
                <div className={'d-flex gap-2 flex-center'}>
                    <GuideSymbol className={'bg-secondary'} />
                    <span className={'pnt-txt s-10'}>{t('Check Complete')}</span>

                    <GuideSymbol className={'bg-lightgray'} />
                    <span className={'pnt-txt s-10'}>{t('Unchecked')}</span>
                </div>
            </GuideWrapper>
        </div>
    );
};

const SearchGroupAM = ({
    wardListValues,
    categoryValues,
    rangeDateValues,
    // assetStatusValues,
    riskLevelValues,
    checkTypeValues,
    dutyValues,
    dateValues,
    renderDayContents,
    onMonthChange,
}) => {
    const t = useTranslation(ASSET_MANAGEMENT);

    const { selectedWardList, options: wardOptions, handleWardChange } = wardListValues || {};
    const { startDate, handleStartChange, endDate, handleEndChange } = rangeDateValues || {};
    const { selectedCategoryList, categoryOptions, handleCategoryChange } = categoryValues || {};
    const { riskLevelOptions, riskLevelList, handleRiskLevelChange } = riskLevelValues || {};
    const { checkTypeOptions, checkTypeList, handleCheckTypeChange } = checkTypeValues || {};
    const { dutyOptions, duty, handleDutyChange } = dutyValues || {};
    const { startDate: date, handleDateChange, handleTodayClick } = dateValues || {};

    return (
        <FilterSearchGroup className={cx('absolute-filter')}>
            <FilterGroup label={t('Filter', 'Filter')}>
                <FilterList>
                    <SearchableSelect
                        title={t('Ward', 'CommonColumn')}
                        data={wardOptions}
                        selected={selectedWardList}
                        onChange={handleWardChange}
                    />
                    {rangeDateValues && (
                        <DatePickerGroupAM
                            startDate={startDate}
                            handleStartChange={handleStartChange}
                            endDate={endDate}
                            handleEndChange={handleEndChange}
                        />
                    )}
                    {categoryValues && (
                        <SearchableSelect
                            title={t('Category')}
                            data={categoryOptions}
                            selected={selectedCategoryList}
                            onChange={handleCategoryChange}
                        />
                    )}
                    {dateValues && (
                        <div className="d-flex">
                            <DatePicker
                                value={date}
                                renderDayContents={renderDayContents}
                                onMonthChange={onMonthChange}
                                onChange={handleDateChange}
                                withoutTime
                                valueType="s"
                                disabledKeyboardNavigation
                                calendarContainer={onMonthChange ? CalendarBottomGuide : null}
                            />
                            <Button className="ml-1 btn-secondary" onClick={handleTodayClick}>
                                {t('Today')}
                            </Button>
                        </div>
                    )}
                    {dutyValues && <Select value={duty} options={dutyOptions} onChange={handleDutyChange} />}
                    {/* {assetStatusValues && (
                        <SearchableSelect
                            title={t('Asset Status')}
                            selected={selectedStatus}
                            data={statusOptions}
                            onChange={handleStatusChange}
                        />
                    )} */}
                    {riskLevelValues && (
                        <SearchableSelect
                            title={t('Risk Level')}
                            data={riskLevelOptions}
                            selected={riskLevelList}
                            onChange={handleRiskLevelChange}
                        />
                    )}
                    {checkTypeValues && (
                        <SearchableSelect
                            title={t('Check Type')}
                            data={checkTypeOptions}
                            selected={checkTypeList}
                            onChange={handleCheckTypeChange}
                        />
                    )}
                </FilterList>
            </FilterGroup>
        </FilterSearchGroup>
    );
};

export default SearchGroupAM;

const GuideWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    height: 2rem;
    position: relative;
`;
const GuideSymbol = styled.div`
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
`;
