import { getApiURL } from '@api/index';
import { getUuid } from '@util/common/util';
import socketio from 'socket.io-client';
import { SOCKET_LEGACY_INSTANCE } from '@util/symbol/window';
import { SocketEventHandler } from '@util/socket/hooks/useSocket';
import { removeSocket } from '@reducer/SocketInfo';

type MessageType = 'updateMarker' | 'notification' | 'changeSensorValue';

const useLegacySocket = () => {
    const initializeSocket = () => {
        if (!window[SOCKET_LEGACY_INSTANCE]) {
            getApiURL().then(({ wmsSocketUrl }) => {
                const uuid = getUuid();
                window[SOCKET_LEGACY_INSTANCE] = socketio(wmsSocketUrl, {
                    transports: ['websocket'],
                    forceNew: true,
                    reconnection: true,
                    reconnectionAttempts: 5,
                    reconnectionDelay: 5000,
                });
                const socket = window[SOCKET_LEGACY_INSTANCE] as SocketIOClient.Socket;
                socket.on('connect', function () {
                    console.log('LEGACY_SOCKET_CONNECTED : ', wmsSocketUrl);
                    socket.emit('join', `presence-${uuid}`);
                });
                socket.on('disconnect', function () {
                    console.log('LEGACY_SOCKET_DISCONNECT : ', wmsSocketUrl);
                });
                socket.on('reconnect', function () {
                    console.log('LEGACY_SOCKET_RECONNECTED : ', wmsSocketUrl);
                    socket.emit('join', `presence-${uuid}`);
                });
            });
        }
    };

    const closeSocket = () => {
        removeSocket(SOCKET_LEGACY_INSTANCE);
    };

    const setSocketEvent = <DataType = object>(messageType: MessageType, callback: SocketEventHandler<DataType>) => {
        if (window[SOCKET_LEGACY_INSTANCE]) {
            (window[SOCKET_LEGACY_INSTANCE] as SocketIOClient.Socket).on(messageType, callback);
        }
    };

    const removeSocketEvent = <DataType = object>(
        messageType: MessageType,
        callback?: SocketEventHandler<DataType>,
    ) => {
        if (window[SOCKET_LEGACY_INSTANCE]) {
            (window[SOCKET_LEGACY_INSTANCE] as SocketIOClient.Socket).off(messageType, callback);
        }
    };

    return {
        socket: window[SOCKET_LEGACY_INSTANCE],
        initializeSocket,
        closeSocket,
        setSocketEvent,
        removeSocketEvent,
    };
};

export default useLegacySocket;
