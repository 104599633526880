import React from 'react';
import InnerItemCard from '@components/Card/InnerItemCard';
import { useTranslation } from '@hooks';
import { MEDICAL_WASTE } from '../../../constants';
import { Bar, Line } from 'react-chartjs-2';
import { CHART_COLORS } from '@components/Charts/config';

const lineChartOptions = {
    plugins: {
        legend: {
            position: 'bottom',
        },
    },
    scales: {
        x: {
            offset: true,
        },
        y: {
            ticks: {
                stepSize: 10,
            },
        },
    },
};

const barChartOptions = {
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        y: {
            ticks: {
                stepSize: 10,
            },
        },
    },
};

const LABEL_LIST = [
    {
        labelName: 'Isolated medical',
        dataName: 'isolatedMedical',
    },
    {
        labelName: 'Normal medical',
        dataName: 'normalMedical',
    },
    {
        labelName: 'Tissue',
        dataName: 'tissue',
    },
    {
        labelName: 'Damage',
        dataName: 'damage',
    },
    {
        labelName: 'Pathology',
        dataName: 'pathology',
    },
    {
        labelName: 'Biochemistry',
        dataName: 'biochemistry',
    },
    {
        labelName: 'Blood contamination',
        dataName: 'bloodContamination',
    },
    {
        labelName: 'Human tissue',
        dataName: 'humanTissue',
    },
];

const ChartList = ({ chartData, selectedTab }) => {
    const t = useTranslation(MEDICAL_WASTE);

    const lineChartData = {
        labels: chartData.map(ele => ele.date),
        datasets: LABEL_LIST.map(({ labelName, dataName }, i) => ({
            label: t(labelName),
            data: chartData.map(ele => ele[dataName]),
            borderColor: CHART_COLORS[i],
            backgroundColor: CHART_COLORS[i],
        })),
    };

    const barChartData = {
        labels: chartData.map(ele => ele.date),
        datasets: [
            {
                data: chartData.map(ele => ele.wasteCount),
                backgroundColor: CHART_COLORS[0],
            },
        ],
    };

    return (
        <div className="d-flex gap-3">
            <InnerItemCard header={{ title: t('Total Collection Volume By Waste Type') }} className="w-50">
                <Line options={lineChartOptions} data={lineChartData} />
            </InnerItemCard>
            <InnerItemCard
                header={{
                    title: `${t(selectedTab.charAt(0).toUpperCase() + selectedTab.slice(1))} ${t(
                        'Total Collection Volume',
                    )}`,
                }}
                className="w-50"
            >
                <Bar options={barChartOptions} data={barChartData} />
            </InnerItemCard>
        </div>
    );
};

export default ChartList;
