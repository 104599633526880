import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Nouislider from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';
import styled from 'styled-components';
import cx from 'classnames';

import { dateToFormat } from '@util/common/util';
import useTranslation from '@hooks/useTranslation';
import Button from '@components/Button';

import { useHeatmapContext, useHeatmapDispatch } from '../index';
import {
    setPlay,
    setPlayTime,
    setRepeatSectionEnd,
    setRepeatSectionStart,
    setResultHeatmapClear,
    setSliderUpdate,
    setStartTime,
    setTargetNum,
} from '../heatMapSlice';

export const PlayControllerBar = () => {
    const dispatch = useHeatmapDispatch();
    const { play, playTime, startTime, endTime } = useHeatmapContext();
    const slideThrottle = useRef();
    const tempPlayState = useRef(null);

    return (
        <StyledNouislider
            className="tracing-play-bar"
            animate={false}
            connect={[true, false]}
            step={1}
            start={[playTime]}
            range={{ min: startTime, max: endTime }}
            onSlide={(render, handle, [value]) => {
                if (play) {
                    tempPlayState.current = true;
                    dispatch(setPlay(false));
                } else {
                    tempPlayState.current = false;
                }
                dispatch(setSliderUpdate(value));
                dispatch(setPlayTime(value));
                if (slideThrottle.current) {
                    clearTimeout(slideThrottle.current);
                }
                slideThrottle.current = setTimeout(() => {
                    dispatch(setSliderUpdate(value));
                    slideThrottle.current = null;
                    if (tempPlayState.current) {
                        dispatch(setPlay(true));
                    }
                }, 200);
            }}
        />
    );
};

const PlayController = ({ play, pause }) => {
    const t = useTranslation('Button');
    const dispatch = useHeatmapDispatch();
    const { play: playState, playTime, startTime, endTime, detectedTargets } = useHeatmapContext();
    const allTarget = useMemo(() => detectedTargets?.map(({ targetNum }) => targetNum), [detectedTargets]);

    useEffect(() => {
        dispatch(setTargetNum(allTarget));
    }, [allTarget]);

    const handlePlayClick = () => {
        if (!playState) {
            if (playTime >= endTime) {
                dispatch(setResultHeatmapClear());
                dispatch(setStartTime(startTime));
                dispatch(setPlayTime());
            }
            if (!!detectedTargets.length) {
                play();
            }
        } else {
            pause();
        }
    };

    useEffect(() => {
        if (playTime >= endTime) {
            pause();
        }
    }, [playTime]);

    const [repeatSectionStartButtonState, setRepeatSectionStartButtonState] = useState(true);
    const [repeatSectionEndButtonState, setRepeatSectionEndButtonState] = useState(false);

    const handleRepeatSectionStartTimeSetting = () => {
        dispatch(setRepeatSectionStart({ status: true, playTime }));
        setRepeatSectionStartButtonState(!repeatSectionStartButtonState);
        setRepeatSectionEndButtonState(!repeatSectionEndButtonState);
    };

    const handleRepeatSectionEndTimeSetting = () => {
        dispatch(setRepeatSectionEnd({ status: true, playTime }));
        setRepeatSectionEndButtonState(!repeatSectionEndButtonState);
    };

    const handleRepeatSectionSetting = () => {
        dispatch(setRepeatSectionStart({ status: false, playTime }));
        dispatch(setRepeatSectionEnd({ status: false, playTime }));
        setRepeatSectionStartButtonState(true);
        setRepeatSectionEndButtonState(false);
    };

    const handleShowAllClick = () => {
        dispatch(setPlayTime(endTime));
        dispatch(setSliderUpdate(endTime));
    };

    return (
        <div className="play-body-custom w-100 h-25">
            <div className="body-container">
                <div className={cx('', !detectedTargets.length && 'control-disabled')}>
                    <div className="mb-2 row-custom">
                        <PlayControllerBar />
                        <div className={'flx-sb mb-3 p-0'}>
                            <span className="play-time">
                                {dateToFormat(startTime * 1000, 'START_MONTH_2').replace(' ', ' / ')}
                            </span>
                            <span className="play-time">
                                {dateToFormat(playTime * 1000, 'START_MONTH_2').replace(' ', ' / ')}
                            </span>
                            <span className="play-time">
                                {dateToFormat(endTime * 1000, 'START_MONTH_2').replace(' ', ' / ')}
                            </span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mx-1">
                        <Button
                            className="play-button btn-darkgray btn-icon-only br-4"
                            iconName={`${!playState ? 'play_arrow' : 'pause'}`}
                            onClick={handlePlayClick}
                        />
                        <div className="d-flex gap-1">
                            <Button className="show-all-button btn-secondary p-3" onClick={handleShowAllClick}>
                                {t('Show All')}
                            </Button>
                            {repeatSectionStartButtonState && (
                                <Button className="btn-secondary p-3" onClick={handleRepeatSectionStartTimeSetting}>
                                    {t('Start of repeat section')}
                                </Button>
                            )}
                            {repeatSectionEndButtonState && (
                                <Button className=" btn-secondary p-3" onClick={handleRepeatSectionEndTimeSetting}>
                                    {t('Cancel repeat section')}
                                </Button>
                            )}
                            {!repeatSectionStartButtonState && !repeatSectionEndButtonState && (
                                <Button className="btn-secondary p-3" onClick={handleRepeatSectionSetting}>
                                    {t('End of repeat section')}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const StyledNouislider = styled(Nouislider)`
    & .noUi-base {
        background-color: #dcd9e1 !important;
    }
    & .noUi-handle {
        cursor: pointer;
    }
    & .noUi-connect {
        background-color: #6748e3 !important;
    }
`;

export default PlayController;
