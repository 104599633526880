import Nameplate from '@components/Nameplate';
import React from 'react';
import styled from 'styled-components';

const CategoryTag = ({ ward, visible }) => {
    return (
        <Container>
            {visible && (
                <Nameplate className={'d-inline-flex shadow-none'} icon={'apartment'} name={ward}>
                    {ward}
                </Nameplate>
            )}
        </Container>
    );
};

const Container = styled.div`
    min-height: 32px;
    margin-bottom: 0.625rem;
`;

export default CategoryTag;
