import React, { useState } from 'react';
import moment from 'moment';

import Battery from './Components/Battery';
import SensorInfo from './Components/SensorInfo';
import SensorStatus from './Components/SensorStatus';
import { useRefrigeratorSensorContext, useRefrigeratorSensorDispatch } from '../../index';
import { setSensorNum } from '../../refrigeratorSensorSlice';
import useTranslation from '@hooks/useTranslation';
import Bookmark from '../../../../Components/Bookmark';
import Modal from '@components/Modal';
import RangeBar from './Components/RangeBar';
import Button from '@components/Button';
import InnerItemCard from '@components/Card/InnerItemCard';
import NoDataBlock from '../../../../Components/NoDataBlock';
import { REFRIGERATOR_DASHBOARD_TYPE } from '../../RefrigeratorSensorContainer';

const RefrigeratorSensorCard = ({ handleDashboardType, refreshCallback }) => {
    const t = useTranslation('RefrigeratorSensor');
    const modalT = useTranslation('ConfirmModal');
    const { refrigeratorSensorList } = useRefrigeratorSensorContext();
    const dispatch = useRefrigeratorSensorDispatch();

    // 상세보기 button 이벤트
    const handleDetailViewButton = sensorNum => {
        dispatch(setSensorNum(sensorNum));
        handleDashboardType(REFRIGERATOR_DASHBOARD_TYPE.DETAIL);
    };

    // 월간대장 button 이벤트
    const handleMonthlyLogButton = sensorNum => {
        dispatch(setSensorNum(sensorNum));
        handleDashboardType(REFRIGERATOR_DASHBOARD_TYPE.MONTHLY);
    };

    // bookmark error modal
    const [bookmarkErrorModal, setBookmarkErrorModal] = useState(false);
    const toggleBookmarkErrorModal = () => setBookmarkErrorModal(!bookmarkErrorModal);
    const bookmarkErrorModalOkCallback = refreshCallback;
    // bookmark 호출 후 event handler
    const handleBookmarkSuccess = refreshCallback;
    const handleBookmarkFailure = () => {
        setBookmarkErrorModal(true);
    };

    return (
        <>
            {!!refrigeratorSensorList.length ? (
                <div className="grid-container item-grid-250">
                    {refrigeratorSensorList.map(
                        (
                            {
                                batteryStatus,
                                batteryUnit,
                                batteryVal,
                                modDate,
                                isBookmark,
                                sensorNum,
                                sensorName,
                                targetName,
                                targetNum,
                                maxControlLimit,
                                maxRangeLimit,
                                maxRiskLimit,
                                minControlLimit,
                                minRangeLimit,
                                minRiskLimit,
                                temperatureStatus,
                                temperatureUnit,
                                temperatureVal,
                                lostSignalState,
                            },
                            idx,
                        ) => {
                            return (
                                <InnerItemCard
                                    key={`${targetNum}_${idx}`}
                                    header={{
                                        title: targetName,
                                        subTitle: sensorName,
                                        action: (
                                            <>
                                                <Button
                                                    className="btn-icon-only btn-trans"
                                                    iconName="trending_up"
                                                    iconClassName="material-icons-round color-gray"
                                                    onClick={() => handleDetailViewButton(sensorNum)}
                                                />
                                                <Button
                                                    className="btn-icon-only btn-trans"
                                                    iconName="content_paste"
                                                    iconClassName="material-icons-round color-gray"
                                                    onClick={() => handleMonthlyLogButton(sensorNum)}
                                                />
                                                <Bookmark
                                                    successCallback={handleBookmarkSuccess}
                                                    failureCallback={handleBookmarkFailure}
                                                    targetNum={targetNum}
                                                    isBookmark={isBookmark}
                                                ></Bookmark>
                                            </>
                                        ),
                                    }}
                                >
                                    <div>
                                        <div className="flx-row align-items-start mt-1">
                                            <SensorStatus status={temperatureStatus} lostSignal={lostSignalState} />
                                            <div className="ml-3 flx-1 flex-column flx-sb align-start">
                                                <div className="pnt-txt s-9 color-gray">
                                                    {t('Temperature')}({temperatureUnit})
                                                </div>
                                                <SensorInfo
                                                    value={temperatureVal}
                                                    status={temperatureStatus}
                                                    lostSignal={lostSignalState}
                                                />
                                                <RangeBar
                                                    currentTemperature={temperatureVal}
                                                    range={{
                                                        minRangeLimit,
                                                        minRiskLimit,
                                                        minControlLimit,
                                                        maxControlLimit,
                                                        maxRiskLimit,
                                                        maxRangeLimit,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="flx-row justify-content-between align-items-end opacity-8 mt-1">
                                            <Battery value={batteryVal} unit={batteryUnit} />
                                            <p className="pnt-txt s-10">
                                                {!!modDate
                                                    ? moment(modDate * 1000).format('YYYY-MM-DD (HH:mm:ss)')
                                                    : '- ( - )'}
                                            </p>
                                        </div>
                                    </div>
                                </InnerItemCard>
                            );
                        },
                    )}
                </div>
            ) : (
                <NoDataBlock />
            )}
            <Modal
                initModal={bookmarkErrorModal}
                toggleModal={toggleBookmarkErrorModal}
                headerTitle={t('Bookmark')}
                bodyText={modalT('Something has changed.')}
                removeCancel
                okCallback={bookmarkErrorModalOkCallback}
            />
        </>
    );
};

export default RefrigeratorSensorCard;
