import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    selectedOutpatientCareStatus: null,
    keyword: '',
    selectedOutpatient: null,
    outpatientList: {
        totalCount: 0,
        totalPage: 1,
        rows: [],
    },
    outpatientListParam: {
        pageSize: 15,
        page: 1,
        appointmentOption: null,
        // department: null,
        keyword: undefined,
    },
    outpatientCareCount: {
        total: 0,
        arrivalCountStat: {
            arrival: 0,
            nonArrival: 0,
        },
        waitingCountStat: {
            waitingCare: 0,
            nonWaitingCare: 0,
        },
        doneCountStat: {
            doneCare: 0,
            nonDoneCare: 0,
        },
    },
};

const outpatientCareStatusMonitoringSlice = createSlice({
    name: 'outpatientCareStatus',
    initialState,
    reducers: {
        setSelectedOutpatientStatus: (state, action) => {
            const selectedStatus = action.payload;
            if (state.selectedOutpatientCareStatus === selectedStatus) {
                return;
            }
            state.selectedOutpatientCareStatus = selectedStatus;
            state.outpatientListParam = {
                ...state.outpatientListParam,
                appointmentOption: state.selectedOutpatientCareStatus,
            };
        },
        setSearchOutpatient: (state, action) => {
            const keyword = action.payload ? action.payload : undefined;
            state.keyword = keyword;
            state.outpatientListParam = {
                ...state.outpatientListParam,
                keyword: state.keyword,
            };
        },
        setSelectedOutpatient: (state, action) => {
            const selectedOutpatient = action.payload;
            if (!selectedOutpatient) {
                state.selectedOutpatient = null;
                return;
            }
            state.selectedOutpatient = selectedOutpatient;
        },
        setOutpatientListParam: (state, action) => {
            state.outpatientListParam = action.payload;
        },
        clearOutpatientCare: (state, action) => {
            state.keyword = '';
            state.selectedOutpatientCareStatus = null;
            state.outpatientListParam = {
                ...state.outpatientListParam,
                keyword: undefined,
                appointmentOption: null,
            };
        },
    },
});

export const {
    setSearchOutpatient,
    setSelectedOutpatientStatus,
    setOutpatientListParam,
    setSelectedOutpatient,
    clearOutpatientCare,
} = outpatientCareStatusMonitoringSlice.actions;

export default outpatientCareStatusMonitoringSlice;
