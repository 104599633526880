import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Table } from '@components';
import { useTranslation } from '@hooks';
import { MEDICAL_WASTE } from '../../../constants';

const WASTE_TYPE_LABEL = {
    isolatedMedical: 'Isolated medical',
    normalMedical: 'Normal medical',
    tissue: 'Tissue',
    damage: 'Damage',
    pathology: 'Pathology',
    biochemistry: 'Biochemistry',
    bloodContamination: 'Blood contamination',
    humanTissue: 'Human tissue',
};

const CountTable = ({ selectedTab, rows }) => {
    const t = useTranslation(MEDICAL_WASTE);

    const columns = useMemo(
        () => [
            {
                Header: t(selectedTab.charAt(0).toUpperCase() + selectedTab.slice(1)),
                accessor: 'date',
            },
            {
                Header: t('Ward'),
                accessor: 'wardName',
            },
            {
                Header: t('Waste Type'),
                accessor: 'wasteType',
                Cell: ({ row }) => <div>{t(WASTE_TYPE_LABEL[row.original.wasteType])}</div>,
            },
            {
                Header: t('Before Collect'),
                accessor: 'beforeCollect',
            },
            {
                Header: t('Collect Completed'),
                accessor: 'collectCompleted',
            },
            {
                Header: t('Weight'),
                accessor: 'weight',
            },
        ],
        [selectedTab, t],
    );

    return (
        <TableWrapper>
            <Table data={{ rows }} columns={columns} paging={false} />
        </TableWrapper>
    );
};

const TableWrapper = styled.div`
    height: 40rem;
`;

export default CountTable;
