import React, { useEffect, useRef } from 'react';
import { Portal } from 'react-overlays';
import { useRouteMatch, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import AppFooter from './AppFooter';
import LocationPopup from './AppHeader/Components/LocationPopup';

import { ConfirmModal } from '@components';
import { MainRouter } from '../MainPages/Components/Router';

import { useAppSelector, useAsync, useLicenseCheck, useTranslation } from '@hooks';
import { handleSelectedNotificationTypes } from '@reducer/Notification';
import { useUserTokenCheck } from '@hooks/utilHooks';
import { getLsUserInfo } from '@util/common/util';
import {
    logOut,
    setAdditionalInfo,
    setLanguage,
    setNotificationTypes,
    setOauthInfo,
    setOauthStatusModal,
} from '@reducer/UserInfo';
import { fetchMenuList } from '@api/common/menu';
import { setMenuList } from '@reducer/CustomMenu';
import { setColorScheme } from '@reducer/ThemeOptions';
import NotificationToast from '../MainPages/Components/Alert/NotificationToast';
import useNotification from '@hooks/useNotification';
import { A_PATH_EMBED_CUSTOM_SCREEN } from '../MainPages/Components/Router/path';
import NotificationSound from '../MainPages/Components/Alert/NotificationSound';
import EmergencyAlertModal from '../MainPages/Components/Alert/EmergencyAlertModal';
import TemporaryPasswordModal from '../MainPages/Components/TemporaryPasswordModal';
import useEmergencyAlert from '@hooks/EmergencyAlert/useEmergencyAlert';
import useInitSocket from '@util/socket/hooks/useInitSocket';
import useAppStaticInfo from '@hooks/useAppStaticInfo';
import { useDocumentVisibility } from '@hooks/useVisibility';
import AppInitialLoadingPage from './AppInitialLoadingPage';
import FailedLoadSettingScreen from '../MainPages/Components/Screen/FailedLoadSettingScreen';

const AppMain = () => {
    const dispatch = useDispatch();

    // embed 화면 체크용
    const embedMatch = useRouteMatch(A_PATH_EMBED_CUSTOM_SCREEN);

    const { userInfo, modal } = useAppSelector(state => state.UserInfo);
    const { loadedMenu } = useAppSelector(state => state.CustomMenu);

    const t = useTranslation('Login');
    const appMainOuterRef = useRef();
    const portal = document.body;

    const { promise: getMenuList } = useAsync({
        promise: fetchMenuList,
        fixedParam: { isAll: 'Y', includeScreenInfo: 'Y', groupNum: userInfo.authGroupNum },
        resolve: res => {
            dispatch(setMenuList(res.rows ?? []));
        },
        reject: err => {
            dispatch(setMenuList([]));
            console.log({ err });
        },
    });

    // 화면 리프레쉬 후 유저의 정보가 사라졌을때
    // 로컬스토리지에 저장된 정보 호출 후 저장
    useEffect(() => {
        if (!userInfo.userName) {
            const { oAuthInfo, userInfo: lsUserInfo } = getLsUserInfo();
            dispatch(setOauthInfo(oAuthInfo));
            if (lsUserInfo) {
                const { lang, notificationTypes, propValue } = lsUserInfo;
                dispatch(setAdditionalInfo(lsUserInfo));
                dispatch(setLanguage(lang));
                dispatch(setNotificationTypes(notificationTypes));
                dispatch(handleSelectedNotificationTypes(notificationTypes));
                dispatch(setColorScheme(propValue));
            }
        }
    }, [userInfo]);

    useEffect(() => {
        if (userInfo.roleNum) {
            getMenuList({ roleNum: userInfo.roleNum });
        }
    }, [userInfo.roleNum, userInfo.authGroupNum]);

    // refresh token의 에러가 발생했을 시 토글 메뉴 실행 후 유저인포 초기화
    // 유저인포의 초기화로 인하여 로그인 페이지로 이동
    const toggleOauthStatus = () => {
        dispatch(logOut());
        dispatch(setOauthStatusModal(!modal.modalOauthStatusOpen));
    };

    useUserTokenCheck();
    useLicenseCheck();

    useInitSocket();

    useNotification();
    useEmergencyAlert();

    const { isLoading: integrationServiceApiLoading, error: integrationServiceApiError } = useAppStaticInfo();

    useDocumentVisibility();

    return (
        <>
            {!embedMatch && <AppHeader />}
            <div className="app-main">
                {/* sidebar */}
                {!embedMatch && <AppSidebar />}
                {/* main */}
                <div className="app-main__outer" ref={appMainOuterRef}>
                    <div className="app-main__inner">
                        {/* 메뉴 로딩이 완료되기전에 라우터를 랜더링 시키면,
                             url로 바로 접근 시, 메뉴 라우터가 랜더링 되기 전이므로 '/no-authority' 로 리다이렉트 됨
                             '/no-authority' 라우터만 다시 그리는 방법도 있음 */}
                        {integrationServiceApiLoading ? (
                            <AppInitialLoadingPage />
                        ) : integrationServiceApiError ? (
                            <FailedLoadSettingScreen />
                        ) : (
                            loadedMenu && <MainRouter embedPath={embedMatch?.url} />
                        )}
                        <ConfirmModal
                            initModal={modal.modalOauthStatusOpen}
                            toggleModal={toggleOauthStatus}
                            header={{ title: t('Authentication Error') }}
                            confirmText={
                                <span>{t('The authentication information is incorrect. Please log in again.')}</span>
                            }
                            okCallback={toggleOauthStatus}
                            removeCancel={true}
                        />
                        <Portal container={portal}>
                            <LocationPopup appMainOuterRef={appMainOuterRef} />
                        </Portal>
                        <div id="select-container" />
                    </div>
                </div>
                {/* footer */}
                {!embedMatch && <AppFooter />}
            </div>
            <NotificationToast />
            <NotificationSound />
            <EmergencyAlertModal />
            <TemporaryPasswordModal />
        </>
    );
};

export default withRouter(AppMain);
