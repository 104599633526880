import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';

import { Table } from '@components';
import { useAppSelector, useAsync, useConfirmModal, useTranslation } from '@hooks';
import { arraysAreEqual } from '@util/common/util';
import { RowPropGetterType } from '@components/Table/types';
import { usePatientsTableColumns } from '../../columns';
import ContextMenu, { useContextmenu } from './Contextmenu';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_NUMERIC_SENSING, EVENT_TYPE_SUBJECT_PATIENT_DATA } from '@reducer/SocketInfo';
import { RealtimeIoTItemNumericSensingData, RealtimeSubjectPatientData } from '@util/socket/socketData';
import {
    setSelectedMappingSubjectNum,
    setSelectedSubject,
    setToggleMappingModal,
    useExamPatientsMonitoringDispatchContext,
} from '../../slice';
import styles from '@asset/pnt/widgets/sh/ExamPatientsMonitoring.module.scss';
import Button from '@components/Button';
import SensorMappingModal from '../SensorMappingModal';
import { Patient, PatientListInfo, SensorType, SensorValues } from '../../types';
import { createTestTargetPatientApi, getTestTargetPatientListApi } from '@api/sh/examPatientMonitoring';
import useDebounce from '@hooks/useDebounce';
import { getEndOfDate, getStartOfDate } from '@util/date';
import moment from 'moment';
import { Nullable, NullableUnixTimestamp } from '@util/type/util';
import { getCurrentElapsedTime, isMapped, secToMin } from '../../util';
import { isMonitoring } from '@reducer/ScreenInfo';
import { getPatientListApi } from '../../sampleData';

type AutoElapsedTime = { subjectNum: number; elapsedTime: number; startDate?: NullableUnixTimestamp };

export const SAMPLE_STATUS = ['WARNING', 'CRITICAL', 'OUTOFRANGE', 'undefined'];

const PAGE_SIZE = 50;

export const initialTableData = { rows: [], pageSize: PAGE_SIZE };

const SENSOR_TYPE_ZERO_TO_KEEP: SensorType[] = ['HEARTBEAT', 'SPO2'];
const getKeepSensorType = (sensorValues?: Nullable<SensorValues>) => {
    return SENSOR_TYPE_ZERO_TO_KEEP.filter(sensorType => sensorValues?.[sensorType] === 0);
};

const PatientsTable = ({ viewAllLists }: { viewAllLists: boolean }) => {
    const t = useTranslation('ExamPatientsMonitoring');
    const mode = useAppSelector(state => state.ScreenInfo.mode);
    const dispatch = useExamPatientsMonitoringDispatchContext();
    const { contextmenu, setContextmenu, toggleContextmenu } = useContextmenu();

    // 센서 소켓 필터 조건 변경을 위해 대상키만 따로 관리
    const [targetNums, setTargetNums] = useState<number[]>([]);
    const targetNumsRef = useRef<number[]>([]);

    // 진행 시간 증가를 위해 상태
    const autoElapsedTimeRef = useRef<AutoElapsedTime[]>();
    const timerRef = useRef<{ interval: number; now: moment.Moment }>({ interval: -1, now: moment() });

    // 최종 화면 반영 데이터
    const [tableData, setTableData] = useState<PatientListInfo>(initialTableData);

    const columns = usePatientsTableColumns();

    const { promise: getTestTargetPatientList, state } = useAsync({
        promise: isMonitoring(mode) ? getTestTargetPatientListApi : getPatientListApi,
        fixedParam: viewAllLists
            ? {
                  pageSize: PAGE_SIZE,
              }
            : {
                  startDate: getStartOfDate(),
                  endDate: getEndOfDate(),
                  isAll: 'Y',
              },
        immediate: true,
        resolve: response => {
            let newTableData = { ...initialTableData };
            if (response?.rows) {
                response.rows.sort((a: Patient, b: Patient) => b.subjectNum - a.subjectNum);
                newTableData = {
                    ...newTableData,
                    ...response,
                    rows: response.rows.map((patient: Patient) => {
                        const monitoringData = {
                            ...patient,
                            elapsedTime: getCurrentElapsedTime(patient),
                        };
                        getKeepSensorType(patient.recentSensorValues).forEach(sensorType => {
                            monitoringData.recentSensorValues![sensorType] = null;
                            monitoringData.recentSensorStates![sensorType] = null;
                        });
                        return monitoringData;
                    }),
                };
            }
            setTableData(newTableData);
        },
    });

    const { toggleModal: createFailedToggle, Modal: FailedAlertModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Failed to Enroll your research subjects.'),
    });

    const { promise: createTestTargetPatient } = useAsync({
        promise: createTestTargetPatientApi,
        resolve: res => {
            if (res.pkValue) {
                dispatch(setSelectedMappingSubjectNum({ subjectNum: res.pkValue }));
                dispatch(setToggleMappingModal({}));
            }
        },
        reject: () => {
            createFailedToggle();
        },
    });

    const handleDebounceCreatePatientClick = useDebounce(() => createTestTargetPatient(), 1000);

    const getRowProps = ({ row, style }: RowPropGetterType<Patient>) => {
        return {
            style,
            onMouseEnter: ({ currentTarget, target, clientX }: MouseEvent) => {
                // 액션 컬럼 제외
                if (!(target as HTMLElement).classList.contains('disable-hover')) {
                    const { top } = currentTarget.getBoundingClientRect();
                    setContextmenu({ open: true, x: clientX, y: top, row });
                }
            },
            onMouseLeave: (e: MouseEvent) => {
                const relatedTarget = e.relatedTarget as HTMLElement;
                if (
                    !relatedTarget?.classList?.contains('dropdown') &&
                    !relatedTarget?.classList?.contains('dropdown-menu') &&
                    !relatedTarget?.classList?.contains('dropdown-item')
                ) {
                    setContextmenu(prevState => {
                        return {
                            ...prevState,
                            open: false,
                            row: {},
                        };
                    });
                }
            },
        };
    };

    const sensingSocketFilterConfig = useMemo(() => {
        return {
            target: {
                targetNum: {
                    $in: targetNums,
                },
            },
        };
    }, [targetNums]);

    useSocketEvent({
        name: EVENT_TYPE_NUMERIC_SENSING,
        filterConfig: sensingSocketFilterConfig,
        handler: data => {
            const sensingData = data as RealtimeIoTItemNumericSensingData;
            setTableData(prevState => {
                return {
                    ...prevState,
                    rows: prevState.rows.map((patient: Patient) => {
                        if (patient.targetNum === sensingData.target.targetNum && isMapped(patient)) {
                            const newMonitoringData: Patient = {
                                ...patient,
                                battery: sensingData.sensingValues.BATTERY ?? patient.battery,
                                recentSensorValues: { ...sensingData.sensingValues },
                                recentSensorStates: { ...sensingData.sensingState },
                                regDate: sensingData.unixTime,
                            };
                            getKeepSensorType(sensingData.sensingValues).forEach(sensorType => {
                                newMonitoringData.recentSensorValues![sensorType] = patient.recentSensorValues
                                    ? patient.recentSensorValues[sensorType]
                                    : null;
                                newMonitoringData.recentSensorStates![sensorType] = patient.recentSensorStates
                                    ? patient.recentSensorStates[sensorType]
                                    : null;
                            });
                            return newMonitoringData;
                        }
                        return { ...patient };
                    }),
                };
            });
        },
    });

    const patientSocketFilterConfig = useMemo(() => {
        return {};
    }, []);

    useSocketEvent({
        name: EVENT_TYPE_SUBJECT_PATIENT_DATA,
        filterConfig: patientSocketFilterConfig,
        handler: data => {
            const {
                subjectPatientData: { comNum, ...subjectPatientData },
            } = data as RealtimeSubjectPatientData;
            if (subjectPatientData) {
                setTableData(prevState => {
                    // 대상 삭제
                    if (subjectPatientData.progressType === 'D') {
                        return {
                            ...prevState,
                            rows: prevState.rows.filter(
                                patient => subjectPatientData.subjectNum !== patient.subjectNum,
                            ),
                        };
                    }
                    // 새로운 대상 등록
                    if (!prevState.rows.find(patient => subjectPatientData.subjectNum === patient.subjectNum)) {
                        return {
                            ...prevState,
                            rows: [subjectPatientData, ...prevState.rows],
                        };
                    }
                    return {
                        ...prevState,
                        rows: prevState.rows.map(patient => {
                            if (subjectPatientData.subjectNum === patient.subjectNum) {
                                const newPatientData = {
                                    ...patient,
                                    ...subjectPatientData,
                                    fcNum: subjectPatientData.fcNum ?? patient.fcNum,
                                    fcName:
                                        subjectPatientData.fcNum && subjectPatientData.fcName
                                            ? subjectPatientData.fcName
                                            : patient.fcName,
                                    elapsedTime: getCurrentElapsedTime(subjectPatientData),
                                } as Patient;
                                if (!isMapped(subjectPatientData)) {
                                    newPatientData.fcNum = null;
                                    newPatientData.fcName = null;
                                    newPatientData.recentSensorValues = null;
                                    newPatientData.recentSensorStates = null;
                                    newPatientData.regDate = null;
                                    newPatientData.battery = null;
                                }
                                return newPatientData;
                            }
                            return patient;
                        }),
                    };
                });
            }
        },
    });

    useEffect(() => {
        autoElapsedTimeRef.current = tableData.rows.reduce(
            (acc, { subjectNum, progressType, elapsedTime, startDate }) => {
                const existAutoData = (autoElapsedTimeRef.current || []).find(
                    autoData => autoData.subjectNum === subjectNum,
                );
                if (progressType === 'Y') {
                    acc.push({
                        subjectNum,
                        startDate,
                        elapsedTime:
                            existAutoData && existAutoData.startDate === startDate
                                ? existAutoData.elapsedTime
                                : elapsedTime,
                    });
                }
                return acc;
            },
            [] as AutoElapsedTime[],
        );

        const newTargetNums = tableData.rows.reduce((acc, { targetNum, ...restInfo }) => {
            if (isMapped(restInfo)) {
                acc.push(targetNum);
            }
            return acc;
        }, [] as number[]);
        // 의존성배열에 targetNums가 들어가는 걸 피하기 위해 ref 사용
        if (!arraysAreEqual(targetNumsRef.current, newTargetNums)) {
            targetNumsRef.current = newTargetNums;
            setTargetNums(newTargetNums);
        }
    }, [tableData]);

    useEffect(() => {
        clearInterval(timerRef.current.interval);
        timerRef.current.interval = window.setInterval(() => {
            const now = moment();
            if (!timerRef.current.now.isSame(now, 'day')) {
                setTableData(initialTableData);
                return;
            }

            const updateTableData: AutoElapsedTime[] = [];
            autoElapsedTimeRef.current = autoElapsedTimeRef.current?.map(autoElapsedTime => {
                const newElapsedTime = autoElapsedTime.elapsedTime + 1;
                const updateData = {
                    ...autoElapsedTime,
                    elapsedTime: newElapsedTime,
                };
                if (secToMin(newElapsedTime) !== secToMin(autoElapsedTime.elapsedTime)) {
                    updateTableData.push(updateData);
                }
                return updateData;
            });
            if (updateTableData.length) {
                setTableData(prevState => {
                    return {
                        ...prevState,
                        rows: prevState.rows.map(patient => {
                            const autoElapsedTime = updateTableData.find(
                                autoTime => autoTime.subjectNum === patient.subjectNum,
                            );
                            if (autoElapsedTime) {
                                return { ...patient, elapsedTime: autoElapsedTime.elapsedTime };
                            }
                            return patient;
                        }),
                    };
                });
            }
        }, 1000);
        return () => {
            clearInterval(timerRef.current.interval);
        };
    }, []);

    const handlePageChange = (pageIndex: number) => {
        const param = state.request;
        getTestTargetPatientList({ ...param, page: pageIndex });
    };

    return (
        <>
            <div className={styles['action-area']}>
                <Button className={'btn-secondary'} onClick={handleDebounceCreatePatientClick}>
                    {t('Enroll Study Subjects')}
                </Button>
            </div>
            <div className={styles['table-wrapper']}>
                <Table
                    columns={columns}
                    data={tableData}
                    paging={viewAllLists}
                    onPageChange={handlePageChange}
                    dynamicRowHeight
                    getRowProps={getRowProps}
                    onTrClick={row => {
                        dispatch(setSelectedSubject(row));
                    }}
                    noDataMessage={t('Enroll your research subjects.')}
                />
            </div>
            <SensorMappingModal />
            <FailedAlertModal />
            <ContextMenu {...contextmenu} toggle={toggleContextmenu} />
        </>
    );
};

export default PatientsTable;
