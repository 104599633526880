import { useAppSelector, useDeepCompareMemo } from '@hooks';

interface Parameter {
    preParam?: object;
    postParam?: object;
}

/*
 * @param {object} preParam 필터정보로 덮어 씌워질 파라미터 정보
 * @param {object} postParam 필터정보를 덮어 쓸 파라미터 정보
 * */
const useFilter = ({ preParam, postParam }: Parameter = {}): object => {
    const filterParam = useAppSelector(state => state.FilterInfo.filterParam);

    return useDeepCompareMemo(() => {
        return { ...preParam, ...filterParam, ...postParam };
    }, [preParam, postParam, filterParam]);
};

export default useFilter;
