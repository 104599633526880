import { UnixTimestamp, NullableString, YN } from '@util/type/util';
import { RequestHandlerCreator, PageInfoType, PageInfoParam } from '@api/util';
import { reqGet } from '@api/index';

export interface CategoryInfo {
    categoryCode: string;
    categoryCodePath: string;
    categoryName: string;
    categoryNamePath: string;
    comNum: number;
    depth: number;
    modDate: UnixTimestamp;
    normalIconSrc: NullableString;
    normalIconURL: NullableString;
    parentCode: NullableString;
    regDate: UnixTimestamp;
    sosIconSrc: NullableString;
    sosIconURL: NullableString;
}

interface CategoryInfoParam extends PageInfoParam {
    categoryCode?: string;
    categoryCodePath?: string;
    categoryName?: string;
    parentCode?: string;
}

type PropertyOption = {
    name: string;
    value: string;
};

type InputType = 'checkbox' | 'date' | 'image' | 'login' | 'login_group' | 'radio' | 'select' | 'text' | 'textarea';

export interface CategoryPropertyInfo {
    categoryCode: string;
    categoryName: string;
    comNum: number;
    displayName: string;
    inputType: InputType;
    inputValues: string | PropertyOption[];
    isKey: YN;
    isListProperty: YN;
    isRequired: YN;
    isSearchable: YN;
    modDate: UnixTimestamp;
    propertyId: string;
    regDate: UnixTimestamp;
    sortOrder: number;
    type: NullableString;
    validRule?: NullableString;
}

interface CategoryPropertyInfoParam extends PageInfoParam {
    categoryCode?: string;
}

// 카테고리 리스트 호출
export const fetchCategory: RequestHandlerCreator<PageInfoType<CategoryInfo>, CategoryInfoParam> = param =>
    reqGet('/v3/api/common/target/category/info', param);

//대상 카테고리 속성 정보 목록조회 API
export const getCategoryPropertiesListApi: RequestHandlerCreator<
    PageInfoType<CategoryPropertyInfo>,
    CategoryPropertyInfoParam
> = param => reqGet('/v3/api/common/target/category/config', { ...param, isAll: 'Y' });
