import React from 'react';
import { useColumns, useTranslation } from '@hooks';
import CustomTable from './CustomTable';
import moment from 'moment';
import InnerItemCard from '@components/Card/InnerItemCard';
import { Button } from '@components';
import { SensorReport, SensorReportType } from '../types';
import { UnixTimestamp } from '@util/type/util';

type MergedCategory = { column: keyof SensorReport; Header: any; name?: string };
const getMergedSpanItem = (data: SensorReport[] | undefined, key: keyof SensorReport) => {
    if (!data) return [];
    const categoryCounts: MergedCategory[] = [];

    data.forEach((item: SensorReport) => {
        const categoryName = item[key];
        const categoryCountsItem = categoryCounts.find(v => v.name === categoryName);
        if (!categoryCountsItem) {
            categoryCounts.push({ column: key, Header: categoryName });
        }
    });

    return categoryCounts;
};

const UsageSensorList = ({
    data,
    date,
    handleExcelDownload,
}: {
    data: SensorReportType;
    date: UnixTimestamp;
    handleExcelDownload: (param?: any) => Promise<any>;
}) => {
    const t = useTranslation('UsageSensorReport');

    const rowSpans = [...getMergedSpanItem(data.rows, 'modelGroupName'), ...getMergedSpanItem(data?.rows, 'groupName')];

    const getCellDateValue = (value: string, month: number) => {
        const selectYear = moment.unix(date).format('YYYY');
        const currentYear = moment().format('YYYY');
        const currentMonth = moment().format('M');

        if (selectYear === currentYear) {
            return parseInt(currentMonth) >= month ? value : ' - ';
        }

        return value;
    };

    const columns = useColumns(
        [
            {
                Header: 'Ward/Dept',
                accessor: 'groupName',
                width: 50,
                className: 'bg-lightgray',
            },
            {
                Header: 'Device Group Name',
                accessor: 'modelGroupName',
                width: 50,
                className: 'bg-lightgray',
            },
            {
                Header: 'Device name',
                accessor: 'sensorName',
                width: 80,
                className: 'bg-lightgray',
            },
            {
                Header: 'January',
                accessor: 'january',
                width: 30,
                Cell: (value: string) => getCellDateValue(value, 1),
            },
            {
                Header: 'February',
                accessor: 'february',
                width: 30,
                Cell: (value: string) => getCellDateValue(value, 2),
            },
            {
                Header: 'March',
                accessor: 'march',
                width: 30,
                Cell: (value: string) => getCellDateValue(value, 3),
            },
            {
                Header: 'April',
                accessor: 'april',
                width: 30,
                Cell: (value: string) => getCellDateValue(value, 4),
            },
            {
                Header: 'May',
                accessor: 'may',
                width: 30,
                Cell: (value: string) => getCellDateValue(value, 5),
            },
            {
                Header: 'June',
                accessor: 'june',
                width: 30,
                Cell: (value: string) => getCellDateValue(value, 6),
            },
            {
                Header: 'July',
                accessor: 'july',
                width: 30,
                Cell: (value: string) => getCellDateValue(value, 7),
            },
            {
                Header: 'August',
                accessor: 'august',
                width: 30,
                Cell: (value: string) => getCellDateValue(value, 8),
            },
            {
                Header: 'September',
                accessor: 'september',
                width: 30,
                Cell: (value: string) => getCellDateValue(value, 9),
            },
            {
                Header: 'October',
                accessor: 'october',
                width: 30,
                Cell: (value: string) => getCellDateValue(value, 10),
            },
            {
                Header: 'November',
                accessor: 'november',
                width: 30,
                Cell: (value: string) => getCellDateValue(value, 11),
            },
            {
                Header: 'December',
                accessor: 'december',
                width: 30,
                Cell: (value: string) => getCellDateValue(value, 12),
            },
            {
                Header: 'Total usage by device',
                accessor: 'total',
                width: 80,
            },
        ],
        t,
        [data],
    );

    return (
        <div className={'h-100'}>
            <InnerItemCard
                header={{
                    title: t('Usage Sensor Report', 'Widget'),
                    action: (
                        <Button
                            className="btn-secondary btn-icon"
                            iconName={'download'}
                            onClick={() => handleExcelDownload()}
                        >
                            {t('Download')}
                        </Button>
                    ),
                }}
                className={'h-100'}
                bodyClassName={'h-100 w-100'}
            >
                <div className={'h-90'}>
                    <CustomTable columns={columns} rows={data?.rows} rowSpans={rowSpans} />
                </div>
            </InnerItemCard>
        </div>
    );
};

export default UsageSensorList;
