import React, { createContext, useContext } from 'react';
import ContextProvider from '@components/ContextProvider';
import MassRealTimeLocationStatusContainer from './MassRealTimeLocationStatusContainer';
import MassRealTimeLocationSlice from './massRealTimeLocationSlice';

export const MassRealTimeLocationStatusStateContext = createContext(null);
export const MassRealTimeLocationStatusDispatchContext = createContext(null);

export const useMassRealTimeLocationStatusContext = () => useContext(MassRealTimeLocationStatusStateContext);
export const useMassRealTimeLocationStatusDispatch = () => useContext(MassRealTimeLocationStatusDispatchContext);

const MassRealTimeLocationStatus = ({ children, widgetInfo, ...restProps }) => {
    return (
        <ContextProvider
            StateContext={MassRealTimeLocationStatusStateContext}
            DispatchContext={MassRealTimeLocationStatusDispatchContext}
            slice={MassRealTimeLocationSlice}
        >
            <MassRealTimeLocationStatusContainer widgetInfo={widgetInfo} children={children} {...restProps} />
        </ContextProvider>
    );
};

export default MassRealTimeLocationStatus;
