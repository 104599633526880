import moment from 'moment';

export const formatSingleOption = (ele, key) => ({
    label: ele.groupName,
    value: ele[key],
});

export const formatToSelectOptions = (arr, key) => {
    return arr.map(ele => formatSingleOption(ele, key));
};

// column 이름을 받아 해당 row에 있는 unix time을 포매팅
export const formatDateByColumn = (row, column) => {
    const { original } = row;

    const value = original[column];

    if (!value) {
        return '';
    }

    return moment.unix(value).format('YYYY-MM-DD HH:mm:ss');
};

// unix time으로 내려오는 필드를 일별/월별/연도별에 맞춰서 변환
export const formatUnixTimeData = (rows, dateType) => {
    if (dateType === 'daily') {
        return rows.map(ele => ({ ...ele, date: moment.unix(ele.regDate).format('YYYY-MM-DD') }));
    }
    if (dateType === 'monthly') {
        return rows.map(ele => ({ ...ele, date: moment.unix(ele.regDate).format('YYYY-MM') }));
    }
    if (dateType === 'yearly') {
        return rows.map(ele => ({ ...ele, date: moment.unix(ele.regDate).format('YYYY') }));
    }
    return rows;
};

// 엑셀에 나오는 일별/월별/연도별 포매팅
export const formatExcelDateName = dateType => {
    if (dateType === 'daily') {
        return '일별';
    }
    if (dateType === 'monthly') {
        return '월별';
    }
    if (dateType === 'yearly') {
        return '연도별';
    }
    return '일자';
};
