import { useSelector } from 'react-redux';
import React, { useEffect, useRef } from 'react';
import { NOTIFICATION_EVENT_TYPE } from '@util/mappInfo';

const NotificationSound = () => {
    const {
        notificationContents,
        soundConfig: { useSound, sensorSoundUrl, tagSoundUrl, locationSoundUrl },
    } = useSelector(state => state.Notification);

    const tagSoundRef = useRef();
    const sensorSoundRef = useRef();
    const locationSoundRef = useRef();

    // Event type 별 알림 재생
    const handleAlertSound = ({ eventGroup }) => {
        let soundPlay;
        if (eventGroup === NOTIFICATION_EVENT_TYPE.tagStatus) {
            soundPlay = tagSoundRef.current.play();
        }
        if (eventGroup === NOTIFICATION_EVENT_TYPE.sensorStatus) {
            soundPlay = sensorSoundRef.current.play();
        }
        if (eventGroup === NOTIFICATION_EVENT_TYPE.location) {
            soundPlay = locationSoundRef.current.play();
        }
        // MDN 공식 문서의 play() 실패 처리 양식을 따름
        // https://developer.mozilla.org/en-US/docs/Web/Media/Autoplay_guide#autoplay_of_media_elements
        if (soundPlay !== undefined) {
            soundPlay.catch(e => {
                console.warn('Notification Sound Error: ', e);
            });
        }
    };

    useEffect(() => {
        if (!useSound || !notificationContents) {
            return;
        }
        handleAlertSound(notificationContents);
    }, [notificationContents, useSound]);

    if (!useSound) {
        return null;
    }

    return (
        <div id="notification-sounds" className="d-none">
            <audio ref={tagSoundRef} src={tagSoundUrl} />
            <audio ref={sensorSoundRef} src={sensorSoundUrl} />
            <audio ref={locationSoundRef} src={locationSoundUrl} />
        </div>
    );
};

export default NotificationSound;
