import React, { createContext, useContext } from 'react';
import ContextProvider from '@components/ContextProvider';
import CongestionStatusSlice from './congestionStatusSlice';
import CongestionStatusContainer from './CongestionStatusContainer';

export const CongestionStatusStateContext = createContext(null);
export const CongestionStatusDispatchContext = createContext(null);

export const useCongestionStatusContext = () => useContext(CongestionStatusStateContext);

export const useCongestionStatusDispatch = () => useContext(CongestionStatusDispatchContext);

const CongestionStatus = ({ children, widgetInfo, ...restProps }) => {
    return (
        <ContextProvider
            StateContext={CongestionStatusStateContext}
            DispatchContext={CongestionStatusDispatchContext}
            slice={CongestionStatusSlice}
        >
            <CongestionStatusContainer widgetInfo={widgetInfo} children={children} {...restProps} />
        </ContextProvider>
    );
};

export default CongestionStatus;
