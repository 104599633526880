import React, { useEffect, useState } from 'react';
import { ChartData as ChartDataType, ChartType } from 'chart.js';

import { PieChart } from '@components/Charts';

import NoDataBlock from '../../Components/NoDataBlock';

interface ChartData {
    ranking: number;
    key: string;
    name: string;
    value: number;
}
interface Props {
    data: ChartData[];
    settings: { chartType?: ChartType };
}

const CategoryPieChart = ({ data, settings }: Props) => {
    const [chartData, setChartData] = useState<ChartDataType<'pie' | 'doughnut'>>({ datasets: [] });
    useEffect(() => {
        setChartData({
            labels: data.map(({ name }) => name),
            datasets: [{ data: data.map(({ value }) => value) }],
        });
    }, [data]);

    return <>{!!data && !!data.length ? <PieChart data={chartData} type={settings.chartType} /> : <NoDataBlock />}</>;
};

export default React.memo(CategoryPieChart);
