export const CATEGORY_LABEL = {
    ASSET: 'Asset',
    PEOPLE: 'People',
    SENSOR: 'Sensor',
};

export const CATEGORY_CODE = {
    ASSET: 'ASSET',
    PEOPLE: 'PEOPLE',
    SENSOR: 'SENSOR',
    PATIENT: 'PATIENT',
    PROTECTOR: 'PROTECTOR',
};

// 발생 타입
export const OCCUR_STATE_INFO = {
    sos: { SOS_CHANGE_ON: true, SOS_KEEP_ON: true },
    battery: { BATTERY_CHANGE_LOW: true, BATTERY_KEEP_LOW: true },
    leaveAlone: { LEAVEALONE_CHANGE_ON: true },
    longStay: { LONGSTAY_CHANGE_ON: true, LONGSTAY_KEEP_ON: true },
    lostSignal: { LOSTSIGNAL_ON: true },
    fireAlarm: { FIREALRM_CHANGE_ON: true, FIREALRM_KEEP_ON: true },
    restriction: { RESTRICTION_CHANGE_ON: true, RESTRICTION_KEEP_ON: true },
    nobodyAround: { NOBODY_AROUND_ON: true },
};

// 해제 타입
export const RECOVERY_STATE_INFO = {
    sos: { SOS_CHANGE_OFF: true, SOS_KEEP_OFF: true },
    battery: { BATTERY_UNKNOW: true, BATTERY_CHANGE_NORMAL: true, BATTERY_KEEP_NORMAL: true },
    leaveAlone: { LEAVEALONE_CHANGE_OFF: true, LEAVEALONE_KEEP_OFF: true },
    longStay: { LONGSTAY_CHANGE_OFF: true, LONGSTAY_KEEP_OFF: true },
    lostSignal: { LOSTSIGNAL_OFF: true, LOSTSIGNAL_KEEP_OFF: true },
    fireAlarm: { FIREALRM_CHANGE_OFF: true, FIREALRM_KEEP_OFF: true },
    restriction: { RESTRICTION_CHANGE_OFF: true, RESTRICTION_KEEP_OFF: true },
    nobodyAround: { NOBODY_AROUND_OFF: true },
};

export const TAG_STATE_LABEL = {
    sos: 'SOS',
    battery: 'Low Battery',
    leaveAlone: 'Leave Alone',
    longStay: 'Long Stay',
    lostSignal: 'Lost Signal',
    fireAlarm: 'Fire Alarm',
    restriction: 'Restriction',
    nobodyAround: 'Nobody Around',
};

export const TAG_ALARM_LABEL = {
    sos: 'SOS',
    restricted: 'Restricted',
    longStay: 'Long Stay',
    outOfRange: 'Out Of Range',
    lostSignal: 'Lost Signal',
    battery: 'Low Battery',
    nobodyAround: 'Nobody Around',
};

export const NOTIFICATION_TYPE = {
    USER: 'user',
    ROLE: 'role',
    GROUP: 'group',
};

export const NOTIFICATION_EVENT_TYPE = {
    sensorStatus: 'SENSOR_STATUS',
    tagStatus: 'TAG_STATUS',
    location: 'LOCATION',
};

export const isIn = state => {
    switch (state) {
        case 'OUT_PROCESSING':
        case 'IN':
        case 'STAY_PROCESSING':
        case 'STAY':
        case 'STAYING':
            return true;
        default:
            return false;
    }
};

export const isDanger = type => {
    return [
        'FLOOR_OUT',
        'GEOFENCE_OUT',
        'RESTRICTION_IN',
        'LOW_BATTER',
        'LOST_SIGNAL',
        'SOS_ON',
        'LEAVEALONE_ON',
        'LONGSTAY_ON',
        'SENSOR_WARNING',
        'SENSOR_CRITICAL',
        'SENSOR_OUTOFRANGE',
    ].includes(type);
};

// Check Lost Signal On State
export const checkLostSignal = state => ['LOSTSIGNAL_ON', 'LOSTSIGNAL_KEEP_ON'].includes(state);

export const getPermittedState = permitted => {
    return permitted ? 'authorized' : 'unAuthorized';
};
