import React, { useState } from 'react';
import StatusCount from './Components/StatusCount';
import OutpatientDatePicker from './OutpatientDatePicker';
import StatusList from './Components/StatusList';
import { useOutpatientUnattendedReceptionContext } from './index';
import {
    getOutpatientUnattendedReceptionStatusCountApi,
    getOutpatientUnattendedReceptionStatusListApi,
} from '@api/monitoring';
import { useAsync } from '@hooks';
import moment from 'moment';
import useDepartmentOptions from '../util/useDepartmentOptions';

const DEFAULT_UNATTENDED_RECEPTION_COUNT = {
    total: 0,
    todayReceptionCountStat: {
        todayReception: 0,
    },
    receptionCountStat: {
        receptionSuccess: 0,
        receptionFail: 0,
    },
};
const DEFAULT_UNATTENDED_RECEPTION_LIST = {
    pageSize: 15,
    totalCount: 0,
    totalPage: 1,
    rows: [],
};
const OutpatientUnattendedReceptionStatusContainer = () => {
    const [startDate, setStartDate] = useState(moment().startOf('day').unix());
    const [endDate, setEndDate] = useState(
        moment().startOf('day').add(23, 'hours').add(59, 'm').add(59, 'second').unix(),
    );

    const departmentValues = useDepartmentOptions();
    const { department } = departmentValues;

    const {
        selectedOutpatientStatus: status,
        keyword,
        outpatientListParam,
    } = useOutpatientUnattendedReceptionContext();

    const { state: outpatientUnattendedReceptionCountState } = useAsync({
        promise: getOutpatientUnattendedReceptionStatusCountApi,
        immediate: true,
        param: {
            ...outpatientListParam,
            startDate: startDate,
            endDate: endDate,
            departmentCodes: department.toString(),
        },

        reject: err => {
            console.error(err);
        },
        deps: [startDate, endDate, keyword, department],
    });

    const { state: outpatientUnattendedReceptionListState } = useAsync({
        promise: getOutpatientUnattendedReceptionStatusListApi,
        immediate: true,
        param: {
            ...outpatientListParam,
            startDate: startDate,
            endDate: endDate,
            departmentCodes: department.toString(),
        },
        reject: err => {
            console.error(err);
        },
        deps: [startDate, endDate, status, keyword, department],
    });

    const handleStartDate = date => {
        setStartDate(date);
    };
    const handleEndDate = date => {
        setEndDate(date);
    };

    const outpatientUnattendedReceptionCount =
        outpatientUnattendedReceptionCountState.response ?? DEFAULT_UNATTENDED_RECEPTION_COUNT;
    const outpatientUnattendedReceptionList =
        outpatientUnattendedReceptionListState.response ?? DEFAULT_UNATTENDED_RECEPTION_LIST;

    return (
        <div className={'w-100 h-100'}>
            <StatusCount outpatientCount={outpatientUnattendedReceptionCount} />
            <OutpatientDatePicker
                startDate={startDate}
                endDate={endDate}
                handleStartDate={handleStartDate}
                handleEndDate={handleEndDate}
                departmentValues={departmentValues}
            />
            <StatusList outpatientList={outpatientUnattendedReceptionList} />
        </div>
    );
};

export default OutpatientUnattendedReceptionStatusContainer;
