import React, { createContext, useContext } from 'react';
import ContextProvider from '@components/ContextProvider';
import VitalSignsRealTimePartronContainer from './VitalSignsRealTimePartronContainer';
import vitalSignsRealTimePartronSlice from './vitalSignsRealTimePartronSlice';

/**
 * 활력징후 모니터링 위젯
 *
 * @since 2022-12-16
 * @author kunwoong-kim
 * */

export const VitalSignsRealTimePartronStateContext = createContext(null);
export const VitalSignsRealTimePartronDispatchContext = createContext(null);

export const useVitalSignsRealTimePartronContext = () => useContext(VitalSignsRealTimePartronStateContext);
export const useVitalSignsRealTimePartronDispatch = () => useContext(VitalSignsRealTimePartronDispatchContext);

const VitalSignsRealTimePartron = ({ children, widgetInfo, ...restProps }) => {
    return (
        <ContextProvider
            StateContext={VitalSignsRealTimePartronStateContext}
            DispatchContext={VitalSignsRealTimePartronDispatchContext}
            slice={vitalSignsRealTimePartronSlice}
        >
            <VitalSignsRealTimePartronContainer widgetInfo={widgetInfo} children={children} {...restProps} />
        </ContextProvider>
    );
};

export default VitalSignsRealTimePartron;
