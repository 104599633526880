import React, { useRef } from 'react';
import { useAsync } from '@hooks';
import { advancedGenerateFakeData } from '@util/common/util';
import WidgetCard from '../../Components/WidgetCard';
import { setCongestionData } from './Context/slice';
import Map from './Map';
import SearchContainer from './SearchContainer';
import { useOutpatientCongestionContext, useOutpatientCongestionDispatch } from './Context';

const generateRandomData = totalCount => {
    const LAT_LNG = [
        [37.511062, 127.056626],
        [37.511194, 127.056623],
        [37.511078, 127.056877],
        [37.511236, 127.056752],
    ];

    const data = [];

    for (let i = 0; i < totalCount; i++) {
        const row = [];
        for (let j = 0; j < LAT_LNG.length; j++) {
            const point = {
                name: String.fromCharCode(97 + j),
                lat: LAT_LNG[j][0],
                lng: LAT_LNG[j][1],
                congestion: Math.floor(Math.random() * 300),
            };
            row.push(point);
        }
        data.push(row);
    }

    return data;
};

// 하루는 60*24=1440, 1분당 4개의 아이템 표출
const getListApi = advancedGenerateFakeData({ rows: generateRandomData(1440) });

const WidgetContents = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();

    const dispatch = useOutpatientCongestionDispatch();
    const { startDate, endDate, selectedFloorInfo } = useOutpatientCongestionContext();

    const { promise: getData } = useAsync({
        promise: getListApi,
        param: {
            // floor:selectedFloorInfo
            startDate: startDate,
            endDate: endDate,
        },
        resolve: ({ rows }) => {
            dispatch(setCongestionData(rows));
        },
        reject: err => console.error(err),
    });

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <SearchContainer getData={getData} />
            <Map />
            {children}
        </WidgetCard>
    );
};

export default WidgetContents;
