import { fetchExcelLogApi } from '@api/common/log';
import { useAsync } from '@hooks';
import { useRef } from 'react';
import { excelExport } from './excelExport';

const INTERVAL_TIME = 1000;
const FILE_STATUS = {
    COMPLETED: 'COMPLETED',
    PROGRESSING: 'PROGRESSING',
    FAILED: 'FAILED',
};

export const useAsyncExcel = ({ promise, param }) => {
    const excelTimerRef = useRef({});

    const clearExcelTimer = ({ reqNum }) => {
        clearInterval(excelTimerRef.current[reqNum]);
        excelTimerRef.current[reqNum] = null;
    };

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            const { dataSettingStatusType, reqNum, filePath: url, fileName } = res;
            const fileStatus = dataSettingStatusType.toUpperCase();

            if (fileStatus === FILE_STATUS.PROGRESSING) {
                return;
            }
            if (fileStatus === FILE_STATUS.COMPLETED) {
                excelExport(url, fileName);
            } else if (fileStatus === FILE_STATUS.FAILED) {
                console.error('error');
            }
            clearExcelTimer({ reqNum });
        },
        reject: err => console.error(err),
    });

    const downloadWithURL = ({ reqNum }) => {
        excelTimerRef.current[reqNum] = setInterval(() => {
            fetchExcelLog({ reqNum });
        }, INTERVAL_TIME);
    };

    const { promise: triggerDownload } = useAsync({
        promise,
        param,
        resolve: ({ filePath: url, reqNum }) => {
            if (url) {
                downloadWithURL({ reqNum });
            }
        },
        reject: err => console.error(err),
    });

    return triggerDownload;
};
