import { useEffect, useRef, useState } from 'react';

interface UseIntervalParams {
    callback: (...args: any[]) => void;
    delay: number;
    params: any;
    initial: boolean;
}

type ChangedIntervalState = boolean | undefined;

const useInterval = ({ callback, delay, params, initial = true }: UseIntervalParams) => {
    const savedCallback = useRef(callback);
    // intervalState가 false이면 interval이 멈추고 true면 interval이 진행됨
    const [intervalState, setIntervalState] = useState<boolean>(initial);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // intervalState를 toggle하는 함수
    const toggleDelay = (changedIntervalState: ChangedIntervalState) => {
        setIntervalState(prevState => {
            if (typeof changedIntervalState === 'boolean') {
                return changedIntervalState;
            }
            return !prevState;
        });
    };

    useEffect(() => {
        const interval = () => {
            if (savedCallback.current) {
                savedCallback.current(...params);
            }
        };

        if (intervalState) {
            const id = setInterval(interval, delay);

            return () => {
                clearInterval(id);
            };
        }
    }, [delay, intervalState, params]);

    return toggleDelay;
};

export default useInterval;
