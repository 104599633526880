import React, { useEffect, useState } from 'react';
import { Button, Select as ReactSelect, TextInput } from '@components';
import {
    InputGroup,
    InputWrap,
    SearchWrap,
    SelectGroup,
} from '../../../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import SearchButtonArea from '../../../../../../MainPages/Components/FilterSearchGroup/Components/SearchButtonArea';
import { clearSensorStatusWidget, setSensorListParam } from '../../../sensorStatusSlice';
import { useTranslation } from '@hooks';
import { useSensorStatusContext, useSensorStatusDispatch } from '../../../index';
import styled from 'styled-components';
import SearchGroup from '../../../../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import FilterSearchGroup from '../../../../../../MainPages/Components/FilterSearchGroup';

/**
 * 검색 컴포넌트 (selet - 센서상태 , text -대상이름 , 대상 아이디 , 센서 맥어드레스,층)
 *
 *  @author jibeom-jung
 * */
const Search = () => {
    const t = useTranslation('SensorStatus');
    const dispatch = useSensorStatusDispatch();
    const { sensingTypeOptions } = useSensorStatusContext();

    const searchOptions = [
        { value: '', label: t('Search Option', 'Search') },
        { value: 'targetName', label: t('Target Name', 'CommonColumn') },
        { value: 'targetId', label: t('Target ID', 'CommonColumn') },
        { value: 'sensorId', label: t('Sensor Mac Address', 'CommonColumn') },
        { value: 'floorId', label: t('Floor', 'CommonColumn') },
    ];

    const [selectedOption, setSelectedOption] = useState(searchOptions[0]);
    const handleSelectValue = selected => setSelectedOption(selected);

    const [searchInput, setSearchInput] = useState('');
    const handleInput = e => {
        const { value } = e.target;
        setSearchInput(value);
    };

    const [selectedSensingType, setSelectedSensingType] = useState({ sensingType: '', language: t('Select all') });
    const handleSensingType = selected => setSelectedSensingType(selected);
    const handleSearch = () => {
        const param = { keyword: searchInput, opt: selectedOption.value };
        if (selectedSensingType) {
            param['sensingTypes'] = selectedSensingType.sensingType;
        }
        dispatch(setSensorListParam({ ...param, page: 1 }));
    };

    const handleRefresh = () => {
        dispatch(clearSensorStatusWidget());
        setSelectedSensingType(null);
        setSelectedOption(searchOptions[0]);
        setSearchInput('');
    };

    useEffect(() => {
        return () => {
            dispatch(setSensorListParam({ keyword: '', opt: '', page: 1 }));
        };
    }, []);

    return (
        <CustomFilterSearchGroup className={'p-0'}>
            <SearchGroup>
                <div>
                    <ReactSelect
                        name="sensingType"
                        placeholder={t('Select all')}
                        value={selectedSensingType}
                        options={sensingTypeOptions}
                        onChange={handleSensingType}
                        valueKey="sensingType"
                        labelKey="language"
                        customControlStyles={{ width: '100%' }}
                        customMenuStyles={{ width: '100%' }}
                        customOptionStyles={{ width: '100%' }}
                    />
                </div>

                <SearchWrap>
                    <SelectGroup>
                        <ReactSelect
                            name="selectInput"
                            value={selectedOption}
                            options={searchOptions}
                            onChange={handleSelectValue}
                            // customControlStyles={}
                            customMenuStyles={{ width: '100%' }}
                            customOptionStyles={{ width: '100%' }}
                        />
                    </SelectGroup>
                    <InputWrap>
                        <InputGroup>
                            <TextInput
                                type="text"
                                name="keyword"
                                size={60}
                                placeholder={t(
                                    'Please Enter your search keyword after selecting search option.',
                                    'Search',
                                )}
                                value={searchInput}
                                handleChange={handleInput}
                                disabled={!selectedOption.value}
                            />
                        </InputGroup>
                    </InputWrap>
                    <SearchButtonArea>
                        <Button className="btn-secondary mr-2" onClick={handleSearch}>
                            {t('Search', 'Search')}
                        </Button>
                        <Button className="btn-line btn-icon-only" iconName="refresh" onClick={handleRefresh} />
                    </SearchButtonArea>
                </SearchWrap>
            </SearchGroup>
        </CustomFilterSearchGroup>
    );
};

const CustomFilterSearchGroup = styled(FilterSearchGroup)`
    padding-bottom: 0 !important;
`;
export default Search;
