import React from 'react';
import cx from 'classnames';
import styles from '@asset/pnt/widgets/RefrigeratorSensor.module.scss';
import { checkLostSignal } from '@util/mappInfo';

const STATUS = {
    NORMAL: { ICON: 'done', COLOR: 'bg-success', STATUS_COLOR: 'bg-success bg-dark' },
    WARNING: { ICON: 'priority_high', COLOR: 'bg-warning', STATUS_COLOR: 'bg-warning bg-dark' },
    CRITICAL: { ICON: 'close', COLOR: 'bg-danger', STATUS_COLOR: 'bg-danger bg-d-2' },
    OUTOFRANGE: { ICON: 'question_mark', COLOR: 'bg-secondary', STATUS_COLOR: 'bg-secondary bg-d-3' },
    LOSTSIGNAL: { ICON: 'mobiledata_off', COLOR: 'bg-info', STATUS_COLOR: 'bg-info bg-d-3' },
    EMPTY: { ICON: 'remove', COLOR: 'bg-depth-9', STATUS_COLOR: 'bg-gray' },
};

const SensorStatus = ({ status, lostSignal }) => {
    let statusKey = status ? status : 'EMPTY';

    if (checkLostSignal(lostSignal)) {
        statusKey = 'LOSTSIGNAL';
    }

    return (
        <div className={cx(styles.sensor_img, STATUS[statusKey].COLOR)}>
            <span className="s_temperature"></span>
            <div className={cx(styles.status, STATUS[statusKey].STATUS_COLOR)}>
                <span className="material-icons-round">{STATUS[statusKey].ICON}</span>
            </div>
        </div>
    );
};

export default SensorStatus;
