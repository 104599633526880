import React from 'react';
import { Button } from '@components';

interface Props {
    onClick: () => void;
    name: string;
}

const CellButton = ({ onClick, name = '' }: Props) => {
    return (
        <Button className="btn-gray" onClick={onClick}>
            {name}
        </Button>
    );
};

export default CellButton;
