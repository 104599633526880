import {
    DoughnutController,
    PolarAreaController,
    LineController,
    Chart,
    PieController,
    ChartDataset,
    RadarController,
} from 'chart.js';
import { CHART_COLORS } from '@components/Charts/config';

export interface ColorPaletteOptions {
    enabled?: boolean;
}

// interface ColorsDescriptor {
//     backgroundColor?: unknown;
//     borderColor?: unknown;
// }

const BORDER_COLORS = [...CHART_COLORS];

// Border colors with 50% transparency
// const BACKGROUND_COLORS = [...CHART_COLORS];
const BACKGROUND_COLORS = BORDER_COLORS;
const TRANSPARENT_BACKGROUND_COLORS = BORDER_COLORS.map(color => color + '4D');

function getBorderColor(i: number) {
    return BORDER_COLORS[i % BORDER_COLORS.length];
}

function getBackgroundColor(i: number) {
    return BACKGROUND_COLORS[i % BACKGROUND_COLORS.length];
}

function getTransparentBackgroundColor(i: number) {
    return TRANSPARENT_BACKGROUND_COLORS[i % TRANSPARENT_BACKGROUND_COLORS.length];
}

function colorizeDefaultDataset(dataset: ChartDataset, i: number) {
    dataset.borderColor = getBorderColor(i);
    dataset.backgroundColor = getBackgroundColor(i);

    return ++i;
}

function colorizePieDataset(dataset: ChartDataset, i: number) {
    dataset.backgroundColor = dataset.data.map(() => getBackgroundColor(i++));

    return i;
}

function colorizePolarAreaDataset(dataset: ChartDataset, i: number) {
    dataset.backgroundColor = dataset.data.map(() => getTransparentBackgroundColor(i++));

    return i;
}

function colorizeLineDataset(dataset: ChartDataset<'line'>, i: number, transParent?: boolean) {
    dataset.borderColor = getBorderColor(i);
    dataset.pointBorderColor = getBorderColor(i);
    dataset.pointBackgroundColor = getBorderColor(i);

    if (transParent) {
        dataset.backgroundColor = getTransparentBackgroundColor(i);
    } else {
        dataset.backgroundColor = getBackgroundColor(i);
    }

    return ++i;
}

function getColorizer(chart: Chart) {
    let i = 0;
    return (dataset: ChartDataset, datasetIndex: number) => {
        const controller = chart.getDatasetMeta(datasetIndex).controller;

        if (controller instanceof PieController || controller instanceof DoughnutController) {
            i = colorizePieDataset(dataset, i);
        } else if (controller instanceof PolarAreaController) {
            i = colorizePolarAreaDataset(dataset, i);
        } else if (controller instanceof LineController) {
            i = colorizeLineDataset(dataset as ChartDataset<'line'>, i, !!chart.options.datasets?.line?.fill);
        } else if (controller) {
            i = colorizeDefaultDataset(dataset, i);
        }

        convertForcedColor(dataset);
        if (controller instanceof LineController || controller instanceof RadarController) {
            convertForcedPointColor(dataset as ChartDataset<'line' | 'radar'>);
        }
    };
}

function convertForcedColor(dataset: ChartDataset) {
    if (dataset.forcedBorderColor) {
        dataset.borderColor = dataset.forcedBorderColor;
    }
    if (dataset.forcedBackgroundColor) {
        dataset.backgroundColor = dataset.forcedBackgroundColor;
    }
}

function convertForcedPointColor(dataset: ChartDataset<'line' | 'radar'>) {
    if (dataset.forcedPointBorderColor) {
        dataset.pointBorderColor = dataset.forcedPointBorderColor;
    }
    if (dataset.forcedPointBackgroundColor) {
        dataset.pointBackgroundColor = dataset.forcedPointBackgroundColor;
    }
}

// function containsColorsDefinitions(descriptors: ColorsDescriptor[] | Record<string, ColorsDescriptor>) {
//     let tempDescriptors: Record<string, ColorsDescriptor>;
//     if (Array.isArray(descriptors)) {
//         tempDescriptors = descriptors.reduce((acc: Record<string, ColorsDescriptor>, curr, i) => {
//             const indexStr: string = String(i);
//             acc[indexStr] = curr;
//             return acc;
//         }, {});
//     } else {
//         tempDescriptors = descriptors;
//     }
//
//     let k: string;
//
//     for (k in tempDescriptors) {
//         if (
//             tempDescriptors.hasOwnProperty(k) &&
//             (tempDescriptors[k].borderColor || tempDescriptors[k].backgroundColor)
//         ) {
//             return true;
//         }
//     }
//     return false;
// }

// function containsColorsDefinition(descriptor: ColorsDescriptor) {
//     return descriptor && (descriptor.borderColor || descriptor.backgroundColor);
// }

export default {
    id: 'colorPalette',

    defaults: {
        enabled: true,
    } as ColorPaletteOptions,

    beforeLayout(chart: Chart, _args: object, options: ColorPaletteOptions) {
        if (!options.enabled) {
            return;
        }

        const {
            data: { datasets },
        } = chart.config;

        datasets.forEach(getColorizer(chart));
    },
};
