import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@hooks';
import WidgetSettingModal from '../../../Components/WidgetSettingModal';
import Label from '@components/Label';
import { editWidgetSettings } from '../../../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import { useSettings } from '../../../util/useSettings';
import { ScreenEditDispatchContext } from '../../../../MainPages/Settings/ScreenManagement/ScreenEdit';
import { Select } from '@components';

const TopFiveCongestedGeofenceSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('TopFiveCongestedGeofence');
    const dispatch = useContext(ScreenEditDispatchContext);
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { settings: customSettings } = config;
    const options = useMemo(
        () => [
            { value: 'category', label: t('Category') },
            { value: 'restricted', label: t('Authorization') },
        ],
        [t],
    );
    const [selectedType, setSelectedType] = useState(null);

    const handleSelectChange = e => {
        setSelectedType(e);
    };

    const handleSubmitClick = data => {
        const checkedSettings = customSettings
            ? { ...customSettings, ...data, type: selectedType.value }
            : { ...data, type: selectedType.value };

        const updatedWidgetSettings = {
            id: widgetInfo.id,
            settings: checkedSettings,
        };

        dispatch(editWidgetSettings(updatedWidgetSettings));
    };

    useEffect(() => {
        if (settings.type) {
            setSelectedType(options.find(e => e.value === settings.type));
        }
    }, [settings]);

    return (
        <WidgetSettingModal
            headerTitle={t('Congested Geofence Top 5')}
            widgetInfo={widgetInfo}
            socketWidget
            okCallback={handleSubmitClick}
            {...restProps}
        >
            <Label
                name={t('Type')}
                value={<Select options={options} value={selectedType} onChange={handleSelectChange} isModalSelect />}
            />
        </WidgetSettingModal>
    );
};

export default TopFiveCongestedGeofenceSetting;
