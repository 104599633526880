import React from 'react';
import useTranslation from '@hooks/useTranslation';
import WidgetSettingModal from '../../Components/WidgetSettingModal';

const VitalSignMonitoringForHiCardiPlusSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('VitalSignMonitoring');
    return (
        <WidgetSettingModal
            headerTitle={t('Vital Signs Monitoring For HiCardiPlus Setting')}
            widgetInfo={widgetInfo}
            hiddenHeader
            {...restProps}
        />
    );
};

export default VitalSignMonitoringForHiCardiPlusSetting;
