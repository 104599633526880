import React, { useContext, useRef } from 'react';
import WidgetCard from '../../Components/WidgetCard';
import OutpatientBaselineTestingItemContainer from './OutpatientBaselineTestingItemContainer';
import ContextProvider from '@components/ContextProvider';
import OutpatientBaselineTestingSlice from './OutpatientBaselineTestingStatusSlice';

export const OutpatientBaselineTestingStatusStateContext = React.createContext();
export const OutpatientBaselineTestingStatusDispatchContext = React.createContext();
export const useOutpatientBaselineTestingState = () => useContext(OutpatientBaselineTestingStatusStateContext);
export const useOutpatientBaselineTestingDispatch = () => useContext(OutpatientBaselineTestingStatusDispatchContext);

const OutpatientBaselineTestingStatus = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();

    return (
        <ContextProvider
            StateContext={OutpatientBaselineTestingStatusStateContext}
            DispatchContext={OutpatientBaselineTestingStatusDispatchContext}
            slice={OutpatientBaselineTestingSlice}
        >
            <WidgetCard ref={widgetRef} widgetInfo={widgetInfo} {...restProps}>
                <OutpatientBaselineTestingItemContainer widgetInfo={widgetInfo} />
                {children}
            </WidgetCard>
        </ContextProvider>
    );
};

export default OutpatientBaselineTestingStatus;
