import React, { createContext, useEffect, useReducer, useState } from 'react';
import reducer, { initialState, setInitState } from '../reducer';
import { MenuList, Option } from './Parts';
import SearchableSelect from '@components/Select/SearchableSelect';

export const CustomSearchableSelectContext = createContext();
export const CustomSearchableSelectDispatchContext = createContext();

const SearchableSelectWithOption = ({ onChange, isEditable = 'Y', selected, valueKey, ...restProps }) => {
    const [state, dispatch] = useReducer(reducer, { ...initialState });
    const [selectedItem, setSelectedItem] = useState(selected);
    const handleChange = selectedOption => {
        setSelectedItem(selectedOption.map(v => v[valueKey]));
    };

    useEffect(() => {
        dispatch(setInitState({ isEditable }));
    }, [isEditable]);

    useEffect(() => {
        if (typeof onChange === 'function') {
            onChange(selectedItem, state.isEditable);
        }
    }, [selectedItem, state.isEditable]);

    return (
        <CustomSearchableSelectDispatchContext.Provider value={dispatch}>
            <CustomSearchableSelectContext.Provider value={state}>
                <SearchableSelect
                    {...restProps}
                    selected={selectedItem}
                    valueKey={valueKey}
                    MenuListComponent={MenuList}
                    OptionComponent={Option}
                    onChange={handleChange}
                />
            </CustomSearchableSelectContext.Provider>
        </CustomSearchableSelectDispatchContext.Provider>
    );
};

export default SearchableSelectWithOption;
