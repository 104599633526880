import React from 'react';
import Button from '@components/Button';
import { useRouteMatch } from 'react-router-dom';
import { A_PATH_CUSTOM_SCREEN } from '../../../MainPages/Components/Router/path';

/**
 * Fullscreen button 컴포넌트
 */
const FullscreenDot = () => {
    // 대시보드 조회에서만 버튼 활성화
    const match = useRouteMatch(A_PATH_CUSTOM_SCREEN);

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            const dashboardEl = document.getElementsByClassName('app-main__inner')[0];
            dashboardEl.requestFullscreen();
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    };

    if (!match) {
        return null;
    }
    return (
        <div className="fullscreen-box">
            <Button className="btn-icon-only btn-trans" iconName="fullscreen" onClick={toggleFullScreen} />
        </div>
    );
};

export default FullscreenDot;
