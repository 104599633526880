import React from 'react';
import { FilterList } from '../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import FilterGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/FilterGroup';
import FilterSearchGroup from '../../../../MainPages/Components/FilterSearchGroup';
import { useTranslation } from '@util/hooks';
import { Button, DatePicker } from '@components';
import moment from 'moment';

const SearchGroup = ({ date, onChange, onDownloadClick, onRefreshClick }) => {
    const t = useTranslation('OutpatientStorage');

    return (
        <FilterSearchGroup className="p-0">
            <FilterGroup label={t('Filter', 'Filter')}>
                <FilterList>
                    <DatePicker
                        value={moment(date, 'YYYY').unix()}
                        onChange={onChange}
                        withoutTime
                        valueType="s"
                        disabledKeyboardNavigation
                        showYearPicker
                        dateFormat="yyyy"
                    />
                    <Button className="btn-line btn-icon-only ml-2" iconName="refresh" onClick={onRefreshClick} />
                </FilterList>
                <Button className="btn-secondary ms-auto" onClick={onDownloadClick}>
                    {t('Download')}
                </Button>
            </FilterGroup>
        </FilterSearchGroup>
    );
};

export default SearchGroup;
