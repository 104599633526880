import sh_message_ko from './sh/message_ko.json';
import sh_message_en from './sh/message_en.json';
import sh_message_ar_SA from './sh/message_ar-SA.json';
import am_message_ko from './am/message_ko.json';
import am_message_en from './am/message_en.json';
import am_message_ar_SA from './am/message_ar-SA.json';
import so_message_ko from './so/message_ko.json';
import so_message_en from './so/message_en.json';
import so_message_ar_SA from './so/message_ar-SA.json';
import custom_message_ko from './custom/message_ko.json';
import custom_message_en from './custom/message_en.json';
import custom_message_ar_SA from './custom/message_ar-SA.json';
import message_ko from './message_ko.json';
import message_en from './message_en.json';
import message_ar_SA from './message_ar-SA.json';
import { mergeObjects } from '@util/common/util';

type JsonType = Record<string, any>;

export type TranslationType = typeof message_ko &
    typeof sh_message_ko &
    typeof am_message_ko &
    typeof so_message_ko &
    typeof custom_message_ko;

export type LanguageKey = 'ko' | 'en' | 'ar-SA';
const mergeJson = (base: JsonType, ...adds: JsonType[]) => {
    const mergedJson = mergeObjects(base);

    for (const add of adds) {
        for (const key in add) {
            const exist = mergedJson[key];

            if (exist) {
                mergedJson[key] = { ...exist, ...add[key] };
            } else {
                mergedJson[key] = add[key];
            }
        }
    }

    return mergedJson;
};

const customMessageKo = {
    sh: mergeJson(sh_message_ko, custom_message_ko),
    so: mergeJson(so_message_ko, custom_message_ko),
    am: mergeJson(am_message_ko, custom_message_ko),
    common: message_ko,
};

const customMessageEn = {
    sh: mergeJson(sh_message_en, custom_message_en),
    so: mergeJson(so_message_en, custom_message_en),
    am: mergeJson(am_message_en, custom_message_en),
    common: message_en,
};

const customMessageAr_SA = {
    sh: mergeJson(sh_message_ar_SA, custom_message_ar_SA),
    so: mergeJson(so_message_ar_SA, custom_message_ar_SA),
    am: mergeJson(am_message_ar_SA, custom_message_ar_SA),
    common: message_ar_SA,
};

const langItem: Record<LanguageKey, object> = {
    ko: customMessageKo,
    en: customMessageEn,
    'ar-SA': customMessageAr_SA,
};

export const getTranslationKey = (text: string, lang: LanguageKey, ns?: keyof TranslationType | object) => {
    if (!ns || typeof ns === 'object') return;

    const languagePack = langItem[lang] ?? customMessageKo;

    for (const [objName, obj] of Object.entries(languagePack)) {
        if (ns in obj) {
            if (text in obj[ns]) return objName;
        }
    }
};

const resources = {
    ko: {
        translation: customMessageKo,
    },
    en: {
        translation: customMessageEn,
    },
    'ar-SA': {
        translation: customMessageAr_SA,
    },
};

export { resources };
