import React from 'react';
import OutpatientReservedCounts from './Components/OutpatientReservedCounts';
import OutpatientArrivedCounts from './Components/OutpatientArrivedCounts';
import OutpatientWaitingCounts from './Components/OutpatientWaitingCounts';
import OutpatientDoneCounts from './Components/OutpatientDoneCounts';
import styled from 'styled-components';

const OutpatientCareItemStatusCount = ({ outpatientCount }) => {
    const { total, arrivalCountStat, waitingCountStat, doneCountStat } = outpatientCount;
    return (
        <Container>
            <OutpatientReservedCounts total={total} />
            <OutpatientArrivedCounts arrivedPatientCount={arrivalCountStat} />
            <OutpatientWaitingCounts waitingPatientCount={waitingCountStat} />
            <OutpatientDoneCounts donePatientCount={doneCountStat} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    width: 100%;
    & > div {
        &:nth-child(1) {
            flex: 1;
        }
        flex: 2;
    }
    margin-bottom: 5px;
`;

export default OutpatientCareItemStatusCount;
