import { useEffect, useRef } from 'react';

const useDebounce = (callback: (...args: any[]) => void, delay: number) => {
    const savedCallback = useRef(callback);
    const timeoutRef = useRef<number | null>(null);

    // 콜백 함수가 변경될 때마다 저장
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    const debounceFunction = (...args: any[]) => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = window.setTimeout(() => {
            savedCallback.current(...args);
        }, delay);
    };

    // 컴포넌트가 언마운트될 때 타이머 해제
    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return debounceFunction;
};

export default useDebounce;
