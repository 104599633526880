// 시간 00 ~ 09 접두사
export const prefixTime = time => String(time).padStart(2, '0');

// 날짜 am,pm 형태로 변경
export const convertAmpmHours = hours => {
    const num = hours % 12 ? hours % 12 : 12;
    const ampm = hours >= 12 ? ' PM' : ' AM';
    return `${num} ${ampm}`;
};

// Convert to UTC and unix time
export const toUTCUnixTime = unixTime => {
    let currentDate;
    if (unixTime) {
        const unixDefaultLength = new Date().getTime().toString().length;
        const unix = unixTime.toString().length !== unixDefaultLength ? unixTime * 1000 : unixTime;
        currentDate = new Date(unix);
    } else {
        currentDate = new Date();
    }
    return Math.floor(new Date(currentDate.toISOString()).getTime() / 1000);
};

/**
 *
 * 파라미터 값에 falsy한 값을 빼주기 위한 함수
 * @param {object} searchRequirement 파라미터에 필요한 값
 * @param {boolean} useIsAll 파라미터 값이 없을 경우 isAll 보낼것인지에 대한 여부
 * @return {object} 파라미터 값 리턴
 * @author create by 정진범
 */
export const makeParameter = ({ searchRequirement, useIsAll = false }) => {
    const param = {};
    for (const property in searchRequirement) {
        if (searchRequirement[property]) {
            param[property] = searchRequirement[property];
        }
    }
    if (useIsAll) {
        if (Object.keys(param).length === 0) {
            param['isAll'] = 'Y';
        }
    }
    return param;
};

/**
 * category property 정보를 사용자가 인식 가능한 데이터로 변환
 *
 * @param target 변경할 카테고리 속성 정보
 * @param base 카테고리 속성의 기본값
 * @returns {{[p: string]: any}}
 */
export const convertProperties = (target, base) => {
    return Object.fromEntries(
        base.map(({ propertyId, inputValues }) => {
            if (!target[propertyId]) {
                return [propertyId, null];
            }
            if (!inputValues.length) {
                return [propertyId, target[propertyId]];
            }
            // 속성값 타입이 다중인 경우
            const findValue = inputValues.find(({ value: inputValue }) => inputValue === target[propertyId]);
            if (!findValue) {
                return [propertyId, null];
            }
            return [propertyId, findValue.name];
        }),
    );
};
