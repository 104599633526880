import React, { useEffect } from 'react';
import { WidgetProps } from '../../staticInfo';
import ContextProvider from '@components/ContextProvider';
import WidgetCard from '../../Components/WidgetCard';
import assetManualManagementSlice, {
    AssetManualManagementDispatchContext,
    AssetManualManagementStateContext,
    setManagementMode,
    toggleRegisterModal,
    useAssetManualManagementDispatchContext,
    useAssetManualManagementStateContext,
} from './slice';
import { useSettings } from '../../util/useSettings';
import Button from '@components/Button';
import useTranslation from '@hooks/useTranslation';
import RegisterModal from './Components/RegisterModal';
import ManualTable from './Components/ManualTable';

const AssetManualManagement = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    const settings = useSettings(widgetInfo.config);

    return (
        <ContextProvider
            StateContext={AssetManualManagementStateContext}
            DispatchContext={AssetManualManagementDispatchContext}
            slice={assetManualManagementSlice}
            initializer={state => {
                const { managementMode } = settings;
                if (typeof managementMode === 'boolean') {
                    return { ...state, managementMode };
                }
                return state;
            }}
        >
            <ContextConsumer widgetInfo={widgetInfo} {...restProps}>
                {children}
            </ContextConsumer>
        </ContextProvider>
    );
};

const ContextConsumer = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    const t = useTranslation('AssetManualManagement');
    const dispatch = useAssetManualManagementDispatchContext()!;
    const { registerModal, managementMode } = useAssetManualManagementStateContext();

    // 설정화면에서 변경할 경우 바로 적용
    const settings = useSettings(widgetInfo.config);
    useEffect(() => {
        dispatch(setManagementMode(!!settings.managementMode));
    }, [settings]);

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            {...restProps}
            headerAction={
                managementMode && (
                    <Button
                        className={'btn-secondary'}
                        onClick={() => {
                            dispatch(toggleRegisterModal());
                        }}
                    >
                        {t('Register')}
                    </Button>
                )
            }
        >
            <ManualTable />
            <RegisterModal open={registerModal} />
            {children}
        </WidgetCard>
    );
};

export default AssetManualManagement;
