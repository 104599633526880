import React from 'react';
import styled from 'styled-components';
import OutpatientAllBaselineTestingCounts from './Components/OutpatientAllBaselineTestingCounts';
import OutpatientCompleteBaselineTestingCounts from './Components/OutpatientCompleteBaselineTestingCounts';
import OutpatientSafeBaselineTestingCounts from './Components/OutpatientSafeBaselineTestingCounts';

const OutpatientBaselineTestingCounts = ({ outpatientCount }) => {
    const { total, inspectCountStat, safetyCountStat } = outpatientCount;
    return (
        <Container>
            <OutpatientAllBaselineTestingCounts total={total} />
            <OutpatientCompleteBaselineTestingCounts inspectCountStat={inspectCountStat} />
            <OutpatientSafeBaselineTestingCounts safetyCountStat={safetyCountStat} />
        </Container>
    );
};
const Container = styled.div`
    display: flex;
    width: 100%;
    & > div {
        &:nth-child(1) {
            flex: 1;
        }
        flex: 2;
    }
    margin-bottom: 5px;
`;
export default OutpatientBaselineTestingCounts;
