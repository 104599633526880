import React, { useState } from 'react';
import { InputProps } from '@components/type';
import { RequiredKeys } from '@util/type/util';
import cx from 'classnames';

type RadioButtonInputProps = RequiredKeys<InputProps, 'name'> & {
    radioList: { label: string; value: string | number }[];
    radioGroupClassName?: string;
};

/**
 * Radio Button Input
 * @param name 동적으로 label 생성 시 key 값으로 사용
 * @param radioList 동적으로 생성 될 radio List
 * @param handleChange Radio 클릭 시 이벤트
 * @param radioGroupClassName
 */
const RadioButton = ({ name, radioList, handleChange, radioGroupClassName }: RadioButtonInputProps) => {
    const [radioChecked, setRadioChecked] = useState<string | number>('');

    return (
        <>
            {radioList.map(({ label, value }, index) => {
                const radioKey = `${name}_${value}_${index}`;
                return (
                    <label key={radioKey} className={cx('pnt-radio', radioGroupClassName)} htmlFor={radioKey}>
                        <p>{label}</p>
                        <input
                            type={'radio'}
                            id={radioKey}
                            value={value}
                            checked={radioChecked === value}
                            name={name}
                            onChange={event => {
                                setRadioChecked(event.target.value);
                                if (typeof handleChange === 'function') handleChange(event);
                            }}
                        />
                    </label>
                );
            })}
        </>
    );
};

export default RadioButton;
