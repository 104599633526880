import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import { MenuListProps } from 'react-select';
import cx from 'classnames';

import { Select } from '@components';
import { reducer } from '@components/Select/utils';
import { SelectProps } from '@components/Select';
import { OptionType } from '@components/type';
import { DropdownIndicator } from '@components/Select/Components/Common';

import { SingleTreeControl, SingleTreeMenuList, SingleTreeOption } from './Parts';

export const SingleTreeSelectContext = createContext({ labelKey: 'label', valueKey: 'value' });

interface SingleTreeSelectProps extends Partial<SelectProps> {
    data: OptionType[]; // options
    selected?: OptionType[] | any; // value
    title?: string;
    treeKey?: string;
    parentKey?: string;
    MenuListComponent?: React.ComponentType<MenuListProps<OptionType>>;
}

const SingleTreeSelect = ({
    data = [],
    selected = [],
    title,
    labelKey = 'label',
    valueKey = 'value',
    treeKey,
    parentKey = 'parent',
    onChange,
    MenuListComponent,
    isModalSelect,
    className = '',
    isMap = false,
}: SingleTreeSelectProps) => {
    const [state, dispatch] = useReducer(reducer, {
        title,
        labelKey,
        valueKey,
        treeKey: treeKey ? treeKey : valueKey,
        parentKey,
        flattenData: data,
        treeData: [],
    });

    const selectedOptions = useMemo(() => {
        const selectedKeyArr = [selected].map((v: OptionType) => {
            if (typeof v === 'object') {
                return v[valueKey];
            }
            return v;
        });

        return data.reduce((acc, curr) => {
            if ((selectedKeyArr || []).includes(curr[valueKey])) {
                acc.push(curr);
            }
            return acc;
        }, []);
    }, [data, selected, valueKey]);

    useEffect(() => {
        dispatch({ type: 'UPDATE_FLATTEN_DATA', payload: data });
    }, [data]);

    useEffect(() => {
        dispatch({ type: 'UPDATE_TITLE', payload: title });
    }, [title]);

    return (
        <SingleTreeSelectContext.Provider value={state}>
            <Select
                isModalSelect={isModalSelect}
                backspaceRemovesValue={false}
                components={{
                    Control: SingleTreeControl,
                    DropdownIndicator,
                    Option: SingleTreeOption,
                    MenuList: MenuListComponent ? MenuListComponent : SingleTreeMenuList,
                }}
                closeMenuOnSelect={false}
                onChange={(selected, actionMeta) => {
                    if (!Object(selected).hasOwnProperty('children') && typeof onChange === 'function') {
                        onChange(selected, actionMeta);
                    }
                }}
                options={state.treeData}
                className={cx(isMap && `leaflet-map-select`, className)}
                labelKey={labelKey}
                valueKey={valueKey}
                value={selectedOptions}
                isMap={isMap}
            />
        </SingleTreeSelectContext.Provider>
    );
};

export default SingleTreeSelect;
