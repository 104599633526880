import { getQuantityCheckLogListApi, postQuantityCheckHistoryExcelApi } from '@api/am/management';
import { Button, Table } from '@components';
import InnerItemCard from '@components/Card/InnerItemCard';
import { useAsync, useTranslation } from '@hooks';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import SearchGroupAM from '../Components/SearchGroupAM';
import WidgetCard from '../../Components/WidgetCard';
import { ASSET_MANAGEMENT } from '../../constants';
import { useAsyncExcel } from '../../util/useAsyncExcel';
import useCategoryList from '../util/useCategoryList';
import useModal from '../../util/useModal';
import useMultiOptionsByRole from '../util/useMultiOptionsByRole';
import useRangeDateAM from '../util/useRangeDateAM';
import NormalModal from './NormalModal';
import useColumns from '@hooks/useColumns';
import * as column from '@util/grid/column';
import { COLUMN_METAS, DEFAULT_RESPONSE, DEFAULT_VALUE, FIXED_PARAMS, PREFIX_LIST, ROWS_DUMMY } from './constants';
import moment from 'moment';
import { SCREEN_MODE_MONITORING } from '@reducer/ScreenInfo';
import useAppSelector from '@hooks/useAppSelector';
import { DATE_FORMAT } from '@util/times';

const AssetQuantityCheckHistory = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();
    const t = useTranslation(ASSET_MANAGEMENT);
    const columnT = useTranslation('CommonColumn');
    const { mode } = useAppSelector(state => state.ScreenInfo);

    const [isNormalOpen, handleNormalToggle, handleNormalOpen] = useModal();
    const [rowInfo, setRowInfo] = useState();

    const wardListValues = useMultiOptionsByRole();
    const categoryValues = useCategoryList();
    const rangeDateValues = useRangeDateAM();

    const { state, promise: getList } = useAsync({
        promise: getQuantityCheckLogListApi,
        immediate: mode === SCREEN_MODE_MONITORING,
        param: {
            ...FIXED_PARAMS,
            ward:
                wardListValues.selectedWardList.length !== 0
                    ? wardListValues.selectedWardList.toString()
                    : wardListValues.options.map(v => v.value).toString(),
            categoryCode: categoryValues.selectedCategoryList.toString(),
            startCheckDate: rangeDateValues.startDate,
            endCheckDate: rangeDateValues.endDate,
        },
        deps: [
            wardListValues.selectedWardList.length,
            categoryValues.selectedCategoryList.length,
            rangeDateValues.startDate,
            rangeDateValues.endDate,
        ],
        reject: err => console.error(err),
        keepState: true,
    });

    const filteredData =
        mode === SCREEN_MODE_MONITORING
            ? {
                  ...DEFAULT_RESPONSE,
                  ...state.response,
                  rows: state.response?.rows || [],
              }
            : { ...DEFAULT_RESPONSE, rows: ROWS_DUMMY };

    const handleMemoClick = useCallback(
        rowInfo => {
            setRowInfo(rowInfo);
            handleNormalOpen();
        },
        [handleNormalOpen],
    );

    // table column data
    const columns = useColumns(
        [
            column.index({ width: 50 }),
            column.date({
                width: 100,
                accessor: 'regDate',
                Cell: ({ value }) => (value ? moment.unix(value).format(DATE_FORMAT.YMD) : DEFAULT_VALUE),
            }),
            column.memo({
                className: 'p-0',
                Cell: ({ row, value }) => {
                    const handleMemo = () => handleMemoClick(row.original);
                    return (
                        <div className="d-flex h-100 px-2 align-center cursor-pointer" onClick={handleMemo}>
                            <span className="text-ellipsis text-decoration-underline text-primary">{value}</span>
                        </div>
                    );
                },
            }),
            column.categoryName({ width: 270 }),
        ].concat(
            ...PREFIX_LIST.map(prefix => [
                {
                    Header: `${prefix} quantity`,
                    headerClassName: 'text-ellipsis',
                    className: 'text-nowrap',
                    accessor: prefix,
                    width: 100,
                    Cell: ({ row }) => {
                        const original = row.original;
                        const count = original[`${prefix}Count`];
                        const RTLSCount = original[`${prefix}RTLSCount`];
                        return `${count || DEFAULT_VALUE} / ${RTLSCount || DEFAULT_VALUE}`;
                    },
                },
                column.inspector({ accessor: `${prefix}CheckerName`, width: 90 }),
                column.inspectorUsername({
                    accessor: `${prefix}CheckerId`,
                    width: 100,
                    Cell: ({ value }) => {
                        if (!value) {
                            return DEFAULT_VALUE;
                        }
                        if (value.indexOf('@') < 0) {
                            return value;
                        }
                        return value.substring(0, value.indexOf('@'));
                    },
                }),
                column.inspectionDate({
                    accessor: `${prefix}CheckDate`,
                    width: 90,
                    className: 'text-nowrap',
                    Cell: ({ value }) => (value ? moment.unix(value).format(DATE_FORMAT.HMS) : DEFAULT_VALUE),
                }),
            ]),
        ),
        columnT,
        [handleMemoClick, t],
    );

    const triggerDownload = useAsyncExcel({
        promise: postQuantityCheckHistoryExcelApi,
        param: {
            countCheckAssetLogSearchParam: {
                ...state.request,
            },
            excelDownloadRequest: {
                zipFileName: 'countCheckLogsZip',
                fileName: `countCheckLog_${moment().format(DATE_FORMAT.FLAT_YMD_HMS)}`,
                columnMetas: COLUMN_METAS,
            },
        },
    });

    const handleDownloadClick = () => {
        triggerDownload();
    };

    const handlePagination = page => {
        getList({ ...state.request, page });
    };

    return (
        <>
            <WidgetCard
                ref={widgetRef}
                widgetInfo={widgetInfo}
                searchFilter={
                    <SearchGroupAM
                        wardListValues={wardListValues}
                        categoryValues={categoryValues}
                        rangeDateValues={rangeDateValues}
                    />
                }
                bodyClassName="pt-0"
                {...restProps}
            >
                <InnerItemCard
                    header={{
                        title: t('Asset Quantity History Check', 'Widget'),
                        action: (
                            <Button className="btn-darkgray" iconName={'download'} onClick={handleDownloadClick}>
                                {t('Download')}
                            </Button>
                        ),
                    }}
                    className="mt-2"
                >
                    <TableWrapper>
                        <Table
                            columns={columns}
                            loading={state.isLoading}
                            data={filteredData}
                            onPageChange={handlePagination}
                            noDataMessage={t('No check history results.')}
                        />
                    </TableWrapper>
                </InnerItemCard>
                {children}
            </WidgetCard>
            {isNormalOpen && <NormalModal isOpen={isNormalOpen} toggleModal={handleNormalToggle} rowInfo={rowInfo} />}
        </>
    );
};

const TableWrapper = styled.div`
    height: 40.25rem;
`;

export default AssetQuantityCheckHistory;
