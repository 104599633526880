import React, { useMemo } from 'react';
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import InnerItemCard from '@components/Card/InnerItemCard';
import Search from './Search';
import { Table } from '@components';
import moment from 'moment';
import { setOutpatientListParam } from '../OutpatientUnattendedReceptionStatusSlice';
import { useOutpatientUnattendedReceptionDispatch } from '../index';

const RECEPTION_RESULT = 'Er-100';
const StatusList = ({ outpatientList }) => {
    const t = useTranslation('OutpatientUnattendedReceptionStatus');
    const dispatch = useOutpatientUnattendedReceptionDispatch();

    const columns = useMemo(
        () => [
            {
                Header: t('Category'),
                accessor: 'category',
            },
            {
                Header: t('Receipt Date'),
                accessor: 'receptionDate',
                Cell: ({ value }) => {
                    return value ? moment.unix(value).format('YYYY-MM-DD') : ' - ';
                },
            },
            {
                Header: t('Receipt Time'),
                accessor: 'receptionTime',
                Cell: ({ value }) => {
                    return value ? moment.unix(value).format('HH:mm') : ' - ';
                },
                width: 70,
            },
            {
                Header: t('Patient Name'),
                accessor: 'patientName',
            },
            {
                Header: t('Register Num'),
                accessor: 'patientRegNum',
            },
            {
                Header: t('Reservation Date'),
                accessor: 'inspectionDate',
                Cell: ({ value }) => {
                    return value ? moment.unix(value).format('YYYY-MM-DD') : ' - ';
                },
            },
            {
                Header: t('Reservation Time'),
                accessor: 'inspectionTime',
                Cell: ({ value }) => {
                    return value ? moment.unix(value).format('HH:mm') : ' - ';
                },
                width: 70,
            },
            {
                Header: t('Department'),
                accessor: 'specialties',
            },
            {
                Header: t('Attending Physician'),
                accessor: 'doctor',
            },
            {
                Header: t('Same Day Reception'),
                accessor: 'isTodayReception',
                Cell: ({ row: { original } }) => (
                    <span className="">{original.isTodayReception === 'Y' ? 'O' : ''}</span>
                ),
            },
            {
                Header: t('Result'),
                accessor: 'receptionResult',
                Cell: ({ row: { original } }) => (
                    <span className={original.receptionResult === RECEPTION_RESULT ? 'color-danger' : ''}>
                        {original.receptionResult}
                    </span>
                ),
            },
        ],
        [t],
    );

    const handlePageChange = pageIndex => {
        dispatch(
            setOutpatientListParam(prevState => {
                return { ...prevState, page: pageIndex };
            }),
        );
    };

    return (
        <Container>
            <InnerItemCard
                className={'h-100'}
                bodyClassName={'h-100'}
                header={{
                    titleIcon: 'assignment',
                    title: t('Outpatient Unattended Reception'),
                }}
            >
                <Search />
                <TableContainer className={'h-90'}>
                    <Table
                        data={outpatientList}
                        columns={columns}
                        onPageChange={handlePageChange}
                        dynamicRowHeight={true}
                    />
                </TableContainer>
            </InnerItemCard>
        </Container>
    );
};

const Container = styled.div`
    height: 550px;
    margin-top: 5px;
`;
const TableContainer = styled.div`
    height: 250px;
    margin-bottom: 5px;
`;

export default StatusList;
