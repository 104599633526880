import React, { useEffect, useState } from 'react';
import Control from 'react-leaflet-control';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Button, GeofenceLayer, Map, RotatedImageOverlay } from '@components';
import { SCREEN_MODE_EDIT } from '@reducer/ScreenInfo';
import { useAsync } from '@hooks';
import { fetchGeofenceList } from '@api/common/geofence';
import { fetchEntryCountByHoursApi, fetchEntryCountInSelectedTimeRangeApi } from '@api/statistics';
import { selectFirstFloor } from '@reducer/Common/FloorInfo';

import { entryCountFakeDataByDate, entryCountFakeDataByHour } from './constants';
import { useHourlyCongestionStatusDispatch, useHourlyCongestionStatusContext } from './index';
import { setSelectedFloor, setSelectedFloorInfo } from './hourlyCongestionStatusSlice';
import SearchConditions from './Component/SearchConditions';
import HourlyCongestionStatusFilterGroup from './Component/HourlyCongestionStatusFilterGroup';
import { useSettings } from '../../util/useSettings';
import WidgetCard from '../../Components/WidgetCard';
import LineChartByTime from './Component/LineChartByTime';
import LineChartByDate from './Component/LineChartByDate';

const HourlyCongestionStatusContainer = ({ widgetInfo, children, ...restProps }) => {
    const { config } = widgetInfo;
    const { hiddenMapTile } = useSettings(config);
    const { floorList } = useSelector(state => state.FloorInfo);
    const { mode } = useSelector(state => state.ScreenInfo);
    const dispatch = useHourlyCongestionStatusDispatch();
    const {
        selectedCategory,
        selectedFloor,
        selectedFloorInfo,
        startDate,
        endDate,
    } = useHourlyCongestionStatusContext();

    const [showSearchArea, setShowSearchArea] = useState(false);
    const [selectedGeofence, setSelectedGeofence] = useState({});
    const [geofenceInfo, setGeofenceInfo] = useState([]);
    const [filterParam, setFilterParam] = useState({});
    const [entryInfoInTimeRange, setEntryInfoInTimeRange] = useState([]);
    const [entryInfoByHours, setEntryInfoByHours] = useState([]);

    const confirmedTile = hiddenMapTile !== undefined ? !hiddenMapTile : true;
    const unixStartDate = startDate ? moment(startDate).unix() : null;
    const unixEndDate = endDate ? moment(endDate).unix() : null;
    const { floor, floorId } = selectFirstFloor(floorList);

    const { promise: getGeofenceList } = useAsync({
        promise: fetchGeofenceList,
        resolve: res => {
            if (res) {
                setGeofenceInfo(
                    res.rows.map(geofence => {
                        return {
                            ...geofence,
                            bounds: [geofence.latLngList.map(({ lat, lng }) => [lat, lng])],
                        };
                    }),
                );
            }
        },
    });

    const { promise: getEntryCountInTimeRange } = useAsync({
        promise: fetchEntryCountInSelectedTimeRangeApi,
        resolve: ({ uniqueMap, visitMap }) => {
            let tempData = [];
            if (uniqueMap && visitMap) {
                const timeIndex = Object.keys(visitMap).sort();
                tempData = timeIndex.map(time => ({
                    date: time,
                    visitCount: visitMap[time],
                    uniqueCount: uniqueMap[time],
                }));
            }
            setEntryInfoInTimeRange(tempData);
        },
    });

    const { promise: getEntryCountByHours } = useAsync({
        promise: fetchEntryCountByHoursApi,
        resolve: res => {
            let tempObj = [];
            if (res.totalEntryMap && res.uniqueEntryMap) {
                const totalEntryMap = Object.entries(res.totalEntryMap);
                const uniqueEntryMap = Object.entries(res.uniqueEntryMap);
                totalEntryMap.forEach((time, index) => {
                    tempObj.push({
                        time: totalEntryMap[index][0],
                        totalEntry: totalEntryMap[index][1],
                        uniqueEntry: uniqueEntryMap[index][1],
                    });
                });
            }

            setEntryInfoByHours(tempObj);
        },
    });

    const handleSearchBtnClick = () => {
        getAllChartAndGeofence();
        setShowSearchArea(false);
    };

    const getAllChartAndGeofence = () => {
        getEntryCountInTimeRange({
            ...filterParam,
            startDate: unixStartDate,
            endDate: unixEndDate,
        });
        getEntryCountByHours({
            ...filterParam,
            startDate: unixStartDate,
            endDate: unixEndDate,
        });
        getGeofenceList({ floor: selectedFloor });
    };

    useEffect(() => {
        if (floor) {
            dispatch(setSelectedFloor(floorId));
            dispatch(setSelectedFloorInfo(floor));
            getGeofenceList({ floor: floorId });
            getAllChartAndGeofence();
        }
    }, []);

    // selectedFloor가 변할 때
    useEffect(() => {
        if (selectedFloor) {
            getGeofenceList({ floor: selectedFloor });
            let getSelectedFloorInfo = floorList.find(floor => floor.floorId === selectedFloor);
            dispatch(setSelectedFloorInfo(getSelectedFloorInfo));
        }
    }, [selectedFloor]);

    useEffect(() => {
        const paramInfo = {};
        if (selectedCategory) {
            paramInfo.categoryCode = selectedCategory.categoryCode;
        }
        if (selectedFloor) {
            paramInfo.floorIds = selectedFloor;
        }
        if (selectedGeofence.fcNum) {
            paramInfo.fcNums = String(selectedGeofence.fcNum);
        }
        setFilterParam(paramInfo);
    }, [selectedCategory, selectedFloor, selectedGeofence]);

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            {...restProps}
            headerAction={<SearchConditions setShowSearchArea={setShowSearchArea} floor={floor} />}
            searchFilter={
                showSearchArea && <HourlyCongestionStatusFilterGroup handleSearchBtnClick={handleSearchBtnClick} />
            }
            bodyClassName={'p-0'}
        >
            <div className={'d-grid h-100'} style={{ gridTemplateRows: 'auto 750px' }}>
                <div className={'h-100 p-1'}>
                    <Map tile={confirmedTile} rotation={selectedFloorInfo?.rotation}>
                        {selectedFloorInfo && selectedFloorInfo.floorName && (
                            <Control position="topleft" className={'control-container'}>
                                {/*<div className={'item-container'}>*/}
                                {/*    <span className={'map-text'}>{selectedFloorInfo.floorName}</span>*/}
                                {/*</div>*/}
                                <Button className={'pnt-nameplate leaflet-map-nameplate bg-gray bg-d-3'}>
                                    <span className={'material-icons-round'}>info_outline</span>
                                    {selectedFloorInfo.floorName}
                                </Button>
                            </Control>
                        )}
                        {selectedFloorInfo.imgURL && (
                            <RotatedImageOverlay
                                key={selectedFloorInfo.floorId}
                                url={selectedFloorInfo.imgURL}
                                deg={selectedFloorInfo.deg}
                                bounds={selectedFloorInfo.bounds}
                                onLoad={e => {
                                    const { target: layer } = e;
                                    layer._map.fitBounds(layer.getBounds());
                                }}
                            />
                        )}
                        {geofenceInfo.length > 0 && (
                            <GeofenceLayer
                                selectedGeofence={selectedGeofence}
                                geofenceList={geofenceInfo}
                                handleClick={geofence => {
                                    geofence.fcNum === selectedGeofence.fcNum
                                        ? setSelectedGeofence({})
                                        : setSelectedGeofence(geofence);
                                }}
                            />
                        )}
                    </Map>
                </div>
                <div className={'d-grid h-100 p-3 gap-2'} style={{ gridTemplateRows: '350px 350px' }}>
                    <LineChartByDate
                        dataForEntryCount={mode === SCREEN_MODE_EDIT ? entryCountFakeDataByDate : entryInfoInTimeRange}
                    />
                    <LineChartByTime
                        dataForCongestion={mode === SCREEN_MODE_EDIT ? entryCountFakeDataByHour : entryInfoByHours}
                    />
                </div>
            </div>
            {children}
        </WidgetCard>
    );
};

export default HourlyCongestionStatusContainer;
