import { createSlice } from '@reduxjs/toolkit';

const SENSOR_NUM_KEY = 'sensorNum';

const SENSOR_TYPE = {
    ITEM_1: 'SOUND_NOISE',
    ITEM_2: 'CO2',
    ITEM_3: 'TEMPERATURE',
    ITEM_4: 'HUMIDITY',
};

export const initialState = {
    sensingTypeList: [],
    sensorList: {},
    isDetail: false,
    targetId: null,
};

const realTimeEnvironmentalSensorSlice = createSlice({
    name: 'realTimeEnvironmentalSensor',
    initialState,
    reducers: {
        setSensorList: (state, action) => {
            const data = action.payload;
            if (!data) {
                return;
            }

            state.sensorList = Object.fromEntries(
                Object.entries(data).map(([key, value]) => {
                    let sensor = {};
                    value.forEach(sensorItem => {
                        if (!state.sensingTypeList.includes(sensorItem.sensingType)) {
                            state.sensingTypeList.push(sensorItem.sensingType);
                        }
                        sensor = {
                            ...sensor,
                            properties: sensorItem.properties,
                            sensorNum: sensorItem.sensorNum,
                            [sensorItem.sensingType]: {
                                value: sensorItem.currentValue,
                                status: sensorItem.status,
                                measurementUnit: sensorItem.measurementUnit,
                                regDate: sensorItem.regDate,
                            },
                            status: sensorItem.status,
                            targetId: sensorItem.targetId,
                            targetName: sensorItem.targetName,
                            targetNum: sensorItem.targetNum,
                            isBookmark: sensorItem.isBookmark,
                            modDate: sensorItem.modDate,
                        };
                    });
                    return [key, sensor];
                }),
            );
        },
        updateSensorList: (state, action) => {
            const socketSensorData = action.payload;
            if (!socketSensorData) {
                return;
            }
            try {
                const {
                    comNum,
                    sensor,
                    target,
                    targetCategory,
                    sensingState,
                    sensingValues,
                    unixTime,
                } = socketSensorData;
                const { sensorNum, sensorId, sensorName } = sensor;

                const { targetNum: socketTargetNum } = target;

                const socketSoundNoise =
                    sensingState[SENSOR_TYPE.ITEM_1] && sensingValues[SENSOR_TYPE.ITEM_1]
                        ? {
                              value: sensingValues[SENSOR_TYPE.ITEM_1],
                              status: sensingState[SENSOR_TYPE.ITEM_1],
                              regDate: unixTime,
                          }
                        : {};
                const socketCo2 =
                    sensingState[SENSOR_TYPE.ITEM_2] && sensingValues[SENSOR_TYPE.ITEM_2]
                        ? {
                              value: sensingValues[SENSOR_TYPE.ITEM_2],
                              status: sensingState[SENSOR_TYPE.ITEM_2],
                              regDate: unixTime,
                          }
                        : {};
                const socketTemperature =
                    sensingState[SENSOR_TYPE.ITEM_3] && sensingValues[SENSOR_TYPE.ITEM_3]
                        ? {
                              value: sensingValues[SENSOR_TYPE.ITEM_3],
                              status: sensingState[SENSOR_TYPE.ITEM_3],
                              regDate: unixTime,
                          }
                        : {};
                const socketHumidity =
                    sensingState[SENSOR_TYPE.ITEM_4] && sensingValues[SENSOR_TYPE.ITEM_4]
                        ? {
                              value: sensingValues[SENSOR_TYPE.ITEM_4],
                              status: sensingState[SENSOR_TYPE.ITEM_4],
                              regDate: unixTime,
                          }
                        : {};

                // const updateSensingData = Object.fromEntries(
                //     Object.values(SENSOR_TYPE).map(key => {
                //         const value =
                //             sensingState[key] && sensingValues[key]
                //                 ? {
                //                       value: sensingValues[key],
                //                       status: sensingState[key],
                //                       regDate: unixTime,
                //                   }
                //                 : {};
                //         return [key, value];
                //     }),
                // );

                state.sensorList = (function loop(entryValues) {
                    return Object.fromEntries(
                        Object.keys(entryValues).map(key => {
                            // 특정 key 값이 존재 할 때까지 재귀
                            if (!Object.keys(entryValues[key]).includes(SENSOR_NUM_KEY)) {
                                return [key, loop(entryValues[key])];
                            }
                            // 현재 entry value
                            const currentValue = entryValues[key];
                            // 대상 key 또는 센서 key 가 맞지 않는 경우
                            if (currentValue.targetNum !== socketTargetNum || currentValue.sensorNum !== sensorNum) {
                                return [key, currentValue];
                            }

                            // update sensor value
                            const updateValue = {
                                ...entryValues[key],
                                SOUND_NOISE: {
                                    ...currentValue.SOUND_NOISE,
                                    ...socketSoundNoise,
                                },
                                CO2: {
                                    ...currentValue.CO2,
                                    ...socketCo2,
                                },
                                TEMPERATURE: {
                                    ...currentValue.TEMPERATURE,
                                    ...socketTemperature,
                                },
                                HUMIDITY: {
                                    ...currentValue.HUMIDITY,
                                    ...socketHumidity,
                                },
                                regDate: unixTime,
                            };

                            return [key, updateValue];
                        }),
                    );
                })(state.sensorList);
            } catch (e) {
                console.error(e);
            }
        },
        setIsDetail: (state, action) => {
            state.isDetail = action.payload;
        },
        setTargetId: (state, action) => {
            state.targetId = action.payload;
        },
    },
});
export const { setSensorList, updateSensorList, setIsDetail, setTargetId } = realTimeEnvironmentalSensorSlice.actions;
export default realTimeEnvironmentalSensorSlice;
