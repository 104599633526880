import React, { useEffect, useRef } from 'react';

import useTranslation from '@hooks/useTranslation';

import { useSettings } from '../../util/useSettings';
import { clearIotItemStatusWidget } from './iotItemStatusSlice';
import WidgetCard from '../../Components/WidgetCard';
import IotItemWrapper from './Components/IotItemWrapper';
import { useIotItemStatusDispatch } from './index';

const IotItemStatusContainer = ({ widgetInfo, children, ...restProps }) => {
    const t = useTranslation('IotItemStatus');
    const widgetRef = useRef();
    const { config } = widgetInfo;
    const { categoryCode: currentCategoryCode } = useSettings(config);
    const dispatch = useIotItemStatusDispatch();

    useEffect(() => {
        return () => {
            dispatch(clearIotItemStatusWidget());
        };
    }, []);

    return (
        <WidgetCard ref={widgetRef} widgetInfo={widgetInfo} subTitle={t(currentCategoryCode)} {...restProps}>
            <IotItemWrapper widgetInfo={widgetInfo} />
            {children}
        </WidgetCard>
    );
};

export default IotItemStatusContainer;
