import React from 'react';
import Card from '../../Components/WidgetCard';
import { Table, TableBody, TableHead, Td, Th, Tr } from '@components/Table/SimpleTable';
import useMonitor from '../../util/useMonitor';
import { fetchScannerList } from '@api/common/asset';
import { useTranslation } from '@hooks';
import useFilter from '@hooks/useFilter';
import { DUMMY_DATA } from './constants';
import ScannerStatusChart from './ScannerStatusChart';

const SUMMARY_AREA_HEIGHT = '90px';
const TABLE_HEIGHT = '65px';

const ScannerStatus = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const t = useTranslation('ScannerStatus');
    const filterParam = useFilter();

    const { data } = useMonitor({
        config,
        defaultData: DUMMY_DATA,
        dynamicParam: filterParam,
        fetchData: fetchScannerList,
        makeData: ({ newData }) => {
            const scannerList = newData.rows;
            const chartData = [];
            chartData.push({
                key: 'on',
                name: 'On-Line',
                value: scannerList ? scannerList.filter(v => v.status === 'N').length : 0,
            });
            chartData.push({
                key: 'off',
                name: 'Off-Line',
                value: scannerList ? scannerList.filter(v => v.status === 'A').length : 0,
            });
            return chartData;
        },
    });

    return (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <div className={'w-100 pb-3'} style={{ height: `calc(100% - ${SUMMARY_AREA_HEIGHT})` }}>
                <ScannerStatusChart data={data} />
            </div>
            <div className={'w-100'} style={{ height: SUMMARY_AREA_HEIGHT }}>
                {t('Current / Count')}
                <Table style={{ height: TABLE_HEIGHT }} className={'table-for-viewer'}>
                    <TableHead>
                        <Tr className={'active'}>
                            <Th className={'justify-content-center'}>{t('All Scanner')}</Th>
                            <Th className={'justify-content-center'}>{t('On-Line')}</Th>
                            <Th className={'justify-content-center'}>{t('Off-Line')}</Th>
                        </Tr>
                    </TableHead>
                    <TableBody>
                        <Tr>
                            <Td className={'justify-content-center'}>{data[0].value + data[1].value}</Td>
                            <Td className={'justify-content-center'}>{data[0].value}</Td>
                            <Td className={'justify-content-center'}>{data[1].value}</Td>
                        </Tr>
                    </TableBody>
                </Table>
            </div>
            {children}
        </Card>
    );
};

export default ScannerStatus;
