import { createSlice } from '@reduxjs/toolkit';
import { defaultStaticFilterInfo } from '../Components/MainPages/Components/FilterWidget/Option';
import { store } from '../index';
import produce from 'immer';

const DEFAULT_CATEGORY_CODE = 'ASSET';
const INPUT_TYPE_LOGIN_GROUP = 'login_group';
const INPUT_TYPE_LOGIN = 'login';

export const initialFilterInfo = {
    filterInfoCondition: defaultStaticFilterInfo,
};

const initialState = {
    filterList: [],
    originFilterInfo: {},
    filterInfo: initialFilterInfo,
    filterParam: {},
};

const { actions, reducer } = createSlice({
    name: 'filterInfo',
    initialState,
    reducers: {
        setFilterList: (state, action) => {
            state.filterList = action.payload;
        },
        setSelectedFilter: {
            reducer: (state, action) => {
                const { filterInfo, categoryList } = action.payload;
                if (filterInfo) {
                    state.originFilterInfo = filterInfo;
                    state.filterInfo = filterInfo;
                    if (filterInfo && filterInfo.filterInfoCondition) {
                        state.filterParam = makeFilterParam(filterInfo, categoryList);
                    } else {
                        state.filterParam = {};
                    }
                } else {
                    state.originFilterInfo = {};
                    state.filterInfo = {};
                    state.filterParam = {};
                }
            },
            prepare: filterInfo => {
                const state = store.getState();
                const { categoryList, searchableCategoryPropertiesList } = state.CategoryInfo;
                if (filterInfo) {
                    const { categoryCodes } = filterInfo;
                    const userInfo = state.UserInfo.userInfo;

                    // 필터 유형이 단일 카테고리 일 경우에만, 카테고리 속성에 대한 부유화 진행
                    if (categoryCodes && categoryCodes.split(',').length === 1) {
                        return {
                            payload: {
                                filterInfo: produce(filterInfo, draft => {
                                    draft.filterInfoCondition = enrichLoginGroup(
                                        draft,
                                        categoryCodes,
                                        searchableCategoryPropertiesList,
                                        userInfo.groupNums || [],
                                    );
                                    draft.filterInfoCondition = enrichLoginUser(
                                        draft,
                                        categoryCodes,
                                        searchableCategoryPropertiesList,
                                        userInfo.userNum,
                                    );
                                }),
                                categoryList,
                            },
                        };
                    }
                }
                return { payload: { filterInfo, categoryList } };
            },
        },
        updateFilterInfo: {
            reducer: (state, action) => {
                const { filterInfo, categoryList } = action.payload;
                if (filterInfo && filterInfo.filterInfoCondition) {
                    state.filterInfo.filterInfoCondition = filterInfo.filterInfoCondition;
                    state.filterParam = makeFilterParam({ ...state.filterInfo, ...filterInfo }, categoryList);
                } else {
                    state.filterInfo.filterInfoCondition = initialFilterInfo.filterInfoCondition;
                    state.filterParam = {};
                }
            },
            prepare: filterInfo => {
                const { categoryList } = store.getState().CategoryInfo;
                return { payload: { filterInfo, categoryList } };
            },
        },
    },
});

export const makeFilterParam = (filterInfo, categoryList = []) => {
    const { filterInfoCondition, categoryCodes } = filterInfo;
    const initialCategoryCodes = categoryCodes
        ? categoryCodes
              .split(',')
              .reduce((categoryAcc, curr) => {
                  categoryAcc = categoryAcc.concat(
                      categoryList.reduce((childrenCategoryAcc, { categoryCode, parentCode }) => {
                          if (parentCode === curr) {
                              childrenCategoryAcc.push(categoryCode);
                          }
                          return childrenCategoryAcc;
                      }, []),
                  );
                  return categoryAcc;
              }, categoryCodes.split(','))
              .join(',')
        : categoryCodes;

    let dynamicCount = 0;
    return filterInfoCondition.reduce(
        (acc, { conditionId, conditionValues, conditionType }) => {
            if (conditionValues.length) {
                if (conditionType === 'dynamic') {
                    acc['targetPropertiesSearch.categoryCode'] = categoryCodes ? categoryCodes : DEFAULT_CATEGORY_CODE;
                    conditionValues.forEach(value => {
                        acc[`targetPropertiesSearch.propertySearchs[${dynamicCount}].propertyId`] = conditionId;
                        acc[`targetPropertiesSearch.propertySearchs[${dynamicCount}].values`] = value;
                        dynamicCount++;
                    });
                } else {
                    acc[conditionId] = conditionValues.join(',');
                }
            }
            return acc;
        },
        { categoryCodes: initialCategoryCodes },
    );
};

function enrichLoginGroup(filterInfo, filterCategoryCode, searchableCategoryPropertiesList, groupNums) {
    const loginGroupProperties = searchableCategoryPropertiesList.reduce((acc, property) => {
        if (property.categoryCode === filterCategoryCode && property.inputType === INPUT_TYPE_LOGIN_GROUP) {
            acc.push(property.propertyId);
        }
        return acc;
    }, []);
    const conditionGroupNums = groupNums.map(groupNum => groupNum.toString());
    return filterInfo.filterInfoCondition.map(condition => {
        const enrichmentCondition = { ...condition };
        if (
            loginGroupProperties.includes(enrichmentCondition.conditionId) &&
            enrichmentCondition.conditionValues[0] === '-1'
        ) {
            enrichmentCondition.conditionValues = conditionGroupNums;
        }
        return enrichmentCondition;
    });
}

function enrichLoginUser(filterInfo, filterCategoryCode, searchableCategoryPropertiesList, userNum) {
    if (userNum) {
        const loginProperties = searchableCategoryPropertiesList.reduce((acc, property) => {
            if (property.categoryCode === filterCategoryCode && property.inputType === INPUT_TYPE_LOGIN) {
                acc.push(property.propertyId);
            }
            return acc;
        }, []);
        const conditionUserNum = [userNum.toString()];
        return filterInfo.filterInfoCondition.map(condition => {
            const enrichmentCondition = { ...condition };
            if (loginProperties.includes(condition.conditionId) && enrichmentCondition.conditionValues[0] === '-1') {
                enrichmentCondition.conditionValues = conditionUserNum;
            }
            return enrichmentCondition;
        });
    }
    return filterInfo;
}

export const { setFilterList, setSelectedFilter, updateFilterInfo } = actions;
export default reducer;
