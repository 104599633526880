import { UnixTimestamp } from '@util/type/util';
import moment from 'moment';

export const getStartOfDate = (date?: UnixTimestamp) => {
    return (date ? moment.unix(date) : moment()).startOf('day').unix();
};

export const getEndOfDate = (date?: UnixTimestamp) => {
    return (date ? moment.unix(date) : moment()).endOf('day').unix();
};
