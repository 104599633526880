import React from 'react';
import { Button, DatePicker } from '@components';
import moment from 'moment';
import SearchGroup from '../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import { SelectGroup } from '../../../MainPages/Components/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '../../../MainPages/Components/FilterSearchGroup';
import { ButtonGroup } from 'reactstrap';
import { useTranslation } from '@hooks';
import TIMES from '@util/times';

const MonitoringDatePicker = ({ handleDate, startDate, endDate, departmentValues }) => {
    const t = useTranslation('OutpatientBloodDrawStatus');

    const handleYesterdayButtonClick = () => {
        handleDate({
            start: startDate - TIMES.DAY_1_PER_SEC,
            end: endDate - TIMES.DAY_1_PER_SEC,
        });
    };
    const handleTomorrowButtonClick = () => {
        handleDate({
            start: startDate + TIMES.DAY_1_PER_SEC,
            end: endDate + TIMES.DAY_1_PER_SEC,
        });
    };
    const handleStartDate = selected => {
        handleDate({
            start: moment.unix(selected).startOf('day').unix(),
            end: moment.unix(selected).startOf('day').add(23, 'hours').add(59, 'm').add(59, 'second').unix(),
        });
    };
    const handleTodayButtonClick = () => {
        handleDate({
            start: moment().startOf('day').unix(),
            end: moment().startOf('day').add(23, 'hours').add(59, 'm').add(59, 'second').unix(),
        });
    };
    return (
        <FilterSearchGroup className={'p-0'}>
            <SearchGroup>
                <div className={'text-center'}>{t('Date')}</div>
                <SelectGroup className={'w-10'}>
                    <div className="datePicker-container">
                        <DatePicker
                            key={`dp_start_${startDate}`}
                            value={startDate}
                            handleChange={handleStartDate}
                            valueType="s"
                            maxDate={moment().startOf('days').valueOf()}
                            showTimeInput={false}
                            withoutTime
                        />
                    </div>
                </SelectGroup>
                <ButtonGroup>
                    <Button className="btn-gray mr-2 min-w-none" onClick={handleYesterdayButtonClick}>
                        {'<'}
                    </Button>
                    <Button
                        className="btn-gray mr-2 min-w-none"
                        disabled={startDate === moment().startOf('days').unix()}
                        onClick={handleTomorrowButtonClick}
                    >
                        {'>'}
                    </Button>
                    <Button className="btn-secondary mr-2" onClick={handleTodayButtonClick}>
                        {t('Today')}
                    </Button>
                </ButtonGroup>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default MonitoringDatePicker;
