import { reqGet, reqPost, oAuthPost, reqPut } from '../index';

export const login = param => reqPost('/v2/auth/login', param);

export const fetchApiLogin = param => reqPost(`/v3/api/common/login`, param);
export const fetchOAuthToken = param => oAuthPost('/oauth/token', param);
export const fetchUserInfo = param => reqGet('/v3/api/common/user/info', param);
//로그인 유저가 속한 그룹 정보 조회
export const getUserGroupInfoApi = param => reqGet('/v3/api/common/user/group', param);
// export const fetchUserInfo = param => reqGet('/v1/mobile/auth/login/info', param);

// OTP 인증메일 발송 요청
export const fetchSendOtp = param => reqPost('/v3/api/common/auth/send-otp', param);

// OTP 인증
export const fetchCheckOtp = param => reqPost('/v3/api/common/auth/check-otp', param);

// 비밀번호 변경
export const updatePassword = param => reqPut('/v3/api/common/user/mod/password', param);
export const getLicenseStatusApi = param => reqGet('/v3/api/lc01/license/status', param);
