import React, { createContext, useContext } from 'react';
import ContextProvider from '@components/ContextProvider';
import VitalSignsMonitoringContainer from './VitalSignsMonitoringContainer';
import vitalSignsMonitoringSlice from './vitalSignsMonitoringSlice';

/**
 * 활력징후 모니터링 위젯
 *
 * @since 2022-12-16
 * @author kunwoong-kim
 * */
export const VitalSignsMonitoringStateContext = createContext(null);
export const VitalSignsMonitoringDispatchContext = createContext(null);

export const useVitalSignsMonitoringContext = () => useContext(VitalSignsMonitoringStateContext);
export const useVitalSignsMonitoringDispatch = () => useContext(VitalSignsMonitoringDispatchContext);

const VitalSignsMonitoring = ({ children, widgetInfo, ...restProps }) => {
    return (
        <ContextProvider
            StateContext={VitalSignsMonitoringStateContext}
            DispatchContext={VitalSignsMonitoringDispatchContext}
            slice={vitalSignsMonitoringSlice}
        >
            <VitalSignsMonitoringContainer widgetInfo={widgetInfo} children={children} {...restProps} />
        </ContextProvider>
    );
};

export default VitalSignsMonitoring;
