import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useAsync } from '@hooks';
import { setLicenseStatus } from '@reducer/UserInfo';
import TIMES from '@util/times';
import { getLicenseStatusApi } from '@api/common/login';

export const Y = 'Y';
export const N = 'N';
type YN = 'Y' | 'N';

type VerificationMethod = 'ONLINE' | 'CLOSED';

type VerificationResult = 'NONE' | 'NO_PROBLEM' | 'HAS_PROBLEM' | 'RESTRICTION';

type VerificationViolations =
    | 'OVER_TAGS'
    | 'OVER_SENSORS'
    | 'OVER_SCANNERS'
    | 'NO_SERVER_KEY'
    | 'DECRYPT_FAIL_SERVER_KEY'
    | 'INVALID_SERVER_KEY_FORMAT'
    | 'SYNC_FAIL_SERVER_KEY'
    | 'INVALID_SERVER_KEY'
    | 'DUPLICATE_TOKEN'
    | 'TOKEN_EXPIRATION'
    | 'COUNTERFEIT_TOKEN'
    | 'NO_LICENSE_KEY_VERSION'
    | 'NO_SERVER_UNIKEY'
    | 'NO_BASE_START_DATE'
    | 'SYNC_FAIL_BASE_START_DATE'
    | 'NO_FAILURE_DAYS'
    | 'SYNC_FAIL_FAILURE_DAYS'
    | 'NO_RESTRICTION_ACTIVE'
    | 'SYNC_FAIL_RESTRICTION_ACTIVE'
    | 'NO_VERIFICATION_RESULT'
    | 'SYNC_FAIL_VERIFICATION_RESULT'
    | 'NO_LICENSE_VIOLATIONS'
    | 'SYNC_FAIL_LICENSE_VIOLATIONS'
    | 'NO_VERIFICATION_METHOD'
    | 'SYNC_FAIL_VERIFICATION_METHOD'
    | 'OVER_INITIAL_PERIOD'
    | 'MODULATION_LICENSE_INFO';

export type LicenseStatus = {
    verificationActive?: YN | null;
    verificationMethod?: VerificationMethod | null;
    baseStartDate?: number | null;
    verificationFailureDays?: number | null;
    serviceRestrictionActive?: YN;
    verificationResult?: VerificationResult;
    verificationViolations?: VerificationViolations[];
};

export const LOOSE_VIOLATIONS = ['OVER_TAGS', 'OVER_SENSORS', 'OVER_SCANNERS'];

export const MAX_REMAINING_DAYS = 10;

// const getLicenseStatusFake = generateDynamicFakeData(() => {
//     return {
//         verificationActive: sample(['Y', 'N']),
//         verificationMethod: 'ONLINE',
//         baseStartDate: 1681011672,
//         verificationFailureDays: random(0, 10),
//         serviceRestrictionActive: sample(['Y', 'N']),
//         verificationResult: sample([
//             // 'NO_PROBLEM',
//             // 'HAS_PROBLEM',
//             'RESTRICTION',
//         ]),
//         verificationViolations: sampleSize(['OVER_SCANNERS', 'TOKEN_EXPIRATION', 'OVER_TAGS'], random(1, 3)),
//     };
// });

const useLicenseCheck = () => {
    const dispatch = useDispatch();
    const licenseCheckRef = useRef<null | number>();

    const { promise: getLicenseStatus } = useAsync({
        promise: getLicenseStatusApi,
        param: {},
        resolve: (res: LicenseStatus) => {
            dispatch(setLicenseStatus(res));
        },
        reject: (err: any) => {
            console.error(err);
        },
    });

    const stopInterval = () => {
        if (licenseCheckRef.current) {
            window.clearInterval(licenseCheckRef.current);
            licenseCheckRef.current = null;
        }
    };

    useEffect(() => {
        getLicenseStatus();
        licenseCheckRef.current = window.setInterval(() => {
            getLicenseStatus();
        }, TIMES.MIN_30);
        return () => {
            stopInterval();
        };
    }, []);
};

export default useLicenseCheck;
