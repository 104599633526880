import React, { useEffect, useState } from 'react';

import { Col } from 'reactstrap';

import Modal from '@components/Modal';
import LanguageSelect from './Components/LanguageSelect';
import ThemeSwatch from './Components/ThemeSwatch';

import useTranslation from '@util/hooks/useTranslation';
import { useAsync, useConfirmModal } from '@hooks';
import { getUserSettingApi, updateUserSettingApi } from '@api/common/common';
import { useDispatch, useSelector } from 'react-redux';
import { setColorScheme } from '@reducer/ThemeOptions';
import { getLsUserInfo, setLsUserInfo } from '@util/common/util';
import { setLanguage, setNotificationTypes } from '@reducer/UserInfo';
import { handleSelectedNotificationTypes } from '@reducer/Notification';
import useAppSelector from '@hooks/useAppSelector';
import NotificationSettings from './Components/NotificationSettings';

const PERSONAL_PROPERTY_ID = 'color';
const COLOR_OPTIONS = ['pnt-navy', 'pnt-dark'];
const DEFAULT_SELECT_NOTIFICATION_TYPE = {
    user: false,
    // group: false,
    role: true,
};

const convertToNotificationTypes = updatedNotificationTypes =>
    Object.keys(updatedNotificationTypes).reduce(
        (previousValue, currentValue) =>
            updatedNotificationTypes[currentValue] ? [...previousValue, currentValue] : previousValue,
        [],
    );

/**
 @param initModal  모달 상태값
 @param toggleModal  모달 함수

 @author created by 정진범 2022-12-08
 **/
const PersonalizationModal = ({ initModal, toggleModal }) => {
    const t = useTranslation('UserBox');

    const dispatch = useDispatch();
    const lang = useAppSelector(state => state.UserInfo.lang);
    const [selectedLanguage, setSelectedLanguage] = useState(lang);
    const handleSelectedLanguage = selected => {
        const { value } = selected;
        setSelectedLanguage(value);
    };

    const colorScheme = useSelector(state => state.ThemeOptions.colorScheme);
    const [selectedColorScheme, setSelectedColorScheme] = useState(colorScheme);
    const handleSelectedColorScheme = selected => setSelectedColorScheme(selected);

    const currentNotificationTypes = useSelector(state => state.Notification.selectedNotificationTypes);
    const [selectedNotificationTypes, setSelectedNotificationTypes] = useState(
        Object.fromEntries(
            Object.keys(DEFAULT_SELECT_NOTIFICATION_TYPE).map(key => [[key], currentNotificationTypes.includes(key)]),
        ),
    );
    const handleChangeNotificationTypes = e => {
        const typeKey = e.target.id;
        setSelectedNotificationTypes(prevState => {
            const updatedNotificationTypes = {
                ...selectedNotificationTypes,
                [typeKey]: !selectedNotificationTypes[typeKey],
            };
            const activateList = Object.values(updatedNotificationTypes).filter(value => value);
            if (!activateList.length) {
                toggleRequiredNotificationTypeModal();
                return prevState;
            }
            return updatedNotificationTypes;
        });
    };

    const { Modal: RequiredNotificationTypeModal, toggleModal: toggleRequiredNotificationTypeModal } = useConfirmModal({
        confirmText: t('You must select at least one.', 'ConfirmModal'),
        removeCancel: true,
    });

    const { promise: getConfigSetting } = useAsync({
        promise: getUserSettingApi,
        fixedParam: { propId: PERSONAL_PROPERTY_ID },
        resolve: res => {
            const { oAuthInfo, userInfo } = getLsUserInfo();
            const { alertTypes: selectedNotificationTypes, propValue: colorTheme, description: lang } = res;

            userInfo.notificationTypes = selectedNotificationTypes;
            userInfo.propValue = colorTheme;
            userInfo.lang = lang;
            setLsUserInfo({ oAuthInfo, userInfo });

            dispatch(setLanguage(lang));
            dispatch(setNotificationTypes(selectedNotificationTypes));
            dispatch(setColorScheme(colorTheme));
            dispatch(handleSelectedNotificationTypes(selectedNotificationTypes));
        },
        reject: err => console.error(err),
    });

    const { promise: updateTheme } = useAsync({
        promise: updateUserSettingApi,
        resolve: () => {
            getConfigSetting();
        },
        reject: err => console.error(err),
    });

    useEffect(() => {
        setSelectedNotificationTypes(
            Object.fromEntries(
                Object.keys(DEFAULT_SELECT_NOTIFICATION_TYPE).map(key => [
                    [key],
                    currentNotificationTypes.includes(key),
                ]),
            ),
        );
    }, [currentNotificationTypes]);

    useEffect(() => {
        // 랜더링 후, 초기값 세팅
        setSelectedLanguage(lang);
        setSelectedColorScheme(colorScheme);
    }, [lang, colorScheme]);

    return (
        <Modal
            initModal={initModal}
            okCallback={updateTheme}
            toggleModal={toggleModal}
            callbackParam={{
                propId: PERSONAL_PROPERTY_ID,
                propName: '컬러',
                propValue: selectedColorScheme,
                description: selectedLanguage,
                alertTypes: convertToNotificationTypes(selectedNotificationTypes),
            }}
            headerTitle={t('Personalization')}
            headerClassName={'bg-depth-4'}
            removeCancel
        >
            <div className="modal-body py-4">
                <div className="mb-3 px-sm-3">
                    <LanguageSelect value={selectedLanguage} onChange={handleSelectedLanguage} />
                </div>
                <div className="mb-3 px-sm-3">
                    <ThemeSwatch
                        option={COLOR_OPTIONS}
                        onClick={handleSelectedColorScheme}
                        selectedColorScheme={selectedColorScheme}
                    />
                </div>
                <div className={'px-sm-3'}>
                    <NotificationSettings value={selectedNotificationTypes} onChange={handleChangeNotificationTypes} />
                </div>
            </div>
            <RequiredNotificationTypeModal />
        </Modal>
    );
};

export default PersonalizationModal;
