import React, { useEffect, useState } from 'react';
import NoDataBlock from '../../Components/NoDataBlock';
import BarChart from '@components/Charts/Components/BarChart';

const TriggeredAlertStatusChart = ({ data }) => {
    const [chartData, setChartData] = useState({});

    useEffect(() => {
        setChartData({
            labels: [''],
            datasets: data.map((alert, i) => {
                return {
                    label: alert.name,
                    data: [alert.count],
                };
            }),
        });
    }, [data]);

    return !!data && data.length ? <BarChart data={chartData} /> : <NoDataBlock />;
};

export default TriggeredAlertStatusChart;
