import React, { useEffect, useState } from 'react';
import Modal from '@components/Modal';
import { useIVInjectionMonitoringContext, useIVInjectionMonitoringDispatch } from '../index';
import Label from '@components/Label';
import TextInput from '@components/Input/TextInput';
import cx from 'classnames';
import moment from 'moment';
import Button from '@components/Button';
import DatePicker from '@components/DatePicker';
import { useAsync, useConfirmModal, useTranslation } from '@hooks';
import { getIVInjectionMonitoringApi, updateIVInjectionMonitoringApi } from '@api/monitoring';
import {
    changeCurrentIVInjection,
    changeCurrentIVInjectionValue,
    resetCurrentIVInjectionNum,
} from '../IVInjectionMonitoringSlice';
import { ConfirmModal } from '@components';
import styled from 'styled-components';
import { calcCurrentRemainingValue } from './IVInjectionMonitoringCardList/Component/IVInjectionInfo';

const SENSOR_ITEM_TYPE = {
    CAPACITY: 'CAPACITY',
};
const DEFAULT_VALUE = '-';
const checkValue = value => (value ? value : DEFAULT_VALUE);

const IVInjectionUpdateModal = ({ handleCallbackApi }) => {
    const t = useTranslation('IVInjectionMonitoring');
    const { currentIVInjection, currentIVInjectionNum } = useIVInjectionMonitoringContext();
    const dispatch = useIVInjectionMonitoringDispatch();
    const { targetInfo, IVInjection, sensorItems } = currentIVInjection;

    const [modal, setModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);

    const [expectedInjectionValue, setExpectedInjectionValue] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [currentRemainingIVValue, setCurrentRemainingIVValue] = useState(0);
    const [currentIVVolumeUnit, setCurrentIVVolumeUnit] = useState('');

    // 수액 단일 조회
    const { promise: getIVInjectionMonitoring } = useAsync({
        promise: getIVInjectionMonitoringApi,
        resolve: res => {
            dispatch(changeCurrentIVInjection(res));
            // 총 투여량 센서 아이템
            const sensorCapacity = sensorItems.find(sensorItem => sensorItem.sensingType === SENSOR_ITEM_TYPE.CAPACITY);

            // 현재(남은) 수액용량
            setCurrentRemainingIVValue(calcCurrentRemainingValue(res.totalVal, sensorCapacity.currentValue));
            // 수액 단위
            setCurrentIVVolumeUnit(sensorCapacity.measurementUnit);
        },
    });

    // 수액 정보 업데이트
    const { promise: updateIVInjectionMonitoring } = useAsync({
        promise: updateIVInjectionMonitoringApi,
        resolve: () => {
            // Modal toggle
            toggleModal();
            // update 후 수액 리스트 갱신
            handleCallbackApi();
        },
    });

    // 수액 정보 Update Modal toggle
    const toggleModal = () => {
        setModal(prev => {
            // Modal 닫을시(이전값 true)
            if (prev) {
                // 수액 고유번호 초기화
                dispatch(resetCurrentIVInjectionNum());
                // 오더량 계산 초기화
                setExpectedInjectionValue(null);
            }
            return !prev;
        });
    };

    // 수액 정보 Update 확인 모달 toggle
    const toggleUpdateConfirmModal = () => {
        setConfirmModal(prev => !prev);
    };

    // 수액 정보 input value 변경
    const handleIVInjectionInfoChange = e => {
        const { name, value } = e.target;
        dispatch(changeCurrentIVInjectionValue({ [name]: value }));
    };

    // 현재수액용량(남은량) input value 변경
    const handleRemainingIVValue = e => {
        const { value } = e.target;
        // 남은량은 총 용량보다 클 수 없음
        const confirmValue = IVInjection.totalVal < value ? IVInjection.totalVal : parseFloat(value);
        setCurrentRemainingIVValue(confirmValue);
    };

    // 수액 주입 종료시간 변경
    const handleEndDateChange = selected => {
        const currentUnixTime = moment().unix();
        // 종료시간은 과거를 선택 할 수 없음
        const confirmSelectedDate = currentUnixTime > selected ? currentUnixTime : selected;
        setEndDate(confirmSelectedDate);
    };

    // '오더량 계산' 버튼 이벤트
    const handleCalculateInjectionVolumeButton = () => {
        if (!endDate) {
            setExpectedInjectionValue('- cc/hr');
            return;
        }
        if (currentRemainingIVValue <= 0) {
            setExpectedInjectionValue('- cc/hr');
        }
        // 현재(남은)수액용량 * 60분 / (종료시간 - 현재시간) = 예상 주입량 cc/hr
        // (200 * 60) / ((1674127800 - 1674120600) / 60) = 100 cc/hr
        const calculatedInjectionValue = Math.floor(
            (currentRemainingIVValue * 60) / ((endDate - moment().unix()) / 60),
        );
        setExpectedInjectionValue(`${calculatedInjectionValue} cc/hr`);
    };

    const handleCloseModal = () => {
        toggleModal();
        setEndDate(null);
    };

    // 선택 센서 고유번호 변경시 상태 변환
    useEffect(() => {
        if (!!currentIVInjectionNum) {
            // 수액 상세 api 호출
            getIVInjectionMonitoring({ IVInjectionNum: currentIVInjectionNum });
            // 모달 open
            toggleModal();
        }
    }, [currentIVInjectionNum]);

    const { Modal: FailModal, toggleModal: toggleFailModal } = useConfirmModal({
        header: { title: t('Save Failed') },
        confirmText: t('Please check your Current Injection Amount or Device Name.'),
        removeCancel: true,
    });

    // 수액 종료시간 상태변환
    useEffect(() => {
        setEndDate(IVInjection.endDate);
    }, [IVInjection.endDate]);

    return (
        <>
            <Modal
                initModal={modal}
                toggleModal={toggleModal}
                headerTitle={t('Update IV Injection Information')}
                modalFooter={
                    <div className="modal-footer">
                        <Button className="btn-lightgray" onClick={handleCloseModal}>
                            {t('Cancel', 'ConfirmModal')}
                        </Button>
                        <Button className="btn-secondary" onClick={toggleUpdateConfirmModal}>
                            {t('OK', 'ConfirmModal')}
                        </Button>
                    </div>
                }
            >
                <div className="modal-body">
                    <LowCaseContainer className="flx-col gap-3 pnt-label-8">
                        <Label
                            name={t('Room/Bed')}
                            value={
                                <span className="pnt-txt txt-bold">
                                    {checkValue(targetInfo.properties && targetInfo.properties.room)}
                                    {' / '}
                                    {checkValue(targetInfo.properties && targetInfo.properties.bed)}
                                </span>
                            }
                        />
                        <Label
                            name={t('Patient(ID)')}
                            value={
                                <span className="pnt-txt txt-bold">{`${targetInfo.targetName} ( ${targetInfo.targetId} )`}</span>
                            }
                        />
                        <Label
                            name={t('Medicine Name')}
                            value={<span className="pnt-txt txt-bold">{checkValue(IVInjection.medicineName)}</span>}
                        />
                        <Label
                            name={`${t('Medicine Volume')}(${checkValue(currentIVVolumeUnit)})`}
                            value={
                                <span className="pnt-txt txt-bold">
                                    {checkValue(IVInjection.totalVal)} ({checkValue(currentIVVolumeUnit)})
                                </span>
                            }
                        />
                        <Label
                            name={t('Device Name')}
                            value={
                                <TextInput
                                    name="deviceName"
                                    className="pnt-txt txt-bold"
                                    maxlength={50}
                                    value={IVInjection.deviceName}
                                    handleChange={handleIVInjectionInfoChange}
                                />
                            }
                        />
                        <Label
                            name={`${t('Current Injection Volume')}(ml)`}
                            value={
                                <TextInput
                                    type={'number'}
                                    inputGroupClassName={cx('w-100')}
                                    name={'currentVal'}
                                    id={'currentVal'}
                                    // 현재(남은)수액용량 = 총 수액량(total value) - 누적 투여량(sensor capacity value)
                                    value={currentRemainingIVValue}
                                    handleChange={handleRemainingIVValue}
                                />
                            }
                        />
                        {/* 오더량(injection value) cc/hr */}
                        <Label
                            name={`${t('Injection Volume')}(cc/hr)`}
                            value={
                                <>
                                    <div className="pnt-input--group w-60">
                                        <input
                                            type="number"
                                            name="injectionVal"
                                            id="injectionVal"
                                            className="pnt-input"
                                            value={IVInjection.injectionVal || 0}
                                            onChange={handleIVInjectionInfoChange}
                                        />
                                    </div>
                                    {expectedInjectionValue && (
                                        <div className={'color-danger'}>{expectedInjectionValue}</div>
                                    )}
                                </>
                            }
                        />

                        <Label
                            name={`${t('Max Limit Speed')}(cc/hr)`}
                            value={
                                <TextInput
                                    type={'number'}
                                    inputGroupClassName={cx('w-100')}
                                    name={'maxLimitSpeed'}
                                    id={'maxLimitSpeed'}
                                    value={IVInjection.maxLimitSpeed || 0}
                                    handleChange={handleIVInjectionInfoChange}
                                />
                            }
                        />

                        <Label
                            name={`${t('Min Limit Speed')}(cc/hr)`}
                            value={
                                <TextInput
                                    type={'number'}
                                    inputGroupClassName={cx('w-100')}
                                    name={'minLimitSpeed'}
                                    id={'minLimitSpeed'}
                                    value={IVInjection.minLimitSpeed || 0}
                                    handleChange={handleIVInjectionInfoChange}
                                />
                            }
                        />

                        <div className={'pnt-border border-w border-bold'} />

                        <Label
                            name={t('Start Date')}
                            value={
                                <TextInput
                                    inputGroupClassName={cx('w-100')}
                                    disabled
                                    name={'startDate'}
                                    id={'startDate'}
                                    value={
                                        IVInjection.startDate
                                            ? moment.unix(IVInjection.startDate).format('YYYY-MM-DD HH:mm')
                                            : DEFAULT_VALUE
                                    }
                                />
                            }
                        />

                        <Label
                            name={t('End Date')}
                            value={
                                <div className="flx-row w-100">
                                    <div className="w-70">
                                        <DatePicker
                                            value={endDate}
                                            handleChange={handleEndDateChange}
                                            valueType={'s'}
                                            showTimeInput
                                            minDate={moment().valueOf()}
                                        />
                                    </div>
                                    <Button
                                        className="btn-brand ml-auto"
                                        onClick={handleCalculateInjectionVolumeButton}
                                    >
                                        {t('Calc Injection Volume')}
                                    </Button>
                                </div>
                            }
                        />
                    </LowCaseContainer>
                </div>
            </Modal>

            <ConfirmModal
                initModal={confirmModal}
                toggleModal={toggleUpdateConfirmModal}
                confirmText={t('Do you want to update this item?', 'ConfirmModal')}
                okCallback={callbackParam => {
                    if (!currentRemainingIVValue || !IVInjection.deviceName) {
                        toggleFailModal();
                    } else {
                        updateIVInjectionMonitoring(callbackParam);
                    }
                }}
                callbackParam={{
                    ...IVInjection,
                    IVInjectionNum: IVInjection.ivInjectionNum,
                    currentSapVolume: currentRemainingIVValue ? currentRemainingIVValue : IVInjection.currentVal,
                    endDate,
                }}
            />
            <FailModal />
        </>
    );
};

const LowCaseContainer = styled.div`
    & * {
        text-transform: unset !important;
    }
`;

export default IVInjectionUpdateModal;
