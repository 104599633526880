import BarChart from '@components/Charts/Components/BarChart';
import LineChart from '@components/Charts/Components/LineChart';
import PieChart from '@components/Charts/Components/PieChart';
import BoxPlotChart from '@components/Charts/Components/BoxPlotChart';
import RealTimeLineChart from '@components/Charts/Components/RealTimeLineChart';

import { Chart } from 'react-chartjs-2';
import ContainerBackground from '@components/Charts/Plugins/ContainerBackground';
import ColorPalette from '@components/Charts/Plugins/ColorPalette';
import Loading from '@components/Charts/Plugins/Loading';

Chart.register(ContainerBackground, ColorPalette, Loading);

export { BarChart, LineChart, PieChart, BoxPlotChart, RealTimeLineChart };
