import React, { useMemo, useState } from 'react';
import { Modal, Select, TextInput, Button } from '@components';
import { useTranslation } from '@hooks';
import { useAsync } from '@util/hooks';
import { postRentalReject } from '@api/am/management';
import { ModalFooter } from 'reactstrap';

const RentalDenyModal = ({ initModal, toggle, applyNum, getRentalList }) => {
    const t = useTranslation('AssetManagement');
    const [selectedValue, setSelectedValue] = useState(null);
    const [etcText, setEtcText] = useState('');

    const DENY_REASON_OPTION = useMemo(() => {
        return [
            {
                label: t('It is in an unusable state.'),
                value: t('It is in an unusable state.'),
            },
            {
                label: t('It is planned to be used.'),
                value: t('It is planned to be used.'),
            },
            {
                label: t('Another department has borrowed it.'),
                value: t('Another department has borrowed it.'),
            },
            {
                label: t('Other (text input)'),
                value: t('Other'),
            },
        ];
    }, [t]);

    const { promise } = useAsync({
        promise: postRentalReject,
        param: {
            applyNum,
            description: etcText ? etcText : selectedValue?.value,
        },
        resolve: () => {
            toggle();
            getRentalList();
        },
        reject: err => console.error(err),
    });

    const handleSelected = selected => {
        setSelectedValue(selected);
    };

    const handleTextChange = e => {
        const { target } = e;
        setEtcText(target.value);
    };

    const handleOkClick = () => {
        promise();
    };

    return (
        <Modal
            initModal={initModal}
            headerTitle={t('Reason for rejection')}
            toggleModal={toggle}
            bodyText={
                <div className={'w-100 flx-col gap-2'}>
                    <Select
                        placeholder={t('Select', 'Select')}
                        className={'w-100 '}
                        options={DENY_REASON_OPTION}
                        onChange={handleSelected}
                        isModalSelect
                    />
                    <TextInput
                        inputGroupClassName={'w-100'}
                        placeholder={t('Enter text')}
                        disabled={selectedValue?.value !== 'Other'}
                        handleChange={handleTextChange}
                        value={etcText}
                    />
                </div>
            }
            okCallback={handleOkClick}
            modalFooter={
                <ModalFooter>
                    <Button className={'btn-gray'} onClick={toggle}>
                        {t('Cancel')}
                    </Button>
                    <Button className={'btn-secondary'} onClick={handleOkClick} disabled={!selectedValue}>
                        {t('OK')}
                    </Button>
                </ModalFooter>
            }
        />
    );
};

export default RentalDenyModal;
