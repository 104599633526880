import { createSlice } from '@reduxjs/toolkit';

export const INTERVAL_TIME = 1000;

export const initialState = {
    sensingTypeOptions: [],
    sensorListParam: { pageSize: 15, page: 1, isMappingTarget: 'Y' },
    searchInput: {},
    selectedStatus: null,
    selectedSensorStatusList: { rows: [], totalCount: 0, totalPage: 1 },
    sensorList: { rows: [], totalCount: 0, totalPage: 1 },
    sensorDetailsList: { rows: [], totalCount: 0, totalPage: 1 },
    sensorDetailsListLoading: false,
    selectedSensor: null,
    locationPopup: false,
    reportType: 'daily',
    sensorLineChartData: [],
    sensorLogInfo: { request: null },
    floorId: null,
    currentStatus: 'Total',
};

const sensorStatusSlice = createSlice({
    name: 'sensorStatus',
    initialState,
    reducers: {
        setSensingTypeOptions: (state, action) => {
            state.sensingTypeOptions = action.payload || [];
        },
        setSensorListParam: (state, action) => {
            const data = action.payload;
            let setData = { ...state.sensorListParam };
            if (data.hasOwnProperty('status')) {
                if (!data.status) {
                    // Reflect.deleteProperty 객체 요소 삭제 연산자
                    Reflect.deleteProperty(setData, 'status');
                } else {
                    setData = { ...setData, ...data };
                }
            } else {
                setData = { ...setData, ...data };
            }

            let result = {};
            for (let key in setData) {
                if (setData[key]) {
                    result[key] = setData[key];
                }
            }
            state.sensorListParam = { ...result };
        },
        setSelectedStatus: (state, action) => {
            let selectedStatus = action.payload;
            if (selectedStatus) {
                state.selectedStatus = selectedStatus.toUpperCase();
            } else {
                state.selectedStatus = null;
                state.selectedSensorStatusList = { rows: [], totalCount: 0, totalPage: 1 };
            }
        },
        setSelectedSensorStatusList: (state, action) => {
            const { rows, ...listInfo } = action.payload;
            state.selectedSensorStatusList = {
                ...listInfo,
                rows: rows.reduce((acc, cur) => {
                    acc = acc.concat(cur.sensorItems.map(sensorItem => ({ ...cur, sensorItems: [sensorItem] })));
                    return acc;
                }, []),
            };
        },
        setSensorList: (state, action) => {
            state.sensorList = action.payload;
        },
        setSensorDetailsList: (state, action) => {
            const { data: list, loading, sensorLogInfo, chartData } = action.payload;
            state.sensorLineChartData = chartData ? [...chartData] : [];

            state.sensorDetailsList = {
                ...list,
                rows: chartData,
            };
            state.sensorDetailsListLoading = loading;
            state.sensorLogInfo = sensorLogInfo;
        },
        setSelectedSensor: (state, action) => {
            state.selectedSensor = action.payload;
            if (action.payload) {
                state.floorId = action.payload.floorId;
            } else {
                state.floorId = null;
                state.sensorDetailsList = { rows: [], totalCount: 0, totalPage: 1 };
            }
        },

        openLocationPopup: state => {
            state.locationPopup = true;
        },
        closeLocationPopup: state => {
            state.locationPopup = false;
        },
        setReportType: (state, action) => {
            state.reportType = action.payload;
        },
        setCurrentStatus: (state, action) => {
            state.currentStatus = action.payload;
        },
        clearSensorStatusWidget: state => {
            state.sensorListParam = { pageSize: 15, page: 1, isMappingTarget: 'Y' };
            state.currentStatus = 'Total';
        },
    },
});

export const {
    setSensingTypeOptions,
    setSensorListParam,
    setSelectedSensorStatusList,
    setSensorList,
    setSensorDetailsList,
    setSelectedSensor,
    openLocationPopup,
    closeLocationPopup,
    setReportType,
    setCurrentStatus,
    clearSensorStatusWidget,
} = sensorStatusSlice.actions;

export default sensorStatusSlice;
