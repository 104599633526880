import { useSelector } from 'react-redux';
import { ASSET_CODE } from '../constants';
import { useEffect, useState } from 'react';

const usePropertyList = (parentCategoryCode = ASSET_CODE) => {
    const { categoryPropertiesList } = useSelector(state => state.CategoryInfo);
    const [propertiesList, setPropertiesList] = useState([]);

    useEffect(() => {
        const refineProperties = categoryPropertiesList
            .filter(({ categoryCode }) => parentCategoryCode === categoryCode)
            .sort((a, b) => a.sortOrder - b.sortOrder);
        setPropertiesList(refineProperties);
    }, [categoryPropertiesList, parentCategoryCode]);
    return propertiesList;
};

export default usePropertyList;
