export const DUMMY_LIST_DATA = {
    '801호': [
        {
            ecoSignNum: null,
            targetNum: 177,
            targetName: '환경센서',
            targetId: 'eco',
            sensorNum: 35,
            sensingType: 'BATTERY',
            measurementUnit: '%',
            currentValue: 100.0,
            status: 'OUTOFRANGE',
            regDate: 1702898217,
            modDate: null,
            isBookmark: 'N',
            room: '801호',
            properties: {
                ward: '18',
                borrowward: '18',
                manager: '5',
                room: '801호',
            },
        },
        {
            ecoSignNum: null,
            targetNum: 177,
            targetName: '환경센서',
            targetId: 'eco',
            sensorNum: 35,
            sensingType: 'CO2',
            measurementUnit: 'ppm',
            currentValue: 2171.0,
            status: 'OUTOFRANGE',
            regDate: 1702898217,
            modDate: null,
            isBookmark: 'N',
            room: '801호',
            properties: {
                ward: '18',
                borrowward: '18',
                manager: '5',
                room: '801호',
            },
        },
        {
            ecoSignNum: null,
            targetNum: 177,
            targetName: '환경센서',
            targetId: 'eco',
            sensorNum: 35,
            sensingType: 'HUMIDITY',
            measurementUnit: '%',
            currentValue: 81.3,
            status: 'OUTOFRANGE',
            regDate: 1702898217,
            modDate: null,
            isBookmark: 'N',
            room: '801호',
            properties: {
                ward: '18',
                borrowward: '18',
                manager: '5',
                room: '801호',
            },
        },
        {
            ecoSignNum: null,
            targetNum: 177,
            targetName: '환경센서',
            targetId: 'eco',
            sensorNum: 35,
            sensingType: 'SOUND_NOISE',
            measurementUnit: 'dB',
            currentValue: 43.17,
            status: 'NORMAL',
            regDate: 1702898217,
            modDate: null,
            isBookmark: 'N',
            room: '801호',
            properties: {
                ward: '18',
                borrowward: '18',
                manager: '5',
                room: '801호',
            },
        },
        {
            ecoSignNum: null,
            targetNum: 177,
            targetName: '환경센서',
            targetId: 'eco',
            sensorNum: 35,
            sensingType: 'TEMPERATURE',
            measurementUnit: '℃',
            currentValue: 28.7,
            status: 'NORMAL',
            regDate: 1702898217,
            modDate: null,
            isBookmark: 'N',
            room: '801호',
            properties: {
                ward: '18',
                borrowward: '18',
                manager: '5',
                room: '801호',
            },
        },
    ],
};

export const DUMMY_DETAIL_INFO = {
    ward: '18',
    room: '801호',
    floor: 'main',
    sensorName: '환경센서',
    location: '37.50753,127.053747',
    lastDate: 1702898217,
    status: 'LOSTSIGNAL_ON',
};

export const DUMMY_GRAPH = {
    recentEcoSignSensor: {
        BATTERY: '100',
        SOUND_NOISE: '73.17',
        HUMIDITY: '81.3',
        TEMPERATURE: '25.7',
        CO2: '2171',
    },
    ecoSignSensorList: {
        BATTERY: [
            {
                targetId: 'eco',
                sensingType: 'BATTERY',
                currentValue: '10',
                regDate: 1702889990,
                measurementUnit: '%',
            },
            {
                targetId: 'eco',
                sensingType: 'BATTERY',
                currentValue: '10',
                regDate: 1702890116,
                measurementUnit: '%',
            },
            {
                targetId: 'eco',
                sensingType: 'BATTERY',
                currentValue: '10',
                regDate: 1702890242,
                measurementUnit: '%',
            },
            {
                targetId: 'eco',
                sensingType: 'BATTERY',
                currentValue: '10',
                regDate: 1702891642,
                measurementUnit: '%',
            },
            {
                targetId: 'eco',
                sensingType: 'BATTERY',
                currentValue: '10',
                regDate: 1702891748,
                measurementUnit: '%',
            },
            {
                targetId: 'eco',
                sensingType: 'BATTERY',
                currentValue: '100',
                regDate: 1702898217,
                measurementUnit: '%',
            },
        ],
        SOUND_NOISE: [
            {
                targetId: 'eco',
                sensingType: 'SOUND_NOISE',
                currentValue: '12',
                regDate: 1702889990,
                measurementUnit: 'dB',
            },
            {
                targetId: 'eco',
                sensingType: 'SOUND_NOISE',
                currentValue: '12',
                regDate: 1702890116,
                measurementUnit: 'dB',
            },
            {
                targetId: 'eco',
                sensingType: 'SOUND_NOISE',
                currentValue: '12',
                regDate: 1702890242,
                measurementUnit: 'dB',
            },
            {
                targetId: 'eco',
                sensingType: 'SOUND_NOISE',
                currentValue: '12',
                regDate: 1702891642,
                measurementUnit: 'dB',
            },
            {
                targetId: 'eco',
                sensingType: 'SOUND_NOISE',
                currentValue: '12',
                regDate: 1702891748,
                measurementUnit: 'dB',
            },
            {
                targetId: 'eco',
                sensingType: 'SOUND_NOISE',
                currentValue: '73.17',
                regDate: 1702898217,
                measurementUnit: 'dB',
            },
        ],
        HUMIDITY: [
            {
                targetId: 'eco',
                sensingType: 'HUMIDITY',
                currentValue: '10',
                regDate: 1702889990,
                measurementUnit: '%',
            },
            {
                targetId: 'eco',
                sensingType: 'HUMIDITY',
                currentValue: '10',
                regDate: 1702890116,
                measurementUnit: '%',
            },
            {
                targetId: 'eco',
                sensingType: 'HUMIDITY',
                currentValue: '10',
                regDate: 1702890242,
                measurementUnit: '%',
            },
            {
                targetId: 'eco',
                sensingType: 'HUMIDITY',
                currentValue: '10',
                regDate: 1702891642,
                measurementUnit: '%',
            },
            {
                targetId: 'eco',
                sensingType: 'HUMIDITY',
                currentValue: '10',
                regDate: 1702891748,
                measurementUnit: '%',
            },
            {
                targetId: 'eco',
                sensingType: 'HUMIDITY',
                currentValue: '81.3',
                regDate: 1702898217,
                measurementUnit: '%',
            },
        ],
        TEMPERATURE: [
            {
                targetId: 'eco',
                sensingType: 'TEMPERATURE',
                currentValue: '120',
                regDate: 1702889990,
                measurementUnit: '℃',
            },
            {
                targetId: 'eco',
                sensingType: 'TEMPERATURE',
                currentValue: '120',
                regDate: 1702890116,
                measurementUnit: '℃',
            },
            {
                targetId: 'eco',
                sensingType: 'TEMPERATURE',
                currentValue: '120',
                regDate: 1702890242,
                measurementUnit: '℃',
            },
            {
                targetId: 'eco',
                sensingType: 'TEMPERATURE',
                currentValue: '120',
                regDate: 1702891642,
                measurementUnit: '℃',
            },
            {
                targetId: 'eco',
                sensingType: 'TEMPERATURE',
                currentValue: '120',
                regDate: 1702891748,
                measurementUnit: '℃',
            },
            {
                targetId: 'eco',
                sensingType: 'TEMPERATURE',
                currentValue: '25.7',
                regDate: 1702898217,
                measurementUnit: '℃',
            },
        ],
        CO2: [
            {
                targetId: 'eco',
                sensingType: 'CO2',
                currentValue: '10',
                regDate: 1702889990,
                measurementUnit: 'ppm',
            },
            {
                targetId: 'eco',
                sensingType: 'CO2',
                currentValue: '10',
                regDate: 1702890116,
                measurementUnit: 'ppm',
            },
            {
                targetId: 'eco',
                sensingType: 'CO2',
                currentValue: '10',
                regDate: 1702890242,
                measurementUnit: 'ppm',
            },
            {
                targetId: 'eco',
                sensingType: 'CO2',
                currentValue: '10',
                regDate: 1702891642,
                measurementUnit: 'ppm',
            },
            {
                targetId: 'eco',
                sensingType: 'CO2',
                currentValue: '10',
                regDate: 1702891748,
                measurementUnit: 'ppm',
            },
            {
                targetId: 'eco',
                sensingType: 'CO2',
                currentValue: '2171',
                regDate: 1702898217,
                measurementUnit: 'ppm',
            },
        ],
    },
};
export const DEFAULT_DATE = '--/-- --:--';
export const DEFAULT_VALUE = '-';

export const ADD_SENSOR_ITEM_NAME = {
    SOUND_NOISE: 'Noise',
    CO2: 'Co2',
    TEMPERATURE: 'Temperature',
    HUMIDITY: 'Humidity',
};

export const SIGNAL_STATUS_NORMAL = 'NORMAL';

export const GRAPH_AREA = {
    WIDTH: 1250,
    HEIGHT: 300,
    MARGIN_TOP: 5,
    MARGIN_RIGHT: 20,
    MARGIN_BOTTOM: 40,
    MARGIN_LEFT: 0,
};
export const LABEL_AREA = {
    TOP: 32,
};

export const GRAPH_SORT = Object.keys(ADD_SENSOR_ITEM_NAME);

export const BAR_CHART_COLOR = '#8884d8';
export const LINE_CHART_COLOR = '#82ca9d';
export const WHITE_COLOR = '#fff';
export const GRID_GRAY_COLOR = '#ccc';
export const TOOLTIP_BG_COLOR_DARK = '#4c4c52';
export const TOOLTIP_BG_COLOR_NAVY = '#e8e6ef';
export const TOOLTIP_TEXT_COLOR = '#565672';
export const TEXT_BOLD = 'bold';
export const DARK_THEME = 'pnt-dark';
export const TICK_LIMITS = 16;

export const DEFAULT_CHART_OPTIONS = {
    animation: false,
    layout: {
        padding: {
            top: GRAPH_AREA.MARGIN_TOP,
            left: GRAPH_AREA.MARGIN_LEFT,
            right: GRAPH_AREA.MARGIN_RIGHT,
        },
    },
    plugins: {
        legend: {
            position: 'top',
            align: 'end',
        },
        tooltip: {
            mode: 'index',
            intersect: false,
            animation: { duration: 0 },
            displayColors: false,
            padding: 12,
            titleFont: {
                weight: TEXT_BOLD,
            },
            bodyFont: {
                weight: TEXT_BOLD,
            },
        },
    },
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'second',
                tooltipFormat: 'HH:mm:ss',
                displayFormats: { second: 'HH:mm:ss' },
            },
            display: true,
            grid: {
                borderDash: [3, 3],
                display: true,
                tickLength: LABEL_AREA.TOP,
                offset: false,
                color: GRID_GRAY_COLOR,
            },
            ticks: {
                minRotation: 45,
                source: 'labels',
            },
        },
        y: {
            grid: {
                borderWidth: 1,
                borderDash: [3, 3],
                color: GRID_GRAY_COLOR,
            },
            ticks: {
                maxTicksLimit: 5,
            },
        },
    },
    datasets: {
        line: {
            pointRadius: 0,
            borderWidth: 1,
            offset: true,
        },
    },
};
