import React, { useState } from 'react';
import { Button, Table } from '@components';
import { useAsync, useColumns, useConfirmModal, useTranslation } from '@hooks';
import * as mainCol from '../../../../../../MainPages/util/grid/column';
import * as col from '../../../columns';
import { useExamPatientsMonitoringStateContext } from '../../../slice';
import { getNotMappingSensorListApi, postSensorMappingApi } from '@api/sh/examPatientMonitoring';

const DeviceSearchModal = ({ onToggleModal }: { onToggleModal: () => void }) => {
    const t = useTranslation('ExamPatientsMonitoring');
    const columnT = useTranslation('CommonColumn');

    const [deviceNum, setDeviceNum] = useState<number | null>(null);
    const { selectedMappingSubjectNum } = useExamPatientsMonitoringStateContext();

    const { toggleModal, Modal } = useConfirmModal({
        initModal: false,
        confirmText: t('Do you want to map that device?'),
        okCallback: () => {
            postSensorMapping({ subjectNum: selectedMappingSubjectNum, deviceNum });
        },
    });

    const columns = useColumns(
        [
            mainCol.sensorName({
                className: 'd-flex justify-content-center',
            }),
            col.mapping({
                width: 50,
                className: 'd-flex justify-content-center',
                Cell: ({ row: { original } }: { row: { original: any } }) => {
                    const { deviceNum } = original;
                    return (
                        <Button
                            className={'btn-icon-only btn-trans md-18'}
                            iconName={'link'}
                            onClick={() => handleMappingClick(deviceNum)}
                        />
                    );
                },
            }),
        ],
        columnT,
    );

    function handleMappingClick(deviceNum: number) {
        setDeviceNum(deviceNum);
        toggleModal();
    }

    const {
        state: { response },
    } = useAsync({
        promise: getNotMappingSensorListApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        deps: [],
    });

    const { promise: postSensorMapping } = useAsync({
        promise: postSensorMappingApi,
        resolve: () => {
            onToggleModal();
        },
    });

    const data = response?.rows || [];
    return (
        <div className={'w-100'}>
            <span className={'pnt-txt txt-bold txt-dot s-8'}>{t('Device List')}</span>
            <div style={{ height: '300px' }}>
                <Table columns={columns} data={{ rows: data }} paging={false} />
            </div>
            <Modal />
        </div>
    );
};

export default DeviceSearchModal;
