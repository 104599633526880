import React from 'react';
import { Button, SearchableSelect, Select } from '@components';
import { useTranslation } from '@hooks';
import { OUTPATIENT_REPORT } from '../../constants';
import DatePickerGroupSO from './DatePickerGroupSO';

const SearchGroupSO = ({
    // 일별, 주별, 월별 Select
    showDateType,
    dateTypeValues,
    // 부서 Select
    showDepartment,
    departmentValues,
    // 방식(개별, 합산) Select
    showMethod,
    methodValues,
    // 기간 DatePicker
    dateValues,
    // 조회 버튼 클릭
    getData,
}) => {
    const { dateTypeOptions, dateType = { value: 'daily' }, handleDateTypeChange } = dateTypeValues || {};
    const { departmentOptions, department, handleDepartmentChange } = departmentValues || {};
    const { methodOptions, method, handleMethodChange } = methodValues || {};
    const {
        startDate,
        handleStartChange,
        handleStartLeftClick,
        handleStartRightClick,
        endDate,
        handleEndChange,
        handleEndLeftClick,
        handleEndRightClick,
    } = dateValues || {};
    const t = useTranslation(OUTPATIENT_REPORT);

    const handleInquiryClick = () => {
        getData();
    };

    return (
        <div className="d-flex gap-3">
            {showDateType && <Select value={dateType} onChange={handleDateTypeChange} options={dateTypeOptions} />}
            {showDepartment && (
                <SearchableSelect selected={department} onChange={handleDepartmentChange} data={departmentOptions} />
            )}
            {showMethod && <Select value={method} onChange={handleMethodChange} options={methodOptions} />}
            <div className="d-flex gap-2 align-items-center">
                <DatePickerGroupSO
                    dateType={dateType.value}
                    startDate={startDate}
                    handleStartChange={handleStartChange}
                    handleStartLeftClick={handleStartLeftClick}
                    handleStartRightClick={handleStartRightClick}
                    endDate={endDate}
                    handleEndChange={handleEndChange}
                    handleEndLeftClick={handleEndLeftClick}
                    handleEndRightClick={handleEndRightClick}
                />
            </div>
            <Button className="btn-secondary" onClick={handleInquiryClick}>
                {t('inquiry')}
            </Button>
        </div>
    );
};

export default SearchGroupSO;
