import React, { useRef } from 'react';
import { useAsync, useConfirmModal, useTranslation } from '@hooks';
import { fetchExcelLogApi } from '@api/common/log';
import { excelExport } from '../../../util/excelExport';
import { Button } from '@components';
import { NullableNumber } from '@util/type/util';
import { postExportExcelTestTargetMonitoringApi } from '@api/sh/examPatientMonitoring';

interface Props {
    subjectNum: number;
}

const INTERVAL_TIME = 1000;

const ExcelExport = ({ subjectNum }: Props) => {
    const t = useTranslation('ExamMonitoringHistory');
    const excelTimerRef = useRef<{ [key: string | number]: NullableNumber }>({});

    const { promise: createNewLogFile } = useAsync({
        promise: postExportExcelTestTargetMonitoringApi,
        fixedParam: {
            zipFileName: `(${subjectNum})${t('_Vital_Signs_View_Details')}`,
            fileName: `(${subjectNum})${t('_Vital_Signs_View_Details')}`,
            columnMetas: [
                {
                    key: 'regDateStr',
                    name: t('Measurement Date'),
                },
                {
                    key: 'subjectNum',
                    name: t('Study Number'),
                },
                {
                    key: 'fcName',
                    name: t('Stabilization Room'),
                },
                {
                    key: 'sensorName',
                    name: t('Device Name'),
                },
                {
                    key: 'timeStr',
                    name: t('Measurement Time'),
                },
                {
                    key: 'HR',
                    name: 'HR',
                },
                {
                    key: 'SPO2',
                    name: 'SPo2',
                },
                {
                    key: 'BT',
                    name: 'BT',
                },
            ],
        },
        resolve: res => {
            const { filePath: url, reqNum } = res;
            if (url) {
                tryExcelDown({ reqNum });
            }
        },
        reject: err => {
            toggleExcelConfirmModal();
            console.error(err);
        },
    });

    const tryExcelDown = ({ reqNum }: { reqNum: number }) => {
        excelTimerRef.current[reqNum] = window.setInterval(() => {
            fetchExcelLog({ reqNum });
        }, INTERVAL_TIME);
    };

    const clearExcelTimer = ({ reqNum }: { reqNum: number }) => {
        clearInterval(excelTimerRef.current[reqNum]!);
        excelTimerRef.current[reqNum] = null;
        delete excelTimerRef.current[reqNum];
    };

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            const { dataSettingStatusType, reqNum, filePath: url, fileName } = res;
            if (dataSettingStatusType === 'COMPLETED') {
                excelExport(url, fileName);
                clearExcelTimer({ reqNum });
            } else if (dataSettingStatusType === 'FAILED') {
                toggleExcelDownloadResultModal();
                clearExcelTimer({ reqNum });
            }
        },
        reject: err => console.error(err),
    });

    const handleExportFileClick = () => {
        createNewLogFile({ subjectNum });
    };

    const { Modal: ExcelDownloadResultModal, toggleModal: toggleExcelDownloadResultModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Result', 'Report') },
        confirmText: t('Failed to download the excel.', 'Report'),
    });

    const { Modal: ExcelConfirmModal, toggleModal: toggleExcelConfirmModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        header: { title: t('Excel Download', 'Report') },
        confirmText: t('No data', 'Report'),
    });

    return (
        <>
            <Button className="btn-trans btn-icon-only" iconName={'download'} onClick={handleExportFileClick} />
            <ExcelDownloadResultModal />
            <ExcelConfirmModal />
        </>
    );
};

export default ExcelExport;
