import { getOutpatientWaitTime, postOutpatientWaitTimeExcel } from '@api/so/outpatient';
import { useAsync, useTranslation } from '@hooks';
import React, { useRef } from 'react';
import InsightBox, { BoxBodyText, HighlightedText } from '../Components/InsightBox';
import SearchGroupSO from '../Components/SearchGroupSO';
import WidgetCard from '../../Components/WidgetCard';
import { useAsyncExcel } from '../../util/useAsyncExcel';
import useDepartmentOptions from '../util/useDepartmentOptions';
import useMethodOptions from '../util/useMethodOptions';
import useRangeDateSO from '../util/useRangeDateSO';
import Chart from './Chart';
import { isValidData } from '../util/checkData';
import NoDataBlock from '../../Components/NoDataBlock';
import { Trans } from 'react-i18next';
import moment from 'moment';

const OutpatientWaitAnalysisByTime = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();
    const t = useTranslation('OutpatientReport');

    const departmentValues = useDepartmentOptions();
    const methodValues = useMethodOptions();
    const dateValues = useRangeDateSO();

    const { state, promise: getData } = useAsync({
        promise: getOutpatientWaitTime,
        immediate: true,
        param: {
            departmentCode: departmentValues.department?.toString(),
            method: methodValues.method.value,
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
        },
        reject: err => console.error(err),
    });
    const data = state.response ? state.response.rows : [];
    const { insightTotalAvg = 0, insightMaxAvgHour = 0, insightMaxAvg = 0 } = state.response
        ? state.response.additionalDatas
        : {};

    const triggerDownload = useAsyncExcel({
        promise: postOutpatientWaitTimeExcel,
        param: {
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
            departmentCode: departmentValues.department.toString(),
            method: methodValues.method.value,
            columnMetas: [
                {
                    key: 'departmentName',
                    name: t('Department name'),
                },
                {
                    key: 'departmentCode',
                    name: t('Department code'),
                },
                {
                    key: 'wait8',
                    name: t('Wait time (08:00)'),
                },
                {
                    key: 'wait9',
                    name: t('Wait time (09:00)'),
                },
                {
                    key: 'wait10',
                    name: t('Wait time (10:00)'),
                },
                {
                    key: 'wait11',
                    name: t('Wait time (11:00)'),
                },
                {
                    key: 'wait12',
                    name: t('Wait time (12:00)'),
                },
                {
                    key: 'wait13',
                    name: t('Wait time (13:00)'),
                },
                {
                    key: 'wait14',
                    name: t('Wait time (14:00)'),
                },
                {
                    key: 'wait15',
                    name: t('Wait time (15:00)'),
                },
                {
                    key: 'wait16',
                    name: t('Wait time (16:00)'),
                },
                {
                    key: 'wait17',
                    name: t('Wait time (17:00)'),
                },
            ],
            fileName:
                'so_kioskData_' +
                moment.unix(dateValues.startDate).format('YYYYMMDD') +
                '_' +
                moment.unix(dateValues.endDate).format('YYYYMMDD'),
            zipFileName: 'so_wait_by_time',
        },
        reject: err => console.error(err),
    });

    const handleDownloadClick = () => {
        triggerDownload();
    };

    const meridiem = t(moment({ hour: insightMaxAvgHour }).format('A'));

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <SearchGroupSO
                showDepartment
                departmentValues={departmentValues}
                showMethod
                methodValues={methodValues}
                dateValues={dateValues}
                getData={getData}
            />
            {isValidData(data) ? (
                <>
                    <InsightBox>
                        <BoxBodyText>
                            <Trans
                                t={t}
                                values={{ insightTotalAvg, meridiem, insightMaxAvgHour, insightMaxAvg }}
                                components={[<HighlightedText />]}
                            >
                                {`The overall outpatient waiting time during the period you viewed averaged <0>around {{insightTotalAvg}} minutes</0>, and by time zone, <0>{{meridiem}} {{insightMaxAvgHour}}</0> had the longest waiting time, <0>averaging {{insightMaxAvg}} minutes</0>.`}
                            </Trans>
                        </BoxBodyText>
                    </InsightBox>

                    <Chart data={data} onDownloadClick={handleDownloadClick} />
                </>
            ) : (
                <NoDataBlock text={t('No data')} />
            )}
            {children}
        </WidgetCard>
    );
};

export default OutpatientWaitAnalysisByTime;
