import { useTranslation } from '@hooks';
import { useState } from 'react';
import { ASSET_MANAGEMENT } from '../../constants';

// 위험도 Select
const useRiskLevelOptions = () => {
    const t = useTranslation(ASSET_MANAGEMENT);
    const options = [
        { value: 'high', label: t('high') },
        { value: 'middle', label: t('middle') },
        { value: 'low', label: t('low') },
    ];

    const [riskLevelList, setRiskLevelList] = useState(options.map(ele => ele.value));

    const handleRiskLevelChange = selected => {
        setRiskLevelList(selected.map(v => v.value));
    };

    return {
        riskLevelOptions: options,
        riskLevelList,
        handleRiskLevelChange,
    };
};

export default useRiskLevelOptions;
