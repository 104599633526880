import React from 'react';
import moment from 'moment';
import useTranslation from '@hooks/useTranslation';

const DEFAULT_DATA = '-';
const SENSING_TYPE_TEMPERATURE = 'TEMPERATURE';
const Sheet = ({ data }) => {
    const t = useTranslation('RefrigeratorSensor');
    const { targetName, sensorName, wardInfo, lastDate, sensorItems } = data;
    const temperatureSensor = sensorItems.find(sensor => sensor.sensingType === SENSING_TYPE_TEMPERATURE);

    const titles = [
        { key: 'Name', value: targetName },
        { key: 'Sensor Name', value: sensorName },
        { key: 'Ward/Department', value: wardInfo },
        {
            key: 'Temperature',
            value: temperatureSensor?.currentValue
                ? `${temperatureSensor?.currentValue.toFixed(2)} ${temperatureSensor.measurementUnit}`
                : DEFAULT_DATA,
        },
        { key: 'Status', value: temperatureSensor ? t(temperatureSensor.status) : DEFAULT_DATA },
        { key: 'Recognition date', value: lastDate ? moment(lastDate * 1000).format('YYYY-MM-DD') : DEFAULT_DATA },
    ];

    return (
        <table className="w-100 h-100 border-size-1 border-depth-7">
            <tbody>
                {titles.map(({ key, value }, idx) => {
                    return (
                        <tr key={`${key}_${idx}`} className="border-bottom border-depth-7">
                            <td className="w-20 px-2 py-1 text-ellipsis bg-depth-4">
                                <span title={t(key)}>{t(key)}</span>
                            </td>
                            <td className="w-80 px-2 py-1 border-start border-depth-7">
                                <span title={value}>{value}</span>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default Sheet;
