import React from 'react';
import useTranslation from '@hooks/useTranslation';

const BottomMenu = ({ setting, handleSetting }) => {
    const t = useTranslation('Menu');
    return (
        <div className="pnt-sidebar-bottom app-sidebar__inner">
            <div className="metismenu vertical-nav-menu">
                <ul className="metismenu-container">
                    <li className="metismenu-item">
                        <a
                            className="metismenu-link"
                            href="#"
                            onClick={e => {
                                e.preventDefault();
                                handleSetting();
                            }}
                        >
                            {setting ? (
                                // <i className="metismenu-icon material-icons-round">home</i>
                                <i className="metismenu-icon icon-home" />
                            ) : (
                                // <i className="metismenu-icon material-icons-round">settings</i>
                                <i className="metismenu-icon icon-setting" />
                            )}
                            <span
                                style={{
                                    maxWidth: '100%',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    display: 'inline-block',
                                    fontWeight: 'bold',
                                }}
                            >
                                {setting ? t('Back to Home') : t('Settings')}
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default BottomMenu;
