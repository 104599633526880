import React, { useEffect, useState } from 'react';
import Label from '@components/Label';
import { getVitalSignsDetailInfoApi } from '@api/monitoring';
import useAsync from '@hooks/useAsync';
import MainView from './Components/MainView';
import useTranslation from '@hooks/useTranslation';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DUMMY_DETAIL_INFO, VITAL_SIGN_FIXED_PARAM } from '../../constants';
import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';
import { useAppSelector } from '@hooks';
import { LoadingBlock } from '@components';
import { getBatteryDisplayValue } from '../../util';

const ViewDetail = ({ targetNum }) => {
    const t = useTranslation('VitalSignMonitoring');
    const { mode } = useAppSelector(state => state.ScreenInfo);
    const [info, setInfo] = useState({});
    const wardMapInfo = useAppSelector(state => state.DepartmentsInfo.wardMapInfo);

    const { targetName, targetId, ward, room, bed, deviceName, battery } = info;

    const {
        state: { isLoading },
        promise: getVitalSignsDetailInfo,
    } = useAsync({
        promise: getVitalSignsDetailInfoApi,
        fixedParam: VITAL_SIGN_FIXED_PARAM,
        resolve: res => {
            setInfo(res);
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        if (mode === SCREEN_MODE_EDIT || mode === SCREEN_MODE_PREVIEW) {
            setInfo(DUMMY_DETAIL_INFO);
            return;
        }
        getVitalSignsDetailInfo({ targetId: targetNum });
    }, []);

    return (
        <div className="d-flex h-100">
            <PerfectScrollbar className="w-25 p-3 border border-depth-7">
                <LoadingBlock blocking={isLoading}>
                    <Label name={t('Patient Name')} labelGroupClassName="mb-2" value={targetName ? targetName : '-'} />
                    <Label name={t('Patient ID')} labelGroupClassName="mb-2" value={targetId ? targetId : '-'} />
                    <Label name={t('Ward')} labelGroupClassName="mb-2" value={ward ? wardMapInfo[ward] : '-'} />
                    <Label name={t('Room')} labelGroupClassName="mb-2" value={room ? room : '-'} />
                    <Label name={t('Bed')} value={bed ? bed : '-'} />

                    <div className="pnt-divider horizon-line"></div>

                    <Label name={t('Device Name')} labelGroupClassName="mb-2" value="HiCardi" />
                    <Label name={t('Sensor Name')} labelGroupClassName="mb-2" value={deviceName ? deviceName : '-'} />
                    <Label name={t('Battery')} labelGroupClassName="mb-2" value={getBatteryDisplayValue(battery)} />
                    {/*<Label name={t('Floor')} labelGroupClassName="mb-2" value={floor ? floor : '-'} />*/}
                    {/*<Label name={t('Signal Status')} labelGroupClassName="mb-2" value={status ? t(status) : '-'} />*/}
                </LoadingBlock>
            </PerfectScrollbar>
            <PerfectScrollbar className={'w-75 ml-3 p-3 border border-depth-7'}>
                <MainView targetId={targetNum} />
            </PerfectScrollbar>
        </div>
    );
};

export default ViewDetail;
