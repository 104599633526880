export const DEFAULT_DATA = {
    regAssetCount: {
        total: 0,
        // TODO: 2차에 기획 결정
        // ownedAssetCount: 0,
        // rentalAssetCount: 0,
    },
    permittedZoneAssetCount: {
        total: 0,
        activateCount: 0,
        // lostSignalCount: 0,
    },
    disallowedZoneAssetCount: {
        total: 0,
        activateCount: 0,
        // lostSignalCount: 0,
    },
    assetDailyCheckCount: {
        total: 0,
        highRiskCount: 0,
        normalRiskCount: 0,
    },
    assetCountCheckCount: {
        countCheckAssetCount: 0,
        dayCountSum: 0,
        eveningCountSum: 0,
        nightCountSum: 0,
    },
    assetsLentCount: {
        total: 0,
    },
    assetsBorrowedCount: {
        total: 0,
    },
};

export const HEADER_LIST = Object.keys(DEFAULT_DATA);

export const DEFAULT_VALUE = '-';
