import { RefObject, useCallback, useEffect, useState } from 'react';

interface LoadingProps<D extends Object = {}> {
    tableRef: RefObject<HTMLDivElement>;
    list: Array<D>;
    pageNum: number;
    totalPage: number;
    paging?: boolean;
    isLoading?: boolean;
}

const useTableLoading = ({ tableRef, list, pageNum, totalPage, paging, isLoading }: LoadingProps) => {
    const [loading, setLoading] = useState(false);

    const disableButton = () => {
        setLoading(true);

        if (tableRef.current) {
            const pageBtn = tableRef.current.querySelectorAll('div > button');

            pageBtn.forEach((child: Element) => {
                child.classList.add('pe-none');
                child.classList.add('form-disable');
            });
        }
    };

    const checkUpdateData = useCallback(
        (event: Event) => {
            if (event) {
                const eventElement = event.target as HTMLElement;
                switch (eventElement.innerText) {
                    case 'keyboard_double_arrow_left': {
                        if (pageNum !== 1) {
                            disableButton();
                        }
                        break;
                    }
                    case 'keyboard_double_arrow_right': {
                        if (pageNum !== totalPage) {
                            disableButton();
                        }
                        break;
                    }
                    case 'keyboard_arrow_right': {
                        if (pageNum < totalPage) {
                            disableButton();
                        }
                        break;
                    }
                    case 'keyboard_arrow_left': {
                        if (pageNum > 1) {
                            disableButton();
                        }
                        break;
                    }
                }
            }
        },
        [list],
    );

    useEffect(() => {
        if (isLoading === undefined) return;

        const table = tableRef.current;
        const pageBtn = table?.querySelectorAll('div > button');

        if (pageBtn && paging) {
            pageBtn.forEach((child: Element) => {
                child.addEventListener('click', checkUpdateData);
            });
        }

        return () => {
            if (pageBtn && paging) {
                pageBtn.forEach((child: Element) => {
                    child.removeEventListener('click', checkUpdateData);
                });
            }
        };
    }, [checkUpdateData]);

    useEffect(() => {
        if (!isLoading) {
            if (paging && tableRef.current) {
                const pageBtn = tableRef.current.querySelectorAll('div > button');

                pageBtn.forEach((child: Element) => {
                    child.classList.remove('pe-none');
                    child.classList.remove('form-disable');
                });
            }
            setLoading(false);
        } else {
            disableButton();
        }
    }, [isLoading]);

    return loading;
};

export default useTableLoading;
