import React, { useEffect, useState } from 'react';
import { ChartData as ChartDataType } from 'chart.js';
import NoDataBlock from '../../../Components/NoDataBlock';
import { PieChart } from '@components/Charts';

interface ChartData {
    ranking: number;
    key: string;
    name: string;
    y: number;
}

interface Props {
    data: ChartData[];
}

const ResponsivePieChart = ({ data }: Props) => {
    const [chartData, setChartData] = useState<ChartDataType<'pie'>>({ datasets: [] });

    useEffect(() => {
        setChartData({
            labels: data.map(({ name }) => name),
            datasets: [{ data: data.map(({ y }) => y) }],
        });
    }, [data]);

    return !data.length ? <NoDataBlock /> : <PieChart data={chartData} />;
};

export default ResponsivePieChart;
