import React from 'react';
import moment from 'moment';
import { DatePicker } from '@components';
import { MAXIMUN_DIFF_DAY, MAXIMUN_DIFF_MONTH } from '../util/useRangeDate';

const DatePickerGroupByDateType = ({ dateType, startDate, handleStartChange, endDate, handleEndChange }) => {
    const startDateMs = startDate * 1000;
    const startDateMoment = moment(startDateMs);

    return (
        <>
            {dateType === 'daily' ? (
                <>
                    <DatePicker
                        value={startDate}
                        onChange={handleStartChange}
                        withoutTime
                        valueType="s"
                        disabledKeyboardNavigation
                    />
                    <span>~</span>
                    <DatePicker
                        value={endDate}
                        onChange={handleEndChange}
                        withoutTime
                        valueType="s"
                        disabledKeyboardNavigation
                        minDate={startDateMs}
                        maxDate={startDateMoment.add(MAXIMUN_DIFF_DAY, 'days').valueOf()}
                    />
                </>
            ) : dateType === 'monthly' ? (
                <>
                    <DatePicker
                        value={startDate}
                        onChange={handleStartChange}
                        withoutTime
                        withoutDay
                        valueType="s"
                        disabledKeyboardNavigation
                        showMonthYearPicker
                        dateFormat="yyyy-MM"
                    />
                    <span>~</span>
                    <DatePicker
                        value={endDate}
                        onChange={handleEndChange}
                        withoutTime
                        withoutDay
                        valueType="s"
                        disabledKeyboardNavigation
                        showMonthYearPicker
                        dateFormat="yyyy-MM"
                        minDate={startDateMs}
                        maxDate={startDateMoment.add(MAXIMUN_DIFF_MONTH + 1, 'months').valueOf()}
                    />
                </>
            ) : (
                <>
                    <DatePicker
                        value={startDate}
                        onChange={handleStartChange}
                        withoutTime
                        valueType="s"
                        disabledKeyboardNavigation
                        showYearPicker
                        dateFormat="yyyy"
                    />
                    <span>~</span>
                    <DatePicker
                        value={endDate}
                        onChange={handleEndChange}
                        withoutTime
                        valueType="s"
                        disabledKeyboardNavigation
                        showYearPicker
                        dateFormat="yyyy"
                        minDate={startDateMs}
                    />
                </>
            )}
        </>
    );
};

export default DatePickerGroupByDateType;
