import React from 'react';
import ContextProvider from '@components/ContextProvider';
import WidgetCard from '../../Components/WidgetCard';
import examPatientsMonitoringSlice, {
    ExamPatientsMonitoringDispatchContext,
    ExamPatientsMonitoringStateContext,
    setSelectedSubject,
    useExamPatientsMonitoringDispatchContext,
    useExamPatientsMonitoringStateContext,
} from './slice';
import { WidgetProps } from '../../staticInfo';
import styles from '@asset/pnt/widgets/sh/ExamPatientsMonitoring.module.scss';
import Button from '@components/Button';
import PatientsTable from './Components/PatientsTable';
import useTranslation from '@hooks/useTranslation';
import PatientDetails from './Components/PatientDetails';
import cx from 'classnames';
import { useSettings } from '../../util/useSettings';

const ExamPatientsMonitoring = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    return (
        <ContextProvider
            StateContext={ExamPatientsMonitoringStateContext}
            DispatchContext={ExamPatientsMonitoringDispatchContext}
            slice={examPatientsMonitoringSlice}
        >
            <ContextConsumer widgetInfo={widgetInfo} {...restProps}>
                {children}
            </ContextConsumer>
        </ContextProvider>
    );
};

const ContextConsumer = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    const t = useTranslation('ExamPatientsMonitoring');
    const dispatch = useExamPatientsMonitoringDispatchContext();
    const settings = useSettings(widgetInfo.config);

    const { selectedSubject } = useExamPatientsMonitoringStateContext();

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            {...restProps}
            headerAction={
                selectedSubject && (
                    <Button className="btn-lightgray" onClick={() => dispatch(setSelectedSubject(null))}>
                        {t('Back', 'Button')}
                    </Button>
                )
            }
        >
            <div className={cx(styles['content-wrapper'], selectedSubject && styles['details'])}>
                {selectedSubject ? <PatientDetails /> : <PatientsTable viewAllLists={settings.viewAllLists ?? false} />}
            </div>
            {children}
        </WidgetCard>
    );
};

export default ExamPatientsMonitoring;
