import React, { createContext, useContext } from 'react';
import ContextProvider from '@components/ContextProvider';
import TopFiveCongestedGeofenceContainer from './TopFiveCongestedGeofenceContainer';
import topFiveCongestedGeofenceSlice from './congestedGeofenceSlice';

export const TopFiveCongestedGeofenceStateContext = createContext(null);
export const TopFiveCongestedGeofenceDispatchContext = createContext(null);

export const useTopFiveCongestedGeofenceContext = () => useContext(TopFiveCongestedGeofenceStateContext);
export const useTopFiveCongestedGeofenceDispatch = () => useContext(TopFiveCongestedGeofenceDispatchContext);

const TopFiveCongestedGeofence = ({ children, widgetInfo, ...restProps }) => {
    return (
        <ContextProvider
            StateContext={TopFiveCongestedGeofenceStateContext}
            DispatchContext={TopFiveCongestedGeofenceDispatchContext}
            slice={topFiveCongestedGeofenceSlice}
        >
            <TopFiveCongestedGeofenceContainer widgetInfo={widgetInfo} children={children} {...restProps} />
        </ContextProvider>
    );
};

export default TopFiveCongestedGeofence;
