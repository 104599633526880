import React from 'react';
import { ErrorHandleSwitch } from '../../Components/Router';
import { Redirect, Route } from 'react-router-dom';
import { A_PATH_MENU, A_PATH_MENU_LIST, A_PATH_MENU_CREATE, A_PATH_MENU_DETAIL } from '../../Components/Router/path';
import MenuDetail from './MenuDetail';
import MenuList from './MenuList';

const MenuManagement = () => {
    return (
        <ErrorHandleSwitch>
            <Route exact path={`${A_PATH_MENU}/`} render={() => <Redirect to={A_PATH_MENU_LIST} />} />
            <Route exact path={A_PATH_MENU_LIST} component={MenuList} />
            <Route exact path={[A_PATH_MENU_CREATE, A_PATH_MENU_DETAIL]} component={MenuDetail} />
        </ErrorHandleSwitch>
    );
};

export default MenuManagement;
