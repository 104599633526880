import React, { useContext, useState } from 'react';
import { useSettings } from '../../util/useSettings';
import { editWidgetSettings } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import useTranslation from '@hooks/useTranslation';
import WidgetSettingModal from '../../Components/WidgetSettingModal';
import { ScreenEditDispatchContext } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit';
import { HiddenMapTile } from '../../Components/WidgetSettingOptions';

const RealTimeSensorMonitoringSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('RealTimeSensorMonitoring');
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { settings: customSettings } = config;
    const dispatch = useContext(ScreenEditDispatchContext);

    const initialWidgetInfo =
        settings?.hiddenMapTile !== undefined ? { hiddenMapTile: settings.hiddenMapTile } : { hiddenMapTile: false };
    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState(initialWidgetInfo);

    const handleSubmitClick = data => {
        const checkedSettings = customSettings
            ? { ...customSettings, ...data, ...updatedWidgetInfo }
            : { ...data, ...updatedWidgetInfo };

        const updatedWidgetSettings = {
            id: widgetInfo.id,
            settings: checkedSettings,
        };

        dispatch(editWidgetSettings(updatedWidgetSettings));
    };

    const handleHiddenMapTile = checkedHidden => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, hiddenMapTile: checkedHidden };
        });
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo(initialWidgetInfo);
    };

    return (
        <WidgetSettingModal
            headerTitle={t('Realtime Sensor Monitoring Setting')}
            widgetInfo={widgetInfo}
            socketWidget
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <HiddenMapTile
                updateWidgetInfo={updatedWidgetInfo}
                customSettings={customSettings}
                onChange={handleHiddenMapTile}
            />
        </WidgetSettingModal>
    );
};

export default RealTimeSensorMonitoringSetting;
