import React, { useEffect, useMemo, useRef } from 'react';

import useTranslation from '@hooks/useTranslation';
import useFilter from '@hooks/useFilter';
import useAsync from '@hooks/useAsync';
import { getIVInjectionMonitoringListApi } from '@api/monitoring';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_IV_INJECTION_SENSING } from '@reducer/SocketInfo';
import ToggleButton from '@components/Button/ToggleButton';

import IVInjectionMonitoringCardList from './Components/IVInjectionMonitoringCardList';
import IVInjectionUpdateModal from './Components/IVInjectionUpdateModal';
import { setIVInjectionList, toggleRemainingValueDisplay, updateIVInjectionList } from './IVInjectionMonitoringSlice';
import { useIVInjectionMonitoringContext, useIVInjectionMonitoringDispatch } from './index';
import WidgetCard from '../../Components/WidgetCard';

const CATEGORY_CODE = 'patient';
const postParams = { categoryCodes: CATEGORY_CODE };

const IVInjectionMonitoringContainer = ({ widgetInfo, children, ...restProps }) => {
    const t = useTranslation('IVInjectionMonitoring');
    const widgetRef = useRef();
    const filterParams = useFilter({ postParam: postParams });
    const dispatch = useIVInjectionMonitoringDispatch();
    const state = useIVInjectionMonitoringContext();

    // 수액 List 조회
    const { promise: getIVInjectionMonitoringList } = useAsync({
        promise: getIVInjectionMonitoringListApi,
        param: filterParams,
        resolve: res => {
            dispatch(setIVInjectionList(res));
        },
    });

    // 실시간 update
    const socketFilterConfig = useMemo(() => {
        return {};
    }, []);

    useSocketEvent({
        name: EVENT_TYPE_IV_INJECTION_SENSING,
        filterConfig: socketFilterConfig,
        handler: data => {
            dispatch(updateIVInjectionList(data));
        },
    });

    // 수액 잔량 보기 토글
    const handleRemainingValueDisplay = () => {
        dispatch(toggleRemainingValueDisplay());
    };

    // 수액 리스트 호출
    const handleGetIVMonitoringList = () => getIVInjectionMonitoringList(filterParams);

    // filter 내용 변경시 api 호출
    useEffect(() => {
        getIVInjectionMonitoringList();
    }, [filterParams]);

    return (
        <WidgetCard
            ref={widgetRef}
            widgetInfo={widgetInfo}
            headerAction={
                <div className="d-flex align-items-center">
                    <span>{t('Remaining Amount')}</span>
                    <ToggleButton
                        className="form-h-small ml-2"
                        checked={state.remainingValueDisplay}
                        handleChecked={handleRemainingValueDisplay}
                    />
                </div>
            }
            {...restProps}
        >
            <IVInjectionMonitoringCardList widgetInfo={widgetInfo} handleCallbackApi={handleGetIVMonitoringList} />
            <IVInjectionUpdateModal handleCallbackApi={handleGetIVMonitoringList} />
            {children}
        </WidgetCard>
    );
};

export default IVInjectionMonitoringContainer;
