import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { NOTIFICATION_TYPE } from '@util/mappInfo';

// screenLimitAlert
// availHeight = Windows 작업표시줄과 같은 인터페이스 기능 빼고 사용자 화면의 높이를 픽셀 단위로 반환
// 64 = 알람의 높이 사이즈(padding, margin 포함)
const INIT_SCREEN_LIMIT = Math.floor((document.documentElement.clientHeight - 120) / 64);
// 알림 설정 정보 Key
export const NOTIFICATION_SETTING_KEY = {
    [NOTIFICATION_TYPE.USER]: 'userAlertInfo',
    [NOTIFICATION_TYPE.ROLE]: 'roleAlertInfo',
    [NOTIFICATION_TYPE.GROUP]: 'groupAlertInfoList',
};
// 알림 설정 요청 정보 Key
export const NOTIFICATION_PARAMETER_KEY = {
    [NOTIFICATION_SETTING_KEY.user]: 'userAlertSetting',
    [NOTIFICATION_SETTING_KEY.role]: 'roleAlertSetting',
    [NOTIFICATION_SETTING_KEY.group]: 'groupAlertSetting',
    alertSettingNum: 'alertSettingNum',
    groupNumList: 'loginGroups',
    propertyId: 'propertyId',
    beaconAlertInterfaceCommandList: 'interfaceBeaconList',
    sensorAlertInterfaceCommandList: 'interfaceSensorList',
};
// 센서 알림 설정의 범위 (전체, 센서 아이템)
export const SENSOR_SCOPE_KEY = {
    ALL: 'ALL',
    SINGLE: 'SINGLE',
};
const BOOKMARK_TARGET_REQUEST_PARAM_KEY = 'targetNums';
const SUFFIX_TIME = '000';
const initialState = {
    // 알림 내용 (소켓 응답 데이터)
    notificationContents: null,
    // 팝업(Toast) limit 서비스 설정 값
    originalLimit: 0,
    // 알림 팝업(Toast) 서비스 설정 값
    toastConfig: {
        useToast: true,
        toastLimit: INIT_SCREEN_LIMIT,
        autoClose: 5000,
    },
    // 알림 소리 서비스 설정 값
    soundConfig: {
        useSound: false,
        sensorSoundUrl: null,
        tagSoundUrl: null,
        locationSoundUrl: null,
    },
    // 사용자 전체 알림 설정
    userWideNotificationSettings: null,
    // 사용자 알림 수신 타입 설정
    selectedNotificationTypes: [NOTIFICATION_TYPE.USER],
    // 선택한 알림 타입에 따른 알림 설정 서버 요청 값
    selectedNotificationSettings: {
        [BOOKMARK_TARGET_REQUEST_PARAM_KEY]: [],
    },
    // 알림 목록에서 알림 대상 '위치'를 보기 위해 선택한 알림 정보
    selectedNotificationLogInfo: {},
    notificationCount: 0,
};

const { actions, reducer } = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        // 알림 소켓 응답 데이터 수신 및 알림 정보 생성
        setNotificationContents: (state, action) => {
            const { event, target, unixTime } = action.payload;
            const { eventGroup, interfaceCommandType } = event;
            const { targetNum, targetName } = target;
            const notificationDate = moment.unix(unixTime).format('YYYY-MM-DD HH:mm:ss');
            state.notificationContents = {
                eventGroup,
                targetName,
                interfaceCommandType,
                notificationDate,
                targetNum,
            };
            state.notificationCount += 1;
        },
        // 사용자 전체 알림 설정 세팅 또는 갱신
        setUserWideNotificationSettings: (state, action) => {
            state.userWideNotificationSettings = action.payload;

            // 선택한 알림 수신 타입이 없을 경우
            if (!state.selectedNotificationTypes.length) {
                return;
            }
            // 사용자 전체 알림 설정에서 선택한 알림 수신 타입 별 설정 서버 요청 객체로 변경 및 세팅
            state.selectedNotificationSettings = replaceSelectedNotificationSettingsWithRequestParameterTypes(
                state.selectedNotificationTypes,
                action.payload,
            );
        },
        // '위치' 정보를 보기 위한 선택한 알림 정보 생성
        setSelectedNotificationLogInfo: (state, action) => {
            state.selectedNotificationLogInfo = action.payload;
        },
        // 사용자 알림 수신 타입 핸들러
        handleSelectedNotificationTypes: (state, action) => {
            const payloadSelectedTypes = action.payload;
            // 선택한 알림 수신 타입이 없는 경우
            if (!payloadSelectedTypes || !payloadSelectedTypes.length) {
                return;
            }
            state.selectedNotificationTypes = action.payload;

            // 사용자 전체 알림 설정 값이 없는 경우
            if (!state.userWideNotificationSettings) {
                return;
            }
            // 사용자 전체 알림 설정에서 선택한 알림 수신 타입 별 설정 서버 요청 객체로 변경 및 세팅
            state.selectedNotificationSettings = replaceSelectedNotificationSettingsWithRequestParameterTypes(
                payloadSelectedTypes,
                state.userWideNotificationSettings,
            );
        },
        // 알림 서비스 설정 값 [팝업(Toast), 소리]
        setConfigValue: (state, action) => {
            const {
                alertSoundConfig: { rootDefaultUrl, soundOnOff, sensorStatus, tagStatus, location },
                alertPopupConfig: { popupActive, showCount, showTime },
            } = action.payload;

            // wms 서비스 설정의 알림 설정값
            const useToast = popupActive === 'Y'; // 팝업(Toast) 사용 여부
            const limit = parseInt(showCount); // 팝업(Toast) 노출 갯수
            const autoCloseTime = showTime + SUFFIX_TIME; // 팝업(Toast) 지속 시간
            state.toastConfig = {
                ...state.toastConfig,
                useToast,
                toastLimit: Math.min(INIT_SCREEN_LIMIT, limit),
                autoClose: parseInt(autoCloseTime),
            };
            state.originalLimit = limit;

            // wms 서비스 설정의 알림 소리 설정값
            const useSound = soundOnOff === 'yes'; // 소리 사용 여부
            const sensorSoundUrl = rootDefaultUrl + sensorStatus; // 센서 알림 소리 파일
            const tagSoundUrl = rootDefaultUrl + tagStatus; // 태그 알림 소리 파일
            const locationSoundUrl = rootDefaultUrl + location; // 위치 알림 소리 파일
            state.soundConfig = {
                ...state.soundConfig,
                useSound,
                sensorSoundUrl,
                tagSoundUrl,
                locationSoundUrl,
            };
        },
        setToastLimit: (state, action) => {
            state.toastConfig = {
                ...state.toastConfig,
                toastLimit: Math.min(action.payload, state.originalLimit),
            };
        },
        clearAlertContents: state => {
            state.notificationContents = null;
        },
        setNotificationCount: (state, action) => {
            const count = action.payload;
            if (typeof count === 'number') {
                state.notificationCount = action.payload;
            }
        },
    },
});

/**
 * 사용자 전체 알림 설정에서 선택한 알림 수신 타입의 설정만 가져오고
 * 알림 API와 알림 소켓을 요청하기 위한 요청 객체 타입을 만드는 작업
 * 기존 객체 Key를 요청 Parameter key로 변경하는 작업
 *
 * @param selectedNotificationTypes 선택한 알림 수신 타입
 * @param originUserWideNotificationSettings 사용자 전체 알림 설정
 * @returns {*} 알림 API 또는 알림 소켓 요청 객체
 */
const replaceSelectedNotificationSettingsWithRequestParameterTypes = (
    selectedNotificationTypes,
    originUserWideNotificationSettings,
) => {
    // 초기값을 만들기 위한 조건
    // '개인' 알림 수신 타입인 경우
    const userTargetCondition = selectedNotificationTypes.includes(NOTIFICATION_TYPE.USER);
    // '역할' 알림 수신 타입인 경우
    // propertyId 즉, 카테고리 속성 값이 없는 경우 북마크 대상만 알림 발생
    // 카테고리 속성이 있는 경우 사용자 그룹 설정에 따른 알림 발생
    const roleTargetCondition =
        selectedNotificationTypes.includes(NOTIFICATION_TYPE.ROLE) &&
        !originUserWideNotificationSettings[NOTIFICATION_SETTING_KEY[NOTIFICATION_TYPE.ROLE]]?.propertyId;
    // 조건에 따른 초기값 설정
    const init =
        userTargetCondition || roleTargetCondition
            ? { [BOOKMARK_TARGET_REQUEST_PARAM_KEY]: originUserWideNotificationSettings.bookmarkTargetNumList }
            : {};

    // 선택한 알림 수신 타입에 따른 요청 객체 생성
    return selectedNotificationTypes.reduce((selectedNotificationSettings, currentNotificationType) => {
        // 알림 수신 타입의 알림 설정 Key(기존 key)
        const currentKey = NOTIFICATION_SETTING_KEY[currentNotificationType];
        // 알림 수신 타입의 요청 객체 Key(변경할 key)
        const parameterKey = NOTIFICATION_PARAMETER_KEY[NOTIFICATION_SETTING_KEY[currentNotificationType]];
        // 알림 설정이 없는 경우
        if (!originUserWideNotificationSettings[currentKey]) {
            return selectedNotificationSettings;
        }
        // 알림 설정이 그룹이 아닌 경우 (그룹의 경우 배열)
        if (!Array.isArray(originUserWideNotificationSettings[currentKey])) {
            // Entry를 객체로 변경하여 서버 요청 객체의 변경할 key에 할당
            selectedNotificationSettings[parameterKey] = Object.fromEntries(
                // 객체를 Entry로 변경 후 map 함수를 통해 key 정보 변경
                Object.entries(originUserWideNotificationSettings[currentKey]).map(([key, value]) => [
                    NOTIFICATION_PARAMETER_KEY[key],
                    value,
                ]),
            );
            return selectedNotificationSettings;
        }
        // 알림 설정이 그룹인 경우
        selectedNotificationSettings[parameterKey] = originUserWideNotificationSettings[currentKey].map(originSetting =>
            Object.fromEntries(
                Object.entries(originSetting).map(([key, value]) => [NOTIFICATION_PARAMETER_KEY[key], value]),
            ),
        );
        return selectedNotificationSettings;
    }, init);
};

export const {
    setNotificationContents,
    setUserWideNotificationSettings,
    setSelectedNotificationLogInfo,
    handleSelectedNotificationTypes,
    setConfigValue,
    clearAlertContents,
    setToastLimit,
    setNotificationCount,
} = actions;
export default reducer;
