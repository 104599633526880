import React, { useMemo } from 'react';
import { Button, Modal, Table } from '@components';
import { useAsync, useTranslation } from '@util/hooks';
import styled from 'styled-components';
import NumberInput from '@components/Input/NumberInput';
import { Row } from '.';
import useModal from '../../util/useModal';
import { postRentalAvailabilityList } from '@api/am/management';

interface Props {
    rows: Row[];
    originRows: Row[];
    onCellChange: (rowIndex: number, columnId: string, value: string | number) => void;
    getList: () => void;
}

const isExcessRowExist = (rows: Row[]) => {
    return rows.filter(({ count, rentalMaxCount }) => rentalMaxCount > count).length > 0;
};

const RentalAvailabilityTable = ({ rows, originRows, onCellChange, getList }: Props) => {
    const t = useTranslation('AssetManagement');
    const [isOpen, handleToggle, handleOpen] = useModal();

    const { promise: postList } = useAsync({
        promise: postRentalAvailabilityList,
        resolve: () => {
            handleOpen();
        },
    });

    const handleSaveClick = () => {
        if (isExcessRowExist(rows)) {
            handleOpen();
            return;
        }

        postList({
            maxCountList: rows.map(({ ward, categoryCode, rentalMaxCount }) => ({
                ward,
                categoryCode,
                maxCount: rentalMaxCount,
            })),
        });
    };

    const columns = useMemo(
        () => [
            {
                Header: t('Asset Count'),
                accessor: 'count',
                width: 20,
            },
            {
                Header: t('Lendable Asset Count'),
                className: 'd-flex justify-content-center',
                accessor: 'rentalMaxCount',
                Cell: ({ row, column }: any) => {
                    return (
                        <NumberInput
                            inputGroupClassName={'w-50 min-w-none h-auto'}
                            className={'text-center py-1 pr-0'}
                            value={row.original.rentalMaxCount}
                            min={0}
                            max={originRows[row.index]?.count}
                            handleChange={e => {
                                onCellChange(row.index, column.id, Number(e.target.value));
                            }}
                        />
                    );
                },
                width: 20,
            },
            {
                Header: t('Category Name'),
                accessor: 'categoryName',
            },
        ],
        [onCellChange, originRows, t],
    ) as any;

    return (
        <>
            <div>
                <span className="pnt-txt txt-bold s-5">{t('Rental Quantity Setting')}</span>
                <TableWrapper>
                    <Table columns={columns} data={{ rows }} paging={false} />
                </TableWrapper>
                <Button className="btn-secondary mt-2" onClick={handleSaveClick}>
                    {t('Save')}
                </Button>
            </div>
            <Modal
                initModal={isOpen}
                toggleModal={handleToggle}
                headerTitle={t('Notification')}
                bodyText={
                    isExcessRowExist(rows) ? (
                        t('Check category count: ') +
                        rows
                            .filter(({ count, rentalMaxCount }) => rentalMaxCount > count)
                            .map(({ categoryName }) => categoryName)
                    ) : (
                        <div>{t('Save is complete.')}</div>
                    )
                }
                removeCancel
                okCallback={() => {
                    if (!isExcessRowExist(rows)) {
                        getList();
                    }
                }}
            />
        </>
    );
};

const TableWrapper = styled.div`
    height: 38rem;
`;

export default RentalAvailabilityTable;
