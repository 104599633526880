import React, { useState } from 'react';
import { useAsync } from '@hooks';
import { useOutpatientBaselineTestingState } from './index';
import { getOutpatientBaselineTestStatusCountApi, getOutpatientBaselineTestStatusListApi } from '@api/monitoring';
import BasicTestDatePicker from './BasicTestDatePicker';
import moment from 'moment';
import OutpatientBaselineTestingCounts from './Components/OutpatientBaselineTestingCounts';
import OutpatientBaselineTestingLists from './Components/OutpatientBaselineTestingLists';
import useDepartmentOptions from '../util/useDepartmentOptions';

const DEFAULT_OUTPATIENT_BASIC_TEST_COUNT = {
    total: 0,
    inspectCountStat: {
        doneInspect: 0,
        nonDoneInspect: 0,
    },
    safetyCountStat: {
        safePatient: 0,
        dangerPatient: 0,
    },
};
const DEFAULT_OUTPATIENT_BASIC_TEST_LIST = {
    pageSize: 15,
    totalCount: 0,
    totalPage: 1,
    rows: [],
};

const OutpatientBaselineTestingItemContainer = ({ widgetInfo }) => {
    const [startDate, setStartDate] = useState(moment().startOf('day').unix());
    const [endDate, setEndDate] = useState(
        moment().startOf('day').add(23, 'hours').add(59, 'm').add(59, 'second').unix(),
    );

    const departmentValues = useDepartmentOptions();
    const { department } = departmentValues;

    const {
        selectedOutpatientBasicTestStatus: status,
        keyword,
        outpatientBasicTestListParam,
    } = useOutpatientBaselineTestingState();

    const { state: outpatientBaselineCountState } = useAsync({
        promise: getOutpatientBaselineTestStatusCountApi,
        immediate: true,
        param: {
            ...outpatientBasicTestListParam,
            startDate: startDate,
            endDate: endDate,
            departmentCodes: department.toString(),
        },
        reject: err => {
            console.error(err);
        },
        deps: [startDate, endDate, keyword, department],
    });

    const { state: outpatientBaselineListState } = useAsync({
        promise: getOutpatientBaselineTestStatusListApi,
        immediate: true,
        param: {
            ...outpatientBasicTestListParam,
            startDate: startDate,
            endDate: endDate,
            departmentCodes: department.toString(),
        },
        reject: err => {
            console.error(err);
        },
        deps: [startDate, endDate, status, keyword, department],
    });

    const handleDate = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end);
    };

    const outpatientBaselineCount = outpatientBaselineCountState.response ?? DEFAULT_OUTPATIENT_BASIC_TEST_COUNT;
    const outpatientBaselineList = outpatientBaselineListState.response ?? DEFAULT_OUTPATIENT_BASIC_TEST_LIST;

    return (
        <div className={'w-100 h-100'}>
            <OutpatientBaselineTestingCounts outpatientCount={outpatientBaselineCount} />
            <BasicTestDatePicker
                departmentValues={departmentValues}
                handleDate={handleDate}
                startDate={startDate}
                endDate={endDate}
            />
            <OutpatientBaselineTestingLists outpatientBaselineTestList={outpatientBaselineList} />
        </div>
    );
};

export default OutpatientBaselineTestingItemContainer;
