import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useTranslation from '@hooks/useTranslation';
import cx from 'classnames';
import { TreeSelectContext } from '@components/Select/TreeSelect';
import { components } from 'react-select';
import Tree from '@components/Select/TreeSelect/Tree';
import { mergeSelected, findAllOptions, findAllChildren } from '@components/Select/TreeSelect/Parts';
import { CustomTreeSelectContext, CustomTreeSelectDispatchContext } from './index';
import { setEditable } from '../reducer';
import { FilterGeneratorContext } from '../../../FilterGenerator';

export const TreeMenuList = ({ children, getValue, setValue, options, ...restProps }) => {
    const t = useTranslation('TreeSelect');
    const filterT = useTranslation('Filter');
    const selected = getValue();
    const { valueKey, labelKey, treeKey, parentKey, flattenData } = useContext(TreeSelectContext);
    const dispatch = useContext(CustomTreeSelectDispatchContext);
    const { filterEditMode } = useContext(FilterGeneratorContext);
    const { isEditable } = useContext(CustomTreeSelectContext);

    const mergedSelection = useMemo(() => {
        return mergeSelected(flattenData, treeKey, parentKey, selected);
    }, [treeKey, parentKey, flattenData, selected]);

    const allOptions = useMemo(() => {
        return findAllOptions(options);
    }, [options]);

    const handleChange = e => {
        if (isEditable !== 'Y') {
            return;
        }
        const { checked } = e.currentTarget;
        setValue(checked ? allOptions : []);
    };

    // // 초기 체크될 값
    // const [checked, setChecked] = useState(selected ? makeCheckedDescendantsNode(selected.map(v => v[valueKey])) : []);
    const [checked, setChecked] = useState(
        selected.map(v => {
            if (typeof v === 'object') {
                return v[valueKey];
            }
            return v;
        }),
    );

    const handleCheck = useCallback(checked => {
        setChecked(checked);
    }, []);

    useEffect(() => {
        setChecked(
            selected.map(v => {
                if (typeof v === 'object') {
                    return v[valueKey];
                }
                return v;
            }),
        );
    }, [selected.length]);

    useEffect(() => {
        const checkedToStr = checked.map(v => v.toString());
        setValue(flattenData.filter(v => checkedToStr.includes(v[valueKey].toString())).map(v => v[valueKey]));
    }, [checked, flattenData, valueKey]);

    return (
        <components.MenuList className="select__options" {...restProps} setValue={setValue}>
            <div
                className={cx(
                    'flx-row tl p-1 border-bottom bg-depth-6',
                    isEditable !== 'Y' && !filterEditMode && 'disabled-select-option',
                )}
            >
                <label className={cx('pnt-checkbox form-h-small')} htmlFor={'Selected'}>
                    <input
                        id={'Selected'}
                        type="checkbox"
                        onChange={() => setValue([], 'deselect-option')}
                        checked={selected.length === allOptions.length}
                    />
                    <span className="checkbox-effect" />
                </label>
                <p className="pnt-txt txt-bold color-trans-black">{t('Selected')}</p>
            </div>
            <div className={'select__options-list'}>
                {mergedSelection.length ? (
                    mergedSelection.map((v, index) => (
                        <div
                            key={`${v[valueKey]}_${index}`}
                            className={cx(
                                'styled-option select__option react-select__option',
                                isEditable !== 'Y' && 'disabled-select-option',
                            )}
                            onClick={() => {
                                if (isEditable !== 'Y') {
                                    return;
                                }
                                const filtered = mergedSelection.filter(
                                    selectedOption => selectedOption[valueKey] !== v[valueKey],
                                );
                                // setValue(filtered);
                                setChecked(
                                    findAllChildren(flattenData, treeKey, parentKey, filtered).map(
                                        selectedOption => selectedOption[valueKey],
                                    ),
                                );
                            }}
                        >
                            <label className="pnt-checkbox form-h-small check-checked" htmlFor={v[labelKey]}>
                                <input
                                    id={v[labelKey]}
                                    type="checkbox"
                                    style={{ cursor: 'pointer' }}
                                    value={v[valueKey]}
                                    checked
                                    onChange={() => null}
                                />
                                <span className="checkbox-effect" />
                            </label>
                            <span className="overflow-hidden text-ellipsis">{v[labelKey]}</span>
                        </div>
                    ))
                ) : (
                    <div className={'styled-option-label'}>{t('Not Selected')}</div>
                )}
            </div>
            <div className="pnt-divider horizon-line border-gray opacity-6 m-0" />
            <div
                className={cx(
                    'flx-row tl p-1 border-bottom bg-depth-5',
                    isEditable !== 'Y' && !filterEditMode && 'disabled-select-option',
                )}
            >
                <label className={cx('pnt-checkbox form-h-small')} htmlFor={'All'} title={t('All Items', 'Select')}>
                    <input
                        id={'All'}
                        type="checkbox"
                        onChange={handleChange}
                        checked={selected.length === allOptions.length}
                    />
                    <span className="checkbox-effect" />
                </label>
                <p className="pnt-txt txt-bold color-trans-black">{t('All')}</p>
            </div>
            {!selected.length && !options.length ? (
                <div className={'styled-option-label'}>{t('No matches found')}</div>
            ) : (
                <div className={cx(isEditable !== 'Y' && 'disabled-tree-option')}>
                    <Tree data={options} checked={checked} setChecked={handleCheck} />
                </div>
            )}
            <div className="pnt-divider horizon-line border-gray opacity-6 m-0" />
            <div className={cx(!filterEditMode && 'disabled-option')}>
                <div className={cx('flx-row tl p-1 border-bottom bg-depth-6', !filterEditMode && 'disabled-option')}>
                    <p className="pnt-txt txt-bold color-trans-black">{filterT('Option')}</p>
                </div>
                <div className={'styled-option flx-row'}>
                    <label
                        className={cx('pnt-checkbox form-h-small gap-1')}
                        htmlFor={'Permission'}
                        title={filterT('Permission to change')}
                    >
                        <input
                            id={'Permission'}
                            type="checkbox"
                            onChange={e => dispatch(setEditable(e.currentTarget.checked ? 'Y' : 'N'))}
                            checked={isEditable === 'Y'}
                        />
                        <span className="checkbox-effect" />
                        <span className="pnt-txt">{filterT('Permission to change')}</span>
                    </label>
                </div>
            </div>
        </components.MenuList>
    );
};
