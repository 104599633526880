import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    selectedOutpatientBasicTestStatus: null,
    keyword: '',
    outpatientBasicTestList: {
        totalCount: 0,
        totalPage: 1,
        rows: [],
    },
    outpatientBasicTestListParam: {
        pageSize: 15,
        page: 1,
        appointmentOption: null,
        keyword: undefined,
    },
    outpatientBasicTestCount: {
        total: 0,
        inspectCountStat: {
            doneInspect: 0,
            nonDoneInspect: 0,
        },
        safetyCountStat: {
            safePatient: 0,
            dangerPatient: 0,
        },
    },
};

const OutpatientBaselineTestingSlice = createSlice({
    name: 'outpatientBasicTestStatus',
    initialState,
    reducers: {
        setSelectedOutpatientStatus: (state, action) => {
            const selectedStatus = action.payload;
            if (state.selectedOutpatientBasicTestStatus === selectedStatus) {
                return;
            }
            state.selectedOutpatientBasicTestStatus = selectedStatus;
            state.outpatientBasicTestListParam = {
                ...state.outpatientBasicTestListParam,
                appointmentOption: state.selectedOutpatientBasicTestStatus,
            };
        },
        setSearchOutpatient: (state, action) => {
            const keyword = action.payload ? action.payload : undefined;
            state.keyword = keyword;
            state.outpatientBasicTestListParam = {
                ...state.outpatientBasicTestListParam,
                keyword: state.keyword,
            };
        },
        setOutpatientListParam: (state, action) => {
            state.outpatientBasicTestListParam = action.payload;
        },
        clearOutpatientCare: (state, action) => {
            state.keyword = '';
            state.selectedOutpatientCareStatus = null;
            state.outpatientBasicTestListParam = {
                ...state.outpatientBasicTestListParam,
                keyword: undefined,
                appointmentOption: null,
            };
        },
    },
});
export const {
    setSearchOutpatient,
    setSelectedOutpatientStatus,
    setOutpatientListParam,
    clearOutpatientCare,
} = OutpatientBaselineTestingSlice.actions;

export default OutpatientBaselineTestingSlice;
