import { reqGet, reqPatch, reqPost, reqPut } from '../index';

// 자산 관리 대시보드 조회
export const getAMDashboardApi = param => reqGet('/v3/api/am01/dashboard', param);

/**
 * 일상 점검 목록
 * @param {string} param.ward 부서/병동(userGroupNum)
 * @param {'HIGH'| 'NORMAL'} [param.checkType] 점검구분(고위험:HIGH, 일반:NORMAL)
 */
export const getDailyInspectionListApi = param => reqPost('/v3/api/am01/target/daily/check', param);

/**
 * 일상 점검 (달력) 수량점검 여부 판단
 * @param {number} param.startDate 조회시작일(당일 00시 00분 00초)
 * @param {number} param.endDate 조회종료일(당일 23시 59분 59초)
 * @param {string} param.ward 부서/병동(userGroupNum)
 */
export const getDailyInspectionCalendarApi = param => reqPost('/v3/api/am01/target/daily/check/calendar', param);

/**
 * 일상 점검 고위험 상세 조회
 * @param {number} targetNum 대상 번호
 */
export const getDailyInspectionDetailApi = ({ dailyCheckNum, ...params }) =>
    reqGet(`/v3/api/am01/daily-check-targets/${dailyCheckNum}`, params);

/**
 * 고위험 일상 점검 메모 등록
 * @param {number} [param.dailyCheckNum] 대상 번호
 * @param {string} [param.memo] 메모
 */
export const setDailyHighRiskInspectionMemoApi = param => reqPost('/v3/api/am01/daily-check-targets/memo', param);

/**
 * 일상 점검 등록
 * @param {[{ward: string, checkResult: string, [memo]: string}]} params
 * @param {number}[] ward 부서/병동(userGroupNum)
 * @param {'good'| 'bad'}[] checkResult 점검 결과 (양호: good, 불량: bad)
 * @param {string}[] memo 메모
 */
export const setDailyInspectionApi = params => reqPost('/v3/api/am01/daily-check-targets', params);

/**
 * 일상 점검 이력 조회
 * @param {string} param.ward 부서/병동(userGroupNum)
 * @param {string} [param.categoryCode] 카테고리코드
 * @param {'HIGH'| 'NORMAL'} [param.checkType] 점검구분(고위험:HIGH, 일반:NORMAL)
 * @param {'HIGH'|'MIDDLE'|'LOW'} [param.riskLevel] 위험도(HIGH,MIDDLE,LOW)
 * @param {string} [param.targetId] 대상 ID
 * @param {string} [param.targetName] 대상 이름
 * @param {number} param.startCheckDate 조회 시작 일시
 * @param {number} param.endCheckDate 조회 종료 일시
 * @param {number} [param.page] 목록조회 페이지 번호
 * @param {number} [param.pageSize] 목록조회 페이지별 Row 수
 */
export const getDailyInspectionLogListApi = param => reqGet('/v3/api/am01/daily-check-target-logs', param);

// 일상 점검 이력 조회 엑셀 다운로드
export const postDailyHistoryCheckApi = ({ dailyCheckAssetLogSearchParam, excelDownloadRequest, ...restParam }) =>
    reqPost(
        `/v3/api/am01/daily-check-target-logs/export`,
        {
            dailyCheckAssetLogSearchParam,
            excelDownloadRequest,
        },
        restParam,
    );

// 일상 점검 이력 조회 출력 테스트 다운로드
export const postDailyHistoryOutputTestApi = ({
    startDate,
    endDate,
    searchRequests,
    excelDownloadRequest,
    ...restParam
}) =>
    reqPost(
        '/v3/api/am01/daily-check-target-logs/export/img',
        {
            startDate,
            endDate,
            searchRequests,
            excelDownloadRequest,
        },
        restParam,
    );

/**
 * 수량 점검 목록 조회
 * @param {number} param.ward 부서/병동(userGroupNum)
 */
export const getQuantityCheckListApi = param => reqPost('/v3/api/am01/count-check-targets', param);

/**
 * 수량 점검 등록
 */
export const setQuantityCheckListApi = params => reqPut('/v3/api/am01/count-check-targets', params);

/**
 * 수량 점검 이력 조회
 * @param {string} param.ward 부서/병동(userGroupNum)
 * @param {string} [param.categoryCode] 카테고리코드
 * @param {string} [param.targetName] 대상 이름
 * @param {number} param.startCheckDate 조회 시작 일시
 * @param {number} param.endCheckDate 조회 종료 일시
 * @param {number} [param.page] 목록조회 페이지 번호
 * @param {number} [param.pageSize] 목록조회 페이지별 Row 수
 */
export const getQuantityCheckLogListApi = param => reqGet('/v3/api/am01/count-check-target-logs', param);

/**
 * 수량 점검 (달력) 수량점검 여부 판단
 * @param {number} param.startDate 조회시작일(당일 00시 00분 00초)
 * @param {number} param.endDate 조회종료일(당일 23시 59분 59초)
 * @param {string} param.ward 부서/병동(userGroupNum)
 */
export const getQuantityCheckCalendarApi = param => reqPost('/v3/api/am01/count-check-targets/calendar', param);

// 수량 점검 이력 조회 엑셀 다운로드
export const postQuantityCheckHistoryExcelApi = ({
    countCheckAssetLogSearchParam,
    excelDownloadRequest,
    ...restParam
}) =>
    reqPost(
        `/v3/api/am01/count-check-target-logs/export`,
        {
            countCheckAssetLogSearchParam,
            excelDownloadRequest,
        },
        restParam,
    );

// 출력 테스트 이미지 조회
export const getPrintTestImage = ({ dailyCheckNum, ...param }) =>
    reqGet(`/v3/api/am01/daily-check-targets/${dailyCheckNum}/print-test-image`, param);

// 대여받은 자산 목록 조회
export const getBorrowingAssetList = param => reqPost('/v3/api/common/rented-assets/list', param);

// 대여받은자산 대여 취소
export const deleteRentalCancel = param => reqPost('/v3/api/common/rented-assets/cancel', param);

// 대여받은자산 대여 신청
export const postAssetRental = param => reqPost('/v3/api/am01/rental-apply', param);

// 대여받은자산 소유부서 조회
export const getOwnerList = param => reqGet('/v3/api/am01/available-list', param);

// 대여 여부 설정 조회
export const getRentalAvailabilityList = param => reqGet('/v3/api/am01/asset/reg-rental-max-count', param);

// 대여 여부 설정 저장
export const postRentalAvailabilityList = param => reqPost('/v3/api/am01/asset/rental-max-count', param);

// 대여해준자산 목록 조회
export const getLendingAssetList = param => reqPost('/v3/api/common/lend-assets/list', param);

// 대여이력 목록 조회
export const getRentalHistoryList = param => reqPost('/v3/api/common/rented-assets/log/list', param);

// 대여이력 상세 조회
export const getRentalHistoryDetail = param => reqGet('/v3/api/common/rented-assets/log', param);

// 대여이력 엑셀 다운로드
export const postRentalHistoryExcel = ({ am01AssetRentalLogRequest, excelDownloadRequest, ...restParam }) =>
    reqPost(
        '/v3/api/common/rented-assets/log/export',
        {
            am01AssetRentalLogRequest,
            excelDownloadRequest,
        },
        restParam,
    );

// 인계승인
export const patchHandOverApprove = ({ applyNum, ...restParam }) =>
    reqPatch(`/v3/api/common/lend-assets/approve/${applyNum}`, restParam);

// 대여거부
export const postRentalReject = param => reqPost(`/v3/api/common/lend-assets/refusal`, param);

// 인수자반납자 조회
export const getUserByID = ({ userId, ...restParam }) => reqGet(`/v3/api/common/user/info/${userId}`, restParam);

// 대여자산 상세
export const getRentalDetail = ({ applyNum, ...restParam }) =>
    reqGet(`/v3/api/common/lend-assets/${applyNum}`, restParam);

// 대여 인계 처리
export const postRentalCheckOut = param => reqPost('/v3/api/common/lend-assets/check-out', param);

// 대여 반납 처리
export const postRentalReturn = param => reqPost('/v3/api/common/lend-assets/return', param);
