import React from 'react';
import { Chart as ChartJs, LinearScale, CategoryScale } from 'chart.js';
import Chart from 'react-chartjs-2';
import { BoxAndWiskers, BoxPlotController } from '@sgratzl/chartjs-chart-boxplot';
import { PntChart } from '@components/Charts/types';
import { mergeObjects } from '@util/common/util';
import useChartOptions from '@components/Charts/util/useChartOptions';
import useChartData from '@components/Charts/util/useChartData';

ChartJs.register(BoxPlotController, BoxAndWiskers, LinearScale, CategoryScale);

/*
 * datasets :{
 *   labels : "",
 *   datasets: [{
 *          data : [
 *               //min Whisker,
 *               //Lower Quartile
 *               //Median
 *               //Inter Quartile Range (IQR)
 *               //Mean
 *               // Upper Quartile
 *               //max Whisker
 *          ]
 *   }]
 * }
 * */

const BoxPlotChart = ({ data, options, plugins }: PntChart<'boxplot'>) => {
    const chartData = useChartData<'boxplot'>(data);
    const chartOption = useChartOptions<'boxplot'>(options);
    return <Chart type={'boxplot'} data={chartData} options={mergeObjects(chartOption, options)} plugins={plugins} />;
};

export default BoxPlotChart;
