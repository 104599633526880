import React, { useEffect, useState } from 'react';
import Label from '@components/Label';
import { getEnvironmentalSensorDetailInfoApi } from '@api/monitoring';
import useAsync from '@hooks/useAsync';
import MainView from './MainView';
import moment from 'moment';
import useTranslation from '@hooks/useTranslation';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DEFAULT_VALUE, DUMMY_DETAIL_INFO } from '../../constants';
import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';
import { useAppSelector } from '@hooks';
import useCategoryProperties from '../../../../util/useCategoryProperties';
import { CATEGORY_CODE } from '@util/mappInfo';
import { convertProperties } from '../../../../util/commons';

const ViewDetail = ({ targetId }) => {
    const t = useTranslation('RealTimeEnvironmentalSensor');
    const categoryProperties = useCategoryProperties({ categoryCode: CATEGORY_CODE.SENSOR });
    const { mode } = useAppSelector(state => state.ScreenInfo);
    const [info, setInfo] = useState({});
    const { sensorName, ward, room, floor, lastDate } = info;

    const { promise: getEnvironmentalSensorDetailInfo } = useAsync({
        promise: getEnvironmentalSensorDetailInfoApi,
        resolve: res => {
            setInfo(res);
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        if (mode === SCREEN_MODE_EDIT || mode === SCREEN_MODE_PREVIEW) {
            setInfo(DUMMY_DETAIL_INFO);
            return;
        }
        getEnvironmentalSensorDetailInfo({ targetId });
    }, []);

    const convertProps = convertProperties(
        {
            ward,
            room,
        },
        categoryProperties,
    );

    return (
        <div className="d-flex h-100">
            <PerfectScrollbar className="w-25 p-3 border border-depth-7">
                <Label
                    name={t('Ward/Department')}
                    labelGroupClassName="mb-2"
                    value={convertProps?.ward || DEFAULT_VALUE}
                />
                <Label name={t('Room')} labelGroupClassName="mb-2" value={room || DEFAULT_VALUE} />

                <div className="pnt-divider horizon-line"></div>

                <Label name={t('Floor')} labelGroupClassName="mb-2" value={floor || DEFAULT_VALUE} />
                <Label name={t('Sensor Name')} labelGroupClassName="mb-2" value={sensorName || DEFAULT_VALUE} />
                <Label
                    name={t('Recognition Time')}
                    value={lastDate ? moment.unix(lastDate).format('YYYY-MM-DD HH:mm:ss') : DEFAULT_VALUE}
                />
            </PerfectScrollbar>
            <PerfectScrollbar className={'w-75 ml-3 p-3 border border-depth-7'}>
                <MainView targetId={targetId} />
            </PerfectScrollbar>
        </div>
    );
};

export default ViewDetail;
