import React from 'react';

import WidgetCard from '../../Components/WidgetCard';
import { Table } from '@components';

import ResponsivePieChart from './Components/ResponsivePieChart';

import useMonitor from '../../util/useMonitor';
import { useTranslation, useColumns } from '@hooks';
import { fetchIotItemCount } from '@api/common/asset';

import * as column from '../../../MainPages/util/grid/column';

const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min) + min + 1);

const DEFAULT_DATA = {
    rows: [
        {
            categoryCode: 'child1',
            categoryName: 'Example1',
            categoryCodePath: 'Example1>child1',
            targetCnt: getRandomInt(18, 20),
        },
        {
            categoryCode: 'Example2',
            categoryName: 'Example2',
            categoryCodePath: 'Example2',
            targetCnt: getRandomInt(16, 17),
        },
        {
            categoryCode: 'child3',
            categoryName: 'Example3',
            categoryCodePath: 'Example3>child3',
            targetCnt: getRandomInt(13, 15),
        },
        {
            categoryCode: 'child4',
            categoryName: 'Example4',
            categoryCodePath: 'Example4>child4',
            targetCnt: getRandomInt(7, 8),
        },
        {
            categoryCode: 'Example5',
            categoryName: 'Example5',
            categoryCodePath: 'Example5',
            targetCnt: getRandomInt(4, 5),
        },
    ],
};

const TABLE_HEADER_HEIGHT = 32;
const TABLE_ROW_HEIGHT = 36.4;

const ItemDetailStatus = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('ItemDetailStatus');
    const { config } = widgetInfo;

    const { data } = useMonitor({
        config,
        paramInfo: ['categoryCode'],
        defaultData: DEFAULT_DATA,
        fetchData: fetchIotItemCount,
        makeData: ({ newData }) => {
            const iotItemList = newData.rows || [];

            return iotItemList.slice(0, 5).map(({ categoryCode, categoryName, targetCnt }, index) => ({
                ranking: index + 1,
                key: categoryCode,
                name: categoryName,
                y: targetCnt,
            }));
        },
    });

    const columns = useColumns(
        [
            column.ranking(),
            column.name({ Cell: ({ value }) => value ?? '-' }),
            column.count({
                accessor: 'y',
                Cell: ({ value }) => value ?? '-',
            }),
        ],
        t,
    );

    const tableHeight = !data.length ? '15rem' : `${TABLE_HEADER_HEIGHT + TABLE_ROW_HEIGHT * data.length}px`;

    return (
        <WidgetCard widgetInfo={widgetInfo} {...restProps}>
            <div className={'w-100 pb-3'} style={{ height: `calc(100% - ${tableHeight})` }}>
                <ResponsivePieChart data={data} />
            </div>
            <div className={'w-100'} style={{ height: tableHeight }}>
                <Table columns={columns} data={{ rows: data }} paging={false} className={'table-for-viewer'} />
            </div>
            {children}
        </WidgetCard>
    );
};

export default ItemDetailStatus;
