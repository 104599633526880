import { ChartOptions, ChartType } from 'chart.js';
import { useMemo } from 'react';
import { mergeObjects } from '@util/common/util';
import { useAppSelector } from '@hooks';

// defaults.set 사용 시 tooltip, scales의 grid, tick 등 일부 옵션이 적용 안되는 오류로 상수로 사용
const DEFAULT_CHART_OPTIONS: ChartOptions = {
    maintainAspectRatio: false,
    animation: {
        duration: 200,
    },
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
            align: 'center',
            labels: {
                boxWidth: 14,
                boxHeight: 14,
                font: {
                    family: 'pretendard',
                    weight: '600',
                    size: 14,
                },
            },
        },
        tooltip: {
            padding: 8,
            titleFont: {
                family: 'pretendard',
                size: 14,
                weight: '400',
            },
            bodyFont: {
                family: 'pretendard',
                size: 14,
                weight: '400',
            },
        },
    },
    datasets: {
        pie: { borderWidth: 1 },
        doughnut: { borderWidth: 0 },
        bar: { borderWidth: 1, borderColor: 'white' },
    },
    scales: {
        x: {
            ticks: { font: { family: 'pretendard', size: 14 } },
        },
        y: {
            ticks: { font: { family: 'pretendard', size: 14 } },
        },
    },
};

const themeOptions = (colorScheme: string): ChartOptions => ({
    plugins: {
        legend: { labels: { color: colorScheme === 'pnt-dark' ? 'white' : 'black' } },
    },
    scales: {
        x: {
            grid: {
                color: colorScheme === 'pnt-dark' ? '#3c3c43' : '#f3f2f6',
                borderColor: colorScheme === 'pnt-dark' ? '#f3f2f6' : '#3c3c43',
            },
            ticks: {
                color: colorScheme === 'pnt-dark' ? 'white' : 'black',
            },
        },
        y: {
            grid: {
                color: colorScheme === 'pnt-dark' ? '#3c3c43' : '#f3f2f6',
                borderColor: colorScheme === 'pnt-dark' ? '#f3f2f6' : '#3c3c43',
            },
            ticks: { color: colorScheme === 'pnt-dark' ? 'white' : 'black' },
        },
    },
});

const useChartOptions = <TType extends ChartType>(options?: ChartOptions<TType>) => {
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);
    return useMemo<ChartOptions<TType>>(() => {
        let additionalOptions = mergeObjects(DEFAULT_CHART_OPTIONS, themeOptions(colorScheme));

        return mergeObjects(additionalOptions, options);
    }, [options, colorScheme]);
};
export default useChartOptions;
