import React from 'react';
import VitalSignsBedList from './VitalSignsBedList';
import { getSingleValueAsc } from '../util';
import cx from 'classnames';

/**
 * 병실 리스트 컴포넌트
 *
 * @param wardNum 부서/병동
 * @param roomList 병실 목록
 * @param handleRefreshData 환자별 활력징후 데이터 새로고침 handler
 * */
const VitalSignsRoomList = ({ wardNum, roomList, handleRefreshData }) => {
    const roomKeys = getSingleValueAsc(Object.entries(roomList));
    return (
        <div className="flx-col gap-3">
            {roomKeys.map(([roomNum], idx) => (
                <div key={`${wardNum}_${roomNum}_${idx}`}>
                    <div className={cx('p-1')}>
                        <span className={'pnt-txt txt-bold s-7'}>
                            {wardNum} {roomNum}
                        </span>
                    </div>
                    <VitalSignsBedList bedList={roomList[roomNum]} handleRefreshData={handleRefreshData} />
                </div>
            ))}
        </div>
    );
};

export default VitalSignsRoomList;
