import React from 'react';
import styled from 'styled-components';
import OutpatientTotalCounts from './Counts/OutpatientTotalCounts';
import OutpatientTodayCounts from './Counts/OutpatietTodayCounts';
import OutpatientReceptionCounts from './Counts/OutpatietReceptionCounts';

const StatusCount = ({ outpatientCount }) => {
    const { total, todayReceptionCountStat, receptionCountStat } = outpatientCount;
    return (
        <Container>
            <OutpatientTotalCounts total={total} />
            <OutpatientTodayCounts todayReceptionCountStat={todayReceptionCountStat} />
            <OutpatientReceptionCounts receptionCountStat={receptionCountStat} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    width: 100%;
    & > div {
        &:nth-child(1) {
            flex: 1;
        }
        &:nth-child(2) {
            flex: 1;
        }
        flex: 2;
    }
    margin-bottom: 5px;
`;
export default StatusCount;
