import React from 'react';
import { NumberInputProps, TextInputProps } from '../type';
import cx from 'classnames';

export const TextInput = ({
    type = 'number',
    size,
    name,
    id,
    value,
    defaultValue,
    handleChange,
    style,
    disabled = false,
    readonly,
    placeholder,
    buttonIcon,
    buttonIconClassName = 'material-icons-round',
    className,
    buttonClassName,
    min,
    max,
    required,
    buttonHandleClick,
    handleKeyUp,
    handleKeyDown,
    inputGroupClassName = '',
    errorMsg,
    autoFocus = false,
}: NumberInputProps): JSX.Element => {
    return (
        <div
            className={cx(
                'pnt-input--group',
                inputGroupClassName,
                disabled && 'form-disable',
                buttonIcon && 'btn-on-right',
            )}
        >
            <input
                type={type}
                size={size}
                name={name}
                id={id}
                value={value}
                defaultValue={defaultValue}
                min={min}
                max={max}
                onChange={handleChange}
                className={cx('pnt-input', className)}
                placeholder={placeholder}
                disabled={disabled}
                required={required}
                style={style}
                onKeyUp={handleKeyUp}
                onKeyDown={handleKeyDown}
                autoFocus={autoFocus}
                readOnly={readonly}
            />
            {buttonIcon && (
                <button
                    className={`pnt-btn btn-icon-only btn-trans ${buttonClassName}`}
                    tabIndex={-1}
                    onClick={buttonHandleClick}
                >
                    <span className={buttonIconClassName}>{buttonIcon}</span>
                </button>
            )}
            <p className="input-error-txt">{errorMsg}</p>
        </div>
    );
};

export default TextInput;
