import { createSlice } from '@reduxjs/toolkit';

const IV_INPUT_KEY = {
    INJECTION_VALUE: 'injectionVal',
    MIN_LIMIT_SPEED: 'minLimitSpeed',
    MAX_LIMIT_SPEED: 'maxLimitSpeed',
};
// 선택 수액 대상정보 초기값
const initialIVInjectionState = {
    targetInfo: {},
    IVInjection: {},
    sensorItems: [],
};

export const initialState = {
    IVInjectionList: {},
    searchInput: {
        ward: '',
    },
    currentIVInjectionNum: null, // 선택 수액 고유번호
    currentIVInjection: initialIVInjectionState, // 선택 수액 대상정보
    remainingValueDisplay: false, // 잔량 표시
};

const IVInjectionMonitoringSlice = createSlice({
    name: 'IVInjectionMonitoring',
    initialState,
    reducers: {
        setIVInjectionList: (state, action) => {
            state.IVInjectionList = action.payload;
        },
        updateIVInjectionList: (state, action) => {
            const IVInjectionSensor = action.payload;
            if (!IVInjectionSensor) {
                return;
            }
            try {
                const {
                    comNum,
                    ivInjection: IVInjection,
                    sensor,
                    target,
                    targetCategory,
                    targetState,
                    sensingState,
                    sensingValues,
                    unixTime,
                } = IVInjectionSensor;
                const { sensorNum: socketSensorNum, sensorId, sensorName } = sensor;

                const {
                    ivInjectionNum,
                    currentVal,
                    totalVal,
                    modDate,
                    maxLimitSpeed,
                    minLimitSpeed,
                    injectionVal,
                } = IVInjection;
                const {
                    BATTERY: batteryStatus,
                    CAPACITY: capacityStatus,
                    DROP_COUNT: dropCountStatus,
                    DROP_RATE: dropRateStatus,
                } = sensingState;
                const {
                    BATTERY: batteryValue,
                    CAPACITY: capacityValue,
                    DROP_COUNT: dropCountValue,
                    DROP_RATE: dropRateValue,
                } = sensingValues;
                const { lostSignal } = targetState;

                const socketSensorValues = {
                    BATTERY: {
                        value: batteryValue,
                        status: batteryStatus,
                    },
                    CAPACITY: {
                        value: capacityValue,
                        status: capacityStatus,
                    },
                    DROP_COUNT: {
                        value: dropCountValue,
                        status: dropCountStatus,
                    },
                    DROP_RATE: {
                        value: dropRateValue,
                        status: dropRateStatus,
                    },
                };

                state.IVInjectionList = (function findArrayInObject(entryValues) {
                    return Object.fromEntries(
                        Object.keys(entryValues).map(key => {
                            // 배열까지 재귀
                            if (!Array.isArray(entryValues[key])) {
                                return [key, findArrayInObject(entryValues[key])];
                            }
                            // update 대상의 수액 정보
                            const { targetInfo, targetInjectionStateList } = entryValues[key][0];
                            // 수액 리스트
                            const updateTargetInjectionStateList = targetInjectionStateList.map(
                                ({ ivInjection: prevIVInjection, sensor }) => {
                                    // 수액 센서 정보
                                    const { deviceNum, sensorItems } = sensor;
                                    // 센서 key 비교
                                    if (socketSensorNum !== deviceNum) {
                                        return { ivInjection: prevIVInjection, sensor };
                                    }
                                    // 현재 주입량 반올림
                                    const roundCurrentValue = currentVal && Math.round(currentVal);
                                    // IV 정보 수정
                                    const updateIVInjection = {
                                        ...prevIVInjection,
                                        ivInjectionNum,
                                        currentVal: roundCurrentValue,
                                        totalVal,
                                        modDate,
                                        maxLimitSpeed,
                                        minLimitSpeed,
                                        injectionVal,
                                        lostSignalState: lostSignal,
                                    };
                                    // 센서 type 별 값 수정
                                    const updateSensorItems = sensorItems.map(
                                        ({ sensingType, currentValue, status, ...otherSensorItemProps }) => {
                                            // 센서 type 없을 경우
                                            if (!socketSensorValues[sensingType]) {
                                                return {
                                                    sensingType,
                                                    currentValue,
                                                    status,
                                                    ...otherSensorItemProps,
                                                };
                                            }
                                            return {
                                                sensingType,
                                                currentValue: socketSensorValues[sensingType].value,
                                                status: socketSensorValues[sensingType].status,
                                                ...otherSensorItemProps,
                                            };
                                        },
                                    );
                                    // 센서 아이템 update
                                    const updateSensor = {
                                        ...sensor,
                                        sensorItems: updateSensorItems,
                                    };
                                    return { ivInjection: updateIVInjection, sensor: updateSensor };
                                },
                            );

                            return [key, [{ targetInfo, targetInjectionStateList: updateTargetInjectionStateList }]];
                        }),
                    );
                })(state.IVInjectionList);
            } catch (e) {
                console.error('updateIVInjectionList', e);
            }
        },
        changeCurrentIVInjection: (state, action) => {
            if (!action.payload) {
                state.currentIVInjection = initialIVInjectionState;
                return;
            }
            const IVInjection = action.payload;
            const { injectionVal, maxLimitSpeed, minLimitSpeed } = IVInjection;
            // 최대 제한 속도(버림)
            const initMaxLimitSpeed =
                maxLimitSpeed <= 0 && injectionVal > 0 ? Math.floor(injectionVal + injectionVal / 2) : maxLimitSpeed;
            // 최소 제한 속도(올림)
            const initMinLimitSpeed =
                minLimitSpeed <= 0 && injectionVal > 0 ? Math.ceil(injectionVal - injectionVal / 2) : minLimitSpeed;

            state.currentIVInjection = {
                ...state.currentIVInjection,
                IVInjection: {
                    ...IVInjection,
                    maxLimitSpeed: initMaxLimitSpeed,
                    minLimitSpeed: initMinLimitSpeed,
                },
            };
        },
        selectCurrentIVInjection: (state, action) => {
            if (!action.payload) {
                state.currentIVInjectionNum = null;
                state.currentIVInjection = initialIVInjectionState;
                return;
            }

            state.currentIVInjectionNum = action.payload.IVInjectionNum;

            const targetInfo = action.payload.targetInfo || {};
            const IVInjection = action.payload.IVInjection || {};
            const sensorItems = action.payload.sensorItems || [];
            state.currentIVInjection = {
                targetInfo,
                IVInjection,
                sensorItems,
            };
        },
        changeCurrentIVInjectionValue: (state, action) => {
            if (!action.payload) {
                return;
            }
            // action state
            const IVInjectionInput = action.payload;
            // ivInjection state
            const currentIVInjection = state.currentIVInjection.IVInjection;
            // 존재하는 항목인지 확인
            const checkIVInjectionKey = Object.keys(IVInjectionInput).some(value =>
                Object.keys(currentIVInjection).includes(value),
            );
            if (!checkIVInjectionKey) {
                return;
            }

            // 오더량(injectionVal) 입력의 경우
            const checkInjectionInput = Object.keys(IVInjectionInput).includes(IV_INPUT_KEY.INJECTION_VALUE);
            if (checkInjectionInput) {
                const IVInjectionInputValue = Number(IVInjectionInput[IV_INPUT_KEY.INJECTION_VALUE]);
                // 오더량이 수액량을 넘지 않도록 설정
                const confirmIVInjectionValue =
                    currentIVInjection.totalVal < IVInjectionInputValue
                        ? currentIVInjection.totalVal
                        : IVInjectionInputValue;
                // 오더량
                IVInjectionInput[IV_INPUT_KEY.INJECTION_VALUE] = Number(confirmIVInjectionValue);
                // 최대 제한 속도(버림)
                IVInjectionInput[IV_INPUT_KEY.MAX_LIMIT_SPEED] =
                    confirmIVInjectionValue > 0 ? Math.floor(confirmIVInjectionValue + confirmIVInjectionValue / 2) : 0;
                // 최소 제한 속도(올림)
                IVInjectionInput[IV_INPUT_KEY.MIN_LIMIT_SPEED] =
                    confirmIVInjectionValue > 0 ? Math.ceil(confirmIVInjectionValue - confirmIVInjectionValue / 2) : 0;
            }

            state.currentIVInjection = {
                ...state.currentIVInjection,
                IVInjection: {
                    ...currentIVInjection,
                    ...IVInjectionInput,
                },
            };
        },
        resetCurrentIVInjectionNum: state => {
            state.currentIVInjectionNum = null;
        },
        toggleRemainingValueDisplay: state => {
            state.remainingValueDisplay = !state.remainingValueDisplay;
        },
    },
});

export const {
    setIVInjectionList,
    updateIVInjectionList,
    changeCurrentIVInjection,
    selectCurrentIVInjection,
    resetCurrentIVInjectionNum,
    toggleRemainingValueDisplay,
    changeCurrentIVInjectionValue,
} = IVInjectionMonitoringSlice.actions;

export default IVInjectionMonitoringSlice;
