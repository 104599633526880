import React, { useRef } from 'react';
import { useAsync } from '@hooks';
import { getReadingStatusApi } from '@api/sh/waste';
import WidgetCard from '../../Components/WidgetCard';
import Header from './Header';
import Main from './Main';
import useRangeDate from '../util/useRangeDate';
import useOptionsByRole from '../util/useOptionsByRole';

const MedicalWasteReadingStatus = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();
    const { options, selectedWard, setSelectedWard } = useOptionsByRole();
    const { startDate, handleStartChange, endDate, handleEndChange } = useRangeDate();

    const { state, promise: getReadingStatus } = useAsync({
        promise: getReadingStatusApi,
        immediate: true,
        param: { ward: selectedWard.value, startDate, endDate },
        reject: err => console.error(err),
    });
    const rows = state.response ? state.response.rows : [];

    return (
        <WidgetCard ref={widgetRef} widgetInfo={widgetInfo} {...restProps}>
            <Header
                options={options}
                selectedWard={selectedWard}
                setSelectedWard={setSelectedWard}
                startDate={startDate}
                handleStartChange={handleStartChange}
                endDate={endDate}
                handleEndChange={handleEndChange}
                getReadingStatus={getReadingStatus}
            />
            <Main rows={rows} />
            {children}
        </WidgetCard>
    );
};

export default MedicalWasteReadingStatus;
