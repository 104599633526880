import React, { useEffect, useState } from 'react';
import NoDataBlock from '../../../Components/NoDataBlock';
import BarChart from '@components/Charts/Components/BarChart';

const FloorPermissionChart = ({ data, chartMeta }) => {
    const [chartData, setChartData] = useState({});

    useEffect(() => {
        if (chartMeta.dataSet) {
            setChartData({
                labels: data.map(floorInfo => floorInfo.name),
                datasets: chartMeta.dataSet?.map(({ name, key }, i) => {
                    return {
                        label: name,
                        data: data.map(floorInfo => floorInfo[key]),
                    };
                }, []),
            });
        }
    }, [data, chartMeta]);

    return !!data && data.length ? (
        <BarChart data={chartData} options={{ scales: { y: { ticks: { stepSize: 1 } } } }} type={'multiBar'} />
    ) : (
        <NoDataBlock />
    );
};

export default FloorPermissionChart;
