import { Button, Modal } from '@components';
import { useTranslation } from '@hooks';
import React from 'react';
import { ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import { ASSET_MANAGEMENT } from '../../constants';

const NormalModal = ({ isOpen, toggleModal, rowInfo }) => {
    const t = useTranslation(ASSET_MANAGEMENT);

    return (
        <Modal
            initModal={isOpen}
            toggleModal={toggleModal}
            headerTitle={t('Normal Inspection Memo')}
            modalFooter={
                <ModalFooter>
                    <Button className={'btn-gray'} onClick={toggleModal}>
                        {t('Close', 'Button')}
                    </Button>
                </ModalFooter>
            }
        >
            <div className="modal-body">
                <TextContainer
                    className="w-100 border border-depth-7 bg-depth-3 p-2"
                    rows={3}
                    maxLength={1000}
                    value={rowInfo?.memo || ''}
                    disabled
                />
            </div>
        </Modal>
    );
};

const TextContainer = styled.textarea`
    resize: none;
    width: 100%;
`;

export default NormalModal;
