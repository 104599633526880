import { useTranslation } from '@hooks';

export const useAssetListFloorDetailColumns = () => {
    const t = useTranslation('RealTimeLocationStatus');
    return [
        {
            Header: t('IoT Item Name'),
            headerClassName: 'flex-center',
            accessor: 'targetName',
            className: 'flex-center text-ellipsis',
        },
        {
            Header: t('IoT Item ID'),
            headerClassName: 'flex-center',
            accessor: 'targetId',
            className: 'flex-center text-ellipsis',
        },
        {
            Header: t('Category Name'),
            headerClassName: 'flex-center',
            accessor: 'categoryName',
            className: 'flex-center',
        },
        {
            Header: t('Entry Time'),
            headerClassName: 'flex-center',
            accessor: 'entryTime',
            className: 'flex-center',
        },
    ];
};

export const useGeofenceTargetInfoColumns = () => {
    const t = useTranslation('GeofenceStatus');
    return [
        {
            Header: t('Target Name'),
            headerClassName: 'flex-center',
            accessor: 'targetName',
            className: 'flex-center text-ellipsis',
        },
        {
            Header: t('Category'),
            headerClassName: 'flex-center',
            accessor: 'category',
            className: 'flex-center text-ellipsis',
        },
        {
            Header: t('Authorized'),
            headerClassName: 'flex-center',
            accessor: 'authorized',
            className: 'flex-center',
        },
        {
            Header: t('Entry Time'),
            headerClassName: 'flex-center',
            accessor: 'entryTime',
            className: 'flex-center',
        },
    ];
};

export const index = ({ ...restProps } = {}) => {
    return {
        Header: 'No.',
        Cell: ({ row, state }) => state.pageIndex * state.pageSize + row.index + 1,
        ...restProps,
    };
};

export const categoryName = ({ ...restProps } = {}) => {
    return {
        Header: 'Category',
        accessor: 'categoryName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const targetName = ({ ...restProps } = {}) => {
    return {
        Header: 'People Name',
        accessor: 'targetName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const targetId = ({ ...restProps } = {}) => {
    return {
        Header: 'Target ID',
        accessor: 'targetId',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const tagName = ({ ...restProps } = {}) => {
    return {
        Header: 'Tag Name',
        accessor: 'beaconName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const floorName = ({ ...restProps } = {}) => {
    return {
        Header: 'Floor',
        accessor: 'floorName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const location = ({ ...restProps } = {}) => {
    return {
        Header: 'Location',
        accessor: 'geofence',
        className: 'p-0 text-ellipsis',
        ...restProps,
    };
};

export const signalStatus = ({ ...restProps } = {}) => {
    return {
        Header: 'Signal Status',
        accessor: 'status',
        className: 'p-0',
        ...restProps,
    };
};

export const lastDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Last Detected Time',
        accessor: 'lastDate',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const bookmark = ({ ...restProps } = {}) => {
    return {
        Header: 'Bookmark',
        headerClassName: 'text-ellipsis',
        accessor: 'bookmark',
        className: 'text-ellipsis p-1',
        ...restProps,
    };
};

export const ward = ({ ...restProps } = {}) => {
    return {
        Header: 'Ward',
        accessor: 'properties.ward',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const wardName = ({ ...restProps } = {}) => {
    return {
        Header: 'Ward',
        accessor: 'wardName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const ownerWard = ({ ...restProps } = {}) => {
    return {
        Header: 'Owner Ward',
        accessor: 'properties.ward',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const room = ({ ...restProps } = {}) => {
    return {
        Header: 'Room',
        accessor: 'properties.room',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const bed = ({ ...restProps } = {}) => {
    return {
        Header: 'Bed',
        accessor: 'properties.bed',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const assetName = ({ ...restProps } = {}) => {
    return {
        Header: 'Asset Name',
        headerClassName: 'text-ellipsis',
        accessor: 'targetName',
        width: 90,
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const assetStatus = ({ ...restProps } = {}) => {
    return {
        Header: 'Asset status',
        accessor: 'rentalStatus',
        className: 'd-flex justify-content-center',
        ...restProps,
    };
};

export const inspectionDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Inspection date',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        accessor: 'regDate',
        ...restProps,
    };
};
export const inspectionStatus = ({ ...restProps } = {}) => {
    return {
        Header: 'Inspection status',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        accessor: 'inspectStatus',
        ...restProps,
    };
};
export const inspectionResult = ({ ...restProps } = {}) => {
    return {
        Header: 'Inspection result',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        accessor: 'checkResult',
        ...restProps,
    };
};

export const inspector = ({ ...restProps } = {}) => {
    return {
        Header: 'Inspector',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        accessor: 'checkerName',
        Cell: ({ value }) => value || '-',
        ...restProps,
    };
};

export const inspectorEmployeeNum = ({ ...restProps } = {}) => {
    return {
        Header: 'Employee Number',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        accessor: 'checkerNum',
        ...restProps,
    };
};

export const inspectorUsername = ({ ...restProps } = {}) => {
    return {
        Header: 'Username',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        accessor: 'checkerId',
        ...restProps,
    };
};

export const memo = ({ ...restProps } = {}) => {
    return {
        Header: 'Memo',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        accessor: 'memo',
        ...restProps,
    };
};

// 의료폐기물 대시보드
export const date = ({ ...restProps } = {}) => {
    return {
        Header: 'Date',
        headerClassName: 'text-center text-ellipsis',
        className: 'text-center text-ellipsis',
        ...restProps,
    };
};

export const billed = ({ ...restProps } = {}) => {
    return {
        Header: 'Billed(Isolated medical)',
        accessor: 'billed',
        ...restProps,
    };
};

export const total = ({ ...restProps } = {}) => {
    return {
        Header: 'Total collection',
        accessor: 'total',
        ...restProps,
    };
};

export const isolateMedical = ({ ...restProps } = {}) => {
    return {
        Header: 'Isolated medical',
        accessor: 'isolatedMedical',
        ...restProps,
    };
};

export const normalMedical = ({ ...restProps } = {}) => {
    return {
        Header: 'Normal medical',
        accessor: 'normalMedical',
        ...restProps,
    };
};
export const tissue = ({ ...restProps } = {}) => {
    return {
        Header: 'Tissue',
        accessor: 'tissue',
        ...restProps,
    };
};

export const damage = ({ ...restProps } = {}) => {
    return {
        Header: 'Damage',
        accessor: 'damage',
        ...restProps,
    };
};
export const pathology = ({ ...restProps } = {}) => {
    return {
        Header: 'Pathology',
        accessor: 'pathology',
        ...restProps,
    };
};
export const biochemistry = ({ ...restProps } = {}) => {
    return {
        Header: 'Biochemistry',
        accessor: 'biochemistry',
        ...restProps,
    };
};
export const bloodContamination = ({ ...restProps } = {}) => {
    return {
        Header: 'Blood contamination',
        accessor: 'bloodContamination',
        ...restProps,
    };
};
export const humanTissue = ({ ...restProps } = {}) => {
    return {
        Header: 'Human tissue',
        accessor: 'humanTissue',
        ...restProps,
    };
};

export const weight = ({ ...restProps } = {}) => {
    return {
        Header: 'Weight(kg)',
        accessor: 'weight',
        ...restProps,
    };
};

export const modelNo = ({ ...restProps } = {}) => {
    return {
        Header: 'ModelNo',
        accessor: 'modelNo',
        className: 'text-ellipsis',
        ...restProps,
    };
};
