import React from 'react';
import { useTranslation } from '@hooks';

const NotExistScreen = () => {
    const t = useTranslation('Dashboard');
    return (
        <div
            className="flex-center"
            style={{
                flexDirection: 'column',
                width: '100%',
                height: 'calc(100vh - 80px)',
                fontSize: '1.8rem',
                color: '#777',
                textAlign: 'center',
            }}
        >
            {t('There are no available menus.')}
            <br />
            {t('Please contact the administrator.')}
        </div>
    );
};

export default NotExistScreen;
