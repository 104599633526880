import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import useTranslation from '@hooks/useTranslation';
import { editWidgetSettings } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import { ScreenEditDispatchContext } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit';
import WidgetSettingModal from '../../Components/WidgetSettingModal';
import { Label, Select } from '@components';
import styled from 'styled-components';
import { useSettings } from '../../util/useSettings';

const OutpatientCareDashboardSetting = ({ widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const { settings: customSettings } = config;
    const settings = useSettings(config);

    const dispatch = useContext(ScreenEditDispatchContext);
    const { screenList } = useSelector(state => state.CustomMenu);

    const t = useTranslation('OutpatientCareDashboard');

    const initialWidgetInfo = settings?.dashboardURL ? { dashboardURL: settings.dashboardURL } : { dashboardURL: {} };
    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState(initialWidgetInfo);

    const handleSubmitClick = data => {
        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...customSettings, ...data, ...updatedWidgetInfo },
                }),
            );
        } else {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...data, ...updatedWidgetInfo },
                }),
            );
        }
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo(initialWidgetInfo);
    };

    const handleChangeMenu = (selected, action) => {
        const dashboardURL = updatedWidgetInfo?.dashboardURL || {};
        setUpdatedWidgetInfo({
            dashboardURL: { ...dashboardURL, [action.name]: selected.id },
        });
    };
    return (
        <WidgetSettingModal
            headerTitle={t('Outpatient Care Status Setting')}
            widgetInfo={widgetInfo}
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <Label
                name={t('Top Tile Link')}
                value={
                    <ScreenSelect
                        screenList={screenList}
                        name={'topTileLink'}
                        value={updatedWidgetInfo?.dashboardURL?.topTileLink}
                        handleChange={handleChangeMenu}
                    />
                }
            />
            <Label
                name={t('Bottom Tile Link')}
                value={
                    <ScreenSelect
                        screenList={screenList}
                        name={'bottomTileLink'}
                        value={updatedWidgetInfo?.dashboardURL?.bottomTileLink}
                        handleChange={handleChangeMenu}
                    />
                }
            />
        </WidgetSettingModal>
    );
};

const ScreenSelect = ({ screenList, name, value, handleChange }) => {
    return (
        <SelectWrapper>
            <Select
                customControlStyles={{ width: '100%' }}
                customMenuStyles={{ width: '100%' }}
                customOptionStyles={{ width: '100%' }}
                name={name}
                options={screenList}
                valueKey={'id'}
                labelKey={'label'}
                value={screenList.find(screen => screen.id === value)}
                onChange={handleChange}
                isModalSelect
            />
        </SelectWrapper>
    );
};

const SelectWrapper = styled.div`
    width: 100%;
`;

export default OutpatientCareDashboardSetting;
