import { useTranslation } from '@hooks';
import { TooltipItem } from 'chart.js';

const WHITE_COLOR = '#fff';
const TOOLTIP_BG_COLOR_DARK = '#4c4c52';
const TOOLTIP_BG_COLOR_NAVY = '#e8e6ef';
const TOOLTIP_TEXT_COLOR = '#565672';
const DARK_THEME = 'pnt-dark';

const getVitalSingsTooltip = (colorScheme: string, t: ReturnType<typeof useTranslation>) => ({
    tooltip: {
        backgroundColor: colorScheme === DARK_THEME ? TOOLTIP_BG_COLOR_DARK : TOOLTIP_BG_COLOR_NAVY,
        titleColor: colorScheme === DARK_THEME ? WHITE_COLOR : TOOLTIP_TEXT_COLOR,
        bodyColor: colorScheme === DARK_THEME ? WHITE_COLOR : TOOLTIP_TEXT_COLOR,
        callbacks: {
            title: (tooltipItem: TooltipItem<'line'>[]) => {
                return `${t('Recognition Time')} ${tooltipItem[0].label}`;
            },
            label: (tooltipItem: TooltipItem<'line'>) => {
                let tooltipValue = tooltipItem.formattedValue;
                // Floating Bar Chart 에서 최대 최소 툴팁 표기
                if (tooltipValue.includes('[')) {
                    tooltipValue = JSON.parse(tooltipValue)
                        .sort((l: number, r: number) => l - r)
                        .join(' ~ ');
                }
                return `${t(tooltipItem.dataset.label as string)} ${tooltipValue}`;
            },
        },
    },
});

export default getVitalSingsTooltip;
