import React, { createContext, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import Page from '@components/Page';
import { useTranslation, useAsync } from '@hooks';
import { getSelectedFilterApi } from '@api/common/filter';
import reducer, { initialState, setEditMode, setOriginFilter } from './reducer';
import DetailView from './View';

export const FilterDetailStateContext = createContext();
export const FilterDetailDispatchContext = createContext();

const FilterDetail = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const t = useTranslation('Filter');
    const { filterNum } = useParams();

    const { promise: getFilterInfo } = useAsync({
        promise: getSelectedFilterApi,
        resolve: res => {
            dispatch(setOriginFilter(res));
        },
    });

    useEffect(() => {
        if (filterNum) {
            getFilterInfo({ filterNum: Number(filterNum) });
        } else {
            dispatch(setOriginFilter(initialState.origin));
            dispatch(setEditMode(true));
        }
        return () => {
            dispatch(setOriginFilter(initialState.origin));
        };
    }, [filterNum]);

    return (
        <FilterDetailDispatchContext.Provider value={dispatch}>
            <FilterDetailStateContext.Provider value={state}>
                <Page className="h-100" menuPath={[t('Filters', 'Menu'), t('Filter Detail', 'Menu')]}>
                    <DetailView />
                </Page>
            </FilterDetailStateContext.Provider>
        </FilterDetailDispatchContext.Provider>
    );
};

export default FilterDetail;
