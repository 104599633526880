import React from 'react';
import ResizeDetector from 'react-resize-detector';

import cx from 'classnames';

const SearchGroup = ({ label, children, className, labelIconName = 'search' }) => {
    return (
        <ResizeDetector
            handleWidth
            render={() => {
                return (
                    <div className={cx('search-box')}>
                        {label && (
                            <div className={cx('filter-label', className)}>
                                <span className="material-icons-round md-18">{labelIconName}</span>
                                {label}
                            </div>
                        )}
                        {children}
                    </div>
                );
            }}
        />
    );
};
export default SearchGroup;
