import React from 'react';
import styled, { css } from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import cx from 'classnames';

const MonthlyTable = ({ columns, rows }) => {
    const t = useTranslation('RefrigeratorSensor');

    return (
        <TableContainer>
            <colgroup>
                <col style={{ width: '5%' }} />
                <col style={{ width: '7%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '7%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '51%' }} />
            </colgroup>
            <thead className="bg-depth-7">
                <tr>
                    <th rowSpan={2}>{t('Date')}</th>
                    <th colSpan={4}>{t('Measurement results')}</th>
                    <th rowSpan={2}>{t('Checker')}</th>
                    <th rowSpan={2}>{t('Note')}</th>
                </tr>
                <tr>
                    {/* date */}
                    <th>{t('Time')}</th>
                    <th>{`${t('Measured Temp')}(℃)`}</th>
                    <th>{t('Time')}</th>
                    <th>{`${t('Measured Temp')}(℃)`}</th>
                </tr>
            </thead>
            <tbody>
                {rows.map((rowItem, rowIdx) => {
                    return (
                        <tr key={rowIdx} className={cx(rowIdx % 2 !== 0 && 'bg-depth-3')}>
                            {columns.map((columnItem, colIdx) => {
                                return (
                                    <TdContainer
                                        key={colIdx}
                                        width={columnItem.width}
                                        className={colIdx === 0 ? 'bg-depth-7' : ''}
                                        outOfControl={rowItem[columnItem.outOfControl]}
                                    >
                                        {rowItem[columnItem.accessor]}
                                    </TdContainer>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </TableContainer>
    );
};

const TableContainer = styled.table`
    width: 100%;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    border-spacing: 0;
    border-collapse: separate;
    @media print {
        col:nth-child(2) {
            width: 6% !important;
        }
        col:nth-child(3) {
            width: 6% !important;
        }
        col:nth-child(4) {
            width: 6% !important;
        }
        col:nth-child(5) {
            width: 6% !important;
        }
        col:nth-child(7) {
            width: 61% !important;
        }
        thead {
            tr:nth-child(2) th {
                line-height: 1;
                padding-top: 0.25rem;
                padding-bottom: 0.25rem;
            }
        }
    }

    thead {
        border-bottom: 1px solid gray !important;
        tr {
            border-color: lightgray !important;
            & th {
                border-bottom: 1px solid lightgray;
            }
            @media print {
                background-color: #f6f4fa !important;
                & th:last-child {
                    border-right: 1px solid lightgray;
                }
            }
        }
        tr:first-child > th:first-child {
            border-left: none;
            @media print {
                border-left: 1px solid lightgray;
            }
        }
    }

    tbody {
        tr {
            & > td:first-child {
                border-left: none;
                @media print {
                    border-left: 1px solid lightgray;
                    background-color: #f6f4fa;
                }
            }

            @media print {
                & td:last-child {
                    padding: 0;
                    border-right: 1px solid lightgray;
                }
            }
        }

        td {
            border-bottom: 1px solid lightgray;
        }
    }

    tr {
        align-content: center;
    }

    th,
    td {
        text-align: center;
        padding: 0.5rem 1rem;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-left-width: 1px;
        font-size: small;
        @media print {
            font-size: 10px;
            padding: 0.4rem 0.25rem;
        }
        &:has(input) {
            position: relative;
        }
        & > input {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
`;

const TdContainer = styled.td`
    flex: ${props => (props.width ? (1 * props.width) / 100 : 1)};
    border-left-color: lightgray;
    ${props =>
        props.outOfControl &&
        css`
            @media screen {
                background-color: #ffce2e !important;
            }
        `}
`;

export default MonthlyTable;
