import React, { useEffect, useState } from 'react';

import { LoadingBlock, Table } from '@components';
import { useAppSelector, useAsync, useColumns, useTranslation } from '@hooks';
import { getEmergencyAlertHistory } from '@api/common/notification';
import { CATEGORY_CODE } from '@util/mappInfo';
import { bed, room, ward } from '@util/grid/column';

import WidgetCard from '../../Components/WidgetCard';
import SearchGroupAM from '../../am/Components/SearchGroupAM';
import useMultiOptionsByRole from '../../am/util/useMultiOptionsByRole';
import useRangeDateAM from '../../am/util/useRangeDateAM';
import Search from './Components/Search';
import moment from 'moment';

const EmergencyAlertHistory = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('EmergencyAlertHistory');
    const wardListValues = useMultiOptionsByRole();
    const rangeDateValues = useRangeDateAM();
    const wardMapInfo = useAppSelector(state => state.DepartmentsInfo.wardMapInfo);

    // 필터 시간 "오늘"로 설정
    useEffect(() => {
        rangeDateValues.handleStartChange(moment());
        rangeDateValues.handleEndChange(moment());
    }, []);

    // 알림 이력 조회
    const [history, setHistory] = useState({ rows: [] });
    // 키워드 검색
    const [word, setWord] = useState('');

    const { state, promise } = useAsync({
        fixedParam: { pageSize: 50 },
        param: {
            targetPropertiesSearch: {
                categoryCode: CATEGORY_CODE.PATIENT,
                propertySearchs:
                    wardListValues?.selectedWardList.length !== 0
                        ? wardListValues?.selectedWardList.map(ward => ({
                              propertyId: 'ward',
                              values: ward,
                          }))
                        : wardListValues?.options.map(({ value }) => ({
                              propertyId: 'ward',
                              values: value,
                          })),
            },
            startDate: rangeDateValues.startDate,
            endDate: rangeDateValues.endDate,
            keyword: word,
        },
        promise: getEmergencyAlertHistory,
        resolve: res => setHistory(res),
        immediate: true,
        deps: [wardListValues.selectedWardList.length, rangeDateValues.startDate, rangeDateValues.endDate, word],
    });

    const columns = useColumns(
        [
            {
                Header: 'Date',
                className: 'text-ellipsis',
                width: 100,
                Cell: ({ row: { original } }) => moment(original?.regDate * 1000).format('YYYY-MM-DD'),
            },
            ward({ accessor: 'ward', Cell: ({ value }) => wardMapInfo[value] ?? '-' }),
            room({ accessor: 'room' }),
            bed({ accessor: 'bed', width: 80 }),
            { Header: t('Patient name'), className: 'text-ellipsis', accessor: 'targetName' },
            { Header: t('Patient Id'), className: 'text-ellipsis', accessor: 'targetId' },
            {
                Header: t('Vital signs'),
                className: 'text-ellipsis',
                accessor: 'sensorStatus',
                Cell: ({ row: { original } }) => <span>{t(original.sensingType)}</span>,
            },
            {
                Header: 'Data',
                accessor: 'currentValue',
                width: 60,
                Cell: ({ value }) => <span>{value ? Math.round(value) : '-'}</span>,
            },
            {
                Header: t('Recognition Time'),
                className: 'text-ellipsis',
                accessor: 'regDate',
                Cell: ({ value }) => moment(value * 1000).format('YYYY-MM-DD HH:mm:ss'),
            },
            { Header: 'Sensor Name', accessor: 'deviceId', className: 'text-ellipsis' },
            { Header: 'Classification', accessor: 'deviceName' },
        ],
        t,
        [wardMapInfo],
    );

    const handlePageChange = pageIndex => {
        const param = state.request;
        promise({ ...param, page: pageIndex });
    };

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            searchFilter={<SearchGroupAM wardListValues={wardListValues} rangeDateValues={rangeDateValues} />}
            bodyClassName={'flx-col h-100 gap-0'}
            {...restProps}
        >
            <Search handleReset={() => setWord('')} handleSearchClick={input => setWord(input)} />
            <LoadingBlock blocking={state.isLoading}>
                <Table
                    columns={columns}
                    data={{ ...history, pageSize: 50 }}
                    onPageChange={handlePageChange}
                    noDataMessage={t('No history of emergency alert.')}
                />
            </LoadingBlock>
            <span className="pnt-txt txt-bold">
                {t(
                    '* Regardless of the emergency notification window, you can check all the history of warning and danger.',
                )}
            </span>
            {children}
        </WidgetCard>
    );
};

export default EmergencyAlertHistory;
