import { Patient } from './types';
import moment from 'moment';

// 현재 경과 시간 = 진행중일경우 마지막 시작 시각으로부터 현재시각까지의 시간을 합산, 진행중이 아닐 경우는 경과시간 값 그대로
export const getCurrentElapsedTime = ({ elapsedTime, progressType, startDate }: Patient) => {
    return elapsedTime + (progressType === 'Y' && startDate ? moment().unix() - startDate : 0);
};

export const secToMin = (sec: number) => Math.floor(sec / 60);

// sensorNum 은 소켓 데이터로 필드 자체가 들어오지 않으므로 언매핑을 해도, 이전의 sensorNum 데이터가 남아 있을 수 있어, 판단에 사용하지 못함.
export const isMapped = ({ sensorName, isMapped }: Pick<Patient, 'sensorName' | 'isMapped'>) => {
    return !!sensorName || isMapped === 'Y';
};
