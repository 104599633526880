import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector, useAsync } from '@hooks';
import { setLicenseStatus } from '@reducer/UserInfo';
import { checkLicense } from '@reducer/ErrorHandler';
import { getLicenseStatusApi } from '@api/common/login';
import { LicenseStatus } from '@hooks/useLicenseCheck';

// const getLicenseStatusFake = generateDynamicFakeData(() => {
//     return {
//         verificationActive: sample(['Y', 'N']),
//         verificationMethod: 'ONLINE',
//         baseStartDate: 1681011672,
//         verificationFailureDays: random(0, 10),
//         serviceRestrictionActive: sample(['Y', 'N']),
//         verificationResult: sample([
//             // 'NO_PROBLEM',
//             // 'HAS_PROBLEM',
//             'RESTRICTION',
//         ]),
//         verificationViolations: sampleSize(['OVER_SCANNERS', 'TOKEN_EXPIRATION', 'OVER_TAGS'], random(1, 3)),
//     };
// });

const useLicenseErrorCheck = () => {
    const dispatch = useDispatch();
    const { licenseError } = useAppSelector(state => state.ErrorHandler);

    const { promise: getLicenseStatus } = useAsync({
        promise: getLicenseStatusApi,
        param: {},
        resolve: (res: LicenseStatus) => {
            dispatch(setLicenseStatus(res));
        },
        reject: (err: any) => {
            console.error(err);
        },
    });

    useEffect(() => {
        if (licenseError) {
            dispatch(checkLicense());
            getLicenseStatus();
        }
    }, [licenseError]);
};

export default useLicenseErrorCheck;
