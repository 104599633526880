import React, { useContext, useState } from 'react';
import useTranslation from '@hooks/useTranslation';
import { editWidgetSettings } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import WidgetSettingModal from '../../Components/WidgetSettingModal';
import { ScreenEditDispatchContext } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit';
import { HiddenMapTile } from '../../Components/WidgetSettingOptions';

const SensorStatusSetting = ({ widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const { settings: customSettings } = config;
    const dispatch = useContext(ScreenEditDispatchContext);

    const t = useTranslation('SensorStatus');

    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    const handleHiddenMapTile = checkedHidden => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, hiddenMapTile: checkedHidden };
        });
    };

    const handleSubmitClick = data => {
        const checkedSettings = customSettings
            ? { ...customSettings, ...data, ...updatedWidgetInfo }
            : { ...data, ...updatedWidgetInfo };

        const updatedWidgetSettings = {
            id: widgetInfo.id,
            settings: checkedSettings,
        };

        dispatch(editWidgetSettings(updatedWidgetSettings));
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    return (
        <WidgetSettingModal
            headerTitle={t('Sensor Status Setting')}
            widgetInfo={widgetInfo}
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <HiddenMapTile
                updateWidgetInfo={updatedWidgetInfo}
                customSettings={customSettings}
                onChange={handleHiddenMapTile}
            />
        </WidgetSettingModal>
    );
};

export default SensorStatusSetting;
