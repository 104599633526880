import React, { useState } from 'react';
import useTranslation from '@hooks/useTranslation';
import Modal from '@components/Modal';
import Bookmark from '../../../../Components/Bookmark';
import SensorCardBody from './SensorCardBody';

/**
 * 카드 리스트 컴포넌트
 *
 * @param header card header title
 * @param bodyInfo card body info
 * @param bookmarkCallBack bookmark 호출 수정 후 이벤트
 * */
const SensorCard = ({ header, bodyInfo, bookmarkCallBack }) => {
    const t = useTranslation('RealTimeEnvironmentalSensor');
    // bookmark error modal
    const [bookmarkErrorModal, setBookmarkErrorModal] = useState(false);
    const toggleBookmarkErrorModal = () => setBookmarkErrorModal(!bookmarkErrorModal);
    const bookmarkErrorModalOkCallback = () => bookmarkCallBack();
    // bookmark 호출 후 event handler
    const handleBookmarkSuccess = () => bookmarkCallBack();
    const handleBookmarkFailure = () => {
        setBookmarkErrorModal(true);
    };

    return (
        <div key={header}>
            <div className="bg-depth-2 border-size-1 border-depth-7 transition-card">
                {/* Header */}
                <div className="flx-row justify-content-between bg-depth-7 border-bottom border-depth-7">
                    <div className="pnt-txt txt-bold px-2">{header}</div>
                    {/* bookmark */}
                    <div className="px-1 border-start border-depth-8">
                        <Bookmark
                            className={'btn-trans btn-icon-only'}
                            successCallback={handleBookmarkSuccess}
                            failureCallback={handleBookmarkFailure}
                            targetNum={bodyInfo.targetNum}
                            isBookmark={bodyInfo.isBookmark}
                        />
                    </div>
                </div>
                <SensorCardBody info={bodyInfo} />
            </div>
            <Modal
                initModal={bookmarkErrorModal}
                toggleModal={toggleBookmarkErrorModal}
                headerTitle={t('Bookmark')}
                bodyText={t('Something has changed.', 'ConfirmModal')}
                removeCancel
                okCallback={bookmarkErrorModalOkCallback}
            />
        </div>
    );
};

export default SensorCard;
