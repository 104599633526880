import React from 'react';
import styled from 'styled-components';
import { LoadingBlock } from '@components';
import useTranslation from '@hooks/useTranslation';

const AppInitialLoadingPage = () => {
    const t = useTranslation('Screen');
    return (
        <LoadingBlock
            blocking
            indicatorMessage={
                <LoadingText className={'pnt-txt s-2 txt-bolder color-white'}>
                    {t('Loading app initialization')}
                    <LoadingDots>
                        <Dot delay={-0.25} />
                        <Dot delay={-0.125} />
                        <Dot delay={0} />
                    </LoadingDots>
                </LoadingText>
            }
            defaultIndicator={false}
        >
            <div className={'app-main flex-center'} />
        </LoadingBlock>
    );
};

const LoadingText = styled.div`
    display: flex;
    gap: 0.3rem;
    align-items: center;
`;

const LoadingDots = styled.div`
    display: flex;
    gap: 0.3rem;
`;

const Dot = styled.div`
    width: 0.4rem;
    height: 0.4rem;
    background-color: #ffffff;
    border-radius: 100%;
    animation: dot-pulse 1s ${props => `${props.delay}s`} infinite;
    @keyframes dot-pulse {
        0%,
        100% {
            transform: scale(1);
            opacity: 1;
        }
        50% {
            transform: scale(0.7);
            opacity: 0.7;
        }
    }
`;

export default AppInitialLoadingPage;
