import React from 'react';
import FilterGroup from '../FilterSearchGroup/Components/FilterGroup';
import { FilterList } from '../FilterSearchGroup/Components/Part';
import FilterSearchGroup from '../FilterSearchGroup';
import useTranslation from '@util/hooks/useTranslation';
import FilterButtonArea from '../FilterSearchGroup/Components/FilterButtonArea';
import FilterGenerator from './FilterGenerator';
import { useDispatch, useSelector } from 'react-redux';
import { SCREEN_MODE_EDIT } from '@reducer/ScreenInfo';
import Select from '@components/Select';
import { setSelectedFilter } from '@reducer/FilterInfo';
import IntervalTimeSelect from '../FilterSearchGroup/Components/IntervalTimeSelect';

const getNoFilterOption = t => ({ filterNum: 0, filterName: t('No filter') });

const FilterContents = ({ handleFilterChange, handleFilterConditionChange, changeableFilter }) => {
    const t = useTranslation('Filter');
    const { mode } = useSelector(state => state.ScreenInfo);
    const { filterInfo, filterList } = useSelector(state => state.FilterInfo);
    const dispatch = useDispatch();
    return (
        <FilterGroup label={mode !== SCREEN_MODE_EDIT && t('Filter')}>
            <FilterList className="w-100">
                {mode === SCREEN_MODE_EDIT && (
                    <>
                        <Select
                            value={
                                filterList.find(filter => filter.filterNum === filterInfo.filterNum) ??
                                getNoFilterOption(t)
                            }
                            options={filterList}
                            valueKey={'filterNum'}
                            labelKey={'filterName'}
                            onChange={selected => {
                                dispatch(setSelectedFilter(selected));
                            }}
                        />
                        <div className="pnt-divider"></div>
                    </>
                )}
                <FilterGenerator
                    filterInfo={filterInfo}
                    filterEditMode={false}
                    monitoringMode
                    handleChange={(selected, isEditable, conditionInfo) => {
                        if (typeof handleFilterConditionChange === 'function') {
                            handleFilterConditionChange({
                                conditionValues: selected,
                                isEditable,
                                conditionId: conditionInfo.conditionId,
                            });
                        }
                    }}
                />
                <IntervalTimeSelect />
            </FilterList>
            <FilterButtonArea />
        </FilterGroup>
    );
};

const FilterWidget = ({
    withContainer = true,
    changeableFilter = true,
    handleFilterChange,
    handleFilterConditionChange,
}) => {
    return withContainer ? (
        <FilterSearchGroup>
            <FilterContents
                changeableFilter={changeableFilter}
                handleFilterChange={handleFilterChange}
                handleFilterConditionChange={handleFilterConditionChange}
            />
        </FilterSearchGroup>
    ) : (
        <FilterContents
            changeableFilter={changeableFilter}
            handleFilterChange={handleFilterChange}
            handleFilterConditionChange={handleFilterConditionChange}
        />
    );
};

export default FilterWidget;
