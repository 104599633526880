import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';
import { useAsync } from '@hooks';
import { fetchLocationLogList } from '@api/common/log';

import ExcelExport from './Component/ExcelExport';
import Search from './Component/Search';
import List from './Component/List';
import WidgetCard from '../../Components/WidgetCard';

const LocationLogContainer = ({ widgetInfo, className, children, ...restProps }) => {
    const { mode } = useSelector(state => state.ScreenInfo);
    const widgetRef = useRef(null);
    const [startDate, setStartDate] = useState(moment().startOf('day').valueOf());
    const [endDate, setEndDate] = useState(
        moment().startOf('day').add(23, 'hour').add(59, 'm').add(59, 'second').valueOf(),
    );

    const handleDateCallback = ({ selected, type }) => {
        switch (type) {
            case 'startDate':
                setStartDate(selected);
                break;
            case 'endDate':
                setEndDate(selected);
                break;
            case 'yesterday':
                const yesterday = moment().startOf('day').subtract(1, 'day');
                setStartDate(yesterday.valueOf());
                setEndDate(yesterday.add(23, 'hours').add(59, 'm').add(59, 'second').valueOf());
                break;
            case 'reset':
                setStartDate(moment().startOf('day').valueOf());
                setEndDate(moment().startOf('day').add(23, 'hour').add(59, 'm').add(59, 'second').valueOf());
                break;
        }
    };

    const [logData, setLogData] = useState({ totalCount: 0, totalPage: 1, rows: [] });
    const { promise: getLocationLogList, state: locationLogListInfo } = useAsync({
        promise: fetchLocationLogList,
        fixedParam: { pageSize: 15 },
        resolve: res => {
            const data = res.rows.map(log => {
                return {
                    logNum: log.logNum,
                    targetName: log.targetName,
                    tagName: log.tagName,
                    floorId: log.floorId,
                    latLng: [log.lat, log.lng],
                    regDate: log.regDate,
                    ...log,
                };
            });
            setLogData({ ...res, rows: data });
        },
    });
    useEffect(() => {
        getLocationLogList({ startDate: startDate / 1000, endDate: endDate / 1000 });
    }, []);

    return (
        <WidgetCard
            className={cx(className, 'locationLog-container')}
            widgetInfo={widgetInfo}
            headerAction={
                mode !== SCREEN_MODE_EDIT &&
                mode !== SCREEN_MODE_PREVIEW && (
                    <ExcelExport listCount={logData.rows.length} startDate={startDate} endDate={endDate} />
                )
            }
            ref={widgetRef}
            {...restProps}
        >
            <Search
                startDate={startDate}
                endDate={endDate}
                handleDateCallback={handleDateCallback}
                promise={getLocationLogList}
            />
            <List list={logData} state={locationLogListInfo} promise={getLocationLogList} widgetRef={widgetRef} />
            {children}
        </WidgetCard>
    );
};

export default LocationLogContainer;
