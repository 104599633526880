import React, { useEffect, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import moment from 'moment';

import useTranslation from '@hooks/useTranslation';
import { LineChart } from '@components';
import PlotLines from '@components/Charts/Plugins/PlotLines';

import { useSensorStatusContext } from '../../../index';
import NoDataBlock from '../../../../../Components/NoDataBlock';

const SensorLineGraph = ({ dataKey = 'regDate', selectedSensingType }) => {
    const t = useTranslation('SensorStatus');
    const { sensorLineChartData, selectedSensor } = useSensorStatusContext();
    const [limit, setLimit] = useState({ maxRiskLimit: 0, minRiskLimit: 0 });
    const [chartData, setChartData] = useState({ datasets: [] });
    const [chartOption, setChartOption] = useState({});

    // Limit
    useEffect(() => {
        if (selectedSensor) {
            const { sensorItems } = selectedSensor;
            const { minRiskLimit, maxRiskLimit } = sensorItems.find(
                sensorItem => selectedSensingType === sensorItem.sensingType,
            );
            setLimit({ maxRiskLimit, minRiskLimit });
        }
    }, [selectedSensor]);

    // Data
    useEffect(() => {
        setChartData({
            labels:
                dataKey === 'date'
                    ? sensorLineChartData.map(e => e['date'])
                    : sensorLineChartData.map(e => e['regDate']),
            datasets: [
                {
                    data: sensorLineChartData.map(({ data }) => data),
                },
            ],
        });
    }, [sensorLineChartData]);

    // Options
    useEffect(() => {
        setChartOption({
            plugins: {
                legend: { display: false },
                plotLines: [
                    { text: t('Max Risk Limit'), value: limit.maxRiskLimit, color: 'red' },
                    { text: t('Min Risk Limit'), value: limit.minRiskLimit, color: 'red' },
                ],
                tooltip: {
                    callbacks: {
                        title: tooltipItems => {
                            if (dataKey === 'date') {
                                const label = tooltipItems[0].label;
                                const index = tooltipItems[0].dataIndex;
                                return `${label} ${
                                    sensorLineChartData[index].regDate
                                        ? moment.unix(sensorLineChartData[index].regDate).format('HH:mm')
                                        : ''
                                }`;
                            }
                        },
                        label: context => `${t('Data')} : ${context.parsed.y}`,
                    },
                },
            },
            scales: {
                x: { offset: true },
                y: {
                    tick: { tickLength: 25 },
                    suggestedMin: Math.min(...sensorLineChartData.map(e => e['data'])),
                    suggestedMax: Math.max(...sensorLineChartData.map(e => e['data'])),
                },
            },
            datasets: { line: { tension: 0.4, borderCapStyle: 'round' } },
        });
    }, [sensorLineChartData, limit, t]);

    return (
        <>
            {!!sensorLineChartData && sensorLineChartData.length ? (
                <ReactResizeDetector refreshMode="debounce" refreshRate={100} handleHeight handleWidth>
                    <LineChart data={chartData} options={chartOption} plugins={[PlotLines]} />
                </ReactResizeDetector>
            ) : (
                <NoDataBlock />
            )}
        </>
    );
};

export default SensorLineGraph;
