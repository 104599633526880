import { ReactNode } from 'react';

/**
 * ### 기본 응답 에러
 *
 * @author kunwoong-kim
 */
export const DEFAULT_ERRORS = {
    /**
     * 지정된 Error handling 후 항상 실행되는 handling이 필요한 경우
     */
    COMMON: 'common',
    /**
     * 어디에도 지정되지 않은 에러의 경우
     */
    DEFAULT: 'default',
} as const;

/**
 * ### HTTP STATUS CODE ERRORS
 * 기본적으로 사용되는 HTTP 상태 코드와 Api 응답으로 정의된 내용을 취합하여 사용중
 *
 * [HttpStatus Code 참고](https://developer.mozilla.org/ko/docs/Web/HTTP/Status#%ED%81%B4%EB%9D%BC%EC%9D%B4%EC%96%B8%ED%8A%B8_%EC%97%90%EB%9F%AC_%EC%9D%91%EB%8B%B5)
 * @author kunwoong-kim
 */
export const HTTP_ERRORS = {
    /**
     * 이 응답은 잘못된 문법으로 인하여 서버가 요청을 이해할 수 없음을 의미합니다.
     */
    BAD_REQUEST: '400',
    /**
     * 비록 HTTP 표준에서는 "미승인(unauthorized)"를 명확히 하고 있지만, 의미상 이 응답은 "비인증(unauthenticated)"을 의미합니다.
     * 클라이언트는 요청한 응답을 받기 위해서는 반드시 스스로를 인증해야 합니다.
     */
    UNAUTHORIZED: '401',
    /**
     * 클라이언트는 콘텐츠에 접근할 권리를 가지고 있지 않습니다. 예를들어 그들은 미승인이어서 서버는 거절을 위한 적절한 응답을 보냅니다.
     * 401과 다른 점은 서버가 클라이언트가 누구인지 알고 있습니다.
     */
    FORBIDDEN: '403',
    /**
     * 서버는 요청받은 리소스를 찾을 수 없습니다.
     * 브라우저에서는 알려지지 않은 URL을 의미합니다.
     * 이것은 API에서 종점은 적절하지만 리소스 자체는 존재하지 않음을 의미할 수도 있습니다.
     */
    NOT_FOUND: '404',
    /**
     * 서버가 처리 방법을 모르는 상황이 발생했습니다. 서버는 아직 처리 방법을 알 수 없습니다.
     */
    INTERNAL_SERVER_ERROR: '500',
} as const;

/**
 * ### OAuth API 응답 에러
 * OAuth Api Error Response Code Reference
 *
 * [OAuth API document](https://redmine.pntbiz.com:13001/projects/platform-group/wiki/Oauth_flow#%EC%97%90%EB%9F%AC-%EC%BD%94%EB%93%9C)
 *
 * @author kunwoong-kim
 */
export const OAUTH_ERRORS = {
    /**
     * 요청시 데이터가 누락되어 요청한 경우
     */
    INVALID_REQUEST: 'invalid_request',
    /**
     * 잘못된 authorization_code를 사용한 경우
     *
     * 잘못되거나 만료된 refresh_token 을 요청한 경우
     *
     * refresh_token 사용횟수를 오버한 경우 (default :300)
     */
    INVALID_GRANT: 'invalid_grant',
    /**
     * response_type 값이 누락되거나 "code"가 아닐 경우
     */
    UNSUPPORTED_RESPONSE_TYPE: 'unsupported_response_type',
    /**
     * grant_type 값이 누락되는 경우
     */
    UNSUPPORTED_GRANT_TYPE: 'unsupported_grant_type',
    /**
     * scope가 누락되거나, 잘못된 형식인 경우
     */
    INVALID_SCOPE: 'invalid_scope',
    /**
     * client_id/ client_secret 값을 누락한 경우
     *
     * 잘못된 client_id, client_secret로 요청한 경우
     */
    INVALID_CLIENT: 'invalid_client',
} as const;

/**
 * ### HTTP STATUS CODE ERRORS
 * HTTP Status code 상태의 정의는 정상이지만 Indoorplus Api 응답의 경우
 * response data 안에 에러가 정의되어 있으므로
 * Handler 구분을 위한 Key를 따로 정의함
 *
 * [Indoorplus API document v3](https://redmine.pntbiz.com:13001/projects/manual/wiki/Error_Response_Code_Reference_For_v3)
 * [People & Technology Swagger REST API Documentation v3](https://redmine.pntbiz.com:13001/projects/manual/wiki/Error_Response_Code_Reference_For_v3)
 * @author kunwoong-kim
 */
export const INDOORPLUS_ERROR_HANDLE_KEY = '200' as const;

/**
 * ### Indoorplus API 응답 에러
 * Indoorplus Api Error Response Code Reference
 *
 * [Indoorplus API document v3](https://redmine.pntbiz.com:13001/projects/manual/wiki/Error_Response_Code_Reference_For_v3)
 * [People & Technology Swagger REST API Documentation v3](https://redmine.pntbiz.com:13001/projects/manual/wiki/Error_Response_Code_Reference_For_v3)
 *
 * @author kunwoong-kim
 */
export const INDOORPLUS_ERRORS = {
    /**
     * @deprecated
     * ### 서버에서 SFTP연결하는 과정에서 문제가 발생함
     */
    SFTP_CONNECTION_FAIL: '1001',
    /**
     * ### 요청된 데이터가 이미 등록되어 있을 경우 발생
     */
    EXISTED_RESOURCE: '1101',
    /**
     * ### 요청된 데이터가 존재하지 않을 경우 발생
     */
    NOT_EXISTED_RESOURCE: '1102',
    /**
     * @deprecated
     * ### 디바이스(센서, 비콘)가 이미 대상정보(IoT 아이템)에 매핑되어 있을 경우 발생
     */
    ALREADY_MAPPED: '1103',
    /**
     * ### 요청된 데이터 유효성 검사.
     *
     * 데이터가 양식에 맞지 않아서 발생<br />
     * NOT_JSON_STRING_TYPE 에러의 상위 개념 (같은 개념으로 둘다 사용가능)
     */
    WRONG_VALUE: '1104',
    /**
     * ### JSON 구조의 문자열이어야 할 데이터가 json 구조가 아닐 때
     */
    NOT_JSON_STRING_TYPE: '1104',
    /**
     * ### 요청한 데이터가 실제 데이터의 규격이나 구조, 패턴에 일치하지 않을 경우 발생
     */
    NOT_MATCHED: '1201',
    /**
     * ### 요청된 데이터에 필수로 입력해야 할 값이 비어있을 때 발생
     */
    MUST_BE_ENTERED: '1301',
    /**
     * ### 라이선스 검증이 실패했을 경우 발생
     */
    LICENSE_VERIFICATION_FAIL: '2101',
    /**
     * ### 데이터를 하나만 조회하는 경우임에도 불구하고 조건에 맞는 데이터가 여러 개일 경우 발생
     */
    TOO_MANY_RESULTS: '1401',
    /**
     * ### 요청된 파라미터 유효성 검사
     * RTLS 파라미터, movingSpeed, batteryLevel 등 입력값이 실제 등록이 가능한지 검사
     */
    CANNOT_ENTER_VALUE: '2001',
    /**
     * ### 요청된 정보를 삭제함으로써 영향받는 정보가 있을 경우 발생
     */
    CANNOT_DELETE: '2002',
    /**
     * ### 로그인 실패. 로그인 정보가 없을 경우 발생
     */
    LOGIN_FAIL: '4011',
    /**
     * ### 요청된 token이 인증되지 않았을 경우 발생
     */
    UNAUTHORIZED_TOKEN: '4012',
    /**
     * ### 서버에서 스캐너 배포를 진행하는 중에 문제가 발생함
     */
    DEPLOY_FAIL: '5011',
    /**
     * ### HTTP header에 필요한 값이 없을 경우 발생
     *
     * 사용자 기반의 인증 token 없이 AdminAPI를 호출하거나,
     * 회사 기반의 인증 token으로 요청하더라도 UUID 없이 AdminAPI를 호출함
     */
    NOT_EXISTED_HEADER: '5101',
    /**
     * ### Open API 중 업체 인증으로는 작업을 수행할 수 없는 경우 발생
     */
    FUNCTION_FAIL: '6011',
} as const;

/**
 * ### RMS 공통 API 응답 에러 타입
 * Api Error Response Code Reference
 *
 * [IndoorPlus API document 참고](https://redmine.pntbiz.com:13001/projects/manual/wiki/Error_Response_Code_Reference_For_v3)
 *
 * @version v3
 * @author kunwoong-kim
 */
export declare namespace ApiError {
    /**
     * HTTP Method Type [유형 참고](https://developer.mozilla.org/ko/docs/Web/HTTP/Methods)
     */
    type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'delete';
    type HttpStatusCode = typeof HTTP_ERRORS[keyof typeof HTTP_ERRORS];
    type DefaultErrorCode = typeof DEFAULT_ERRORS[keyof typeof DEFAULT_ERRORS];

    type OAuthErrorHandlerKey = DefaultErrorCode | HttpStatusCode;
    type IndoorplusErrorHandlerKey = DefaultErrorCode | typeof INDOORPLUS_ERROR_HANDLE_KEY | HttpStatusCode;

    type OAuthErrorCode = typeof OAUTH_ERRORS[keyof typeof OAUTH_ERRORS];

    // type IndoorplusApiErrorResult = 'error' | 'success' | 'fail' | string;
    type IndoorplusErrorCode = typeof INDOORPLUS_ERRORS[keyof typeof INDOORPLUS_ERRORS];

    type ErrorHandlerType = (() => void) | ((error: ResponseData) => void);
    type ApiErrorHook = (handler?: ApiError.OAuthErrorHandlerType | ApiError.IndoorplusErrorHandlerType) => any;

    type HttpStatusErrorHandlerType = {
        [key in HttpStatusCode]?: ErrorHandlerType;
    };
    type IndoorplusErrorHandlerType = {
        [key in IndoorplusErrorCode]?: ErrorHandlerType;
    };
    type OAuthErrorHandlerType = {
        [key in OAuthErrorCode]?: ErrorHandlerType;
    };

    interface ResponseData {
        status: number;
        code: string;
        message: string;
        requestUri: string;
        requestMethod: HttpMethod;
        requestPrams: object;
    }

    interface BaseHandler {
        [DEFAULT_ERRORS.COMMON]?: ErrorHandlerType;
        [DEFAULT_ERRORS.DEFAULT]: ErrorHandlerType;
    }

    interface DefaultHandler
        extends BaseHandler,
            HttpStatusErrorHandlerType,
            IndoorplusErrorHandlerType,
            OAuthErrorHandlerType {
        [INDOORPLUS_ERROR_HANDLE_KEY]: ErrorHandlerType;
    }
}

export default ApiError;
