import React, { ChangeEvent, FormEvent } from 'react';
import { Button, DatePicker, TextInput } from '@components';
import { useTranslation } from '@hooks';
import cx from 'classnames';
import { FilterList } from '../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '../../../../MainPages/Components/FilterSearchGroup';
import FilterGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/FilterGroup';
import SearchGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import moment from 'moment';
import { UnixTimestamp } from '@util/type/util';
import styled from 'styled-components';

export type DateRange = { startDate: UnixTimestamp; endDate: UnixTimestamp };

type SearchProps = {
    handleSearchClick(): void;
    handleReset(): void;
    handleSubjectNum(subjectNum: string): void;
    subjectNum: string;
    handleDateRange({ startDate, endDate }: DateRange): void;
    dateRange: DateRange;
};

const Search = ({
    handleSearchClick,
    handleReset,
    handleSubjectNum,
    subjectNum,
    handleDateRange,
    dateRange,
}: SearchProps) => {
    const t = useTranslation('ExamPatientsEmergencyAlertHistory');
    const handleStartChange = (date: UnixTimestamp) => {
        handleDateRange({ ...dateRange, startDate: date });
    };
    const handleEndChange = (date: UnixTimestamp) => {
        handleDateRange({
            ...dateRange,
            endDate: moment(date * 1000)
                .endOf('day')
                .unix(),
        });
    };
    const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (value.trim() === '') {
            handleSubjectNum(value);
        } else {
            handleSubjectNum(value.replace(/(\D+)/g, ''));
        }
    };

    const handleSearch = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleSearchClick();
    };

    const handleRefresh = () => {
        handleReset();
    };

    return (
        <form className="flx-row" onSubmit={handleSearch}>
            <FilterSearchGroup className={cx('absolute-filter', 'w-100')}>
                <FilterGroup className={''} label={t('Filter', 'Filter')}>
                    <FilterList className={''}>
                        <DatePicker
                            value={dateRange.startDate}
                            handleChange={handleStartChange}
                            withoutTime
                            valueType="s"
                            disabledKeyboardNavigation
                            minDate={moment().subtract(14, 'days').valueOf()}
                            maxDate={moment.unix(dateRange.endDate).valueOf()}
                        />
                        <span>~</span>
                        <DatePicker
                            value={dateRange.endDate}
                            handleChange={handleEndChange}
                            withoutTime
                            valueType="s"
                            disabledKeyboardNavigation
                            minDate={moment.unix(dateRange.startDate).valueOf()}
                            maxDate={moment().valueOf()}
                        />
                    </FilterList>
                </FilterGroup>
                <SearchGroup className={''} label={''}>
                    <FilterList className={''}>
                        <InputGroupWrapper>
                            <TextInput
                                inputGroupClassName={'w-100'}
                                type="text"
                                maxlength={13}
                                name="subjectNum"
                                value={subjectNum}
                                handleChange={handleSearchInput}
                                placeholder={t("Please enter the patient's study number.")}
                            />
                        </InputGroupWrapper>
                        <Button type="submit" className="btn-secondary">
                            {t('Search', 'Search')}
                        </Button>
                        <Button className="btn-line btn-icon-only ml-2" iconName="refresh" onClick={handleRefresh} />
                    </FilterList>
                </SearchGroup>
            </FilterSearchGroup>
        </form>
    );
};

const InputGroupWrapper = styled.div`
    @media screen and (min-width: 45rem) {
        width: 30rem;
    }
`;

export default Search;
