import React, { ChangeEvent } from 'react';
import {
    setMappingInfo,
    useExamPatientsMonitoringDispatchContext,
    useExamPatientsMonitoringStateContext,
} from '../../../slice';
import { Button, Table, TextInput } from '@components';
import { useAsync, useColumns, useConfirmModal, useTranslation } from '@hooks';
import * as mainCols from '../../../../../../MainPages/util/grid/column';
import * as col from '../../../columns';
import DeviceSearchModal from './DeviceSearchModal';
import { ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { Table as SimpleTable, TableBody, TableHead, Td, Th, Tr } from '@components/Table/SimpleTable';
import styles from '@asset/pnt/widgets/sh/ExamPatientsMonitoring.module.scss';
import { DeviceType, SensorItems } from '../../../types';
import NoDataBlock from '../../../../../Components/NoDataBlock';
import sensor_guide from '@asset/images/sensingItem-guide_ko.webp';
import styled from 'styled-components';
import { deleteSensorMappingApi } from '@api/sh/examPatientMonitoring';

interface Props {
    getTestTargetPatient: () => void;
}

const RANGE_ORDER = [
    'minRangeLimit', // 경고 최소값 (범위 최저값)
    'minRiskLimit', // 주의 최소값 (위험 최저값)
    'minControlLimit', // 정상 최소값 (정상 최저값)
    'maxControlLimit', // 정상 최대값 (정상 최대값)
    'maxRiskLimit', // 주의 최대값 (위험 최대값)
    'maxRangeLimit', // 경고 최대값 (범위 최대값)
];
const SENSING_KEY = ['HEARTBEAT', 'SPO2', 'TEMPERATURE'];

// 7 = th + 센서 범위 td 갯수
const INPUT_BORDER = 2;
const CELL_HEIGHT = 30;
const SENSOR_VALUE_TABLE_HEIGHT = {
    height: CELL_HEIGHT * 7 + INPUT_BORDER,
};

const TargetMappingInfo = ({ getTestTargetPatient }: Props) => {
    const t = useTranslation('ExamPatientsMonitoring');
    const columnT = useTranslation('CommonColumn');

    const {
        mappingInfo,
        selectedMappingSubjectNum,
        selectedMappingSubjectStarted,
    } = useExamPatientsMonitoringStateContext();
    const dispatch = useExamPatientsMonitoringDispatchContext();

    const { deviceNum } = mappingInfo.sensorInfo;
    const { promise: deleteSensorMapping } = useAsync({
        promise: deleteSensorMappingApi,
        resolve: () => {
            getTestTargetPatient();
        },
    });

    const { toggleModal: toggleAlertModal, Modal: AlertModal } = useConfirmModal({
        initModal: false,
        confirmText: t('You already have a device mapped. You can unmap and then map another device.'),
    });

    const { toggleModal: toggleUnmappingModal, Modal: UnmappingModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Do you want to unmap?'),
        okCallback: () => {
            deleteSensorMapping({
                subjectNum: selectedMappingSubjectNum,
            });
        },
    });

    const { toggleModal: togglePreventEditModal, Modal: PreventEditModal } = useConfirmModal({
        initModal: false,
        confirmText: t("You can't modify the range because the measurement has already started."),
        removeCancel: true,
    });

    // 디바이스 검색 모달
    const { toggleModal, Modal } = useConfirmModal({
        initModal: false,
        confirmText: <DeviceSearchModal onToggleModal={handleToggleModal} />,
        header: { title: t('Add Device') },
        modalFooter: (
            <ModalFooter className={'d-flex justify-content-center'}>
                <Button className={'btn-secondary'} onClick={handleToggleModal}>
                    {t('Close')}
                </Button>
            </ModalFooter>
        ),
    });

    const mappingColumns = useColumns(
        [
            mainCols.sensorName({
                className: 'justify-content-center',
                width: 100,
                Cell: ({ value }: { value: string }) => {
                    return <div>{value || '-'}</div>;
                },
            }),
            col.mapping({
                width: 50,
                className: 'd-flex justify-content-center',
                Cell: () => {
                    return deviceNum ? (
                        <Button
                            className={'btn-icon-only btn-trans md-18'}
                            iconName={'link_off'}
                            onClick={toggleUnmappingModal}
                        />
                    ) : (
                        '-'
                    );
                },
            }),
        ],
        columnT,
        [mappingInfo.sensorInfo],
    );

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const [labelKey, valueKey] = name?.split('_');

        if (/^[0-9\s]*$/.test(value)) {
            dispatch(setMappingInfo({ labelKey, valueKey, value: Number(value) }));
        }
    };

    function handleToggleModal() {
        getTestTargetPatient();
        toggleModal();
    }

    const handleAddDevice = () => {
        if (deviceNum) {
            toggleAlertModal();

            return;
        }

        toggleModal();
    };

    return (
        <>
            <div className={'h-100 d-flex flex-column gap-3'}>
                <div>
                    <span className={'pnt-txt txt-dot s-8'}>{t('Subject Number')}</span>
                    <div className={'d-flex flex-column gap-1'}>{selectedMappingSubjectNum}</div>
                </div>
                <div>
                    <span className={'pnt-txt txt-dot s-8'}>{t('Mapped Device')}</span>
                    <div className={'d-flex flex-column gap-1'}>
                        <div style={{ height: 70 }}>
                            <Table data={{ rows: [mappingInfo.sensorInfo] }} columns={mappingColumns} paging={false} />
                        </div>
                        <Button className={'btn-secondary w-100 flex-center'} onClick={handleAddDevice}>
                            <span className={'material-icons-round md-18'}>add</span>
                            {t('Add Mapping Device')}
                        </Button>
                    </div>
                </div>
                <div>
                    <div className={'d-flex gap-1 mb-1'}>
                        <span className={'pnt-txt txt-dot s-8 w-auto'}>{t('Range Setting')}</span>
                        <div className={'d-flex align-items-center'}>
                            <span id={'tooltip_range_help'} className="material-icons-round md-18 cursor-default">
                                help
                            </span>
                            <CustomQuestionTooltip target={'tooltip_range_help'} placement={'right'}>
                                <div className={'d-flex flex-column'}>
                                    <div className={'d-flex justify-content-center tooltip-title'}>{t('Help')}</div>
                                    <img alt="센서 범위 설명" src={sensor_guide} width={700} />
                                </div>
                            </CustomQuestionTooltip>
                        </div>
                    </div>
                    <div style={SENSOR_VALUE_TABLE_HEIGHT}>
                        {deviceNum ? (
                            <div className={'h-100'}>
                                <SimpleTable>
                                    <TableHead>
                                        <Tr>
                                            <Th className={`justify-content-center p-0 ${styles['flex-2']}`}>
                                                {t('Range')}
                                            </Th>
                                            <Th className={'justify-content-center p-0'}>{t('Heartbeat')}</Th>
                                            <Th className={'justify-content-center p-0'}>{t('SpO2')}</Th>
                                            <Th className={'justify-content-center p-0'}>{t('Temperature')}</Th>
                                        </Tr>
                                    </TableHead>
                                    <TableBody
                                        style={{ height: `${SENSOR_VALUE_TABLE_HEIGHT.height - CELL_HEIGHT}px` }}
                                    >
                                        <div>
                                            {RANGE_ORDER.map(name => {
                                                const value =
                                                    mappingInfo.sensorItems[name as keyof SensorItems].sensingValues;
                                                return (
                                                    <Tr key={name}>
                                                        <Td
                                                            className={`justify-content-center p-0 ${styles['flex-2']}`}
                                                        >
                                                            {t(name)}
                                                        </Td>
                                                        {SENSING_KEY.map(key => {
                                                            const cellValue = value[key as DeviceType];

                                                            return (
                                                                <Td key={name + key} className={'p-0'}>
                                                                    <TextInput
                                                                        name={name + '_' + key}
                                                                        className={'text-center'}
                                                                        maxlength={13}
                                                                        inputGroupClassName={styles['table-input']}
                                                                        value={String(cellValue)}
                                                                        handleChange={handleChangeInput}
                                                                        disabled={!!selectedMappingSubjectStarted}
                                                                        style={{ width: '100%' }}
                                                                    />
                                                                </Td>
                                                            );
                                                        })}
                                                    </Tr>
                                                );
                                            })}
                                        </div>
                                        {!!selectedMappingSubjectStarted && (
                                            <BlockedOverlay
                                                onClick={() => {
                                                    togglePreventEditModal();
                                                }}
                                            />
                                        )}
                                    </TableBody>
                                </SimpleTable>
                            </div>
                        ) : (
                            <NoDataBlock text={t('Please mapp your device first')} />
                        )}
                    </div>
                </div>
            </div>
            <Modal />
            <AlertModal />
            <UnmappingModal />
            <PreventEditModal />
        </>
    );
};

export default TargetMappingInfo;

const CustomQuestionTooltip = styled(UncontrolledTooltip)`
    .tooltip-title {
        background: gray;
    }
    .tooltip-inner {
        max-width: none;
        padding: 0;
    }
    .tooltip.show {
        opacity: 1;
    }
`;

const BlockedOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`;
