export const ROWS_DUMMY = [
    {
        targetNum: 6,
        comNum: 1,
        categoryCode: 'anesthesiamachine',
        categoryName: '제세동기',
        targetId: 'acfibrillator01',
        targetName: '제세동기',
        parentCategoryCode: 'ASSET',
        parentCategoryName: 'Asset',
        riskLevel: 'high',
        riskLevelName: '고위험',
        ward: 9,
        wardName: '소화기내과',
        memo: '점검 이상 없음.',
        checkerId: 'pntbiz@pntbiz.com',
        checkerName: '강동경희대병원 관리자',
        checkDate: 1701686677,
        checkDateStr: '2023-12-04 19:44:37',
        checkResult: 'good',
    },
    {
        targetNum: 4,
        comNum: 2,
        categoryCode: 'wheelchair',
        categoryName: '휠체어',
        targetId: 'wheelchair_01',
        targetName: '휠체어_01',
        parentCategoryCode: 'ASSET',
        parentCategoryName: 'Asset',
        riskLevel: 'high',
        riskLevelName: '고위험',
        ward: 23,
        wardName: '중앙병동1층',
        memo: '왼쪽 바퀴 고정 불량',
        checkerId: 'pntbiz@pntbiz.com',
        checkerName: '강동경희대병원 관리자',
        checkDate: 1701686677,
        checkDateStr: '2023-12-04 19:44:37',
        checkResult: 'bad',
    },
];

export const ROW_INFO_DUMMY = {
    targetNum: 6,
    comNum: 1,
    ward: 3,
    checkItem: [
        {
            checkItemNum: 1,
            checkItemName: '외관상 이상이 없는지?',
            checked: 'Y',
            checkDate: 1,
            checkDateStr: '2023-12-01 09:00:01',
        },
        {
            checkItemNum: 2,
            checkItemName: '조명이 들어오는지?',
            checked: 'N',
            checkDate: 1,
            checkDateStr: '2023-12-01 09:00:01',
        },
    ],
};

export const DEFAULT_VALUE = '-';
