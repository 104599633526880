import React, { useEffect, useMemo } from 'react';
import NoDataBlock from '../../../Components/NoDataBlock';
import { setSensorList, updateSensorList } from '../Context/slice';
import useFilter from '@hooks/useFilter';
import useAsync from '@hooks/useAsync';
import { getEnvironmentalSensorListApi } from '@api/monitoring';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_NUMERIC_SENSING } from '@reducer/SocketInfo';
import { DUMMY_LIST_DATA } from '../constants';
import { useAppSelector } from '@hooks';
import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';
import SensorCard from './SensorCard';
import { useRealTimeEnvironmentalSensorContext, useRealTimeEnvironmentalSensorDispatch } from '../Context';

const EnvironmentalSensorList = () => {
    const { sensorList } = useRealTimeEnvironmentalSensorContext();
    const dispatch = useRealTimeEnvironmentalSensorDispatch();
    const { mode } = useAppSelector(state => state.ScreenInfo);

    const filterParams = useFilter();

    // 센서 List 조회
    const { promise: getEnvironmentalSensorList } = useAsync({
        promise: getEnvironmentalSensorListApi,
        param: filterParams,
        resolve: res => {
            dispatch(setSensorList(res));
        },
    });

    const handleRefreshData = () => getEnvironmentalSensorList();

    const socketFilterConfig = useMemo(() => {
        return {};
    }, []);

    // socket 요청
    useSocketEvent({
        name: EVENT_TYPE_NUMERIC_SENSING,
        filterConfig: socketFilterConfig,
        handler: data => {
            dispatch(updateSensorList(data));
        },
    });

    useEffect(() => {
        if (mode === SCREEN_MODE_EDIT || mode === SCREEN_MODE_PREVIEW) {
            dispatch(setSensorList(DUMMY_LIST_DATA));
            return;
        }
        getEnvironmentalSensorList();
    }, [mode, filterParams]);

    // 데이터 없는 경우
    if (sensorList && !Object.entries(sensorList).length) {
        return <NoDataBlock />;
    }
    // 렌더링을 위한 Entry
    const entries = Object.entries(sensorList);

    return (
        <div className="grid-container item-grid-300">
            {entries.map(([header, info]) => (
                <SensorCard key={header} header={header} bodyInfo={info} bookmarkCallBack={handleRefreshData} />
            ))}
        </div>
    );
};

export default EnvironmentalSensorList;
