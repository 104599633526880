import React from 'react';
import { useAsync } from '@hooks';
import { getPatientStatusApi } from '@api/sh/waste';
import useOptionsByRole from '../../util/useOptionsByRole';
import useRangeDate from '../../util/useRangeDate';
import BodyHeader from './BodyHeader';
import ChartList from './ChartList';
import CountList from './CountList';
import CountTable from './CountTable';
import useAdminCheck from '../../util/useAdminCheck';
import moment from 'moment';
import { formatUnixTimeData } from '../../../util/format';

const formatToChartData = (arr, isAdmin = false) => {
    let result = [];

    // 관리자
    if (isAdmin) {
        const groupedObj = {};

        arr.forEach(ele => {
            const { date } = ele;

            if (!groupedObj[date]) {
                groupedObj[date] = {
                    date,
                    isolatedPatientCount: 0,
                    normalPatientCount: 0,
                    wasteCount: 0,
                };
            }

            groupedObj[date].isolatedPatientCount += ele.isolatedPatientCount;
            groupedObj[date].normalPatientCount += ele.normalPatientCount;
            groupedObj[date].wasteCount += ele.wasteCount;
        });

        const groupedArr = Object.values(groupedObj);
        result = groupedArr.slice().sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf());
    }
    // 사용자
    else {
        // 날짜 역순 데이터(arr) 재정렬
        const reversedResponseArray = arr.slice().reverse();
        result = reversedResponseArray.map(({ wardName, ...rest }) => rest);
    }

    return result;
};

export const DATA_TYPE_LIST = [
    { name: 'isolatedPatientCount', label: 'Isolated patient' },
    { name: 'normalPatientCount', label: 'Normal patient' },
    { name: 'wasteCount', label: 'Waste count' },
];

const TabCardBody = ({ selectedTab }) => {
    const { options, selectedWard, setSelectedWard } = useOptionsByRole();
    const { startDate, handleStartChange, endDate, handleEndChange } = useRangeDate(selectedTab);
    const isAdmin = useAdminCheck();

    const { state, promise: getList } = useAsync({
        promise: getPatientStatusApi,
        immediate: true,
        param: { dateType: selectedTab, ward: selectedWard.value, startDate, endDate },
        reject: err => console.error(err),
    });

    const data = state.response ? formatUnixTimeData(state.response.rows, selectedTab) : [];

    const countList = DATA_TYPE_LIST.map(({ name, label }) => ({
        name,
        label,
        count: data.reduce((acc, cur) => acc + cur[name], 0),
    }));

    const chartData = formatToChartData(data, isAdmin);

    return (
        <div className="card-body flx-col gap-3">
            <BodyHeader
                options={options}
                selectedTab={selectedTab}
                selectedWard={selectedWard}
                setSelectedWard={setSelectedWard}
                startDate={startDate}
                handleStartChange={handleStartChange}
                endDate={endDate}
                handleEndChange={handleEndChange}
                getList={getList}
            />
            <CountList countList={countList} />
            <ChartList chartData={chartData} />
            <CountTable selectedTab={selectedTab} rows={data} />
        </div>
    );
};

export default TabCardBody;
