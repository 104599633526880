import React, { useEffect } from 'react';
import TargetMappingInfo from './Components/TargetMappingInfo';
import { Button, Modal } from '@components';
import { ModalFooter } from 'reactstrap';
import {
    setInitMappingInfo,
    setResetMappingInfo,
    setResetSelectedMappingSubjectNum,
    setToggleMappingModal,
    useExamPatientsMonitoringDispatchContext,
    useExamPatientsMonitoringStateContext,
} from '../../slice';
import { useAsync, useConfirmModal, useTranslation } from '@hooks';
import { getTargetInfoApi, postSensorRangeModifyApi } from '@api/sh/examPatientMonitoring';

const SensorMappingModal = () => {
    const t = useTranslation('ExamPatientsMonitoring');
    const dispatch = useExamPatientsMonitoringDispatchContext();
    const { mappingInfo, selectedMappingSubjectNum, mappingModalOpen } = useExamPatientsMonitoringStateContext();
    const {
        sensorInfo: { deviceNum },
    } = mappingInfo;

    const { toggleModal: toggleAlertModal, Modal: AlertModal } = useConfirmModal({
        initModal: false,
        confirmText: t('Invalid sensor range settings.'),
    });

    const { promise: getTestTargetPatient } = useAsync({
        promise: getTargetInfoApi,
        param: { subjectNum: selectedMappingSubjectNum },
        resolve: res => {
            if (res) {
                dispatch(setInitMappingInfo(res));
            }
        },
        reject: () => {
            dispatch(setResetMappingInfo({}));
        },
    });

    const { promise: postSensorMapping } = useAsync({
        promise: postSensorRangeModifyApi,
        resolve: () => {
            handleResetSelectedTargetNum();
        },
        reject: () => {
            toggleAlertModal();
        },
    });

    const toggleModal = () => {
        dispatch(setToggleMappingModal({}));
    };

    function handleResetSelectedTargetNum() {
        dispatch(setResetSelectedMappingSubjectNum({}));
        dispatch(setResetMappingInfo({}));
        toggleModal();
    }

    const handleSaveMappingInfo = () => {
        if (!deviceNum) {
            toggleModal();
            return;
        }

        postSensorMapping(mappingInfo);
    };

    useEffect(() => {
        if (selectedMappingSubjectNum) {
            getTestTargetPatient({ subjectNum: selectedMappingSubjectNum });
        }
    }, [selectedMappingSubjectNum]);

    return (
        <>
            <Modal
                initModal={mappingModalOpen}
                headerTitle={t('Enroll Study Subjects')}
                bodyText={<TargetMappingInfo getTestTargetPatient={getTestTargetPatient} />}
                toggleModal={toggleModal}
                modalFooter={
                    <ModalFooter className={'d-flex justify-content-center'}>
                        <div className={'d-flex gap-2'}>
                            <Button className={'btn-secondary'} onClick={handleSaveMappingInfo}>
                                {t('Save')}
                            </Button>
                            <Button className={'btn-gray'} onClick={handleResetSelectedTargetNum}>
                                {t('Close')}
                            </Button>
                        </div>
                    </ModalFooter>
                }
            />
            <AlertModal />
        </>
    );
};

export default React.memo(SensorMappingModal);
