import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useAsync } from '@hooks';
import { getIotItemMappingLogApi } from '@api/common/log';
import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';

import ExcelExport from './Component/ExcelExport';
import Search from './Component/Search';
import List from './Component/List';
import WidgetCard from '../../Components/WidgetCard';

const IotItemMappingLogContainer = ({ widgetInfo, children, ...restProps }) => {
    const { mode } = useSelector(state => state.ScreenInfo);
    const [list, setList] = useState({ totalCount: 0, totalPage: 1, rows: [] });
    const { promise: getIotItemMappingLog, state: iotItemMappingLogInfo } = useAsync({
        promise: getIotItemMappingLogApi,
        immediate: true,
        fixedParam: { pageSize: 15 },
        resolve: res => setList(res),
        reject: err => console.error(err),
    });

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            headerAction={
                mode !== SCREEN_MODE_EDIT &&
                mode !== SCREEN_MODE_PREVIEW && <ExcelExport listCount={list.rows.length} />
            }
            {...restProps}
        >
            <Search promise={getIotItemMappingLog} />
            <List list={list} state={iotItemMappingLogInfo} promise={getIotItemMappingLog} />
            {children}
        </WidgetCard>
    );
};

export default IotItemMappingLogContainer;
