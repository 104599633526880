import React, { createContext, useContext } from 'react';
import ContextProvider from '@components/ContextProvider';
import SensorStatusContainer from './SensorStatusContainer';
import sensorStatusSlice from './sensorStatusSlice';

export const SensorStatusStateContext = createContext(null);
export const SensorStatusDispatchContext = createContext(null);

export const useSensorStatusContext = () => useContext(SensorStatusStateContext);
export const useSensorStatusDispatch = () => useContext(SensorStatusDispatchContext);

/**
 * 센서현황 컴포넌트
 *
 * @author jinbeom-jung
 * */

const SensorStatus = ({ children, widgetInfo, ...restProps }) => {
    return (
        <ContextProvider
            StateContext={SensorStatusStateContext}
            DispatchContext={SensorStatusDispatchContext}
            slice={sensorStatusSlice}
        >
            <SensorStatusContainer widgetInfo={widgetInfo} children={children} {...restProps} />
        </ContextProvider>
    );
};

export default SensorStatus;
