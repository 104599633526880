import React, { useEffect, useState } from 'react';
import SummaryTable from './SummaryTable';
import { SearchWrap, SelectGroup } from '../../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '../../../../../MainPages/Components/FilterSearchGroup';
import moment from 'moment';
import useAsync from '@hooks/useAsync';
import { getVitalSignsDetailGraphOfPatronApi } from '@api/monitoring';
import DatePicker from '@components/DatePicker';
import useTranslation from '@hooks/useTranslation';
import Button from '@components/Button';
import { LineChart, LoadingBlock } from '@components';
import NoDataBlock from '../../../../Components/NoDataBlock';
import SearchGroup from '../../../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import cx from 'classnames';
import TIMES from '@util/times';
import {
    customTooltip,
    DEFAULT_CHART_OPTIONS,
    formatSensorList,
    GRAPH_AREA,
    SENSOR_TYPE,
    TAB_ITEMS,
} from '../../constants';
import { mergeObjects } from '@util/common/util';
import styles from '@asset/pnt/widgets/VitalSignsMonitoring.module.scss';
import { useAppSelector } from '@hooks';

const ContentView = ({ data }) => {
    const t = useTranslation('VitalSignsRealTimePartron');
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);
    const { targetId } = data;

    const [date, setDate] = useState(moment().unix());
    const [heartbeatValue, setHeartbeatValue] = useState({});

    const getToday = () => moment().format('YYYY-MM-DD');
    const getDate = () => moment.unix(date).format('YYYY-MM-DD');
    const handleChangeDate = date => {
        if (!date) {
            setDate(moment().unix());
            return;
        }
        setDate(date);
    };
    const [sensorGraphData, setSensorGraphData] = useState({});

    const [currentTab, setCurrentTab] = useState(0);
    const handleTabItem = idx => {
        setCurrentTab(idx);
    };

    const { promise: getVitalSignsDetailGraph, state: vitalSignsGraph } = useAsync({
        promise: getVitalSignsDetailGraphOfPatronApi,
        resolve: res => {
            const { recentVitalSensor, vitalSensorList } = res;
            setSensorGraphData(formatSensorList(vitalSensorList, t)[0]);
            setHeartbeatValue(recentVitalSensor);
        },
        reject: err => {
            setSensorGraphData({});
            console.error(err);
        },
    });

    useEffect(() => {
        const selectedDate = moment.unix(date);
        getVitalSignsDetailGraph({
            targetId,
            startDate: selectedDate.startOf('date').unix(),
            endDate: selectedDate.endOf('date').unix(),
        });
    }, [date]);

    return (
        <div className="w-100 d-flex flex-column">
            <SummaryTable vitalSensor={heartbeatValue} loading={vitalSignsGraph.isLoading} />

            <ul className="nav nav-tabs border-light">
                {TAB_ITEMS.map(({ key, value }, idx) => (
                    <li key={key} className="nav-item" onClick={() => handleTabItem(idx)}>
                        <span className={cx('nav-link', currentTab === idx && 'bg-lightgray')}>{t(key)}</span>
                    </li>
                ))}
            </ul>

            <FilterSearchGroup className="px-0 mb-2">
                <SearchGroup>
                    <SearchWrap>
                        <SelectGroup>
                            <div className={'datePicker-container'}>
                                <DatePicker
                                    value={date}
                                    handleChange={handleChangeDate}
                                    maxDate={moment().valueOf()}
                                    withoutTime
                                />
                            </div>
                        </SelectGroup>
                        <Button
                            className="btn-icon-only btn-lightgray ml-2"
                            iconName="keyboard_arrow_left"
                            onClick={() => handleChangeDate(date - TIMES.DAY_1_PER_SEC)}
                            disabled={vitalSignsGraph.isLoading}
                        />
                        <Button
                            className="btn-icon-only btn-lightgray ml-1"
                            iconName="keyboard_arrow_right"
                            onClick={() => handleChangeDate(date + TIMES.DAY_1_PER_SEC)}
                            disabled={getDate() === getToday() || vitalSignsGraph.isLoading}
                        />
                    </SearchWrap>
                </SearchGroup>
            </FilterSearchGroup>

            <div className="my-auto">
                <LoadingBlock blocking={vitalSignsGraph.isLoading}>
                    <div className="mb-5">
                        <div className="w-100 d-flex align-items-end mb-1">
                            <span className="pnt-txt txt-bold s-6">{t(SENSOR_TYPE.NAME.HEARTBEAT)}</span>
                            <span className="pnt-txt ml-1">({t(SENSOR_TYPE.UNIT.HEARTBEAT)})</span>
                        </div>
                        <div style={{ height: `${GRAPH_AREA.HEIGHT}px` }}>
                            {sensorGraphData.data && sensorGraphData.data?.datasets[0].data.length > 0 ? (
                                <LineChart
                                    data={sensorGraphData.data}
                                    options={mergeObjects(DEFAULT_CHART_OPTIONS, {
                                        plugins: customTooltip(colorScheme, t),
                                        scales: {
                                            y: {
                                                min: sensorGraphData.min - 10,
                                                max: sensorGraphData.max + 10,
                                            },
                                        },
                                    })}
                                />
                            ) : (
                                <NoDataBlock className={cx(styles.detail_graph, 'border-size-1 border-depth-7')} />
                            )}
                        </div>
                    </div>
                </LoadingBlock>
            </div>
        </div>
    );
};

export default ContentView;

