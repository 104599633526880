import React from 'react';
import ItemCount from './ItemCount';
import InnerItemCard from '@components/Card/InnerItemCard';
import styled from 'styled-components';
import { useTranslation } from '@hooks';
import { MEDICAL_WASTE } from '../../../../constants';

const DEFAULT_COUNT = 0;

const MedicalWasteSummary = ({ data }) => {
    const t = useTranslation(MEDICAL_WASTE);

    const firstRow = data => [
        { label: t('Isolated medical'), value: data?.isolatedMedical ?? DEFAULT_COUNT },
        { label: t('Normal medical'), value: data?.normalMedical ?? DEFAULT_COUNT },
        { label: t('Tissue'), value: data?.tissue ?? DEFAULT_COUNT },
        { label: t('Damage'), value: data?.damage ?? DEFAULT_COUNT },
    ];

    const secondRow = data => [
        { label: t('Pathology'), value: data?.pathology ?? DEFAULT_COUNT },
        { label: t('Biochemistry'), value: data?.biochemistry ?? DEFAULT_COUNT },
        { label: t('Blood contamination'), value: data?.bloodContamination ?? DEFAULT_COUNT },
        { label: t('Human tissue'), value: data?.humanTissue ?? DEFAULT_COUNT },
    ];

    let summaryData;
    if (data.length > 0) {
        const { billed, total } = data[0];
        summaryData = {
            billed,
            total,
            firstRow: firstRow(data[0]),
            secondRow: secondRow(data[0]),
        };
    } else {
        summaryData = {
            billed: DEFAULT_COUNT,
            total: DEFAULT_COUNT,
            firstRow: firstRow(),
            secondRow: secondRow(),
        };
    }

    return (
        <div className="grid-container item-grid-200">
            <RowFillInnerItemCard bodyClassName="h-100">
                <div className="widget-item-nums h-100">
                    <ItemCount
                        label={`${t('Billed')} (${t('Isolated medical')})`}
                        countClassname={'fs-1'}
                        start={DEFAULT_COUNT}
                        end={summaryData.billed}
                    />
                </div>
            </RowFillInnerItemCard>
            <RowFillInnerItemCard bodyClassName="h-100">
                <div className="widget-item-nums h-100">
                    <ItemCount
                        label={t('Total collection')}
                        countClassname={'fs-1'}
                        start={DEFAULT_COUNT}
                        end={summaryData.total}
                    />
                </div>
            </RowFillInnerItemCard>
            {/* 1st Row */}
            <ColumnFillInnerItemCard>
                <div className="widget-item-nums h-100">
                    {summaryData.firstRow.map(({ label, value }, idx) => (
                        <ItemCount
                            key={`${label}_${idx}`}
                            label={label}
                            className={'py-1'}
                            start={DEFAULT_COUNT}
                            end={value}
                        />
                    ))}
                </div>
            </ColumnFillInnerItemCard>
            <ColumnFillInnerItemCard>
                <div className="widget-item-nums h-100">
                    {summaryData.secondRow.map(({ label, value }, idx) => (
                        <ItemCount
                            key={`${label}_${idx}`}
                            label={label}
                            className={'py-1'}
                            start={DEFAULT_COUNT}
                            end={value}
                        />
                    ))}
                </div>
            </ColumnFillInnerItemCard>
        </div>
    );
};

const ColumnFillInnerItemCard = styled(InnerItemCard)`
    grid-column: 3 / auto-fill;
`;

const RowFillInnerItemCard = styled(InnerItemCard)`
    grid-row: 1 / span 2;
`;
export default MedicalWasteSummary;
