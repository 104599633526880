import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import useTranslation from '@hooks/useTranslation';
import { editWidgetSettings } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import { ScreenEditDispatchContext } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit';
import WidgetSettingModal from '../../Components/WidgetSettingModal';
import { Label, Select } from '@components';
import styled from 'styled-components';
import { useSettings } from '../../util/useSettings';
import { HEADER_LIST } from './constants';

const AssetDashboardSetting = ({ widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const { settings: customSettings } = config;
    const settings = useSettings(config);

    const dispatch = useContext(ScreenEditDispatchContext);
    const { screenList } = useSelector(state => state.CustomMenu);

    const t = useTranslation('AssetDashboard');

    const initialWidgetInfo = settings?.dashboardURL ? { dashboardURL: settings.dashboardURL } : { dashboardURL: {} };
    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState(initialWidgetInfo);

    const handleSubmitClick = data => {
        const checkedSettings = customSettings
            ? { ...customSettings, ...data, ...updatedWidgetInfo }
            : { ...data, ...updatedWidgetInfo };

        const updatedWidgetSettings = {
            id: widgetInfo.id,
            settings: checkedSettings,
        };

        dispatch(editWidgetSettings(updatedWidgetSettings));
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo(initialWidgetInfo);
    };

    const handleChangeMenu = (selected, action) => {
        const dashboardURL = updatedWidgetInfo?.dashboardURL || {};
        setUpdatedWidgetInfo({
            dashboardURL: { ...dashboardURL, [action.name]: selected.id },
        });
    };

    return (
        <WidgetSettingModal
            headerTitle={t('Asset Dashboard Setting')}
            widgetInfo={widgetInfo}
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            {HEADER_LIST.map(header => (
                <Label
                    key={header}
                    name={t(header)}
                    value={
                        <ScreenSelect
                            screenList={screenList}
                            name={header}
                            value={updatedWidgetInfo?.dashboardURL[header]}
                            handleChange={handleChangeMenu}
                        />
                    }
                />
            ))}
        </WidgetSettingModal>
    );
};

const ScreenSelect = ({ screenList, name, value, handleChange }) => {
    return (
        <SelectWrapper>
            <Select
                customControlStyles={{ width: '100%' }}
                customMenuStyles={{ width: '100%' }}
                customOptionStyles={{ width: '100%' }}
                name={name}
                options={screenList}
                valueKey={'id'}
                labelKey={'label'}
                value={screenList.find(screen => screen.id === value)}
                onChange={handleChange}
                isModalSelect
            />
        </SelectWrapper>
    );
};

const SelectWrapper = styled.div`
    width: 100%;
`;

export default AssetDashboardSetting;
