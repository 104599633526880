import { Button, LoadingBlock, Modal } from '@components';
import { useAsync, useTranslation } from '@hooks';
import React, { useRef, useState } from 'react';
import { ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import { ASSET_MANAGEMENT, INSPECTION_COMPLETED_TYPE, INSPECTION_RESULT_TYPE } from '../../constants';
import { getDailyInspectionDetailApi, getPrintTestImage, setDailyHighRiskInspectionMemoApi } from '@api/am/management';
import cx from 'classnames';
import { SCREEN_MODE_MONITORING } from '@reducer/ScreenInfo';
import { ROW_INFO_DUMMY } from './constants';
import useAppSelector from '@hooks/useAppSelector';

const HighRiskModal = ({ isOpen, toggleModal, rowInfo }) => {
    const t = useTranslation(ASSET_MANAGEMENT);
    const { mode } = useAppSelector(state => state.ScreenInfo);
    const memoRef = useRef();
    const [isErrorImage, setIsErrorImage] = useState(false);
    const onErrorImage = () => setIsErrorImage(true);
    const [isOriginalImageModalOpen, setIsOriginalImageModalOpen] = useState(false);
    const toggleOriginalImageModal = () => setIsOriginalImageModalOpen(prevState => !prevState);

    const { state: inspectionDetailState } = useAsync({
        promise: getDailyInspectionDetailApi,
        param: { dailyCheckNum: rowInfo.dailyCheckNum },
        immediate: mode === SCREEN_MODE_MONITORING,
        reject: err => console.error(err),
    });

    const { state: imageState } = useAsync({
        promise: getPrintTestImage,
        param: { dailyCheckNum: rowInfo.dailyCheckNum },
        immediate: mode === SCREEN_MODE_MONITORING,
        reject: err => console.error(err),
    });
    const src = imageState.response ? imageState.response.fileUrl : '';
    const inspectionCheckList =
        mode !== SCREEN_MODE_MONITORING ? ROW_INFO_DUMMY.checkItem : inspectionDetailState.response?.checkItem || [];

    const { promise: setDailyHighRiskInspectionMemo } = useAsync({
        promise: setDailyHighRiskInspectionMemoApi,
        resolve: () => {
            toggleModal();
        },
    });

    const handleSubmit = () => {
        setDailyHighRiskInspectionMemo({
            dailyCheckNum: rowInfo.dailyCheckNum,
            targetNum: rowInfo.targetNum,
            memo: memoRef.current?.value,
        });
    };

    const confirmInspectionResult = rowInfo.checkResult === INSPECTION_RESULT_TYPE.GOOD;

    return (
        <>
            <Modal
                initModal={isOpen}
                toggleModal={toggleModal}
                headerTitle={t('Check result details')}
                modalFooter={
                    <ModalFooter>
                        <Button className={'btn-gray'} onClick={toggleModal}>
                            {t('Close', 'Button')}
                        </Button>
                        <Button className={'btn-secondary ml-2'} onClick={handleSubmit}>
                            {t('Save', 'Button')}
                        </Button>
                    </ModalFooter>
                }
            >
                <div className="modal-body">
                    <div className="mb-3 border">
                        <div className="text-center fs-6 py-2 bg-depth-3 border-bottom">
                            <span className="fw-bold">{t('Check Lists')}</span>
                        </div>
                        {inspectionCheckList.length !== 0 ? (
                            inspectionCheckList.map(checkItem => (
                                <div
                                    key={checkItem.checkItemName}
                                    className="d-flex justify-content-between px-3 py-2 border-bottom"
                                >
                                    <span>{checkItem.checkItemName}</span>
                                    {checkItem.checked === INSPECTION_COMPLETED_TYPE.YES && (
                                        <span className="material-icons-round md-18 color-black">done</span>
                                    )}
                                </div>
                            ))
                        ) : (
                            <div className="d-flex justify-content-between px-3 py-2 border-bottom">
                                <span>{t('Not Inspection item list')}</span>
                            </div>
                        )}
                        <div className="d-flex justify-content-between align-center bg-depth-3 px-3 py-2">
                            <span className="fw-bold">{t('Check Result')}</span>
                            <span className={cx('fw-bold', confirmInspectionResult ? 'text-success' : 'text-danger')}>
                                {t(rowInfo.checkResult)}
                            </span>
                        </div>
                    </div>
                    <div className="mb-1">
                        <span className="fw-bold fs-7">{t('Print Test')}</span>
                    </div>
                    <div className="d-flex">
                        <LoadingBlock blocking={imageState.isLoading}>
                            <ImageContainer
                                className={'d-flex justify-content-center align-center border border-color-depth-8'}
                            >
                                {!imageState.isLoading &&
                                    (isErrorImage ? (
                                        <span className={'text-center px-2'}>
                                            {t('No test images are registered.')}
                                        </span>
                                    ) : (
                                        <img
                                            style={{ objectFit: 'cover' }}
                                            className="w-100 h-100 cursor-pointer"
                                            src={src}
                                            alt={t('Print Test')}
                                            onError={onErrorImage}
                                            onClick={toggleOriginalImageModal}
                                            title={t('Images will enlarge when clicked.')}
                                        />
                                    ))}
                            </ImageContainer>
                        </LoadingBlock>
                        <TextContainer
                            ref={memoRef}
                            className="w-100 border border-depth-8 bg-depth-3 p-2 ml-2"
                            rows={3}
                            maxLength={1000}
                            placeholder={t('Notes (up to 1,000 characters)')}
                            defaultValue={rowInfo?.memo || ''}
                        />
                    </div>
                </div>
            </Modal>
            <OriginalImageModal
                initModal={isOriginalImageModalOpen}
                className={'max-w-none w-60 vh-100 my-0 shadow-none'}
                headerClassName={'d-none'}
                footerClassName={'d-none'}
                toggleModal={toggleOriginalImageModal}
            >
                <div className="modal-body h-100 p-3 bg-transparent d-flex">
                    <img
                        style={{ objectFit: 'contain' }}
                        className="w-100 h-100 cursor-pointer m-auto"
                        src={src}
                        alt={t('Print Test')}
                        onError={onErrorImage}
                        onClick={toggleOriginalImageModal}
                        title={t('Images will collapse when clicked.')}
                    />
                </div>
            </OriginalImageModal>
        </>
    );
};

const TextContainer = styled.textarea`
    resize: none;
`;

const ImageContainer = styled.div`
    width: 140px;
    height: 120px;
`;

const OriginalImageModal = styled(Modal)`
    height: 100vh;
    & .modal-content {
        height: 100%;
        border: none;
    }
`;

export default HighRiskModal;
