import React from 'react';
import Nameplate from '@components/Nameplate';
import VitalSignsBedList from './VitalSignsBedList';
import styles from '@asset/pnt/widgets/VitalSignsMonitoring.module.scss';
import cx from 'classnames';

/**
 * 병실 리스트 컴포넌트
 *
 * @param wardNum 부서/병동
 * @param roomList 병실 목록
 * @param handleRefreshData 환자별 활력징후 데이터 새로고침 handler
 *
 * @since 2023-02-07
 * @author kunwoong-kim
 * */
const VitalSignsRoomList = ({ wardNum, roomList, handleRefreshData }) => {
    // 렌더링을 위한 Entry
    const roomEntries = Object.entries(roomList);

    return roomEntries.map(([roomNum, beds], idx) => (
        <div key={`${wardNum}_${roomNum}_${idx}`} className={styles.room_container}>
            <div className={cx(styles.ward_container, 'd-flex mb-1')}>
                {idx === 0 && <Nameplate icon={'apartment'} name={wardNum} />}
            </div>
            <VitalSignsBedList roomNum={roomNum} bedList={beds} handleRefreshData={handleRefreshData} />
        </div>
    ));
};

export default VitalSignsRoomList;
