import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Table } from '@components';
import { useTranslation } from '@hooks';
import { MEDICAL_WASTE, WASTE_TYPE_LABEL } from '../../constants';
import { formatDateByColumn } from '../../util/format';
import moment from 'moment';

const getRoundedDownTime = unixTime => {
    return Math.floor(unixTime / 3600);
};

// 보관시간 계산
const getDiffStorageTime = (storageStartTime, collectionTime) => {
    // storageStartTime에는 readTimeA, collectionTime에는 readTimeD
    const currentTime = moment().unix();
    let diffStorageTime = 0;

    // 수거 시간 있으면 보관시간 = 수거시간 - 보관시작일자
    // 수거 시간이 없으면 보관시간 = 현재시간 - 보관시작일자
    if (collectionTime) {
        diffStorageTime = collectionTime - storageStartTime;
    } else {
        diffStorageTime = currentTime - storageStartTime;
    }

    return diffStorageTime;
};

// row에 주황색 칠하기
const getRowProps = ({ row, style }) => {
    const { readTimeA, storageTime, readTimeD } = row.original; // storageTime === 폐기물별 보관시간
    const diffStorageTime = getDiffStorageTime(readTimeA, readTimeD);
    let className = '';

    if (getRoundedDownTime(diffStorageTime) >= storageTime) {
        className = 'w-auto bg-brand';
    }

    return {
        style,
        className,
    };
};

const formatStatus = readCase => {
    // A = 부착전, B = 수집, C = 이송, D = 수거
    if (readCase === 'A') {
        return 'Before Attachment';
    }
    if (readCase === 'B') {
        return 'Acquisition';
    }
    if (readCase === 'C') {
        return 'Transfer';
    }
    if (readCase === 'D') {
        return 'Collection';
    }
    return '';
};

const Main = ({ rows }) => {
    const t = useTranslation(MEDICAL_WASTE);
    const columns = useMemo(
        () => [
            {
                Header: t('Ward'),
                accessor: 'wardName',
                width: 100,
            },
            {
                Header: t('Waste Type'),
                accessor: 'wasteType',
                Cell: ({ row }) => <div>{t(WASTE_TYPE_LABEL[row.original.wasteType])}</div>,
            },
            {
                Header: t('Status'),
                accessor: 'status',
                Cell: ({ row }) => <div>{t(formatStatus(row.original.readCase))}</div>,
                width: 100,
            },
            {
                Header: t('Storage Start Date'),
                className: 'text-nowrap',
                Cell: ({ row }) => formatDateByColumn(row, 'readTimeA'),
            },
            {
                Header: t('Storage Time'),
                Cell: ({ row }) => {
                    const { readTimeA, readTimeD } = row.original;
                    const diffStorageTime = getDiffStorageTime(readTimeA, readTimeD);

                    return <div>{getRoundedDownTime(diffStorageTime)}</div>;
                },
                width: 100,
            },
            {
                Header: t('Before Attachment Date'),
                className: 'text-nowrap',
                Cell: ({ row }) => formatDateByColumn(row, 'readTimeA'),
            },
            {
                Header: t('Acquisition Date'),
                className: 'text-nowrap',
                Cell: ({ row }) => formatDateByColumn(row, 'readTimeB'),
            },
            {
                Header: t('Transfer Date'),
                className: 'text-nowrap',
                Cell: ({ row }) => formatDateByColumn(row, 'readTimeC'),
            },
            {
                Header: t('Collection Date'),
                className: 'text-nowrap',
                Cell: ({ row }) => formatDateByColumn(row, 'readTimeD'),
            },
            {
                Header: t('KKRCode'),
                accessor: 'kkrCode',
                width: 200,
            },
            {
                Header: t('RFIDCode'),
                accessor: 'rfidCode',
                width: 300,
            },
            {
                Header: t('DEPTCode'),
                accessor: 'ward',
            },
        ],
        [t],
    );

    return (
        <TableWrapper className="mt-3">
            <Table data={{ rows }} columns={columns} paging={false} getRowProps={getRowProps} />
        </TableWrapper>
    );
};

const TableWrapper = styled.div`
    height: 45rem;
`;

export default Main;
