import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useConfirmModal, useTranslation } from '@hooks';
import { SCREEN_MODE_MONITORING } from '@reducer/ScreenInfo';
import InnerItemCard from '@components/Card/InnerItemCard';
import Label from '@components/Label';
import { DEFAULT_VALUE } from './constants';

const TOTAL_KEY = ['total', 'countCheckAssetCount'];

const DashboardCard = ({ header, status, settings }) => {
    const t = useTranslation('AssetDashboard');

    const { mode } = useSelector(state => state.ScreenInfo);
    const { dashboardURL } = settings || {};

    const history = useHistory();

    const { Modal: NotExistScreenModal, toggleModal: toggleNotExistScreenModal } = useConfirmModal({
        confirmText: t('Linked screen is not set. Please set it in screen editing.'),
        removeCancel: true,
    });

    const { Modal: MoveConfirmModal, toggleModal: toggleMoveConfirmModal } = useConfirmModal({
        confirmText: t('You cannot move the menu in edit mode.'),
        removeCancel: true,
    });

    const handleDashboardClick = () => {
        if (mode !== SCREEN_MODE_MONITORING) {
            toggleMoveConfirmModal();
        } else if (dashboardURL[header]) {
            history.push(dashboardURL[header]);
        } else {
            toggleNotExistScreenModal();
        }
    };

    const [_, total] = Object.entries(status)?.find(([key, _]) => TOTAL_KEY.includes(key)) || [null, null];

    return (
        <>
            <InnerItemCard bodyClassName="h-100 d-flex flex-column p-4">
                <span className="pnt-txt s-3">{t(header)}</span>
                <div className="pnt-divider horizon-line" />
                <div className="d-flex justify-content-between align-center my-auto">
                    <CountContainer onClick={handleDashboardClick}>
                        <p className="pnt-txt txt-bold s-1">{total || DEFAULT_VALUE}</p>
                    </CountContainer>
                    <div>
                        {Object.entries(status).map(
                            ([value, count]) =>
                                !TOTAL_KEY.includes(value) && (
                                    <Label
                                        key={`${value}_${count}`}
                                        className="pnt-label-6"
                                        labelGroupClassName="pnt-txt s-7"
                                        name={t(value)}
                                        value={<span className="w-100 text-right pnt-txt s-7 txt-bold">{count}</span>}
                                    />
                                ),
                        )}
                    </div>
                </div>
            </InnerItemCard>

            <NotExistScreenModal />
            <MoveConfirmModal />
        </>
    );
};

const CountContainer = styled.div`
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;

export default React.memo(DashboardCard);
