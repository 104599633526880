import React from 'react';
import useTranslation from '@hooks/useTranslation';
import WidgetSettingModal from '../../Components/WidgetSettingModal';

const VitalSignsRealTimePartronPoCSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('VitalSignsRealTimePartron');
    return (
        <WidgetSettingModal
            headerTitle={t('Real-time monitoring of mildly ill patients(Partron) Setting')}
            widgetInfo={widgetInfo}
            hiddenHeader
            {...restProps}
        />
    );
};

export default VitalSignsRealTimePartronPoCSetting;
