import { reqDelete, reqGet, reqPost, reqPut } from '../index';
import { YN, UnixTimestamp, NullableNumber, Nullable, NullableString, NullableUnixTimestamp } from '@util/type/util';
import { RequestHandlerCreator, PageInfoType, PageInfoParam } from '@api/util';

type ConditionType = 'static' | 'dynamic';

type AccessScope = 'R' | 'W';

interface FilterCondition {
    conditionId: string;
    conditionType: ConditionType;
    isEditable: YN;
    conditionValues: string[];
}

interface FilterAccessRight {
    userNum: NullableNumber;
    groupNum: NullableNumber;
    accessScope: Nullable<AccessScope>;
}

interface FilterInfo {
    categoryCodes: string;
    comNum: number;
    description: string;
    filterInfoAccessRight: FilterAccessRight[];
    filterInfoCondition: FilterCondition[];
    filterName: string;
    filterNum: number;
    isApplyMenu: YN;
    isFavorite: YN;
    modDate: NullableUnixTimestamp;
    regDate: UnixTimestamp;
}

interface FilterListParam extends PageInfoParam {
    isApplyMenu?: YN;
    isFavorite?: YN;
    opt?: 'filterName';
    keyword?: NullableString;
}

interface FilterParam {
    filterNum: number;
}

interface CreateFilterParam
    extends Pick<FilterInfo, 'categoryCodes' | 'description' | 'filterInfoCondition' | 'filterName'> {}

interface UpdateFilterParam extends Omit<FilterInfo, 'comNum' | 'filterInfoAccessRight' | 'modeDate' | 'regDate'> {}

export const getFilterListApi: RequestHandlerCreator<PageInfoType<FilterInfo>, FilterListParam> = param =>
    reqGet('/v3/api/common/filter/info', param);

export const createFilterApi: RequestHandlerCreator<{}, CreateFilterParam> = param =>
    reqPost('/v3/api/common/filter/info', param);

export const copyFilterApi: RequestHandlerCreator<{}, FilterParam> = param =>
    reqPut(`/v3/api/common/filter/info/copy/${param.filterNum}`, param);

export const getSelectedFilterApi: RequestHandlerCreator<FilterInfo, FilterParam> = param =>
    reqGet(`/v3/api/common/filter/info/${param.filterNum}`);

export const deleteFilterApi: RequestHandlerCreator<{}, FilterParam> = param =>
    reqDelete(`/v3/api/common/filter/info/${param.filterNum}`);

export const editFilterApi: RequestHandlerCreator<{}, UpdateFilterParam> = ({ filterNum, ...restParam }) =>
    reqPut(`/v3/api/common/filter/info/${filterNum}`, restParam);
