import React, { useContext, useEffect, useState } from 'react';
import { components, GroupBase, MenuListProps, OptionProps, ValueContainerProps } from 'react-select';
import { MenuPortalProps } from 'react-select/dist/declarations/src/components/Menu';
import styled from 'styled-components';

import useTranslation from '@hooks/useTranslation';
import { OptionType } from '@components/type';
import { CommonMenuList } from '@components/Select/Components/Common';
import { SearchableSelectContext } from '@components/Select/SearchableSelect/index';
import { getChildrenTypeNode } from '@components/Select/utils';

interface MenuListInputProps {
    onMenuInputChange: (inputValue: string) => void;
    onMenuInputFocusToggle: () => void;
}
export const MenuListInput = ({ onMenuInputChange, onMenuInputFocusToggle }: MenuListInputProps) => {
    const t = useTranslation('Search');
    return (
        <MenuInputContainer>
            <MenuInput
                onChange={event => {
                    if (typeof onMenuInputChange === 'function') {
                        onMenuInputChange(event.currentTarget.value);
                    }
                }}
                onMouseDown={event => {
                    event.stopPropagation();
                    event.currentTarget.focus();
                }}
                onFocus={onMenuInputFocusToggle}
                onBlur={onMenuInputFocusToggle}
                placeholder={t('Search')}
            />
            <DropdownIndicator />
        </MenuInputContainer>
    );
};

export const DropdownIndicator = () => (
    <div className={'select-dropdown-indicator d-flex justify-content-end'}>
        <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation">
            <path
                d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    </div>
);

export const ValueContainer = ({ children, ...restProps }: ValueContainerProps<OptionType>) => {
    const { title, labelKey } = useContext(SearchableSelectContext);
    const { getValue, options, selectProps } = restProps;
    const { placeholder: originPlaceholder, translator = (text: string) => text } = selectProps;

    const t = useTranslation('TreeSelect');
    const selected = getValue();
    const selectedLength = selected ? selected.length : 0;
    const optionsLength = options ? options.length : 0;
    const placeholder = () => {
        if (!selectedLength) {
            return title ? `${title} : ${t('All')}` : originPlaceholder || t('Select', 'Select');
        }
        if (selectedLength === 1) {
            return translator(selected[0][labelKey]);
        }
        if (selectedLength === optionsLength) {
            return title ? `${title} : ${t('All')}` : t('All');
        }
        return (
            <>
                <span>{translator(selected[0][labelKey])}</span>
                <span className="text-nowrap pnt-txt">
                    &nbsp;{t('other')} {selectedLength - 1}
                </span>
            </>
        );
    };

    const { otherChildren: ValueContainerWithoutPlaceholder } = getChildrenTypeNode(children, 'Placeholder');
    return (
        <components.ValueContainer {...restProps}>
            <div
                className={'react-select__placeholder text-ellipsis'}
                style={{ gridArea: '1/1/2/3', boxSizing: 'border-box' }}
            >
                <strong className={'w-100'}>{placeholder()}</strong>
            </div>
            {ValueContainerWithoutPlaceholder}
        </components.ValueContainer>
    );
};

export const MultiSelectMenuList = ({ children, ...restProps }: MenuListProps<OptionType>) => {
    const selected = restProps.getValue();
    const { valueKey, labelKey } = useContext(SearchableSelectContext);

    return (
        <CommonMenuList
            selected={selected}
            valueKey={valueKey}
            labelKey={labelKey}
            handleSelectedClick={option =>
                restProps.setValue(
                    selected.filter(selectedOption => selectedOption[valueKey] !== option[valueKey]),
                    'select-option',
                )
            }
            allOptions={restProps.options}
            {...restProps}
        >
            <div className={'select__options-list'}>{children}</div>
        </CommonMenuList>
    );
};

export const MultiSelectOption = function ({ label, isSelected, ...restProps }: OptionProps<OptionType>) {
    const {
        selectProps: { translator = (text: string) => text },
    } = restProps;
    return (
        <components.Option
            className={'styled-option select__option'}
            {...restProps}
            label={label}
            isSelected={isSelected}
        >
            <label className={'pnt-checkbox form-h-small'} htmlFor={label} title={label}>
                <input id={label} type="checkbox" checked={isSelected} onChange={() => null} />
                <span className="checkbox-effect" />
            </label>
            <span className="overflow-hidden text-ellipsis">{translator(label)}</span>
        </components.Option>
    );
};

export const MenuPortal = ({ children, ...restProps }: MenuPortalProps<OptionType, boolean, GroupBase<OptionType>>) => {
    const { controlElement, getValue } = restProps;
    const selected = getValue();
    const [position, setPosition] = useState({});
    useEffect(() => {
        setPosition(prev => ({
            ...prev,
            left: controlElement?.getBoundingClientRect().left,
            top: controlElement?.getBoundingClientRect().bottom,
            width: controlElement?.getBoundingClientRect().width,
        }));
    }, [selected]);

    return (
        <components.MenuPortal
            {...restProps}
            innerProps={{
                style: {
                    ...position,
                },
            }}
        >
            {children}
        </components.MenuPortal>
    );
};

const MenuInputContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 0.5rem;
    input:focus,
    input:active,
    input:focus-visible {
        filter: none !important;
        outline: none;
        border: none !important;
    }
    border-bottom: 1px solid #ebeaed;
    font-size: 0.8rem;
`;

const MenuInput = styled.input`
    max-width: 100%;
    width: 100%;
    height: 100%;
    border: none;
    background-color: inherit;
    border-radius: 2px;
    box-sizing: border-box;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
    &::placeholder {
        white-space: nowrap;
        font-weight: 400;
    }
`;
