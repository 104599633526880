import { GroupBase, StylesConfig } from 'react-select';
import { OptionType } from '@components/type';

export const DEFAULT_HEIGHT = '1.75rem';

export const defaultStyle: StylesConfig<OptionType, boolean, GroupBase<OptionType>> = {
    container: (provided, state) => ({
        ...provided,
        minWidth: '11.5rem',
        maxWidth: '14rem',
        height: DEFAULT_HEIGHT,
    }),
    control: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        height: DEFAULT_HEIGHT,
        minHeight: DEFAULT_HEIGHT,
        // border: "none",
        // borderBottom: "1px solid white",
        // backgroundColor: "rgba(255, 255, 255, 0.2)"
    }),
    singleValue: (provided, state) => ({
        ...provided,
        lineHeight: 1,
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        padding: '6px',
    }),
    option: (provided, state) => {
        return {
            ...provided,
            padding: '4px 8px',
            color: '#000 !important',
            backgroundColor: state.isSelected ? '#FFE3B8' : state.isFocused ? '#EBEBEB' : '#fff',
        };
    },
};
