import React from 'react';
import ItemCount from './ItemCount';
import { useTranslation } from '@hooks';
import InnerItemCard from '@components/Card/InnerItemCard';
import cx from 'classnames';
import styled from 'styled-components';
import { MEDICAL_WASTE, WASTE_TYPE_LABEL } from '../../../../constants';

const DEFAULT_COUNT = 0;

const MedicalWasteSummary = ({ className, title, data }) => {
    const t = useTranslation(MEDICAL_WASTE);

    const summaryData = [
        { label: t('Total'), value: data?.total ?? DEFAULT_COUNT },
        { label: t(WASTE_TYPE_LABEL.isolatedMedical), value: data?.isolatedMedical ?? DEFAULT_COUNT },
        { label: t(WASTE_TYPE_LABEL.normalMedical), value: data?.normalMedical ?? DEFAULT_COUNT },
        { label: t(WASTE_TYPE_LABEL.tissue), value: data?.tissue ?? DEFAULT_COUNT },
        { label: t(WASTE_TYPE_LABEL.damage), value: data?.damage ?? DEFAULT_COUNT },
        { label: t(WASTE_TYPE_LABEL.pathology), value: data?.pathology ?? DEFAULT_COUNT },
        { label: t(WASTE_TYPE_LABEL.biochemistry), value: data?.biochemistry ?? DEFAULT_COUNT },
        { label: t(WASTE_TYPE_LABEL.bloodContamination), value: data?.bloodContamination ?? DEFAULT_COUNT },
        { label: t(WASTE_TYPE_LABEL.humanTissue), value: data?.humanTissue ?? DEFAULT_COUNT },
    ];

    return (
        <div className={cx('d-flex', className)}>
            <InnerItemCard bodyClassName="grid-container item-grid-150 h-100">
                <div className="widget-item-nums h-100">
                    <TitleContainer className={cx('flex-center col')}>
                        <div className={'pnt-txt s-5 txt-bold text-wrap'}>{title}</div>
                    </TitleContainer>
                </div>
            </InnerItemCard>
            <InnerItemCard className={'flx-1 ml-3'} bodyClassName="grid-container item-grid-150">
                {summaryData.map(({ label, value }, idx) => (
                    <div className="widget-item-nums text-center" key={`${label}_${idx}`}>
                        <ItemCount
                            label={label}
                            className={t('Total') === label && 'bg-depth-9'}
                            start={DEFAULT_COUNT}
                            end={value}
                        />
                    </div>
                ))}
            </InnerItemCard>
        </div>
    );
};

const TitleContainer = styled.div`
    width: 150px;
`;

export default MedicalWasteSummary;
