import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    filterInfo: {},

    // 필터 편집 화면일 경우: true
    // 필터 편집 화면이 아닐 경우: false, ex) 필터 상세 화면, 메인 화면
    // 편집 화면에서 '편집 가능 여부' 옵션에 상관없이 옵션 선택이 가능하게 할 때 사용됨
    filterEditMode: false,

    // 필터 사용 화면일 경우: true, ex) 메인 화면
    // 필터 사용 화면이 아닐 경우: false, ex) 필터 상세 화면, 필터 편집 화면
    // 메인 화면에서 로그인, 로그인 그룹 속성 옵션을 실제 로그인한 유저 정보로 대체할 때 사용
    monitoringMode: true,
};

const { actions, reducer } = createSlice({
    name: 'filterWidget',
    initialState,
    reducers: {
        setInitialInfo: (state, action) => {
            const { filterInfo, filterEditMode, monitoringMode } = action.payload;
            state.filterInfo = filterInfo ?? {};
            state.filterEditMode = !!filterEditMode;
            state.monitoringMode = !!monitoringMode;
        },
        setFilterInfo: (state, action) => {
            state.filterInfo = action.payload ?? {};
        },
    },
});

export const { setFilterInfo } = actions;
export default reducer;
