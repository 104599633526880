import React, { useContext, useEffect, useState } from 'react';
import useTranslation from '@hooks/useTranslation';
import { Button, TextInput } from '@components';
import { FilterListContext } from './index';

const SEARCH_OPTION = 'filterName';

const Search = ({ fetchListData }) => {
    const t = useTranslation('Filter');
    const { refreshFlag } = useContext(FilterListContext);

    const [searchInput, setSearchInput] = useState('');

    const handleRefreshClick = () => {
        setSearchInput('');
        fetchListData();
    };

    const handleSearchClick = () => {
        if (searchInput && searchInput.trim()) {
            fetchListData({
                opt: SEARCH_OPTION,
                keyword: searchInput,
            });
        } else {
            fetchListData();
        }
    };

    const handleInputChange = e => {
        const { value } = e.currentTarget;
        setSearchInput(value);
    };

    useEffect(() => {
        if (refreshFlag) {
            handleRefreshClick();
        }
    }, [refreshFlag]);

    return (
        <div className="flx-row">
            <p className="pnt-txt txt-bold mr-4">{t('Search', 'Search')}</p>
            <TextInput
                type={'text'}
                size={60}
                placeholder={t('Input Filter Name')}
                name={'name'}
                value={searchInput}
                handleChange={handleInputChange}
            />
            <Button className={'btn-darkgray btn-icon'} iconName="search" onClick={handleSearchClick}>
                {t('Search', 'Search')}
            </Button>
            <Button className="btn-lightgray btn-icon-only ml-2" iconName="refresh" onClick={handleRefreshClick} />
        </div>
    );
};

export default Search;
