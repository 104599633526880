import React from 'react';
import { Table } from '@components';
import { useAsync, useColumns, useTranslation } from '@hooks';
import * as column from '@util/grid/column';
import styled from 'styled-components';
import Search from '../../../Components/Search';
import cx from 'classnames';
import { ASSET_CODE, DEFAULT_LABEL_VALUE, DEFAULT_PARAMETERS, SIGNAL_STATUS, SPECIFIC_COLUMNS } from '../../constants';
import InnerItemCard from '@components/Card/InnerItemCard';
import { ASSET_MANAGEMENT } from '../../../../constants';
import { getIotItemApi } from '@api/common/asset';
import { useSelector } from 'react-redux';

const AssetList = ({ list, handleRowClick, handleReset, handleListParam, handleListSearch, isLoading, tableRef }) => {
    const t = useTranslation(ASSET_MANAGEMENT);
    const { categoryPropertiesList } = useSelector(state => state.CategoryInfo);

    // list에서 표시할 선택된 카테고리 속성
    const properties = categoryPropertiesList
        .filter(property => property.categoryCode === ASSET_CODE && property.isListProperty === 'Y')
        .sort((a, b) => a.sortOrder - b.sortOrder);

    // TODO: Asset isListProperty 값이 IoT item status widget 과 동일함 관련된 내용 구분이 필요함
    // 선택된 카테고리의 list 속성 항목
    const currentPropertyColumns = properties
        .filter(({ propertyId }) => SPECIFIC_COLUMNS.includes(propertyId))
        .map(({ displayName, propertyId, inputValues }) => {
            return {
                Header: displayName,
                accessor: `properties.${propertyId}`,
                className: 'text-ellipsis',
                width: 80,
                Cell: row => {
                    const { value } = row;
                    if (value == null) {
                        return DEFAULT_LABEL_VALUE;
                    }
                    if (!inputValues.length) {
                        return value;
                    }
                    const findValue = inputValues.find(({ value: inputValue }) => inputValue === value);
                    return findValue != null ? findValue.name : DEFAULT_LABEL_VALUE;
                },
            };
        });

    const { promise: getIotItem } = useAsync({
        promise: getIotItemApi,
        resolve: res => {
            handleRowClick(res);
        },
        reject: err => console.error(err),
    });

    // Table Row 선택
    const handleIoTItemClick = selected => {
        const { targetNum } = selected;
        getIotItem({ targetNum });
    };

    // Table columns
    const columns = useColumns(
        [
            column.index({ width: 40 }),
            column.categoryName({ width: 200 }),
            column.targetName({ Header: t('Asset Name'), width: 270 }),
            column.targetId({ Header: t('Asset ID'), className: 'text-ellipsis text-uppercase' }),
            ...currentPropertyColumns,
            column.floorName({
                width: 70,
                accessor: 'floorName',
                Cell: ({ value }) => (value ? value : DEFAULT_LABEL_VALUE),
            }),
            column.location({
                width: 100,
                Cell: ({ row }) => {
                    const { original } = row;
                    const { status } = original;
                    let classNames = '';

                    if (
                        status?.includes(SIGNAL_STATUS.LOST_SIGNAL) ||
                        status?.includes(SIGNAL_STATUS.OUT_OF_AUTHORIZED_ZONE)
                    ) {
                        classNames = 'bg-danger color-white text-ellipsis';
                    } else if (!status) {
                        classNames = 'bg-depth-9';
                    }
                    return original.geofences ? (
                        <span className={cx('h-100 flx-col flx-center', classNames)}>
                            {original.geofences.map(geofence => geofence.fcName).join(', ')}
                        </span>
                    ) : (
                        <span className={cx('h-100 flx-col flx-center', classNames)}>-</span>
                    );
                },
            }),
            column.signalStatus({
                width: 150,
                Cell: ({ value }) => {
                    const statusName = value?.map(status => t(status)).join(', ') || DEFAULT_LABEL_VALUE; // 다국어 처리
                    const critical =
                        value?.includes(SIGNAL_STATUS.LOW_BATTERY) || value?.includes(SIGNAL_STATUS.NO_RESPONSE);
                    const warning =
                        value?.includes(SIGNAL_STATUS.OUT_OF_AUTHORIZED_ZONE) ||
                        value?.includes(SIGNAL_STATUS.LOST_SIGNAL);
                    let classNames = '';
                    if (!value) {
                        classNames = 'bg-depth-9';
                        // 조건 1: 배터리 부족, 응답없음
                    } else if (critical) {
                        classNames = 'bg-brand color-white text-ellipsis';
                        // 조건 2: 신호상실, 비인가지역 진입
                    } else if (warning) {
                        classNames = 'bg-info text-ellipsis';
                    }
                    return (
                        <span title={statusName} className={cx('h-100 flx-col flx-center', classNames)}>
                            {statusName}
                        </span>
                    );
                },
            }),
        ],
        null,
        [],
    );

    return (
        <InnerItemCard
            header={{
                title: t('Asset List'),
            }}
            className="mt-3"
        >
            <div className="d-flex gap-2 w-100">
                <Search handleReset={handleReset} handleSearchClick={handleListSearch} isLoading={isLoading} />
            </div>
            <BodyContainer ref={tableRef}>
                <Table
                    columns={columns}
                    data={list ? { ...DEFAULT_PARAMETERS, ...list } : { ...DEFAULT_PARAMETERS, rows: [] }}
                    onTrClick={handleIoTItemClick}
                    autoResetPage={false}
                    loading={isLoading}
                    onPageChange={handleListParam}
                />
            </BodyContainer>
        </InnerItemCard>
    );
};

const BodyContainer = styled.div`
    height: 33rem;
`;

export default AssetList;
