import React from 'react';
import cx from 'classnames';

const Nameplate = ({ name = '', icon = '', className }) => {
    return (
        <div className={cx('pnt-nameplate', className)}>
            {icon && <span className="material-icons-round">{icon}</span>}
            <div className="cont-wrap">{name}</div>
        </div>
    );
};

export default Nameplate;
