import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Control from 'react-leaflet-control';

import { Button, Card, GeofenceLayer, Map, RotatedImageOverlay, Table } from '@components';
import { useAsync, useColumns, useTranslation } from '@hooks';
import { fetchGeofenceList } from '@api/common/geofence';
import { fetchCongestionStatusApi } from '@api/statistics';
import SingleTreeSelect from '@components/Select/SingleTreeSelect';

import CongestionFilterGroup from './Component/CongestionFilterGroup';
import SelectedCongestionSummary from './Component/SelectedCongestionSummary';
import AllCongestionSummary from './Component/AllCongestionSummary';
import WidgetCard from '../../Components/WidgetCard';
import { useSettings } from '../../util/useSettings';
import {
    setEndDate,
    setSelectedCategory,
    setSelectedFloor,
    setSelectedFloorInfo,
    setStartDate,
} from './congestionStatusSlice';
import * as column from '../../../MainPages/util/grid/column';
import { defaultCongestionInfo, defaultSelectedCongestionInfo } from './constants';
import { useCongestionStatusContext, useCongestionStatusDispatch } from './index';
import { selectFirstFloor } from '@reducer/Common/FloorInfo';

const CongestionStatusContainer = ({ widgetInfo, children, ...restProps }) => {
    const t = useTranslation('CongestionStatus');
    const { config } = widgetInfo;
    const { hiddenMapTile } = useSettings(config);
    const { floorList } = useSelector(state => state.FloorInfo);
    const dispatch = useCongestionStatusDispatch();
    const state = useCongestionStatusContext();

    const [showSearchArea, setShowSearchArea] = useState(false);
    const [selectedGeofence, setSelectedGeofence] = useState({});
    const [geofenceInfo, setGeofenceInfo] = useState([]);
    const [filterParam, setFilterParam] = useState({});
    const [congestionInfo, setCongestionInfo] = useState([]);
    const [selectedGeofenceCongestionInfo, setSelectedGeofenceCongestionInfo] = useState({ ...defaultCongestionInfo });

    const { floor, floorId } = selectFirstFloor(floorList);
    const { selectedCategory, selectedFloor, selectedFloorInfo, startDate, endDate } = state;
    const confirmedTile = hiddenMapTile !== undefined ? !hiddenMapTile : true;

    const { promise: getGeofenceList } = useAsync({
        promise: fetchGeofenceList,
        resolve: res => {
            if (res) {
                setGeofenceInfo(
                    res.rows.map(geofence => {
                        return {
                            ...geofence,
                            bounds: [geofence.latLngList.map(({ lat, lng }) => [lat, lng])],
                        };
                    }),
                );
            }
        },
    });

    const { promise: getCongestionStatus } = useAsync({
        promise: fetchCongestionStatusApi,
        resolve: res => {
            setCongestionInfo(res);
        },
    });

    const columns = useColumns([
        column.geofenceName({ width: 50 }),
        column.countOfEntries({ width: 50 }),
        column.countOfUnique({ width: 50 }),
        column.averageStayOfEntry({ width: 50 }),
        column.averageStayOfUnique({ width: 50 }),
    ]);

    const handleSearchAreaClick = () => {
        setShowSearchArea(!showSearchArea);
    };
    const handleRefreshClick = () => {
        dispatch(setSelectedCategory(''));
        dispatch(setSelectedFloor(floorId));
        dispatch(setStartDate(moment().startOf('day').valueOf()));
        dispatch(setEndDate(moment().endOf('day').valueOf()));
        dispatch(setSelectedFloorInfo(floor));
    };

    const handleSearchClick = e => {
        if (selectedFloor && startDate && endDate) {
            getCongestionStatus({
                ...filterParam,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
        }
        setShowSearchArea(false);
    };

    const handleFloorChange = selects => {
        if (selects) {
            dispatch(setSelectedFloor(selects.floorId));
            dispatch(setSelectedFloorInfo(selects));
        }
    };

    useEffect(() => {
        if (floorList[0]) {
            dispatch(setSelectedFloor(floorId));
            dispatch(setSelectedFloorInfo(floor));
            getGeofenceList({ floor: floorId });
        }
    }, []);

    useEffect(() => {
        if (selectedFloorInfo.floorName) {
            getCongestionStatus({
                floorId: selectedFloorInfo.floorId,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
        }
    }, [selectedFloorInfo]);

    useEffect(() => {
        if (selectedFloor) {
            getCongestionStatus({
                ...filterParam,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
            getGeofenceList({ floor: selectedFloor });
            setSelectedGeofenceCongestionInfo({});
        }
    }, [filterParam]);

    useEffect(() => {
        const paramInfo = {};
        if (selectedCategory) {
            paramInfo.categoryCode = selectedCategory;
        }
        if (selectedFloor) {
            paramInfo.floorId = selectedFloor;
        }
        setFilterParam(paramInfo);
    }, [selectedCategory, selectedFloor]);

    useEffect(() => {
        let foundOne = {};

        if (selectedGeofence.fcNum) {
            foundOne = congestionInfo.geofenceList.find(geofence => geofence.fcNum === selectedGeofence.fcNum);
            if (foundOne) {
                setSelectedGeofenceCongestionInfo(foundOne);
            } else {
                setSelectedGeofenceCongestionInfo({ fcNum: selectedGeofence.fcNum, ...defaultSelectedCongestionInfo });
            }
        } else {
            setSelectedGeofenceCongestionInfo({});
        }
    }, [selectedGeofence]);

    const tableHeight = !congestionInfo.geofenceList?.length
        ? '15rem'
        : (31 + congestionInfo.geofenceList.length * 36.4).toString() + 'px';

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            {...restProps}
            headerAction={
                <>
                    <Button className={'btn-darkgray'} onClick={handleSearchAreaClick}>
                        {t('Search', 'Button')}
                    </Button>
                    <div className="pnt-border border-h" />
                    <Button className="btn-lightgray btn-icon-only" iconName="refresh" onClick={handleRefreshClick} />
                </>
            }
            searchFilter={showSearchArea && <CongestionFilterGroup handleSearchClick={handleSearchClick} />}
        >
            <div className={'grid-container w-100 h-100'} style={{ gridTemplateColumns: '1fr 1fr' }}>
                <div className={'h-100 w-100'}>
                    <Map tile={confirmedTile} rotation={selectedFloorInfo?.rotation}>
                        {selectedFloorInfo && selectedFloorInfo.floorName && (
                            <Control position="topleft">
                                <SingleTreeSelect
                                    data={floorList}
                                    title={'Category'}
                                    valueKey={'floorId'}
                                    labelKey={'floorName'}
                                    parentKey={'upperFloorId'}
                                    selected={selectedFloor}
                                    onChange={handleFloorChange}
                                    isMap
                                />
                            </Control>
                        )}
                        {selectedFloorInfo.imgURL && (
                            <RotatedImageOverlay
                                key={selectedFloorInfo.floorId}
                                url={selectedFloorInfo.imgURL}
                                deg={selectedFloorInfo.deg}
                                bounds={selectedFloorInfo.bounds}
                                onLoad={e => {
                                    const { target: layer } = e;
                                    layer._map.fitBounds(layer.getBounds());
                                }}
                            />
                        )}
                        {geofenceInfo.length > 0 && (
                            <GeofenceLayer
                                selectedGeofence={selectedGeofence}
                                geofenceList={geofenceInfo}
                                handleClick={geofence => {
                                    geofence.fcNum === selectedGeofence.fcNum
                                        ? setSelectedGeofence({})
                                        : setSelectedGeofence(geofence);
                                }}
                            />
                        )}
                    </Map>
                </div>
                <Card className={'p-0'}>
                    {selectedGeofenceCongestionInfo.fcNum ? (
                        <SelectedCongestionSummary selectedCongestionInfo={selectedGeofenceCongestionInfo} />
                    ) : (
                        <AllCongestionSummary congestionInfo={congestionInfo} />
                    )}

                    <div style={{ marginTop: '11px', height: tableHeight }}>
                        <Table
                            paging={false}
                            data={{
                                rows: congestionInfo.geofenceList ? congestionInfo.geofenceList : [],
                            }}
                            textAlign={'left'}
                            columns={columns}
                            className={'table-for-viewer'}
                        />
                    </div>
                </Card>
            </div>

            {children}
        </WidgetCard>
    );
};

export default CongestionStatusContainer;
