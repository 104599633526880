import React from 'react';
import InnerItemCard from '@components/Card/InnerItemCard';
import ItemCount from './ItemCount';

const LIST = ['todayReception'];
const OutpatientTodayCounts = ({ todayReceptionCountStat }) => {
    return (
        <InnerItemCard>
            <div className="widget-item-nums">
                {LIST.map(status => {
                    return <ItemCount key={status} start={0} end={todayReceptionCountStat[status]} label={status} />;
                })}
            </div>
        </InnerItemCard>
    );
};

export default OutpatientTodayCounts;
