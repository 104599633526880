import React from 'react';
import { PntChart } from '@components/Charts/types';
import { Doughnut, Pie } from 'react-chartjs-2';
import useChartData from '@components/Charts/util/useChartData';
import useChartOptions from '@components/Charts/util/useChartOptions';
import { ChartData, ChartOptions, Plugin } from 'chart.js';
import { mergeObjects } from '@util/common/util';
import DoughnutInnerText from '@components/Charts/Plugins/DoughnutInnerText';

const defaultPieOptions: ChartOptions<'pie'> = {
    plugins: { legend: { position: 'right' } },
    scales: { x: { display: false }, y: { display: false } },
};

const doughnutDefaultOption: ChartOptions<'doughnut'> = mergeObjects(defaultPieOptions, {
    cutout: '75%', // 단위 %
});

const PieChart = ({ data, options, plugins = [], type = 'pie' }: PntChart<'pie' | 'doughnut'>) => {
    const chartData = useChartData(data);
    const chartOptions = useChartOptions<'pie' | 'doughnut'>(
        type === 'pie' ? defaultPieOptions : doughnutDefaultOption,
    );
    return (
        <>
            {type === 'pie' ? (
                <Pie
                    data={chartData as ChartData<'pie'>}
                    options={mergeObjects(chartOptions, options)}
                    plugins={plugins as Plugin<'pie'>[]}
                />
            ) : (
                <Doughnut
                    data={chartData as ChartData<'doughnut'>}
                    options={mergeObjects(chartOptions, options)}
                    plugins={[...plugins, DoughnutInnerText] as Plugin<'doughnut'>[]}
                />
            )}
        </>
    );
};

export default PieChart;
