import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import L from 'leaflet';
import { Marker } from 'react-leaflet';
import { Label, Map, RotatedImageOverlay } from '@components';

import { useAsync, useTranslation } from '@hooks';

import { useSelector } from 'react-redux';
import { getIotItemApi } from '@api/common/asset';
import Bookmark from '../../../Components/Bookmark';
import moment from 'moment';

const DEFAULT_VALUE = '-';

const IotItemStatusDetails = ({
    parentCategoryCode,
    selectedIotItem,
    setSelectedIotItem,
    status,
    geofence,
    beaconName,
    bookmarkSuccessCallback,
    hiddenMapTile,
}) => {
    const t = useTranslation('IotItemStatus');
    const mapRef = useRef();
    const { categoryPropertiesList } = useSelector(state => state.CategoryInfo);
    const { floorList } = useSelector(state => state.FloorInfo);

    const confirmedTile = hiddenMapTile !== undefined ? !hiddenMapTile : true;

    const [propertiesList, setPropertiesList] = useState([]);
    useEffect(() => {
        const refineProperties = categoryPropertiesList
            .filter(({ categoryCode }) => parentCategoryCode === categoryCode)
            .sort((a, b) => a.sortOrder - b.sortOrder);
        setPropertiesList(refineProperties);
    }, [categoryPropertiesList, parentCategoryCode]);

    const { promise: getAsset } = useAsync({
        promise: getIotItemApi,
        resolve: res => {
            setSelectedIotItem(res);
        },
        reject: err => console.error(err),
    });

    const [floorInfo, setFloorInfo] = useState({});
    useEffect(() => {
        if (selectedIotItem) {
            const { floorId } = selectedIotItem;
            setFloorInfo(floorList.find(floor => floor.floorId === floorId));
        }
    }, [selectedIotItem, floorList]);

    const successCallback = () => {
        getAsset({ targetNum });
        bookmarkSuccessCallback();
    };

    const {
        categoryCode,
        targetId,
        targetName,
        targetNum,
        isBookmark,
        floorName,
        lastDate,
        properties,
        lat,
        lng,
    } = selectedIotItem;

    const propertiesLabel = ({ comNum, displayName, propertyId, inputValues }) => {
        // 속성값이 없는 경우
        if (properties[propertyId] == null) {
            return <Label key={`${propertyId}_${comNum}`} name={displayName} value={DEFAULT_VALUE} />;
        }
        // 속성값 타입이 단일인 경우
        if (!inputValues.length) {
            return <Label key={`${propertyId}_${comNum}`} name={displayName} value={properties[propertyId]} />;
        }
        // 속성값 타입이 다중인 경우
        const findValue = inputValues.find(({ value: inputValue }) => inputValue === properties[propertyId]);
        if (findValue == null) {
            return <Label key={`${propertyId}_${comNum}`} name={displayName} value={DEFAULT_VALUE} />;
        }
        return <Label key={`${propertyId}_${comNum}`} name={displayName} value={findValue.name ?? DEFAULT_VALUE} />;
    };

    return (
        <Container>
            <div className={'p-2'}>
                <div className="flx-col gap-4 pnt-label-5">
                    <Label
                        name={t('Category')}
                        value={
                            <Set>
                                <span title={categoryCode}>{categoryCode}</span>
                                <Bookmark
                                    isBookmark={isBookmark}
                                    targetNum={targetNum}
                                    successCallback={successCallback}
                                />
                            </Set>
                        }
                    />
                    <Label name={t('Name')} value={targetName} />

                    <div className="pnt-divider horizon-line" />

                    {propertiesList.map(property => propertiesLabel(property))}

                    <div className="pnt-divider horizon-line" />

                    <Label name={t('Tag Name')} value={beaconName} />

                    <Label name={t('Floor')} value={floorName} />

                    <Label name={t('Location')} value={geofence} />

                    <Label name={t('Status')} value={status} />

                    <Label
                        name={t('Recognition Time')}
                        value={<>{lastDate ? moment.unix(lastDate).format('YYYY-MM-DD HH:mm:ss') : DEFAULT_VALUE}</>}
                    />
                </div>
            </div>
            <div className={'border-lightgray'}>
                <Map tile={confirmedTile} ref={mapRef} rotation={floorInfo?.rotation}>
                    {floorInfo && floorInfo.imgURL && (
                        <RotatedImageOverlay
                            key={floorInfo.floorId}
                            url={floorInfo.imgURL}
                            deg={floorInfo.deg}
                            bounds={floorInfo.bounds}
                            onLoad={() => {
                                const map = mapRef.current.leafletElement;
                                if (map) {
                                    map.setView([lat, lng]);
                                }
                            }}
                        />
                    )}

                    <Marker
                        key={targetId}
                        position={[lat, lng]}
                        icon={L.divIcon({
                            className: 'custom-asset-marker',
                            html: `<div></div>`,
                        })}
                    />
                </Map>
            </div>
        </Container>
    );
};
const Set = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

const Container = styled.div`
    display: grid;
    height: 100%;
    grid-gap: 10px;
    grid-template-columns: 30% calc(70% - 10px);
`;
export default IotItemStatusDetails;
