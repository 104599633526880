import React from 'react';
import useTranslation from '@hooks/useTranslation';
import ItemCount from './ItemCount';
import { useSensorStatusContext, useSensorStatusDispatch } from '../../../index';
import { setCurrentStatus, setSensorListParam } from '../../../sensorStatusSlice';
import InnerItemCard from '@components/Card/InnerItemCard';

// 전체 상태 별 측정항목 수
const DEFAULT_DATA = [
    { key: 'total', label: 'Total' },
    { key: 'normal', label: 'Normal' },
    { key: 'warning', label: 'Warning' },
    { key: 'critical', label: 'Critical' },
    { key: 'outOfRange', label: 'OutOfRange' },
    { key: 'undefine', label: 'Undefined' },
];

/**
 * 전체 상태 별 측정항목 수
 *
 * @param statusCountData 전체 상태 별 측정항목 수
 *
 * @author jinbeom-jung
 */
const SensorStatusCount = ({ statusCountData }) => {
    const t = useTranslation('SensorStatus');
    const dispatch = useSensorStatusDispatch();
    const { currentStatus } = useSensorStatusContext();

    const handleCountClick = status => {
        const param = ['TOTAL', 'Total'].includes(status) ? { status: null } : { status: status.toUpperCase() };
        dispatch(setSensorListParam({ page: 1, ...param }));
        dispatch(setCurrentStatus(status));
    };

    return (
        <InnerItemCard header={{ title: t('Total Number of items by status') }}>
            <div className="widget-item-nums" style={{ height: 124 }}>
                {DEFAULT_DATA.map(({ key, label }) => {
                    return (
                        <ItemCount
                            key={key}
                            start={statusCountData.prev[key]}
                            end={statusCountData.curr[key]}
                            label={label}
                            status={label}
                            handleCountClick={handleCountClick}
                            currentStatus={currentStatus}
                        />
                    );
                })}
            </div>
        </InnerItemCard>
    );
};

export default React.memo(SensorStatusCount);
