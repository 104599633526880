import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanyInfo } from '@api/common/company';

interface AppConfig {
    markerConfig: MarkerConfig;
    markerConfigValue: {
        moveTransitionTime: number;
        className: string;
        defaultSize: number;
        minSize: number;
        maxSize: number;
    };
}

interface MarkerConfig
    extends Pick<
        CompanyInfo,
        | 'markerTransitionActive'
        | 'markerPulseActive'
        | 'markerBorderRadius'
        | 'markerPulseAnimation'
        | 'markerPulseRssi'
        | 'markerPulseColor'
    > {
    // 잘못된 API 응답 처리를 위한 타입
    [key: string]: any;
}

type MarkerConfigKeys = keyof MarkerConfig;

const initialState: AppConfig = {
    // 설정 된 값
    markerConfig: {
        markerTransitionActive: 'Y',
        markerPulseActive: 'Y',
        markerBorderRadius: 'C',
        markerPulseAnimation: 'U',
        markerPulseRssi: -50.0,
        markerPulseColor: '#009313',
    },

    // UI에 실제 적용될 값
    markerConfigValue: {
        moveTransitionTime: 250,
        className: 'circle pulse-U',
        defaultSize: 10,
        minSize: 2,
        maxSize: 23,
    },
};

const { actions, reducer } = createSlice({
    name: 'common/appConfig',
    initialState,
    reducers: {
        setMarkerConfig: (state, action: PayloadAction<MarkerConfig>) => {
            const defaultConfig = initialState.markerConfig;
            const config = action.payload;
            for (let propId in config) {
                if (config.hasOwnProperty(propId)) {
                    const stablePropId = propId as MarkerConfigKeys;
                    const configValue = config[stablePropId];
                    if (configValue === null || configValue === undefined || configValue === '') {
                        config[stablePropId] = defaultConfig[stablePropId];
                    }
                }
            }
            state.markerConfig = config;

            const className = [];
            let moveTransitionTime = 0;
            if (config.markerTransitionActive === 'Y') {
                moveTransitionTime = 250;
            }
            state.markerConfigValue.moveTransitionTime = moveTransitionTime;

            if (config.markerPulseActive === 'Y') {
                if (config.markerBorderRadius === 'C') {
                    className.push('circle');
                } else {
                    className.push('square');
                }

                className.push(`pulse-${config.markerPulseAnimation}`);
            }
            // className.push(config.markerPulseColor);

            state.markerConfigValue.className = className.join(' ');
        },
    },
});

export const { setMarkerConfig } = actions;
export default reducer;
