import React from 'react';
import { InputProps } from '@components/type';
import cx from 'classnames';

export interface CheckboxInputProps extends InputProps {
    checked?: boolean;
    handleChecked?: React.ChangeEventHandler<HTMLInputElement>;
    inputName?: string;
}

const Checkbox = ({
    name,
    id,
    className,
    disabled,
    checked,
    handleChecked,
    inputName,
    ...restProps
}: CheckboxInputProps) => {
    return (
        <label
            className={cx('pnt-checkbox', checked && 'check-checked', disabled && 'form-disable', className)}
            htmlFor={id}
        >
            <input name={inputName} type="checkbox" id={id} onChange={handleChecked} checked={checked} {...restProps} />
            <span className="checkbox-effect" />
            <p>{name}</p>
        </label>
    );
};

export default Checkbox;
