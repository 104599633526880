import { createSlice } from '@reduxjs/toolkit';
import { RECOVERY_STATE_INFO, isIn, getPermittedState } from '../../util/mappInfo';
import {
    convertState,
    setTagStatus,
    calcCategoryStatus,
    setTagAlarmList,
    setCompactGeofenceInOutState,
    setFloorInOutState,
    setGeofenceInOutState,
    setTagListByFloor,
    setTagList,
} from './processSocketData';

const initialState = {
    tagList: {},
    tagListByFloor: {},
    tagStatus: {
        sos: [],
        battery: [],
        leaveAlone: [],
        longStay: [],
        lostSignal: [],
        fireAlarm: [],
        restriction: [],
    },
    categoryStatus: {
        PEOPLE: [],
        SENSOR: [],
        ASSET: [],
    },
    tagAlarmList: [],
    floorInOutState: {},
    geofenceInOutState: {},
};

const updateMarkerProcess = (state, tagInfo) => {
    const { beaconNum, beaconName, restrictedZone, restrictedZonePermitted } = tagInfo.beacon;
    const { targetNum, targetId, targetName, categoryCode, parentCode } = tagInfo.target;
    const { floor, lat, lng } = tagInfo.location;
    const tagState = tagInfo.state;
    const floorInOutState = tagInfo.floorInOutState;
    const geofenceInOutState = tagInfo.geofencesInOutState;
    const [firstScanner] = tagInfo.scanner;
    // console.log({ convertState: convertState(tagState) });
    const compactTagInfo = {
        evtTime: tagInfo.timeStamp,
        beacon: { beaconNum, beaconName, restrictedZone, restrictedZonePermitted },
        target: { targetNum, targetId, targetName, categoryCode, parentCode },
        location: { floor, lat, lng, latLng: [lat, lng] },
        state: convertState(tagState),
        recovery: convertState(tagState, RECOVERY_STATE_INFO),
        floorInOutState: {
            floorId: floorInOutState.zoneId,
            floorName: floorInOutState.name,
            isIn: isIn(floorInOutState.state),
            inTime: floorInOutState.inTime,
            stayTime: floorInOutState.stayTime,
            outTime: floorInOutState.outTime,
            permitted: getPermittedState(floorInOutState.permitted),
        },
        geofenceInOutState: setCompactGeofenceInOutState(geofenceInOutState),
        scannerRssi: firstScanner ? firstScanner.rssi : null,
    };
    setTagStatus(state.tagStatus, compactTagInfo);
    calcCategoryStatus(state.categoryStatus, compactTagInfo);
    setTagAlarmList(state.tagAlarmList, compactTagInfo);
    setFloorInOutState(state.floorInOutState, compactTagInfo);
    setGeofenceInOutState(state.geofenceInOutState, compactTagInfo);
    setTagList(state.tagList, compactTagInfo);
};

const { actions, reducer } = createSlice({
    name: 'tagInfo',
    initialState,
    reducers: {
        setTagInfo: (state, action) => {
            const tagInfo = action.payload;
            updateMarkerProcess(state, tagInfo);
            setTagListByFloor(state);
        },
        setTagInfos: (state, action) => {
            const tagInfos = action.payload;
            for (let i in tagInfos) {
                const tagInfo = tagInfos[i];
                updateMarkerProcess(state, tagInfo);
            }
            setTagListByFloor(state);
        },
    },
});

export const { setTagInfo, setTagInfos } = actions;
export default reducer;
