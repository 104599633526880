import React from 'react';
import { Button, Modal } from '@components';
import { ModalFooter } from 'reactstrap';
import { CommonModalProps } from '@components/type';
import { useTranslation } from '@hooks';
import cx from 'classnames';

const AlertModal = ({
    initModal,
    toggleModal = () => {},
    headerTitle,
    bodyText,
    okCallback,
    callbackParam,
    okClassName,
    okButtonText,
    ...restProps
}: CommonModalProps) => {
    const t = useTranslation('ConfirmModal');

    return (
        <Modal
            initModal={initModal}
            toggleModal={toggleModal}
            headerTitle={headerTitle ?? t('Alert')}
            bodyText={bodyText}
            modalFooter={
                <ModalFooter>
                    <Button
                        className={cx(okClassName || 'btn-danger')}
                        onClick={() => {
                            if (typeof okCallback === 'function') {
                                okCallback(callbackParam);
                            }
                            toggleModal();
                        }}
                    >
                        {okButtonText ?? t('OK')}
                    </Button>
                </ModalFooter>
            }
            {...restProps}
        />
    );
};

export default AlertModal;
