import React from 'react';
import InnerItemCard from '@components/Card/InnerItemCard';
import { Col } from 'reactstrap';
import CountUp from 'react-countup';
import { useTranslation } from '@hooks';
import { MEDICAL_WASTE } from '../../../constants';

const CountList = ({ countList }) => {
    const t = useTranslation(MEDICAL_WASTE);

    return (
        <InnerItemCard>
            <div className="widget-item-nums">
                {countList.map(({ name, label, count }) => (
                    <Col key={name}>
                        <div className="pnt-txt s-5">{t(label)}</div>
                        <CountUp
                            className="item-num"
                            decimals={Number.isInteger(count) ? 0 : 2}
                            start={0}
                            end={count}
                            duration="1"
                            useEasing={false}
                            separator=""
                            prefix=""
                            suffix=""
                        />
                    </Col>
                ))}
            </div>
        </InnerItemCard>
    );
};

export default CountList;
