// 소켓 인스턴스
export const SOCKET_INSTANCE = Symbol.for('SOCKET_INSTANCE');

// 구 소켓 인스턴스
export const SOCKET_LEGACY_INSTANCE = Symbol.for('SOCKET_LEGACY_INSTANCE');

declare global {
    interface Window {
        [SOCKET_INSTANCE]: SocketIOClient.Socket | null;
        [SOCKET_LEGACY_INSTANCE]: SocketIOClient.Socket | null;
    }
}
