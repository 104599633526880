import React from 'react';
import { Line } from 'react-chartjs-2';
import { PntChart } from '@components/Charts/types';
import useChartData from '@components/Charts/util/useChartData';
import { mergeObjects } from '@util/common/util';
import useChartOptions from '@components/Charts/util/useChartOptions';

const lineDefaultOption = {
    scales: {
        x: {
            grid: { display: false },
        },
        y: {
            grid: { borderWidth: 0 },
        },
    },
};

const areaDefaultOption = mergeObjects(lineDefaultOption, {
    datasets: {
        line: { fill: true },
    },
});

const LineChart = ({ data, options, plugins, type = 'line' }: PntChart<'line'>) => {
    const chartData = useChartData<'line'>(data);
    const chartOptions = useChartOptions(type === 'line' ? lineDefaultOption : areaDefaultOption);
    return <Line data={chartData} options={mergeObjects(chartOptions, options)} plugins={plugins} />;
};

export default LineChart;
