import React from 'react';
import VersionLabel from '../../MainPages/Components/VersionLabel';

const AppFooter = () => {
    return (
        <>
            <footer className="app-footer">
                <div className="footer-wrap">
                    <VersionLabel className={'mr-2'} />
                    <span>copyright &copy; 2022. People and technology all right reserved</span>
                </div>
            </footer>
        </>
    );
};
export default AppFooter;
