import React, { FormEvent, useState } from 'react';
import { Button, Select, TextInput } from '@components';
import { OptionType } from '@components/type';
import { useTranslation } from '@util/hooks';
import { Filter } from '..';

interface Props {
    initialFilter: Filter;
    options: OptionType[];
    onSubmit: (filter: Filter) => void;
    onRefreshClick: () => void;
}

const SearchGroup = ({ initialFilter, options, onSubmit, onRefreshClick }: Props) => {
    const t = useTranslation('OutpatientStorage');

    const [filterDraft, setFilterDraft] = useState<Filter>(initialFilter);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(filterDraft);
    };

    const handleRefreshClick = () => {
        setFilterDraft(initialFilter);
        onRefreshClick();
    };

    return (
        <form className="d-flex align-items-center gap-3" onSubmit={handleSubmit}>
            <span className="fw-bold">{t('Sort by')}</span>
            <Select
                value={filterDraft.sort}
                options={options}
                onChange={selected => setFilterDraft({ ...filterDraft, sort: selected })}
            />
            <div className="d-flex">
                <TextInput
                    value={filterDraft.keyword}
                    placeholder={t('Enter the patients name or patients ID.')}
                    handleChange={e => setFilterDraft({ ...filterDraft, keyword: e.target.value })}
                />
                <Button type="submit" className="btn-secondary">
                    {t('Search', 'Search')}
                </Button>
                <Button className="btn-line btn-icon-only ml-2" iconName="refresh" onClick={handleRefreshClick} />
            </div>
        </form>
    );
};

export default SearchGroup;
