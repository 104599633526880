import { useEffect } from 'react';
import { hidden as globalHidden, visibilityChange } from '@util/crossBrowsingAttrKeys';
import { useAppSelector } from '@hooks/index';
import { useDispatch } from 'react-redux';
import { setHidden } from '@reducer/ScreenInfo';

export const useDocumentVisibility = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const onVisibilityChange = e => {
            dispatch(setHidden(e.target[globalHidden]));
        };
        document.addEventListener(visibilityChange, onVisibilityChange);
        return () => {
            document.removeEventListener(visibilityChange, onVisibilityChange);
        };
    }, []);
};

const useVisibility = handleVisibilityChange => {
    const hidden = useAppSelector(state => state.ScreenInfo.hidden);

    useEffect(() => {
        if (typeof handleVisibilityChange !== 'function') {
            return;
        }
        handleVisibilityChange(hidden);
    }, [hidden, handleVisibilityChange]);

    return { hidden };
};

export default useVisibility;
