import React from 'react';
import Button from '@components/Button';
import { useColumns, useTranslation } from '@hooks';
import { Modal, Table } from '@components';
import Label from '@components/Label';
import styled from 'styled-components';
import * as column from './components/column';
import { useOutpatientBloodDrawStatusContext } from './index';
import moment from 'moment';

const BloodDrawModal = ({ modal, setModal }) => {
    const t = useTranslation('OutpatientBloodDrawStatus');
    const { selectedOutpatient } = useOutpatientBloodDrawStatusContext();
    const { bloodTestMetaList } = selectedOutpatient;
    const toggleModal = () => {
        setModal(!modal);
    };
    const handleCloseModal = () => {
        toggleModal();
    };
    const columns = useColumns(
        [
            column.categoryExam({ width: 130, Cell: ({ value }) => <span>{t(value)}</span> }),
            column.testName(),
            column.payment({ width: 50 }),
            column.singleTest({ width: 50 }),
            column.specimen(),
            column.container(),
        ],
        t,
        [],
    );

    return (
        <>
            <Modal
                initModal={modal}
                toggleModal={toggleModal}
                headerTitle={t('Outpatient')}
                className={'justify-content-center'}
                style={{ maxWidth: '1035px' }}
                modalFooter={
                    <div className="modal-footer" style={{ justifyContent: 'center' }}>
                        <Button className="btn-secondary" onClick={handleCloseModal}>
                            {t('OK', 'ConfirmModal')}
                        </Button>
                    </div>
                }
            >
                <div className="modal-body">
                    <CareInfoContainer>
                        <LowCaseContainer className="flx-col gap-3 pnt-label-8">
                            <Label
                                name={t('Date of appointment')}
                                value={
                                    <span className="pnt-txt txt-bold">
                                        {selectedOutpatient &&
                                            `${moment
                                                .unix(selectedOutpatient.appointmentDate)
                                                .format('YYYY-MM-DD')} ${moment
                                                .unix(selectedOutpatient.appointmentTime)
                                                .format('HH:mm')}`}
                                    </span>
                                }
                            />
                        </LowCaseContainer>
                        <LowCaseContainer className="flx-col gap-3 pnt-label-8">
                            <Label
                                name={t('Medical specialty')}
                                value={<span className="pnt-txt txt-bold">{selectedOutpatient.specialties}</span>}
                            />
                        </LowCaseContainer>
                        <LowCaseContainer className="flx-col gap-3 pnt-label-8">
                            <Label
                                name={t('Doctor')}
                                value={<span className="pnt-txt txt-bold">{selectedOutpatient.doctor}</span>}
                            />
                        </LowCaseContainer>
                    </CareInfoContainer>
                    <PatientInfoContainer>
                        <LowCaseContainer className="flx-col gap-3 pnt-label-8">
                            <Label
                                name={t('Patient name (registration number)')}
                                value={
                                    <span className="pnt-txt txt-bold">{`${selectedOutpatient.patientName} (${selectedOutpatient.patientRegNum})`}</span>
                                }
                            />
                        </LowCaseContainer>
                        <LowCaseContainer className="flx-col gap-3 pnt-label-8">
                            <Label
                                name={t('Age')}
                                value={
                                    <span className="pnt-txt txt-bold">
                                        {selectedOutpatient && selectedOutpatient.age}
                                    </span>
                                }
                            />
                        </LowCaseContainer>
                        <LowCaseContainer className="flx-col gap-3 pnt-label-8">
                            <Label
                                name={t('Sex')}
                                value={
                                    <span className="pnt-txt txt-bold">
                                        {selectedOutpatient && selectedOutpatient.sex === 'M' ? t('Male') : t('Female')}
                                    </span>
                                }
                            />
                        </LowCaseContainer>
                        <TableInfoContainer>
                            <Table data={{ rows: bloodTestMetaList ?? [] }} columns={columns} paging={false} />
                        </TableInfoContainer>
                    </PatientInfoContainer>
                </div>
            </Modal>
        </>
    );
};
const LowCaseContainer = styled.div`
    & * {
        text-transform: unset !important;
    }
`;

const CareInfoContainer = styled.div`
    border-bottom: 1px solid black;
    padding: 1.75rem 0;
`;
const PatientInfoContainer = styled.div`
    padding: 10px 0;
`;

const TableInfoContainer = styled.div`
    margin-top: 10px;
    height: 170px;
    width: 1000px;
`;
export default BloodDrawModal;
