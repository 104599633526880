import React, { useEffect, useState } from 'react';
import ReactSelect, { CSSObjectWithLabel, PropsValue } from 'react-select';
import { Props } from 'react-select/dist/declarations/src/Select';
import cx from 'classnames';

import { useTranslation } from '@hooks';
import { RequiredKeys } from '@util/type/util';
import { OptionType } from '@components/type';
import { DropdownIndicator } from '@components/Select/Components/Common';
import { SelectControl, SelectMenuList, SelectOption, SelectValueContainer } from '@components/Select/Parts';
import { TranslationKey } from '@hooks/useTranslation';

export const ICON_CLASS = 'material-icons-round md-18';

export interface SelectProps extends RequiredKeys<Partial<Props<OptionType>>, 'options'> {
    isModalSelect?: boolean;
    disabled?: boolean;
    valueKey?: string;
    labelKey?: string;
    customContainerStyles?: Omit<CSSObjectWithLabel, 'label'>;
    customControlStyles?: Omit<CSSObjectWithLabel, 'label'>;
    customMenuStyles?: Omit<CSSObjectWithLabel, 'label'>;
    customOptionStyles?: Omit<CSSObjectWithLabel, 'label'>;
    showSearchInput?: boolean;
    translator?: (text: string, overWriteNs?: TranslationKey | object, variables?: string | object) => string;
}

const Select = ({
    name,
    value,
    options,
    closeMenuOnSelect = true,
    isSearchable = false,
    isClearable = false,
    isMulti = false,
    isModalSelect = false,
    iconName,
    onChange,
    onInputChange,
    noOptionsMessage,
    disabled = false,
    placeholder,
    valueKey = 'value',
    labelKey = 'label',
    className,
    customContainerStyles,
    customControlStyles,
    customMenuStyles,
    customOptionStyles,
    showSearchInput,
    translator,
    ...restProps
}: SelectProps): React.ReactElement<Props<OptionType>> => {
    const t = useTranslation('Select');
    const [selectedValue, setSelectedValue] = useState<PropsValue<OptionType> | undefined>(null);

    // 특정 option을 선택한 후 언어설정을 바꾸면, 언어설정을 바꾸기 전 언어로 남아있어서, 선택한 언어로 변경하기 위한 로직
    useEffect(() => {
        let selected = value;
        // 싱글 셀렉트에서만 동작
        if (value && !Array.isArray(value)) {
            const singleValue = value as OptionType;
            selected = options.find((option: OptionType) => option[valueKey] === singleValue[valueKey]);
        }
        setSelectedValue(selected);
    }, [options, value, valueKey]);

    const [inputValue, setInputValue] = useState('');
    // 메뉴 내부의 input Element 관한 focus
    const [inputFocused, setInputFocused] = useState(false);

    // 메뉴가 열고 닫힐 때 input 의 값을 초기상태로 함.
    useEffect(() => {
        if (!inputFocused) {
            setInputValue('');
        }
    }, [inputFocused]);

    return (
        <ReactSelect
            menuPortalTarget={document.getElementById(
                `${!isModalSelect ? 'select-container' : 'modal-select-container'}`,
            )}
            menuPosition={'fixed'}
            menuShouldBlockScroll
            closeMenuOnScroll
            name={name}
            value={selectedValue}
            options={options}
            onChange={onChange}
            onInputChange={onInputChange}
            closeMenuOnSelect={closeMenuOnSelect}
            isSearchable={isSearchable}
            isClearable={isClearable}
            isMulti={isMulti}
            unstyled
            className={cx('react-select pnt-select--group', className)}
            classNamePrefix="react-select"
            placeholder={placeholder || t('Select')}
            noOptionsMessage={() => noOptionsMessage || t('No options')}
            isDisabled={disabled}
            // menuIsOpen
            // 초기 객체값은 항상 빈값으로 존재 -> {}
            // 따라서 string 타입을 객체의 키값으로 사용할 수가 없음 -> 그 키가 객체에 존재하는지 알 수 없기 때문에
            // 하지만 valueKey와 labelKey가 무조건 존재한다고 확신할 수 있는 상황이기 때문에
            // Typescript에 valueKey와 labelKey는 항상 존재하는 키값이라고 선언해줄 수 있음 (as keyof)
            getOptionValue={option => option[valueKey as keyof OptionType]}
            getOptionLabel={option => option[labelKey as keyof OptionType]}
            components={{
                Control: SelectControl,
                DropdownIndicator,
                Option: SelectOption,
                IndicatorSeparator: null,
                ValueContainer: SelectValueContainer,
                MenuList: SelectMenuList,
            }}
            iconName={iconName}
            styles={{
                container: base => ({ ...base, ...customContainerStyles }),
                control: base => ({ ...base, ...customControlStyles }),
                menu: base => ({ ...base, ...customMenuStyles }),
                option: base => ({ ...base, ...customOptionStyles }),
            }}
            onKeyDown={event => {
                if (event.key === 'Enter') {
                    event.preventDefault();
                }
            }}
            // input 관련 props
            inputValue={inputValue}
            onMenuInputChange={inputValue => setInputValue(inputValue)}
            onMenuInputFocusToggle={() => setInputFocused(prev => !prev)}
            onMenuClose={() => setInputFocused(false)}
            menuIsOpen={inputFocused || undefined}
            showSearchInput={showSearchInput}
            translator={translator}
            {...restProps}
        />
    );
};

export default Select;
