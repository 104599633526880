import { reqDelete, reqGet, reqPatch, reqPost } from '@api/index';

// ----------------- 연구 대상 관리 -----------------
// 연구 대상 환자 정보 등록
export const createTestTargetPatientApi = param => reqPost(`/v3/api/sh01/monitoring/patients`, param);

// 연구 대상 환자 정보 삭제
export const deleteTestTargetPatientApi = param =>
    reqDelete(`/v3/api/sh01/monitoring/patients/${param.subjectNum}`, param);

// 연구 대상 환자 정보 상세 조회
export const getTestTargetPatientInfoApi = param => reqGet(`/v3/api/sh01/monitoring/patients/${param.subjectNum}`);

// 연구 대상 환자 정보 목록 조회
export const getTestTargetPatientListApi = param => reqGet(`/v3/api/sh01/monitoring/patients`, param);

// ----------------- 매핑 관리 -----------------
// 환자 - 센서 범위 수정
export const postSensorRangeModifyApi = param => reqPost(`/v3/api/sh01/monitoring/patients-sensors/modify`, param);

// 환자 - 센서 언매핑
export const deleteSensorMappingApi = param =>
    reqDelete(`/v3/api/sh01/monitoring/patients-sensors/unmapping/${param.subjectNum}`, param);

// 환자 - 센서 매핑 정보 조회
export const getTargetInfoApi = param => reqGet(`/v3/api/sh01/monitoring/patients-sensors/${param.subjectNum}`, param);

// 매핑되지 않은 기기 조회
export const getNotMappingSensorListApi = param => reqGet(`/v3/api/sh01/monitoring/patients-sensors/unmapped`, param);

// 환자 - 센서 매핑
export const postSensorMappingApi = param => reqPost(`/v3/api/sh01/monitoring/patients-sensors/mapping`, param);

// -------------- 연구 진행상태 제어 ---------------
// 연구 진행상태 중지
export const patchPauseSubjectApi = param =>
    reqPatch(`/v3/api/sh01/monitoring/progress/status/pause/${param.subjectNum}`);
// 연구 진행상태 시작
export const patchStartSubjectApi = param =>
    reqPatch(`/v3/api/sh01/monitoring/progress/status/start/${param.subjectNum}`);
// 연구 진행상태 이력 조회
export const getProgressHistoryApi = param => reqGet(`/v3/api/sh01/monitoring/progress/status/${param.subjectNum}`);

// -------------- 연구 알람 이력 ---------------
// 연구 대상 환자 알람 이력 조회
export const getTestTargetPatientAlertHistoryApi = param =>
    reqGet('/v3/api/sh01/monitoring/emergency/alert/logs', param);
// 연구 대상 환자 알람 이력 엑셀 다운로드
export const getTestTargetPatientAlertHistoryExcelApi = ({ excelDownloadRequest, ...restParam }) =>
    reqPost('/v3/api/sh01/monitoring/emergency/alert/logs/export', excelDownloadRequest, restParam);

// -------------- 생체 정보 로그 조회 ---------------
// 연구 대상 환자 생체 정보 로그 조회
export const getWatchVitalSignsApi = param => reqGet('/v3.1/api/sh01/vitality/by-ward/watch/graph', param);

// -------------- 모니터링 완료 이력 ---------------
export const getTestTargetMonitoringHistoryApi = param =>
    reqGet(`/v3/api/sh01/monitoring/patients/completion/logs`, param);

export const postExportExcelTestTargetMonitoringApi = param =>
    reqPost(`/v3/api/sh01/monitoring/patients/completion/logs/export/${param.subjectNum}`, param);
