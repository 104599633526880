import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AppInfo {
    apiRequestDebounceTime: number;
    emergencyAlert: {
        activationFilter: boolean;
    };
    [key: string]: any;
}

const initialState: AppInfo = {
    apiRequestDebounceTime: 400,
    emergencyAlert: {
        activationFilter: false,
    },
};

const { actions, reducer } = createSlice({
    name: 'common/appInfo',
    initialState,
    reducers: {
        setInitAppInfo: (state, action: PayloadAction<AppInfo>) => {
            const customAppConfig = action.payload;
            Object.entries(customAppConfig).forEach(([configId, configValue]) => {
                state[configId] = configValue;
            });
        },
    },
});

export const { setInitAppInfo } = actions;
export default reducer;
