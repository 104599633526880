import React, { useEffect, useRef } from 'react';
import useAsync from '@hooks/useAsync';
import { getQuantityCheckCalendarApi, getQuantityCheckListApi } from '@api/am/management';
import { SCREEN_MODE_MONITORING } from '@reducer/ScreenInfo';

import WidgetCard from '../../Components/WidgetCard';
import SearchGroupAM from '../Components/SearchGroupAM';
import useMultiOptionsByRole from '../util/useMultiOptionsByRole';
import { DUTY_LIST, INSPECTION_TYPE, PROPERTY_ID } from '../../constants';
import { ROWS_DUMMY } from './constants';
import useWorkTime from '../util/useWorkTime';
import QuantityList from './QuantityList';
import useCategoryList from '../util/useCategoryList';
import useDutyOptions from '../util/useDutyOptions';
import useDateAM from '../util/useDateAM';
import { useAppSelector, useTranslation } from '@hooks';
import { CATEGORY_CODE } from '@util/mappInfo';
import moment from 'moment';
import { LoadingBlock, QuestionTooltip } from '@components';
import TIMES, { DATE_FORMAT } from '@util/times';
import cx from 'classnames';
import styled from 'styled-components';
import useTimeout from '../util/useTimeout';

const AssetQuantityCheck = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();
    const t = useTranslation('AssetManagement');
    const { mode } = useAppSelector(state => state.ScreenInfo);
    // 담당 시간
    const workTime = useWorkTime();

    const wardListValues = useMultiOptionsByRole();
    const categoryValues = useCategoryList();
    const dutyValues = useDutyOptions(workTime.currentWorkTimeType);
    const dateValues = useDateAM(workTime);

    const inspectingStart = workTime.workTimeSet.DAY.startDate;
    const inspectingEnd = inspectingStart + TIMES.HOUR_1 / 1000;
    const isInspectingTime = useTimeout(inspectingStart, inspectingEnd);

    // 오늘을 검증하기 위한 정보
    const adjustSelectedDate =
        workTime.currentWorkTimeType !== DUTY_LIST.NIGHT ||
        moment.unix(dateValues?.startDate).format(DATE_FORMAT.YMD) !==
            moment.unix(workTime.workTimeSet[DUTY_LIST.NIGHT]?.startDate).format(DATE_FORMAT.YMD)
            ? moment
                  .unix(dateValues?.startDate)
                  .hour(moment().hour())
                  .minute(moment().minute())
                  .second(moment().second())
            : moment();
    const validationToday = adjustSelectedDate.isBetween(
        moment.unix(workTime.workTimeSet[workTime.currentWorkTimeType]?.startDate).add(-1, 's'),
        moment.unix(workTime.workTimeSet[workTime.currentWorkTimeType]?.endDate).add(1, 's'),
    );
    const isToday = validationToday ? 'Y' : 'N';

    const { promise: getQuantityCheckList, state } = useAsync({
        promise: getQuantityCheckListApi,
        fixedParam: { checkType: INSPECTION_TYPE.NORMAL },
        param: {
            categoryCodes:
                categoryValues.selectedCategoryList.length !== 0
                    ? categoryValues.selectedCategoryList.toString()
                    : categoryValues.categoryOptions.map(v => v.value).toString(),
            targetPropertiesSearch: {
                categoryCode: CATEGORY_CODE.ASSET,
                propertySearchs:
                    wardListValues?.selectedWardList.length !== 0
                        ? wardListValues?.selectedWardList.map(value => ({
                              propertyId: PROPERTY_ID.ASSET.WARD,
                              values: [value],
                          }))
                        : wardListValues?.options.map(({ value }) => ({
                              propertyId: PROPERTY_ID.ASSET.WARD,
                              values: [value],
                          })),
            },
            workTimeType: dutyValues.duty.value,
            startDate: dateValues.startDate, // NaN 예외 처리 필요 ex) NaN이면 오늘의 startDate
            endDate: dateValues.endDate, // NaN 예외 처리 필요
            isToday,
        },
    });

    const { promise: getQuantityCalendarList, state: quantityCalendarState } = useAsync({
        promise: getQuantityCheckCalendarApi,
        fixedParam: {
            targetPropertiesSearch: {
                categoryCode: CATEGORY_CODE.ASSET,
                propertySearchs: [
                    ...(wardListValues?.selectedWardList.length !== 0
                        ? wardListValues?.selectedWardList.map(value => ({
                              propertyId: PROPERTY_ID.ASSET.WARD,
                              values: [value],
                          }))
                        : wardListValues?.options.map(({ value }) => ({
                              propertyId: PROPERTY_ID.ASSET.WARD,
                              values: [value],
                          }))),
                ],
            },
        },
    });

    useEffect(() => {
        if (mode !== SCREEN_MODE_MONITORING) {
            return;
        }

        // 필수 값 체크
        if (!workTime.currentWorkTimeType && !dateValues.startDate && !dateValues.endDate) {
            return;
        }

        getQuantityCheckList();
        getQuantityCalendarList({
            startDate: moment.unix(dateValues.startDate).startOf('months').unix(),
            endDate: moment.unix(dateValues.endDate).endOf('months').unix(),
        });
    }, [
        wardListValues.selectedWardList.length,
        categoryValues.selectedCategoryList.length,
        dutyValues.duty.value,
        dateValues.startDate,
    ]);

    const handleRefresh = () => {
        getQuantityCheckList();
    };

    const renderDayContents = (day, date) => {
        const dayOfUnix = moment(date).startOf('days').unix();
        const selectedDate = dateValues.startDate;
        const calendarCheckList = quantityCalendarState.response?.rows ?? [];

        if (quantityCalendarState.isLoading) {
            return <DateSkeleton />;
        }
        //선택된 날짜일 경우
        if (dayOfUnix === selectedDate) return day;

        const targetDate = calendarCheckList.find(({ date }) => date === dayOfUnix);

        if (targetDate && targetDate.date === dayOfUnix) {
            return (
                <DateContents
                    className={cx(targetDate.isChecked === 'Y' ? 'bg-secondary color-white' : 'bg-lightgray')}
                >
                    {day}
                </DateContents>
            );
        }

        return day;
    };
    const onMonthChange = date => {
        getQuantityCalendarList({
            startDate: moment(date).startOf('months').unix(),
            endDate: moment(date).endOf('months').unix(),
        });
    };

    const rows = mode !== SCREEN_MODE_MONITORING ? ROWS_DUMMY : state.response?.rows || [];

    return (
        <WidgetCard
            ref={widgetRef}
            widgetInfo={widgetInfo}
            subTitle={
                <QuestionTooltip
                    isFitContent
                    contents={
                        <div className={'text-start p-2'}>
                            <div>{t('During quantity check, rented assets are also subject to inspection.')}</div>
                            <div>
                                {t('Quantity check and memo are reset at {{startTime}} every day.', 'AssetManagement', {
                                    startTime: moment.unix(workTime?.workTimeSet.DAY.startDate).format(DATE_FORMAT.HM),
                                })}
                            </div>
                            <div>
                                {t(
                                    'Modifications can be made even after the inspection is completed, and past date inspections can',
                                )}
                            </div>
                            <div>{t('be done through the calendar.')}</div>
                            <div>
                                {t(
                                    "'Today' is from {{startTime}} business days to {{endTime}} the next day.",
                                    'AssetManagement',
                                    {
                                        startTime: moment
                                            .unix(workTime?.workTimeSet?.DAY.startDate)
                                            .format(DATE_FORMAT.HM),
                                        endTime: moment
                                            .unix(workTime?.workTimeSet.NIGHT.endDate)
                                            .format(DATE_FORMAT.HM),
                                    },
                                )}
                            </div>
                            <br />
                            <div>{t('three-shift inspection time standard is as follows.')}</div>
                            <div>
                                - Day : {moment.unix(workTime?.workTimeSet.DAY.startDate).format(DATE_FORMAT.HM)}~
                                {moment.unix(workTime?.workTimeSet.DAY.endDate).format(DATE_FORMAT.HM)}
                            </div>
                            <div>
                                - Evening :{' '}
                                {moment.unix(workTime?.workTimeSet.EVENING.startDate).format(DATE_FORMAT.HM)}~
                                {moment.unix(workTime?.workTimeSet.EVENING.endDate).format(DATE_FORMAT.HM)}
                            </div>
                            <div>
                                - Night : {moment.unix(workTime?.workTimeSet.NIGHT.startDate).format(DATE_FORMAT.HM)}~
                                {moment.unix(workTime?.workTimeSet.NIGHT.endDate).format(DATE_FORMAT.HM)}
                            </div>
                        </div>
                    }
                />
            }
            searchFilter={
                !isInspectingTime && (
                    <SearchGroupAM
                        wardListValues={wardListValues}
                        categoryValues={categoryValues}
                        dutyValues={dutyValues}
                        dateValues={dateValues}
                        renderDayContents={renderDayContents}
                        onMonthChange={onMonthChange}
                    />
                )
            }
            bodyClassName={'pt-0'}
            {...restProps}
        >
            {!isInspectingTime ? (
                <LoadingBlock blocking={state.isLoading}>
                    <QuantityList
                        data={rows}
                        currentWorkTimeType={workTime.currentWorkTimeType}
                        handleRefresh={handleRefresh}
                        selectedDuty={dutyValues.duty.value}
                        targetDate={dateValues.startDate}
                        isToday={validationToday}
                        workTimeSet={workTime.workTimeSet}
                    />
                </LoadingBlock>
            ) : (
                <div className="fs-4 fw-bold d-flex flex-column justify-content-center align-items-center h-100">
                    <div>
                        {t('It is currently data inspection time.')}({moment.unix(inspectingStart).format('HH:mm')})~(
                        {moment.unix(inspectingEnd).format('HH:mm')})
                    </div>
                    <div>{t('It can be used after inspection time.')}</div>
                </div>
            )}
            {children}
        </WidgetCard>
    );
};

export default AssetQuantityCheck;

const DateContents = styled.span`
    display: flex;
    justify-content: center;
    align-content: center;
    min-width: 1.7rem;
    min-height: 1.7rem;
    color: white;
    border-radius: 50%;
`;

const DateSkeleton = styled.div`
    min-width: 1.7rem;
    min-height: 1.7rem;
    background: #f0f0f0;
    border-radius: 8px;
    animation: pulse 1.5s infinite;
    pointer-events: none;
`;
