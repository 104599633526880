export const BATTERY_CHARGING = 255;
export const BATTERY_LEAD_OFF = 250;

export const getBatteryIconClassName = (batteryValue: number, chargingValue?: number) => {
    if (!batteryValue) {
        return 'battery_unknown';
    } else if (batteryValue <= 0) {
        return 'battery_0_bar';
    } else if (batteryValue > 0 && batteryValue <= 15) {
        return 'battery_1_bar';
    } else if (batteryValue > 15 && batteryValue <= 30) {
        return 'battery_2_bar';
    } else if (batteryValue > 30 && batteryValue <= 45) {
        return 'battery_3_bar';
    } else if (batteryValue > 45 && batteryValue <= 60) {
        return 'battery_4_bar';
    } else if (batteryValue > 60 && batteryValue <= 75) {
        return 'battery_5_bar';
    } else if (batteryValue > 75 && batteryValue <= 99) {
        return 'battery_6_bar';
    } else if (batteryValue === (chargingValue ?? BATTERY_CHARGING)) {
        return 'battery_charging_full';
    } else {
        return 'battery_full';
    }
};

export const getBatteryDisplayValue = (batteryValue: number, chargingValue?: number, leadOffValue?: number) => {
    if (!batteryValue || batteryValue === (leadOffValue ?? BATTERY_LEAD_OFF)) {
        return '- %';
    } else if (batteryValue === (chargingValue ?? BATTERY_CHARGING)) {
        return 'Charging';
    } else {
        return `${batteryValue} %`;
    }
};
