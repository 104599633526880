export function convertSecondsToMinutes(seconds: number | null) {
    if (seconds === null) {
        return 0;
    }
    // 초를 60으로 나누어 분으로 변환
    const minutes = seconds / 60;

    // 소수점 이하를 제거하여 정수로 반환
    return Math.floor(minutes);
}
