import { useTranslation } from '@hooks';
import { useState } from 'react';
import { DATE_TYPE_OPTIONS } from '../../constants';

const useDateTypeOptions = () => {
    const t = useTranslation('OutpatientReport');
    const dateTypeOptions = DATE_TYPE_OPTIONS.map(({ value, label }) => ({
        value,
        label: t(label),
    }));

    const [dateType, setDateType] = useState(dateTypeOptions[0]); // 일별, 월별

    const handleDateTypeChange = selected => {
        setDateType(selected);
    };

    return {
        dateTypeOptions,
        dateType,
        handleDateTypeChange,
    };
};

export default useDateTypeOptions;
