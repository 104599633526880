import React from 'react';
import { useAsync } from '@hooks';
import { fetchRealTimeSensorMonitoring } from '@api/monitoring';
import { useSensorStatusContext, useSensorStatusDispatch } from '../../../index';
import { setSensorList } from '../../../sensorStatusSlice';
import Bookmark from '../../../../../Components/Bookmark';

const BookmarkIcon = ({ row: { original } }) => {
    const dispatch = useSensorStatusDispatch();
    const { sensorListParam } = useSensorStatusContext();
    const { promise: getRealTimeSensorMonitoring } = useAsync({
        promise: fetchRealTimeSensorMonitoring,
        resolve: res => {
            dispatch(setSensorList(res));
        },
        reject: err => console.error(err),
    });

    const handleRefresh = () => {
        getRealTimeSensorMonitoring({ ...sensorListParam });
    };

    return <Bookmark successCallback={handleRefresh} targetNum={original.targetNum} isBookmark={original.isBookmark} />;
};

export default BookmarkIcon;
