import React, { useEffect } from 'react';
import useTranslation from '@hooks/useTranslation';
import FilterSearchGroup from '../../../../MainPages/Components/FilterSearchGroup';
import SearchGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import { Button, Select as ReactSelect, TextInput } from '@components';
import {
    InputGroup,
    InputWrap,
    SearchWrap,
    SelectGroup,
} from '../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import SearchButtonArea from '../../../../MainPages/Components/FilterSearchGroup/Components/SearchButtonArea';
import { useIotItemMappingLogContext, useIotItemMappingLogDispatch } from '../index';
import { reset, setKeyword, setSearchOption } from '../IotItemMappingLogSlice';
import { makeParameter } from '../../../util/commons';

/**
 * iot 아이템 맵핑 로그 검색 기능 컴포넌트
 *
 * @param promise Iot아이템 맵핑 리스트 호출 callback
 *
 * @author jinbeom-jung
 * */
const Search = ({ promise }) => {
    const t = useTranslation('Iot Item Mapping Log');
    const dispatch = useIotItemMappingLogDispatch();
    const { keyword, searchOption } = useIotItemMappingLogContext();
    const searchOptions = [
        { value: '', label: t('Search Option', 'Search') },
        { value: 'targetNum', label: t('IoT Item No.', 'CommonColumn') },
        { value: 'targetId', label: t('IoT Item ID', 'CommonColumn') },
        { value: 'targetName', label: t('IoT Item Name', 'CommonColumn') },
    ];

    useEffect(() => {
        dispatch(
            reset({
                searchOption: searchOptions[0],
            }),
        );
    }, []);

    const handleInputChange = e => {
        const { value } = e.target;
        dispatch(setKeyword(value));
    };

    const handleSelectValueChange = selected => {
        const { value } = selected;
        if (value === '') {
            dispatch(setKeyword(''));
        }
        dispatch(setSearchOption(selected));
    };

    const handleRefreshClick = () => {
        dispatch(
            reset({
                keyword: '',
                searchOption: searchOptions[0],
            }),
        );
        promise();
    };

    const handleSearchClick = () => {
        const result = makeParameter({
            searchRequirement: {
                keyword,
                opt: searchOption.value,
            },
        });
        promise(result);
    };

    return (
        <FilterSearchGroup className={'p-0'}>
            <SearchGroup>
                <div className={'d-flex'}>
                    <SearchWrap>
                        <SelectGroup>
                            <ReactSelect
                                name="selectInput"
                                value={searchOption}
                                options={searchOptions}
                                onChange={handleSelectValueChange}
                                customControlStyles={{ width: '100%' }}
                                customMenuStyles={{ width: '100%' }}
                                customOptionStyles={{ width: '100%' }}
                            />
                        </SelectGroup>
                        <InputWrap>
                            <InputGroup>
                                <TextInput
                                    type="text"
                                    size={60}
                                    placeholder={t(
                                        'Please Enter your search keyword after selecting search option.',
                                        'Search',
                                    )}
                                    name="name"
                                    value={keyword}
                                    handleChange={handleInputChange}
                                    disabled={!searchOption.value}
                                />
                            </InputGroup>
                        </InputWrap>
                    </SearchWrap>
                    <SearchButtonArea>
                        <div className={'d-flex'}>
                            <Button
                                className="btn-secondary btn-icon mr-2"
                                iconName="search"
                                onClick={handleSearchClick}
                            >
                                {t('Search', 'Search')}
                            </Button>
                            <Button
                                className="btn-line btn-icon-only"
                                iconName="refresh"
                                onClick={handleRefreshClick}
                            />
                        </div>
                    </SearchButtonArea>
                </div>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default Search;
