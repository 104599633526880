import { useDispatch } from 'react-redux';
import {
    initSocket,
    destroySocket,
    emitMessage,
    setEventHandler,
    setEventHandlerWithFilter,
    removeEventHandler,
    SocketInfo,
    generateSocketFilterId,
} from '@reducer/SocketInfo';
import { SOCKET_INSTANCE } from '@util/symbol/window';
import { getApiURL } from '@api/index';
import socketio, { Socket } from 'socket.io-client';
import useAppSelector from '@hooks/useAppSelector';

export type SocketEventHandler<T> = (data: T, ...args: any[]) => void;

interface SocketUtil {
    socket: null | typeof Socket;
    initializeSocket(): void;
    sendMessage(eventName: string, data?: any): void;
    setSocketEvent<T>(messageType: string, callback: SocketEventHandler<T>, filterConfig?: object): string | void;
    removeSocketEvent<T>(messageType: string, callback?: SocketEventHandler<T>, filterId?: string): void;
    closeSocket(): void;
    socketInfo: SocketInfo;
}

const useSocket = (): SocketUtil => {
    const dispatch = useDispatch();
    const socketInfo = useAppSelector(state => state.SocketInfo);

    const setSocketEvent = <DataType>(
        messageType: string,
        callback: SocketEventHandler<DataType>,
        filterConfig?: object,
    ) => {
        if (filterConfig) {
            const filterId = generateSocketFilterId();
            dispatch(setEventHandlerWithFilter({ messageType, callback, filterInfo: { filterId, filterConfig } }));
            return filterId;
        } else {
            dispatch(setEventHandler({ messageType, callback }));
        }
    };

    const removeSocketEvent = <DataType>(
        messageType: string,
        callback?: SocketEventHandler<DataType>,
        filterId?: string,
    ) => {
        dispatch(removeEventHandler({ messageType, callback, filterId }));
    };

    const closeSocket = () => {
        dispatch(destroySocket());
    };

    const initializeSocket = () => {
        // 연결된 소켓 없음, 연결중 아님
        if (!window[SOCKET_INSTANCE]) {
            getApiURL().then(({ wmsSocketUrl }) => {
                window[SOCKET_INSTANCE] = socketio(wmsSocketUrl, {
                    transports: ['websocket'],
                    forceNew: true,
                    reconnection: true,
                    reconnectionAttempts: 5,
                    reconnectionDelay: 5000,
                });
                const socket = window[SOCKET_INSTANCE] as SocketIOClient.Socket;
                socket.on('connect', function () {
                    console.log('SOCKET_CONNECTED : ', wmsSocketUrl);
                    dispatch(initSocket());
                });
                socket.on('disconnect', function () {
                    console.log('SOCKET_DISCONNECT : ', wmsSocketUrl);
                    dispatch(initSocket());
                });
                socket.on('reconnect', function () {
                    console.log('SOCKET_RECONNECTED : ', wmsSocketUrl);
                    dispatch(initSocket());
                });
            });
        }
    };

    const sendMessage = (eventName: string, data?: any) => {
        dispatch(emitMessage({ eventName, data }));
    };

    return {
        socket: window[SOCKET_INSTANCE],
        initializeSocket,
        sendMessage,
        setSocketEvent,
        removeSocketEvent,
        closeSocket,
        socketInfo,
    };
};

export default useSocket;
