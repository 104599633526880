import { useEffect, useState } from 'react';
import moment from 'moment';

const useTimeout = (start, end) => {
    const [isShow, setIsShow] = useState(false);

    useEffect(() => {
        const current = moment().unix();

        if (!start) {
            return;
        }

        if (current >= start && current <= end) {
            setIsShow(true);
        }

        const id = setTimeout(() => {
            setIsShow(false);
        }, (end - current) * 1000);

        return () => {
            clearTimeout(id);
        };
    }, [end, start]);

    return isShow;
};

export default useTimeout;
