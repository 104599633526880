import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from '@hooks';
import { Select } from '@components';
import FilterSearchGroup from '../../../../MainPages/Components/FilterSearchGroup';
import { Button, DatePicker } from '@components';
import { setEndDate, setSelectedCategory, setStartDate } from '../congestionStatusSlice';
import { useCongestionStatusContext, useCongestionStatusDispatch } from '../index';
import SearchGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import SearchButtonArea from '../../../../MainPages/Components/FilterSearchGroup/Components/SearchButtonArea';
import { FilterList, SelectGroup } from '../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import FilterGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/FilterGroup';

const CongestionFilterGroup = ({ handleSearchClick }) => {
    const t = useTranslation('CongestionStatus');
    const [categoryListWithAllOption, setCategoryListWithAllOption] = useState([]);
    const { floorList } = useSelector(state => state.FloorInfo);
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const { selectedCategory, selectedFloor, startDate, endDate } = useCongestionStatusContext();
    const dispatch = useCongestionStatusDispatch();

    const handleSelectCategoryChange = e => {
        dispatch(setSelectedCategory(e.categoryCode));
    };

    const handleYesterdayBtnClick = () => {
        dispatch(setStartDate(moment().subtract(1, 'days').startOf('day').valueOf()));
        dispatch(setEndDate(moment().subtract(1, 'days').endOf('day').valueOf()));
    };

    const handleTodayBtnClick = () => {
        dispatch(setStartDate(moment().startOf('day').valueOf()));
        dispatch(setEndDate(moment().endOf('day').valueOf()));
    };

    // const handleFloorChange = selects => {
    //     if (selects[0]) {
    //         dispatch(setSelectedFloor(selects[0].floorId));
    //         dispatch(setSelectedFloorInfo(selects[0]));
    //     }
    // };

    useEffect(() => {
        setCategoryListWithAllOption([{ categoryCode: '', categoryName: t('All') }, ...categoryList]);
    }, [categoryList, t]);

    // 시작날짜(시간)이 종료날짜(시간)를 넘어가는 경우 종료날짜를 시작날짜로 초기화
    useEffect(() => {
        if (startDate > endDate) {
            dispatch(setEndDate(startDate));
        }
    }, [startDate, endDate]);

    return (
        <FilterSearchGroup className={'card absolute-filter'}>
            <FilterGroup label={t('Filter')}>
                <Select
                    placeholder={t('All')}
                    value={
                        categoryListWithAllOption.length === 0
                            ? []
                            : categoryListWithAllOption.find(category => category.categoryCode === selectedCategory)
                    }
                    onChange={handleSelectCategoryChange}
                    options={categoryListWithAllOption}
                    valueKey={'categoryCode'}
                    labelKey={'categoryName'}
                    listStyle={{ height: '200px', overflow: 'auto' }}
                />
            </FilterGroup>
            <SearchGroup label={t('Search')}>
                <FilterList>
                    <SelectGroup>
                        <DatePicker
                            value={startDate}
                            handleChange={selected => dispatch(setStartDate(selected))}
                            valueType={'ms'}
                            maxDate={endDate || moment().valueOf()}
                            showTimeInput
                        />
                    </SelectGroup>
                    <div> ~ </div>
                    <SelectGroup>
                        <DatePicker
                            value={endDate}
                            handleChange={selected => dispatch(setEndDate(selected))}
                            valueType={'ms'}
                            minDate={startDate}
                            maxDate={moment().valueOf()}
                            showTimeInput
                        />
                    </SelectGroup>
                    <SearchButtonArea>
                        <div className={'flx-row gap-1'}>
                            <Button className={'btn-gray btn-yesterday'} onClick={handleYesterdayBtnClick}>
                                {t('Yesterday', 'Button')}
                            </Button>
                            <Button className={'btn-gray btn-yesterday'} onClick={handleTodayBtnClick}>
                                {t('Today', 'Button')}
                            </Button>
                            <Button className="pnt-btn btn-secondary" onClick={handleSearchClick}>
                                {t('Search')}
                            </Button>
                        </div>
                    </SearchButtonArea>
                </FilterList>
            </SearchGroup>
        </FilterSearchGroup>
    );
};
export default CongestionFilterGroup;
