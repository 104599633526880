import React, { useEffect, useState } from 'react';
import { useAsync } from '@hooks';
import UnattendedReceivingItemCount from './Components/OutpatientUnattendedReceivingItemStatusCount';
import OutpatientDatePicker from './Components/OutpatientDatePicker';
import { useOutpatientUnattendedReceivingContext, useOutpatientUnattendedReceivingDispatch } from './index';
import { clearOutpatientCare, setOutpatientListParam } from './OutpatientUnattendedReceivingStatusSlice';
import { getUnattendedReceivingStatusCountApi, getUnattendedReceivingStatusListApi } from '@api/monitoring';
import moment from 'moment';
import OutpatientUnattendedReceivingStatusList from './Components/OutpatientUnattendedReceivingStatusList';
import useDepartmentOptions from '../util/useDepartmentOptions';

const DEFAULT_UNATTENDED_PAYMENTS_COUNTS = {
    total: 0,
    paymentSuccess: 0,
    paymentFail: 0,
};
const DEFAULT_UNATTENDED_PAYMENTS_LIST = {
    pageSize: 15,
    totalCount: 0,
    totalPage: 1,
    rows: [],
};
const OutpatientUnattendedReceivingItemContainer = () => {
    const dispatch = useOutpatientUnattendedReceivingDispatch();
    const {
        selectedOutpatientStatus: status,
        keyword,
        outpatientListParam,
    } = useOutpatientUnattendedReceivingContext();
    const [startDate, setStartDate] = useState(moment().startOf('day').unix());
    const [endDate, setEndDate] = useState(
        moment().startOf('day').add(23, 'hours').add(59, 'm').add(59, 'second').unix(),
    );

    const departmentValues = useDepartmentOptions();
    const { department } = departmentValues;

    const handlePageChange = pageIndex => {
        dispatch(
            setOutpatientListParam(prevState => {
                return { ...prevState, page: pageIndex };
            }),
        );
    };

    const { state: outpatientUnattendedReceivingCountState } = useAsync({
        promise: getUnattendedReceivingStatusCountApi,
        immediate: true,
        param: {
            ...outpatientListParam,
            startDate: startDate,
            endDate: endDate,
            departmentCodes: department.toString(),
        },

        reject: err => {
            console.error(err);
        },
        deps: [startDate, endDate, keyword, department],
    });
    const { state: outpatientUnattendedReceivingListState } = useAsync({
        promise: getUnattendedReceivingStatusListApi,
        immediate: true,
        param: {
            ...outpatientListParam,
            startDate: startDate,
            endDate: endDate,
            departmentCodes: department.toString(),
        },

        reject: err => {
            console.error(err);
        },
        deps: [startDate, endDate, status, keyword, department],
    });

    const outpatientUnattendedReceivingCount =
        outpatientUnattendedReceivingCountState.response ?? DEFAULT_UNATTENDED_PAYMENTS_COUNTS;
    const outpatientUnattendedReceivingList =
        outpatientUnattendedReceivingListState.response ?? DEFAULT_UNATTENDED_PAYMENTS_LIST;

    useEffect(() => {
        return () => {
            dispatch(clearOutpatientCare());
        };
    }, []);

    const handleStartDate = date => {
        setStartDate(date);
    };
    const handleEndDate = date => {
        setEndDate(date);
    };

    return (
        <div className={'w-100 h-100'}>
            <UnattendedReceivingItemCount outpatientCount={outpatientUnattendedReceivingCount} />
            <OutpatientDatePicker
                startDate={startDate}
                endDate={endDate}
                handleStartDate={handleStartDate}
                handleEndDate={handleEndDate}
                departmentValues={departmentValues}
            />
            <OutpatientUnattendedReceivingStatusList
                handleOutpatientListParam={handlePageChange}
                outpatientList={outpatientUnattendedReceivingList}
            />
        </div>
    );
};

export default OutpatientUnattendedReceivingItemContainer;
