import { postPatientStatusExcelApi } from '@api/sh/waste';
import { Button, Select } from '@components';
import { useTranslation } from '@hooks';
import React from 'react';
import DatePickerGroupByDateType from '../../Components/DatePickerGroupByDateType';
import { MEDICAL_WASTE } from '../../../constants';
import { formatExcelDateName } from '../../../util/format';
import useAdminCheck from '../../util/useAdminCheck';
import { useAsyncExcel } from '../../../util/useAsyncExcel';

const BodyHeader = ({
    options,
    selectedWard,
    selectedTab, // daily, monthly, yearly
    setSelectedWard,
    startDate,
    handleStartChange,
    endDate,
    handleEndChange,
    getList,
}) => {
    const t = useTranslation(MEDICAL_WASTE);
    const isAdmin = useAdminCheck();

    const triggerDownload = useAsyncExcel({
        promise: postPatientStatusExcelApi,
        param: {
            dateType: selectedTab,
            ward: selectedWard.value,
            startDate,
            endDate,
            columnMetas: [
                {
                    key: 'regDateStr',
                    name: formatExcelDateName(selectedTab),
                },
                {
                    key: 'wardName',
                    name: '부서',
                },
                {
                    key: 'isolatedPatientCount',
                    name: '격리환자',
                },
                {
                    key: 'normalPatientCount',
                    name: '일반환자',
                },
                {
                    key: 'wasteCount',
                    name: '폐기물 수량',
                },
            ],
            zipFileName: 'patient_status',
            fileName: 'patient_status',
        },
    });

    const handleSearchClick = () => {
        getList();
    };

    const handleDownloadClick = () => {
        triggerDownload();
    };

    return (
        <div className="d-flex justify-content-between">
            <div className="d-flex gap-2">
                <div className="d-flex gap-2 align-items-center">
                    <span className="fw-bold">{t('Ward')}</span>
                    <Select
                        value={selectedWard}
                        options={options}
                        onChange={selected => {
                            setSelectedWard(selected);
                        }}
                        // disabled={!isAdmin}
                    />
                </div>
                <div className="d-flex gap-2 align-items-center">
                    <DatePickerGroupByDateType
                        dateType={selectedTab}
                        startDate={startDate}
                        handleStartChange={handleStartChange}
                        endDate={endDate}
                        handleEndChange={handleEndChange}
                    />
                </div>
                <Button className="btn-secondary" onClick={handleSearchClick}>
                    {t('Search', 'Search')}
                </Button>
            </div>
            <div className="d-flex gap-2">
                <Button className="btn-lightgray btn-icon" onClick={handleDownloadClick}>
                    {t('Download')}
                </Button>
            </div>
        </div>
    );
};

export default BodyHeader;
