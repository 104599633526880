import React, { useEffect, useReducer, useRef } from 'react';
import { FeatureGroup, Popup, LayersControl } from 'react-leaflet';
import { useSettings } from '../../util/useSettings';
import { AniMarker, GeofenceLayer, Map, RotatedImageOverlay } from '@components';
import { useTranslation, useAppSelector, useDeepCompareMemo } from '@hooks';
import realTimeLocationReducer, {
    initialState,
    toggleGeofenceTagListPopup,
    updateRealTimeLog,
    setGeofenceList,
} from './realTimeLocationTrackingReducer';
import { EVENT_TYPE_LOCATION } from '@reducer/SocketInfo';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import WidgetCard from '../../Components/WidgetCard';
import Search from './Components/Search';
import LocationDetail from './Components/LocationDetail';
import GeofenceDetailPopup from './Components/GeofenceDetailPopup';
import { ASSET_MANAGEMENT } from '../../constants';

export const RealTimeLocationTrackingContext = React.createContext();
export const RealTimeLocationTrackingDispatchContext = React.createContext();

const RealTimeLocationTracking = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('RealTimeLocationTracking');
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { hiddenMapTile } = settings;
    const confirmedTile = hiddenMapTile !== undefined ? !hiddenMapTile : true;

    const { geofenceGroupList, geofenceList } = useAppSelector(state => state.GeofenceInfo);
    const { representationGroupInfo, groupNums } = useAppSelector(state => state.UserInfo.userInfo);
    const [state, dispatch] = useReducer(realTimeLocationReducer, initialState);
    const { selectedTopFloor, selectedFloor, selectedCategory, geofenceGroupDataPopup, realTimeLog } = state;
    const tagList = Object.values(realTimeLog);
    const floorInfo = selectedFloor || selectedTopFloor;

    const mapRef = useRef();
    const widgetRef = useRef();

    useEffect(() => {
        const floorId = selectedFloor ? selectedFloor.floorId : selectedTopFloor?.floorId;
        if (!floorId) {
            return;
        }
        dispatch(setGeofenceList({ geofenceGroupList, geofenceList, floorId }));
    }, [selectedTopFloor, selectedFloor, geofenceGroupList, geofenceList]);

    const socketFilterConfig = useDeepCompareMemo(() => {
        if (!selectedFloor && !selectedTopFloor) {
            return null;
        }
        const resultConfig = {};
        const floorId = selectedFloor ? selectedFloor.floorId : selectedTopFloor?.floorId;

        if (floorId) {
            resultConfig.$or = [{ currentFloor: { floorId } }, { previousFloor: { floorId } }];
        } else {
            resultConfig.currentFloor = { noneFloor: true };
        }
        if (selectedCategory?.length) {
            resultConfig.targetCategory = {
                categoryCode: {
                    $in: selectedCategory.map(({ value }) => value),
                },
            };
        }
        if (groupNums.length !== 0) {
            resultConfig.target = {
                properties: {
                    $or: [{ ward: { $in: groupNums } }, { using_dep_id: { $in: groupNums } }],
                },
            };
        }
        return resultConfig;
    }, [selectedFloor, selectedTopFloor, selectedCategory, groupNums]);

    useSocketEvent({
        name: EVENT_TYPE_LOCATION,
        handler: data => {
            dispatch(updateRealTimeLog(data));
        },
        filterConfig: socketFilterConfig,
        enableBuffer: true,
    });

    const handleModalToggle = () => {
        dispatch(toggleGeofenceTagListPopup());
    };

    return (
        <RealTimeLocationTrackingDispatchContext.Provider value={dispatch}>
            <RealTimeLocationTrackingContext.Provider value={state}>
                <WidgetCard
                    bodyClassName={'p-1 d-flex'}
                    widgetInfo={widgetInfo}
                    ref={widgetRef}
                    searchFilter={<Search />}
                    {...restProps}
                >
                    <LocationDetail className="w-25" />
                    <div className={'w-75 ml-1 border border-depth-7'}>
                        <Map tile={confirmedTile} ref={mapRef} rotation={floorInfo?.rotation} zoomDelta={0.1}>
                            <LayersControl position={'topright'}>
                                <LayersControl.Overlay checked name={t('Geofence')}>
                                    <GeofenceLayer
                                        geofenceList={geofenceList.filter(
                                            geofence => geofence.floor === floorInfo?.floorId,
                                        )}
                                    />
                                </LayersControl.Overlay>
                            </LayersControl>
                            {floorInfo && floorInfo.imgURL && floorInfo.bounds.length && (
                                <RotatedImageOverlay
                                    key={floorInfo.floorId}
                                    url={floorInfo.imgURL}
                                    deg={floorInfo.deg}
                                    bounds={floorInfo.bounds}
                                    onLoad={() => {
                                        const map = mapRef.current.leafletElement;
                                        if (map) {
                                            if (
                                                representationGroupInfo?.floorId &&
                                                representationGroupInfo.floorId === floorInfo.floorId &&
                                                representationGroupInfo.lat &&
                                                representationGroupInfo.lng
                                            ) {
                                                map.setView(
                                                    [representationGroupInfo.lat, representationGroupInfo.lng],
                                                    representationGroupInfo.zoom,
                                                );
                                            }
                                        }
                                    }}
                                />
                            )}
                            <FeatureGroup>
                                {tagList.map(targetInfo => {
                                    const { target, targetCategory } = targetInfo;
                                    const { targetId, targetName, targetNum } = target;
                                    const { categoryName } = targetCategory;
                                    return (
                                        <AniMarker key={targetNum} targetInfo={targetInfo}>
                                            <Popup attribution={{ autoClose: false }}>
                                                <div className={'d-flex realTime-label'}>
                                                    <div className={'w-50'}>{t('Asset ID', ASSET_MANAGEMENT)}</div>
                                                    <span className={'mx-2'}>|</span>
                                                    <div className={'w-50'}>{targetId}</div>
                                                </div>
                                                <div className={'d-flex realTime-label'}>
                                                    <div className={'w-50'}>{t('Asset Name', ASSET_MANAGEMENT)}</div>
                                                    <span className={'mx-2'}>|</span>
                                                    <div className={'w-50'}>{targetName}</div>
                                                </div>
                                                <div className={'d-flex realTime-label'}>
                                                    <div className={'w-50'}>{t('Category Name', ASSET_MANAGEMENT)}</div>
                                                    <span className={'mx-2'}>|</span>
                                                    <div className={'w-50'}>{categoryName}</div>
                                                </div>
                                            </Popup>
                                        </AniMarker>
                                    );
                                })}
                            </FeatureGroup>
                        </Map>
                    </div>

                    <GeofenceDetailPopup
                        mapRef={mapRef}
                        modal={!!geofenceGroupDataPopup}
                        toggleModal={handleModalToggle}
                        widgetRef={widgetRef}
                    />
                    {children}
                </WidgetCard>
            </RealTimeLocationTrackingContext.Provider>
        </RealTimeLocationTrackingDispatchContext.Provider>
    );
};

export default RealTimeLocationTracking;
