import React, { useContext, useEffect, useRef, useState } from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useTranslation from '@hooks/useTranslation';
import widgetList from '../index';
import { editWidget } from '../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import { TextInput } from '@components';
import { ScreenEditDispatchContext } from '../../MainPages/Settings/ScreenManagement/ScreenEdit';
import Modal from '@components/Modal';
import Button from '@components/Button';
import cx from 'classnames';
import styled from 'styled-components';

const CATEGORY_ALL = 'All';

const WidgetList = ({ widgetList, activeTarget, onClick }) => {
    const t = useTranslation('WidgetList');
    // 각 카테고리 count
    const categoryCount = {};
    Object.values(widgetList).forEach(({ category }) => {
        if (!category) {
            return;
        }
        if (!categoryCount[category]) {
            categoryCount[category] = 1;
            return;
        }
        categoryCount[category] += 1;
    });
    // 전체 count
    const totalCount = Object.values(categoryCount).reduce((a, b) => a + b, 0);

    const isAll = activeTarget === CATEGORY_ALL;
    return (
        <>
            <ListGroupItem
                key="widgetList_total"
                tag="button"
                onClick={onClick}
                value={CATEGORY_ALL}
                className={`layout`}
            >
                <div className={'widgetCounts'}>
                    <div>
                        <span className={cx(isAll && 'color-brand', 'title')}>{t('Total')}</span>
                        <span
                            className={cx(isAll ? 'color-brand' : 'color-gray', 'subTitle')}
                        >{`(${totalCount})`}</span>
                    </div>
                    <span className={cx(isAll && 'color-brand', 'material-icons-round')}>chevron_right</span>
                </div>
            </ListGroupItem>
            {Object.keys(categoryCount).map((key, index) => (
                <ListGroupItem key={`${key}_${index}`} tag="button" onClick={onClick} value={key}>
                    <div className={'widgetCounts'}>
                        <div>
                            <span className={cx(activeTarget === key && 'color-brand', 'title')}>{t(key)}</span>
                            <span
                                className={cx(activeTarget === key ? 'color-brand' : 'color-gray', 'subTitle')}
                            >{`(${categoryCount[key]})`}</span>
                        </div>
                        <span className={cx(activeTarget === key && 'color-brand', 'material-icons-round')}>
                            chevron_right
                        </span>
                    </div>
                </ListGroupItem>
            ))}
        </>
    );
};

const WidgetPrevList = ({ widgetList, layout, toggleModal }) => {
    const t = useTranslation('WidgetList');
    const dispatch = useContext(ScreenEditDispatchContext);
    const widgets = Object.values(widgetList);

    const handleAddClick = (e, widgetInfo) => {
        dispatch(
            editWidget({
                name: widgetInfo.name,
                category: widgetInfo.category,
                id: layout.i,
                type: widgetInfo.type,
                config: widgetInfo.config,
                layout: { ...layout, ...widgetInfo.layout },
            }),
        );
        toggleModal();
    };

    // widget 없는 경우
    if (!widgets.length) {
        return (
            <ListGroupItem key={'noItem'} className="d-flex flex-center">
                <p className="mb-0">{t('There are no search widget')}</p>
            </ListGroupItem>
        );
    }

    return (
        <>
            {widgets.map((widget, index) => (
                <div key={`${widget.name}_${index}`} className="widgetItemWrapper bg-depth-4">
                    <div className={'flx-row justify-content-center'}>
                        <div>
                            <span className={widget.thumbnailClassName} />
                        </div>
                        <div className="w-100 p-3">
                            <span className="widgetName">{t(widget.name, 'Widget')}</span>
                            <p className="widgetDesc">{t(widget.desc, 'Widget')}</p>
                        </div>
                        <Button
                            className="btn-brand btn-icon btn-icon-only"
                            iconName="add"
                            onClick={e => {
                                handleAddClick(e, widget);
                            }}
                        />
                    </div>
                </div>
            ))}
        </>
    );
};

const PalettePopup = ({
    initOpen = false,
    setModal,
    toggleModal = function () {
        initOpen = !initOpen;
    },
    layout,
    ...restProps
}) => {
    const { temp, ...validWidgetList } = widgetList;
    const t = useTranslation('Widget');
    const [filteredList, setFilteredList] = useState(validWidgetList);
    const [currentCategory, setCurrentCategory] = useState(CATEGORY_ALL);
    const [searchKeywords, setSearchKeywords] = useState('');
    const ps = useRef();

    const scrollTop = () => {
        const curr = ps.current;
        if (curr) {
            curr.scrollTop = 0;
        }
    };

    // 검색어 변경 이벤트
    const handleFilterChange = e => {
        setSearchKeywords(e.currentTarget.value);
    };

    // 카테고리 선택 이벤트
    const onListClick = e => {
        setCurrentCategory(e.currentTarget.value);
    };

    const handleRefreshClick = () => {
        setSearchKeywords('');
        setCurrentCategory(CATEGORY_ALL);
    };

    useEffect(() => {
        scrollTop();

        setFilteredList(
            Object.fromEntries(
                Object.entries(validWidgetList).filter(([_, { name, category }]) => {
                    const keywordsFilter = t(name).toUpperCase().indexOf(searchKeywords.toUpperCase()) >= 0;
                    const categoryFilter = currentCategory === CATEGORY_ALL || category === currentCategory;
                    return keywordsFilter && categoryFilter;
                }),
            ),
        );
    }, [currentCategory, searchKeywords]);

    return (
        <Modal
            className={'widget-add-popup'}
            initModal={initOpen}
            toggleModal={toggleModal}
            style={{ maxWidth: '45rem' }}
            removeCancel
            {...restProps}
            modalHeader={
                <div className={'modal-header w-100 bg-depth-3'}>
                    <div className={'flx-row justify-content-between w-100 align-center'}>
                        <span className={'s-8 fw-bold'}>{t('Add Widget', 'WidgetList')}</span>
                        <span className={'icon-close cursor-pointer'} onClick={toggleModal} />
                    </div>
                </div>
            }
        >
            <div className="modal-body d-flex p-0">
                <SearchContainer className="w-40 py-3">
                    <TextInput
                        type={'text'}
                        name={'dashboard-palette-popup'}
                        placeholder={t('Search', 'Search')}
                        value={searchKeywords}
                        handleChange={handleFilterChange}
                        inputGroupClassName={'w-100 px-3 mb-3'}
                    />
                    <ListGroup flush className="w-100">
                        <WidgetList widgetList={validWidgetList} activeTarget={currentCategory} onClick={onListClick} />
                    </ListGroup>
                    <SearchResultContainer className={'px-3'}>
                        <span className={'text-ellipsis'}>
                            {t('A total of {{totalCount}} results were found.', 'WidgetList', {
                                totalCount: Object.keys(filteredList).length,
                            })}
                        </span>
                        <Button
                            title={t('Refresh', 'Button')}
                            className="btn-icon-only btn-trans"
                            iconName="refresh"
                            onClick={handleRefreshClick}
                        />
                    </SearchResultContainer>
                </SearchContainer>

                <div className="w-60" style={{ height: '400px' }}>
                    <PerfectScrollbar containerRef={e => (ps.current = e)}>
                        <ListGroup flush>
                            <WidgetPrevList layout={layout} toggleModal={toggleModal} widgetList={filteredList} />
                        </ListGroup>
                    </PerfectScrollbar>
                </div>
            </div>
        </Modal>
    );
};

const SearchContainer = styled.div`
    display: grid;
    grid-template-rows: min-content auto min-content;
`;

const SearchResultContainer = styled.div`
    display: grid;
    grid-template-columns: auto min-content;
    align-items: center;
`;

export default PalettePopup;
