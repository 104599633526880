import React, { useEffect, useMemo, useRef, useState } from 'react';
import Table from '@components/Table';
import * as column from '@util/grid/column';
import useTranslation from '@hooks/useTranslation';
import { useColumns } from '@hooks';
import { ASSET_MANAGEMENT } from '../../constants';
import Select from '@components/Select';
import moment from 'moment';
import { DEFAULT_VALUE } from './constants';
import NormalRiskInspection from './NormalRiskInspection';

const NormalRiskInspectionList = ({ data, handleRefresh, targetDate, isToday }) => {
    const t = useTranslation(ASSET_MANAGEMENT);
    const inspectionResult = useMemo(
        () => ({
            good: { label: t('good'), value: 'good' },
            bad: { label: t('bad'), value: 'bad' },
        }),
        [],
    );
    const resultOptions = useMemo(() => Object.values(inspectionResult), []);

    const changedRowsRef = useRef({});

    // table column data
    const columns = useColumns(
        [
            column.index({ width: 40 }),
            column.inspectionResult({
                width: 100,
                className: 'py-0',
                Cell: ({ value, ...others }) => {
                    const { original } = others.row;
                    const {
                        targetNum,
                        ward,
                        checkResult,
                        memo,
                        riskLevel,
                        categoryCode,
                        dailyCheckNum,
                        checkDate,
                        regDate,
                    } = original;
                    const selected = checkResult ? inspectionResult[checkResult] : inspectionResult.good;

                    const resultData = {
                        targetNum,
                        dailyCheckNum,
                        ward,
                        checkResult: selected.value,
                        memo,
                        riskLevel,
                        categoryCode,
                        checkDate,
                        regDate,
                    };

                    const [localSelected, setLocalSelected] = useState(selected);
                    if (!checkResult && !changedRowsRef.current[targetNum]) {
                        changedRowsRef.current[targetNum] = resultData;
                    }
                    const handleChange = selected => {
                        setLocalSelected(selected);
                        // 최초 점검 결과가 없을 때
                        if (!checkResult && changedRowsRef.current[targetNum]) {
                            changedRowsRef.current[targetNum].checkResult = selected.value;
                            return;
                        }
                        if (checkResult !== selected.value) {
                            changedRowsRef.current[targetNum] = { ...resultData, checkResult: selected.value };
                        } else {
                            if (changedRowsRef.current[targetNum]) {
                                delete changedRowsRef.current[targetNum];
                            }
                        }
                    };
                    return (
                        <div className="d-flex w-100 h-100 justify-content-center align-center">
                            <Select value={localSelected} options={resultOptions} onChange={handleChange} />
                        </div>
                    );
                },
            }),
            column.categoryName(),
            column.assetName({ width: 150 }),
            column.targetId({
                width: 150,
                Header: t('Asset ID'),
                style: { textTransform: 'uppercase' },
            }),
            column.modelNo({ Header: t('modelNo'), accessor: 'modelName' }),
            {
                Header: t('Ward/Dept.'),
                accessor: 'usingDepName',
            },
            column.inspectionDate({
                accessor: 'checkDate',
                headerClassName: 'text-nowrap',
                className: 'text-ellipsis text-nowrap',
                Cell: ({ value }) =>
                    value ? (
                        <span title={moment.unix(value).format('YYYY-MM-DD HH:mm:ss')}>
                            {moment.unix(value).format('YYYY-MM-DD HH:mm:ss')}
                        </span>
                    ) : (
                        DEFAULT_VALUE
                    ),
            }),
            column.inspector(),
            column.inspectorUsername({
                Cell: ({ value }) => {
                    if (!value) {
                        return DEFAULT_VALUE;
                    }
                    if (value.indexOf('@') < 0) {
                        return value;
                    }
                    return value.substring(0, value.indexOf('@'));
                },
            }),
            {
                Header: t('Owner Ward'),
                accessor: 'wardName',
            },
        ],
        useTranslation('CommonColumn'),
        [data],
    );

    const getChangedRows = () => changedRowsRef.current;

    useEffect(() => {
        // 데이터 정보 변경될 경우 초기화
        changedRowsRef.current = {};
    }, [data]);

    return (
        <NormalRiskInspection
            data={data}
            handleRefresh={handleRefresh}
            getChangedRows={getChangedRows}
            targetDate={targetDate}
            isToday={isToday}
        >
            <Table columns={columns} data={{ rows: data }} paging={false} noDataMessage={t('No items to check.')} />
        </NormalRiskInspection>
    );
};

export default NormalRiskInspectionList;
