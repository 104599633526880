import React, { useEffect, useRef, useState } from 'react';
import 'nouislider/distribute/nouislider.css';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { useAsync, useConfirmModal, useTranslation } from '@hooks';
import { Button, DatePicker } from '@components';
import { fetchHeatMapLocationLogList } from '@api/common/log';
import { fetchFloorList, fetchSelectedFloorInfo } from '@api/common/floor';
import { setFloorList } from '@reducer/Common/FloorInfo';
import SingleTreeSelect from '@components/Select/SingleTreeSelect';

import FilterSearchGroup from '../../../MainPages/Components/FilterSearchGroup';
import SearchGroup from '../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import { FilterList, SelectGroup } from '../../../MainPages/Components/FilterSearchGroup/Components/Part';
import HeatMap from './Components/HeatMap';
import PlayController from './Components/PlayController';
import WidgetCard from '../../Components/WidgetCard';
import { useSettings } from '../../util/useSettings';
import {
    setClear,
    setDetectedTargets,
    setEndTime,
    setLog,
    setPlay,
    setPlayTime,
    setSelectedFloor,
    setStartTime,
} from './heatMapSlice';
import { useHeatmapDispatch } from './index';

const AnalysisHeatmapContainer = ({ widgetInfo, children, ...restProps }) => {
    const t = useTranslation('ConfirmModal');
    const { config } = widgetInfo;
    const settings = useSettings(config);

    const dispatch = useHeatmapDispatch();
    const storeDispatch = useDispatch();
    const { floorList } = useSelector(state => state.FloorInfo);

    const widgetRef = useRef();
    const playInterval = useRef();

    const play = () => {
        playInterval.current = setInterval(() => {
            dispatch(setPlayTime());
        }, 1000 / 64);
        dispatch(setPlay(true));
    };

    const pause = () => {
        clearInterval(playInterval.current);
        dispatch(setPlay(false));
    };

    // 호출 시 시작날짜, 끝날짜와 층정보를 넣으면 해당하는 데이터를 가져온다.
    const { promise: getDetectedTargetList } = useAsync({
        promise: fetchHeatMapLocationLogList,
        resolve: res => {
            const { additionalDatas, rows } = res;
            dispatch(setDetectedTargets(additionalDatas.detectedTargets));
            dispatch(setLog(rows));
            if (rows.length) {
                const startLogRegDate = rows[rows.length - 1].regDate;
                let endLogRegDate = rows[0].regDate;
                if (startLogRegDate === endLogRegDate) {
                    endLogRegDate += 1;
                }
                dispatch(setStartTime(startLogRegDate));
                dispatch(setEndTime(endLogRegDate));
            }
        },
        reject: err => {
            console.log(err);
        },
    });

    // 등록되어있는 모든 층 정보를 가져오는 곳 (초기실행 필수)
    useAsync({
        promise: fetchFloorList,
        resolve: res => {
            storeDispatch(setFloorList(res.rows));
        },
        reject: err => {
            console.log(err);
        },
        immediate: true,
    });

    // 선택한 층의 정보를 가져온다.
    const { promise: getFloorInfo } = useAsync({
        promise: fetchSelectedFloorInfo,
        resolve: res => {
            dispatch(setSelectedFloor(res));
        },
        reject: err => {
            console.log(err);
        },
    });

    const [showSearchArea, setShowSearchArea] = useState(false);
    const handleSearchAreaClick = () => {
        setShowSearchArea(!showSearchArea);
    };

    const [floor, setFloor] = useState('');
    const [floorName, setFloorName] = useState('');

    const handleFloorChange = selected => {
        const { floorId, floorName } = selected;
        setFloor(floorId);
        setFloorName(floorName);
    };

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const { Modal: SearchResultModal, toggleModal: toggleSearchResultModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Result') },
        confirmText: t('Please specify a start and end date'),
    });
    const handleSearchClick = () => {
        if (startDate && endDate) {
            dispatch(setClear());
            getDetectedTargetList({ startDate, endDate, floor });
            getFloorInfo({ floorId: floor });
            // 검색 시 Slider값 초기화
            dispatch(setStartTime(startDate));
            pause();
        } else {
            toggleSearchResultModal();
        }
    };

    const handleRefreshClick = () => {
        setStartDate(null);
        setEndDate(null);
        setFloor('');
        dispatch(setClear());
    };

    // startDate변경 시 초기에는 동작하지 않음
    // endDate가 startDate보다 작은 값이 될 수 없음
    // endDate가 startDate보다 작은 값을 선택 시 startDate값으로
    // startDate를 endDate보다 큰값으로 선택 시 endDate를 startDate값으로 변경
    useEffect(() => {
        if (endDate && startDate > endDate) {
            setEndDate(startDate);
        }
    }, [startDate, endDate]);

    return (
        <>
            <WidgetCard
                bodyClassName={'p-1'}
                widgetInfo={widgetInfo}
                ref={widgetRef}
                headerAction={
                    <Button className={'btn-darkgray'} onClick={handleSearchAreaClick}>
                        {t('Search', 'Button')}
                    </Button>
                }
                searchFilter={
                    showSearchArea && (
                        <FilterSearchGroup className={'card absolute-filter h-100 justify-content-center'}>
                            <SearchGroup label={t('Search', 'AssetCountStatus')}>
                                <FilterList className={'w-100'}>
                                    <SelectGroup>
                                        <div className={'datePicker-container'}>
                                            <DatePicker
                                                value={startDate}
                                                maxDate={moment().valueOf()}
                                                handleChange={selected => setStartDate(selected)}
                                                showTimeSelect
                                            />
                                        </div>
                                    </SelectGroup>
                                    <SelectGroup>
                                        <div className={'datePicker-container'}>
                                            <DatePicker
                                                value={endDate}
                                                minDate={moment.unix(startDate).valueOf()}
                                                maxDate={moment().valueOf()}
                                                disabled={!startDate}
                                                handleChange={selected => setEndDate(selected)}
                                                showTimeSelect
                                            />
                                        </div>
                                    </SelectGroup>
                                    <SelectGroup>
                                        <SingleTreeSelect
                                            data={floorList}
                                            title={'Category'}
                                            valueKey={'floorId'}
                                            labelKey={'floorName'}
                                            parentKey={'upperFloorId'}
                                            selected={floor}
                                            onChange={handleFloorChange}
                                        />
                                    </SelectGroup>
                                    <Button
                                        disabled={(!startDate && !endDate) || !floorName}
                                        className={'btn-secondary btn-icon'}
                                        onClick={handleSearchClick}
                                    >
                                        {t('Search', 'Search')}
                                    </Button>
                                    <Button
                                        className="btn-line btn-icon-only"
                                        iconName="refresh"
                                        onClick={handleRefreshClick}
                                    />
                                </FilterList>
                            </SearchGroup>
                        </FilterSearchGroup>
                    )
                }
                {...restProps}
            >
                <div className={'h-100 w-100'}>
                    <HeatMap settings={settings} ref={widgetRef} />
                    <PlayController play={play} pause={pause} />
                </div>
                {children}
            </WidgetCard>
            <SearchResultModal />
        </>
    );
};

export default AnalysisHeatmapContainer;
