import { Patient, PatientListInfo } from './types';
import { advancedGenerateFakeData, getRandom, getSample } from '@util/common/util';
import { initialTableData, SAMPLE_STATUS } from './Components/PatientsTable';
import { UnixTimestamp } from '@util/type/util';

///////////////// 환자 모니터링 목록
const patientListInfo: PatientListInfo = {
    ...initialTableData,
    rows: [
        {
            subjectNum: 392,
            targetNum: 392,
            targetName: '인석',
            fcName: getRandom(1, 5).toString(),
            recentSensorValues: {
                HEARTBEAT: getRandom(80, 120),
                SPO2: getRandom(80, 120),
                TEMPERATURE: getRandom(80, 120),
            },
            recentSensorStates: {
                HEARTBEAT: getSample(SAMPLE_STATUS),
                SPO2: getSample(SAMPLE_STATUS),
                TEMPERATURE: getSample(SAMPLE_STATUS),
            },
            battery: getSample([getRandom(50, 90), getRandom(50, 90), getRandom(50, 90), null, 255, 0, 5, 10]),
            regDate: getRandom(new Date().getTime() / 1000 - 43200, new Date().getTime() / 1000),
            sensorName: '인석',
            sensorNum: 293,
            elapsedTime: getRandom(60, 800),
            progressType: getSample(['Y', 'N']),
        },
    ],
};

for (let i = 1; i < 12; i++) {
    const currSensorName = '홍길동 센서' + i;
    const sensorName = getSample([null, currSensorName, currSensorName, currSensorName]);
    patientListInfo.rows.push({
        subjectNum: i,
        targetNum: i,
        targetName: '홍길동' + i,
        fcName: getRandom(1, 5).toString(),
        recentSensorValues: {
            HEARTBEAT: getRandom(80, 120),
            SPO2: getRandom(80, 120),
            TEMPERATURE: getRandom(80, 120),
        },
        recentSensorStates: {
            HEARTBEAT: getSample(SAMPLE_STATUS),
            SPO2: getSample(SAMPLE_STATUS),
            TEMPERATURE: getSample(SAMPLE_STATUS),
        },
        battery: sensorName
            ? getSample([getRandom(50, 90), getRandom(50, 90), getRandom(50, 90), null, 255, 0, 5, 10])
            : null,
        regDate: getRandom(new Date().getTime() / 1000 - 43200, new Date().getTime() / 1000),
        sensorName,
        sensorNum: sensorName ? i : null,
        elapsedTime: getSample([0, getRandom(60, 800)]),
        progressType: sensorName ? getSample(['Y', 'N']) : 'N',
    });
}

export const getPatientListApi = advancedGenerateFakeData(patientListInfo);

////////////////////////////////////////////////////////////////////

////////////////// 환자 상세
const patient: Patient = {
    subjectNum: 392,
    targetNum: 392,
    targetName: '인석',
    fcName: getRandom(1, 5).toString(),
    recentSensorValues: {
        HEARTBEAT: getRandom(80, 120),
        SPO2: getRandom(80, 120),
        TEMPERATURE: getRandom(80, 120),
    },
    recentSensorStates: {
        HEARTBEAT: getSample(SAMPLE_STATUS),
        SPO2: getSample(SAMPLE_STATUS),
        TEMPERATURE: getSample(SAMPLE_STATUS),
    },
    battery: getSample([getRandom(50, 90), getRandom(50, 90), getRandom(50, 90), null, 255, 0, 5, 10]),
    regDate: getRandom(new Date().getTime() / 1000 - 43200, new Date().getTime() / 1000),
    sensorName: '인석',
    sensorNum: 293,
    elapsedTime: getRandom(60, 800),
    progressType: getSample(['Y', 'N']),
};

export const getPatientApi = advancedGenerateFakeData(patient);

////////////////////////////////////////////////////////////////////

///////////////////////// 환자 상세 그래프
const graphData = {
    data: {
        measurementUnits: {
            SPO2: '%',
            TEMPERATURE: '℃',
            HEARTBEAT: 'bpm',
        },
        sensingList: {
            TEMPERATURE: [
                36.5,
                36.5,
                36.5,
                36.5,
                36.5,
                36.5,
                36.5,
                22.9,
                22.9,
                22.9,
                22.9,
                22.9,
                22.9,
                22.9,
                23.1,
                23.1,
                23.1,
                23.1,
                23.1,
                23.1,
                23.1,
                23.3,
                23.3,
                23.3,
                23.3,
                23.3,
                23.3,
                23.3,
                23.3,
                23.8,
                23.8,
                23.8,
                23.8,
                23.9,
                36.5,
                36.5,
                36.5,
                36.5,
                36.5,
                36.5,
                36.5,
                36.5,
                36.5,
                24.2,
                24.2,
                24.2,
                24.2,
                24.2,
                24.2,
                24.2,
                24.2,
                24.2,
                24.3,
                24.3,
                24.3,
                24.3,
                24.3,
                24.3,
                24.3,
                24.3,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.4,
                24.5,
                24.5,
                24.5,
                24.5,
                24.5,
                24.5,
                24.5,
                24.5,
                24.5,
                24.5,
                24.5,
                24.5,
                24.5,
                24.5,
                24.5,
                24.5,
                24.5,
                24.5,
                24.5,
                24.5,
                24.5,
                24.5,
                24.5,
                24.5,
                24.5,
                24.6,
                24.6,
                24.6,
                24.6,
                24.6,
                24.6,
                24.6,
                24.6,
                24.6,
                24.6,
                24.6,
                24.6,
                24.6,
                24.6,
                24.6,
                24.6,
                24.6,
                24.6,
                24.6,
                24.6,
                24.6,
                24.6,
                24.6,
                24.6,
                24.6,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
                24.7,
            ],
            SPO2: [
                15.0,
                15.0,
                15.0,
                15.0,
                15.0,
                15.0,
                15.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                15.0,
                15.0,
                15.0,
                15.0,
                15.0,
                15.0,
                15.0,
                15.0,
                15.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
            ],
            HEARTBEAT: [
                60.0,
                60.0,
                60.0,
                60.0,
                60.0,
                60.0,
                60.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                60.0,
                60.0,
                60.0,
                60.0,
                60.0,
                60.0,
                60.0,
                60.0,
                60.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
                0.0,
            ],
            regDate: [
                1715216994,
                1715216997,
                1715216998,
                1715216999,
                1715217000,
                1715217001,
                1715217002,
                1715217003,
                1715217004,
                1715217005,
                1715217007,
                1715217008,
                1715217009,
                1715217013,
                1715217014,
                1715217015,
                1715217016,
                1715217018,
                1715217020,
                1715217021,
                1715217022,
                1715217023,
                1715217024,
                1715217026,
                1715217027,
                1715217028,
                1715217029,
                1715217030,
                1715217031,
                1715217049,
                1715217050,
                1715217051,
                1715217053,
                1715217054,
                1715217065,
                1715217066,
                1715217067,
                1715217068,
                1715217069,
                1715217070,
                1715217072,
                1715217073,
                1715217074,
                1715217075,
                1715217076,
                1715217077,
                1715217079,
                1715217080,
                1715217081,
                1715217082,
                1715217083,
                1715217084,
                1715217085,
                1715217087,
                1715217088,
                1715217089,
                1715217090,
                1715217091,
                1715217093,
                1715217094,
                1715217095,
                1715217096,
                1715217097,
                1715217098,
                1715217099,
                1715217101,
                1715217102,
                1715217103,
                1715217104,
                1715217105,
                1715217106,
                1715217108,
                1715217109,
                1715217110,
                1715217111,
                1715217112,
                1715217113,
                1715217115,
                1715217116,
                1715217117,
                1715217118,
                1715217120,
                1715217121,
                1715217122,
                1715217123,
                1715217124,
                1715217126,
                1715217127,
                1715217128,
                1715217129,
                1715217131,
                1715217132,
                1715217133,
                1715217134,
                1715217135,
                1715217137,
                1715217138,
                1715217139,
                1715217140,
                1715217141,
                1715217142,
                1715217144,
                1715217145,
                1715217146,
                1715217147,
                1715217149,
                1715217150,
                1715217151,
                1715217152,
                1715217153,
                1715217154,
                1715217155,
                1715217156,
                1715217157,
                1715217159,
                1715217160,
                1715217161,
                1715217162,
                1715217163,
                1715217164,
                1715217166,
                1715217167,
                1715217168,
                1715217169,
                1715217170,
                1715217171,
                1715217172,
                1715217174,
                1715217175,
                1715217176,
                1715217177,
                1715217179,
                1715217180,
                1715217181,
                1715217182,
                1715217184,
                1715217185,
                1715217186,
                1715217187,
                1715217188,
                1715217189,
                1715217190,
                1715217191,
                1715217193,
                1715217194,
                1715217195,
                1715217196,
                1715217197,
                1715217198,
                1715217200,
                1715217201,
                1715217202,
                1715217203,
                1715217204,
                1715217206,
                1715217207,
                1715217208,
                1715217209,
                1715217210,
                1715217212,
                1715217213,
                1715217214,
                1715217215,
                1715217216,
                1715217218,
                1715217219,
                1715217220,
                1715217221,
                1715217222,
                1715217224,
                1715217225,
                1715217226,
                1715217227,
                1715217228,
                1715217229,
                1715217231,
                1715217232,
                1715217233,
                1715217234,
                1715217235,
                1715217237,
                1715217238,
                1715217239,
                1715217240,
                1715217241,
                1715217242,
                1715217243,
                1715217244,
                1715217247,
                1715217248,
                1715217250,
                1715217251,
            ],
        },
    },
};

export const getPatientVitalSingsApi = advancedGenerateFakeData(graphData);

const timeArr = [];
for (let i = 0; i < getRandom(2, 9); i++) {
    timeArr.push(getSample(graphData.data.sensingList.regDate));
}
timeArr.sort();

const sampleActiveTimes = timeArr.map((time, i) => {
    return {
        comNum: 1,
        logNum: i,
        progressType: i % 2 === 1 ? 'N' : 'Y',
        subjectNum: 1,
        time,
    };
});
export const getPatientActiveTimeApi = advancedGenerateFakeData({ rows: sampleActiveTimes });
