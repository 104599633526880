export const SIGNAL_STATUS_NORMAL = 'NORMAL';

export const DEFAULT_DATE = '--/-- --:--';

export const LINE_CHART_COLOR = '#82ca9d';
export const WHITE_COLOR = '#fff';
export const GRID_GRAY_COLOR = '#ccc';
export const TOOLTIP_BG_COLOR_DARK = '#4c4c52';
export const TOOLTIP_BG_COLOR_NAVY = '#e8e6ef';
export const TOOLTIP_TEXT_COLOR = '#565672';
export const TEXT_BOLD = 'bold';
export const DARK_THEME = 'pnt-dark';

export const GRAPH_AREA = {
    WIDTH: 1250,
    HEIGHT: 300,
    MARGIN_TOP: 5,
    MARGIN_RIGHT: 20,
    MARGIN_BOTTOM: 40,
    MARGIN_LEFT: 0,
};
export const LABEL_AREA = {
    TOP: 32,
};
const TICK_LIMITS = 16;

export const ADD_SENSOR_ITEM_NAME = {
    HEARTBEAT: 'HEARTBEAT',
    CALORIES: 'CALORIES',
    STEP: 'STEP',
    DISTANCE: 'DISTANCE',
};

export const formatSensorList = (
    { HEARTBEAT: heartbeatList = [], DISTANCE: distanceList = [], CALORIES: caloriesList = [], STEP: stepList = [] },
    t,
) => {
    const lists = [heartbeatList, caloriesList, stepList, distanceList];

    return lists.map(list => {
        let name;
        let unit;
        let min = Number.MAX_SAFE_INTEGER;
        let max = Number.MIN_SAFE_INTEGER;
        const stepSize = Math.min(TICK_LIMITS, list.length);
        const startTime = list[0]?.regDate * 1000;
        const endTime = list[list.length - 1]?.regDate * 1000;
        const offset = Math.floor((endTime - startTime) / stepSize);

        const data = list.reduce(
            (acc, value, index) => {
                const { currentValue, regDate, sensingType, measurementUnit } = value;
                name = ADD_SENSOR_ITEM_NAME[sensingType];
                unit = measurementUnit;
                min = Math.min(min, currentValue);
                max = Math.max(max, currentValue);

                if (!acc['datasets'][0]?.label) {
                    acc['datasets'][0].label = t(name);
                }

                if (index < TICK_LIMITS && !isNaN(startTime) && !isNaN(endTime)) {
                    acc['labels'].push(startTime + index * offset);
                }
                acc['datasets'][0].data.push({ x: regDate * 1000, y: Number(currentValue) });

                return acc;
            },
            {
                labels: [],
                datasets: [
                    {
                        label: t(SENSOR_TYPE.NAME.HEARTBEAT),
                        data: [],
                        forcedPointBackgroundColor: WHITE_COLOR,
                        forcedBorderColor: LINE_CHART_COLOR,
                        forcedPointBorderColor: LINE_CHART_COLOR,
                        forcedBackgroundColor: LINE_CHART_COLOR,
                    },
                ],
            },
        );

        return { name, unit, data, min, max };
    });
};

export const DEFAULT_CHART_OPTIONS = {
    animation: false,
    parser: false,
    layout: {
        padding: {
            top: GRAPH_AREA.MARGIN_TOP,
            left: GRAPH_AREA.MARGIN_LEFT,
            right: GRAPH_AREA.MARGIN_RIGHT,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            mode: 'index',
            intersect: false,
            animation: { duration: 0 },
            displayColors: false,
            padding: 12,
            titleFont: {
                weight: TEXT_BOLD,
            },
            bodyFont: {
                weight: TEXT_BOLD,
            },
        },
    },
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'second',
                tooltipFormat: 'HH:mm:ss',
                displayFormats: { second: 'HH:mm:ss' },
            },
            display: true,
            grid: {
                borderDash: [4, 4],
                display: true,
                tickLength: LABEL_AREA.TOP,
                offset: false,
                color: GRID_GRAY_COLOR,
            },
            ticks: {
                minRotation: 45,
                source: 'labels',
            },
        },
        y: {
            grid: {
                borderWidth: 1,
                borderDash: [4, 4],
                color: GRID_GRAY_COLOR,
            },
            ticks: {
                maxTicksLimit: 5,
            },
        },
    },
    datasets: {
        line: {
            pointRadius: 0,
            borderWidth: 1,
            offset: true,
        },
    },
};

export const SENSOR_TYPE = {
    KEY: {
        HEARTBEAT: 'HEARTBEAT',
    },
    NAME: {
        HEARTBEAT: 'Heartbeat',
    },
    UNIT: {
        HEARTBEAT: 'bpm',
    },
};

export const TAB_ITEMS = [
    {
        key: 'Daily Graph',
        value: 1,
    },
];

export const customTooltip = (colorScheme, t) => ({
    tooltip: {
        backgroundColor: colorScheme === DARK_THEME ? TOOLTIP_BG_COLOR_DARK : TOOLTIP_BG_COLOR_NAVY,
        titleColor: colorScheme === DARK_THEME ? WHITE_COLOR : TOOLTIP_TEXT_COLOR,
        bodyColor: colorScheme === DARK_THEME ? WHITE_COLOR : TOOLTIP_TEXT_COLOR,
        callbacks: {
            title: tooltipItem => {
                return `${t('Recognition Time')} ${tooltipItem[0].label}`;
            },
            label: tooltipItem => {
                let tooltipValue = tooltipItem.formattedValue;
                // Floating Bar Chart 에서 최대 최소 툴팁 표기
                if (tooltipValue.includes('[')) {
                    tooltipValue = JSON.parse(tooltipValue)
                        .sort((l, r) => l - r)
                        .join(' ~ ');
                }
                return `${t(tooltipItem.dataset.label)} ${tooltipValue}`;
            },
        },
    },
});

export const STATUS_COLOR = {
    WARNING: { BG: 'bg-warning', COLOR: '' },
    CRITICAL: { BG: 'bg-warning', COLOR: '' },
    OUTOFRANGE: { BG: 'bg-secondary bg-l-4', COLOR: '' },
    undefined: { BG: 'bg-gray bg-light', COLOR: '' },
};
// 테이블 높이
export const TABLE_HEADER_HEIGHT = 32;
export const TABLE_ROW_HEIGHT = 35;
export const TABLE_BORDER_WIDTH = 2;

export const OUT_OF_RANGE = 'OUTOFRANGE';

export const BATTERY_CHARGING = 255;

export const DEFAULT_DISPLAY_VALUE = 'N/A';

