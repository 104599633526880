import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector, useTranslation } from '@hooks';
import { LicenseStatus, Y, N, MAX_REMAINING_DAYS, LOOSE_VIOLATIONS } from '@hooks/useLicenseCheck';
import { useDispatch } from 'react-redux';
import { logOut } from '@reducer/UserInfo';
import { ConfirmModal } from '@components';

const LicenseModal = () => {
    const t = useTranslation('License');
    const { licenseStatus }: { licenseStatus: LicenseStatus } = useAppSelector(state => state.UserInfo);
    const [prevLicenseStatus, setPrevLicenseStatus] = useState<LicenseStatus>(licenseStatus);
    const dispatch = useDispatch();
    const location = useLocation();

    const [restrictionModalOpen, setRestrictionModalOpen] = useState(false);
    const toggleRestrictionModal = () => {
        if (restrictionModalOpen) {
            dispatch(logOut(null));
        }
        setRestrictionModalOpen(!restrictionModalOpen);
    };

    const [problemModalOpen, setProblemModalOpen] = useState(false);
    const toggleProblemModal = () => {
        setProblemModalOpen(!problemModalOpen);
    };

    useEffect(() => {
        // 라이선스 제한
        if (licenseStatus.serviceRestrictionActive === Y) {
            setRestrictionModalOpen(true);
        } else {
            setRestrictionModalOpen(false);
        }
        // 라이선스 상태가 없다가 생겼을 경우
        if (!Object.keys(prevLicenseStatus).length && licenseStatus?.serviceRestrictionActive === N) {
            // unmount 되기 전까지 prevLicenseStatus 상태가 업데이트 되지 않기 때문에 다음 업데이트 때도 preLicenseStatus 는 빈 객체로 체크하여 두 번 들어오게 됨.
            // 미리 이전 상태로 업데이트하여 다음 체크 시에 진입 방지.
            setPrevLicenseStatus(licenseStatus);
            if (licenseStatus?.verificationViolations?.length) {
                setProblemModalOpen(true);
            }
        }
        return () => {
            setPrevLicenseStatus(licenseStatus);
        };
    }, [licenseStatus]);

    useEffect(() => {
        // 라이선스 제한 : 서비스를 사용하지 못하는 상태
        if (licenseStatus.serviceRestrictionActive === Y) {
            setRestrictionModalOpen(true);
            // 라이선스 위반 : 위반 사항이 있으나 서비스 사용 가능 상태
        } else if (licenseStatus?.verificationViolations?.length) {
            setProblemModalOpen(true);
        }
    }, [location]);

    return (
        <>
            <ConfirmModal
                initModal={restrictionModalOpen}
                confirmText={
                    <>
                        <li>
                            <span className="pnt-label--group">
                                <div className="label-main w-100" style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                                    {t('Service limited. Please check the violations below.')}
                                </div>
                            </span>
                        </li>
                        {licenseStatus?.verificationViolations?.map((msg, i) => (
                            <li key={`validation_${i}`}>
                                <span className="pnt-label--group">
                                    <div className="pnt-txt txt-dot txt-bold s-8 w-100">{t(msg)}</div>
                                </span>
                            </li>
                        ))}
                    </>
                }
                toggleModal={toggleRestrictionModal}
                removeCancel
            />
            {licenseStatus.serviceRestrictionActive !== Y && (
                <ConfirmModal
                    initModal={problemModalOpen}
                    confirmText={
                        <>
                            <li>
                                <span className="pnt-label--group">
                                    <div
                                        className="label-main w-100"
                                        style={{ fontWeight: 'bold', fontSize: '0.9rem' }}
                                    >
                                        {/* 서비스 기간 제한과 관련 있는 위반 사항 일 경우 */}
                                        {licenseStatus?.verificationViolations?.filter(
                                            violation => !LOOSE_VIOLATIONS.includes(violation),
                                        ).length
                                            ? t(
                                                  'You have {{remainingDays}} days left until your service limit. Please check the violations below.',
                                                  {
                                                      remainingDays: Math.max(
                                                          MAX_REMAINING_DAYS -
                                                              (licenseStatus?.verificationFailureDays ?? 0),
                                                          0,
                                                      ),
                                                  },
                                              )
                                            : t('The violations below exist. Please check the violations.')}
                                    </div>
                                </span>
                            </li>
                            {licenseStatus?.verificationViolations?.map((msg, i) => (
                                <li key={`validation_${i}`}>
                                    <span className="pnt-label--group">
                                        <div className="pnt-txt txt-dot txt-bold s-8 w-100">{t(msg)}</div>
                                    </span>
                                </li>
                            ))}
                        </>
                    }
                    toggleModal={toggleProblemModal}
                    removeCancel
                />
            )}
        </>
    );
};

export default LicenseModal;
