import { Button, TextInput } from '@components';
import { useTranslation } from '@hooks';
import React, { useState } from 'react';
import { ASSET_MANAGEMENT } from '../../constants';

const TableSearchBox = ({ onSearchClick, onRefreshClick }) => {
    const t = useTranslation(ASSET_MANAGEMENT);

    const [searchInput, setSearchInput] = useState('');

    const handleRefreshClick = () => {
        onRefreshClick();
        setSearchInput('');
    };

    const handleKeyUp = e => {
        if (e.key?.toUpperCase() === 'ENTER') {
            onSearchClick(searchInput);
        }
    };

    return (
        <div className="d-flex">
            <TextInput
                value={searchInput}
                handleKeyUp={handleKeyUp}
                handleChange={e => setSearchInput(e.target.value)}
                placeholder={t('Enter the name or ID of the asset.')}
            />
            <Button className="btn-secondary" onClick={() => onSearchClick(searchInput)}>
                {t('Search', 'Search')}
            </Button>
            <Button className="btn-line btn-icon-only ml-2" iconName="refresh" onClick={handleRefreshClick} />
        </div>
    );
};

export default TableSearchBox;
