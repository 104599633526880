import { reqDelete, reqFormDataPost, reqGet } from '@api/index';
import { PageInfoParam, PageInfoType, RequestHandlerCreator } from '@api/util';
import { NullableString, UnixTimestamp } from '@util/type/util';

export interface AssetManual {
    manualNum: number;
    comNum: number;
    categoryCode: string;
    categoryName: string;
    description: NullableString;
    regDate: UnixTimestamp;
    fileNum: number;
    fileName: string;
    fileUrl: string;
    fileDownloadUrl: string;
}

export interface AssetManualListParam {}

export interface RegisterParam {
    file: File;
    categoryCode: string;
    description?: NullableString;
}

export interface DeleteParam {
    manualNum: number;
}

// 자산 매뉴얼 목록 조회
export const getAssetManualListApi: RequestHandlerCreator<
    PageInfoType<AssetManual>,
    PageInfoParam & AssetManualListParam
> = param => reqGet('/v3/api/am01/manual', param);

// 자산 매뉴얼 등록
export const registerAssetManualApi: RequestHandlerCreator<{}, { formData: FormData }> = param =>
    reqFormDataPost('/v3/api/am01/manual', param);

// 자산 매뉴얼 삭제
export const deleteAssetManualApi: RequestHandlerCreator<{}, DeleteParam> = param =>
    reqDelete(`/v3/api/am01/manual/${param.manualNum}`);
