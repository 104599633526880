export const measurementDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Measurement Date',
        accessor: 'measurementDate',
        width: 90,
        ...restProps,
    };
};

export const subjectNum = ({ ...restProps } = {}) => {
    return {
        Header: 'Study Number',
        accessor: 'subjectNum',
        width: 90,

        ...restProps,
    };
};

export const stabilizationRoom = ({ ...restProps } = {}) => {
    return {
        Header: 'Stabilization Room',
        accessor: 'fcName',
        width: 90,
        Cell: ({ value }: { value: string }) => {
            return value || '-';
        },
        ...restProps,
    };
};

export const deviceName = ({ ...restProps } = {}) => {
    return {
        Header: 'Device Name',
        accessor: 'sensorName',
        width: 90,
        Cell: ({ value }: { value: string }) => {
            return value || '-';
        },
        ...restProps,
    };
};

export const progressTime = ({ ...restProps } = {}) => {
    return {
        Header: 'Progress time',
        accessor: 'elapsedTime',
        width: 90,
        ...restProps,
    };
};

export const measurementTime = ({ ...restProps } = {}) => {
    return {
        Header: 'Measurement Time',
        accessor: 'measurementTime',
        width: 90,

        ...restProps,
    };
};

export const detailDownload = ({ ...restProps } = {}) => {
    return {
        Header: 'Detail Download',
        accessor: 'detailDownload',
        className: 'd-flex justify-content-center',
        width: 50,
        ...restProps,
    };
};
