import React, { useEffect, useRef } from 'react';
import { Line, Chart } from 'react-chartjs-2';
import 'chartjs-adapter-luxon';
import StreamingPlugin from 'chartjs-plugin-streaming';
import { getColor } from '../../config';
import moment from 'moment';
import { useAppSelector } from '@hooks';

// Chart.js에 실시간 차트 플러그인 넣기
Chart.register(StreamingPlugin);

const MILLISECOND_TO_SECOND = 1000;

const RealTimeLineChart = ({ data, tagOption = 20, dataInteger = false }) => {
    const categoryList = useAppSelector(state => state.CategoryInfo.categoryList);
    // 시간을 이용해 차트에 찍는방식
    const now = moment().valueOf();

    const dataSets = useRef([]);

    useEffect(() => {
        for (let key in data) {
            if (!dataSets.current.find(dataSet => dataSet.id === key)) {
                const color = getColor(dataSets.current.length);
                dataSets.current.push({
                    id: key,
                    label: categoryList.find(category => category.categoryCode === key)?.categoryName ?? key,
                    backgroundColor: color,
                    borderColor: color,
                    borderWidth: 3,
                    cubicInterpolationMode: 'monotone',
                    fill: false,
                    data: [],
                });
            }
        }
        dataSets.current = [...dataSets.current];
    }, [data]);

    return (
        <Line
            type="line"
            width={'100%'}
            height={'100%'}
            data={{
                datasets: dataSets.current,
            }}
            options={{
                // 이 옵션을 넣어야지 위젯크기에 따라 size가 조정됨
                maintainAspectRatio: false,
                animation: {
                    duration: 0,
                },
                plugins: {
                    legend: {
                        display: true,
                        position: 'left',
                        labels: {
                            usePointStyle: true,
                            pointStyle: 'circle',
                            font: {
                                size: 11,
                                weight: 'bold',
                            },
                        },
                        onHover: event => {
                            event.native.target.style.cursor = 'pointer';
                        },
                        onLeave: event => {
                            event.native.target.style.cursor = 'default';
                        },
                    },
                    tooltip: {
                        position: 'nearest',
                        mode: 'index',
                        intersect: false,
                        usePointStyle: true,
                        pointStyle: 'circle',
                        cornerRadius: 0,
                        caretSize: 0,
                        backgroundColor: '#fff',
                        titleColor: '#000000',
                        bodyColor: '#000000',
                        borderColor: '#000000',
                        borderWidth: 0.15,
                        callbacks: {
                            // 아무것도 리턴하지 않으면 borderColor를 따라감
                            labelTextColor: () => {},
                        },
                    },
                },
                scales: {
                    y: {
                        min: 0,
                        ticks: {
                            // y축 단위를 정수 단위로 바꿈
                            callback: dataInteger
                                ? value => {
                                      if (value % 1 === 0) {
                                          return value;
                                      }
                                  }
                                : undefined,
                        },
                    },
                    x: {
                        type: 'realtime',
                        time: {
                            parser: 'timeFormat',
                            // 24시간 format으로 표시
                            tooltipFormat: 'HH:mm:ss.SSS',
                            displayFormats: {
                                second: 'HH:mm:ss',
                                minute: 'HH:mm:ss',
                                hour: 'HH:mm:ss',
                            },
                        },
                        realtime: {
                            delay: 3 * MILLISECOND_TO_SECOND,
                            duration: tagOption * MILLISECOND_TO_SECOND,
                            frameRate: 45,
                            // 최적화를 위해 1분 동안만 데이터 보관
                            ttl: 60 * MILLISECOND_TO_SECOND,
                            onRefresh: () => {
                                for (let key in data) {
                                    const idx = dataSets.current.findIndex(el => el.id === key);
                                    if (~idx) {
                                        dataSets.current[idx].data.push({
                                            x: now,
                                            y: data[key],
                                        });
                                    }
                                }
                            },
                        },
                    },
                },
            }}
        />
    );
};

export default RealTimeLineChart;
