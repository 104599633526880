import React from 'react';
import CountUp from 'react-countup';
import cx from 'classnames';

const ItemCount = ({ className, countClassname, start = 0, end = 0, label }) => {
    return (
        <div className={cx('flex-center col', className)}>
            <div className={'flx-row pnt-txt s-5'}>{label}</div>
            <CountUp
                className={cx('item-num', countClassname)}
                decimals={0}
                start={start}
                end={end}
                decimal=","
                duration="1"
                useEasing={false}
                separator=""
                prefix=""
                suffix=""
            />
        </div>
    );
};

export default ItemCount;
