import React, { useEffect, useState } from 'react';
import TIMES from '@util/times';
import { useDispatch, useSelector } from 'react-redux';
import { startGlobalIntervalMonitoring, stopGlobalMonitoring } from '@reducer/GlobalIntervalTime';
import useTranslation from '@hooks/useTranslation';
import useVisibility from '@hooks/useVisibility';
import Select from '@components/Select';
import Label from '@components/Label';

const POC_COMPANY_CODES = ['11C4BB90-A570-11EE-B8EB-6FD5513EA258', '00000000-0000-0000-0000-000000000001'];
/**
 * 모니터링 위젯 갱신시간 설정 컴포넌트
 */
const IntervalTimeSelect = () => {
    const t = useTranslation('Dashboard');
    const dispatch = useDispatch();
    const { hidden } = useVisibility();
    const { globalIntervalTime } = useSelector(state => state.GlobalIntervalTime);
    const { oAuthInfo } = useSelector(state => state.UserInfo);

    const [selectedTime, setSelectedTime] = useState(globalIntervalTime);

    // interval time set
    const intervalTimeSet = [TIMES.ZERO, TIMES.SEC_5, TIMES.SEC_10, TIMES.SEC_15, TIMES.SEC_20, TIMES.SEC_30].map(
        value => ({
            value,
            label: value === TIMES.ZERO ? t('Not update') : `${Math.floor(value / TIMES.SEC_1)} ${t('seconds')}`,
        }),
    );

    // 탭 비활성화 시, 갱신 안함
    const stopInterval = () => {
        dispatch(stopGlobalMonitoring());
    };

    // 갱신 설정 및 시작
    const handleIntervalTime = selected => {
        setSelectedTime(selected);
        dispatch(startGlobalIntervalMonitoring(selected.value));
    };

    // 갱신 안함 interval time stop
    useEffect(() => {
        if (hidden) {
            stopInterval();
        } else {
            const time = Math.floor(globalIntervalTime / TIMES.SEC_1);
            handleIntervalTime({
                value: globalIntervalTime,
                label: `${time} ${t('seconds')}`,
            });
        }
    }, [hidden]);

    return (
        // TODO: POC 용 임시처리
        !POC_COMPANY_CODES.includes(oAuthInfo?.scope) && (
            <Label
                name={t('Auto renewal')}
                labelGroupClassName="pnt-label-2 ml-auto"
                labelValueClassName={'w-auto'}
                value={<Select value={selectedTime} options={intervalTimeSet} onChange={handleIntervalTime} />}
            />
        )
    );
};

export default IntervalTimeSelect;
