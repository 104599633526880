export const COLUMN_METAS = [
    {
        key: 'regDate',
        name: '날짜',
    },
    {
        key: 'wardName',
        name: '부서/병동',
    },
    {
        key: 'categoryName',
        name: '카테고리',
    },
    {
        key: 'dayCount',
        name: 'Day 입력 수량',
    },
    {
        key: 'dayRTLSCount',
        name: 'Day 등록 수량',
    },
    {
        key: 'dayCheckerName',
        name: '점검자 명',
    },
    {
        key: 'dayCheckerId',
        name: '계정',
    },
    {
        key: 'dayCheckDate',
        name: 'Day 점검 일시',
    },
    {
        key: 'eveningCount',
        name: 'Evening 입력 수량',
    },
    {
        key: 'eveningRTLSCount',
        name: 'Evening 등록 수량',
    },
    {
        key: 'eveningCheckerName',
        name: '점검자 명',
    },
    {
        key: 'eveningCheckerId',
        name: '계정',
    },
    {
        key: 'eveningCheckDate',
        name: 'Evening 점검 일시',
    },
    {
        key: 'nightCount',
        name: 'Night 입력 수량',
    },
    {
        key: 'nightRTLSCount',
        name: 'Night 등록 수량',
    },
    {
        key: 'nightCheckerName',
        name: '점검자 명',
    },
    {
        key: 'nightCheckerId',
        name: '계정',
    },
    {
        key: 'nightCheckDate',
        name: 'Night 점검 일시',
    },
    {
        key: 'memo',
        name: '메모',
    },
];

export const PREFIX_LIST = ['day', 'evening', 'night'];

export const DEFAULT_VALUE = '-';
export const FIXED_PARAMS = {
    pageSize: 200,
    page: 1,
};

export const DEFAULT_RESPONSE = {
    ...FIXED_PARAMS,
    rows: [],
    totalCount: 0,
    totalPage: 0,
};

export const ROWS_DUMMY = [
    {
        logNum: 8,
        categoryCode: 'wheelchair',
        categoryName: '휠체어',
        targetName: '휠체어',
        ward: 23,
        wardName: '중앙병동1층',
        comNum: 2,
        dayCount: 0,
        dayRTLSCount: 0,
        dayCheckerId: 'am01@pntbiz.com',
        dayCheckerName: '스마트공공병원원',
        dayCheckDate: 1702273398,
        eveningCount: 0,
        eveningRTLSCount: 0,
        eveningCheckerId: 'am01@pntbiz.com',
        eveningCheckerName: '스마트공공병원원',
        eveningCheckDate: 1702275100,
        nightCount: null,
        nightRTLSCount: null,
        nightCheckerId: null,
        nightCheckerName: null,
        nightCheckDate: null,
        memo: '미점검',
        checkDateStr: '2023-12-11',
        dayCheckDateStr: '2023-12-11 14:43:18',
        eveningCheckDateStr: '2023-12-11 15:11:40',
        nightCheckDateStr: null,
    },
    {
        logNum: 7,
        categoryCode: 'defibrillator',
        categoryName: '제세동기',
        targetName: '제세동기',
        ward: 23,
        wardName: '중앙병동1층',
        comNum: 2,
        dayCount: 1,
        dayRTLSCount: null,
        dayCheckerId: 'am01@pntbiz.com',
        dayCheckerName: '스마트공공병원원',
        dayCheckDate: 1702276692,
        eveningCount: 2,
        eveningRTLSCount: 0,
        eveningCheckerId: 'am01@pntbiz.com',
        eveningCheckerName: '스마트공공병원원',
        eveningCheckDate: 1702277270,
        nightCount: null,
        nightRTLSCount: null,
        nightCheckerId: null,
        nightCheckerName: null,
        nightCheckDate: null,
        memo: 'Night 점검요망',
        checkDateStr: '2023-12-11',
        dayCheckDateStr: '2023-12-11 15:38:12',
        eveningCheckDateStr: '2023-12-11 15:47:50',
        nightCheckDateStr: null,
    },
    {
        logNum: 5,
        categoryCode: 'wheelchair',
        categoryName: '휠체어',
        targetName: '휠체어',
        ward: 23,
        wardName: '중앙병동1층',
        comNum: 2,
        dayCount: 0,
        dayRTLSCount: null,
        dayCheckerId: 'am01@pntbiz.com',
        dayCheckerName: '스마트공공병원원',
        dayCheckDate: 1702124294,
        eveningCount: null,
        eveningRTLSCount: null,
        eveningCheckerId: 'am01@pntbiz.com',
        eveningCheckerName: '스마트공공병원원',
        eveningCheckDate: 1702124294,
        nightCount: null,
        nightRTLSCount: null,
        nightCheckerId: 'am01@pntbiz.com',
        nightCheckerName: '스마트공공병원원',
        nightCheckDate: 1702124294,
        memo: '중앙병동 대여 +1',
        checkDateStr: '2023-12-09',
        dayCheckDateStr: '2023-12-09 21:18:14',
        eveningCheckDateStr: '2023-12-09 21:18:14',
        nightCheckDateStr: '2023-12-09 21:18:14',
    },
    {
        logNum: 4,
        categoryCode: 'defibrillator',
        categoryName: '제세동기',
        targetName: '제세동기',
        ward: 23,
        wardName: '중앙병동1층',
        comNum: 2,
        dayCount: 0,
        dayRTLSCount: null,
        dayCheckerId: 'am01@pntbiz.com',
        dayCheckerName: '스마트공공병원원',
        dayCheckDate: 1702125214,
        eveningCount: 2,
        eveningRTLSCount: null,
        eveningCheckerId: 'am01@pntbiz.com',
        eveningCheckerName: '스마트공공병원원',
        eveningCheckDate: 1702125221,
        nightCount: null,
        nightRTLSCount: null,
        nightCheckerId: 'am01@pntbiz.com',
        nightCheckerName: '스마트공공병원원',
        nightCheckDate: 1702124294,
        memo: null,
        checkDateStr: '2023-12-09',
        dayCheckDateStr: '2023-12-09 21:33:34',
        eveningCheckDateStr: '2023-12-09 21:33:41',
        nightCheckDateStr: '2023-12-09 21:18:14',
    },
    {
        logNum: 2,
        categoryCode: 'wheelchair',
        categoryName: '휠체어',
        targetName: '휠체어',
        ward: 23,
        wardName: '중앙병동1층',
        comNum: 2,
        dayCount: 5,
        dayRTLSCount: 5,
        dayCheckerId: 'am01@pntbiz.com',
        dayCheckerName: '스마트공공병원원',
        dayCheckDate: 1702125214,
        eveningCount: 3,
        eveningRTLSCount: 3,
        eveningCheckerId: 'am01@pntbiz.com',
        eveningCheckerName: '스마트공공병원원',
        eveningCheckDate: 1702125221,
        nightCount: 5,
        nightRTLSCount: 5,
        nightCheckerId: 'am01@pntbiz.com',
        nightCheckerName: '스마트공공병원원',
        nightCheckDate: 1702124294,
        memo: '점검 완료',
        checkDateStr: '2023-12-09',
        dayCheckDateStr: '2023-12-09 21:33:34',
        eveningCheckDateStr: '2023-12-09 21:33:41',
        nightCheckDateStr: '2023-12-09 21:18:14',
    },
];
