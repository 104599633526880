import React from 'react';
import MobileMenuPath from './Components/MobileMenuPath';

const Page = ({ menuPath, children, ...restProps }) => {
    return (
        <div {...restProps}>
            {menuPath && Array.isArray(menuPath) && menuPath.length && <MobileMenuPath menuPath={menuPath} />}
            {children}
        </div>
    );
};

export default Page;
