import React, { useContext, useRef } from 'react';
import WidgetCard from '../../Components/WidgetCard';
import OutpatientProofIssuanceItemContainer from './OutpatientProofIssuanceItemContainer';
import OutpatientProofIssuanceStatusSlice from './OutpatientProofIssuanceStatusSlice';
import ContextProvider from '@components/ContextProvider';

export const OutpatientProofIssuanceStatusStateContext = React.createContext();
export const OutpatientProofIssuanceStatusDispatchContext = React.createContext();
export const useOutpatientProofContext = () => useContext(OutpatientProofIssuanceStatusStateContext);
export const useOutpatientProofDispatch = () => useContext(OutpatientProofIssuanceStatusDispatchContext);
const OutpatientProofIssuanceStatus = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();

    return (
        <ContextProvider
            StateContext={OutpatientProofIssuanceStatusStateContext}
            DispatchContext={OutpatientProofIssuanceStatusDispatchContext}
            slice={OutpatientProofIssuanceStatusSlice}
        >
            <WidgetCard ref={widgetRef} widgetInfo={widgetInfo} {...restProps}>
                <OutpatientProofIssuanceItemContainer widgetInfo={widgetInfo} />
                {children}
            </WidgetCard>
        </ContextProvider>
    );
};

export default OutpatientProofIssuanceStatus;
