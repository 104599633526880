export const category = ({ ...restProps } = {}) => {
    return {
        Header: 'Category',
        headerClassName: 'text-ellipsis',
        accessor: 'category',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const ward = ({ ...restProps } = {}) => {
    return {
        Header: 'Ward',
        headerClassName: 'text-ellipsis',
        accessor: 'department',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const speciality = ({ ...restProps } = {}) => {
    return {
        Header: 'Speciality',
        headerClassName: 'text-ellipsis',
        accessor: 'specialties',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const doctor = ({ ...restProps } = {}) => {
    return {
        Header: 'Doctor',
        headerClassName: 'text-ellipsis',
        accessor: 'doctor',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const id = ({ ...restProps } = {}) => {
    return {
        Header: 'Patient ID',
        headerClassName: 'text-ellipsis',
        accessor: 'patientRegNum',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const patientName = ({ ...restProps } = {}) => {
    return {
        Header: 'Patient Name',
        headerClassName: 'text-ellipsis',
        accessor: 'patientName',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const reservedDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Reserved Date',
        headerClassName: 'text-ellipsis',
        accessor: 'appointmentDate',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const reservedTime = ({ ...restProps } = {}) => {
    return {
        Header: 'Reserved Time',
        headerClassName: 'text-ellipsis',
        accessor: 'appointmentTime',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const arrivedTime = ({ ...restProps } = {}) => {
    return {
        Header: 'Arrived Time',
        headerClassName: 'text-ellipsis',
        accessor: 'arrivalTime',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const basicTest = ({ ...restProps } = {}) => {
    return {
        Header: 'Basic Inspection',
        headerClassName: 'text-ellipsis',
        accessor: 'isFoundationExam',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const waitingCare = ({ ...restProps } = {}) => {
    return {
        Header: 'Waiting for an appointment',
        headerClassName: 'text-ellipsis',
        accessor: 'isWaiting',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const care = ({ ...restProps } = {}) => {
    return {
        Header: 'Care Status',
        headerClassName: 'text-ellipsis',
        accessor: 'isCare',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const receiptWon = ({ ...restProps } = {}) => {
    return {
        Header: 'Amount received',
        headerClassName: 'text-ellipsis',
        accessor: 'amount',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const pgResultCode = ({ ...restProps } = {}) => {
    return {
        Header: 'PG Result Code',
        headerClassName: 'text-ellipsis',
        accessor: 'pgResultCode',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const pgResult = ({ ...restProps } = {}) => {
    return {
        Header: 'PG Result',
        headerClassName: 'text-ellipsis',
        accessor: 'pgResult',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const emrResultCode = ({ ...restProps } = {}) => {
    return {
        Header: 'EMR Result Code',
        headerClassName: 'text-ellipsis',
        accessor: 'emrResultCode',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const issueDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Issue Date',
        headerClassName: 'text-ellipsis',
        accessor: 'publishDate',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const issueTime = ({ ...restProps } = {}) => {
    return {
        Header: 'Issue time',
        headerClassName: 'text-ellipsis',
        accessor: 'publishTime',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const issuanceCertificate = ({ ...restProps } = {}) => {
    return {
        Header: 'Certificate of Publication',
        headerClassName: 'text-ellipsis',
        accessor: 'publishCertificate',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
