import { Children, isValidElement, ReactNode } from 'react';
import { components } from 'react-select';
import { OptionType } from '@components/type';

export function getChildrenTypeNode(children: ReactNode, targetComponentName: keyof typeof components) {
    const childrenArray = Children.toArray(children);

    const targetChildren = childrenArray.filter(
        child => isValidElement(child) && typeof child.type === 'function' && child.type.name === targetComponentName,
    );
    const otherChildren = childrenArray.filter(
        child => isValidElement(child) && typeof child.type === 'function' && child.type.name !== targetComponentName,
    );

    return { targetChildren, otherChildren };
}

function addLeafNode(treeData: OptionType[], dataToAdd: OptionType, treeKey: string, parentKey: string) {
    const parent = dataToAdd[parentKey];
    let find = false;

    // 자식 탐색
    treeData.every(v => {
        if (v[treeKey] === parent) {
            if (v.children) {
                v.children.push(dataToAdd);
            } else {
                v.children = [dataToAdd];
            }
            find = true;
            return false;
        }
        return true;
    });

    // 자식 탐색 실패시 자식의 자식 탐색
    if (!find) {
        treeData.every(v => {
            if (v.children) {
                find = addLeafNode(v.children, dataToAdd, treeKey, parentKey);
                if (find) {
                    return false;
                }
            }
            return true;
        });
    }
    return find;
}
export function makeTreeData(
    treeData: OptionType[] = [],
    flattenData: OptionType[],
    treeKey: string,
    parentKey: string,
) {
    const dataToAdd = [...flattenData];
    const restData = [];

    if (treeData.length) {
        while (dataToAdd.length > 0) {
            const curr = dataToAdd.shift() ?? {};
            const found = addLeafNode(treeData, curr, treeKey, parentKey);
            if (!found) {
                restData.push(curr);
            }
        }
    }

    if (restData.length > 0) {
        makeTreeData(treeData, restData, treeKey, parentKey);
    }
    return treeData;
}

export type TreeState = {
    title?: string;
    labelKey: string;
    valueKey: string;
    treeKey: string;
    parentKey: string;
    flattenData: OptionType[];
    treeData: OptionType[];
};

type TreeActionType =
    | { type?: 'UPDATE_FLATTEN_DATA'; payload: OptionType[] }
    | { type?: 'UPDATE_CHECKED_NODE'; payload: boolean }
    | { type?: 'UPDATE_TITLE'; payload?: string };

// Tree Reducyer
export function reducer(state: TreeState, action: TreeActionType) {
    switch (action.type) {
        case 'UPDATE_FLATTEN_DATA':
            const { valueKey, treeKey, labelKey, parentKey } = state;
            const flattenData: OptionType[] = action.payload.map(v => ({
                ...v,
                value: v[valueKey],
                label: v[labelKey],
            }));
            return {
                ...state,
                flattenData: flattenData,
                treeData: makeTreeData(
                    flattenData.filter(v => !v[parentKey]),
                    flattenData.filter(v => !!v[parentKey]),
                    treeKey,
                    parentKey,
                ),
            };
        case 'UPDATE_CHECKED_NODE':
            return { ...state, checked: action.payload };
        case 'UPDATE_TITLE':
            return { ...state, title: action.payload };
        default:
            throw new Error(`Unknown action type: ${action.type}`);
    }
}

export type SearchableSelectState = {
    title?: string;
    labelKey: string;
    valueKey: string;
};

type SearchableSelectActionType = {
    type: 'UPDATE_TITLE';
    payload?: string;
};

// Searchable Reducyer
export const SearchableSelectReducer = (state: SearchableSelectState, action: SearchableSelectActionType) => {
    switch (action.type) {
        case 'UPDATE_TITLE':
            return { ...state, title: action.payload };
        default:
            throw new Error(`Unknown action type: ${action.type}`);
    }
};
