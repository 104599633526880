import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from '@hooks';
import { Select, Button, DatePicker } from '@components';
import SingleTreeSelect from '@components/Select/SingleTreeSelect';

import { setEndDate, setSelectedCategory, setSelectedFloor, setStartDate } from '../hourlyCongestionStatusSlice';
import { FilterList, SelectGroup } from '../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '../../../../MainPages/Components/FilterSearchGroup';
import SearchGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import SearchButtonArea from '../../../../MainPages/Components/FilterSearchGroup/Components/SearchButtonArea';
import FilterGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/FilterGroup';
import { useHourlyCongestionStatusContext, useHourlyCongestionStatusDispatch } from '../index';

const HourlyCongestionStatusFilterGroup = ({ handleSearchBtnClick }) => {
    const t = useTranslation('CongestionStatus');
    const [categoryListWithAllOption, setCategoryListWithAllOption] = useState([]);
    const { floorList } = useSelector(state => state.FloorInfo);
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const { selectedCategory, selectedFloor, startDate, endDate } = useHourlyCongestionStatusContext();
    const dispatch = useHourlyCongestionStatusDispatch();

    const handleSelectCategoryChange = e => {
        dispatch(setSelectedCategory(e));
    };

    const handleYesterdayBtnClick = () => {
        dispatch(setStartDate(moment().subtract(1, 'days').startOf('day').valueOf()));
        dispatch(setEndDate(moment().subtract(1, 'days').endOf('day').valueOf()));
    };

    const handleTodayBtnClick = () => {
        dispatch(setStartDate(moment().startOf('day').valueOf()));
        dispatch(setEndDate(moment().endOf('day').valueOf()));
    };

    const handleFloorChange = selects => {
        if (selects) {
            dispatch(setSelectedFloor(selects.floorId));
        }
    };

    useEffect(() => {
        setCategoryListWithAllOption([{ categoryCode: '', categoryName: t('All') }, ...categoryList]);
    }, [categoryList]);

    // 시작날짜(시간)이 종료날짜(시간)를 넘어가는 경우 종료날짜를 시작날짜로 초기화
    useEffect(() => {
        if (startDate > endDate) {
            dispatch(setEndDate(startDate));
        }
    }, [startDate, endDate]);

    return (
        <>
            <FilterSearchGroup className={'card absolute-filter'}>
                <FilterGroup label={t('Filter')}>
                    <Select
                        placeholder={t('All')}
                        value={categoryListWithAllOption.length > 0 ? selectedCategory : []}
                        onChange={handleSelectCategoryChange}
                        options={categoryListWithAllOption}
                        valueKey={'categoryCode'}
                        labelKey={'categoryName'}
                        listStyle={{ height: '200px', overflow: 'auto' }}
                    />
                    <SingleTreeSelect
                        data={floorList}
                        title={'Category'}
                        valueKey={'floorId'}
                        labelKey={'floorName'}
                        parentKey={'upperFloorId'}
                        selected={selectedFloor}
                        onChange={handleFloorChange}
                    />
                </FilterGroup>
                <SearchGroup label={t('Search')}>
                    <FilterList>
                        <SelectGroup>
                            <DatePicker
                                value={startDate}
                                handleChange={selected => dispatch(setStartDate(selected))}
                                valueType={'ms'}
                                maxDate={endDate}
                                showTimeInput
                            />
                        </SelectGroup>
                        <div> ~ </div>
                        <SelectGroup>
                            <DatePicker
                                value={endDate}
                                handleChange={selected => dispatch(setEndDate(selected))}
                                valueType={'ms'}
                                minDate={startDate}
                                maxDate={moment().valueOf()}
                                showTimeInput
                            />
                        </SelectGroup>
                        <SearchButtonArea>
                            <div className={'gap-1 flx-row'}>
                                <Button className={'btn-gray btn-yesterday'} onClick={handleYesterdayBtnClick}>
                                    {t('Yesterday', 'Button')}
                                </Button>
                                <Button className={'btn-gray btn-yesterday'} onClick={handleTodayBtnClick}>
                                    {t('Today', 'Button')}
                                </Button>
                                <Button className="pnt-btn btn-secondary" onClick={handleSearchBtnClick}>
                                    {t('Search')}
                                </Button>
                            </div>
                        </SearchButtonArea>
                    </FilterList>
                </SearchGroup>
            </FilterSearchGroup>
        </>
    );
};

export default HourlyCongestionStatusFilterGroup;
