import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { components, MenuListProps, Options, PlaceholderProps } from 'react-select';
import useTranslation from '../../../../util/hooks/useTranslation';
import Tree from './Tree';
import { TreeSelectContext } from './index';

import { OptionType } from '@components/type';
import { CommonMenuList } from '@components/Select/Components/Common';

// 하위 노드가 모두 선택 되어있을 때, 부모노드를 선택항목으로 추가
export function mergeSelected(
    flattenData: OptionType[],
    treeKey: string,
    parentKey: string,
    selected: Options<OptionType>,
    selectedNode?: OptionType,
) {
    let mergedSelected = [...selected];

    // 부모 노드키
    const parents = selectedNode
        ? [selectedNode[parentKey]]
        : selected.reduce((acc, curr) => {
              if (curr[parentKey]) {
                  acc.push(curr[parentKey]);
              }
              return acc;
          }, []);

    // 자식 노드가 모두 선택되어 있는지 체크
    const childrenLenCheck = parents.reduce((acc: OptionType, curr: string) => {
        const childrenLen = flattenData.filter(v => v[parentKey] === curr).length;
        const selectedLen = selected.filter(v => v[parentKey] === curr).length;
        if (curr) {
            acc[curr] = childrenLen === selectedLen;
        }
        return acc;
    }, {});

    // 상위 자식이 선택되어 있는 경우 하위 자식 요소 제거
    for (let parent in childrenLenCheck) {
        if (childrenLenCheck.hasOwnProperty(parent) && childrenLenCheck[parent]) {
            const parentNode = flattenData.find(node => node[treeKey] === parent) ?? [];
            const childrenIdList = findAllChildren(flattenData, treeKey, parentKey, [parentNode])
                .map(node => node[treeKey])
                .filter(nodeId => nodeId !== parent);
            childrenIdList.forEach(childrenId => {
                if (childrenLenCheck[childrenId]) {
                    childrenLenCheck[childrenId] = null;
                    delete childrenLenCheck[childrenId];
                }
            });
        }
    }

    // 자식이 모두 선택되어 있다면 부모도 선택 항목에 추가
    for (let parent in childrenLenCheck) {
        if (childrenLenCheck.hasOwnProperty(parent)) {
            const checked = childrenLenCheck[parent];
            if (checked) {
                const foundIndex = mergedSelected.findIndex(v => v[parentKey] === parent);
                mergedSelected = removeAllChildren(mergedSelected, treeKey, parentKey, parent);
                if (!mergedSelected.find(v => v[treeKey] === parent)) {
                    const parentNode = flattenData.find(v => v[treeKey] === parent);
                    if (parentNode) {
                        mergedSelected.splice(foundIndex, 0, parentNode);
                        mergedSelected = mergeSelected(flattenData, treeKey, parentKey, mergedSelected, parentNode);
                    }
                }
            }
        }
    }

    return mergedSelected;
}

/**
 * 모든 자식 요소를 제거한 목록 반환
 *
 * @param nodeList 대상 목록
 * @param treeKey 키 필드명
 * @param parentKey 부모 키 필드명
 * @param parentId 찾을 부모 ID
 *
 * @return parentId의 모든 자식 요소가 제거된 목록
 *
 * */
const removeAllChildren = (nodeList: OptionType[], treeKey: string, parentKey: string, parentId: string) => {
    const childrenList: string[] = [];
    nodeList.forEach(node => {
        if (node[parentKey] === parentId) {
            const nodeId = node[treeKey];
            childrenList.push(nodeId);
        }
    });
    let removedList = nodeList.filter(node => !childrenList.includes(node[treeKey]));
    childrenList.forEach(newParentId => {
        if (removedList.find(node => node[parentKey] === newParentId)) {
            removedList = removeAllChildren(removedList, treeKey, parentKey, newParentId);
        }
    });

    return removedList;
};

/**
 * 선택한 목록의 목록의 모든 자식 요소가 포함된 목록 반환
 *
 * @param flattenData 전체 목록
 * @param treeKey 키 필드명
 * @param parentKey 부모 키 필드명
 * @param selected 선택한 요소의 목록
 *
 * @return selected의 모든 자식 요소가 포함된 목록
 *
 * */
export const findAllChildren = (
    flattenData: OptionType[],
    treeKey: string,
    parentKey: string,
    selected: Options<OptionType>,
) => {
    let allNode = [...selected];
    selected.forEach(selectedNode => {
        if (selectedNode) {
            const findId = selectedNode[treeKey];
            flattenData.forEach(node => {
                if (node[parentKey] === findId && !allNode.find(v => v[treeKey] === node[treeKey])) {
                    allNode.push(...findAllChildren(flattenData, treeKey, parentKey, [node]));
                }
            });
        }
    });
    return allNode;
};

export const ValueContainerText = ({ children, ...restProps }: PlaceholderProps<OptionType>) => {
    const t = useTranslation('TreeSelect');
    const { title, labelKey, treeKey, parentKey, flattenData } = useContext(TreeSelectContext);
    const { getValue } = restProps;
    const selected = getValue();
    const mergedSelection = useMemo(() => {
        return mergeSelected(flattenData, treeKey, parentKey, selected);
    }, [treeKey, parentKey, flattenData, selected]);

    const selectedLength = mergedSelection ? mergedSelection.length : 0;
    const totalSelectedLength = mergedSelection ? mergedSelection.filter(v => !v[parentKey]).length : 0;

    const totalLength = (() => {
        const dataList = [];
        if (flattenData.length) {
            flattenData.forEach(data => {
                if (!data[parentKey]) {
                    dataList.push(data);
                }
            });
        }
        return dataList.length;
    })();

    return (
        <components.Placeholder {...restProps}>
            {!selectedLength || totalSelectedLength === totalLength ? (
                <strong className={'w-100'}>
                    {title} : {t('All')}
                </strong>
            ) : selectedLength === 1 ? (
                <strong className={'w-100 text-ellipsis'}>{mergedSelection[0][labelKey]}</strong>
            ) : (
                <span className={'d-flex w-100'}>
                    <strong className={'text-ellipsis'}>{mergedSelection[0][labelKey]}</strong>
                    <span className={'text-nowrap'}>
                        &nbsp;{t('other')} {selectedLength - 1}
                    </span>
                </span>
            )}
        </components.Placeholder>
    );
};

export const findAllOptions = (options: Options<OptionType>, allOptions: OptionType[] = []) => {
    options.forEach(option => {
        allOptions.push(option.value);
        if (option.children) {
            findAllOptions(option.children, allOptions);
        }
    });
    return allOptions;
};

export const TreeMenuList = ({ children, ...restProps }: MenuListProps<OptionType>) => {
    const t = useTranslation('TreeSelect');
    const { getValue, setValue, options } = restProps;
    const selected = getValue();
    const { valueKey, labelKey, treeKey, parentKey, flattenData } = useContext(TreeSelectContext);
    const mergedSelection = useMemo(() => {
        return mergeSelected(flattenData, treeKey, parentKey, selected);
    }, [treeKey, parentKey, flattenData, selected]);

    const allOptions = useMemo(() => {
        return findAllOptions(options);
    }, [options]);

    // // 자기 자신과 하위 노드들을 선택
    // // @param checked 체크된 노드들의 valueKey
    // // @return 하위 노드들을 포함한 노드
    // const makeCheckedDescendantsNode = useCallback(
    //     checked => {
    //         let descendantsNode = [];
    //         checked.forEach(v => {
    //             descendantsNode.push(flattenData.find(original => original[valueKey] === v));
    //             descendantsNode = descendantsNode.concat(
    //                 flattenData.filter(original => ~original[valueKey].indexOf(v + '>')),
    //             );
    //         });
    //         return descendantsNode.map(v => v[valueKey]);
    //     },
    //     [valueKey, flattenData],
    // );
    //
    // // 초기 체크될 값
    // const [checked, setChecked] = useState(selected ? makeCheckedDescendantsNode(selected.map(v => v[valueKey])) : []);
    const [checked, setChecked] = useState(
        selected.map(v => {
            if (typeof v === 'object') {
                return v[valueKey];
            }
            return v;
        }),
    );

    const handleCheck = useCallback(checked => {
        setChecked(checked);
    }, []);

    useEffect(() => {
        setChecked(
            selected.map(v => {
                if (typeof v === 'object') {
                    return v[valueKey];
                }
                return v;
            }),
        );
    }, [selected.length]);

    // useEffect(() => {
    //     const calculatedChecked = [];
    //     checked.forEach(treePath => {
    //         const lastNode = calculatedChecked[calculatedChecked.length - 1];
    //         if (!lastNode || (lastNode && treePath.indexOf(lastNode[valueKey] + '>') !== 0)) {
    //             calculatedChecked.push(flattenData.find(option => option[valueKey] === treePath));
    //         }
    //     });
    //     setValue(calculatedChecked);
    // }, [checked]);

    useEffect(() => {
        const checkedToStr = checked.map(v => v.toString());
        setValue(
            flattenData.filter(v => checkedToStr.includes(v[valueKey].toString())).map(v => v[valueKey]),
            'select-option',
        );
    }, [checked, valueKey]);

    return (
        <CommonMenuList
            selected={mergedSelection}
            valueKey={valueKey}
            labelKey={labelKey}
            handleSelectedClick={option => {
                const filtered = mergedSelection.filter(
                    selectedOption => selectedOption[valueKey] !== option[valueKey],
                );
                setChecked(
                    findAllChildren(flattenData, treeKey, parentKey, filtered).map(
                        selectedOption => selectedOption[valueKey],
                    ),
                );
            }}
            allOptions={allOptions}
            treeOption={true}
            {...restProps}
        >
            {!selected.length && !options.length ? (
                <div className={'styled-option-label'}>{t('No matches found')}</div>
            ) : (
                <div>
                    <Tree data={options} checked={checked} setChecked={handleCheck} />
                </div>
            )}
        </CommonMenuList>
    );
};
