import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DepartmentInfo } from '@api/so/department';
import { CategoryPropertyInfo } from '@api/common/category';

interface DepartmentsInfo {
    departmentList: DepartmentInfo[];
    wardMapInfo: Record<string, string>;
}

const initialState: DepartmentsInfo = {
    departmentList: [],
    wardMapInfo: {},
};

const { actions, reducer } = createSlice({
    name: 'common/departmentsInfo',
    initialState,
    reducers: {
        setDepartmentsInfo: (
            state,
            action: PayloadAction<{ departmentList: DepartmentInfo[]; propertiesList: CategoryPropertyInfo[] }>,
        ) => {
            const { departmentList, propertiesList } = action.payload;
            // state Department
            state.departmentList = departmentList;

            // state WardMapInfo
            const MONITORING_CATEGORY = 'PATIENT';
            const PROP_ID_WARD = 'ward';

            state.wardMapInfo = propertiesList.reduce((acc: Record<string, string>, prop) => {
                if (prop.categoryCode === MONITORING_CATEGORY && prop.propertyId === PROP_ID_WARD) {
                    if (typeof prop.inputValues !== 'string') {
                        prop.inputValues.forEach(propOption => {
                            acc[propOption.value] = propOption.name;
                        });
                    }
                }
                return acc;
            }, {});
        },
    },
});

export const { setDepartmentsInfo } = actions;
export default reducer;
