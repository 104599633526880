import React, { useContext, useState } from 'react';
import { editWidgetSettings } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import { useSettings } from '../../util/useSettings';
import useTranslation from '@hooks/useTranslation';
import Label from '@components/Label';
import WidgetSettingModal from '../../Components/WidgetSettingModal';
import { Select } from '@components';
import { HiddenMapTile } from '../../Components/WidgetSettingOptions';
import { ScreenEditDispatchContext } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit';
import { useSelector } from 'react-redux';

const ASSET = 'ASSET';
const PATIENT = 'PATIENT';
const PROTECTOR = 'PROTECTOR';

// 모든 카테고리 속성에서 자산, 환자, 보호자의 속성 이름만 추출
function getCategoryPropertyList(list) {
    const result = {};

    list.forEach(item => {
        const { categoryCode, propertyId, displayName } = item;

        if (!result[categoryCode]) {
            result[categoryCode] = [];
        }

        result[categoryCode].push({
            value: propertyId,
            label: displayName,
        });
    });

    return result;
}

const IotItemStatusSettingForSecurity = ({ widgetInfo, ...restProps }) => {
    const dispatch = useContext(ScreenEditDispatchContext);
    const t = useTranslation('IotItemStatus');
    const categoryOptions = [
        { value: ASSET, label: t('asset') },
        { value: PATIENT, label: t('patient') },
        { value: PROTECTOR, label: t('protector') },
    ];
    const emptyOption = { value: null, label: t('No options', 'Select') };
    const { config } = widgetInfo;
    const { settings: customSettings } = config;
    const settings = useSettings(config);
    const { categoryPropertiesList } = useSelector(state => state.CategoryInfo);
    const categoryPropertyOptions = getCategoryPropertyList(categoryPropertiesList);
    const initialValue = {
        categoryCode: settings.categoryCode,
        categoryPropertyId:
            // 선택한 property id 없는 경우
            settings.categoryPropertyId
                ? categoryPropertyOptions[settings.categoryCode].find(e => e.value === settings.categoryPropertyId)
                      .value
                : categoryPropertyOptions[settings.categoryCode]
                ? categoryPropertyOptions[settings.categoryCode][0].value
                : null,
    };
    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState(initialValue);

    const handleSubmitClick = data => {
        let settingInfo = { ...data, ...updatedWidgetInfo };

        if (customSettings) {
            settingInfo = { ...customSettings, ...settingInfo };
        }

        dispatch(editWidgetSettings({ id: widgetInfo.id, settings: settingInfo }));
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo(initialValue);
    };

    const handleHiddenMapTile = checkedHidden => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, hiddenMapTile: checkedHidden };
        });
    };

    const handleCategoryChange = selectedCategory => {
        setUpdatedWidgetInfo({
            categoryCode: selectedCategory.value,
            // category가 바뀌면 category 속성은 options의 첫 번째 value
            categoryPropertyId: categoryPropertyOptions[selectedCategory.value]
                ? categoryPropertyOptions[selectedCategory.value][0].value
                : null,
        });
    };

    const handleCategoryPropertyChange = selected => {
        setUpdatedWidgetInfo({
            ...updatedWidgetInfo,
            categoryPropertyId: selected.value,
        });
    };

    const categoryValue = categoryOptions.find(e =>
        updatedWidgetInfo.categoryCode ? e.value === updatedWidgetInfo.categoryCode : e.value === settings.categoryCode,
    );

    const categoryPropertyValue =
        updatedWidgetInfo.categoryCode &&
        updatedWidgetInfo.categoryPropertyId &&
        categoryPropertyOptions[updatedWidgetInfo.categoryCode]
            ? categoryPropertyOptions[updatedWidgetInfo.categoryCode].find(
                  e => e.value === updatedWidgetInfo.categoryPropertyId,
              )
            : emptyOption;

    return (
        <WidgetSettingModal
            headerTitle={t('Iot Item Status Setting For Security Guards')}
            widgetInfo={widgetInfo}
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <HiddenMapTile
                updateWidgetInfo={updatedWidgetInfo}
                customSettings={customSettings}
                onChange={handleHiddenMapTile}
            />
            <Label
                name={t('Type of Category', 'CategoryChart')}
                labelGroupClassName={'w-100'}
                value={
                    <Select
                        options={categoryOptions}
                        value={categoryValue}
                        onChange={handleCategoryChange}
                        isModalSelect
                    />
                }
            />
            <Label
                name={t('Category Properties Setting')}
                labelGroupClassName={'w-100'}
                value={
                    <Select
                        options={categoryPropertyOptions[updatedWidgetInfo.categoryCode] ?? [emptyOption]}
                        value={categoryPropertyValue}
                        onChange={handleCategoryPropertyChange}
                        isModalSelect
                        disabled={!categoryPropertyOptions[updatedWidgetInfo.categoryCode]}
                    />
                }
            />
        </WidgetSettingModal>
    );
};

export default IotItemStatusSettingForSecurity;
