import React from 'react';
import moment from 'moment';
import { useAsync } from '@hooks';
import { getWasteTreatmentStatusApi } from '@api/sh/waste';
import useOptionsByRole from '../../util/useOptionsByRole';
import useRangeDate from '../../util/useRangeDate';
import BodyHeader from './BodyHeader';
import ChartList from './ChartList';
import CountList from './CountList';
import CountTable from './CountTable';
import useAdminCheck from '../../util/useAdminCheck';
import { formatUnixTimeData } from '../../../util/format';

const formatToChartData = (arr, isAdmin = false) => {
    // 관리자
    let result = [];

    if (isAdmin) {
        const groupedObj = {};

        arr.forEach(ele => {
            const { date, wasteType, collectCompleted } = ele;

            if (!groupedObj[date]) {
                groupedObj[date] = {
                    date,
                    isolatedMedical: 0,
                    normalMedical: 0,
                    tissue: 0,
                    damage: 0,
                    pathology: 0,
                    biochemistry: 0,
                    bloodContamination: 0,
                    humanTissue: 0,
                };
            }

            groupedObj[date][wasteType] += collectCompleted;
        });

        // 객체에서 배열로
        const groupedArr = Object.values(groupedObj);
        // wasteCount를 추가
        const groupedArrWithCount = groupedArr.map(dateItem => {
            const newItem = { ...dateItem };

            const sumOfWasteType = Object.keys(dateItem)
                .filter(wasteType => wasteType !== 'date')
                .reduce((acc, key) => acc + dateItem[key], 0);

            newItem.wasteCount = sumOfWasteType;

            return newItem;
        });
        // 날짜 오름차순 정렬
        result = groupedArrWithCount.slice().sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf());
    }
    // 사용자
    else {
        const groupedArrWithCount = [];

        // 날짜(date)로 그룹화
        const groupedData = arr.reduce((grouped, item) => {
            const { date, wasteType, collectCompleted } = item;

            if (!grouped[date]) {
                grouped[date] = { date, wasteCount: 0 };
            }

            grouped[date][wasteType] = collectCompleted;
            grouped[date].wasteCount += item.collectCompleted;

            return grouped;
        }, {});

        // 그룹화된 데이터를 배열로 변환
        for (const date in groupedData) {
            groupedArrWithCount.push(groupedData[date]);
        }

        result = groupedArrWithCount.slice().sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf());
    }

    return result;
};

const DATA_TYPE_LIST = [
    { name: 'beforeCollect', label: 'Before Collect' },
    { name: 'collectCompleted', label: 'Collect Completed' },
    { name: 'weight', label: 'Weight' },
];

const TabCardBody = ({ selectedTab }) => {
    const { options, selectedWard, setSelectedWard } = useOptionsByRole();
    const { startDate, handleStartChange, endDate, handleEndChange } = useRangeDate(selectedTab);
    const isAdmin = useAdminCheck();

    // 날짜 역순 데이터
    const { state, promise: getList } = useAsync({
        promise: getWasteTreatmentStatusApi,
        immediate: true,
        param: {
            dateType: selectedTab,
            ward: selectedWard.value,
            startDate,
            endDate,
        },
        reject: err => console.error(err),
    });

    const data = state.response ? formatUnixTimeData(state.response.rows, selectedTab) : [];
    const countListData = DATA_TYPE_LIST.map(({ name, label }) => ({
        name,
        label,
        count: data.reduce((acc, cur) => acc + cur[name], 0),
    }));
    const chartData = formatToChartData(data, isAdmin);

    return (
        <div className="card-body flx-col gap-3">
            <BodyHeader
                options={options}
                selectedTab={selectedTab}
                selectedWard={selectedWard}
                setSelectedWard={setSelectedWard}
                startDate={startDate}
                handleStartChange={handleStartChange}
                endDate={endDate}
                handleEndChange={handleEndChange}
                getList={getList}
            />
            <CountList countList={countListData} />
            <ChartList chartData={chartData} selectedTab={selectedTab} />
            <CountTable selectedTab={selectedTab} rows={data} />
        </div>
    );
};

export default TabCardBody;
