import { createSlice } from '@reduxjs/toolkit';
import { NOTIFICATION_TYPE } from '@util/mappInfo';
import { store } from '../index';

// 알림 설정 정보 Key
export const NOTIFICATION_SETTING_KEY = {
    [NOTIFICATION_TYPE.USER]: 'userAlertInfo',
    [NOTIFICATION_TYPE.ROLE]: 'roleAlertInfo',
    [NOTIFICATION_TYPE.GROUP]: 'groupAlertInfoList',
};
// 알림 설정 요청 정보 Key
export const NOTIFICATION_PARAMETER_KEY = {
    [NOTIFICATION_SETTING_KEY.user]: 'userAlertSetting',
    [NOTIFICATION_SETTING_KEY.role]: 'roleAlertSetting',
    [NOTIFICATION_SETTING_KEY.group]: 'groupAlertSetting',
    alertSettingNum: 'alertSettingNum',
    groupNumList: 'loginGroups',
    propertyId: 'propertyId',
    beaconAlertInterfaceCommandList: 'interfaceBeaconList',
    sensorAlertInterfaceCommandList: 'interfaceSensorList',
};
// 센서 알림 설정의 범위 (전체, 센서 아이템)
export const SENSOR_SCOPE_KEY = {
    ALL: 'ALL',
    SINGLE: 'SINGLE',
};
const SUFFIX_TIME = '000';
const BATTERY_CHARGING = 255;
const BATTERY_LEAD_OFF = 250;
const SENSOR_PREFIX = 'SENSOR';
const SENSOR_STATUS = {
    NORMAL: 'NORMAL',
    OUTOFRANGE: 'OUTOFRANGE',
    LOST_SIGNAL: 'LOST_SIGNAL',
};

// 핵의학과 임시 - 알림 무시 조건
const MODEL_CODE_GALAXY_WATCH = '1002';
const IGNORE_SENSOR_TYPE = ['SPO2', 'HEARTBEAT'];
const IGNORE_VALUE = 0;

const initialState = {
    // 긴급 알림 내용 (소켓 응답 데이터) - 중간 처리
    emergencyAlertContents: [],
    // 긴급 알림 모달 내용 (소켓 응답 데이터) - 실제 모달 팝업 데이터
    emergencyAlertModalContents: [],
    // 대상별 지속 알람 발생 카운트
    persistentAlertCount: {},
    // 응급 모달을 띄우기 위한 알람 개수
    occurThreshold: 12,
    // 긴급 알림 limit 서비스 설정 값
    originalLimit: 10,
    // 긴급 알림 서비스 설정 값
    emergencyAlertConfig: {
        useEmergencyAlert: true,
        limit: 10,
    },
    // 긴급 알림 소리 서비스 설정 값
    soundConfig: {
        useSound: false,
        sensorSoundUrl: null,
        tagSoundUrl: null,
        locationSoundUrl: null,
    },
    // 긴급 알림 설정
    emergencyAlertSettings: null,
    // 선택한 알림 타입에 따른 알림 설정 서버 요청 값
    emergencySocketFilterConfig: [],
    // 그룹 알림 설정한 센서 아이템(타입) 목록
    selectedSensorTypeByModel: {},
    activationTargetNumList: [],
    activationTargetAdditionalData: {},
};

// TODO: 긴급 알림 설정 추가 기획 후 변경 예정
const { actions, reducer } = createSlice({
    name: 'emergencyAlert',
    initialState,
    reducers: {
        // 긴급 알림 소켓 응답 데이터 수신 및 알림 정보 생성
        setEmergencyAlertContents: {
            reducer: (state, action) => {
                let { socketData, activationFilter } = action.payload;

                if (activationFilter) {
                    socketData = socketData.filter(data =>
                        state.activationTargetNumList.includes(data.target.targetNum),
                    );
                }

                // 더 우선 순위가 높은 데이터의 처리가 반영되도록 순서 정렬(우선순위 오름차순)
                socketData.sort((item, nextItem) => {
                    return getPriorityByStatus(item) - getPriorityByStatus(nextItem);
                });

                const realTimeAlertKeys = [];

                socketData.forEach(item => {
                    const {
                        event,
                        target,
                        unixTime: detectionTime,
                        sensingUnits,
                        targetCategory,
                        sensor: { modelCode },
                        sensingState,
                        sensingValues,
                    } = item;
                    const { interfaceCommandType } = event;

                    const alertKey = getAlertKey(item);
                    realTimeAlertKeys.push(alertKey);
                    const foundIndex = state.emergencyAlertContents.findIndex(value => value?.alertKey === alertKey);
                    const foundModalIndex = state.emergencyAlertModalContents.findIndex(
                        value => value?.alertKey === alertKey,
                    );

                    // 필터링된 센싱 상태
                    let filteredSensingState;
                    // 필터링된 상태의 수
                    let filteredSensingStateCount;

                    // 그룹 알림 설정의 전체 선택 일 때 와 아닐 때 구분
                    if (!Array.isArray(state.selectedSensorTypeByModel)) {
                        filteredSensingState = Object.keys(state.selectedSensorTypeByModel[modelCode]);
                    } else {
                        filteredSensingState = Object.keys(sensingState);
                    }

                    filteredSensingStateCount = filteredSensingState.length;

                    // 필터링된 센서 아이템 중 Normal 또는 OutofRange 제거 하고, 센서 아이템의 상태가 선택된 것이 아닐 때 제거한 결과
                    filteredSensingState = filteredSensingState.reduce((acc, key) => {
                        const status = sensingState[key];
                        const value = sensingValues[key];

                        // LOST SIGNAL, 배터리 충전 중, 탈착 시 위급상황 알림 미발생(모두 필터)
                        if (
                            interfaceCommandType === `${SENSOR_PREFIX}_${SENSOR_STATUS.LOST_SIGNAL}` ||
                            sensingValues?.BATTERY === BATTERY_CHARGING ||
                            sensingValues?.BATTERY === BATTERY_LEAD_OFF
                        ) {
                            filteredSensingStateCount = 0;
                            return acc;
                        }

                        // 워치의 산소포화도/심박수 값이 0 이면 등급에 상관없이 처리
                        if (
                            modelCode === MODEL_CODE_GALAXY_WATCH &&
                            IGNORE_SENSOR_TYPE.includes(key) &&
                            value === IGNORE_VALUE
                        ) {
                            filteredSensingStateCount -= 1;
                            acc[key] = { status, value };
                            return acc;
                        }

                        const settingScope = !Array.isArray(state.selectedSensorTypeByModel);
                        const settingStatus = settingScope
                            ? state.selectedSensorTypeByModel[modelCode][key].includes(`${SENSOR_PREFIX}_${status}`)
                            : state.selectedSensorTypeByModel.includes(`${SENSOR_PREFIX}_${status}`);

                        // 센서의 상태가 NORMAL, OUTOFRAGNE 일 때 필터링, 센서의 상태가 그룹 알림 설정에 속하지 않을 때 필터링
                        if (status === SENSOR_STATUS.NORMAL || status === SENSOR_STATUS.OUTOFRANGE || !settingStatus) {
                            filteredSensingStateCount -= 1;
                        }

                        acc[key] = { status, value };
                        return acc;
                    }, {});

                    // 센서 상태가 모두 필터링 됐다면 제거, 하나라도 남아있으면 유지
                    if (filteredSensingStateCount <= 0) {
                        let ignoreClear = false;
                        for (let sensorType in filteredSensingState) {
                            if (
                                modelCode === MODEL_CODE_GALAXY_WATCH &&
                                IGNORE_SENSOR_TYPE.includes(sensorType) &&
                                filteredSensingState[sensorType].value === IGNORE_VALUE
                            ) {
                                ignoreClear = true;
                            }
                        }

                        if (!ignoreClear && ~foundIndex) {
                            state.emergencyAlertContents = state.emergencyAlertContents.filter(
                                value => value?.alertKey !== alertKey,
                            );
                        }
                        if (!ignoreClear && ~foundModalIndex) {
                            state.emergencyAlertModalContents = state.emergencyAlertModalContents.filter(
                                value => value?.alertKey !== alertKey,
                            );
                        }
                        return;
                    }

                    if (!alertKey) {
                        return;
                    }

                    const emergencyAlert = {
                        alertKey,
                        target,
                        interfaceCommandType,
                        detectionTime,
                        filteredSensingState,
                        // 알림 대상의 모든 센서 아이템 단위
                        sensingUnits,
                        // 알림 대상의 카테고리
                        categoryName: targetCategory.categoryName,
                    };

                    // 기존에 있는 대상이면 새로운 알림 내용으로 교체
                    if (~foundIndex) {
                        state.emergencyAlertContents[foundIndex] = emergencyAlert;
                        return;
                    }

                    const checkContents = [...state.emergencyAlertContents];
                    state.emergencyAlertContents = [...checkContents, emergencyAlert];
                });

                // 같은 등급으로 지속되면 알림이 오지 않으므로 socket 으로 실제 오지 않은 알림 유지하기 위한 플래그 추가
                state.emergencyAlertContents = state.emergencyAlertContents.map(alert => {
                    if (!realTimeAlertKeys.includes(alert.alertKey)) {
                        return {
                            ...alert,
                            keepingData: true,
                        };
                    }
                    return alert;
                });

                const newAlertKeys = new Set(
                    state.emergencyAlertContents.filter(alert => !alert.keepingData).map(alert => alert.alertKey),
                );
                const keepingAlertKeys = new Set(
                    state.emergencyAlertContents.filter(alert => alert.keepingData).map(alert => alert.alertKey),
                );
                const newAlertCount = {};
                newAlertKeys.forEach(alertKey => {
                    newAlertCount[alertKey] = state.persistentAlertCount[alertKey]
                        ? state.persistentAlertCount[alertKey] + 1
                        : 1;
                });
                keepingAlertKeys.forEach(alertKey => {
                    newAlertCount[alertKey] = state.persistentAlertCount[alertKey];
                });

                for (let alertKey in newAlertCount) {
                    if (newAlertCount[alertKey] >= state.occurThreshold) {
                        const foundIndex = state.emergencyAlertModalContents.findIndex(
                            value => value?.alertKey === alertKey,
                        );
                        const newAlert = state.emergencyAlertContents.find(alert => alert.alertKey === alertKey);
                        const newAlertTargetNum = String(newAlert.target.targetNum);
                        if (~foundIndex) {
                            state.emergencyAlertModalContents[foundIndex] = {
                                ...newAlert,
                                ...state.activationTargetAdditionalData[newAlertTargetNum],
                            };
                        } else {
                            // 임시 : 길이가 10개 보다 많으면 하나 제거하고 추가
                            if (state.emergencyAlertModalContents.length >= 10) {
                                state.emergencyAlertModalContents.shift();
                            }
                            state.emergencyAlertModalContents.push({
                                ...newAlert,
                                ...state.activationTargetAdditionalData[newAlertTargetNum],
                            });
                        }

                        state.emergencyAlertContents = state.emergencyAlertContents.filter(
                            alert => alert.alertKey !== alertKey || (alert.alertKey === alertKey && alert.keepingData),
                        );

                        newAlertCount[alertKey] = null;
                        delete newAlertCount[alertKey];
                    }
                }
                state.persistentAlertCount = newAlertCount;
            },
            prepare: socketData => {
                const { activationFilter } = store.getState().AppInfo.emergencyAlert;
                return { payload: { socketData, activationFilter } };
            },
        },
        // 긴급 알림 제거
        deleteEmergencyAlertContents: (state, action) => {
            const { alertKey } = action.payload;

            state.emergencyAlertContents = state.emergencyAlertContents.filter(
                value => value?.alertKey !== alertKey || (value.alertKey === alertKey && value.keepingData),
            );
            state.emergencyAlertModalContents = state.emergencyAlertModalContents.filter(
                value => value?.alertKey !== alertKey,
            );
            state.persistentAlertCount[alertKey] = null;
            delete state.persistentAlertCount[alertKey];
        },
        // 비활성화 대상의 긴급 알림 제거 - 이미 떠있는 모달은 유지
        deleteDisableTargetEmergencyAlertContents: state => {
            const { activationTargetNumList } = state;

            const disableTargetAlerts = state.emergencyAlertContents.filter(
                value => !activationTargetNumList.includes(value?.target.targetNum),
            );
            state.emergencyAlertContents = state.emergencyAlertContents.filter(value =>
                activationTargetNumList.includes(value?.target.targetNum),
            );
            disableTargetAlerts.forEach(alert => {
                if (alert?.alertKey) {
                    const alertKey = alert.alertKey.toString();
                    state.persistentAlertCount[alertKey] = null;
                    delete state.persistentAlertCount[alertKey];
                }
            });
        },
        // 사용자 전체 알림 설정 세팅 또는 갱신
        setEmergencyAlertSettings: (state, action) => {
            const originSetting = action.payload;
            state.emergencyAlertSettings = originSetting;

            // 알림 설정이 없는 경우
            if (!action.payload || action.payload.length === 0) {
                return;
            }
            // 사용자 전체 알림 설정에서 선택한 알림 수신 타입 별 설정 서버 요청 객체로 변경 및 세팅
            state.emergencySocketFilterConfig = originSetting.map(originSetting =>
                Object.fromEntries(
                    Object.entries(originSetting).map(([key, value]) => [NOTIFICATION_PARAMETER_KEY[key], value]),
                ),
            );
            // 그룹 알림 설정 목록(originSetting)에서 설정한 센서 이벤트에서 센서 타입 항목을 모델 코드에 맞추어 전체 추출
            state.selectedSensorTypeByModel = originSetting.reduce((sensorTypesByModel, setting) => {
                if (setting?.sensorAlertInterfaceCommandList) {
                    setting.sensorAlertInterfaceCommandList.forEach(
                        ({ modelCode, sensingType, scope, interfaceCommandType }) => {
                            // 센서 타입 전체 선택일 때 센서 상태만 배열로 저장
                            if (scope === SENSOR_SCOPE_KEY.ALL) {
                                if (!Array.isArray(sensorTypesByModel)) {
                                    sensorTypesByModel = [interfaceCommandType];
                                    return sensorTypesByModel;
                                }
                                sensorTypesByModel.push(interfaceCommandType);
                                return sensorTypesByModel;
                            }
                            // 전체 선택 됐다면 모델 코드를 통한 센서 상태들이 필요 없어지기에 종료
                            if (Array.isArray(sensorTypesByModel)) {
                                return sensorTypesByModel;
                            }
                            if (!sensorTypesByModel[modelCode]) {
                                sensorTypesByModel[modelCode] = {};
                            }
                            if (!sensorTypesByModel[modelCode][sensingType]) {
                                sensorTypesByModel[modelCode][sensingType] = [interfaceCommandType];
                            } else {
                                sensorTypesByModel[modelCode][sensingType].push(interfaceCommandType);
                            }
                        },
                    );
                }
                return sensorTypesByModel;
            }, {});
        },
        // 알림 서비스 설정 값
        setEmergencyAlertConfigValue: (state, action) => {
            const {
                alertSoundConfig: { rootDefaultUrl, soundOnOff, sensorStatus, tagStatus, location },
                alertPopupConfig: { popupActive, showCount, showTime },
            } = action.payload;

            // wms 서비스 설정의 긴급 알림 설정값
            const useEmergencyAlert = popupActive === 'Y'; // 긴급 알림 사용 여부
            const limit = parseInt(showCount); // 긴급 알림 노출 갯수
            const autoCloseTime = showTime + SUFFIX_TIME; // 긴급 알림 지속 시간
            state.emergencyAlertConfig = {
                ...state.emergencyAlertConfig,
                useEmergencyAlert,
                limit,
                autoClose: parseInt(autoCloseTime),
            };
            state.originalLimit = limit;

            // wms 서비스 설정의 알림 소리 설정값
            const useSound = soundOnOff === 'yes'; // 소리 사용 여부
            const sensorSoundUrl = rootDefaultUrl + sensorStatus; // 센서 알림 소리 파일
            const tagSoundUrl = rootDefaultUrl + tagStatus; // 태그 알림 소리 파일
            const locationSoundUrl = rootDefaultUrl + location; // 위치 알림 소리 파일
            state.soundConfig = {
                ...state.soundConfig,
                useSound,
                sensorSoundUrl,
                tagSoundUrl,
                locationSoundUrl,
            };
        },
        clearAlertContents: (state, action) => {
            const keepOpenedModal = action.payload?.keepOpenedModal;
            if (!keepOpenedModal) {
                state.emergencyAlertModalContents = [];
            }
            state.emergencyAlertContents = [];
            state.persistentAlertCount = {};
        },
        initializeActivationTargetNumList: (state, action) => {
            const activationTargetList = action.payload ?? [];
            state.activationTargetNumList = activationTargetList.map(({ targetNum }) => targetNum);
            state.activationTargetAdditionalData = activationTargetList.reduce((acc, { targetNum, fcName }) => {
                acc[targetNum] = { fcName };
                return acc;
            }, {});
        },
        updateActivationTargetNumList: (state, action) => {
            const patient = action.payload;
            const existTargetNum = state.activationTargetNumList.find(targetNum => targetNum === patient.targetNum);

            if (patient.progressType === 'Y') {
                if (!existTargetNum) {
                    state.activationTargetNumList = [...state.activationTargetNumList, patient.targetNum];
                }
                // 새로 들어온 지오펜스정보 있으면 교체 없으면 그대로
                state.activationTargetAdditionalData[patient.targetNum] = {
                    fcName: patient.fcName ?? state.activationTargetAdditionalData[patient.targetNum]?.fcName,
                };
            }

            if (['N', 'D'].includes(patient.progressType) && existTargetNum) {
                state.activationTargetNumList = state.activationTargetNumList.filter(
                    targetNum => targetNum !== patient.targetNum,
                );
                state.activationTargetAdditionalData[patient.targetNum] = null;
                delete state.activationTargetAdditionalData[patient.targetNum];
            }
        },
    },
});

export const {
    setEmergencyAlertContents,
    deleteEmergencyAlertContents,
    deleteDisableTargetEmergencyAlertContents,
    setEmergencyAlertSettings,
    setEmergencyAlertConfigValue,
    clearAlertContents,
    initializeActivationTargetNumList,
    updateActivationTargetNumList,
} = actions;
export default reducer;

function getAlertKey(item) {
    if (!isNaN(item?.target?.targetNum)) {
        return item.target.targetNum.toString();
    }
    return 'unknownItemKey';
}

function getMonitoringSensingState(item) {
    return Object.keys(item.sensingState).reduce((obj, key) => {
        obj[key] = { status: item.sensingState[key], value: item.sensingValues[key] };
        return obj;
    }, {});
}

function getPriorityByStatus(sensor) {
    if (sensor?.event?.interfaceCommandType === 'SENSOR_LOST_SIGNAL') {
        return 9999;
    }
    const sensingState = getMonitoringSensingState(sensor);
    return Object.values(sensingState).reduce((accPriority, sensingStateItem) => {
        switch (sensingStateItem.status) {
            case 'CRITICAL':
                accPriority = accPriority + 2;
                break;
            case 'WARNING':
                accPriority++;
                break;
            case 'NORMAL':
            case 'OUTOFRANGE':
                break;
            default:
                break;
        }
        return accPriority;
    }, 0);
}
