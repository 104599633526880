import useAppSelector from '@hooks/useAppSelector';
import { useAsync } from '@hooks';
import { getTestTargetPatientListApi } from '@api/sh/examPatientMonitoring';
import { getEndOfDate, getStartOfDate } from '@util/date';
import { Patient } from '../../../Components/Widgets/sh/ExamPatientsMonitoring/types';
import { useEffect, useMemo } from 'react';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_SUBJECT_PATIENT_DATA } from '@reducer/SocketInfo';
import { useDispatch } from 'react-redux';
import {
    deleteDisableTargetEmergencyAlertContents,
    initializeActivationTargetNumList,
    updateActivationTargetNumList,
} from '@reducer/EmergencyAlert';
import { RealtimeSubjectPatientData } from '@util/socket/socketData';
import { NullableString } from '@util/type/util';

const useActivationFilter = () => {
    const dispatch = useDispatch();
    const activationFilter = useAppSelector(state => state.AppInfo.emergencyAlert.activationFilter);
    const activationTargetNumList = useAppSelector(state => state.EmergencyAlert.activationTargetNumList);

    const { promise: getTestTargetPatientList } = useAsync({
        promise: getTestTargetPatientListApi,
        fixedParam: {
            isAll: 'Y',
        },
        resolve: response => {
            if (response?.rows) {
                dispatch(
                    initializeActivationTargetNumList(
                        response.rows.reduce(
                            (
                                acc: { targetNum: number; fcName: NullableString }[],
                                { progressType, targetNum, fcName }: Patient,
                            ) => {
                                if (progressType === 'Y') {
                                    acc.push({ targetNum, fcName });
                                }
                                return acc;
                            },
                            [],
                        ),
                    ),
                );
            }
        },
    });

    useEffect(() => {
        if (activationFilter) {
            getTestTargetPatientList({
                startDate: getStartOfDate(),
                endDate: getEndOfDate(),
            });
        }
    }, [activationFilter]);

    const socketFilter = useMemo(() => {
        return activationFilter ? {} : { comNum: null };
    }, [activationFilter]);

    useSocketEvent({
        name: EVENT_TYPE_SUBJECT_PATIENT_DATA,
        filterConfig: socketFilter,
        handler: data => {
            const { subjectPatientData } = data as RealtimeSubjectPatientData;
            dispatch(updateActivationTargetNumList(subjectPatientData));
        },
    });

    // activationTargetNumList 이 변경 될 때마다 수행 필요
    useEffect(() => {
        if (activationFilter) {
            dispatch(deleteDisableTargetEmergencyAlertContents(null));
        }
    }, [activationFilter, activationTargetNumList]);
};

export default useActivationFilter;
