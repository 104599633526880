import reducers from '../reducers';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { ACTION_TYPE_LOGOUT } from '@reducer/UserInfo';
import { AnyAction, Reducer } from 'redux';

const combinedReducer = combineReducers({ ...reducers });

const reducerProxy: Reducer = (state: RootState, action: AnyAction) => {
    if (action.type === ACTION_TYPE_LOGOUT) {
        return combinedReducer(undefined, action);
    }
    return combinedReducer(state, action);
};

export default function createStore() {
    return configureStore({
        reducer: reducerProxy,
        middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
        devTools: true,
    });
}

export type RootState = ReturnType<typeof combinedReducer>;
