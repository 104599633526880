import React from 'react';
import ItemCount from './ItemCounts';
import InnerItemCard from '@components/Card/InnerItemCard';

const LIST = ['total'];
const OutpatientReservedCounts = ({ total }) => {
    return (
        <InnerItemCard>
            <div className="widget-item-nums">
                {LIST.map(status => {
                    return <ItemCount key={status} start={0} end={total} label={status} />;
                })}
            </div>
        </InnerItemCard>
    );
};

export default OutpatientReservedCounts;
