import moment from 'moment';

export const isOverdue = (targetUnixTime, sourceUnixTime, diffNum = 1) => {
    const startDate = moment.unix(targetUnixTime).format('YYYY-MM-DD');
    const currentDate = moment.unix(sourceUnixTime).format('YYYY-MM-DD');
    const diff = moment(currentDate).diff(startDate, 'days');

    if (diff >= diffNum) {
        return true;
    }

    return false;
};

export const RENTAL_STATUS = {
    APPLY: 'apply', // 대여신청
    CHECK_OUT: 'checkOut', // 대여중
    APPROVE: 'approve', // 인계대기
};

export const RENTAL_STATUS_RESPONSE = {
    APPLY: 'apply', // 대여신청
    CHECK_OUT: 'check_out', // 대여중
    APPROVE: 'approve', // 인계대기
};

export const checkRentalStatusResponse = status => {
    if (status === 'apply') {
        return 'Rental Request';
    }
    if (status === 'check_out') {
        return 'Lending';
    }
    if (status === 'approve') {
        return 'Wait hand over';
    }

    return '-';
};

export const checkHistoryRentalStatus = status => {
    if (status === 'check_out') {
        return 'Lending';
    }
    if (status === 'check_in') {
        return 'Return Complete';
    }
    if (status === 'refusal') {
        return 'Reject';
    }
    if (status === 'apply_cancel') {
        return 'Request Cancel';
    }
    if (status === 'take_cancel') {
        return 'Take Over Cancel';
    }
    return '';
};
