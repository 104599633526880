import { createSlice } from '@reduxjs/toolkit';

// export const initialState = { vitalSignsList: {}, originWorkTimeList: [], isDetail: false, targetNum: null };
export const initialState = {
    vitalSignsList: {},
    monitoringTargetNums: [],
    originWorkTimeList: [],
    isDetail: false,
    targetId: null,
    wardNum: null,
};

const vitalSignsRealTimePartronSlice = createSlice({
    name: 'vitalSignsRealTimePartron',
    initialState,
    reducers: {
        storeVitalSignList: (state, action) => {
            if (!action.payload) {
                return;
            }

            const monitoringTargetNums = [];

            state.vitalSignsList = (function loop(entryValues) {
                return Object.fromEntries(
                    Object.keys(entryValues).map(key => {
                        if (!Array.isArray(entryValues[key])) {
                            return [key, loop(entryValues[key])];
                        } else {
                            let person = {};
                            if (entryValues[key][0]) {
                                monitoringTargetNums.push(entryValues[key][0].targetNum);
                            }
                            entryValues[key].forEach(personData => {
                                person = {
                                    ...person,
                                    ...personData,
                                    [personData.sensingType]: personData.currentValue,
                                };
                            });
                            return [key, person];
                        }
                    }),
                );
            })(action.payload);

            state.monitoringTargetNums = monitoringTargetNums;
        },
        eachUpdateVitalSignList: (state, action) => {
            if (!action.payload) {
                return;
            }

            const vitalSigns = action.payload;

            state.vitalSignsList = (function loop(entryValues) {
                return Object.fromEntries(
                    Object.keys(entryValues).map(key => {
                        if (!entryValues[key].deviceId) {
                            return [key, loop(entryValues[key])];
                        }
                        if (entryValues[key].deviceId === vitalSigns.deviceId) {
                            let person = {
                                ...entryValues[key],
                                ...vitalSigns,
                                ...vitalSigns.sensingValue,
                                regDate: vitalSigns.regDate,
                            };
                            return [key, person];
                        } else {
                            return [key, entryValues[key]];
                        }
                    }),
                );
            })(state.vitalSignsList);
        },
        setIsDetail: (state, action) => {
            state.isDetail = action.payload;
        },
        setTargetId: (state, action) => {
            if (!action.payload) {
                return;
            }
            state.targetId = action.payload;
        },
        setWardNum: (state, action) => {
            if (!action.payload) {
                return;
            }
            state.wardNum = action.payload;
        },
    },
});
export const {
    storeVitalSignList,
    eachUpdateVitalSignList,
    setIsDetail,
    setTargetId,
    setWardNum,
} = vitalSignsRealTimePartronSlice.actions;
export default vitalSignsRealTimePartronSlice;
