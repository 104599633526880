import React, { useContext, useState } from 'react';
import { Label, Select } from '@components';
import { useTranslation } from '@util/hooks';
import { ScreenEditDispatchContext } from '../../../../MainPages/Settings/ScreenManagement/ScreenEdit';
import WidgetSettingModal from '../../../Components/WidgetSettingModal';
import { editWidgetSettings } from '../../../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import { useSettings } from '../../../util/useSettings';

const NotificationHistorySetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('OutpatientStorage');

    const options = [
        { label: t('Wonju'), value: 'wonju' },
        { label: t('Andong'), value: 'andong' },
    ];

    const { config } = widgetInfo;
    const { settings: customSettings } = config;
    const settings = useSettings(config);
    const dispatch = useContext(ScreenEditDispatchContext);
    const initialWidgetInfo = settings?.dashboardURL ? { dashboardURL: settings.dashboardURL } : { dashboardURL: {} };
    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState(initialWidgetInfo);

    const handleSubmitClick = data => {
        const checkedSettings = customSettings
            ? { ...customSettings, ...updatedWidgetInfo }
            : { ...data, ...updatedWidgetInfo };

        const updatedWidgetSettings = {
            id: widgetInfo.id,
            settings: checkedSettings,
        };

        dispatch(editWidgetSettings(updatedWidgetSettings));
    };

    const handleChange = selectedCategory => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, categoryCode: selectedCategory.value };
        });
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    const value = options.find(e =>
        updatedWidgetInfo.categoryCode ? e.value === updatedWidgetInfo.categoryCode : e.value === settings.categoryCode,
    );

    return (
        <WidgetSettingModal
            headerTitle={t('Notification History Setting')}
            widgetInfo={widgetInfo}
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <Label
                name={t('Medical Center')}
                value={
                    <Select className="w-100" value={value} options={options} onChange={handleChange} isModalSelect />
                }
            />
        </WidgetSettingModal>
    );
};

export default NotificationHistorySetting;
