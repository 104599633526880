import React, { useState } from 'react';
import { useAsync, useColumns, useTranslation } from '@hooks';
import InnerItemCard from '@components/Card/InnerItemCard';
import { Table } from '@components';
import Search from '../Search';
import styled from 'styled-components';
import * as column from '../column';
import MonitoringModal from '../../MonitoringModal';
import { useOutpatientCareStatusContext, useOutpatientCareStatusDispatch } from '../../index';
import { getSelectedOutpatientCareStatusListApi } from '@api/monitoring';
import { setSelectedOutpatient } from '../../OutpatientCareStatusMonitoringSlice';
import moment from 'moment';
const OutpatientCareStatusList = ({ outpatientCareList, handleOutpatientListParam }) => {
    const t = useTranslation('OutpatientCareStatus');
    const [modal, setModal] = useState(false);
    const { selectedOutpatient } = useOutpatientCareStatusContext();
    const dispatch = useOutpatientCareStatusDispatch();

    const columns = useColumns(
        [
            column.category(),
            column.ward(),
            column.speciality(),
            column.doctor(),
            column.id(),
            column.patientName(),
            column.reservedDate({
                Cell: ({ value }) => {
                    return value ? moment.unix(value).format('YYYY-MM-DD') : ' - ';
                },
            }),
            column.reservedTime({
                Cell: ({ value }) => {
                    return value ? moment.unix(value).format('HH:mm') : ' - ';
                },
            }),
            column.arrivedTime({
                Cell: ({ value }) => {
                    return value ? moment.unix(value).format('HH:mm') : ' - ';
                },
            }),
            column.basicTest({
                Cell: ({ value }) => {
                    return <span>{value !== null ? 'O' : 'X'}</span>;
                },
            }),
            column.waitingCare({
                Cell: ({ value }) => {
                    return <span>{value === 'Y' ? 'O' : 'X'}</span>;
                },
            }),
            column.care({
                Cell: ({ value }) => {
                    return <span>{value === 'Y' ? 'O' : 'X'}</span>;
                },
            }),
        ],
        t,
        [],
    );
    const { promise: getSelectedOutpatientInfo } = useAsync({
        promise: getSelectedOutpatientCareStatusListApi,
        resolve: res => {
            dispatch(setSelectedOutpatient(res));
        },
        reject: err => {
            console.error(err);
        },
    });
    const handleModal = data => {
        const { careNum } = data;
        setModal(!modal);
        getSelectedOutpatientInfo({ careNum });
    };

    return (
        <Container>
            <InnerItemCard
                className={'h-100'}
                bodyClassName={'h-100'}
                header={{
                    titleIcon: 'assignment',
                    title: t('Patient lists'),
                }}
            >
                <Search />
                <TableContainer className={'h-90'}>
                    <Table
                        data={outpatientCareList}
                        columns={columns}
                        onTrClick={handleModal}
                        onPageChange={handleOutpatientListParam}
                        dynamicRowHeight={true}
                    />
                </TableContainer>
            </InnerItemCard>
            {selectedOutpatient && <MonitoringModal modal={modal} setModal={setModal} />}
        </Container>
    );
};

const Container = styled.div`
    height: 550px;
    margin-top: 5px;
`;
const TableContainer = styled.div`
    height: 250px;
    margin-bottom: 5px;
`;

export default OutpatientCareStatusList;
