import React from 'react';
import WidgetCard from '../../Components/WidgetCard';
import { Table } from '@components';
import useMonitor from '../../util/useMonitor';
import { fetchIotItemCount } from '@api/common/asset';
import { useTopCategory } from '../../util/useTopCategory';
import { useColumns, useTranslation } from '@hooks';
import useFilter from '@hooks/useFilter';
import { DUMMY_DATA } from './constants';
import IotItemCountStatusChart from './IotItemCountStatusChart';

const sumTargetCnt = (acc, curr) => {
    acc += curr.targetCnt;
    return acc;
};

const SUMMARY_AREA_HEIGHT = '90px';
const TABLE_HEIGHT = '65px';

const IotItemCountStatus = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const t = useTranslation('IotItemCountStatus');
    const categoryList = useTopCategory();
    const filterParam = useFilter();

    const { data } = useMonitor({
        config,
        defaultData: DUMMY_DATA,
        dynamicParam: filterParam,
        fetchData: fetchIotItemCount,
        makeData: ({ newData }) => {
            const iotItemCountList = newData.rows;
            const chartData = [];
            let parentCodeList = [];

            if (iotItemCountList) {
                for (let i = 0; i < iotItemCountList.length; i++) {
                    if (iotItemCountList[i].categoryCodePath.indexOf('>') < 0) {
                        chartData.push({
                            key: iotItemCountList[i].categoryCode,
                            name: iotItemCountList[i].categoryName,
                            value: iotItemCountList[i].targetCnt,
                        });
                    } else {
                        const parentCode = iotItemCountList[i].categoryCodePath.split('>')[0];
                        parentCodeList.push(parentCode);
                    }
                }
                parentCodeList = [...new Set(parentCodeList)];

                for (let i = 0; i < parentCodeList.length; i++) {
                    const name = categoryList.filter(v => v.value === parentCodeList[i]);
                    chartData.push({
                        key: parentCodeList[i],
                        name: (name && name.label) || parentCodeList[i],
                        value: iotItemCountList
                            .filter(v => v.categoryCodePath.indexOf(parentCodeList[i]) === 0)
                            .reduce(sumTargetCnt, 0),
                    });
                }
            }
            return chartData;
        },
    });

    const columns = useColumns(
        data.map(({ key, name }) => {
            return {
                Header: name,
                accessor: key,
                width: name.length < 5 ? 40 : 50,
                headerClassName: 'text-nowrap',
                Cell: ({ row: { original } }) => {
                    return <span>{original[key]}</span>;
                },
            };
        }),
        t,
        [data],
    );

    const flattenData = data.reduce((acc, { key, value }) => {
        acc[key] = value;
        return acc;
    }, {});

    return (
        <WidgetCard widgetInfo={widgetInfo} {...restProps}>
            <div className={'w-100 pb-3'} style={{ height: `calc(100% - ${SUMMARY_AREA_HEIGHT})` }}>
                <IotItemCountStatusChart data={data} />
            </div>
            <div className={'w-100'} style={{ height: SUMMARY_AREA_HEIGHT }}>
                {t('Current / Count')}
                <div style={{ height: TABLE_HEIGHT }}>
                    <Table
                        data={{ rows: [flattenData] }}
                        columns={columns}
                        paging={false}
                        className={'table-for-viewer'}
                    />
                </div>
            </div>
            {children}
        </WidgetCard>
    );
};

export default IotItemCountStatus;
