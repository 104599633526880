import React from 'react';
import { BarChart, Button } from '@components';
import InnerItemCard from '@components/Card/InnerItemCard';
import { useTranslation } from '@hooks';
import { OUTPATIENT_USAGE_CHART_TITLE } from '../../constants';
import styled from 'styled-components';

const Chart = ({ data, onDownloadClick }) => {
    const t = useTranslation('OutpatientReport');

    const barData = {
        labels: data.map(row => row.departmentName),
        datasets: [
            {
                label: t('Scheduled patients count'),
                data: data.map(row => row.patientCount),
            },
            {
                label: t('Kiosk users count'),
                data: data.map(row => row.kioskCount),
            },
        ],
    };

    return (
        <InnerItemCard
            className={'m-2 h-90'}
            bodyClassName={'h-100'}
            header={{ title: t(OUTPATIENT_USAGE_CHART_TITLE) }}
        >
            {
                <div className={'h-90 flx-row position-relative'}>
                    <div className={'w-100 h-100 '}>
                        <BarChart
                            data={barData}
                            type={'bar'}
                            options={{
                                scales: {
                                    x: { display: true },
                                    y: {
                                        beginAtZero: true,
                                        suggestedMax: 10,
                                    },
                                },
                            }}
                        />
                    </div>
                    <DownloadButton className={'btn-secondary'} onClick={onDownloadClick}>
                        {t('Download')}
                    </DownloadButton>
                </div>
            }
        </InnerItemCard>
    );
};

const DownloadButton = styled(Button)`
    position: absolute;
    top: 0;
    right: 0;
`;

export default Chart;
