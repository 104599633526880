import { useEffect } from 'react';
import useSocket from '@util/socket/hooks/useSocket';
import useLegacySocket from '@util/socket/hooks/useLegacySocket';
import useVisibility from '@hooks/useVisibility';

const useInitSocket = () => {
    const { closeSocket, initializeSocket } = useSocket();
    const { closeSocket: closeLegacySocket, initializeSocket: initializeLegacySocket } = useLegacySocket();
    const { hidden } = useVisibility();

    useEffect(() => {
        if (!hidden) {
            initializeSocket();
            initializeLegacySocket();
        }
        return () => {
            closeSocket();
            closeLegacySocket();
        };
    }, [hidden]);
};

export default useInitSocket;
