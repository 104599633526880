import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import useTranslation from '@hooks/useTranslation';
import { useAsync } from '@hooks';
import { fetchGeofenceList } from '@api/common/geofence';
import { fetchGeofenceLogList } from '@api/common/log';

import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';
import ExcelExport from './Component/ExcelExport';
import Search from './Component/Search';
import List from './Component/List';
import WidgetCard from '../../Components/WidgetCard';
import { setGeofence, setGeofenceList } from './geofenceLogSlice';
import { useGeofenceLogDispatch } from './index';

export const DEFAULT_SELECT_VALUE = 0;

const GeofenceLogContainer = ({ widgetInfo, children, ...restProps }) => {
    const t = useTranslation('Geofence Log');
    const { mode } = useSelector(state => state.ScreenInfo);
    const { selectableFloorList } = useSelector(state => state.FloorInfo);
    const dispatch = useGeofenceLogDispatch();

    const [geofenceLogData, setGeofenceLogData] = useState({ totalCount: 0, totalPage: 1, rows: [] });
    const [startDate, setStartDate] = useState(moment().startOf('day').valueOf());
    const [endDate, setEndDate] = useState(
        moment().startOf('day').add(23, 'hour').add(59, 'm').add(59, 'second').valueOf(),
    );

    const handleDateCallback = ({ selected, type }) => {
        switch (type) {
            case 'startDate':
                setStartDate(selected);
                break;
            case 'endDate':
                setEndDate(selected);
                break;
            case 'yesterday':
                const yesterday = moment().startOf('day').subtract(1, 'day');
                setStartDate(yesterday.valueOf());
                setEndDate(yesterday.add(23, 'hours').add(59, 'm').add(59, 'second').valueOf());
                break;
            case 'reset':
                setStartDate(moment().startOf('day').valueOf());
                setEndDate(moment().startOf('day').add(23, 'hour').add(59, 'm').add(59, 'second').valueOf());
                break;
        }
    };

    useAsync({
        promise: fetchGeofenceList,
        immediate: true,
        resolve: res => {
            const { rows } = res;
            dispatch(setGeofenceList(rows.map(({ fcName, fcNum, floor }) => ({ label: fcName, value: fcNum, floor }))));
            dispatch(setGeofence(rows.map(({ fcNum }) => fcNum)));
        },
        reject: err => {
            dispatch(setGeofenceList([{ label: t('Not Select'), value: DEFAULT_SELECT_VALUE }]));
            dispatch(setGeofence([DEFAULT_SELECT_VALUE]));
            console.error(err);
        },
    });

    const { promise: getGeofenceLogList, state: geofenceLogListInfo } = useAsync({
        promise: fetchGeofenceLogList,
        fixedParam: { pageSize: 15 },
        resolve: res => {
            const { rows } = res;
            const data = rows.map(log => {
                return {
                    comNum: log.comNum,
                    categoryCode: log.categoryCode,
                    targetName: log.targetName,
                    targetId: log.targetId,
                    floorIdPath: log.floorIdPath,
                    fcName: log.fcName,
                    permitted: log.permitted,
                    inDate: log.inDate,
                    inScannerName: log.inScannerName,
                    outDate: log.outDate,
                    outScannerName: log.outScannerName,
                    logNum: log.logNum,
                    ...log,
                };
            });
            setGeofenceLogData({ ...res, rows: data });
        },
    });

    useEffect(() => {
        getGeofenceLogList({
            startDate: moment(startDate).unix(),
            endDate: moment(endDate).unix(),
            floorIds: selectableFloorList[0]?.floorId,
            floorIdPath: selectableFloorList[0]?.floorIdPath,
        });
    }, []);

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            headerAction={
                mode !== SCREEN_MODE_EDIT &&
                mode !== SCREEN_MODE_PREVIEW && (
                    <ExcelExport startDate={startDate} endDate={endDate} listCount={geofenceLogData.rows.length} />
                )
            }
            {...restProps}
        >
            <Search
                startDate={startDate}
                endDate={endDate}
                handleDateCallback={handleDateCallback}
                promise={getGeofenceLogList}
            />
            <List list={geofenceLogData} state={geofenceLogListInfo} promise={getGeofenceLogList} />
            {children}
        </WidgetCard>
    );
};

export default GeofenceLogContainer;
