import React, { useMemo, useReducer, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation, useAsync, useFilter } from '@hooks';
import { Button, LoadingBlock } from '@components';
import { getVitalSignsListOfPatronApi } from '@api/monitoring';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_NUMERIC_SENSING } from '@reducer/SocketInfo';
import reducer, {
    eachUpdateVitalSignList,
    initialState,
    setIsDetail,
    storeVitalSignList,
} from './vitalSignsRealTimePartronPoCReducer';
import VitalSignList from './Components/VitalSignsCardList';
import ViewDetail from './Components/ViewDetail';
import WidgetCard from '../../Components/WidgetCard';

/**
 * 활력징후 모니터링 위젯
 **/
export const RealTimeVitalSignsStateContext = React.createContext();
export const RealTimeVitalSignsDispatchContext = React.createContext();

const VitalSignsRealTimePartronPoC = ({ children, widgetInfo, ...restProps }) => {
    const buttonT = useTranslation('Button');
    const widgetRef = useRef();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { monitoringTargetNums } = state;

    const params = useFilter();

    // 활력징후 센서 데이터 초기 호출
    const { state: vitalSignsList, promise: getVitalSignsListOfPatron } = useAsync({
        promise: getVitalSignsListOfPatronApi,
        immediate: true,
        param: params,
        deps: [params],
        resolve: res => {
            dispatch(storeVitalSignList(res));
        },
        reject: () => {},
    });

    const filterConfig = useMemo(() => {
        return monitoringTargetNums.length
            ? { target: { targetNum: { $in: monitoringTargetNums } } }
            : { target: { targetNum: 'noneTarget' } };
    }, [monitoringTargetNums]);

    useSocketEvent({
        name: EVENT_TYPE_NUMERIC_SENSING,
        handler: data => {
            const { sensor, unixTime, sensingValues, sensingState } = data;
            const sensingValue = {};
            Object.keys(sensingValues).forEach(key => {
                sensingValue[key] = { value: sensingValues[key], status: sensingState[key], regDate: unixTime };
            });
            dispatch(
                eachUpdateVitalSignList({
                    deviceId: sensor.sensorId,
                    sensingValue,
                    regDate: unixTime,
                }),
            );
        },
        filterConfig,
    });

    return (
        <RealTimeVitalSignsDispatchContext.Provider value={dispatch}>
            <RealTimeVitalSignsStateContext.Provider value={state}>
                <WidgetCard
                    ref={widgetRef}
                    widgetInfo={widgetInfo}
                    headerAction={
                        state.isDetail && (
                            <Button className="btn-gray border-h" onClick={() => dispatch(setIsDetail(false))}>
                                {buttonT('Back')}
                            </Button>
                        )
                    }
                    {...restProps}
                >
                    <LoadingBlock blocking={vitalSignsList.isLoading}>
                        {state.isDetail ? (
                            <ViewDetail targetId={state.targetId} />
                        ) : (
                            <ListWrapper>
                                <VitalSignList handleRefreshData={() => getVitalSignsListOfPatron({ ...params })} />
                            </ListWrapper>
                        )}
                    </LoadingBlock>
                    {children}
                </WidgetCard>
            </RealTimeVitalSignsStateContext.Provider>
        </RealTimeVitalSignsDispatchContext.Provider>
    );
};

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: hidden auto;
    height: 100%;
    padding-right: 1rem;
    margin-top: -0.5rem;
    //border-top: 1px solid #dcd9e1;
`;

export default VitalSignsRealTimePartronPoC;
