import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from './lang/resource';

i18n.use(initReactI18next).init({
    resources,
    lng: 'ko',

    nsSeparator: false,
    keySeparator: ';', // we do not use keys in form messages.welcome

    interpolation: {
        escapeValue: false, // react already safes from xss
    },
});

export default i18n;
