import { useMemo } from 'react';
import { useTranslation } from '@hooks';
import { checkLostSignal } from '@util/mappInfo';

export const isLostSignal = target => {
    return !target.lastDate || checkLostSignal(target.lostSignalStatus);
};

const useTargetTitle = (target, hiddenLiveStatus) => {
    const t = useTranslation('RealTimeLocationStatus');
    return useMemo(() => {
        let title = `(name) ${target.targetName} | (ID) ${target.targetId}`;
        if (hiddenLiveStatus) {
            return title;
        }
        return title + ` > ${isLostSignal(target) ? t('Signal Not Detected') : t('Signal Detected')}`;
    }, [target, hiddenLiveStatus, t]);
};

export default useTargetTitle;
