import { useDispatch } from 'react-redux';
import { useAsync } from '@hooks/index';
import { getIntegrationServiceInfo } from '@api/common/common';
import { setFloorList } from '@reducer/Common/FloorInfo';
import { setGeofenceInfo } from '@reducer/Common/GeofenceInfo';
import { setCategoryInfo } from '@reducer/Common/CategoryInfo';
import { NOTIFICATION_SETTING_KEY, setConfigValue, setUserWideNotificationSettings } from '@reducer/Notification';
import { setEmergencyAlertConfigValue, setEmergencyAlertSettings } from '@reducer/EmergencyAlert';
import { setDepartmentsInfo } from '@reducer/Common/DepartmentsInfo';
import { setMarkerConfig } from '@reducer/Common/AppConfig';
import { setAdditionalInfo } from '@reducer/UserInfo';

// 앱 정적 설정 정보 호출
const useAppStaticInfo = () => {
    const dispatch = useDispatch();

    const {
        state: { isLoading, error },
    } = useAsync({
        promise: getIntegrationServiceInfo,
        resolve: response => {
            // FloorList 받아서 Redux에 저장
            dispatch(setFloorList(response.floor));
            // Geofence group, list 받아서 Redux에 저장
            dispatch(setGeofenceInfo({ geofenceGroupList: response.geofenceGroup, geofence: response.geofence }));
            // CategoryList, Properties 받아서 Redux에 저장
            dispatch(
                setCategoryInfo({ categories: response.targetCategory, propertiesList: response.targetCategoryConfig }),
            );
            // Category Properties 내 Ward InputValues (부서) Redux 저장 및 부서(SO) 조회
            dispatch(
                setDepartmentsInfo({
                    departmentList: response.department,
                    propertiesList: response.targetCategoryConfig,
                }),
            );
            // WMS -> 정보 -> 서비스 설정에서 알람관련 설정 변경값 받아오는 곳
            dispatch(setConfigValue(response.serviceConfig));
            dispatch(setEmergencyAlertConfigValue(response.serviceConfig));
            // Company Info 받아 Redux에 저장
            const { company } = response;
            // SuperAdmin -> 마커 설정 관련 정보
            dispatch(
                setMarkerConfig({
                    markerTransitionActive: company.markerTransitionActive,
                    markerPulseActive: company.markerPulseActive,
                    markerBorderRadius: company.markerBorderRadius,
                    markerPulseAnimation: company.markerPulseAnimation,
                    markerPulseRssi: company.markerPulseRssi,
                    markerPulseColor: company.markerPulseColor,
                }),
            );
            dispatch(setAdditionalInfo({ companyInfo: company }));
            // 사용자 전체 알림 설정 호출
            dispatch(setUserWideNotificationSettings(response.alertSetting));
            dispatch(setEmergencyAlertSettings(response.alertSetting[NOTIFICATION_SETTING_KEY.group]));
        },
        reject: error => console.error(error),
        immediate: true,
    });

    return { isLoading, error };
};

export default useAppStaticInfo;
