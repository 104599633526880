import { useEffect, useMemo } from 'react';
import {
    NOTIFICATION_PARAMETER_KEY,
    NOTIFICATION_SETTING_KEY,
    SENSOR_SCOPE_KEY,
    setNotificationContents,
} from '@reducer/Notification';
import { useDispatch, useSelector } from 'react-redux';
import {
    EVENT_TYPE_SENSOR_ITEM_STATUS_EVENT,
    EVENT_TYPE_SENSOR_STATUS_EVENT,
    EVENT_TYPE_TAG_LOCATION_EVENT,
    EVENT_TYPE_TAG_STATUS_EVENT,
} from '@reducer/SocketInfo';
import useSocket from '@util/socket/hooks/useSocket';

const LOST_SIGNAL_COMMAND_TYPE = 'LOST_SIGNAL';
const convertSensorInterfaceCommandListToSensorSocketFilterCondition = (sensorInterfaceCommandList, target) => {
    if (!sensorInterfaceCommandList) {
        return null;
    }
    if (!sensorInterfaceCommandList.length) {
        return null;
    }

    return sensorInterfaceCommandList.reduce((acc, notificationSettings) => {
        const { scope, interfaceCommandType, modelCode, sensingType } = notificationSettings;
        const isAll = scope === SENSOR_SCOPE_KEY.ALL;
        // sensor All / Item 구분 초기값 설정
        if (!acc) {
            acc = isAll ? {} : new Map();
        }
        // 전체 센서 타입의 경우
        if (isAll) {
            if (!interfaceCommandType.includes(LOST_SIGNAL_COMMAND_TYPE)) {
                acc = {
                    ...acc,
                    sensorItemList: {
                        target,
                        event: {
                            interfaceCommandType: {
                                $in: !acc.sensorItemList?.event?.interfaceCommandType
                                    ? [interfaceCommandType]
                                    : [...acc.sensorItemList.event.interfaceCommandType.$in, interfaceCommandType],
                            },
                        },
                    },
                };
            } else {
                acc = {
                    ...acc,
                    status: {
                        target,
                        event: {
                            interfaceCommandType,
                        },
                    },
                };
            }
            return acc;
        }

        if (!acc.has(modelCode)) {
            if (!interfaceCommandType.includes(LOST_SIGNAL_COMMAND_TYPE)) {
                acc.set(modelCode, {
                    sensorItemList: [
                        {
                            target,
                            sensor: { modelCode },
                            event: {
                                interfaceCommandType,
                                sensingItem: {
                                    sensingType,
                                },
                            },
                        },
                    ],
                });
                return acc;
            } else {
                acc.set(modelCode, {
                    sensorItemList: [],
                    status: {
                        target,
                        sensor: { modelCode },
                        event: {
                            interfaceCommandType,
                        },
                    },
                });
                return acc;
            }
        } else {
            const config = acc.get(modelCode);
            if (!interfaceCommandType.includes(LOST_SIGNAL_COMMAND_TYPE)) {
                const sensorItemList = config.sensorItemList;
                const findSensorItemIndex = sensorItemList.findLastIndex(
                    ({ sensor, event }) =>
                        sensor?.modelCode === modelCode && event?.sensingItem?.sensingType === sensingType,
                );
                if (findSensorItemIndex !== -1) {
                    sensorItemList[findSensorItemIndex] = {
                        ...sensorItemList[findSensorItemIndex],
                        event: {
                            ...sensorItemList[findSensorItemIndex].event,
                            interfaceCommandType: {
                                $in: sensorItemList[findSensorItemIndex].event.interfaceCommandType?.$in
                                    ? [
                                          ...sensorItemList[findSensorItemIndex].event.interfaceCommandType.$in,
                                          interfaceCommandType,
                                      ]
                                    : [
                                          sensorItemList[findSensorItemIndex].event.interfaceCommandType,
                                          interfaceCommandType,
                                      ],
                            },
                        },
                    };
                } else {
                    sensorItemList.push({
                        target,
                        sensor: { modelCode },
                        event: {
                            interfaceCommandType,
                            sensingItem: {
                                sensingType,
                            },
                        },
                    });
                }
                acc.set(modelCode, {
                    ...config,
                    sensorItemList,
                });
            } else {
                acc.set(modelCode, {
                    ...config,
                    status: {
                        target,
                        sensor: { modelCode },
                        event: {
                            interfaceCommandType,
                        },
                    },
                });
            }
            return acc;
        }
    }, null);
};

const useNotification = () => {
    const dispatch = useDispatch();
    const { selectedNotificationSettings } = useSelector(state => state.Notification);

    const { socket, setSocketEvent, removeSocketEvent } = useSocket();

    // 기기 유형별(tag, sensor) 구분하여 소켓 채널 생성
    const provideSocketChannelByDeviceType = ({ tagConfig, sensorConfigList }) => {
        const filterInfos = [];
        // 태그 조건이 존재할 때
        if (tagConfig) {
            // Tag 위치 이벤트 채널
            filterInfos.push({
                messageType: EVENT_TYPE_TAG_LOCATION_EVENT,
                filterId: setSocketEvent(EVENT_TYPE_TAG_LOCATION_EVENT, null, tagConfig),
            });

            // Tag 신호 상태 이벤트 채널
            filterInfos.push({
                messageType: EVENT_TYPE_TAG_STATUS_EVENT,
                filterId: setSocketEvent(EVENT_TYPE_TAG_STATUS_EVENT, null, tagConfig),
            });
        }
        // Sensor 조건이 존재하면서 Map의 경우
        if (sensorConfigList && sensorConfigList instanceof Map) {
            sensorConfigList.forEach(sensorConfig => {
                const { status, sensorItemList } = sensorConfig;
                // Sensor 기기 신호 상태 이벤트 채널
                if (status) {
                    filterInfos.push({
                        messageType: EVENT_TYPE_SENSOR_STATUS_EVENT,
                        filterId: setSocketEvent(EVENT_TYPE_SENSOR_STATUS_EVENT, null, status),
                    });
                }
                // Sensor item 신호 상태 이벤트 채널
                if (sensorItemList && sensorItemList.length !== 0) {
                    sensorItemList.forEach(sensorItemFilterConfig => {
                        filterInfos.push({
                            messageType: EVENT_TYPE_SENSOR_ITEM_STATUS_EVENT,
                            filterId: setSocketEvent(EVENT_TYPE_SENSOR_ITEM_STATUS_EVENT, null, sensorItemFilterConfig),
                        });
                    });
                }
            });
            // Sensor 조건이 존재하면서 객체의 경우
        } else if (sensorConfigList && !(sensorConfigList instanceof Map)) {
            const { status, sensorItemList } = sensorConfigList;
            // Sensor 기기 신호 상태 이벤트 채널
            filterInfos.push({
                messageType: EVENT_TYPE_SENSOR_STATUS_EVENT,
                filterId: setSocketEvent(EVENT_TYPE_SENSOR_STATUS_EVENT, null, status),
            });
            // Sensor item 신호 상태 이벤트 채널
            filterInfos.push({
                messageType: EVENT_TYPE_SENSOR_ITEM_STATUS_EVENT,
                filterId: setSocketEvent(EVENT_TYPE_SENSOR_ITEM_STATUS_EVENT, null, sensorItemList),
            });
        }
        return filterInfos;
    };

    const removeSocketChannelByDeviceType = filters => {
        filters.forEach(({ messageType, filterId }) => {
            removeSocketEvent(messageType, null, filterId);
        });
    };

    const socketFilterConfig = useMemo(() => {
        const resultConfig = {};

        // User socket filter config
        if (selectedNotificationSettings[NOTIFICATION_PARAMETER_KEY[NOTIFICATION_SETTING_KEY.user]]) {
            const currNotification =
                selectedNotificationSettings[NOTIFICATION_PARAMETER_KEY[NOTIFICATION_SETTING_KEY.user]];
            const target = { targetNum: { $in: selectedNotificationSettings.targetNums } };
            const tagConfig = {
                target,
                event: {
                    interfaceCommandType: {
                        $in: currNotification[NOTIFICATION_PARAMETER_KEY.beaconAlertInterfaceCommandList]
                            ? currNotification[NOTIFICATION_PARAMETER_KEY.beaconAlertInterfaceCommandList].map(
                                  value => value.interfaceCommandType,
                              )
                            : [],
                    },
                },
            };
            const sensorConfigList = convertSensorInterfaceCommandListToSensorSocketFilterCondition(
                currNotification[NOTIFICATION_PARAMETER_KEY.sensorAlertInterfaceCommandList],
                target,
            );
            resultConfig.user = { tagConfig, sensorConfigList };
        }

        // Role socket filter config
        if (selectedNotificationSettings[NOTIFICATION_PARAMETER_KEY[NOTIFICATION_SETTING_KEY.role]]) {
            const currNotification =
                selectedNotificationSettings[NOTIFICATION_PARAMETER_KEY[NOTIFICATION_SETTING_KEY.role]];

            const target = currNotification.propertyId
                ? {
                      properties: {
                          [currNotification.propertyId]: {
                              $in: currNotification.loginGroups,
                          },
                      },
                  }
                : { targetNum: { $in: selectedNotificationSettings.targetNums } };

            const tagConfig = {
                target,
                event: {
                    interfaceCommandType: {
                        $in: currNotification[NOTIFICATION_PARAMETER_KEY.beaconAlertInterfaceCommandList]
                            ? currNotification[NOTIFICATION_PARAMETER_KEY.beaconAlertInterfaceCommandList].map(
                                  value => value.interfaceCommandType,
                              )
                            : [],
                    },
                },
            };

            const sensorConfigList = convertSensorInterfaceCommandListToSensorSocketFilterCondition(
                currNotification[NOTIFICATION_PARAMETER_KEY.sensorAlertInterfaceCommandList],
                target,
            );

            resultConfig.role = { tagConfig, sensorConfigList };
        }

        // Group socket filter config
        if (selectedNotificationSettings[NOTIFICATION_PARAMETER_KEY[NOTIFICATION_SETTING_KEY.group]]) {
            const currNotification =
                selectedNotificationSettings[NOTIFICATION_PARAMETER_KEY[NOTIFICATION_SETTING_KEY.group]];
            resultConfig.group =
                currNotification && currNotification.length !== 0
                    ? currNotification.map(curr => {
                          const target = {
                              properties: {
                                  [curr.propertyId]: {
                                      $in: curr.loginGroups,
                                  },
                              },
                          };
                          return {
                              tagConfig: {
                                  target,
                                  event: {
                                      interfaceCommandType: {
                                          $in: curr[NOTIFICATION_PARAMETER_KEY.beaconAlertInterfaceCommandList]
                                              ? curr[NOTIFICATION_PARAMETER_KEY.beaconAlertInterfaceCommandList].map(
                                                    value => value.interfaceCommandType,
                                                )
                                              : [],
                                      },
                                  },
                              },
                              sensorConfigList: convertSensorInterfaceCommandListToSensorSocketFilterCondition(
                                  curr[NOTIFICATION_PARAMETER_KEY.sensorAlertInterfaceCommandList],
                                  target,
                              ),
                          };
                      })
                    : null;
        }

        return resultConfig;
    }, [selectedNotificationSettings]);

    useEffect(() => {
        const notificationFilters = [];
        // 각 채널별 필터 설정 구독
        if (socketFilterConfig.user) {
            notificationFilters.push(provideSocketChannelByDeviceType(socketFilterConfig.user));
        }
        if (socketFilterConfig.role) {
            notificationFilters.push(provideSocketChannelByDeviceType(socketFilterConfig.role));
        }
        if (socketFilterConfig.group && socketFilterConfig.group.length !== 0) {
            socketFilterConfig.group.forEach(groupFilterConfig =>
                notificationFilters.push(provideSocketChannelByDeviceType(groupFilterConfig)),
            );
        }

        // 소켓 메세지 핸들러
        const handleNotification = data => {
            if (
                notificationFilters.some(filters =>
                    filters.some(filter => data.hooksMetadata.filterIds.includes(filter.filterId)),
                )
            ) {
                dispatch(setNotificationContents(data));
            }
        };

        // Socket 채널 설정
        const eventTypeList = [
            EVENT_TYPE_TAG_LOCATION_EVENT,
            EVENT_TYPE_TAG_STATUS_EVENT,
            EVENT_TYPE_SENSOR_STATUS_EVENT,
            EVENT_TYPE_SENSOR_ITEM_STATUS_EVENT,
        ];

        eventTypeList.forEach(eventType => setSocketEvent(eventType, handleNotification));

        return () => {
            // Socket 채널 해지
            eventTypeList.forEach(eventType => removeSocketEvent(eventType, handleNotification));
            notificationFilters.forEach(filters => removeSocketChannelByDeviceType(filters));
        };
    }, [socketFilterConfig, socket]);
};

export default useNotification;
