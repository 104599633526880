import React, { useMemo, useRef, useState } from 'react';
import DashboardCard from './DashboardCard';
import { useSettings } from '../../util/useSettings';
import useMonitor from '../../util/useMonitor';
import WidgetCard from '../../Components/WidgetCard';
import { DEFAULT_DATA } from './constants';
import { getAMDashboardApi } from '@api/am/management';
import useMultiOptionsByRole from '../util/useMultiOptionsByRole';
import SearchGroupAM from '../Components/SearchGroupAM';
import useWorkTime from '../util/useWorkTime';
import useDateAM from '../util/useDateAM';

const AssetDashboard = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const wardListValues = useMultiOptionsByRole();

    const workTime = useWorkTime();
    const dateValues = useDateAM(workTime);

    const params = useMemo(
        () => ({
            ward: wardListValues.selectedWardList.toString(),
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
        }),
        [wardListValues.selectedWardList.length, dateValues.startDate, dateValues.endDate],
    );

    const [data, setData] = useState(DEFAULT_DATA);

    useMonitor({
        config,
        defaultData: DEFAULT_DATA,
        dynamicParam: params,
        fetchData: getAMDashboardApi,
        makeData: ({ newData }) => {
            const convertData = Object.fromEntries(
                Object.entries(DEFAULT_DATA).map(([key, value]) => {
                    if (typeof newData[key] !== 'object' || !newData[key]) {
                        return [key, value];
                    }
                    const responseData = newData[key];
                    const convertedValue = Object.fromEntries(
                        Object.keys(value).map(valueKey =>
                            responseData[valueKey] ? [valueKey, responseData[valueKey]] : [valueKey, value[valueKey]],
                        ),
                    );
                    return [key, convertedValue];
                }),
            );
            setData(convertData);
        },
        enabled: !isNaN(dateValues.startDate) && !isNaN(dateValues.endDate),
    });

    const firstRow = Object.entries(data).slice(0, 3);
    const secondRow = Object.entries(data).slice(3, 5);
    const thirdRow = Object.entries(data).slice(5, 7);

    return (
        <WidgetCard
            ref={widgetRef}
            widgetInfo={widgetInfo}
            searchFilter={<SearchGroupAM wardListValues={wardListValues} />}
            bodyClassName={'pt-0'}
            {...restProps}
        >
            <div className="d-flex flex-column gap-1">
                {[firstRow, secondRow, thirdRow].map((row, rowIndex) => (
                    <div className="d-flex row-cols-3 gap-1" key={rowIndex}>
                        {row.map(([header, status], index) => (
                            <DashboardCard
                                key={header}
                                header={header}
                                status={status}
                                index={index}
                                settings={settings}
                            />
                        ))}
                    </div>
                ))}
            </div>
            {children}
        </WidgetCard>
    );
};

export default AssetDashboard;

