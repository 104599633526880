import React from 'react';
import { ErrorHandleSwitch } from '../../Components/Router';
import { Redirect, Route } from 'react-router-dom';
import AuthorizationGroupList from './Components/AuthorizationGroupList';
import AuthorizationGroupDetail from './Components/AuthorizationGroupDetail';
import { PV_GROUP_NUM } from '../../Components/Router/path';

const AuthorizationGroupManagement = ({ match }) => {
    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}/`} render={() => <Redirect to={`${match.path}/list`} />} />
            <Route exact path={`${match.path}/list`} component={AuthorizationGroupList} />
            <Route
                exact
                path={[`${match.path}/create`, `${match.path}${PV_GROUP_NUM}`]}
                component={AuthorizationGroupDetail}
            />
        </ErrorHandleSwitch>
    );
};

export default AuthorizationGroupManagement;
