import React from 'react';
import { useTranslation } from '@hooks';
import { Link } from 'react-router-dom';
import { MEDICAL_WASTE, MEDICAL_WASTE_TAB_LIST } from '../../../constants';

const TabCardHeader = ({ selectedTab, setSelectedTab }) => {
    const t = useTranslation(MEDICAL_WASTE);
    return (
        <div className="card-header p-0">
            <div className="card-header__tab">
                <ul>
                    {MEDICAL_WASTE_TAB_LIST.map(({ name }) => (
                        <li key={name} className={selectedTab === name ? 'on' : ''}>
                            <Link to="#" onClick={() => setSelectedTab(name)}>
                                {t(name)}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default TabCardHeader;
