import { useState } from 'react';
import moment from 'moment';
import { DUTY_LIST } from '../../constants';

const useDateAM = ({ currentWorkTimeType: currentDuty, workTimeSet }) => {
    /*
     * moment 의 .date() 사용 이유
     * date = Date of Month (1~30)
     * day = Day of Week (0~6)
     * @link https://momentjs.com/docs/#/get-set/date
     * */
    const [date, setDate] = useState();

    if (currentDuty === DUTY_LIST.NIGHT && !date) {
        const nightStartDay = moment.unix(workTimeSet[currentDuty]?.startDate).date();

        //그달의 1일이고 전날의 night의 경우
        if (moment().date() === 1 && nightStartDay !== 1) {
            setDate(moment().subtract(1, 'days').date(nightStartDay).unix());
        } else {
            setDate(moment().date(nightStartDay).unix());
        }
    } else if ((currentDuty === DUTY_LIST.DAY || currentDuty === DUTY_LIST.EVENING) && !date) {
        setDate(moment().unix());
    }

    const handleDateChange = selected => {
        setDate(moment(selected).unix());
    };

    const handleTodayClick = () => {
        if (currentDuty === DUTY_LIST.NIGHT) {
            const nightStartDay = moment.unix(workTimeSet[currentDuty]?.startDate).date();
            if (moment().date() === 1 && nightStartDay !== 1) {
                setDate(moment().subtract(1, 'days').date(nightStartDay).unix());
            } else {
                setDate(moment().date(nightStartDay).unix());
            }
            return;
        }
        setDate(moment().unix());
    };

    const startDate = moment.unix(date).startOf('day').unix();
    const endDate = moment.unix(date).endOf('day').unix();

    return { startDate, endDate, handleDateChange, handleTodayClick };
};

export default useDateAM;
