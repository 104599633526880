import React from 'react';
import { useTranslation } from '@hooks';
import Label from '@components/Label';
import InnerItemCard from '@components/Card/InnerItemCard';
import cx from 'classnames';
import { ASSET_MANAGEMENT } from '../../../constants';
import moment from 'moment';

const DEFAULT_VALUE = '-';
const LABEL_CLASS = 'pnt-label-4';
const LABEL_GAP_CLASS = 'mt-1';
const PROPERTY_KEY = {
    WARD: 'ward',
    BORROW_WARD: 'borrowward',
    MANAGER: 'manager',
    SERIAL_NUMBER: 'serial_nu',
    YEAR_OF_RECEIPT: 'received_date',
    LAST_INSPECTION_DATE: 'last_inspect_date',
};

const GeofenceDetailPopupAssetDetail = ({ selectedTargetInfo, selectedTagInfo }) => {
    const t = useTranslation('RealTimeLocationTracking');
    const amTs = useTranslation(ASSET_MANAGEMENT);

    return (
        <InnerItemCard
            className="mt-3"
            bodyClassName="h-100 d-flex justify-content-around p-0 bg-depth-1"
            header={{ title: amTs('Asset Detail') }}
        >
            <div className="flx-1 px-3 py-2">
                <Label
                    labelGroupClassName={cx(LABEL_CLASS)}
                    name={t('Category')}
                    value={selectedTargetInfo?.categoryName ?? DEFAULT_VALUE}
                />
                <Label
                    labelGroupClassName={cx(LABEL_CLASS, LABEL_GAP_CLASS)}
                    name={amTs('Asset Name')}
                    value={selectedTargetInfo?.targetName ?? DEFAULT_VALUE}
                />
                <Label
                    labelGroupClassName={cx(LABEL_CLASS, LABEL_GAP_CLASS)}
                    name={amTs('Target ID')}
                    value={selectedTargetInfo?.targetId ?? DEFAULT_VALUE}
                />
                <Label
                    labelGroupClassName={cx(LABEL_CLASS, LABEL_GAP_CLASS)}
                    name={'S/N'}
                    value={selectedTargetInfo?.properties[PROPERTY_KEY.SERIAL_NUMBER] ?? DEFAULT_VALUE}
                />
                <Label
                    labelGroupClassName={cx(LABEL_CLASS, LABEL_GAP_CLASS)}
                    name={amTs('Received date')}
                    value={
                        selectedTargetInfo?.properties[PROPERTY_KEY.YEAR_OF_RECEIPT] &&
                        !isNaN(Number(selectedTargetInfo?.properties[PROPERTY_KEY.YEAR_OF_RECEIPT])) ? (
                            <span title={selectedTargetInfo?.properties[PROPERTY_KEY.YEAR_OF_RECEIPT]}>
                                {moment
                                    .unix(selectedTargetInfo?.properties[PROPERTY_KEY.YEAR_OF_RECEIPT])
                                    .format('YYYY-MM-DD')}
                            </span>
                        ) : (
                            <span title={DEFAULT_VALUE}>{DEFAULT_VALUE}</span>
                        )
                    }
                />
            </div>
            <div className="h-auto pnt-divider vertical-line m-0" />
            <div className="flx-1 px-3 py-2">
                <Label
                    labelGroupClassName={cx(LABEL_CLASS)}
                    name={amTs('Owner Ward')}
                    value={selectedTargetInfo?.properties[PROPERTY_KEY.WARD] || DEFAULT_VALUE}
                />
                <Label
                    labelGroupClassName={cx(LABEL_CLASS, LABEL_GAP_CLASS)}
                    name={amTs('Rental Ward')}
                    value={selectedTargetInfo?.properties[PROPERTY_KEY.BORROW_WARD] || DEFAULT_VALUE}
                />
                <Label
                    labelGroupClassName={cx(LABEL_CLASS, LABEL_GAP_CLASS)}
                    name={amTs('Rental status')}
                    value={selectedTargetInfo?.status ?? DEFAULT_VALUE}
                />
                <Label
                    labelGroupClassName={cx(LABEL_CLASS, LABEL_GAP_CLASS)}
                    name={amTs('Last Inspection Date')}
                    value={
                        selectedTargetInfo?.properties[PROPERTY_KEY.YEAR_OF_RECEIPT] ? (
                            <span title={selectedTargetInfo?.properties[PROPERTY_KEY.LAST_INSPECTION_DATE]}>
                                {!isNaN(selectedTargetInfo?.properties[PROPERTY_KEY.LAST_INSPECTION_DATE]) &&
                                !moment(selectedTargetInfo?.properties[PROPERTY_KEY.LAST_INSPECTION_DATE]).isValid()
                                    ? moment
                                          .unix(selectedTargetInfo?.properties[PROPERTY_KEY.LAST_INSPECTION_DATE])
                                          .format('YYYY-MM-DD')
                                    : DEFAULT_VALUE}
                            </span>
                        ) : (
                            <span title={DEFAULT_VALUE}>{DEFAULT_VALUE}</span>
                        )
                    }
                />
            </div>
            <div className="h-auto pnt-divider vertical-line m-0" />
            <div className="flx-1 px-3 py-2">
                <Label
                    labelGroupClassName={cx(LABEL_CLASS)}
                    name={amTs('Tag Name')}
                    value={selectedTagInfo?.tagName ?? DEFAULT_VALUE}
                />
                <Label
                    labelGroupClassName={cx(LABEL_CLASS, LABEL_GAP_CLASS)}
                    name={amTs('Floor')}
                    value={selectedTargetInfo?.floorName ?? DEFAULT_VALUE}
                />
                <Label
                    labelGroupClassName={cx(LABEL_CLASS, LABEL_GAP_CLASS)}
                    name={amTs('Location')}
                    value={selectedTagInfo?.location ?? DEFAULT_VALUE}
                />
                <Label
                    labelGroupClassName={cx(LABEL_CLASS, LABEL_GAP_CLASS)}
                    name={amTs('Signal Status')}
                    value={selectedTagInfo?.tagStatus ?? DEFAULT_VALUE}
                />
                <Label
                    labelGroupClassName={cx(LABEL_CLASS, LABEL_GAP_CLASS)}
                    name={amTs('Recognition Time')}
                    value={selectedTagInfo?.recognitionDate ?? DEFAULT_VALUE}
                />
            </div>
        </InnerItemCard>
    );
};

export default GeofenceDetailPopupAssetDetail;
