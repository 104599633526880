import React from 'react';
import { ErrorHandleSwitch } from '../../Components/Router';
import { Redirect, Route } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import ScreenList from './ScreenList';
import ScreenEdit from './ScreenEdit';
import { PV_SCREEN_CODE } from '../../Components/Router/path';

const ScreenManagement = ({ match }) => {
    return (
        <ErrorHandleSwitch>
            <Route exact path={`${match.path}/`} render={() => <Redirect to={`${match.path}/list`} />} />
            <Route exact path={`${match.path}/list`} component={ScreenList} />
            {!isMobile && <Route exact path={`${match.path}/edit${PV_SCREEN_CODE}`} component={ScreenEdit} />}
        </ErrorHandleSwitch>
    );
};

export default ScreenManagement;
