import React from 'react';
import { Button } from '@components';
import { useTranslation } from '@util/hooks';
import ExampleModal from './ExampleModal';
import useModal from '../../../util/useModal';

interface Props {
    onSendClick: () => void;
    disabled: boolean;
}

const SendContainer = ({ onSendClick, disabled }: Props) => {
    const t = useTranslation('OutpatientStorage');
    const [isOpen, handleToggle, handleOpen] = useModal();

    const handleSendClick = () => {
        onSendClick();
    };

    const handleInfoClick = () => {
        handleOpen();
    };

    return (
        <>
            <div className="d-flex align-items-center gap-2">
                <span className="fw-bold">{t('To selected patient')}</span>
                <div className="d-flex">
                    <Button className="btn-secondary" onClick={handleSendClick} disabled={disabled}>
                        {t('Send Notification')}
                    </Button>
                    <Button className="btn-icon-only bg-transparent" iconName="info" onClick={handleInfoClick} />
                </div>
            </div>
            {isOpen && <ExampleModal isOpen={isOpen} toggleModal={handleToggle} />}
        </>
    );
};

export default SendContainer;
