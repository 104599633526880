import React, { createContext, useContext } from 'react';
import IVInjectionMonitoringSlice from './IVInjectionMonitoringSlice';
import ContextProvider from '@components/ContextProvider';
import IVInjectionMonitoringContainer from './IVInjectionMonitoringContainer';

export const IVInjectionMonitoringStateContext = createContext(null);
export const IVInjectionMonitoringDispatchContext = createContext(null);

export const useIVInjectionMonitoringContext = () => useContext(IVInjectionMonitoringStateContext);
export const useIVInjectionMonitoringDispatch = () => useContext(IVInjectionMonitoringDispatchContext);

const IVInjectionMonitoring = ({ children, widgetInfo, ...restProps }) => {
    return (
        <ContextProvider
            StateContext={IVInjectionMonitoringStateContext}
            DispatchContext={IVInjectionMonitoringDispatchContext}
            slice={IVInjectionMonitoringSlice}
        >
            <IVInjectionMonitoringContainer widgetInfo={widgetInfo} children={children} {...restProps} />
        </ContextProvider>
    );
};

export default IVInjectionMonitoring;
