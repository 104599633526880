import React, { useRef } from 'react';
import { getOutpatientUsageTime, postOutpatientUsageTimeExcel } from '@api/so/outpatient';
import { useAsync, useTranslation } from '@hooks';
import WidgetCard from '../../Components/WidgetCard';
import Chart from './Chart';
import useMethodOptions from '../util/useMethodOptions';
import useDepartmentOptions from '../util/useDepartmentOptions';
import InsightBox, { BoxBodyText, HighlightedText } from '../Components/InsightBox';
import { useAsyncExcel } from '../../util/useAsyncExcel';
import useRangeDateSO from '../util/useRangeDateSO';
import SearchGroupSO from '../Components/SearchGroupSO';
import { isValidData } from '../util/checkData';
import NoDataBlock from '../../Components/NoDataBlock';
import moment from 'moment';
import { Trans } from 'react-i18next';

const OutpatientUsageAnalysisByTime = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();
    const t = useTranslation('OutpatientReport');

    const departmentValues = useDepartmentOptions();
    const methodValues = useMethodOptions();
    const dateValues = useRangeDateSO();

    const { state, promise: getData } = useAsync({
        promise: getOutpatientUsageTime,
        immediate: true,
        param: {
            departmentCode: departmentValues.department.toString(),
            method: methodValues.method.value,
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
        },
        reject: err => console.error(err),
    });
    const data = state.response ? state.response.rows : [];
    const { insightMaxTime = '-' } = state.response ? state.response.additionalDatas : {};

    const triggerDownload = useAsyncExcel({
        promise: postOutpatientUsageTimeExcel,
        param: {
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
            departmentCode: departmentValues.department.toString(),
            method: methodValues.method.value,
            columnMetas: [
                {
                    key: 'departmentName',
                    name: t('Department name'),
                },
                {
                    key: 'departmentCode',
                    name: t('Department code'),
                },
                {
                    key: 'kioskCount8',
                    name: t('Usage (08:00)'),
                },
                {
                    key: 'kioskCount9',
                    name: t('Usage (09:00)'),
                },
                {
                    key: 'kioskCount10',
                    name: t('Usage (10:00)'),
                },
                {
                    key: 'kioskCount11',
                    name: t('Usage (11:00)'),
                },
                {
                    key: 'kioskCount12',
                    name: t('Usage (12:00)'),
                },
                {
                    key: 'kioskCount13',
                    name: t('Usage (13:00)'),
                },
                {
                    key: 'kioskCount14',
                    name: t('Usage (14:00)'),
                },
                {
                    key: 'kioskCount15',
                    name: t('Usage (15:00)'),
                },
                {
                    key: 'kioskCount16',
                    name: t('Usage (16:00)'),
                },
                {
                    key: 'kioskCount17',
                    name: t('Usage (17:00)'),
                },
            ],
            fileName:
                'so_kioskData_' +
                moment.unix(dateValues.startDate).format('YYYYMMDD') +
                '_' +
                moment.unix(dateValues.endDate).format('YYYYMMDD'),
            zipFileName: 'so_usage_by_time',
        },
        reject: err => console.error(err),
    });

    const handleDownloadClick = () => {
        triggerDownload();
    };

    const meridiem = t(moment({ hour: insightMaxTime }).format('A'));
    const time = moment({ hour: insightMaxTime }).format('h');

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <SearchGroupSO
                showDepartment
                departmentValues={departmentValues}
                showMethod
                methodValues={methodValues}
                dateValues={dateValues}
                getData={getData}
            />
            {isValidData(data) ? (
                <>
                    <InsightBox>
                        <BoxBodyText>
                            <Trans t={t} values={{ meridiem, time }} components={[<HighlightedText />]}>
                                {`Smart outpatient use, such as arrival notification using kiosks, is most utilized <0>around {{meridiem}} {{time}}</0>, when outpatients are most crowded by time zone.`}
                            </Trans>
                        </BoxBodyText>
                    </InsightBox>
                    <Chart data={data} onDownloadClick={handleDownloadClick} />
                </>
            ) : (
                <NoDataBlock text={t('No data')} />
            )}
            {children}
        </WidgetCard>
    );
};

export default OutpatientUsageAnalysisByTime;
