import { advancedGenerateFakeData } from '@util/common/util';

const assetManualListInfo = {
    rows: [
        {
            manualNum: 8,
            comNum: 1,
            categoryCode: 'E011',
            categoryName: 'Defibrillator',
            description: '테스트 파일업로드_240701_3',
            regDate: 1719804776,
            fileNum: 139,
            fileName: '제세동기 매뉴얼_PDA용.pdf',
            fileUrl: 'http://127.0.0.1:8701/1/am01/manualFile/20240701123255896.pdf',
            fileDownloadUrl:
                'http://127.0.0.1:8401/v3/api/am01/manual/download?fileUrl=http://127.0.0.1:8701/1/am01/manualFile/20240701123255896.pdf&amp;fileName=제세동기 매뉴얼_PDA용.pdf',
        },
        {
            manualNum: 7,
            comNum: 1,
            categoryCode: 'E011',
            categoryName: 'Defibrillator',
            description: '테스트 파일업로드_240701_2',
            regDate: 1719800202,
            fileNum: 138,
            fileName: '제세동기 매뉴얼_PDA용.pdf',
            fileUrl: 'http://127.0.0.1:8701/1/am01/manualFile/20240701111642403.pdf',
            fileDownloadUrl:
                'http://127.0.0.1:8401/v3/api/am01/manual/download?fileUrl=http://127.0.0.1:8701/1/am01/manualFile/20240701111642403.pdf&amp;fileName=제세동기 매뉴얼_PDA용.pdf',
        },
        {
            manualNum: 3,
            comNum: 1,
            categoryCode: 'E039',
            categoryName: 'Ventilator',
            description: '테스트 파일업로드1',
            regDate: 1719561598,
            fileNum: 1,
            fileName: '20231120_NO-IBEACON_beacon_bulk_asset.pdf',
            fileUrl: 'http://10.100.206.183:8701/1/bulk/20231120175406053.pdf',
            fileDownloadUrl:
                'http://127.0.0.1:8401/v3/api/am01/manual/download?fileUrl=http://10.100.206.183:8701/1/bulk/20231120175406053.pdf&amp;fileName=20231120_NO-IBEACON_beacon_bulk_asset.pdf',
        },
        {
            manualNum: 2,
            comNum: 1,
            categoryCode: 'E039',
            categoryName: 'Ventilator',
            description: '테스트 파일업로드1',
            regDate: 1719561090,
            fileNum: 1,
            fileName: '20231120_NO-IBEACON_beacon_bulk_asset.pdf',
            fileUrl: 'http://10.100.206.183:8701/1/bulk/20231120175406053.pdf',
            fileDownloadUrl:
                'http://127.0.0.1:8401/v3/api/am01/manual/download?fileUrl=http://10.100.206.183:8701/1/bulk/20231120175406053.pdf&amp;fileName=20231120_NO-IBEACON_beacon_bulk_asset.pdf',
        },
        {
            manualNum: 1,
            comNum: 1,
            categoryCode: 'E039',
            categoryName: 'Ventilator',
            description: '테스트 파일업로드',
            regDate: 1719561016,
            fileNum: 1,
            fileName: '20231120_NO-IBEACON_beacon_bulk_asset.pdf',
            fileUrl: 'http://10.100.206.183:8701/1/bulk/20231120175406053.pdf',
            fileDownloadUrl:
                'http://127.0.0.1:8401/v3/api/am01/manual/download?fileUrl=http://10.100.206.183:8701/1/bulk/20231120175406053.pdf&amp;fileName=20231120_NO-IBEACON_beacon_bulk_asset.pdf',
        },
    ],
};

export const getAssetManualListSampleApi = advancedGenerateFakeData(assetManualListInfo);
