import React from 'react';
import cx from 'classnames';
import { LoadingBlock, Table } from '@components';
import { useColumns } from '@hooks';
import useTranslation from '@hooks/useTranslation';
import { DEFAULT_DISPLAY_VALUE } from '../../../constants';
import styles from '@asset/pnt/widgets/VitalSignsMonitoring.module.scss';

const InfoTable = ({ vitalSensor, loading }) => {
    const t = useTranslation('VitalSignMonitoring');
    const {
        RESPIRATION_RATE = DEFAULT_DISPLAY_VALUE,
        TEMPERATURE = DEFAULT_DISPLAY_VALUE,
        HEARTBEAT = DEFAULT_DISPLAY_VALUE,
    } = vitalSensor;

    const data = [
        {
            heartbeat: HEARTBEAT ?? DEFAULT_DISPLAY_VALUE,
            respirationRate: RESPIRATION_RATE ?? DEFAULT_DISPLAY_VALUE,
            skinTemperature: TEMPERATURE ?? DEFAULT_DISPLAY_VALUE,
        },
    ];

    const columns = useColumns(
        [
            {
                Header: t('HB'),
                accessor: 'heartbeat',
                className: 'text-ellipsis',
                headerClassName: 'text-ellipsis',
                // width: 250,
            },
            {
                Header: t('RR'),
                accessor: 'respirationRate',
                className: 'text-ellipsis',
                headerClassName: 'text-ellipsis',
                // width: 250,
            },
            {
                Header: t('ST'),
                accessor: 'skinTemperature',
                className: 'text-ellipsis',
                headerClassName: 'text-ellipsis',
                Cell: ({ value }) => {
                    if (value === DEFAULT_DISPLAY_VALUE) {
                        return value;
                    }
                    return Number(value).toFixed(1);
                },
                // width: 250,
            },
            // TODO: step sensor item
            // {
            //     Header: t('STEP'),
            //     accessor: 'step',
            //     className: 'text-ellipsis',
            //     headerClassName: 'text-ellipsis',
            //     // width: 250,
            // },
        ],
        null,
        [],
    );

    return (
        <div className={cx(styles.detail_info_table, 'mb-3')}>
            <LoadingBlock blocking={loading}>
                <Table className="table-for-viewer" data={{ rows: data }} columns={columns} paging={false} />
            </LoadingBlock>
        </div>
    );
};

export default InfoTable;
