import { reqGet } from '@api/index';
import { NullableString, NullableNumber, YN, NullableUnixTimestamp } from '@util/type/util';
import { PageInfoParam, PageInfoType, RequestHandlerCreator } from '@api/util';

type FloorType = 'REGION' | 'ZONE' | 'BUILDING' | 'FLOOR';

export interface FloorInfo {
    comNum: number;
    deg: NullableNumber;
    floorCodeNum: number;
    floorId: string;
    floorIdPath: string;
    floorName: string;
    floorNamePath: string;
    floorNum: NullableNumber;
    imgSrc: NullableString;
    imgURL: NullableString;
    latRate: number;
    levelNo: number;
    lngRate: number;
    modDate: NullableUnixTimestamp;
    neLat: NullableNumber;
    neLng: NullableNumber;
    regDate: NullableUnixTimestamp;
    rotation: number;
    sortNo: number;
    swLat: NullableNumber;
    swLng: NullableNumber;
    typeCode: FloorType;
    upperFloorId: NullableString;
    useFlag: YN;
    zoom: number;
}

export interface FloorListParam extends PageInfoParam {
    floorCodeNum?: number;
    floorId?: string;
    floorName?: string;
}

export interface FloorParam {
    floorId?: string;
}

export const fetchFloorList: RequestHandlerCreator<PageInfoType<FloorInfo>, FloorListParam> = param =>
    reqGet('/v3/api/common/floor', { ...param, isAll: 'Y' });

export const fetchSelectedFloorInfo: RequestHandlerCreator<FloorInfo, FloorParam> = ({ floorId, ...restParam }) =>
    reqGet(`/v3/api/common/floor/${floorId}`, restParam);
