import React, { useEffect, useRef, useState } from 'react';
import { Label, Map, RotatedImageOverlay } from '@components';
import { useTranslation } from '@hooks';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ASSET_MANAGEMENT } from '../../../constants';
import { DEFAULT_LABEL_VALUE } from '../constants';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { convertProperties } from '../../../util/commons';
import usePropertyList from '../hooks/usePropertyList';
import AniMarker from '@components/Map/Components/AniMarker';

const PROPERTY_KEY = {
    WARD: 'ward',
    BORROW_WARD: 'borrowward',
    MANAGER: 'manager',
    MODEL_NUMBER: 'model_nu',
    SERIAL_NUMBER: 'serial_nu',
    YEAR_OF_RECEIPT: 'received_date',
    LAST_INSPECTION_DATE: 'last_inspect_date',
};

const AssetDetail = ({ selectedIotItem, status, geofence, beaconName, hiddenMapTile }) => {
    const t = useTranslation(ASSET_MANAGEMENT);
    const mapRef = useRef();
    const { floorList } = useSelector(state => state.FloorInfo);
    const categoryProperties = usePropertyList();

    const confirmedTile = hiddenMapTile !== undefined ? !hiddenMapTile : true;

    const [floorInfo, setFloorInfo] = useState({});
    useEffect(() => {
        if (selectedIotItem) {
            const { floorId } = selectedIotItem;
            setFloorInfo(floorList.find(floor => floor.floorId === floorId));
        }
    }, [selectedIotItem, floorList]);

    const {
        categoryName,
        targetId,
        targetName,
        floorName,
        lastDate,
        properties,
        lat,
        lng,
        rentalStatus,
    } = selectedIotItem;

    const targetInfo = {
        target: selectedIotItem,
        location: {
            latLng: {
                lat,
                lng,
            },
        },
    };

    const convertedProperties = convertProperties(properties, categoryProperties);
    const displayNameProperties = Object.fromEntries(
        categoryProperties.map(({ propertyId, displayName }) => [propertyId, displayName]),
    );
    return (
        <div className="d-flex h-100">
            <PerfectScrollbar className={'w-25 p-3 border-lightgray'}>
                <div className="flx-col gap-2 pnt-label-6">
                    <Label
                        name={t('Category')}
                        labelOptionsClassName="text-ellipsis"
                        value={
                            <span className="text-ellipsis" title={categoryName}>
                                {categoryName}
                            </span>
                        }
                    />
                    <Label
                        name={t('Asset Name')}
                        labelOptionsClassName="text-ellipsis"
                        value={
                            <span className="text-ellipsis" title={targetName}>
                                {targetName}
                            </span>
                        }
                    />
                    <Label
                        name={t('Asset ID')}
                        labelOptionsClassName="text-ellipsis text-uppercase"
                        value={
                            <span className="text-ellipsis" title={targetId}>
                                {targetId}
                            </span>
                        }
                    />
                    <Label
                        name={displayNameProperties[PROPERTY_KEY.MODEL_NUMBER]}
                        labelOptionsClassName="text-ellipsis"
                        value={
                            <span
                                className="text-ellipsis"
                                title={convertedProperties[PROPERTY_KEY.MODEL_NUMBER] || DEFAULT_LABEL_VALUE}
                            >
                                {convertedProperties[PROPERTY_KEY.MODEL_NUMBER] || DEFAULT_LABEL_VALUE}
                            </span>
                        }
                    />
                    <Label
                        name={t('S/N')}
                        labelOptionsClassName="text-ellipsis"
                        value={
                            <span
                                className="text-ellipsis"
                                title={convertedProperties[PROPERTY_KEY.SERIAL_NUMBER] || DEFAULT_LABEL_VALUE}
                            >
                                {convertedProperties[PROPERTY_KEY.SERIAL_NUMBER] || DEFAULT_LABEL_VALUE}
                            </span>
                        }
                    />
                    <Label
                        name={displayNameProperties[PROPERTY_KEY.YEAR_OF_RECEIPT]}
                        labelOptionsClassName="text-ellipsis"
                        value={
                            convertedProperties[PROPERTY_KEY.YEAR_OF_RECEIPT] &&
                            !isNaN(Number(convertedProperties[PROPERTY_KEY.YEAR_OF_RECEIPT])) ? (
                                <span
                                    className="text-ellipsis"
                                    title={convertedProperties[PROPERTY_KEY.YEAR_OF_RECEIPT]}
                                >
                                    {moment
                                        .unix(convertedProperties[PROPERTY_KEY.YEAR_OF_RECEIPT])
                                        .format('YYYY-MM-DD')}
                                </span>
                            ) : (
                                <span className="text-ellipsis" title={DEFAULT_LABEL_VALUE}>
                                    {DEFAULT_LABEL_VALUE}
                                </span>
                            )
                        }
                    />

                    <div className="pnt-divider horizon-line" />

                    <Label
                        name={t('Asset status')}
                        labelOptionsClassName="text-ellipsis"
                        value={
                            <span className="text-ellipsis" title={DEFAULT_LABEL_VALUE}>
                                {rentalStatus === 'CHECK_OUT' ? t('Lending') : '-'}
                            </span>
                        }
                    />

                    <Label
                        name={t('Owner Ward')}
                        labelOptionsClassName="text-ellipsis"
                        value={
                            <span
                                className="text-ellipsis"
                                title={convertedProperties[PROPERTY_KEY.WARD] || DEFAULT_LABEL_VALUE}
                            >
                                {convertedProperties[PROPERTY_KEY.WARD] || DEFAULT_LABEL_VALUE}
                            </span>
                        }
                    />
                    <Label
                        name={t('Rent Ward')}
                        labelOptionsClassName="text-ellipsis"
                        value={
                            <span
                                className="text-ellipsis"
                                title={convertedProperties[PROPERTY_KEY.BORROW_WARD] || DEFAULT_LABEL_VALUE}
                            >
                                {convertedProperties[PROPERTY_KEY.BORROW_WARD] || DEFAULT_LABEL_VALUE}
                            </span>
                        }
                    />
                    <Label
                        name={t('Last Inspection Date')}
                        labelOptionsClassName="text-ellipsis"
                        value={
                            convertedProperties[PROPERTY_KEY.LAST_INSPECTION_DATE] &&
                            !isNaN(Number(convertedProperties[PROPERTY_KEY.LAST_INSPECTION_DATE])) ? (
                                <span
                                    className="text-ellipsis"
                                    title={convertedProperties[PROPERTY_KEY.LAST_INSPECTION_DATE]}
                                >
                                    {moment
                                        .unix(convertedProperties[PROPERTY_KEY.LAST_INSPECTION_DATE])
                                        .format('YYYY-MM-DD')}
                                </span>
                            ) : (
                                <span title={DEFAULT_LABEL_VALUE}>{DEFAULT_LABEL_VALUE}</span>
                            )
                        }
                    />

                    <div className="pnt-divider horizon-line" />

                    <Label
                        name={t('Tag Name')}
                        labelOptionsClassName="text-ellipsis"
                        value={beaconName || DEFAULT_LABEL_VALUE}
                    />
                    <Label
                        name={t('Floor')}
                        labelOptionsClassName="text-ellipsis"
                        value={floorName || DEFAULT_LABEL_VALUE}
                    />
                    <Label
                        name={t('Location')}
                        labelOptionsClassName="text-ellipsis"
                        value={geofence || DEFAULT_LABEL_VALUE}
                    />
                    <Label
                        name={t('Signal Status')}
                        labelOptionsClassName="text-ellipsis"
                        value={status || DEFAULT_LABEL_VALUE}
                    />
                    <Label
                        name={t('Recognition Time')}
                        labelOptionsClassName="text-ellipsis"
                        value={
                            <span className="text-ellipsis">
                                {lastDate ? moment.unix(lastDate).format('YYYY-MM-DD HH:mm:ss') : DEFAULT_LABEL_VALUE}
                            </span>
                        }
                    />

                    <div className="pnt-divider horizon-line" />
                </div>
            </PerfectScrollbar>
            <div className={'w-75 ml-2 border-lightgray'}>
                <Map tile={confirmedTile} ref={mapRef} rotation={floorInfo?.rotation}>
                    {floorInfo?.imgURL && (
                        <RotatedImageOverlay
                            key={floorInfo.floorId}
                            url={floorInfo.imgURL}
                            deg={floorInfo.deg}
                            bounds={floorInfo.bounds}
                            onLoad={() => {
                                const map = mapRef.current.leafletElement;
                                if (map) {
                                    map.setView([lat, lng]);
                                }
                            }}
                        />
                    )}
                    <AniMarker key={targetId} targetInfo={targetInfo} />
                </Map>
            </div>
        </div>
    );
};

export default AssetDetail;
