import { getOutpatientUsageDay, postOutpatientUsageDayExcel } from '@api/so/outpatient';
import { useAsync, useTranslation } from '@hooks';
import React, { useRef } from 'react';
import InsightBox, { BoxBodyText, HighlightedText } from '../Components/InsightBox';
import SearchGroupSO from '../Components/SearchGroupSO';
import WidgetCard from '../../Components/WidgetCard';
import { useAsyncExcel } from '../../util/useAsyncExcel';
import useDepartmentOptions from '../util/useDepartmentOptions';
import useMethodOptions from '../util/useMethodOptions';
import useRangeDateSO from '../util/useRangeDateSO';
import Chart from './Chart';
import { isValidData } from '../util/checkData';
import NoDataBlock from '../../Components/NoDataBlock';
import { Trans } from 'react-i18next';
import moment from 'moment';

const OutpatientUsageAnalysisByDay = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();
    const t = useTranslation('OutpatientReport');

    const departmentValues = useDepartmentOptions();
    const methodValues = useMethodOptions();
    const dateValues = useRangeDateSO('weekly');

    const { state, promise: getData } = useAsync({
        promise: getOutpatientUsageDay,
        immediate: true,
        param: {
            departmentCode: departmentValues.department.toString(),
            method: methodValues.method.value,
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
        },
        reject: err => console.error(err),
    });
    const data = state.response ? state.response.rows : [];
    const { insightWeekdays = [] } = state.response ? state.response.additionalDatas : {};
    const [originDay1, originDay2] = insightWeekdays;
    const day1 = t(originDay1);
    const day2 = t(originDay2);

    const triggerDownload = useAsyncExcel({
        promise: postOutpatientUsageDayExcel,
        param: {
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
            departmentCode: departmentValues.department.toString(),
            method: methodValues.method.value,
            columnMetas: [
                {
                    key: 'departmentName',
                    name: t('Department name'),
                },
                {
                    key: 'departmentCode',
                    name: t('Department code'),
                },
                {
                    key: 'kioskCountMon',
                    name: t('Usage (Mon)'),
                },
                {
                    key: 'kioskCountTue',
                    name: t('Usage (Tue)'),
                },
                {
                    key: 'kioskCountWed',
                    name: t('Usage (Wed)'),
                },
                {
                    key: 'kioskCountThu',
                    name: t('Usage (Thu)'),
                },
                {
                    key: 'kioskCountFri',
                    name: t('Usage (Fri)'),
                },
            ],
            fileName:
                'so_kioskData_' +
                moment.unix(dateValues.startDate).format('YYYYMMDD') +
                '_' +
                moment.unix(dateValues.endDate).format('YYYYMMDD'),
            zipFileName: 'so_usage_by_day',
        },
        reject: err => console.error(err),
    });

    const handleDownloadClick = () => {
        triggerDownload();
    };

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <SearchGroupSO
                dateTypeValues={{ dateType: { value: 'weekly' } }}
                showDepartment
                departmentValues={departmentValues}
                showMethod
                methodValues={methodValues}
                dateValues={dateValues}
                getData={getData}
            />
            {isValidData(data) ? (
                <>
                    <InsightBox>
                        <BoxBodyText>
                            <Trans t={t} values={{ day1, day2 }} components={[<HighlightedText />]}>
                                {`Smart outpatient use, such as arrival notification using kiosks, is most frequently used by day of the week, in that order, <0>{{day1}}</0> and <0>{{day2}}</0>.`}
                            </Trans>
                        </BoxBodyText>
                    </InsightBox>
                    <Chart data={data} onDownloadClick={handleDownloadClick} />
                </>
            ) : (
                <NoDataBlock text={t('No data')} />
            )}
            {children}
        </WidgetCard>
    );
};

export default OutpatientUsageAnalysisByDay;
