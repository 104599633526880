import React from 'react';
import InnerItemCard from '@components/Card/InnerItemCard';
import ItemCount from './ItemCounts';

const LIST = ['waitingCare', 'nonWaitingCare'];
const OutpatientWaitingCounts = ({ waitingPatientCount }) => {
    return (
        <InnerItemCard>
            <div className="widget-item-nums">
                {LIST.map(status => {
                    return <ItemCount key={status} start={0} end={waitingPatientCount[status]} label={status} />;
                })}
            </div>
        </InnerItemCard>
    );
};

export default OutpatientWaitingCounts;
