import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TYPE_TO_SELECT } from '../../Components/MainPages/Components/FilterWidget/dynamicOption';
import { CategoryInfo, CategoryPropertyInfo } from '@api/common/category';
import { NullableString } from '@util/type/util';

interface CategoryInfoStore {
    topCategoryList: TopCategorySimpleInfo[];
    categoryList: CategoryInfo[];
    categoryPropertiesList: CategoryPropertyInfo[];
    searchableCategoryPropertiesList: CategoryPropertyInfo[];
    categoryToImg: {
        [key: string]: NullableString;
    };
}

interface TopCategorySimpleInfo {
    value: string;
    label: string;
}

const initialState: CategoryInfoStore = {
    topCategoryList: [],
    categoryList: [],
    categoryPropertiesList: [],
    searchableCategoryPropertiesList: [],
    categoryToImg: {},
};

const { actions, reducer } = createSlice({
    name: 'common/categoryInfo',
    initialState,
    reducers: {
        setCategoryInfo: (
            state,
            action: PayloadAction<{ propertiesList: CategoryPropertyInfo[]; categories: CategoryInfo[] }>,
        ) => {
            const { propertiesList, categories } = action.payload;
            // State Category Properties
            state.categoryPropertiesList = propertiesList;
            state.searchableCategoryPropertiesList = propertiesList.filter(
                v => v.isSearchable === 'Y' && TYPE_TO_SELECT.includes(v.inputType),
            );

            // State Category List
            const categoryList = [...categories];
            if (categoryList && Array.isArray(categoryList)) {
                categoryList.sort((a, b) => (a.categoryCodePath > b.categoryCodePath ? 1 : -1));
                state.categoryToImg = {};
                categoryList.forEach(category => {
                    state.categoryToImg[category.categoryCode] = category.normalIconURL;
                });
            }

            state.categoryList = categoryList;
            state.topCategoryList = categoryList
                .filter(category => !category.depth)
                .map(topCategory => {
                    return { value: topCategory.categoryCode, label: topCategory.categoryName };
                });
        },
    },
});

export const { setCategoryInfo } = actions;
export default reducer;
