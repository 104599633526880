import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const useGroupFloor = ({ handleChangeFloor }) => {
    const { representationGroupInfo } = useSelector(state => state.UserInfo.userInfo);
    const floorList = useSelector(state => state.FloorInfo.floorList);

    useEffect(() => {
        if (representationGroupInfo && floorList.length) {
            const representationFloorInfo = floorList.find(floor => floor.floorId === representationGroupInfo.floorId);
            if (typeof handleChangeFloor) {
                handleChangeFloor(representationFloorInfo);
            }
        }
    }, [representationGroupInfo, floorList]);
};

export default useGroupFloor;
