import React, { useState } from 'react';

import Setting from './Components/Setting';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import { fetchUserInfo } from '@api/common/login';
import useAsync from '@hooks/useAsync';

import DropDownHeader from './Components/DropDownHeader';
import ProfileModal from '../UserBoxPopup/ProfileModal';
import LogoutModal from '../UserBoxPopup/LogoutModal';
import PersonalizationModal from '../UserBoxPopup/PersonalizationModal';
import { useSelector } from 'react-redux';
import { getUnreadNotificationCountApi } from '@api/common/notification';

const UserBox = () => {
    const { selectedNotificationSettings } = useSelector(state => state.Notification);

    const [userInfo, setUserInfo] = useState({});
    useAsync({
        promise: fetchUserInfo,
        immediate: true,
        resolve: res => {
            const { userID, userName, userEmail } = res;
            setUserInfo({ userID, userName, userEmail });
        },
        reject: error => console.error(error),
    });

    const [count, setCount] = useState(0);
    const { promise: getUnreadNotificationCount } = useAsync({
        promise: getUnreadNotificationCountApi,
        resolve: res => {
            const { count } = res;
            setCount(count);
        },
        reject: error => console.error(error),
    });

    const [logoutModal, setLogoutModal] = useState(false);
    const toggleLogoutModal = () => {
        setLogoutModal(!logoutModal);
    };

    const [profileModal, setProfileModal] = useState(false);
    const toggleProfileModal = () => {
        setProfileModal(!profileModal);
    };

    const [personalizationModal, setPersonalizationModal] = useState(false);
    const togglePersonalizationModal = () => {
        setPersonalizationModal(!personalizationModal);
    };

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        if (!isOpen) {
            getUnreadNotificationCount(selectedNotificationSettings);
        }
        setIsOpen(!isOpen);
    };

    return (
        <div className="user-box ml-2">
            <Dropdown isOpen={isOpen} toggle={toggle}>
                <DropdownToggle className="user-btn p-0 border-0 text-decoration-none">
                    <span className="material-icons-round">person_outline</span>
                    <div className="user-name">
                        <b>{userInfo.userName}</b>
                    </div>
                    <span className="material-icons-round">arrow_drop_down</span>
                </DropdownToggle>
                <DropdownMenu right className="user-options rm-pointers p-0 border-0">
                    <DropDownHeader count={count} userInfo={userInfo} toggleLogoutModal={toggleLogoutModal} />
                    <Setting
                        toggleProfileModal={toggleProfileModal}
                        togglePersonalizationModal={togglePersonalizationModal}
                    />
                </DropdownMenu>
            </Dropdown>
            <LogoutModal initModal={logoutModal} toggleModal={toggleLogoutModal} />
            <ProfileModal initModal={profileModal} toggleModal={toggleProfileModal} userInfo={userInfo} />
            <PersonalizationModal initModal={personalizationModal} toggleModal={togglePersonalizationModal} />
        </div>
    );
};

export default UserBox;
