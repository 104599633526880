import React, { useMemo, createContext, useEffect, useReducer } from 'react';
import { GroupBase, MenuListProps, StylesConfig } from 'react-select';

import { Select } from '@components';
import { SelectProps } from '@components/Select';
import { OptionType } from '@components/type';
import { TreeMenuList, ValueContainerText } from '@components/Select/TreeSelect/Parts';
import { reducer, TreeState } from '@components/Select/utils';
import { SelectControl } from '@components/Select/Parts';
import { DropdownIndicator } from '@components/Select/Components/Common';

import { defaultStyle } from '../selectStyle';

const selectStyles: StylesConfig<OptionType, boolean, GroupBase<OptionType>> = {
    ...defaultStyle,
    container: provided => ({ ...provided, width: 'unset', minWidth: '9rem', maxWidth: '14rem' }),
    control: provided => ({ ...provided }),
    menu: provided => ({ ...provided, minWidth: '11.5rem', boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
};

export const TreeSelectContext = createContext<TreeState>({
    labelKey: 'label',
    valueKey: 'value',
    treeKey: '',
    parentKey: 'parent',
    treeData: [],
    flattenData: [],
});

interface TreeSelectProps extends Partial<SelectProps> {
    data: OptionType[];
    selected?: OptionType[];
    title?: string;
    treeKey?: string;
    parentKey?: string;
    MenuListComponent?: React.ComponentType<MenuListProps<OptionType>>;
}
const TreeSelect = ({
    data = [],
    selected = [],
    title,
    labelKey = 'label',
    valueKey = 'value',
    treeKey,
    parentKey = 'parent',
    onChange,
    isModalSelect = false,
    MenuListComponent,
    ...restProps
}: TreeSelectProps) => {
    const [state, dispatch] = useReducer(reducer, {
        title,
        labelKey,
        valueKey,
        treeKey: treeKey || valueKey,
        parentKey,
        flattenData: data,
        treeData: [],
    });

    const selectedOptions = useMemo(() => {
        const selectedKeyArr = selected.map(v => {
            if (typeof v === 'object') {
                return v[valueKey];
            }
            return v;
        });
        return data.reduce((acc, curr) => {
            if ((selectedKeyArr || []).includes(curr[valueKey])) {
                acc.push(curr);
            }
            return acc;
        }, []);
    }, [data, selected, valueKey]);

    useEffect(() => {
        dispatch({ type: 'UPDATE_FLATTEN_DATA', payload: data });
    }, [data]);

    useEffect(() => {
        dispatch({ type: 'UPDATE_TITLE', payload: title });
    }, [title]);

    return (
        <TreeSelectContext.Provider value={state}>
            <Select
                isModalSelect={isModalSelect}
                isMulti={true}
                options={state.treeData}
                value={selectedOptions}
                valueKey={valueKey}
                labelKey={labelKey}
                tabSelectsValue={false}
                onChange={onChange}
                controlShouldRenderValue={false}
                backspaceRemovesValue={false}
                components={{
                    Control: SelectControl,
                    Placeholder: ValueContainerText,
                    DropdownIndicator,
                    MenuList: MenuListComponent || TreeMenuList,
                }}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                styles={selectStyles}
                {...restProps}
            />
        </TreeSelectContext.Provider>
    );
};

export default TreeSelect;
