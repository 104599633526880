import { Chart } from 'chart.js';

// Container Background Plugin Type
export interface ContainerBackgroundOptions {
    color: string | null;
}

export default {
    id: 'containerBackground',
    beforeDraw(chart: Chart, args: object, options: ContainerBackgroundOptions): boolean | void {
        const { ctx, canvas } = chart;

        ctx.save();
        // container background 색상 지정
        ctx.fillStyle = options?.color || 'transparent';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.restore();
    },
    defaults: {
        color: 'transparent',
    },
};
