import React, { useState } from 'react';
import { Button, Table } from '@components';
import { useAsync, useColumns, useTranslation } from '@hooks';
import * as column from '@util/grid/column';
import { getIotItemApi } from '@api/common/asset';
import moment from 'moment';
import styled from 'styled-components';
import Search from './Search';

import { useSettings } from '../../../../util/useSettings';
import IotItemStatusDetails from '../IotItemStatusDetails';
import cx from 'classnames';
import Bookmark from '../../../../Components/Bookmark';
import NoDataBlock from '../../../../Components/NoDataBlock';
import { useSelector } from 'react-redux';
import { AREA_KEY, DEFAULT_PARAMETERS, SIGNAL_STATUS, TITLES, TRUE_FLAG } from '../../constants';
import InnerItemCard from '@components/Card/InnerItemCard';

const IotItemStatusList = ({
    widgetInfo,
    dynamicParam,
    iotItemList,
    handleIotItemListParam,
    bookmarkSuccessCallback,
}) => {
    const t = useTranslation('IotItemStatus');
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { categoryCode: parentCategory, hiddenMapTile } = settings;
    const { categoryPropertiesList } = useSelector(state => state.CategoryInfo);

    // list에서 표시할 선택된 카테고리 속성
    const properties = categoryPropertiesList
        .filter(property => property.categoryCode === parentCategory && property.isListProperty === TRUE_FLAG)
        .sort((a, b) => a.sortOrder - b.sortOrder);

    // 선택된 카테고리의 IoT Item list 속성 항목
    const currentPropertyColumns = properties.map(({ displayName, propertyId, inputValues }) => {
        return {
            Header: displayName,
            accessor: `properties.${propertyId}`,
            className: 'text-ellipsis',
            width: 100,
            Cell: row => {
                const { value } = row;
                if (value == null) {
                    return <span>-</span>;
                }
                if (!inputValues.length) {
                    return <span title={value}>{value}</span>;
                }
                const findValue = inputValues.find(({ value: inputValue }) => inputValue === value);
                const valueName = findValue != null ? findValue.name : '-';
                return <span title={valueName}>{valueName}</span>;
            },
        };
    });

    // IoT Item 상세정보
    const [selectedIotItem, setSelectedIotItem] = useState(null);
    // IoT Item 상세 조회
    const { promise: getIotItems } = useAsync({
        promise: getIotItemApi,
        resolve: res => {
            setSelectedIotItem(res);
        },
        reject: err => console.error(err),
    });

    // IoT Item Table Row 선택
    const handleIoTItemClick = selected => {
        const { targetNum } = selected;
        getIotItems({ targetNum });
    };

    // Back button event
    const handleBackButton = () => {
        setSelectedIotItem(null);
    };

    // Reset button event
    const handleResetListParams = () => {
        handleIotItemListParam(DEFAULT_PARAMETERS);
    };

    // 신호상태 정보
    const translateIotItemStatus = statusList => {
        return statusList.map(status => t(status)).join(', ');
    };

    // IoT items table data
    const columns = useColumns(
        [
            column.categoryName({ width: 80 }),
            column.targetName({ Header: `${t(parentCategory)} ${t('Name')}` }),
            ...currentPropertyColumns,
            column.tagName({ width: 80 }),
            column.floorName({
                width: 100,
                accessor: 'floorName',
                Cell: ({ value }) => {
                    const floorName = value ? value : '-';
                    return <span title={floorName}>{floorName}</span>;
                },
            }),
            column.location({
                Cell: ({ row }) => {
                    const { original } = row;
                    const { status, properties } = original;
                    let classNames = '';

                    const existArea = !!properties[AREA_KEY];
                    if (
                        existArea &&
                        (status.includes(SIGNAL_STATUS.LOST_SIGNAL) ||
                            status.includes(SIGNAL_STATUS.OUT_OF_AUTHORIZED_ZONE))
                    ) {
                        classNames = 'bg-danger color-white text-ellipsis';
                    }
                    return original.geofences ? (
                        <span className={cx('h-100 flx-col flx-center', classNames)}>
                            {original.geofences.map(geofence => geofence.fcName).join(', ')}
                        </span>
                    ) : (
                        <span className={cx('h-100 flx-col flx-center', classNames)}>-</span>
                    );
                },
            }),
            column.signalStatus({
                width: 200,
                Cell: ({ value }) => {
                    const statusName = translateIotItemStatus(value); // 다국어 처리
                    const critical =
                        value.includes(SIGNAL_STATUS.LOW_BATTERY) || value.includes(SIGNAL_STATUS.NO_RESPONSE);
                    const warning =
                        value.includes(SIGNAL_STATUS.OUT_OF_AUTHORIZED_ZONE) ||
                        value.includes(SIGNAL_STATUS.LOST_SIGNAL);
                    let classNames = '';
                    // 조건 1: 배터리 부족, 응답없음
                    if (critical) {
                        classNames = 'bg-brand color-white text-ellipsis';
                    }
                    // 조건 2: 신호상실, 비인가지역 진입
                    if (warning) {
                        classNames = 'bg-info text-ellipsis';
                    }
                    return (
                        <span title={statusName} className={cx('h-100 flx-col flx-center', classNames)}>
                            {statusName}
                        </span>
                    );
                },
            }),
            column.lastDate({
                formatTitle: ({ value }) => moment.unix(value).format('YYYY-MM-DD HH:mm:ss'),
                Cell: ({ value }) => {
                    const displayFormat = value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : '-';
                    return <div title={displayFormat}>{displayFormat}</div>;
                },
            }),
            column.bookmark({
                width: 80,
                headerClassName: 'text-nowrap',
                className: 'text-ellipsis d-flex flx-center',
                Cell: ({ row: { original } }) => {
                    return (
                        <Bookmark
                            isBookmark={original.isBookmark}
                            targetNum={original.targetNum}
                            successCallback={bookmarkSuccessCallback}
                        />
                    );
                },
            }),
        ],
        null,
        [dynamicParam],
    );

    // fixme: 선택 item 신호상태 정보 상세화면으로 값 보내기용 (임시 사용중)
    const getStatusList = selectedIotItem => {
        const findItem = iotItemList.rows.find(item => item.targetNum === selectedIotItem.targetNum);
        if (!findItem || !findItem.status) {
            return '-';
        }
        return translateIotItemStatus(findItem.status);
    };

    // fixme: 선택 item 신호위치 정보 상세화면으로 값 보내기용 (임시 사용중)
    const getGeofence = selectedIotItem => {
        const findItem = iotItemList.rows.find(item => item.targetNum === selectedIotItem.targetNum);
        if (!findItem || !findItem.geofences) {
            return '-';
        }
        return findItem.geofences.map(geofence => geofence.fcName).join(', ');
    };

    // fixme: 선택 item 신호위치 정보 상세화면으로 값 보내기용 (임시 사용중)
    const getBeaconName = selectedIotItem => {
        const findItem = iotItemList.rows.find(item => item.targetNum === selectedIotItem.targetNum);
        if (!findItem || !findItem.beaconName) {
            return '-';
        }
        return findItem.beaconName;
    };

    return (
        <InnerItemCard
            header={{
                title: selectedIotItem ? t('Selected Item Information') : t(TITLES[parentCategory]),
                action: selectedIotItem && (
                    <Button className="btn-gray" onClick={handleBackButton}>
                        {t('Back', 'Button')}
                    </Button>
                ),
            }}
            className="mt-3"
            bodyClassName={''}
        >
            {dynamicParam.categoryCodes ? (
                selectedIotItem ? (
                    <BodyContainer>
                        <IotItemStatusDetails
                            selectedIotItem={selectedIotItem}
                            setSelectedIotItem={setSelectedIotItem}
                            parentCategoryCode={parentCategory}
                            status={getStatusList(selectedIotItem)}
                            geofence={getGeofence(selectedIotItem)}
                            beaconName={getBeaconName(selectedIotItem)}
                            bookmarkSuccessCallback={bookmarkSuccessCallback}
                            hiddenMapTile={hiddenMapTile}
                        />
                    </BodyContainer>
                ) : (
                    <>
                        <Search categoryCode={parentCategory} handleReset={handleResetListParams} />
                        <BodyContainer>
                            <Table
                                columns={columns}
                                data={iotItemList}
                                onPageChange={handleIotItemListParam}
                                onTrClick={handleIoTItemClick}
                            />
                        </BodyContainer>
                    </>
                )
            ) : (
                <NoDataBlock />
            )}
        </InnerItemCard>
    );
};

const BodyContainer = styled.div`
    height: 33rem;
`;

export default IotItemStatusList;
