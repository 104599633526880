import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch, withRouter } from 'react-router-dom';
import cx from 'classnames';
import ResizeDetector from 'react-resize-detector';
import AppMain from '../Layout/AppMain';
import { TopSwitch } from './Components/Router';
import { useTranslation } from 'react-i18next';
import { setInitAppInfo } from '@reducer/Common/AppInfo';
import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';
import LicenseModal from './Components/LicenseModal';
import { A_PATH_EMBED_CUSTOM_SCREEN } from './Components/Router/path';
import { useAppSelector, useLicenseErrorCheck } from '@hooks';

const Main = ({ appInfo, location }) => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const { colorScheme, enableMobileMenu, enableFixedSidebar, enableClosedSidebar } = useAppSelector(
        state => state.ThemeOptions,
    );
    const lang = useAppSelector(state => state.UserInfo.lang);
    const { fullScreen, mode } = useAppSelector(state => state.ScreenInfo);
    // embed 화면 체크용
    const embedMatch = useRouteMatch(A_PATH_EMBED_CUSTOM_SCREEN);

    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [lang]);

    useEffect(() => {
        if (appInfo) {
            dispatch(setInitAppInfo(appInfo));
        }
    }, [appInfo]);

    useEffect(() => {
        document.documentElement.setAttribute('color-theme', colorScheme);
    }, [colorScheme]);
    useLicenseErrorCheck();

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <div
                    className={cx(
                        'fixed-header fixed-footer',
                        'app-container',
                        !location.pathname.match(/^\/login$/) && 'app-theme-' + colorScheme,
                        { 'background-color-unset': location.pathname.match(/^\/login$/) },
                        { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
                        { 'closed-sidebar': enableClosedSidebar || width < 1250 },
                        { 'closed-sidebar-mobile': width < 1250 },
                        { 'sidebar-mobile-open': enableMobileMenu },
                        { 'full-screen-viewer': fullScreen },
                        { 'dashboard-edit-mode': [SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW].includes(mode) },
                        { 'embed-container': embedMatch },
                    )}
                >
                    <TopSwitch MainComponent={AppMain} />
                    <LicenseModal />
                </div>
            )}
        />
    );
};

export default withRouter(Main);
