import React, { useEffect, useState } from 'react';
import {
    SearchWrap,
    SelectGroup,
} from '../../../../../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import DatePicker from '@components/DatePicker';
import FilterSearchGroup from '../../../../../../../../MainPages/Components/FilterSearchGroup';
import Button from '@components/Button';
import moment from 'moment';
import SearchGroup from '../../../../../../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import useTranslation from '@hooks/useTranslation';

const DAY_WITH_UNIX = 86400;

const Search = ({ date, onChange }) => {
    const t = useTranslation('RefrigeratorSensor');
    const [today, setToday] = useState(moment().format('YYYY-MM-DD'));
    const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
    useEffect(() => {
        setToday(moment().format('YYYY-MM-DD'));
        setSelectedDate(moment(date * 1000).format('YYYY-MM-DD'));
    }, [date]);

    return (
        <FilterSearchGroup className="p-0 mb-3">
            <SearchGroup label={t('Search')}>
                <SearchWrap className="pt-0">
                    <SelectGroup>
                        <DatePicker
                            value={date}
                            handleChange={selected => onChange(selected)}
                            maxDate={moment.now()}
                            withoutTime
                        />
                    </SelectGroup>
                    <Button
                        className="btn-icon-only btn-gray ml-2"
                        iconName="keyboard_arrow_left"
                        onClick={() => onChange(date - DAY_WITH_UNIX)}
                    />
                    <Button
                        className={'btn-icon-only btn-gray ml-1'}
                        iconName="keyboard_arrow_right"
                        onClick={() => onChange(date + DAY_WITH_UNIX)}
                        disabled={selectedDate === today}
                    />
                </SearchWrap>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default Search;
