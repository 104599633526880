import React, { useState } from 'react';
import MonitoringDatePicker from './MonitoringDatePicker';
import { useAsync, useFilter } from '@hooks';
import { useOutpatientCareStatusContext, useOutpatientCareStatusDispatch } from './index';
import { getOutpatientCareStatusCountApi, getOutpatientCareStatusListApi } from '@api/monitoring';
import { setOutpatientListParam } from './OutpatientCareStatusMonitoringSlice';
import moment from 'moment';
import OutpatientCareItemStatusCount from './Components/OutpatientCareStatusCount';
import OutpatientCareStatusList from './Components/OutpatientCareStatusList';
import useDepartmentOptions from '../util/useDepartmentOptions';

const DEFAULT_OUTPATIENT_CARE_COUNT = {
    total: 0,
    arrivalCountStat: {
        arrival: 0,
        nonArrival: 0,
    },
    waitingCountStat: {
        waitingCare: 0,
        nonWaitingCare: 0,
    },
    doneCountStat: {
        doneCare: 0,
        nonDoneCare: 0,
    },
};

const DEFAULT_OUTPATIENT_CARE_LIST = {
    pageSize: 15,
    totalCount: 0,
    totalPage: 1,
    rows: [],
};

const OutpatientCareItemContainer = () => {
    const filter = useFilter();
    const dispatch = useOutpatientCareStatusDispatch();
    const [startDate, setStartDate] = useState(moment().startOf('day').unix());
    const [endDate, setEndDate] = useState(
        moment().startOf('day').add(23, 'hours').add(59, 'm').add(59, 'second').unix(),
    );
    const departmentValues = useDepartmentOptions();
    const { department } = departmentValues;

    const { selectedOutpatientCareStatus: status, keyword, outpatientListParam } = useOutpatientCareStatusContext();
    const handlePageChange = pageIndex => {
        dispatch(
            setOutpatientListParam(prevState => {
                return { ...prevState, page: pageIndex };
            }),
        );
    };
    const { state: outpatientCareCountState } = useAsync({
        promise: getOutpatientCareStatusCountApi,
        immediate: true,
        param: {
            ...outpatientListParam,
            startDate: startDate,
            endDate: endDate,
            departmentCodes: department.toString(),
        },
        reject: err => {
            console.error(err);
        },
        fixedParam: { ...filter },
        deps: [startDate, endDate, keyword, department],
    });

    const { state: outpatientCareListState } = useAsync({
        promise: getOutpatientCareStatusListApi,
        immediate: true,
        param: {
            ...outpatientListParam,
            startDate: startDate,
            endDate: endDate,
            departmentCodes: department.toString(),
        },
        reject: err => {
            console.error(err);
        },
        fixedParam: { ...filter },
        deps: [startDate, endDate, status, keyword, department],
    });

    const outpatientCareCount = outpatientCareCountState.response ?? DEFAULT_OUTPATIENT_CARE_COUNT;
    const outpatientCareList = outpatientCareListState.response ?? DEFAULT_OUTPATIENT_CARE_LIST;

    const handleDate = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end);
    };

    return (
        <div className={'w-100 h-100'}>
            <OutpatientCareItemStatusCount outpatientCount={outpatientCareCount} />
            <MonitoringDatePicker
                departmentValues={departmentValues}
                handleDate={handleDate}
                startDate={startDate}
                endDate={endDate}
            />
            <OutpatientCareStatusList
                handleOutpatientListParam={handlePageChange}
                outpatientCareList={outpatientCareList}
            />
        </div>
    );
};

export default OutpatientCareItemContainer;
