import React, { useMemo } from 'react';
import { fetchTriggeredAlertStatus } from '@api/statistics';
import useMonitor from '../../util/useMonitor';
import { useTranslation } from '@hooks';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useFilter from '@hooks/useFilter';
import ReactResizeDetector from 'react-resize-detector';
import { Table, TableBody, TableHead, Td, Th, Tr } from '@components/Table/SimpleTable';
import TriggeredAlertStatusChart from './TriggeredAlertStatusChart';
import WidgetCard from '../../Components/WidgetCard';

const SUMMARY_AREA_HEIGHT = '90px';
const TABLE_HEIGHT = '65px';

const TriggeredAlertStatus = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const t = useTranslation('TriggeredAlertStatus');

    const defaultData = { rows: [{ key: 'PNT', name: 'PNT', count: 0 }] };
    const filterParam = useFilter();

    const { data } = useMonitor({
        config,
        defaultData,
        dynamicParam: filterParam,
        fetchData: fetchTriggeredAlertStatus,
        makeData: ({ newData }) => {
            const triggeredAlertList = newData.rows || [];
            const chartData = [
                {
                    key: 'SOS',
                    count: 0,
                },
                {
                    key: 'Restricted',
                    count: 0,
                },
                {
                    key: 'Long Stay',
                    count: 0,
                },
                {
                    key: 'Out Of Range',
                    count: 0,
                },
                {
                    key: 'Lost Signal',
                    count: 0,
                },
                {
                    key: 'Low Battery',
                    count: 0,
                },
            ];

            if (triggeredAlertList) {
                for (let i = 0; i < triggeredAlertList.length; i++) {
                    switch (triggeredAlertList[i].eventType) {
                        case 'SN':
                        case 'SF':
                            chartData[0] = {
                                ...chartData[0],
                                count: chartData[0].count + triggeredAlertList[i].eventCnt,
                            };
                            break;
                        case 'RI':
                        case 'RS':
                        case 'RO':
                            chartData[1] = {
                                ...chartData[1],
                                count: chartData[1].count + triggeredAlertList[i].eventCnt,
                            };
                            break;
                        case 'LN':
                        case 'LF':
                            chartData[2] = {
                                ...chartData[2],
                                count: chartData[2].count + triggeredAlertList[i].eventCnt,
                            };
                            break;
                        case 'SO':
                            chartData[3] = {
                                ...chartData[3],
                                count: chartData[3].count + triggeredAlertList[i].eventCnt,
                            };
                            break;
                        case 'LS':
                            chartData[4] = {
                                ...chartData[4],
                                count: chartData[4].count + triggeredAlertList[i].eventCnt,
                            };
                            break;
                        case 'LB':
                            chartData[5] = {
                                ...chartData[5],
                                count: chartData[5].count + triggeredAlertList[i].eventCnt,
                            };
                            break;
                        default:
                            break;
                    }
                }
            }
            return chartData;
        },
    });

    const translatedData = useMemo(() => {
        return data.map(status => {
            return { ...status, name: t(status.key) };
        });
    }, [t, data]);

    return (
        <WidgetCard widgetInfo={widgetInfo} {...restProps}>
            <div className={'scrollbar-container'}>
                <PerfectScrollbar>
                    <div className={'h-100'}>
                        <ReactResizeDetector handleHeight handleWidth>
                            {({ width, height }) => (
                                <div style={{ width, height: `calc(${height}px - ${SUMMARY_AREA_HEIGHT})` }}>
                                    <TriggeredAlertStatusChart data={translatedData} />
                                </div>
                            )}
                        </ReactResizeDetector>
                        <div style={{ height: SUMMARY_AREA_HEIGHT }}>
                            {t('Today / Count')}
                            <Table style={{ height: TABLE_HEIGHT }} className={'table-for-viewer '}>
                                <TableHead>
                                    <Tr className={'active'}>
                                        {translatedData.map(v => (
                                            <Th key={v.key} className={'justify-content-center'}>
                                                {v.name}
                                            </Th>
                                        ))}
                                    </Tr>
                                </TableHead>
                                <TableBody>
                                    <Tr>
                                        {translatedData.map(v => (
                                            <Td key={v.key} className={'justify-content-center'}>
                                                {v.count || 0}
                                            </Td>
                                        ))}
                                    </Tr>
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </PerfectScrollbar>
            </div>
            {children}
        </WidgetCard>
    );
};

export default TriggeredAlertStatus;
