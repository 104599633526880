export const DEFAULT_VALUE = '-';

export const COLUMN_METAS = [
    {
        key: 'checkDateStrOnlyDate',
        name: '날짜',
    },
    {
        key: 'wardName',
        name: '부서/병동',
    },
    {
        key: 'riskLevelName',
        name: '위험도',
    },
    {
        key: 'categoryName',
        name: '카테고리',
    },
    {
        key: 'targetName',
        name: '자산 이름',
    },
    {
        key: 'targetId',
        name: '자산 ID',
    },
    {
        key: 'checkTypeName',
        name: '점검 구분',
    },
    {
        key: 'checkResultName',
        name: '점검 결과',
    },
    {
        key: 'checkerName',
        name: '점검자 명',
    },
    {
        key: 'checkerId',
        name: '계정',
    },
    {
        key: 'checkDateStr',
        name: '점검 일시',
    },
    {
        key: 'memo',
        name: '메모',
    },
    {
        key: 'imgFileName',
        name: '출력테스트 이미지 파일명',
    },
];

export const ROWS_DUMMY = [
    {
        logNum: 1,
        targetNum: 3,
        comNum: 1,
        categoryCode: 'wheelchair',
        categoryName: '휠체어',
        targetId: 'wheelchair_01',
        targetName: '휠체어',
        checkResult: 'bad',
        riskLevel: 'high',
        ward: '6',
        wardName: '관절센터',
        memo: '메모테스트',
        checkerId: 'pntbiz@pntbiz.com',
        checkerName: '강동경희대병원 관리자',
        checkDate: 1701664660,
        checkDateStr: '2023-12-04 13:37:40',
        checkDateStrOnlyDate: '2023-12-04',
        checkType: 'high',
    },
    {
        logNum: 2,
        targetNum: 4,
        comNum: 1,
        categoryCode: 'wheelchair',
        categoryName: '휠체어',
        targetId: 'wheelchair_01',
        targetName: '휠체어',
        checkResult: 'good',
        riskLevel: 'middle',
        ward: '6',
        wardName: '관절센터',
        memo: '메모테스트',
        checkerId: 'pntbiz@pntbiz.com',
        checkerName: '강동경희대병원 관리자',
        checkDate: 1701664660,
        checkDateStr: '2023-12-04 13:37:40',
        checkDateStrOnlyDate: '2023-12-04',
        checkType: 'normal',
    },
];

export const ROW_INFO_DUMMY = {
    targetNum: 6,
    comNum: 1,
    ward: 3,
    checkItem: [
        {
            checkItemNum: 1,
            checkItemName: '외관상 이상이 없는지?',
            checked: 'Y',
            checkDate: 1,
            checkDateStr: '2023-12-01 09:00:01',
        },
        {
            checkItemNum: 2,
            checkItemName: '조명이 들어오는지?',
            checked: 'N',
            checkDate: 1,
            checkDateStr: '2023-12-01 09:00:01',
        },
    ],
};

export const FIXED_PARAMS = {
    pageSize: 200,
    page: 1,
};

export const DEFAULT_RESPONSE = {
    ...FIXED_PARAMS,
    rows: [],
    totalCount: 0,
    totalPage: 0,
};
