import { createSlice } from '@reduxjs/toolkit';

export const FILTER_LIST_PAGE_SIZE = 20;
const initFilterListInfo = { totalPage: 1, page: 1, rows: [], pageSize: FILTER_LIST_PAGE_SIZE };

export const initialState = {
    filterListInfo: initFilterListInfo,
    refreshFlag: null,
};

const { actions, reducer } = createSlice({
    name: 'filterList',
    initialState,
    reducers: {
        setFilterListInfo: (state, action) => {
            const filterListInfo = action.payload;
            state.filterListInfo = { ...initFilterListInfo, ...filterListInfo };
        },
        refreshList: (state, action) => {
            state.refreshFlag = {};
        },
    },
});

export const { setFilterListInfo, refreshList } = actions;
export default reducer;
