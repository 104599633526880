import React, { useState } from 'react';
import { useSettings } from '../../../../util/useSettings';
import ItemCount from './ItemCount';
import InnerItemCard from '@components/Card/InnerItemCard';

const DEFAULT_IoT_COUNT = {
    totalCount: 0,
    restrictedCount: 0,
    lostSignalCount: 0,
    lowBatteryCount: 0,
    neverDetectedCount: 0,
};

const IotItemStatusCount = ({ widgetInfo, iotItemCount }) => {
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { categoryCode: parentCategory } = settings;

    const [countList, setCountList] = useState(iotItemCount);

    if (iotItemCount !== countList) {
        setCountList(iotItemCount);
    }

    return (
        <InnerItemCard>
            <div className="widget-item-nums">
                {Object.keys(iotItemCount).map((key, idx) => (
                    <ItemCount
                        key={`${key}_${idx}`}
                        label={key}
                        start={DEFAULT_IoT_COUNT[key]}
                        end={countList[key]}
                        parentCategory={parentCategory}
                    />
                ))}
            </div>
        </InnerItemCard>
    );
};

export default IotItemStatusCount;
