import React, { Fragment, useCallback, useMemo } from 'react';
import moment from 'moment';
import { useVitalSignsRealTimePartronDispatch } from '../index';
import { setIsDetail, setTargetId, setWardNum } from '../vitalSignsRealTimePartronSlice';
import styled, { css } from 'styled-components';
import useTranslation from '@hooks/useTranslation';

const CARD_AREA = {
    SENSOR_WIDTH: 22,
    PATIENT_INFO_WIDTH: 63,
    DATE_WIDTH: 20,
    PATIENT_NAME_WIDTH: 104,
    BED_WIDTH: 48,
    ICON_WIDTH: 48,
};

const CHARGING_BATTERY = 255;
const FULL_BATTERY = 100;
const DEFAULT_VALUE = '-';

/**
 * 환자 활력징후 정보 card
 *
 * @param contents 환자 정보
 * @param wardNum 병동/부서 정보
 *
 * @since 2022-12-20
 * @author kunwoong-kim
 */
const VitalSignCard = ({ contents, wardNum }) => {
    const t = useTranslation('VitalSignsRealTimePartron');
    const dispatch = useVitalSignsRealTimePartronDispatch();

    const handleViewDetail = targetId => {
        dispatch(setWardNum(wardNum)); // 병동 정보
        dispatch(setTargetId(targetId)); // 대상 고유번호
        dispatch(setIsDetail(true)); // 상세화면 여부
    };

    const checkSensorValue = useCallback(sensorValue => !!sensorValue, []);

    const contentsEntries = Object.entries(contents);
    const flattenData = useMemo(() => {
        return [].concat(
            ...contentsEntries.map(([_, patientList]) => Object.values(patientList).map(patient => ({ ...patient }))),
        );
    }, [contentsEntries]);

    return (
        <Fragment>
            {!!Object.entries(contents).length && (
                <Container>
                    <RowContainer className="bg-lightgray fw-bold">
                        {/* Sensor Info */}
                        <ColumnContainer $width={CARD_AREA.SENSOR_WIDTH}>
                            <CellContainer $width={CARD_AREA.ICON_WIDTH}>{t('Wear')}</CellContainer>
                            <CellContainer $width={CARD_AREA.ICON_WIDTH}>{t('SOS')}</CellContainer>
                            <CellContainer>{t('Battery')}</CellContainer>
                        </ColumnContainer>
                        {/* Patient Info */}
                        <ColumnContainer $width={CARD_AREA.PATIENT_INFO_WIDTH} className="border-white">
                            <CellContainer $width={CARD_AREA.BED_WIDTH}>{t('Bed')}</CellContainer>
                            <CellContainer $width={CARD_AREA.PATIENT_NAME_WIDTH}>{t('Patient Info')}</CellContainer>
                            <CellContainer className="flex-column">
                                {t('Heartbeat')}
                                <span className="opacity-8">(bpm)</span>
                            </CellContainer>
                            <CellContainer className="flex-column">
                                {t('Calories')}
                                <span className="opacity-8">(Kcal)</span>
                            </CellContainer>
                            <CellContainer className="flex-column">
                                {t('Step')}
                                <span className="opacity-8">({t('step')})</span>
                            </CellContainer>
                            <CellContainer className="flex-column">
                                {t('Distance')}
                                <span className="opacity-8">(km)</span>
                            </CellContainer>
                        </ColumnContainer>
                        <ColumnContainer $width={CARD_AREA.DATE_WIDTH} className="border-white">
                            <CellContainer>{t('Received Time')}</CellContainer>
                        </ColumnContainer>
                    </RowContainer>
                    {flattenData.map((data, idx) => {
                        const {
                            targetId,
                            targetName,
                            infoValue,
                            regDate,
                            properties: { bed },
                        } = data;
                        const handOff = infoValue ? infoValue.handOff : null;
                        const sos = infoValue ? infoValue.sos : null;
                        const battery = (batteryValue => {
                            if (!batteryValue) {
                                return DEFAULT_VALUE;
                            }
                            if (batteryValue > 100 && batteryValue < CHARGING_BATTERY) {
                                return `${Math.floor(FULL_BATTERY)}%`;
                            }
                            if (batteryValue === CHARGING_BATTERY) {
                                return t('Charging');
                            }
                            return `${Math.floor(batteryValue)}%`;
                        })(checkSensorValue(data.BATTERY) ? data.BATTERY : null);
                        const fullFormatRegDate = regDate
                            ? moment(regDate * 1000).format('YYYY-MM-DD HH:mm:ss')
                            : DEFAULT_VALUE;

                        return (
                            <RowContainer
                                key={`${bed}_${idx}`}
                                className="transition-card cursor-pointer"
                                onClick={() => handleViewDetail(targetId)}
                            >
                                <ColumnContainer $width={CARD_AREA.SENSOR_WIDTH}>
                                    <CellContainer $width={CARD_AREA.ICON_WIDTH}>
                                        <WearIconContainer className={handOff ? 'bg-success' : 'bg-lightgray'}>
                                            {handOff ? 'ON' : 'OFF'}
                                        </WearIconContainer>
                                    </CellContainer>
                                    <CellContainer $width={CARD_AREA.ICON_WIDTH}>
                                        <span
                                            className={`icon-warning icon-big ${sos ? 'icon-filter-red' : 'opacity-3'}`}
                                        ></span>
                                    </CellContainer>
                                    <CellContainer>{battery}</CellContainer>
                                </ColumnContainer>
                                <ColumnContainer $width={CARD_AREA.PATIENT_INFO_WIDTH}>
                                    <CellContainer $width={CARD_AREA.BED_WIDTH}>{bed}</CellContainer>
                                    <CellContainer $width={CARD_AREA.PATIENT_NAME_WIDTH}>
                                        <PatientContainer>
                                            <div className="fw-bold pnt-txt s-8">{targetId}</div>
                                            <PatientNameContainer className="opacity-8">
                                                {targetName}
                                            </PatientNameContainer>
                                        </PatientContainer>
                                    </CellContainer>
                                    <CellContainer className="fw-bold">
                                        {checkSensorValue(data.HEARTBEAT) ? Math.floor(data.HEARTBEAT) : DEFAULT_VALUE}
                                    </CellContainer>
                                    <CellContainer className="fw-bold">
                                        {checkSensorValue(data.CALORIES) ? Math.floor(data.CALORIES) : DEFAULT_VALUE}
                                    </CellContainer>
                                    <CellContainer className="fw-bold">
                                        {checkSensorValue(data.STEP) ? Math.floor(data.STEP) : DEFAULT_VALUE}
                                    </CellContainer>
                                    <CellContainer className="fw-bold">
                                        {checkSensorValue(data.DISTANCE)
                                            ? Number(data.DISTANCE).toFixed(2)
                                            : DEFAULT_VALUE}
                                    </CellContainer>
                                </ColumnContainer>
                                <ColumnContainer $width={CARD_AREA.DATE_WIDTH}>
                                    <CellContainer>{fullFormatRegDate}</CellContainer>
                                </ColumnContainer>
                            </RowContainer>
                        );
                    })}
                </Container>
            )}
        </Fragment>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const RowContainer = styled.div`
    display: flex;
    width: 100%;
    border: 1px solid lightgray;
    :not(&:first-child) {
        border-top: none;
    }
    &:first-child {
        border-radius: 2px 2px 0 0;
    }
    &:last-child {
        border-radius: 0 0 2px 2px;
    }
`;

const ColumnContainer = styled.div`
    ${({ $width }) => ($width ? `width: ${$width}` : '')}%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    border-left: 1px solid lightgray;
    &:first-child {
        border-left: none;
    }
`;

const CellContainer = styled.span`
    ${({ $width }) =>
        $width &&
        css`
            width: ${$width}px;
            min-width: ${$width}px;
            max-width: ${$width}px;
        `};
    flex: 1 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-size: 0.8rem;
    padding: 0.25rem 0.5rem !important;
    //border-left: 1px solid lightgray;
    &:first-child {
        border-left: none;
    }
`;

const PatientContainer = styled.div`
    text-align: center;
    overflow: hidden;
`;

const PatientNameContainer = styled.p`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
`;

const WearIconContainer = styled.div`
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.5rem;
    font-weight: bold;
    color: white !important;
    padding: 0.25rem;
    border-radius: 100%;
`;

export default VitalSignCard;
