import React from 'react';
import styled from 'styled-components';
import { useConfirmModal, useTranslation } from '@hooks';
import SAMPLE_IMG from '@asset/images/kiosk.png';
import cx from 'classnames';
import { SCREEN_MODE_MONITORING } from '@reducer/ScreenInfo';
import { useHistory } from 'react-router-dom';
import { POWER_ON, WARNING_STATUS } from '../constants';
import useAppSelector from '@hooks/useAppSelector';

const CategoryCard = ({ data, settings, color }) => {
    const t = useTranslation('OutpatientCareDashboard');
    const { department, deptCount, careCount, waitingCount, powerStatus, status } = data;
    const { mode } = useAppSelector(state => state.ScreenInfo);
    const { dashboardURL } = settings || {};

    const history = useHistory();

    const handleTopClick = () => {
        const { topTileLink } = dashboardURL || {};
        if (mode !== SCREEN_MODE_MONITORING) {
            toggleMoveConfirmModal();
        } else if (topTileLink) {
            history.push(topTileLink);
        } else {
            toggleNotExistScreenModal();
        }
    };
    const handleBottomClick = () => {
        const { bottomTileLink } = dashboardURL || {};
        if (mode !== SCREEN_MODE_MONITORING) {
            toggleMoveConfirmModal();
        } else if (bottomTileLink) {
            history.push(bottomTileLink);
        } else {
            toggleNotExistScreenModal();
        }
    };

    const { Modal: NotExistScreenModal, toggleModal: toggleNotExistScreenModal } = useConfirmModal({
        confirmText: t('Linked screen is not set. Please set it in screen editing.'),
        removeCancel: true,
    });

    const { Modal: MoveConfirmModal, toggleModal: toggleMoveConfirmModal } = useConfirmModal({
        confirmText: t('You cannot move the menu in edit mode.'),
        removeCancel: true,
    });

    return (
        <>
            <div className="inner-item">
                <BodyContainer className={`item-body p-0 py-3`} backgroundColor={color}>
                    <div className="cursor-pointer" onClick={handleTopClick}>
                        <div className="d-flex justify-content-between align-items-center px-3 mb-3">
                            <span className="pnt-txt txt-bold s-4">{t('Outpatient')}</span>
                            <span className="pnt-txt s-4">{department}</span>
                            <div className="bg-white px-3 rounded-pill">
                                <span className="pnt-txt txt-bold s-6">{deptCount}</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center px-3">
                            <div className={'px-2'}>
                                <img src={SAMPLE_IMG} alt="Sample Images" width={64} />
                            </div>
                            <div className="d-flex flx-1 ml-1">
                                <div className={'w-50'}>
                                    <CareStatusContainer className="pnt-txt txt-bold text-center s-7 py-1 mb-2">
                                        {t('Completion of care')}
                                    </CareStatusContainer>
                                    <CareStatusContainer className="pnt-txt txt-bold text-center s-7 py-1">
                                        {careCount}
                                    </CareStatusContainer>
                                </div>
                                <div className={'w-50'}>
                                    <CareStatusContainer className="pnt-txt txt-bold text-center s-7 px-2 py-1 mb-2">
                                        {t('Wait after arrival')}
                                    </CareStatusContainer>
                                    <CareStatusContainer className="pnt-txt txt-bold text-center s-7 py-1">
                                        {waitingCount}
                                    </CareStatusContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="d-flex justify-content-between align-items-center bg-white py-1 px-3 cursor-pointer"
                        onClick={handleBottomClick}
                    >
                        <div
                            className={cx(
                                'color-white rounded-pill w-30 text-center py-1',
                                powerStatus === POWER_ON ? 'bg-primary' : 'bg-danger',
                            )}
                        >
                            {powerStatus}
                        </div>
                        <div
                            className={cx(
                                'text-center',
                                'font-size-lg',
                                'txt-bold',
                                WARNING_STATUS.includes(status) && 'color-danger',
                            )}
                        >
                            {t(status)}
                        </div>
                    </div>
                </BodyContainer>
            </div>
            <NotExistScreenModal />
            <MoveConfirmModal />
        </>
    );
};

const CareStatusContainer = styled.div`
    background-color: rgba(255, 255, 255, 0.7);
`;

const BodyContainer = styled.div`
    background-color: ${props => props.backgroundColor};
`;

export default React.memo(CategoryCard);
