import React from 'react';
import styled from 'styled-components';
import EquipmentTotalCounts from './Counts/EquipmentTotalCounts';
import EquipmentDetailCounts from './Counts/EquipmentDetailCounts';

const StatusCount = ({ outpatientCount }) => {
    const { total, equipmentCountStat } = outpatientCount;
    return (
        <Container>
            <EquipmentTotalCounts total={total} />
            <EquipmentDetailCounts equipmentCountStat={equipmentCountStat} />
        </Container>
    );
};
const Container = styled.div`
    display: flex;
    width: 100%;
    & > div {
        &:nth-child(1) {
            flex: 1;
        }
        flex: 2;
    }
    margin-bottom: 5px;
`;

export default StatusCount;
