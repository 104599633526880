import React, { useEffect, useState } from 'react';
import MonitoringDatePicker from './MonitoringDatePicker';
import { useAsync } from '@hooks';
import { useOutpatientBloodDrawStatusContext, useOutpatientBloodDrawStatusDispatch } from './index';
import { getOutpatientBloodDrawStatusCountApi, getOutpatientBloodDrawStatusListApi } from '@api/monitoring';
import { clearOutpatientCare } from './OutpatientBloodDrawStatusSlice';
import moment from 'moment';
import OutpatientBloodDrawStatusCount from './components/OutpatientBloodDrawStatusCount';
import OutpatientBloodDrawStatusList from './components/OutpatientBloodDrawStatusList';
import useDepartmentOptions from '../util/useDepartmentOptions';

const DEFAULT_OUTPATIENT_BLOOD_COUNT = {
    total: 0,
    nonProcessingStat: {
        waitingInspect: 0,
        nonArrival: 0,
    },
    inspectCountStat: {
        doneInspect: 0,
        nonDoneInspect: 0,
    },
};
const DEFAULT_OUTPATIENT_BLOOD_LIST = {
    pageSize: 15,
    totalCount: 0,
    totalPage: 1,
    rows: [],
};
const OutpatientBloodDrawItemContainer = ({ widgetInfo }) => {
    const { config } = widgetInfo;
    const dispatch = useOutpatientBloodDrawStatusDispatch();
    const [startDate, setStartDate] = useState(moment().startOf('day').unix());
    const [endDate, setEndDate] = useState(
        moment().startOf('day').add(23, 'hours').add(59, 'm').add(59, 'second').unix(),
    );

    const {
        selectedOutpatientCareStatus: status,
        keyword,
        outpatientBloodListParam,
    } = useOutpatientBloodDrawStatusContext();

    const { state: outpatientBloodCount } = useAsync({
        promise: getOutpatientBloodDrawStatusCountApi,
        immediate: true,
        param: {
            ...outpatientBloodListParam,
            startDate: startDate,
            endDate: endDate,
        },
        reject: err => {
            console.error(err);
        },
        deps: [startDate, endDate, keyword],
    });

    const { state: outpatientBloodState } = useAsync({
        promise: getOutpatientBloodDrawStatusListApi,
        immediate: true,
        param: {
            ...outpatientBloodListParam,
            startDate: startDate,
            endDate: endDate,
        },
        reject: err => {
            console.error(err);
        },
        deps: [startDate, endDate, keyword, status],
    });

    const handleDate = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end);
    };

    const bloodDrawCount = outpatientBloodCount.response ?? DEFAULT_OUTPATIENT_BLOOD_COUNT;
    const outpatientBloodList = outpatientBloodState.response ?? DEFAULT_OUTPATIENT_BLOOD_LIST;

    useEffect(() => {
        return () => {
            dispatch(clearOutpatientCare());
        };
    }, []);

    return (
        <div className={'w-100 h-100'}>
            <OutpatientBloodDrawStatusCount bloodDrawCount={bloodDrawCount} />
            <MonitoringDatePicker handleDate={handleDate} startDate={startDate} endDate={endDate} />
            <OutpatientBloodDrawStatusList config={config} outpatientBloodList={outpatientBloodList} />
        </div>
    );
};

export default OutpatientBloodDrawItemContainer;
