import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
    selectedOutpatientStatus: null,
    keyword: '',
    outpatientList: {
        totalCount: 0,
        totalPage: 1,
        rows: [],
    },
    outpatientListParam: {
        pageSize: 15,
        page: 1,
        appointmentOption: null,
        keyword: undefined,
    },
    outpatientCount: {
        total: 0,
        todayReceptionCountStat: {
            todayReception: 0,
        },
        receptionCountStat: {
            receptionSuccess: 0,
            receptionFail: 0,
        },
    },
};

const outpatientUnattendedReceptionSlice = createSlice({
    name: 'outpatientCareStatus',
    initialState,
    reducers: {
        setSelectedOutpatientStatus: (state, action) => {
            const selectedStatus = action.payload;
            if (state.selectedOutpatientStatus === selectedStatus) {
                return;
            }
            state.selectedOutpatientStatus = selectedStatus;
            state.outpatientListParam = {
                ...state.outpatientListParam,
                appointmentOption: state.selectedOutpatientStatus,
            };
        },
        setSearchOutpatient: (state, action) => {
            const keyword = action.payload ? action.payload : undefined;
            state.keyword = keyword;
            state.outpatientListParam = {
                ...state.outpatientListParam,
                keyword: state.keyword,
            };
        },
        setOutpatientListParam: (state, action) => {
            state.outpatientListParam = action.payload;
        },
        clearOutpatientCare: (state, action) => {
            state.keyword = '';
            state.selectedOutpatientStatus = null;
            state.outpatientListParam = {
                ...state.outpatientListParam,
                keyword: undefined,
                appointmentOption: null,
            };
        },
    },
});
export const {
    setSearchOutpatient,
    setSelectedOutpatientStatus,
    setOutpatientListParam,
    clearOutpatientCare,
} = outpatientUnattendedReceptionSlice.actions;

export default outpatientUnattendedReceptionSlice;
