import React, { useEffect, useState } from 'react';
import NoDataBlock from '../../../Components/NoDataBlock';
import BarChart from '@components/Charts/Components/BarChart';

const CategoryBarChart = ({ congestedGeofenceInfo }) => {
    const [chartData, setChartData] = useState({});

    useEffect(() => {
        setChartData({
            labels: congestedGeofenceInfo.map(geofenceInfo => geofenceInfo.fcName),
            datasets: congestedGeofenceInfo.reduce((acc, geofenceData) => {
                geofenceData.categoryInfo.forEach(category => {
                    const existData = acc.find(data => data.label === category.categoryName);
                    if (existData) {
                        existData.data.push(category.categoryCount);
                    } else {
                        acc.push({
                            label: category.categoryName,
                            data: [category.categoryCount],
                        });
                    }
                });
                return acc;
            }, []),
        });
    }, [congestedGeofenceInfo]);

    return congestedGeofenceInfo.length ? <BarChart type={'stackedBar'} data={chartData} /> : <NoDataBlock />;
};

export default CategoryBarChart;
