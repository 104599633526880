import { useRouteMatch, match as Match } from 'react-router-dom';
import {
    A_PATH_CUSTOM_SCREEN,
    A_PATH_EMBED_CUSTOM_SCREEN,
    A_PATH_SCREEN_EDIT,
} from '../../Components/MainPages/Components/Router/path';

interface ScreenKeyInfo {
    menuCode?: string;
    screenCode?: string;
}

type ScreenMatch = Match<ScreenKeyInfo> | {};

// 커스텀 화면의 useRouteMatch 결과값을 리턴
const useScreenMatch = (): ScreenMatch => {
    return useRouteMatch([A_PATH_CUSTOM_SCREEN, A_PATH_SCREEN_EDIT, A_PATH_EMBED_CUSTOM_SCREEN]) ?? {};
};

export default useScreenMatch;
