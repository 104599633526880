import React from 'react';
import styled from 'styled-components';
import Table from '@components/Table';
import useTranslation from '@hooks/useTranslation';
import { LoadingBlock } from '@components';
import { DEFAULT_DISPLAY_VALUE } from '../../constants';

const DEFAULT_WIDTH = 250;
const DEFAULT_COLUMN = {
    Header: null,
    accessor: null,
    className: 'text-ellipsis',
    headerClassName: 'text-ellipsis',
    width: DEFAULT_WIDTH,
};

const SummaryTable = ({ vitalSensor, loading }) => {
    const t = useTranslation('VitalSignsRealTimePartron');

    const heartbeat = vitalSensor?.HEARTBEAT ? Math.floor(vitalSensor.HEARTBEAT) : DEFAULT_DISPLAY_VALUE;

    const data = [
        {
            heartbeat,
        },
    ];

    const columns = [
        {
            ...DEFAULT_COLUMN,
            Header: `${t('Heartbeat')} (bpm)`,
            accessor: 'heartbeat',
        },
    ];

    return (
        <Container>
            <LoadingBlock blocking={loading}>
                <Table className="table-for-viewer" data={{ rows: data }} columns={columns} paging={false} />
            </LoadingBlock>
        </Container>
    );
};

const Container = styled.div`
    height: 4.4rem;
    margin-bottom: 2rem;
`;

export default SummaryTable;
