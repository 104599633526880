import React, { ComponentType } from 'react';
import { components, ControlProps, MenuListProps, ValueContainerProps } from 'react-select';
import { OptionProps } from 'react-select/dist/declarations/src/components/Option';
import cx from 'classnames';

import { OptionType } from '@components/type';
import { ICON_CLASS } from '@components/Select';
import { Control } from '@components/Select/Components/Common';
import { getChildrenTypeNode } from '@components/Select/utils';
import { MenuListInput } from '@components/Select/SearchableSelect/Parts';

export const SelectControl: ComponentType<ControlProps<OptionType>> = ({
    children,
    selectProps,
    ...rest
}: ControlProps<OptionType>) => {
    const { iconName } = selectProps;
    const { targetChildren, otherChildren } = getChildrenTypeNode(children, 'IndicatorsContainer');

    return (
        <Control selectProps={selectProps} {...rest}>
            <button className={'select__btn'}>
                <div className="cont-wrap">
                    {iconName && <span className={ICON_CLASS}>{iconName}</span>}
                    {otherChildren}
                </div>
                {targetChildren}
            </button>
        </Control>
    );
};

export const SelectOption = ({ children, ...rest }: OptionProps<OptionType>) => {
    const {
        selectProps: { className },
    } = rest;
    const mapClass = className?.includes('map-select-item');
    return (
        <components.Option className={cx(mapClass && 'map-select-item')} {...rest}>
            {children}
        </components.Option>
    );
};

export const SelectMenuList = ({ children, ...rest }: MenuListProps<OptionType>) => {
    const { selectProps } = rest;
    const { showSearchInput, onMenuInputChange, onMenuInputFocusToggle } = selectProps;

    return (
        <div>
            {showSearchInput && !!onMenuInputChange && !!onMenuInputFocusToggle && (
                <MenuListInput onMenuInputChange={onMenuInputChange} onMenuInputFocusToggle={onMenuInputFocusToggle} />
            )}
            <components.MenuList {...rest}>{children}</components.MenuList>
        </div>
    );
};

export const SelectValueContainer = ({ children, ...rest }: ValueContainerProps<OptionType>) => {
    const { selectProps } = rest;
    const { inputValue } = selectProps;

    return (
        <components.ValueContainer {...rest}>
            <div className="d-flex">{inputValue || children}</div>
        </components.ValueContainer>
    );
};
