import React, { createContext, useContext } from 'react';
import ContextProvider from '@components/ContextProvider';
import AnalysisHeatmapContainer from './AnalysisHeatmapContainer';
import heatMapSlice from './heatMapSlice';

export const HeatMapStateContext = createContext(null);
export const HeatMapDispatchContext = createContext(null);

export const useHeatmapContext = () => useContext(HeatMapStateContext);

export const useHeatmapDispatch = () => useContext(HeatMapDispatchContext);

const AnalysisHeatMap = ({ widgetInfo, children, ...restProps }) => {
    return (
        <ContextProvider
            StateContext={HeatMapStateContext}
            DispatchContext={HeatMapDispatchContext}
            slice={heatMapSlice}
        >
            <AnalysisHeatmapContainer widgetInfo={widgetInfo} children={children} {...restProps} />
        </ContextProvider>
    );
};

export default AnalysisHeatMap;
