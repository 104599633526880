import React, { useContext, useEffect, useMemo } from 'react';
import { getVitalSignsMonitoringListApi } from '@api/monitoring';
import VitalSignsRoomList from './VitalSignsRoomList';
import { LoadingBlock } from '@components';
import { useAppSelector, useFilter, useAsync } from '@hooks';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_NUMERIC_SENSING } from '@reducer/SocketInfo';
import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';
import { VitalSignsMonitoringDispatchContext, VitalSignsMonitoringForHiCardiPlusStateContext } from '../index';
import { getSingleValueAsc } from '../util';
import NoDataBlock from '../../../Components/NoDataBlock';
import { storeVitalSignList, updateVitalSignList } from '../vitalSignsMonitoringForHiCardiPlusReducer';
import { DUMMY_LIST_DATA, VITAL_SIGN_FIXED_PARAM } from '../constants';

/**
 * 병실/병상, 환자 상태 리스트 컴포넌트
 **/
const VitalSignsList = () => {
    const { vitalSignsList, monitoringTargetNums } = useContext(VitalSignsMonitoringForHiCardiPlusStateContext);
    const dispatch = useContext(VitalSignsMonitoringDispatchContext);
    const { mode } = useAppSelector(state => state.ScreenInfo);
    const wardMapInfo = useAppSelector(state => state.DepartmentsInfo.wardMapInfo);

    const filterParams = useFilter({ postParam: { categoryCodes: 'PATIENT' } });

    // 활력징후 List 조회
    const {
        state: { isLoading },
        promise: getVitalSignsMonitoringList,
    } = useAsync({
        promise: getVitalSignsMonitoringListApi,
        fixedParam: VITAL_SIGN_FIXED_PARAM,
        param: filterParams,
        resolve: res => {
            dispatch(storeVitalSignList(res));
        },
        reject: () => {
            dispatch(storeVitalSignList({}));
        },
    });

    const socketFilterConfig = useMemo(() => {
        return monitoringTargetNums.length
            ? { target: { targetNum: { $in: monitoringTargetNums } } }
            : { target: { targetNum: 'noneTarget' } };
    }, [monitoringTargetNums]);

    // socket 요청
    useSocketEvent({
        name: EVENT_TYPE_NUMERIC_SENSING,
        filterConfig: socketFilterConfig,
        handler: data => {
            dispatch(updateVitalSignList(data));
        },
        enableBuffer: true,
    });

    useEffect(() => {
        if (mode === SCREEN_MODE_EDIT || mode === SCREEN_MODE_PREVIEW) {
            dispatch(storeVitalSignList(DUMMY_LIST_DATA));
            return;
        }
        getVitalSignsMonitoringList();
    }, [mode, filterParams]);

    // 활력징후 데이터 없는 경우
    if (vitalSignsList && !Object.entries(vitalSignsList).length) {
        return <NoDataBlock />;
    }
    // 렌더링을 위한 Entry
    const vitalSignsEntries = getSingleValueAsc(Object.entries(vitalSignsList));

    return (
        <LoadingBlock blocking={isLoading}>
            {vitalSignsEntries.map(([wardNum, rooms]) => (
                <VitalSignsRoomList
                    key={wardNum}
                    wardNum={wardMapInfo[wardNum]}
                    roomList={rooms}
                    handleRefreshData={getVitalSignsMonitoringList}
                />
            ))}
        </LoadingBlock>
    );
};

export default VitalSignsList;
