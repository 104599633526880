import { reqDelete, reqGet, reqPut } from './index';

// 모니터링 - 실시간 위치현황
export const fetchRealTimeLocationStatus = param => reqGet('/v3/api/common/realtime/location/list', param);

// 실시간 센서 모니터링
export const fetchRealTimeSensorMonitoring = param => reqGet('/v3/api/common/sensor', param);
// 센싱 타입 조회
export const fetchSensingTypes = param => reqGet('/v3/api/common/sensor/sensingTypes', param);
// 센싱 타입 조회
export const fetchSensingTypeMetas = param => reqGet('/v3/api/common/sensor/sensing-type-metas', param);

//// 수액 모니터링
// 수액 리스트 조회
export const getIVInjectionMonitoringListApi = param => reqGet('/v3/api/sh01/ivinjection', param);
// 수액 상세 조회
export const getIVInjectionMonitoringApi = param => reqGet(`/v3/api/sh01/ivinjection/${param.IVInjectionNum}`, param);
// 수액 정보 수정
export const updateIVInjectionMonitoringApi = param =>
    reqPut(`/v3/api/sh01/ivinjection/${param.IVInjectionNum}`, param);
// 수액 센서 언맵핑
export const updateUnmapIVInjectionMonitoringApi = param => reqPut(`/v3/api/sh01/ivinjection`, param);

// 냉장고 센서
export const getRefrigeratorSensorListApi = param => reqGet('/v3/api/sh01/fridge', param);
export const getRefrigeratorSensorDailyLogApi = param => reqGet('/v3/api/sh01/fridge/dailylog', param);
export const updateRefrigeratorSensorDailyLogApi = param => reqPut('/v3/api/sh01/fridge/dailylog', param);
export const deleteRefrigeratorSensorDailyLogApi = param => reqDelete(`/v3/api/sh01/fridge/dailylog/${param.logNum}`);
export const getRefrigeratorSensorMonthlyLogApi = param => reqGet('/v3/api/sh01/fridge/monthlylog', param);
export const getRefrigeratorSensorLogListApi = param => reqGet('/v3/api/common/log/sensor', param);
export const getRefrigeratorSensorDetailApi = param => reqGet(`/v3/api/sh01/fridge/${param.sensorNum}`, param);
export const getRefrigeratorSensorDateApi = param => reqGet(`/v3/api/sh01/fridge/period`, param);
export const putRefrigeratorSensorDetailApi = param => reqPut(`/v3/api/common/sensor/${param.sensorNum}`, param);

// 활력징후 모니터링
// export const getVitalSignsRealTimeListApi = param => reqGet('/v3/api/sh01/vitality/by-ward/vitally', param);
export const getVitalSignsMonitoringListApi = param => reqGet('/v3/api/sh01/vitality/by-ward/temp-bp', param);
export const getVitalSignsDetailInfoApi = param =>
    reqGet(`/v3/api/sh01/vitality/by-ward/vitally/targetInfo/${param.targetId}`, param);
export const getVitalSignsDetailGraphApi = param => reqGet(`/v3/api/sh01/vitality/by-ward/vitally/graph`, param);
export const getVitalSignsWorkTimeApi = param => reqGet(`/v3/api/sh01/vitality/by-ward/worktime`, param);
// 활력징후 partron
export const getVitalSignsListOfPatronApi = param => reqGet('/v3/api/sh01/vitality/by-ward/patron', param);
export const getVitalSignsDetailOfPatronApi = param =>
    reqGet(`/v3/api/sh01/vitality/by-ward/patron/${param.targetId}`, param);
export const getVitalSignsDetailGraphOfPatronApi = param => reqGet(`/v3/api/sh01/vitality/by-ward/patron/graph`, param);

// 환경센서 모니터링
export const getEnvironmentalSensorListApi = param => reqGet('/v3/api/sh01/eco/by-room', param);
export const getEnvironmentalSensorDetailInfoApi = ({ targetId, ...param }) =>
    reqGet(`/v3/api/sh01/eco/by-room/targetInfo/${targetId}`, param);
export const getEnvironmentalSensorDetailGraphApi = param => reqGet(`/v3/api/sh01/eco/by-room/graph`, param);

//스마트 외래
export const getOutpatientDashboardApi = param => reqGet(`/v3/api/so01/dashboard/status`, param);
export const getOutpatientCareStatusCountApi = param => reqGet(`/v3/api/so01/care/status/count`, param);
export const getOutpatientCareStatusListApi = param => reqGet(`/v3/api/so01/care/status`, param);
export const getSelectedOutpatientCareStatusListApi = param =>
    reqGet(`/v3/api/so01/care/status/${param.careNum}`, param);
export const getOutpatientBloodDrawStatusCountApi = param => reqGet(`/v3/api/so01/out-blood/status/count`, param);
export const getOutpatientBloodDrawStatusListApi = param => reqGet(`/v3/api/so01/out-blood/status`, param);
export const getSelectedOutpatientBloodDrawApi = param =>
    reqGet(`/v3/api/so01/out-blood/status/${param.bloodTestNum}`, param);
export const getOutpatientBaselineTestStatusCountApi = param =>
    reqGet(`/v3/api/so01/foundation-exam/status/count`, param);
export const getOutpatientBaselineTestStatusListApi = param => reqGet(`/v3/api/so01/foundation-exam/status`, param);
export const getUnattendedReceivingStatusCountApi = param =>
    reqGet(`/v3/api/so01/unattended-storage/status/count`, param);
export const getUnattendedReceivingStatusListApi = param => reqGet(`/v3/api/so01/unattended-storage/status`, param);
export const getOutpatientProofIssuanceStatusCountApi = param =>
    reqGet(`/v3/api/so01/proof-publication/status/count`, param);
export const getOutpatientProofIssuanceStatusListApi = param => reqGet(`/v3/api/so01/proof-publication/status`, param);
export const getOutpatientUnattendedReceptionStatusCountApi = param =>
    reqGet(
        `/v3/api/so01/unattended-reception/status/count
`,
        param,
    );
export const getOutpatientUnattendedReceptionStatusListApi = param =>
    reqGet(
        `/v3/api/so01/unattended-reception/status/
`,
        param,
    );

export const getOutpatientEquipmentCountApi = param => reqGet(`/v3/api/so01/kiosk/status/count`, param);
export const getOutpatientEquipmentListApi = param => reqGet(`/v3/api/so01/kiosk/status`, param);
