import { defaultActionCreator } from '@util/common/util';

const SET_STEP = 'SET_STEP';
const INIT_FIND_STEP = 'INIT_FIND_STEP';
const SET_NEXT_STEP = 'SET_NEXT_STEP';
const SET_PREV_STEP = 'SET_PREV_STEP';
const SET_CURR_EMAIL = 'SET_CURR_EMAIL';
const SET_SHOW_ERROR_MSG = 'SET_SHOW_ERROR_MSG';

export const setStep = param => defaultActionCreator(SET_STEP, param);
export const initFindStep = param => defaultActionCreator(INIT_FIND_STEP, param);
export const setNextStep = param => defaultActionCreator(SET_NEXT_STEP, param);
export const setPrevStep = param => defaultActionCreator(SET_PREV_STEP, param);
export const setCurrEmail = param => defaultActionCreator(SET_CURR_EMAIL, param);
export const setShowErrorMsg = param => defaultActionCreator(SET_SHOW_ERROR_MSG, param);

export const LOGIN_STEP = ['LOGIN', 'FIND', 'CHANGE', 'COMPLETE'];

export const initialState = {
    step: LOGIN_STEP[0],
    stepIndex: 0,
    email: '',
    showErrorMsg: false,
};

export const reducer = (state, action) => {
    switch (action.type) {
        case SET_STEP:
            const stepInfo = action.payload;
            return { ...state, ...stepInfo };
        case INIT_FIND_STEP:
            return { ...initialState, step: LOGIN_STEP[1], stepIndex: 1 };
        case SET_NEXT_STEP:
            let nextStepIndex = ++state.stepIndex;
            if (nextStepIndex >= LOGIN_STEP.length) {
                nextStepIndex = 0;
            }
            return {
                ...state,
                stepIndex: nextStepIndex,
                step: LOGIN_STEP[nextStepIndex],
                ...action.payload,
            };
        case SET_PREV_STEP:
            const prevStepIndex = Math.max(--state.stepIndex, 0);
            return {
                ...state,
                stepIndex: prevStepIndex,
                step: LOGIN_STEP[prevStepIndex],
            };
        case SET_CURR_EMAIL:
            return {
                ...state,
                email: action.payload,
            };
        case SET_SHOW_ERROR_MSG:
            return {
                ...state,
                showErrorMsg: action.payload,
            };
        default:
            throw new Error(`Unknown action type: ${action.type}`);
    }
};
