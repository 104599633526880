import React from 'react';
import { Button, Modal } from '@components';
import { ModalFooter } from 'reactstrap';
import { useTranslation } from '@hooks';
import { ConfirmModalProps } from '@components/type';
import cx from 'classnames';
import styled from 'styled-components';

const SubTitle = styled.p`
    line-height: 1.7;
    font-size: 1rem;
    font-weight: 400;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.6);
    margin: 0;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    overflow: hidden;
`;

const ConfirmModal = ({
    initModal = false,
    toggleModal = function () {},
    okCallback,
    okValidation = true,
    cancelCallback,
    callbackParam,
    header = { title: '', subTitle: '', className: '' },
    confirmText = 'Are you sure?',
    modalFooter,
    footerClassName,
    removeCancel,
    okClassName,
    className,
    container,
    ...restProps
}: ConfirmModalProps) => {
    const t = useTranslation('ConfirmModal');
    return (
        <Modal
            container={container}
            className={className}
            initModal={initModal}
            toggleModal={toggleModal}
            headerClassName={header?.className}
            headerTitle={
                <>
                    {header?.title || t('Confirm')} {!!header?.subTitle && <SubTitle>{header.subTitle}</SubTitle>}
                </>
            }
            bodyText={typeof confirmText === 'string' ? t(confirmText) : confirmText}
            okCallback={okCallback}
            okValidation={okValidation}
            cancelCallback={cancelCallback}
            callbackParam={callbackParam}
            footerClassName={footerClassName}
            modalFooter={
                modalFooter || (
                    <ModalFooter className={footerClassName}>
                        {!removeCancel && (
                            <Button
                                className={'btn-gray'}
                                onClick={() => {
                                    if (typeof cancelCallback === 'function') {
                                        cancelCallback(callbackParam);
                                    }
                                    toggleModal();
                                }}
                            >
                                {t('Cancel')}
                            </Button>
                        )}
                        <Button
                            className={cx('btn-secondary', okClassName)}
                            onClick={() => {
                                if (typeof okCallback === 'function') {
                                    okCallback(callbackParam);
                                }
                                if (okValidation) {
                                    toggleModal();
                                }
                            }}
                        >
                            {t('OK')}
                        </Button>
                    </ModalFooter>
                )
            }
            {...restProps}
        />
    );
};

export default ConfirmModal;
