import React from 'react';
import SensorReport from './Components/SensorReport/SensorReport';
import { useSensorStatusContext } from '../../index';
import SensorDetails from './Components/SensorDetails';

const SensorDetailInfo = () => {
    const { reportType } = useSensorStatusContext();

    return (
        <div className={'gap-2 h-100 flx-col'}>
            <SensorReport />
            {reportType === 'daily' && <SensorDetails />}
        </div>
    );
};

export default SensorDetailInfo;
