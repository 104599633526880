import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { Button, Modal, Table } from '@components';
import { advancedGenerateFakeData } from '@util/common/util';
import { useAsync, useTranslation } from '@hooks';
import WidgetCard from '../../Components/WidgetCard';
import { MEDICAL_WASTE } from '../../constants';
import useAdminCheck from '../util/useAdminCheck';
import { formatDateByColumn } from '../../util/format';
import useModal from '../../util/useModal';
import RegisterModal from './RegisterModal';

const getListApi = advancedGenerateFakeData({
    rows: [
        {
            fileNum: 'aa',
            billingAmount: 10,
            regDate: 1698120573,
            registrant: '최둘리',
            note: `이설명서는 기존 의료폐기물 설명서에 위험물질 관리법이 추가됨
                이설명서는 기존 의료폐기물 설명서에 위험물질 관리법이 추가됨
                이설명서는 기존 의료폐기물 설명서에 위험물질 관리법이 추가됨`,
        },
        {
            fileNum: 'bb',
            billingAmount: 20,
            regDate: 1698120173,
            registrant: '손현수',
            note: null,
        },
        {
            fileNum: 'cc',
            billingAmount: 30,
            regDate: 1698110573,
            registrant: '최둘리',
            note: null,
        },
        {
            fileNum: 'dd',
            billingAmount: 40,
            regDate: 1697120573,
            registrant: '김을삼',
            note: null,
        },
    ],
});

const deleteApi = advancedGenerateFakeData({
    result: 'success',
});

const BillingAmountManagement = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();
    const t = useTranslation(MEDICAL_WASTE);
    const isAdmin = useAdminCheck();
    const [isOpen, handleToggle, handleOpen] = useModal();
    const [isDeleteOpen, handleDeleteToggle, handleDeleteOpen] = useModal();

    const { state, promise: getList } = useAsync({
        promise: getListApi,
        immediate: true,
        reject: err => console.error(err),
    });
    const rows = state.response ? state.response.rows : [];

    const [selectedFileNumList, setSelectedFileNumList] = useState([]);
    const { promise: deleteList } = useAsync({
        promise: deleteApi,
        resolve: () => {
            getList();
            handleDeleteToggle();
        },
        reject: err => console.error(err),
    });

    const handleDeleteClick = () => {
        if (selectedFileNumList.length <= 0) {
            alert('청구량이 선택되지 않았습니다.');
            handleDeleteToggle();
            return;
        }
        deleteList({
            fileNumList: selectedFileNumList,
        });
    };

    const handleCheckChange = useCallback(
        (e, row) => {
            const isChecked = e.target.checked; // 체크되지 않았었을 때 true
            const selectedFileNum = row.original.fileNum;

            if (isChecked) {
                setSelectedFileNumList([...selectedFileNumList, selectedFileNum]);
                return;
            }
            const filteredList = selectedFileNumList.filter(fileNum => fileNum !== selectedFileNum);
            setSelectedFileNumList(filteredList);
        },
        [selectedFileNumList],
    );

    const columns = useMemo(
        () => [
            {
                Header: t('Select'),
                Cell: ({ row }) => (
                    <input
                        type="checkbox"
                        className="m-0"
                        checked={selectedFileNumList.includes(row.original.fileNum)}
                        onChange={e => handleCheckChange(e, row)}
                    />
                ),
                width: 20,
            },
            {
                Header: t('Billing amount(Isolated medical)'),
                accessor: 'billingAmount',
            },
            {
                Header: t('Register Date'),
                Cell: ({ row }) => <div>{formatDateByColumn(row, 'regDate')}</div>,
            },
            {
                Header: t('Registrant'),
                accessor: 'registrant',
                width: 40,
            },
            {
                Header: t('Note'),
                Cell: ({ row }) => (
                    <>
                        <div className="text-truncate" id={'prefix' + row.original.fileNum.toString()}>
                            {row.original.note}
                        </div>
                        <UncontrolledTooltip target={'prefix' + row.original.fileNum.toString()} placement="bottom">
                            {row.original.note}
                        </UncontrolledTooltip>
                    </>
                ),
            },
        ],
        [handleCheckChange, selectedFileNumList, t],
    );

    return (
        <WidgetCard ref={widgetRef} widgetInfo={widgetInfo} {...restProps}>
            {isAdmin ? (
                <>
                    <div className="d-flex justify-content-end gap-2">
                        <Button className="btn-lightgray" onClick={handleOpen}>
                            {t('Register')}
                        </Button>
                        <Button className="btn-lightgray" onClick={handleDeleteOpen}>
                            {t('Delete')}
                        </Button>
                    </div>
                    <TableWrapper className="mt-3">
                        <Table data={{ rows }} columns={columns} paging={false} />
                    </TableWrapper>
                    <RegisterModal isOpen={isOpen} toggleModal={handleToggle} />
                    <Modal
                        initModal={isDeleteOpen}
                        toggleModal={handleDeleteToggle}
                        headerTitle={t('Delete')}
                        modalFooter={
                            <ModalFooter>
                                <Button className={'btn-gray'} onClick={handleDeleteToggle}>
                                    {t('Cancel', 'Button')}
                                </Button>
                                <Button className={'btn-secondary'} onClick={handleDeleteClick}>
                                    {t('Ok', 'Button')}
                                </Button>
                            </ModalFooter>
                        }
                    >
                        <div className="modal-body">
                            {t('The billing amount data will be deleted. Are you sure you want to delete?')}
                        </div>
                    </Modal>
                </>
            ) : (
                <div className={'h-100 flex-center'}>
                    <span className="pnt-txt s-3">{t('This screen is accessible only to administrators.')}</span>
                </div>
            )}
            {children}
        </WidgetCard>
    );
};

const TableWrapper = styled.div`
    height: 45rem;
`;

export default BillingAmountManagement;
