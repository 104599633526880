import { reqDelete, reqGet, reqPatch, reqPost } from '../index';

//북마크 여부
export const fetchBookmarkList = param => reqGet('/v3/api/common/target/bookmark', { ...param, isAll: 'Y' });

//북마크 등록
export const createBookmarkApi = param => reqPost(`/v3/api/common/target/bookmark`, param);
//북마크 삭제
export const deleteBookmarkApi = param => reqDelete(`/v3/api/common/target/bookmark/${param.targetNum}`);

export const getUserInfoApi = param => reqGet('/v3/api/common/user/info/list', param);

// 개인설정 업데이트
export const updateUserSettingApi = param => reqPatch('/v3/api/common/user/personal/config', param);

// 개인설정 조회
export const getUserSettingApi = param => reqGet('/v3/api/common/user/personal/config', param);

// 통합 서비스 정보 조회
export const getIntegrationServiceInfo = param => reqGet('/v3/api/common/integration/service', param);
