import React, { useRef } from 'react';

import useTranslation from '@hooks/useTranslation';
import Button from '@components/Button';

import { setIsDetail } from './vitalSignsMonitoringSlice';
import FilterCheckbox from './Components/FilterCheckbox';
import ViewDetail from './Components/ViewDetail';
import VitalSignsCardList from './Components/VitalSignsList';
import WidgetCard from '../../Components/WidgetCard';
import { useVitalSignsMonitoringContext, useVitalSignsMonitoringDispatch } from './index';

const VitalSignsMonitoringContainer = ({ widgetInfo, children, ...restProps }) => {
    const t = useTranslation();
    const { config } = widgetInfo;
    const widgetRef = useRef();
    const state = useVitalSignsMonitoringContext();
    const dispatch = useVitalSignsMonitoringDispatch();

    return (
        <WidgetCard
            ref={widgetRef}
            widgetInfo={widgetInfo}
            headerAction={
                state.isDetail ? (
                    <Button className="btn-lightgray" onClick={() => dispatch(setIsDetail(false))}>
                        {t('Back', 'Button')}
                    </Button>
                ) : (
                    <FilterCheckbox />
                )
            }
            {...restProps}
        >
            {state.isDetail ? <ViewDetail targetNum={state.targetNum} /> : <VitalSignsCardList config={config} />}
            {children}
        </WidgetCard>
    );
};

export default VitalSignsMonitoringContainer;
