import { useState } from 'react';
import { useAppSelector } from '@hooks';

const useDepartmentOptions = () => {
    const { departmentList } = useAppSelector(state => state.DepartmentsInfo);
    const departmentOptions = departmentList
        .map(({ departmentName, departmentCode }) => ({
            value: departmentCode,
            label: departmentName,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const [department, setDepartment] = useState(departmentOptions.map(v => v.value));

    const handleDepartmentChange = selected => {
        setDepartment(selected.map(v => v.value));
    };
    return {
        departmentOptions,
        department,
        handleDepartmentChange,
    };
};

export default useDepartmentOptions;
