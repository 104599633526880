export const category = ({ ...restProps } = {}) => {
    return {
        Header: 'Category',
        headerClassName: 'text-ellipsis',
        accessor: 'category',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const ward = ({ ...restProps } = {}) => {
    return {
        Header: 'Ward',
        headerClassName: 'text-ellipsis',
        accessor: 'department',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const speciality = ({ ...restProps } = {}) => {
    return {
        Header: 'Speciality',
        headerClassName: 'text-ellipsis',
        accessor: 'specialties',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const doctor = ({ ...restProps } = {}) => {
    return {
        Header: 'Doctor',
        headerClassName: 'text-ellipsis',
        accessor: 'doctor',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const id = ({ ...restProps } = {}) => {
    return {
        Header: 'Patient ID',
        headerClassName: 'text-ellipsis',
        accessor: 'patientRegNum',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const patientName = ({ ...restProps } = {}) => {
    return {
        Header: 'Patient Name',
        headerClassName: 'text-ellipsis',
        accessor: 'patientName',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const reservedDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Reserved Date',
        headerClassName: 'text-ellipsis',
        accessor: 'appointmentDate',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const reservedTime = ({ ...restProps } = {}) => {
    return {
        Header: 'Reserved Time',
        headerClassName: 'text-ellipsis',
        accessor: 'appointmentTime',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const arrivedTime = ({ ...restProps } = {}) => {
    return {
        Header: 'Arrived Time',
        headerClassName: 'text-ellipsis',
        accessor: 'arrivalTime',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const basicTest = ({ ...restProps } = {}) => {
    return {
        Header: 'Basic Inspection',
        headerClassName: 'text-ellipsis',
        accessor: 'isFoundationExam',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const waitingCare = ({ ...restProps } = {}) => {
    return {
        Header: 'Waiting for an appointment',
        headerClassName: 'text-ellipsis',
        accessor: 'isWaiting',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const care = ({ ...restProps } = {}) => {
    return {
        Header: 'Care Status',
        headerClassName: 'text-ellipsis',
        accessor: 'isCare',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};

export const patientInfoName = ({ ...restProps } = {}) => {
    return {
        Header: '',
        headerClassName: 'text-ellipsis',
        accessor: 'name',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const todayPatientInfo = ({ ...restProps } = {}) => {
    return {
        Header: 'Today',
        headerClassName: 'text-ellipsis',
        accessor: 'today',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const previousPatientInfo = ({ ...restProps } = {}) => {
    return {
        Header: 'Previous',
        headerClassName: 'text-ellipsis',
        accessor: 'before',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
