import React, { useEffect, useRef } from 'react';
import WidgetCard from '../../Components/WidgetCard';
import { useAppSelector, useAsync } from '@hooks';
import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';
import TIMES from '@util/times';
import { LoadingBlock } from '@components';
import useTranslation from '@hooks/useTranslation';
import MedicalWasteCollectionStatusList from './Components/MedicalWasteCollectionStatusList';
import MedicalWasteCollectionStatusSummary from './Components/MedicalWasteCollectionStatusSummary';
import useAdminCheck from '../util/useAdminCheck';
import { MEDICAL_WASTE } from '../../constants';
import { getCollectionStatusListApi } from '@api/sh/waste';

const MedicalWasteCollectionStatus = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation(MEDICAL_WASTE);
    const { mode } = useAppSelector(state => state.ScreenInfo);
    const editMode = mode === SCREEN_MODE_EDIT || mode === SCREEN_MODE_PREVIEW;
    const isAdmin = useAdminCheck();

    const widgetRef = useRef();

    const { state: medicalWasteCollectionState, promise: getMedicalWasteCollection } = useAsync({
        promise: getCollectionStatusListApi,
        immediate: true,
        reject: err => console.error(err),
    });

    const summaryData = medicalWasteCollectionState.response?.rows.reduce(
        (acc, item) => {
            const { wasteType, collectables, collectCompleted } = item;

            acc.collectibleQuantity[wasteType] = (acc.collectibleQuantity[wasteType] || 0) + collectables;
            acc.quantityCollected[wasteType] = (acc.quantityCollected[wasteType] || 0) + collectCompleted;

            acc.collectibleQuantity.total = (acc.collectibleQuantity.total || 0) + collectables;
            acc.quantityCollected.total = (acc.quantityCollected.total || 0) + collectCompleted;

            return acc;
        },
        {
            collectibleQuantity: {},
            quantityCollected: {},
        },
    );

    useEffect(() => {
        let intervalId = -1;
        if (editMode) {
            return;
        }

        intervalId = setInterval(() => {
            getMedicalWasteCollection();
        }, TIMES.MIN_1 * 3);

        return () => {
            if (intervalId !== -1) {
                clearInterval(intervalId);
            }
        };
    }, []);

    const { isLoading, response } = medicalWasteCollectionState;
    const data = response ? response.rows : [];

    return (
        <WidgetCard ref={widgetRef} widgetInfo={widgetInfo} {...restProps}>
            {/* {isAdmin ? ( */}
            <LoadingBlock blocking={isLoading}>
                <MedicalWasteCollectionStatusSummary
                    className={'mb-3'}
                    title={t('Collectible quantity')}
                    data={summaryData?.collectibleQuantity}
                />
                <MedicalWasteCollectionStatusSummary
                    title={t('Quantity collected')}
                    data={summaryData?.quantityCollected}
                />
                <MedicalWasteCollectionStatusList rows={data} />
            </LoadingBlock>
            {/* ) : (
                <div className={'h-100 flex-center'}>
                    <span className="pnt-txt s-3">{t('This screen is accessible only to administrators.')}</span>
                </div>
            )} */}
            {children}
        </WidgetCard>
    );
};

export default MedicalWasteCollectionStatus;
