import { reqDelete, reqGet, reqPatch, reqPost } from '../index';
import { YN, NullableString, Nullable, UnixTimestamp, ServiceCode } from '@util/type/util';
import { RequestHandlerCreator, PageInfoType, PageInfoParam } from '@api/util';
import { GroupInfo } from '@api/common/authGroup';
import { ScreenInfo } from '@api/common/screen';

export interface DetailGroupInfo extends GroupInfo {
    modUserNum: number;
    regUserNum: number;
    authUserList: Nullable<any>;
    authUserNumList: Nullable<number[]>;
}

export interface MenuInfo {
    menuCode: string;
    menuName: string;
    icon: string;
    order: number;
    description: NullableString;
    serviceCode: ServiceCode;
    modDate: UnixTimestamp;
    regDate: UnixTimestamp;
    screenList: Nullable<ScreenInfo<DetailGroupInfo>[]>;
}

interface MenuListParam extends PageInfoParam {
    includeScreenInfo?: YN;
    groupNum?: number;
}

interface MenuParam {
    menuCode: string;
}

interface CreateMenuParam extends Omit<MenuInfo, 'description' | 'modeDate' | 'regDate' | 'screenList'> {
    description?: NullableString;
}

interface UpdateMenuParam {
    menuCode: string;
    menuName?: string;
    icon?: string;
    order?: number;
    description?: string;
}

interface DeleteMenuParam {
    menuCode: string;
}

// 메뉴
export const fetchMenuList: RequestHandlerCreator<PageInfoType<MenuInfo>, MenuListParam> = param =>
    reqGet('/v3/api/common/menu', param);
export const fetchMenu: RequestHandlerCreator<Omit<MenuInfo, 'screenList'>, MenuParam> = param =>
    reqGet(`/v3/api/common/menu/${param.menuCode}`, param);
export const createMenuApi: RequestHandlerCreator<{}, CreateMenuParam> = param => reqPost('/v3/api/common/menu', param);
export const updateMenuApi: RequestHandlerCreator<{}, UpdateMenuParam> = param =>
    reqPatch(`/v3/api/common/menu/${param.menuCode}`, param);
export const deleteMenuApi: RequestHandlerCreator<{}, DeleteMenuParam> = param =>
    reqDelete(`/v3/api/common/menu/${param.menuCode}`);
