import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Table } from '@components';
import { useTranslation } from '@hooks';
import { MEDICAL_WASTE } from '../../../constants';

const CountTable = ({ selectedTab, rows }) => {
    const t = useTranslation(MEDICAL_WASTE);

    const columns = useMemo(
        () => [
            {
                Header: t(selectedTab.charAt(0).toUpperCase() + selectedTab.slice(1)),
                accessor: 'date',
            },
            {
                Header: t('Ward'),
                accessor: 'wardName',
            },
            {
                Header: t('Isolated patient'),
                accessor: 'isolatedPatientCount',
            },
            {
                Header: t('Normal patient'),
                accessor: 'normalPatientCount',
            },
            {
                Header: t('Waste count'),
                accessor: 'wasteCount',
            },
        ],
        [selectedTab, t],
    );

    return (
        <TableWrapper>
            <Table data={{ rows }} columns={columns} paging={false} />
        </TableWrapper>
    );
};

const TableWrapper = styled.div`
    height: 20rem;
`;

export default CountTable;
