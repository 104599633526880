import { createContext, Dispatch, useContext } from 'react';
import { ActionType } from '@components/ContextProvider';
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    registerModal: false,
    managementMode: false,
    refreshFlag: false,
    manualCategoryList: [],
};

export const AssetManualManagementStateContext = createContext(initialState);
export const AssetManualManagementDispatchContext = createContext<Dispatch<ActionType> | null>(null);

export const useAssetManualManagementStateContext = () => useContext(AssetManualManagementStateContext);
export const useAssetManualManagementDispatchContext = () => useContext(AssetManualManagementDispatchContext);

const assetManualManagementSlice = createSlice({
    name: 'am/assetManualManagement',
    initialState,
    reducers: {
        toggleRegisterModal: state => {
            state.registerModal = !state.registerModal;
        },
        setManagementMode: (state, action) => {
            state.managementMode = action.payload;
        },
        refreshManualList: state => {
            state.refreshFlag = !state.refreshFlag;
        },
        setManualCategoryList: (state, action) => {
            state.manualCategoryList = action.payload || [];
        },
    },
});

export const {
    toggleRegisterModal,
    setManagementMode,
    refreshManualList,
    setManualCategoryList,
} = assetManualManagementSlice.actions;

export default assetManualManagementSlice;
