import React, { useMemo, useRef, useState } from 'react';
import { getDashboardStatusCountApi } from '@api/statistics';
import CategoryCard from './Components/CategoryCard';
import styled from 'styled-components';
import { useSettings } from '../../util/useSettings';
import useFilter from '@hooks/useFilter';
import useMonitor from '../../util/useMonitor';
import WidgetCard from '../../Components/WidgetCard';

const CategoryCardContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(370px, auto));
    grid-gap: 10px;
    grid-auto-rows: 400px;
`;

const defaultData = [
    {
        data: {
            inj: {
                all: 12,
                stop: 0,
                full: 0,
            },
        },
    },
    {
        data: {
            scale: {
                all: 0,
            },
        },
    },
    {
        data: {
            PATIENT: {
                all: 19,
                isLostSignal: 14,
                isUnPermitted: 0,
                isLowBattery: 5,
            },
        },
    },
    {
        data: {
            ASSET: {
                all: 0,
                isLostSignal: 0,
                isUnPermitted: 0,
                isLowBattery: 0,
            },
        },
    },
    {
        data: {
            refrigerator: {
                all: 0,
                CRITICAL: 0,
                OUTOFRANGE: 0,
            },
        },
    },
];

const MONITORING_PARAM_CATEGORY_LIST = ['PATIENT', 'scale', 'PATIENT', 'wheelchair', 'refrigerator'];
const MONITORING_CATEGORY_LIST = ['inj', 'scale', 'PATIENT', 'ASSET', 'refrigerator'];

const initialData = MONITORING_CATEGORY_LIST.map(categoryCode => ({ categoryCode, status: {} }));

const Dashboard = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();
    const { config } = widgetInfo;
    const settings = useSettings(config);

    const [data, setData] = useState(initialData);
    const filterParam = useFilter();

    const multiParam = useMemo(() => {
        return MONITORING_PARAM_CATEGORY_LIST.map(category => {
            const newParam = { ...filterParam };
            if (newParam.categoryCodes) {
                newParam.categoryCodes = category;
            }
            if (newParam['targetPropertiesSearch.categoryCode']) {
                newParam['targetPropertiesSearch.categoryCode'] = category;
            }
            return newParam;
        });
    }, [filterParam]);

    useMonitor({
        config,
        defaultData,
        dynamicParam: multiParam,
        // 속성 검색은 단일 카테고리에만 적용되기 때문에 카테고리별 조회 API를 따로 호출
        fetchData: [
            getDashboardStatusCountApi,
            getDashboardStatusCountApi,
            getDashboardStatusCountApi,
            getDashboardStatusCountApi,
            getDashboardStatusCountApi,
        ],
        makeData: ({ newData }) => {
            setData(
                MONITORING_CATEGORY_LIST.map((categoryCode, i) => {
                    return { categoryCode, status: newData[i]?.data?.[categoryCode] ?? {} };
                }),
            );
        },
    });

    return (
        <WidgetCard ref={widgetRef} widgetInfo={widgetInfo} {...restProps}>
            <CategoryCardContainer>
                {data.map((dashboard, index) => {
                    const { categoryCode, status } = dashboard;
                    return (
                        <CategoryCard
                            key={categoryCode + index}
                            categoryCode={categoryCode}
                            status={status}
                            index={index}
                            settings={settings}
                        />
                    );
                })}
            </CategoryCardContainer>
            {children}
        </WidgetCard>
    );
};

export default Dashboard;
