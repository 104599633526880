import React, { ChangeEvent, useContext, useState } from 'react';
import { WidgetProps } from '../../staticInfo';
import WidgetSettingModal from '../../Components/WidgetSettingModal';
import { Label } from '@components';
import useTranslation from '@hooks/useTranslation';
import Checkbox from '@components/Input/Checkbox';
import { useSettings } from '../../util/useSettings';
import { ScreenEditDispatchContext } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit';
import { editWidgetSettings } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';

type SettingOption = { name?: string; viewAllLists?: boolean };

const ExamPatientsMonitoringSetting = ({ widgetInfo, ...restProps }: WidgetProps) => {
    const dispatch = useContext(ScreenEditDispatchContext);
    const t = useTranslation('ExamPatientsMonitoring');
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { settings: customSettings } = config;
    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState<SettingOption>({});

    const handleSubmitClick = (data: SettingOption) => {
        const checkedSettings = customSettings
            ? { ...customSettings, ...data, ...updatedWidgetInfo }
            : { ...data, ...updatedWidgetInfo };
        const updatedWidgetSettings = {
            id: widgetInfo.id,
            settings: checkedSettings,
        };

        dispatch(editWidgetSettings(updatedWidgetSettings));
    };
    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };
    const handleViewAllListsChange = (e: ChangeEvent<HTMLInputElement>) => {
        const viewAllLists = e.currentTarget.checked;
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, viewAllLists };
        });
    };

    return (
        <WidgetSettingModal
            widgetInfo={widgetInfo}
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            socketWidget={false}
            headerTitle={null}
            intervalTimeOptions={null}
            {...restProps}
        >
            <Label
                name={t('View all lists')}
                value={
                    <Checkbox
                        checked={updatedWidgetInfo.viewAllLists ?? settings.viewAllLists}
                        handleChecked={handleViewAllListsChange}
                    />
                }
            />
        </WidgetSettingModal>
    );
};

export default ExamPatientsMonitoringSetting;
