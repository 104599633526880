import React from 'react';
import useTranslation from '@hooks/useTranslation';
import ItemCount, { VIEW_MODE } from './ItemCount';
import InnerItemCard from '@components/Card/InnerItemCard';

const DEFAULT_DATA = [
    { key: 'mappedSensorCount', label: 'Number of sensors mapped', mode: VIEW_MODE },
    { key: 'mappedTargetCount', label: 'Number of IoT items mapped', mode: VIEW_MODE },
];

/**
 * 전체 IoT 아이템 & 센서 수 카운트 컴포넌트
 *
 * @param targetCountData 전체 IoT 아이템 & 센서수
 *
 * @author jinbeom-jung
 */
const SensorAndIotCount = ({ targetCountData }) => {
    const t = useTranslation('SensorStatus');
    return (
        <InnerItemCard header={{ title: t('Total number of IoT items & sensors') }}>
            <div className="widget-item-nums" style={{ height: 124 }}>
                {DEFAULT_DATA.map(({ key, label, mode }) => {
                    return (
                        <ItemCount
                            key={key}
                            start={targetCountData.prev[key]}
                            end={targetCountData.curr[key]}
                            label={label}
                            mode={mode}
                        />
                    );
                })}
            </div>
        </InnerItemCard>
    );
};

export default React.memo(SensorAndIotCount);
