// 가시성 관련 키
const getVisibilityKeys = () => {
    let hidden = '';
    let visibilityChange = '';
    if (typeof document.hidden !== 'undefined') {
        // Opera 12.10 and Firefox 18 and later support
        hidden = 'hidden';
        visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden';
        visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden';
        visibilityChange = 'webkitvisibilitychange';
    }
    return { hidden, visibilityChange };
};

// hidden(브라우저 탭 활성화 여부), visibilityChange(브라우저 탭 활성화 변경 이벤트헨들러 명)
export const { hidden, visibilityChange } = getVisibilityKeys();
