import React from 'react';
import styled from 'styled-components';
import Table from '@components/Table';
import useTranslation from '@hooks/useTranslation';

const DEFAULT_VALUE = '-';
const DEFAULT_WIDTH = 250;
const DEFAULT_COLUMN = {
    Header: null,
    accessor: null,
    className: 'text-ellipsis',
    headerClassName: 'text-ellipsis',
    width: DEFAULT_WIDTH,
};

const SummaryTable = ({ vitalSensor }) => {
    const t = useTranslation('VitalSignsRealTimePartron');

    const heartbeat = vitalSensor?.HEARTBEAT ? Math.floor(vitalSensor.HEARTBEAT) : DEFAULT_VALUE;
    const calories = vitalSensor?.CALORIES ? Math.floor(vitalSensor.CALORIES) : DEFAULT_VALUE;
    const step = vitalSensor?.STEP ? Math.floor(vitalSensor.STEP) : DEFAULT_VALUE;
    const distance = vitalSensor?.DISTANCE ? Number(vitalSensor.DISTANCE).toFixed(2) : DEFAULT_VALUE;

    const data = [
        {
            heartbeat,
            calories,
            step,
            distance,
        },
    ];

    const columns = [
        {
            ...DEFAULT_COLUMN,
            Header: `${t('Heartbeat')} (bpm)`,
            accessor: 'heartbeat',
        },
        {
            ...DEFAULT_COLUMN,
            Header: `${t('Calories')} (Kcal)`,
            accessor: 'calories',
        },
        {
            ...DEFAULT_COLUMN,
            Header: `${t('Step')} (${t('step')})`,
            accessor: 'step',
        },
        {
            ...DEFAULT_COLUMN,
            Header: `${t('Distance')} (km)`,
            accessor: 'distance',
        },
    ];

    return (
        <Container minHeight={Object.values(...data).every(el => el === '-')}>
            <Table
                data={{ rows: Object.values(...data).every(el => el === '-') ? [] : data }}
                columns={columns}
                paging={false}
            />
        </Container>
    );
};

const Container = styled.div`
    height: 4.6rem;
    ${props => props.minHeight && 'min-height: 12rem;'}
    margin-bottom: 2rem;
`;

export default SummaryTable;
