import { Chart } from 'chart.js';
import { AnyObject } from 'chart.js/types/basic';

type Axis = 'x' | 'y';

type Part = {
    axis: Axis;
    arrange: [start: number, end: number];
    color: string;
};

export type PartialBackgroundColorOptions = Part[];

const partialBackgroundColorPlugin = {
    id: 'partialBackgroundColor',
    beforeDraw(
        chart: Chart,
        args: { cancelable: true },
        options: AnyObject & PartialBackgroundColorOptions,
    ): boolean | void {
        if (options.length) {
            const { ctx, chartArea } = chart;
            const dataSetIndex = 0;
            const meta = chart.getDatasetMeta(dataSetIndex);
            ctx.save();
            options.forEach(option => {
                const { axis, arrange, color } = option;
                const indexScale = axis === 'y' ? meta.yScale : meta.xScale;
                // min, max 가 없을 경우 그리지 않음
                const min = indexScale?.min ?? Infinity;
                const max = indexScale?.max ?? -Infinity;
                // 최대 최소 벗어나면 그리지 않음
                if (min > arrange[1] || max < arrange[0]) {
                    return;
                }
                const start = indexScale?.getPixelForValue(Math.max(arrange[0], min));
                let stop = indexScale?.getPixelForValue(Math.min(arrange[1], max));
                if (typeof stop !== 'number' || isNaN(stop)) {
                    stop = axis === 'y' ? chartArea.top : chartArea.right;
                }
                if (typeof start === 'number') {
                    ctx.fillStyle = option.color || 'transparent';
                    if (axis === 'y') {
                        ctx.fillRect(chartArea.left, start, chartArea.width, stop - start);
                    } else {
                        ctx.fillRect(start, chartArea.top, stop - start, chartArea.height);
                    }
                }
            });
            ctx.restore();
        }
    },
};

export default partialBackgroundColorPlugin;
