import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import Button from '@components/Button';
import { ASSET_MANAGEMENT, DUTY_LIST } from '../../constants';
import useModal from '../../util/useModal';
import Modal from '@components/Modal';
import useAsync from '@hooks/useAsync';
import { setDailyInspectionApi } from '@api/am/management';
import { SCREEN_MODE_MONITORING } from '@reducer/ScreenInfo';
import { useAppSelector } from '@hooks';
import TimeSelect from '../Components/TimeSelect';
import moment from 'moment';
import { DATE_FORMAT } from '@util/times';
import { Trans } from 'react-i18next';
import useWorkTime from '../util/useWorkTime';

const NormalRiskInspection = ({ data, handleRefresh, getChangedRows, targetDate, isToday, children }) => {
    const t = useTranslation(ASSET_MANAGEMENT);
    const { mode } = useAppSelector(state => state.ScreenInfo);
    const workTime = useWorkTime();
    const [isSubmitOpen, handleSubmitToggle, handleSubmitOpen] = useModal();
    const [isDateSettingOpen, handleDateSettingToggle, handleDateSettingOpen] = useModal();

    const formattedDate = moment.unix(targetDate).format(DATE_FORMAT.YMD);
    const checkDateRef = useRef(null);

    const [textareaValue, setTextareaValue] = useState();
    const handleTextareaValue = e => {
        const value = e.target?.value;
        setTextareaValue(value);
    };

    const { promise: setDailyInspection } = useAsync({
        promise: setDailyInspectionApi,
        resolve: () => {
            handleRefresh();
            setTextareaValue(null);
        },
    });

    const handleSubmitCallback = () => {
        const changedRows = getChangedRows();

        Object.keys(changedRows).forEach(key => {
            // 오늘이 아니면서 점검 시간이 있는 경우
            if (!isToday && checkDateRef.current) {
                changedRows[key].checkDate = checkDateRef.current;
                // 점검표 생성시간 이후에 추가된 자산인 경우
                if (!changedRows[key].dailyCheckNum) {
                    const selectedCheckDate = moment.unix(checkDateRef.current);
                    changedRows[key].regDate = moment
                        .unix(workTime.workTimeSet[DUTY_LIST.NIGHT].startDate)
                        .year(selectedCheckDate.year())
                        .month(selectedCheckDate.month())
                        .date(selectedCheckDate.date())
                        .unix();
                }
            } else {
                const now = moment().unix();
                changedRows[key].checkDate = now;
                // 점검표 생성시간 이후에 추가된 자산인 경우
                if (!changedRows[key].dailyCheckNum) {
                    changedRows[key].regDate = moment.unix(workTime.workTimeSet[DUTY_LIST.NIGHT].startDate).unix();
                }
            }
        });

        if (data[0]?.memo !== textareaValue) {
            const assetDailyCheck = data.map(({ dailyCheckNum, targetNum, ward, riskLevel, categoryCode }) => {
                return {
                    dailyCheckNum,
                    targetNum,
                    ward,
                    riskLevel,
                    categoryCode,
                    ...changedRows[targetNum],
                    memo: textareaValue,
                };
            });
            setDailyInspection({ assetDailyCheck });
            return;
        }

        const assetDailyCheck = Object.values(changedRows);
        if (assetDailyCheck.length === 0) {
            return;
        }
        setDailyInspection({ assetDailyCheck });
    };

    const handleChangeCheckerDate = unixTime => {
        checkDateRef.current = unixTime;
    };

    const onClick = () => {
        if (isToday) {
            return handleSubmitOpen();
        }
        if (Object.keys(getChangedRows()).length <= 0) {
            return handleSubmitOpen();
        }
        return handleDateSettingOpen();
    };

    useEffect(() => {
        setTextareaValue(data[0]?.memo);
    }, [data]);

    return (
        <>
            {children}
            <div className="d-flex mt-3">
                <TextContainer
                    className="flx-1 border border-depth-7 bg-depth-3 p-2"
                    rows={3}
                    maxLength={1000}
                    placeholder={t('Notes (up to 1,000 characters)')}
                    disabled={data.length === 0}
                    value={textareaValue ?? ''}
                    onChange={handleTextareaValue}
                />
            </div>
            <div className="d-block mt-3">
                <Button
                    className="btn-secondary"
                    onClick={onClick}
                    disabled={mode !== SCREEN_MODE_MONITORING || data.length === 0}
                >
                    {t('Save', 'Button')}
                </Button>
            </div>
            <Modal
                initModal={isSubmitOpen}
                toggleModal={handleSubmitToggle}
                okCallback={handleSubmitCallback}
                headerTitle={t('Confirm submit')}
            >
                <div className={'modal-body'}>
                    <div>{t('Do you want to save?')}</div>
                </div>
            </Modal>
            {!isToday && (
                <Modal
                    initModal={isDateSettingOpen}
                    toggleModal={handleDateSettingToggle}
                    okCallback={handleSubmitCallback}
                    headerTitle={t('Inspection time setting')}
                >
                    <div className="modal-body">
                        <div className="text-center pnt-txt s-7">
                            <Trans t={t}>
                                The result of the general check on <b className="color-danger">{{ formattedDate }}</b>{' '}
                                is saved as the time below.
                            </Trans>
                        </div>
                        <div className="text-center pnt-txt s-7">
                            {t(
                                'If you modify the scorecard individually, only the results for that asset will change.',
                            )}
                        </div>
                        <TimeSelect
                            className="mt-3"
                            targetDate={targetDate}
                            handleTimeChange={handleChangeCheckerDate}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

const TextContainer = styled.textarea`
    resize: none;
`;

export default NormalRiskInspection;
