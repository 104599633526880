import React, { useMemo, useRef, useState } from 'react';
import WidgetCard from '../../Components/WidgetCard';
import useTranslation from '@hooks/useTranslation';
import CountByAssetStatus from './Components/CountByAssetStatus';
import { ASSET_MANAGEMENT, PROPERTY_ID } from '../../constants';
import {
    ASSET_CODE,
    DEFAULT_LABEL_VALUE,
    DEFAULT_PARAMETERS,
    DUMMY_COUNT_RESPONSE,
    DUMMY_LIST_RESPONSE,
    TAG_MAPPED_STATUS,
} from './constants';
import { Button } from '@components';
import AssetList from './Components/AssetList';
import { fetchAssetCountStatus, getTargetListWithLocationApi } from '@api/common/asset';
import AssetDetail from './Components/AssetDetail';
import { useSettings } from '../../util/useSettings';
import useMultiOptionsByRole from '../util/useMultiOptionsByRole';
import useCategoryList from '../util/useCategoryList';
import useMonitor from '../../util/useMonitor';
import Filter from '../Components/Filter';

const AssetRentalStatus = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation(ASSET_MANAGEMENT);
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { hiddenMapTile } = settings;

    const { selectedWardList, options: wardOptions, handleWardChange } = useMultiOptionsByRole();
    const { selectedCategoryList, categoryOptions, handleCategoryChange } = useCategoryList();

    const [selectedStatusType, setSelectedStatusType] = useState(null);
    const [page, setPage] = useState(DEFAULT_PARAMETERS.page);
    const [word, setWord] = useState(undefined);
    const [selectedAsset, setSelectedAsset] = useState(null);

    const [loading, setLoading] = useState(true);
    const tableRef = useRef(null);

    const baseParam = useMemo(() => {
        return {
            isTagMapped: 'N',
            categoryCodes:
                selectedCategoryList.length !== 0
                    ? selectedCategoryList.toString()
                    : categoryOptions.map(v => v.value).toString(),
            targetPropertiesSearch: {
                categoryCode: ASSET_CODE,
                propertySearchs:
                    selectedWardList.length !== 0
                        ? selectedWardList.map(ward => ({
                              propertyId: PROPERTY_ID.ASSET.WARD,
                              values: [ward],
                          }))
                        : wardOptions.map(({ value }) => ({
                              propertyId: PROPERTY_ID.ASSET.WARD,
                              values: [value],
                          })),
            },
            target: word,
        };
    }, [selectedWardList.length, selectedCategoryList.length, word]);

    const countParam = useMemo(() => {
        return {
            ...baseParam,
            statusType: selectedStatusType && selectedStatusType !== TAG_MAPPED_STATUS ? selectedStatusType : undefined,
        };
    }, [baseParam, selectedStatusType]);

    const listParam = useMemo(() => {
        return {
            ...DEFAULT_PARAMETERS,
            ...baseParam,
            isTagMapped: selectedStatusType !== null ? 'Y' : 'N',
            status: selectedStatusType && selectedStatusType !== TAG_MAPPED_STATUS ? selectedStatusType : undefined,
            page,
        };
    }, [baseParam, selectedStatusType, page]);

    // Summary 조회
    const [assetCount, setAssetCount] = useState(DUMMY_COUNT_RESPONSE);
    useMonitor({
        config,
        defaultData: DUMMY_COUNT_RESPONSE,
        dynamicParam: countParam,
        fetchData: fetchAssetCountStatus,
        makeData: ({ newData }) => {
            setAssetCount(newData);
        },
    });

    // 목록 조회
    const [assetList, setAssetList] = useState(DUMMY_LIST_RESPONSE);
    useMonitor({
        config,
        defaultData: DUMMY_LIST_RESPONSE,
        dynamicParam: listParam,
        fetchData: getTargetListWithLocationApi,
        makeData: ({ newData }) => {
            if (newData.rows) {
                setAssetList({ ...DUMMY_LIST_RESPONSE, ...newData });
                setLoading(false);
            }
        },
        errorHandler: () => {
            setLoading(false);
        },
    });

    const handleSelectedStatusType = selectValue => {
        setLoading(true);
        setSelectedStatusType(selectValue);
        setPage(DEFAULT_PARAMETERS.page);
    };

    const handleListSearch = word => {
        setWord(word);
        setPage(DEFAULT_PARAMETERS.page);
    };

    // Reset button event
    const handleReset = () => {
        setLoading(true);
        setSelectedStatusType(null);
        setWord(undefined);
    };

    const handlePagination = pageIndex => {
        setLoading(true);
        setPage(pageIndex);
    };

    const handleRowClick = selected => {
        setSelectedAsset(selected);
    };

    // fixme: 선택 태그 정보 (신호 상태, 위치, 이름) 상세 화면 값 보내기 용 (임시 사용중)
    const findItem = assetList?.rows.find(item => item.targetNum === selectedAsset?.targetNum);
    const tempTargetValue = {
        statusList:
            !findItem || !findItem.status ? DEFAULT_LABEL_VALUE : findItem.status.map(status => t(status)).join(', '),
        geofenceList:
            !findItem || !findItem.geofences
                ? DEFAULT_LABEL_VALUE
                : findItem.geofences.map(geofence => geofence.fcName).join(', '),
        beaconName: !findItem || !findItem.beaconName ? DEFAULT_LABEL_VALUE : findItem.beaconName,
    };

    const handleWard = ward => {
        setLoading(true);
        setPage(1);
        handleWardChange(ward);
    };
    const handleCategory = category => {
        setLoading(true);
        setPage(1);
        handleCategoryChange(category);
    };

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            headerAction={
                selectedAsset && (
                    <Button className="btn-gray" onClick={() => setSelectedAsset(null)}>
                        {t('Back', 'Button')}
                    </Button>
                )
            }
            searchFilter={
                <Filter>
                    <Filter.SearchableSelect
                        options={{
                            title: t('Ward', 'CommonColumn'),
                            data: wardOptions,
                            selected: selectedWardList,
                            onChange: handleWard,
                        }}
                    />
                    <Filter.SearchableSelect
                        options={{
                            title: t('Category'),
                            data: categoryOptions,
                            selected: selectedCategoryList,
                            onChange: handleCategory,
                        }}
                    />
                </Filter>
            }
            {...restProps}
        >
            {!selectedAsset ? (
                <>
                    <CountByAssetStatus
                        countByAssetStatus={assetCount}
                        selectedStatusType={selectedStatusType}
                        handleSelectedStatusType={handleSelectedStatusType}
                    />
                    <AssetList
                        list={assetList}
                        handleRowClick={handleRowClick}
                        handleReset={handleReset}
                        handleListSearch={handleListSearch}
                        isLoading={loading}
                        handleListParam={handlePagination}
                        tableRef={tableRef}
                    />
                </>
            ) : (
                <AssetDetail
                    selectedIotItem={selectedAsset}
                    setSelectedIotItem={setSelectedAsset}
                    parentCategoryCode={ASSET_CODE}
                    status={tempTargetValue.statusList}
                    geofence={tempTargetValue.geofenceList}
                    beaconName={tempTargetValue.beaconName}
                    hiddenMapTile={hiddenMapTile}
                />
            )}
            {children}
        </WidgetCard>
    );
};

export default AssetRentalStatus;
