import React, { useContext, useEffect } from 'react';
import { useTranslation } from '@hooks';
import { SearchWrap } from '../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '../../../../MainPages/Components/FilterSearchGroup';
import FilterGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/FilterGroup';
import FloorGroupSelect from './FloorGroupSelect';
import { RealTimeLocationTrackingDispatchContext } from '../index';
import { setSelectedCategory } from '../realTimeLocationTrackingReducer';
import useCategoryList from '../../util/useCategoryList';
import { SearchableSelect } from '@components';

const Search = () => {
    const t = useTranslation('Filter');
    const dispatch = useContext(RealTimeLocationTrackingDispatchContext);
    const { categoryOptions, selectedCategoryList, handleCategoryChange: handleCategoryListChange } = useCategoryList(
        {},
    );

    const handleCategoryChange = selected => {
        handleCategoryListChange(selected);
        dispatch(setSelectedCategory(selected));
    };

    useEffect(() => {
        dispatch(setSelectedCategory(categoryOptions));
    }, [categoryOptions]);

    return (
        <FilterSearchGroup>
            <FilterGroup label={t('Filter')}>
                <SearchWrap>
                    <FloorGroupSelect />
                    <SearchableSelect
                        className="ml-2"
                        selected={selectedCategoryList}
                        data={categoryOptions}
                        onChange={handleCategoryChange}
                        placeholder={t('Category')}
                    />
                </SearchWrap>
            </FilterGroup>
        </FilterSearchGroup>
    );
};

export default Search;
