import React, { useContext, useMemo, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { useColumns, useTranslation } from '@hooks';
import { Table } from '@components';
import Modal from '@components/Modal';
import { isObject } from '@util/common/util';
import Bookmark from '../../../Components/Bookmark';
import {
    DEFAULT_DISPLAY_VALUE,
    STATUS_COLOR,
    TABLE_BORDER_WIDTH,
    TABLE_HEADER_HEIGHT,
    TABLE_ROW_HEIGHT,
} from '../constants';
import { VitalSignsMonitoringDispatchContext } from '../index';
import { setIsDetail, setTargetNum } from '../vitalSignsMonitoringForHiCardiPlusReducer';
import { getBatteryDisplayValue, getBatteryIconClassName } from '../util';
import { BatteryError } from './BatteryError';

/**
 * 병상 리스트 컴포넌트
 *
 * @param wardNum 병동/부서 이름
 * @param roomNum 병실 번호
 * @param bedList 병상 목록
 * @param handleRefreshData 환자별 활력징후 데이터 새로고침 handler
 *
 * @since 2023-02-07
 * @author kunwoong-kim
 * */
const VitalSignsBedList = ({ wardNum, roomNum, bedList, handleRefreshData }) => {
    const t = useTranslation('VitalSignMonitoring');
    const dispatch = useContext(VitalSignsMonitoringDispatchContext);
    // 렌더링을 위한 Entry
    const bedEntries = Object.entries(bedList);
    const handleViewDetail = targetId => {
        dispatch(setTargetNum(targetId));
        dispatch(setIsDetail(true));
    };

    // 임시, API 수정 시 변경 예정
    const flattenData = useMemo(
        () =>
            [].concat(
                ...bedEntries.map(([bedKey, patientList]) =>
                    Object.entries(patientList).map(([patientKey, patient]) => ({
                        ward: wardNum,
                        room: roomNum,
                        bed: bedKey,
                        ...patient,
                    })),
                ),
            ),
        [bedEntries],
    );

    // 북마크 관련 모달
    const [bookmarkErrorModal, setBookmarkErrorModal] = useState(false);
    const toggleBookmarkErrorModal = () => setBookmarkErrorModal(!bookmarkErrorModal);
    const bookmarkErrorModalOkCallback = () => handleRefreshData();
    const handleBookmarkSuccess = () => handleRefreshData();
    const handleBookmarkFailure = () => setBookmarkErrorModal(true);

    const columns = useColumns(
        [
            {
                Header: 'Bed',
                accessor: 'bed',
                width: 40,
                Cell: ({ row: { original } }) => {
                    return <span>{original.bed || '-'}</span>;
                },
                className: 'text-ellipsis',
            },
            { Header: 'Patient Name', accessor: 'targetName', width: 70, className: 'text-ellipsis' },
            { Header: 'Patient ID', accessor: 'targetId', width: 70, className: 'text-ellipsis' },
            {
                Header: 'HB',
                accessor: 'HEARTBEAT.value',
                width: 70,
                headerClassName: 'text-ellipsis bg-depth-9',
                className: 'bg-depth-3 p-0',
                Cell: ({ row: { original } }) => {
                    let valueHB = original.HEARTBEAT?.value;
                    const statusHB = original.HEARTBEAT?.status;

                    if (!original.HEARTBEAT || (valueHB === 0 && !statusHB)) {
                        valueHB = DEFAULT_DISPLAY_VALUE;
                    } else {
                        valueHB = Math.round(valueHB);
                    }

                    return (
                        <div
                            className={cx(
                                'd-flex justify-content-center align-items-center h-100 pnt-txt txt-bold s-7',
                                STATUS_COLOR[statusHB]?.BG,
                                STATUS_COLOR[statusHB]?.COLOR,
                            )}
                        >
                            {valueHB}
                        </div>
                    );
                },
            },
            {
                Header: 'RR',
                accessor: 'RESPIRATION_RATE.value',
                width: 70,
                headerClassName: 'text-ellipsis bg-depth-9',
                className: 'bg-depth-3 p-0',
                Cell: ({ row: { original } }) => {
                    let valueRR = original.RESPIRATION_RATE?.value;
                    const statusRR = original.RESPIRATION_RATE?.status;

                    if (!original.RESPIRATION_RATE || (valueRR === 0 && !statusRR)) {
                        valueRR = DEFAULT_DISPLAY_VALUE;
                    } else {
                        valueRR = Math.floor(valueRR);
                    }

                    return (
                        <div
                            className={cx(
                                'd-flex justify-content-center align-items-center h-100 pnt-txt txt-bold s-7',
                                STATUS_COLOR[statusRR]?.BG,
                                STATUS_COLOR[statusRR]?.COLOR,
                            )}
                        >
                            {valueRR}
                        </div>
                    );
                },
            },
            {
                Header: 'ST',
                accessor: 'TEMPERATURE.value',
                width: 70,
                headerClassName: 'text-ellipsis bg-depth-9',
                className: 'bg-depth-3 p-0',
                Cell: ({ row: { original } }) => {
                    let valueT = original.TEMPERATURE?.value;
                    const statusT = original.TEMPERATURE?.status;

                    if (!original.TEMPERATURE || (valueT === 0 && !statusT)) {
                        valueT = DEFAULT_DISPLAY_VALUE;
                    } else {
                        valueT = typeof valueT === 'number' ? valueT.toFixed(1) : valueT;
                    }

                    return (
                        <div
                            className={cx(
                                'd-flex justify-content-center align-items-center h-100 pnt-txt txt-bold s-7',
                                STATUS_COLOR[statusT]?.BG,
                                STATUS_COLOR[statusT]?.COLOR,
                            )}
                        >
                            {valueT}
                        </div>
                    );
                },
            },
            {
                Header: 'Battery',
                headerClassName: 'text-ellipsis',
                width: 45,
                Cell: ({ row: { original } }) => {
                    let remainsBattery = original?.BATTERY ?? original?.battery;
                    if (!!remainsBattery) {
                        if (isObject(remainsBattery)) {
                            remainsBattery = Math.round(remainsBattery.value);
                        } else {
                            remainsBattery = Math.round(remainsBattery);
                        }
                    }
                    return (
                        <div className="d-flex justify-content-between align-items-center pnt-txt s-9 text-ellipsis px-1">
                            {!remainsBattery ? (
                                <BatteryError />
                            ) : (
                                <span
                                    className={cx(
                                        'material-icons-round md-20 rotate-2',
                                        remainsBattery && remainsBattery <= 10 && 'color-danger',
                                    )}
                                >
                                    {getBatteryIconClassName(remainsBattery)}
                                </span>
                            )}

                            <span className={cx(remainsBattery && remainsBattery <= 10 && 'color-danger')}>
                                {t(getBatteryDisplayValue(remainsBattery))}
                            </span>
                        </div>
                    );
                },
            },
            {
                Header: 'Recognition Time',
                accessor: 'regDate',
                width: 80,
                headerClassName: 'text-ellipsis',
                className: 'text-ellipsis',
                Cell: ({ value }) => (
                    <span title={moment.unix(value).format('YYYY-MM-DD HH:mm:ss')}>
                        {value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : '-'}
                    </span>
                ),
            },
            {
                Header: 'Sensor Name',
                accessor: 'sensorName',
                width: 60,
                headerClassName: 'text-ellipsis',
                className: 'text-ellipsis',
            },
            {
                Header: 'Classification',
                accessor: 'deviceName',
                width: 60,
                headerClassName: 'text-ellipsis',
                className: 'text-ellipsis',
            },
            {
                Header: 'Bookmark',
                accessor: 'isBookmark',
                width: 30,
                headerClassName: 'text-ellipsis',
                className: 'd-flex flx-center',
                Cell: ({ row: { original } }) => {
                    return (
                        <Bookmark
                            isBookmark={original.isBookmark}
                            targetNum={original.targetNum}
                            successCallback={handleBookmarkSuccess}
                            failureCallback={handleBookmarkFailure}
                        />
                    );
                },
            },
        ],
        t,
        [],
    );

    return (
        <>
            <div
                style={{
                    height: `${TABLE_HEADER_HEIGHT + flattenData.length * TABLE_ROW_HEIGHT + TABLE_BORDER_WIDTH}px`,
                }}
            >
                <Table
                    columns={columns}
                    data={{ rows: flattenData }}
                    paging={false}
                    onTrClick={({ targetId }) => handleViewDetail(targetId)}
                />
            </div>
            <Modal
                initModal={bookmarkErrorModal}
                toggleModal={toggleBookmarkErrorModal}
                headerTitle={t('Bookmark')}
                bodyText={t('Something has changed.', 'ConfirmModal')}
                removeCancel
                okCallback={bookmarkErrorModalOkCallback}
            />
        </>
    );
};

export default VitalSignsBedList;
