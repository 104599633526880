import React, { useMemo, useState } from 'react';
import { Table } from '@components';
import { useTranslation } from '@util/hooks';
import styled from 'styled-components';
import HistoryModal from './HistoryModal';
import { Data, Row } from '../hooks/useAmountList';
import useModal from '../../../util/useModal';
import { formatDateByColumn } from '../../../util/format';

interface Props {
    data: Data;
    onPageChange: (page: number) => void;
    onSelectAllChange: (checked: boolean) => void;
    onRowCheckedChange: (id: string) => void;
}

const OutstandingAmountList = ({ data, onPageChange, onSelectAllChange, onRowCheckedChange }: Props) => {
    const t = useTranslation('OutpatientStorage');
    const [selectedRow, setSelectedRow] = useState<Row>();
    const [isOpen, handleToggle, handleOpen] = useModal(); // 알림톡 이력 상세 모달

    const columns = useMemo(
        () => [
            {
                id: 'select',
                Header: () => {
                    return (
                        <div>
                            <input
                                type="checkbox"
                                className="mr-1"
                                checked={data.rows.every(ele => ele.checked)}
                                onChange={e => {
                                    onSelectAllChange(e.target.checked);
                                }}
                            />
                            {t('Select all')}
                        </div>
                    );
                },
                width: 50,
                Cell: ({ row: { original } }: { row: { original: Row } }) => {
                    return (
                        <div>
                            <input
                                type="checkbox"
                                className="m-0"
                                checked={original.checked}
                                onChange={() => onRowCheckedChange(original.patientId)}
                                onClick={e => e.stopPropagation()}
                            />
                        </div>
                    );
                },
            },
            {
                Header: t('Patient number'),
                accessor: 'patientId',
            },
            {
                Header: t('Patient name'),
                accessor: 'patientName',
            },
            {
                Header: t('Total outstanding amount'),
                Cell: ({ row: { original } }: { row: { original: Row } }) => {
                    return <span>{original.amount.toLocaleString()}</span>;
                },
            },
            {
                Header: t('Notification History'),
                Cell: ({ row: { original } }: { row: { original: Row } }) => {
                    return (
                        <span>
                            {original.historyCount}
                            {t(' times')}
                        </span>
                    );
                },
            },
            {
                Header: t('Last sent data'),
                Cell: ({ row }: { row: { original: Row } }) => {
                    return <span>{formatDateByColumn(row, 'lastSendingDate')}</span>;
                },
            },
        ],
        [data.rows, onRowCheckedChange, onSelectAllChange, t],
    ) as any;

    const handleTrClick = (row: Row) => {
        setSelectedRow(row);
        handleOpen();
    };

    return (
        <>
            <TableWrapper>
                <Table columns={columns} data={data} onPageChange={onPageChange} onTrClick={handleTrClick} />
            </TableWrapper>
            {isOpen && selectedRow && (
                <HistoryModal isOpen={isOpen} toggleModal={handleToggle} patientInfo={selectedRow} />
            )}
        </>
    );
};

const TableWrapper = styled.div`
    height: 600px;
`;

export default OutstandingAmountList;
