import React from 'react';
import styled from 'styled-components';
import { Button, LineChart } from '@components';
import InnerItemCard from '@components/Card/InnerItemCard';
import { useTranslation } from '@hooks';
import { OUTPATIENT_CONGESTION_CHART_TITLE, OUTPATIENT_REPORT, TIME_LABELS } from '../../constants';

const Chart = ({ data }) => {
    const t = useTranslation(OUTPATIENT_REPORT);

    const lineData = {
        labels: TIME_LABELS,
        datasets: data.map(({ departmentName, wait }) => ({
            label: departmentName,
            data: wait,
        })),
    };
    return (
        <InnerItemCard
            className={'m-2 h-90'}
            bodyClassName={'h-100'}
            header={{ title: t(OUTPATIENT_CONGESTION_CHART_TITLE) }}
        >
            <div className={'h-90 flx-row position-relative'}>
                <div className={'w-100 h-100 '}>
                    <LineChart
                        data={lineData}
                        options={{
                            scales: {
                                y: {
                                    display: true,
                                    ticks: {
                                        stepSize: 20,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: true,
                                    text: t(OUTPATIENT_CONGESTION_CHART_TITLE),
                                },
                            },
                        }}
                    />
                </div>
                <DownloadButton className={'btn-secondary ml-3'}>{t('Download')}</DownloadButton>
            </div>
        </InnerItemCard>
    );
};

const DownloadButton = styled(Button)`
    position: absolute;
    top: 0;
    right: 0;
`;

export default Chart;
