const EVT_TYPE = {
    FLOOR_IN: 'Floor In',
    FLOOR_OUT: 'Floor Out',
    GEOFENCE_IN: 'Geofence In',
    GEOFENCE_OUT: 'Geofence Out',
    RESTRICTION_IN: 'Restriction In',
    RESTRICTION_OUT: 'Restriction Out',
    LOW_BATTERY: 'Low Battery',
    LOST_SIGNAL: 'Lost Signal',
    SOS_ON: 'SOS On',
    SOS_OFF: 'SOS Off',
    LEAVEALONE_ON: 'Leave Alone On',
    LEAVEALONE_OFF: 'Leave Alone Off',
    LONGSTAY_ON: 'Long Stay On',
    LONGSTAY_OFF: 'Long Stay Off',
    SENSOR_NORMAL: 'Sensor : Normal',
    SENSOR_WARNING: 'Sensor : Warning',
    SENSOR_CRITICAL: 'Sensor : Critical',
    SENSOR_OUTOFRANGE: 'Sensor : Out of Range',
    SENSOR_LOST_SIGNAL: 'Sensor : Lost Signal',
    NOBODY_AROUND_ON: 'Nobody Around On',
    NOBODY_AROUND_OFF: 'Nobody Around Off',
    LONG_STAY_ZONE_ON: 'Long Stay Zone ON',
    LONG_STAY_ZONE_OFF: 'Long Stay Zone OFF',
};

export default EVT_TYPE;
