import { useTranslation } from '@hooks';
import { useState } from 'react';
import { ASSET_MANAGEMENT } from '../../constants';

// 점검구분 Select
const useCheckTypeOptions = () => {
    const t = useTranslation(ASSET_MANAGEMENT);

    const options = [
        { value: 'high', label: t('high inspection') },
        { value: 'normal', label: t('normal inspection') },
    ];

    const [checkTypeList, setCheckTypeList] = useState(options.map(ele => ele.value));

    const handleCheckTypeChange = selected => {
        setCheckTypeList(selected.map(v => v.value));
    };

    return {
        checkTypeOptions: options,
        checkTypeList,
        handleCheckTypeChange,
    };
};

export default useCheckTypeOptions;
