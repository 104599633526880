import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    floorIds: { floorId: '', floorName: '' },
    floorIdPath: '',
    scannerNums: [],
    scannerList: [],
};

const scannerStatusLogSlice = createSlice({
    name: 'scannerStatusLog',
    initialState,
    reducers: {
        setFloorIds: (state, action) => {
            state.floorIds = action.payload;
        },
        setFloorIdPath: (state, action) => {
            state.floorIdPath = action.payload;
        },
        setScannerNums: (state, action) => {
            state.scannerNums = action.payload;
        },
        setScannerList: (state, action) => {
            state.scannerList = action.payload;
        },
        reset: (state, action) => {
            const initState = { ...initialState, ...action.payload };
            for (let key in initState) {
                state[key] = initState[key];
            }
        },
    },
});

export const { setFloorIds, setFloorIdPath, setScannerNums, setScannerList, reset } = scannerStatusLogSlice.actions;
export default scannerStatusLogSlice;
