import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { getRefrigeratorSensorDateApi } from '@api/monitoring';
import useAsync from '@hooks/useAsync';
import { useRefrigeratorSensorContext } from '../../../../index';
import useTranslation from '@hooks/useTranslation';
import { LoadingBlock, LineChart } from '@components';
import NoDataBlock from '../../../../../../Components/NoDataBlock';

const Graph = ({ date }) => {
    const t = useTranslation('RefrigeratorSensor');

    const { sensorNum } = useRefrigeratorSensorContext();
    const [chartData, setChartData] = useState({});

    const {
        promise: getRefrigeratorSensorDate,
        state: { isLoading },
    } = useAsync({
        promise: getRefrigeratorSensorDateApi,
        resolve: res => {
            const graphData = res.targetInfoList.map(({ dataMap: { TEMPERATURE }, regDate }) => {
                return {
                    x: regDate * 1000,
                    y: TEMPERATURE ? Number(TEMPERATURE.toFixed(2)) : null,
                };
            });

            setChartData({
                datasets: [
                    {
                        label: t('Temperature'),
                        data: graphData,
                    },
                ],
            });
        },
        reject: err => console.error(err),
    });

    useEffect(() => {
        setChartData(prevState => {
            if (prevState.datasets) {
                return {
                    datasets: [
                        {
                            ...prevState.datasets[0],
                            label: t('Temperature'),
                        },
                    ],
                };
            }
            return prevState;
        });
    }, [t]);

    // 일자 변경
    useEffect(() => {
        const currentDate = moment().date();
        const selectedDate = moment(date * 1000).date();
        let newParams;

        // 현재일자 === 선택일자 ? 현재시간부터 전일 현재 시간까지
        if (currentDate === selectedDate) {
            newParams = { startDate: moment().subtract(1, 'days').unix(), endDate: moment().unix(), sensorNum };
        }
        // 현재일자 !== 선택일자 ? 선택일자 00:00:00 ~ 23:59:59
        else {
            newParams = {
                startDate: moment(date * 1000)
                    .startOf('date')
                    .unix(),
                endDate: moment(date * 1000)
                    .endOf('date')
                    .unix(),
                sensorNum,
            };
        }

        getRefrigeratorSensorDate(newParams);
    }, [date]);

    return (
        <LoadingBlock blocking={isLoading}>
            {chartData?.datasets?.[0]?.data?.length ? (
                <div className="w-100 h-100 p-2 border-size-1 border-depth-7">
                    <LineChart
                        data={chartData}
                        options={{
                            radius: 0,
                            borderWidth: 2,
                            interaction: {
                                intersect: false,
                            },
                            plugins: {
                                legend: {
                                    display: false,
                                },
                            },
                            scales: {
                                x: {
                                    type: 'time',
                                    time: {
                                        unit: 'minute',
                                        tooltipFormat: 'HH:mm:ss',
                                        displayFormats: { minute: 'HH:mm' },
                                    },
                                    grid: { display: true },
                                },
                                y: {
                                    afterDataLimits: function (scale) {
                                        scale.max = scale.max + 5;
                                        scale.min = scale.min - 5;
                                    },
                                    grid: {
                                        borderDash: [8, 3],
                                    },
                                },
                            },
                        }}
                    />
                </div>
            ) : (
                <NoDataBlock className="border-size-1 border-depth-7" />
            )}
        </LoadingBlock>
    );
};

export default Graph;
