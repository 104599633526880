import React from 'react';
import { Modal } from '@components';
import styles from '@asset/pnt/widgets/RealTimeSensorMonitoring.module.scss';
import { ModalBody } from 'reactstrap';
import styled from 'styled-components';
import cx from 'classnames';
import moment from 'moment';
import useTranslation from '@hooks/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { convertProperties } from '../../../Widgets/util/commons';
import useCategoryProperties from '../../../Widgets/util/useCategoryProperties';
import { CATEGORY_CODE } from '@util/mappInfo';
import { deleteEmergencyAlertContents } from '@reducer/EmergencyAlert';
import sosImage from '@asset/images/icon_sos.svg';

const MAX_MULTIPLE_MODAL = 10;
const OFFSET = 20;

const SENSOR = {
    RESPIRATION_RATE: 's_ipi',
    TEMPERATURE: 's_temperature',
    HEARTBEAT: 's_heartbeat',
};

const STATUS = {
    SENSOR_CRITICAL: { ICON_NAME: 'close', COLOR: 'danger' },
    SENSOR_WARNING: { ICON_NAME: 'priority_high', COLOR: 'warning' },
    SENSOR_NORMAL: { ICON_NAME: 'done', COLOR: 'success' },
};

const SENSOR_UNIT = {
    RESPIRATION_RATE: 'bpm',
    TEMPERATURE: '℃',
    HEARTBEAT: 'bpm',
};

const SENSOR_STATUS_COLOR = {
    CRITICAL: 'danger',
    WARNING: 'danger',
    NORMAL: 'success',
    undefined: 'dark',
};

const OUT_OF_RANGE = 'OUTOFRANGE';

// 위급상황 알림에 보여지는 센서 아이템 개수, 이 개수의 숫자보다 높은 n번째 센서 아이템은 스크롤 처리
const MAX_VISIBLE_SENSING_ITEM_CONTENT_COUNT = 6;

const EmergencyAlertModal = () => {
    const t = useTranslation('EmergencyAlert');
    const dispatch = useDispatch();
    const categoryProperties = useCategoryProperties({ categoryCode: CATEGORY_CODE.PATIENT });
    const { emergencyAlertModalContents } = useSelector(state => state.EmergencyAlert);

    const closedModal = ({ alertKey }) => dispatch(deleteEmergencyAlertContents({ alertKey }));

    return (
        <>
            {emergencyAlertModalContents.map((alertInfo, index) => {
                const {
                    alertKey,
                    target,
                    detectionTime,
                    filteredSensingState,
                    sensingUnits,
                    categoryName,
                    fcName,
                } = alertInfo;
                const convertProps = convertProperties(target?.properties, categoryProperties);
                const sensorBgColor = 'danger';

                return (
                    <MultipleModal
                        key={alertKey}
                        initModal
                        toggleModal={() => closedModal({ alertKey })}
                        backdrop={false}
                        keyboard={false}
                        headerTitle={`${categoryName} ${t('emergency alerts')}`}
                        removeCancel
                        index={index}
                        length={emergencyAlertModalContents?.length}
                    >
                        <ModalBody className={'flx-col justify-content-center align-items-center'}>
                            {/* 센서 상태 */}
                            <div className={cx(styles.sensor_img, `my-2 bg-${sensorBgColor}`)}>
                                {/*사이렌 아이콘*/}
                                <img src={sosImage} alt={'sos iamge'} />
                            </div>
                            {/* 상태 정보 */}
                            <Box className="bg-depth-7 w-80" maxVisibleCount={MAX_VISIBLE_SENSING_ITEM_CONTENT_COUNT}>
                                {Object.keys(filteredSensingState)
                                    .sort()
                                    .map(sensingType => {
                                        // sensingType : RESPIRATION_RATE, HEARTBEAT
                                        const status = filteredSensingState[sensingType].status;
                                        const color = SENSOR_STATUS_COLOR[status];

                                        return (
                                            <div
                                                className={cx(`d-flex w-100 gap-1 justify-content-center`)}
                                                key={alertKey + '_' + sensingType}
                                            >
                                                <div className={cx(`w-50 text-center fs-3 color-${color} fw-bold`)}>
                                                    {t(sensingType, 'RealTimeSensorMonitoring')}
                                                </div>
                                                <div
                                                    className={cx(
                                                        `d-flex justify-content-center align-items-center w-50 gap-1`,
                                                    )}
                                                >
                                                    <span className={`fs-3 color-${color} fw-bold`}>
                                                        {Math.round(filteredSensingState[sensingType].value)}
                                                    </span>
                                                    <span className={`fs-3 color-${color} fw-bold`}>
                                                        {sensingUnits && sensingUnits[sensingType]}
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </Box>
                            {/* 환자 정보 */}
                            <div className={'flx-col fw-bold color-gray fs-4 gap-0 align-items-center w-80 mx-2 mb-3'}>
                                <div className={'text-center w-100 text-ellipsis'}>{fcName}</div>
                                <div className={'text-center w-100 text-ellipsis'}>{target.targetName}</div>
                            </div>
                            <div className={'fw-bold'}>
                                {t('Emergency notification time')} :{' '}
                                {moment.unix(detectionTime).format('YYYY-MM-DD HH:mm:ss')}
                            </div>
                        </ModalBody>
                    </MultipleModal>
                );
            })}
            {emergencyAlertModalContents.length !== 0 && (
                <BackDrop className="position-fixed w-100 h-100 critical-backdrop" />
            )}
        </>
    );
};

const MultipleModal = styled(Modal)`
    margin-top: calc(${({ index, length }) => index * OFFSET + OFFSET * (MAX_MULTIPLE_MODAL - length)}px);
    margin-left: calc(27% + ${({ index, length }) => index * OFFSET + OFFSET * (MAX_MULTIPLE_MODAL - length)}px);
`;

const Box = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    border-radius: 0.5rem;
    padding: 1rem;
    // Emergency Sensing Item 내용 높이 2.625rem
    max-height: ${props => 1 + props.maxVisibleCount * 2.625}rem;
    overflow-y: auto;
`;

const BackDrop = styled.div`
    z-index: 1000;
`;

export default EmergencyAlertModal;
