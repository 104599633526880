import React, { useContext, useRef } from 'react';
import WidgetCard from '../../Components/WidgetCard';
import outpatientUnattendedReceptionSlice from './OutpatientUnattendedReceptionStatusSlice';
import ContextProvider from '@components/ContextProvider';
import OutpatientUnattendedReceptionStatusContainer from './OutpatientUnattendedReceptionContainer';

export const OutpatientUnattendedReceptionStatusDispatchContext = React.createContext();
export const OutpatientUnattendedReceptionStatusStateContext = React.createContext();
export const useOutpatientUnattendedReceptionContext = () =>
    useContext(OutpatientUnattendedReceptionStatusStateContext);
export const useOutpatientUnattendedReceptionDispatch = () =>
    useContext(OutpatientUnattendedReceptionStatusDispatchContext);

const OutpatientUnattendedReceptionStatus = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();

    return (
        <ContextProvider
            StateContext={OutpatientUnattendedReceptionStatusStateContext}
            DispatchContext={OutpatientUnattendedReceptionStatusDispatchContext}
            slice={outpatientUnattendedReceptionSlice}
        >
            <WidgetCard ref={widgetRef} widgetInfo={widgetInfo} {...restProps}>
                <OutpatientUnattendedReceptionStatusContainer widgetInfo={widgetInfo} />
                {children}
            </WidgetCard>
        </ContextProvider>
    );
};

export default OutpatientUnattendedReceptionStatus;
