import React, { useContext, useEffect, useRef, useState } from 'react';
import { useAppSelector, useTranslation } from '@hooks';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import Table from './CustomTable';
import Draggable from '@components/Draggable';
import { useHeatmapContext, useHeatmapDispatch } from '../index';
import useColumns from '@hooks/useColumns';
import * as column from '../../../util/columns';
import { setTargetNum } from '../heatMapSlice';

const PAGE_SIZE = 5;

const DetectedTargetPopup = ({ modal, toggleModal, widgetRef, mapRef }) => {
    const t = useTranslation('HeatMap');
    const state = useHeatmapContext();
    const dispatch = useHeatmapDispatch();
    const popupRef = useRef();
    const { detectedTargets, targetNum } = state;
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);

    const columns = useColumns([column.categoryName(), column.targetName()], '', [colorScheme]);

    const [position, setPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const widgetPosition = getWidgetPosition();
        if (popupRef.current && modal) {
            const x = Math.ceil((widgetPosition.width - popupRef.current.getBoundingClientRect().width) / 2 - 20);
            const y = Math.ceil(widgetPosition.height / 2 - widgetPosition.height / 4 - 100);
            setPosition({
                x: x,
                y: y,
            });
        }
    }, [modal]);

    const getWidgetPosition = () => {
        const widgetPosition = { x: 0, y: 0 };
        if (widgetRef.current) {
            const widget = widgetRef.current;
            const { offsetWidth: width, offsetHeight: height } = widget;
            widgetPosition.width = width;
            widgetPosition.height = height;
        }
        return widgetPosition;
    };

    const onTrClick = selected => {
        const { targetNum: selectedTargetNum } = selected;
        if (targetNum.length) {
            if (targetNum.includes(selectedTargetNum)) {
                const arrTargetNum = targetNum.reduce((acc, curr) => {
                    if (curr !== selectedTargetNum) {
                        acc.push(curr);
                    }
                    return acc;
                }, []);
                dispatch(setTargetNum(arrTargetNum));
            } else {
                dispatch(setTargetNum([...targetNum, selectedTargetNum]));
            }
        } else {
            dispatch(setTargetNum([selectedTargetNum]));
        }
    };

    return (
        <Draggable
            key={JSON.stringify(position)}
            bounds="parent"
            backgroundMapElement={mapRef.current?.leafletElement}
            defaultPosition={position}
        >
            <Toast isOpen={modal} className={'popup-open floorDetail bg-depth-3'} innerRef={popupRef}>
                <ToastHeader toggle={toggleModal} className={'bg-depth-4'}>
                    {t('Detected Target')}
                </ToastHeader>
                <ToastBody>
                    <Table
                        columns={columns}
                        textAlign={'left'}
                        onTrClick={onTrClick}
                        data={{
                            rows: detectedTargets,
                            pageSize: PAGE_SIZE,
                            totalPage: Math.ceil(detectedTargets.length / PAGE_SIZE),
                            totalCount: detectedTargets.length,
                        }}
                        manualPagination={false}
                        autoResetPage={false}
                        rowSelect={{
                            selected: targetNum,
                            rowKey: 'targetNum',
                            selectedRowClassName:
                                colorScheme === 'pnt-dark' ? 'select-color__mode_dark' : 'select-color__mode_navy',
                        }}
                    />
                </ToastBody>
            </Toast>
        </Draggable>
    );
};

export default DetectedTargetPopup;
