import React, { useState } from 'react';
import SearchGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import { InputGroup, InputWrap, SearchWrap } from '../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import { Button, TextInput } from '@components';
import useTranslation from '@hooks/useTranslation';
import SearchButtonArea from '../../../../MainPages/Components/FilterSearchGroup/Components/SearchButtonArea';
import { useOutpatientEquipmentDispatch } from '../index';
import { clearOutpatientCare, setSearchOutpatient } from '../OutpatientEquipmentStatusSlice';

const Search = () => {
    const t = useTranslation('OutpatientEquipmentStatus');
    const dispatch = useOutpatientEquipmentDispatch();
    const [searchInput, setSearchInput] = useState('');

    const handleSearchInput = e => {
        const { value } = e.target;
        setSearchInput(value);
    };
    const handleSearchClick = () => {
        dispatch(setSearchOutpatient(searchInput));
    };
    const handleRefresh = () => {
        dispatch(clearOutpatientCare());
        setSearchInput('');
    };

    const handleKeyDown = event => {
        //enter key
        if (event.keyCode === 13) {
            handleSearchClick();
        }
    };

    return (
        <SearchGroup>
            <SearchWrap>
                <InputWrap>
                    <InputGroup>
                        <TextInput
                            type="text"
                            value={searchInput}
                            handleChange={handleSearchInput}
                            handleKeyDown={handleKeyDown}
                            placeholder={t('Enter the name or ID of the equipment')}
                        />
                    </InputGroup>
                </InputWrap>
                <SearchButtonArea>
                    <Button className="btn-secondary" onClick={handleSearchClick}>
                        {t('Search', 'Search')}
                    </Button>
                </SearchButtonArea>
                <Button className="btn-line btn-icon-only ml-2" iconName="refresh" onClick={handleRefresh} />
            </SearchWrap>
        </SearchGroup>
    );
};

export default Search;
