import React, { useContext, useMemo } from 'react';
import { components } from 'react-select';
import { SearchableSelectContext } from '@components/Select/SearchableSelect';
import useTranslation from '@util/hooks/useTranslation';
import { SelectDispatchContext, SelectContext, OPTION_LOGIN_GROUP } from './index';
import cx from 'classnames';
import { setEditable } from '../../Components/SelectWithOption/reducer';
import { FilterGeneratorContext } from '../../FilterGenerator';
import { getOptionTitle } from '../util';
import { OPTION_LOGIN_USER } from '../LoginUserSelect';
import { MenuListInput } from '@components/Select/SearchableSelect/Parts';

export const MenuList = ({ children, getValue, setValue, options, ...restProps }) => {
    const selected = getValue();
    const t = useTranslation('TreeSelect');
    const filterT = useTranslation('Filter');
    const { valueKey, labelKey } = useContext(SearchableSelectContext);
    const dispatch = useContext(SelectDispatchContext);
    const { filterEditMode, monitoringMode } = useContext(FilterGeneratorContext);
    const { isEditable } = useContext(SelectContext);
    const handleChange = e => {
        if (isEditable !== 'Y') {
            return;
        }
        const { checked } = e.currentTarget;
        setValue(
            checked
                ? options.filter(
                      option =>
                          typeof option[labelKey] === 'string' &&
                          (option[labelKey].toLowerCase().includes(inputValue.toLowerCase()) ||
                              selected.includes(option)),
                  )
                : [],
        );
    };
    const selectedAllCondition = selected.length === options.length - !monitoringMode;

    const {
        selectProps: { showSearchInput, onMenuInputChange, onMenuInputFocusToggle, inputValue },
    } = restProps;

    const filteredSelected = useMemo(() => {
        if (selected.length && typeof selected[0][labelKey] === 'object') {
            return selected;
        }
        return selected.filter(option => option[labelKey].toLowerCase().includes(inputValue.toLowerCase()));
    }, [selected, inputValue]);

    return (
        <div>
            {showSearchInput && !!onMenuInputChange && !!onMenuInputFocusToggle && (
                <MenuListInput onMenuInputChange={onMenuInputChange} onMenuInputFocusToggle={onMenuInputFocusToggle} />
            )}
            <components.MenuList className="select__options" {...restProps} setValue={setValue}>
                <div
                    className={cx(
                        'flx-row tl p-1 border-bottom bg-depth-6',
                        isEditable !== 'Y' && !filterEditMode && 'disabled-select-option',
                    )}
                >
                    <label className={cx('pnt-checkbox form-h-small')} htmlFor={'Selected'}>
                        <input
                            id={'Selected'}
                            type="checkbox"
                            onChange={() =>
                                setValue(
                                    selected.filter(
                                        option =>
                                            typeof option[labelKey] === 'string' &&
                                            !option[labelKey].toLowerCase().includes(inputValue.toLowerCase()),
                                    ),
                                    'deselect-option',
                                )
                            }
                            checked={selectedAllCondition}
                        />
                        <span className="checkbox-effect" />
                    </label>
                    <p className="pnt-txt txt-bold color-trans-black">{t('Selected')}</p>
                </div>
                <div className={'select__options-list'}>
                    {filteredSelected.length ? (
                        filteredSelected.map((v, index) => (
                            <div
                                key={`${v[valueKey]}_${index}`}
                                className={cx(
                                    'styled-option select__option react-select__option',
                                    isEditable !== 'Y' && 'disabled-select-option',
                                )}
                                onClick={() => {
                                    if (isEditable !== 'Y') {
                                        return;
                                    }
                                    setValue(
                                        selected.filter(selectedOption => selectedOption[valueKey] !== v[valueKey]),
                                    );
                                }}
                            >
                                <label className="pnt-checkbox form-h-small check-checked" htmlFor={v[labelKey]}>
                                    <input
                                        id={v[labelKey]}
                                        type="checkbox"
                                        style={{ cursor: 'pointer' }}
                                        value={v[valueKey]}
                                        checked
                                        onChange={() => null}
                                    />
                                    <span className="checkbox-effect" />
                                </label>
                                <span className="overflow-hidden text-ellipsis">{v[labelKey]}</span>
                            </div>
                        ))
                    ) : (
                        <div className={'styled-option-label'}>{t('Not Selected')}</div>
                    )}
                </div>
                <div className="pnt-divider horizon-line border-gray opacity-6 m-0" />
                <div
                    className={cx(
                        'flx-row tl p-1 border-bottom bg-depth-5',
                        isEditable !== 'Y' && !filterEditMode && 'disabled-select-option',
                    )}
                >
                    <label className={cx('pnt-checkbox form-h-small')} htmlFor={'All'} title={t('All Items', 'Select')}>
                        <input id={'All'} type="checkbox" onChange={handleChange} checked={selectedAllCondition} />
                        <span className="checkbox-effect" />
                    </label>
                    <p className="pnt-txt txt-bold color-trans-black">{t('All')}</p>
                </div>
                <div className={cx(isEditable !== 'Y' && !filterEditMode && 'disabled-select-option')}>{children}</div>
                <div className="pnt-divider horizon-line border-gray opacity-6 m-0" />
                <div className={cx(!filterEditMode && 'disabled-option')}>
                    <div
                        className={cx('flx-row tl p-1 border-bottom bg-depth-6', !filterEditMode && 'disabled-option')}
                    >
                        <p className="pnt-txt txt-bold color-trans-black">{filterT('Option')}</p>
                    </div>
                    <div className={'styled-option flx-row'}>
                        <label
                            className={cx('pnt-checkbox form-h-small gap-1')}
                            htmlFor={'Permission'}
                            title={filterT('Permission to change')}
                        >
                            <input
                                id={'Permission'}
                                type="checkbox"
                                onChange={e => dispatch(setEditable(e.currentTarget.checked ? 'Y' : 'N'))}
                                checked={isEditable === 'Y'}
                            />
                            <span className="checkbox-effect" />
                            <span className="pnt-txt">{filterT('Permission to change')}</span>
                        </label>
                    </div>
                </div>
            </components.MenuList>
        </div>
    );
};

export const Option = function ({ label, isSelected, getValue, setValue, innerProps, ...restProps }) {
    const selected = getValue();
    const { valueKey } = useContext(SearchableSelectContext);
    const { isEditable } = useContext(SelectContext);

    return (
        <components.Option
            className={cx('styled-option select__option', isEditable !== 'Y' && 'disabled-select-option')}
            {...restProps}
            innerProps={{
                ...innerProps,
                onClick: e => {
                    if (isEditable !== 'Y') {
                        return;
                    }
                    e.stopPropagation();
                    e.preventDefault();
                    const { data, value } = restProps;
                    let selectedList;
                    if (value === OPTION_LOGIN_USER.value) {
                        selectedList = [data];
                    } else {
                        selectedList = [
                            ...selected.filter(option => option[valueKey] !== OPTION_LOGIN_USER.value),
                            data,
                        ];
                    }
                    setValue(selectedList);
                },
            }}
        >
            <label className={'pnt-checkbox form-h-small'} htmlFor={label} title={getOptionTitle(label)}>
                <input id={label} type="checkbox" checked={isSelected} onChange={() => null} />
                <span className="checkbox-effect" />
            </label>
            <span className="overflow-hidden text-ellipsis">{label}</span>
        </components.Option>
    );
};
