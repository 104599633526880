import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, Modal, Table } from '@components';
import { useTranslation } from '@util/hooks';
import { Data, Row, SendingType } from '..';
import useModal from '../../../util/useModal';
import { formatDateByColumn } from '../../../util/format';

interface Props {
    centerName: string;
    data: Data;
    onPageChange: (page: number) => void;
}

const convertSendingType = (sendingType: SendingType) => {
    if (sendingType === 'WT') {
        return 'wait';
    }
    if (sendingType === 'OD') {
        return 'order';
    }
    if (sendingType === 'AP') {
        return 'reservation';
    }
    if (sendingType === 'UP') {
        return 'amount';
    }

    return '-';
};

const HistoryList = ({ centerName, data, onPageChange }: Props) => {
    const t = useTranslation('OutpatientStorage');

    const [modalContent, setModalContent] = useState({
        text: '',
        url: '',
    });
    const [isOpen, handleToggle, handleOpen] = useModal();

    const columns = useMemo(
        () => [
            {
                Header: t('Send Date'),
                accessor: 'date',
                Cell: ({ row }: { row: { original: Row } }) => <span>{formatDateByColumn(row, 'sendingDate')}</span>,
            },
            {
                Header: t('Send Type'),
                accessor: 'type',
                Cell: ({ row: { original } }: { row: { original: Row } }) => (
                    <span>{t(convertSendingType(original.sendingType))}</span>
                ),
            },
            {
                Header: t('Patient Id'),
                accessor: 'patientId',
            },
            {
                Header: t('Patient name'),
                accessor: 'patientName',
            },
            {
                Header: t('Dept'),
                accessor: 'dept',
                Cell: ({ row: { original } }: { row: { original: Row } }) => (
                    <span>{original.sendingType === 'UP' ? '-' : original.departmentName}</span>
                ),
            },
            {
                Header: t('Send Text'),
                className: 'd-flex justify-content-center',
                accessor: 'text',
                Cell: ({ row: { original } }: { row: { original: Row } }) => (
                    <Button
                        className="text-white"
                        onClick={() => {
                            const { message } = original;
                            const splittedMessage = message.split('\n');
                            const plainText = splittedMessage.slice(0, -1).join('\n');
                            const paymentUrl = splittedMessage.slice(-1)[0];
                            setModalContent({ text: plainText, url: paymentUrl });
                            handleOpen();
                        }}
                    >
                        {t('Show text')}
                    </Button>
                ),
            },
        ],
        [handleOpen, t],
    );

    return (
        <>
            <TableWrapper>
                <Table columns={columns} data={data} onPageChange={onPageChange} />
            </TableWrapper>
            <Modal
                initModal={isOpen}
                toggleModal={handleToggle}
                headerTitle={t('Notification text')}
                bodyText={
                    <ModalBodyWrapper>
                        [{t(centerName)} {t('medical center information')}] {modalContent.text}
                        <div>
                            <a href={modalContent.url} target="_blank" rel="noopener noreferrer">
                                {t('Payment')}
                            </a>
                        </div>
                    </ModalBodyWrapper>
                }
                removeCancel={true}
            ></Modal>
        </>
    );
};

const TableWrapper = styled.div`
    height: 600px;
`;

const ModalBodyWrapper = styled.div`
    white-space: pre;
`;

export default HistoryList;
