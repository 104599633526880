import React from 'react';
import Switch, { ReactSwitchProps } from 'react-switch';
import styled from 'styled-components';
import { SwitchButtonProps } from '@components/type';

const StyledSwitch = styled(Switch)<ReactSwitchProps>`
    margin-left: 0.5rem;
    .react-switch-bg {
        border-radius: 7px !important;
    }

    .react-switch-handle {
        border-radius: 7px !important;
    }
`;

const SwitchButton = ({ checked, handleChange }: SwitchButtonProps): JSX.Element => {
    return (
        <StyledSwitch
            onChange={handleChange}
            checked={checked}
            borderRadius={1}
            uncheckedIcon={false}
            checkedIcon={false}
            offHandleColor={'#cccccc'}
            onHandleColor={'#fca558'}
            onColor={'#FF7800'}
            className={'ml-3'}
            uncheckedHandleIcon={<span className="icon-close icon-filter-white ml-1" />}
            checkedHandleIcon={<span className="icon-check icon-filter-white ml-1" />}
        />
    );
};

export default SwitchButton;
