import { DivOverlay as LeafletDivOverlay, Marker, DomUtil, DomEvent } from 'leaflet';
import { withLeaflet, DivOverlay } from 'react-leaflet';
import { connect } from 'react-redux';
import { getMakerSize } from '@components/Map/Components/AniMarker';
import markerDefaultImg from '../../../../assets/images/marker_green_10.png';

class NearbyMarkerList extends DivOverlay {
    // 마커 라벨 높이
    HEIGHT_MARKER_LABEL = 18;

    // 근처 마커 계산 실행 디바운스
    calcTimeout = null;

    // 마커 리스트 제거 타임아웃
    closeTimeout = null;

    clearCalcTimeout() {
        clearTimeout(this.calcTimeout);
        this.calcTimeout = null;
    }

    setAutoCloseTimeout(timeout = 1000) {
        this.closeTimeout = setTimeout(() => {
            this.leafletElement.remove();
        }, timeout);
    }
    clearAutoCloseTimeout() {
        clearTimeout(this.closeTimeout);
        this.closeTimeout = null;
    }

    isMarker(className = '') {
        return (
            className.includes &&
            (className.includes('leaflet-marker-icon') ||
                className.includes('marker-img') ||
                className.includes('marker-label'))
        );
    }

    makeItemHTML({ targetNum, targetName, categoryImg }) {
        let img = `<img alt="markerCategoryImage" src="${categoryImg}" onerror="this.src='${markerDefaultImg}'">`;
        if (this.props.withoutIcon) {
            img = '';
        }
        const label = `<span>${targetName}</span>`;
        return `<div class="nearby-marker-item" data-target-num="${targetNum}" title="${targetName}">${img}${label}</div>`;
    }

    mousemoveHandler(e) {
        const { target } = e.originalEvent;
        if (!target) {
            this.clearCalcTimeout();
            return;
        }
        if (!this.isMarker(target.className)) {
            this.clearCalcTimeout();
            return;
        }
        const el = this.leafletElement;
        if (el.isOpen()) {
            return;
        }
        this.calcTimeout = setTimeout(() => {
            const { leaflet, markerConfigValue, categoryToImg } = this.props;
            const { map } = leaflet;
            const { defaultSize, minSize, maxSize } = markerConfigValue;
            const checkDistance = getMakerSize(map.getZoom(), defaultSize, minSize, maxSize) + this.HEIGHT_MARKER_LABEL;

            const currentLatLng = e.latlng;
            const innerHTML = [];
            map.eachLayer(layer => {
                if (layer instanceof Marker) {
                    const { data } = layer.options;
                    if (!data || !data.targetNum) {
                        return;
                    }
                    const { targetNum, targetName, categoryCode } = data;
                    if (
                        map.latLngToLayerPoint(currentLatLng).distanceTo(map.latLngToLayerPoint(layer.getLatLng())) <
                        checkDistance
                    ) {
                        innerHTML.push(
                            this.makeItemHTML({
                                targetNum,
                                targetName,
                                categoryImg: categoryToImg[categoryCode],
                            }),
                        );
                    }
                }
            });

            if (innerHTML.length > 1) {
                el.addTo(map);
                el.setLatLng(currentLatLng);
                el.setContent(innerHTML.join(''));
                el.bringToFront();
                el._container.onwheel = DomEvent.stopPropagation;
                el._container.querySelectorAll('.nearby-marker-item').forEach(itemNode => {
                    itemNode.onclick = function (e) {
                        const { targetNum } = e.currentTarget.dataset;
                        map.eachLayer(layer => {
                            if (layer instanceof Marker && layer.options?.data?.targetNum === Number(targetNum)) {
                                setTimeout(() => {
                                    layer.fireEvent('click');
                                }, 50);
                                el.remove();
                            }
                        });
                    };
                });
            }
        }, 100);
    }

    createLeafletElement(props) {
        const _this = this;
        const map = props.leaflet.map;
        const NearbyMarkerList = LeafletDivOverlay.extend({
            options: {
                interactive: true,
                className: 'nearby-marker-list',
                markerList: [],
                withoutIcon: false,
            },
            onAdd: function (map) {
                this._container = DomUtil.create('div', this.options.className);
                this._contentNode = this._container;
                map.getPanes().popupPane.appendChild(this._container);
                // this.update();
                this.bringToFront();
                this._container.onmouseenter = function (e) {
                    _this.clearAutoCloseTimeout();
                };
                this._container.onmouseout = function (e) {
                    const targetElement = e.toElement || e.relatedTarget;
                    if (
                        targetElement &&
                        (targetElement.parentNode?.parentNode === this ||
                            targetElement.parentNode === this ||
                            targetElement === this)
                    ) {
                        _this.clearAutoCloseTimeout();
                        return;
                    }
                    _this.setAutoCloseTimeout();
                };
                _this.setAutoCloseTimeout(2000);
                return this._container;
            },
            _updateLayout: function () {
                const container = this._contentNode;
                const style = container.style;

                style.width = '';
                style.whiteSpace = 'nowrap';

                let width = container.offsetWidth;
                width = Math.min(width, this.options.maxWidth);
                width = Math.max(width, this.options.minWidth);

                style.width = width + 1 + 'px';
                style.whiteSpace = '';

                style.height = '';

                const height = container.offsetHeight;
                const maxHeight = this.options.maxHeight;
                const scrolledClass = 'leaflet-popup-scrolled';

                if (maxHeight && height > maxHeight) {
                    style.height = maxHeight + 'px';
                    DomUtil.addClass(container, scrolledClass);
                } else {
                    DomUtil.removeClass(container, scrolledClass);
                }

                this._containerWidth = this._container.offsetWidth;
            },
            // 팬 조정 - 팬을 조정할 필요는 없으나 setLatLng, update에서 해당 함수 호출
            _adjustPan: function () {},
        });
        const el = new NearbyMarkerList({
            withoutIcon: !!this.props.withoutIcon,
        });
        map.on('mousemove', this.mousemoveHandler, this);

        return el;
    }

    componentWillUnmount() {
        this.clearCalcTimeout();
        this.clearAutoCloseTimeout();

        const el = this.leafletElement;
        if (!el) {
            return;
        }
        Object.keys(this._leafletEvents).forEach(ev => {
            el.off(ev, this._leafletEvents[ev]);
        });

        const map = this.props.leaflet.map;
        if (!map) {
            return;
        }
        map.off('mousemove', this.mousemoveHandler);
    }
}

const mapStateToProps = state => ({
    markerConfigValue: state.AppConfig.markerConfigValue,
    categoryToImg: state.CategoryInfo.categoryToImg,
});

export default connect(mapStateToProps)(withLeaflet(NearbyMarkerList));
