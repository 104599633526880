import { getRentalDetail } from '@api/am/management';
import { Modal, Table, Label } from '@components';
import { useAsync, useTranslation } from '@util/hooks';
import moment from 'moment';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { checkRentalStatusResponse } from '../utils/check';

const AssetDetailModal = ({ initModal, toggle, applyNum }) => {
    const t = useTranslation('AssetManagement');

    const { state } = useAsync({
        promise: getRentalDetail,
        immediate: true,
        param: {
            applyNum,
        },
    });

    const {
        categoryName,
        targetName,
        targetId,
        ownDepName,
        applyDepName,
        giveUserName,
        giveUserID,
        applyUserName,
        applyUserID,
        regDate,
        requestStartDate,
        requestEndDate,
        giveDate,
        takeUserName,
        takeUserID,

        status,
        approveDate,
    } = state.response ?? {};

    const columns = useMemo(
        () => [
            {
                Header: t('Category Name'),
                accessor: 'categoryName',
                Cell: ({ value }) => <div>{value ?? '-'}</div>,
            },
            {
                Header: t('Asset Name'),
                accessor: 'targetName',
                Cell: ({ value }) => <div>{value ?? '-'}</div>,
            },
            {
                Header: t('Asset ID'),
                accessor: 'targetId',
                Cell: ({ value }) => <div>{value ?? '-'}</div>,
            },
        ],
        [t],
    );

    const rows = [
        {
            categoryName,
            targetName,
            targetId,
        },
    ];

    return (
        <Modal
            className="modal-lg"
            headerTitle={t('Rental Detail')}
            removeCancel
            initModal={initModal}
            toggleModal={toggle}
            bodyText={
                <div>
                    <TableWrapper className="mb-3">
                        <Table
                            data={{
                                rows,
                            }}
                            columns={columns}
                            paging={false}
                        />
                    </TableWrapper>
                    <div className="d-flex">
                        <div className="w-50">
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Owner Ward')}
                                value={<span>{ownDepName}</span>}
                            />
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Rental Ward')}
                                value={<span>{applyDepName}</span>}
                            />
                        </div>
                        <div className="w-50">
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Supplier')}
                                value={<span>{giveUserName ?? '-'}</span>}
                            />
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Supplier ID')}
                                value={<span>{giveUserID ?? '-'}</span>}
                            />
                        </div>
                    </div>
                    <div className="pnt-divider horizon-line border-gray opacity-2 mb-2" />
                    <div className="d-flex">
                        <div className="w-50">
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Applicant')}
                                value={<span>{applyUserName}</span>}
                            />
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Applicant ID')}
                                value={<span>{applyUserID ?? '-'}</span>}
                            />
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Request Date')}
                                value={<span>{moment.unix(regDate).format('YYYY-MM-DD HH:mm:ss')}</span>}
                            />
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Request Rental Period')}
                                value={
                                    <span>
                                        {requestStartDate ? moment.unix(requestStartDate).format('YYYY-MM-DD') : '-'} ~{' '}
                                        {requestEndDate ? moment.unix(requestEndDate).format('YYYY-MM-DD') : '-'}
                                    </span>
                                }
                            />
                        </div>
                        <div className="w-50">
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Receive Date')}
                                value={
                                    <span>{giveDate ? moment.unix(giveDate).format('YYYY-MM-DD HH:mm:ss') : '-'}</span>
                                }
                            />
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Receiver')}
                                value={<span>{takeUserName ?? '-'}</span>}
                            />
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Receiver ID')}
                                value={<span>{takeUserID ?? '-'}</span>}
                            />
                        </div>
                    </div>
                    <div className="pnt-divider horizon-line border-gray opacity-2 mb-2" />
                    <div className="d-flex">
                        <div>
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Rental Status')}
                                value={<span>{t(checkRentalStatusResponse(status))}</span>}
                            />
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Approve/Reject/Cancel Date')}
                                value={
                                    <span>
                                        {status === 'apply'
                                            ? '-'
                                            : `${t('Approve')}: ${moment
                                                  .unix(approveDate)
                                                  .format('YYYY-MM-DD HH:mm:ss')}`}
                                    </span>
                                }
                            />
                        </div>
                    </div>
                </div>
            }
        />
    );
};

const TableWrapper = styled.div`
    height: 4.3rem;
`;

export default AssetDetailModal;
