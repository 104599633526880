export const isValidData = (data: any[] | any[][]) => {
    if (data.length <= 0) {
        return false;
    }
    if (data.length === 1 && data[0].length <= 0) {
        return false;
    }

    return true;
};
