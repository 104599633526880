import React from 'react';
import StatusCount from './Components/StatusCount';
import StatusList from './Components/StatusList';
import { useOutpatientEquipmentContext } from './index';
import { useAsync } from '@hooks';
import { getOutpatientEquipmentCountApi, getOutpatientEquipmentListApi } from '@api/monitoring';

const DEFAULT_EQUIPMENT_COUNT = {
    total: 0,
    equipmentCountStat: {
        normal: 0,
        abnormal: 0,
    },
};
const DEFAULT_EQUIPMENT_LIST = {
    pageSize: 15,
    totalCount: 0,
    totalPage: 1,
    rows: [],
};
const OutpatientEquipmentContainer = ({ widgetInfo }) => {
    const { selectedOutpatientStatus, keyword, outpatientListParam } = useOutpatientEquipmentContext();

    const { state: outpatientEquipmentCountState } = useAsync({
        promise: getOutpatientEquipmentCountApi,
        immediate: true,
        param: outpatientListParam,
        reject: err => {
            console.error(err);
        },
        deps: [keyword],
    });

    const { state: outpatientEquipmentListState } = useAsync({
        promise: getOutpatientEquipmentListApi,
        immediate: true,
        param: outpatientListParam,
        reject: err => {
            console.error(err);
        },
        deps: [selectedOutpatientStatus, keyword],
    });

    const outpatientEquipmentCount = outpatientEquipmentCountState.response ?? DEFAULT_EQUIPMENT_COUNT;
    const outpatientEquipmentList = outpatientEquipmentListState.response ?? DEFAULT_EQUIPMENT_LIST;

    return (
        <div className={'w-100 h-100'}>
            <StatusCount outpatientCount={outpatientEquipmentCount} />
            <StatusList outpatientList={outpatientEquipmentList} />
        </div>
    );
};

export default OutpatientEquipmentContainer;
