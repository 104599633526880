export const category = ({ ...restProps } = {}) => {
    return {
        Header: 'Category',
        headerClassName: 'text-ellipsis',
        accessor: 'category',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const ward = ({ ...restProps } = {}) => {
    return {
        Header: 'Ward',
        headerClassName: 'text-ellipsis',
        accessor: 'department',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const speciality = ({ ...restProps } = {}) => {
    return {
        Header: 'Speciality',
        headerClassName: 'text-ellipsis',
        accessor: 'specialties',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const doctor = ({ ...restProps } = {}) => {
    return {
        Header: 'Doctor',
        headerClassName: 'text-ellipsis',
        accessor: 'doctor',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const id = ({ ...restProps } = {}) => {
    return {
        Header: 'Patient ID',
        headerClassName: 'text-ellipsis',
        accessor: 'patientRegNum',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const patientName = ({ ...restProps } = {}) => {
    return {
        Header: 'Patient Name',
        headerClassName: 'text-ellipsis',
        accessor: 'patientName',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const reservedDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Reserved Date',
        headerClassName: 'text-ellipsis',
        accessor: 'appointmentDate',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const inspectionDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Inspection Date',
        headerClassName: 'text-ellipsis',
        accessor: 'inspectionDate',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const inspectionTime = ({ ...restProps } = {}) => {
    return {
        Header: 'Inspection Time',
        headerClassName: 'text-ellipsis',
        accessor: 'inspectionTime',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const highestBloodPressure = ({ ...restProps } = {}) => {
    return {
        Header: 'Highest Blood Pressure',
        headerClassName: 'text-ellipsis',
        accessor: 'maxBloodPressure',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const lowestBloodPressure = ({ ...restProps } = {}) => {
    return {
        Header: 'Lowest Blood Pressure',
        headerClassName: 'text-ellipsis',
        accessor: 'minBloodPressure',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const heartRateBpm = ({ ...restProps } = {}) => {
    return {
        Header: 'Heart Rate(bpm)',
        headerClassName: 'text-ellipsis',
        accessor: 'heartbeat',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const heightCm = ({ ...restProps } = {}) => {
    return {
        Header: 'Height(cm)',
        headerClassName: 'text-ellipsis',
        accessor: 'height',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const weightKg = ({ ...restProps } = {}) => {
    return {
        Header: 'Weight(kg)',
        headerClassName: 'text-ellipsis',
        accessor: 'weight',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
