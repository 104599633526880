import React, { useState } from 'react';
import { Button, Label, Modal, Select, TextInput } from '@components';
import { useAsync, useTranslation } from '@hooks';
import cx from 'classnames';
import { ModalFooter } from 'reactstrap';
import { HAND_OVER } from '../Lend';
import { getUserByID, postRentalCheckOut, postRentalReturn } from '@api/am/management';
import { fetchIotItemList, getTargetListWithLocationApi } from '@api/common/asset';
import styled from 'styled-components';
import { fetchSelectedFloorInfo } from '@api/common/floor';
import useModal from '../../../util/useModal';

// PDA로 인계 처리가 이미 된 에러
const ALREADY_CHECKED_OUT_CODE = 8001;
// PDA로 반납 처리가 이미 된 에러
const ALREADY_RETURNED_CODE = 8005;

const MINIMUN_SEARCH_LENGTH = 5;

const RentalActionModal = ({ initModal, toggle, rentalInfo, getRentalList }) => {
    const t = useTranslation('AssetManagement');
    const { type, targetName, categoryCode, applyNum, ownDepNum, applyDepNum } = rentalInfo;
    const isHandOver = type === HAND_OVER;
    const [isErrorOpen, handleErrorToggle, handleErrorOpen] = useModal();

    // 대여자산 목록
    const [selectedAsset, setSelectedAsset] = useState(isHandOver ? null : targetName);
    const { state: assetListState } = useAsync({
        promise: fetchIotItemList,
        immediate: !selectedAsset,
        param: {
            categoryCodes: categoryCode,
            targetPropertiesSearch: {
                categoryCode: 'ASSET',
                propertySearchs: [
                    {
                        propertyId: 'ward',
                        values: ownDepNum,
                    },
                ],
            },
            rentalAvailable: 'Y',
        },
    });
    const assetListResponse = assetListState.response ? assetListState.response.rows : [];
    const assetListOptions = assetListResponse.map(({ targetNum, targetName, targetId, floorId }) => ({
        value: targetNum,
        label: targetName,
        targetId,
        floorId,
    }));

    // 대여자산 위치정보: 건물 층
    const { state: floorState } = useAsync({
        promise: fetchSelectedFloorInfo,
        immediate: selectedAsset && selectedAsset.floorId,
        param: { floorId: selectedAsset?.floorId },
        deps: [selectedAsset],
    });
    const { floorNamePath = '' } = floorState.response || {};

    // 대여자산 위치정보: 지오펜스명
    const { state: geofenceState } = useAsync({
        promise: getTargetListWithLocationApi,
        immediate: selectedAsset,
        param: { target: selectedAsset?.targetId },
        deps: [selectedAsset],
    });
    const { geofences } = geofenceState.response?.rows[0] || {};
    const { fcName = '' } = geofences || {};

    // 인수자, 반납자 조회
    const [searchTarget, setSearchTarget] = useState(null);
    const { state: userState, promise: getUser } = useAsync({
        promise: getUserByID,
        param: {
            userId: searchTarget,
            applyDepNum,
        },
    });
    const userList = userState.response ? userState.response.rows : [];
    const [selectedUser, setSelectedUser] = useState(null);

    // 인계 post
    const { promise: postHandOver } = useAsync({
        promise: postRentalCheckOut,
        resolve: () => {
            getRentalList();
            toggle();
        },
        reject: ({ data }) => {
            const { code } = data;
            if (code === ALREADY_CHECKED_OUT_CODE) {
                handleErrorOpen();
            }
        },
    });

    // 반납 post
    const { promise: postReturn } = useAsync({
        promise: postRentalReturn,
        resolve: () => {
            getRentalList();
            toggle();
        },
        reject: ({ data }) => {
            const { code } = data;
            if (code === ALREADY_RETURNED_CODE) {
                handleErrorOpen();
            }
        },
    });

    const handleHandOverClick = () => {
        if (isHandOver) {
            postHandOver({
                applyNum,
                takeUserID: selectedUser.userID,
                targetNum: selectedAsset.value,
                categoryCode,
                ownDepNum,
            });
            return;
        }
        postReturn({
            applyNum,
            returnUserID: selectedUser.userID,
            categoryCode,
        });
    };

    const handleSubmit = e => {
        e.preventDefault();

        if (searchTarget.length < MINIMUN_SEARCH_LENGTH) {
            return;
        }
        getUser();
        setSelectedUser(null);
    };

    return (
        <>
            <Modal
                headerTitle={t('Rental') + ' ' + t(type)}
                initModal={initModal}
                toggleModal={toggle}
                bodyText={
                    <div className={'w-100 flx-col gap-2'}>
                        <Label
                            name={t('Rental Asset')}
                            value={
                                isHandOver ? (
                                    <div className={'flx-col w-100'}>
                                        <Select
                                            options={assetListOptions}
                                            onChange={selected => setSelectedAsset(selected)}
                                            className={'w-80'}
                                            value={selectedAsset}
                                            placeholder={t('Select', 'Select')}
                                            isModalSelect
                                        />
                                    </div>
                                ) : (
                                    <TextInput
                                        inputGroupClassName={'w-100'}
                                        className={'w-80'}
                                        disabled
                                        value={selectedAsset}
                                    />
                                )
                            }
                        />
                        {isHandOver && selectedAsset && (
                            <Label
                                value={
                                    <span className={cx('pnt-txt s-9', 'color-primary')}>
                                        {floorNamePath.split('>').join(' ') + ' ' + fcName}
                                    </span>
                                }
                            />
                        )}
                        <Label
                            name={isHandOver ? t('Receiver') : t('Returner')}
                            value={
                                <form className={'w-100 flx-col'} onSubmit={handleSubmit}>
                                    <div className={'flx-row w-80 gap-2'}>
                                        <TextInput
                                            inputGroupClassName={'w-100'}
                                            className={'w-100'}
                                            handleChange={e => setSearchTarget(e.target.value)}
                                            value={searchTarget ?? ''}
                                        />
                                        <Button type="submit" className="btn-gray">
                                            {t('Search', 'Search')}
                                        </Button>
                                    </div>
                                </form>
                            }
                        />
                        <Label
                            value={
                                selectedUser ? (
                                    <span className="color-primary">
                                        {selectedUser.wardName} {selectedUser.userName}({selectedUser.empNum})
                                    </span>
                                ) : userList.length >= 1 ? (
                                    <UserListContainer className="pnt-txt s-9">
                                        {userList.map(user => (
                                            <li
                                                key={user.empNum}
                                                onClick={() => {
                                                    setSelectedUser(user);
                                                    setSearchTarget(user.empNum);
                                                }}
                                            >
                                                {user.empNum}({user.wardName})
                                            </li>
                                        ))}
                                    </UserListContainer>
                                ) : (
                                    <span>{t('No search results.')}</span>
                                )
                            }
                        />
                    </div>
                }
                modalFooter={
                    <ModalFooter className="justify-content-center">
                        <Button
                            className={'btn-secondary'}
                            onClick={handleHandOverClick}
                            disabled={!selectedAsset || !selectedUser}
                        >
                            {t('OK')}
                        </Button>
                        <Button className={'btn-gray'} onClick={toggle}>
                            {t('Cancel')}
                        </Button>
                    </ModalFooter>
                }
            />
            {isErrorOpen && (
                <Modal
                    initModal={isErrorOpen}
                    toggleModal={handleErrorToggle}
                    headerTitle={t('Notification')}
                    bodyText={t('This is an asset that has already been processed.')}
                    removeCancel
                    okCallback={toggle}
                />
            )}
        </>
    );
};

const UserListContainer = styled.ul`
    overflow-y: scroll;
    max-height: 10rem;
    width: 80%;

    & > li {
        display: flex;
        align-items: center;
        height: 2rem;
        margin-bottom: 0.25rem;
        border-bottom: 1px solid #b9b9b9;
        cursor: pointer;
    }
`;

export default RentalActionModal;
