import React, { createContext, useContext } from 'react';
import ContextProvider from '@components/ContextProvider';
import RefrigeratorSensorContainer from './RefrigeratorSensorContainer';
import refrigeratorSensorSlice from './refrigeratorSensorSlice';

export const RefrigeratorSensorStateContext = createContext(null);
export const RefrigeratorSensorDispatchContext = createContext(null);

export const useRefrigeratorSensorContext = () => useContext(RefrigeratorSensorStateContext);
export const useRefrigeratorSensorDispatch = () => useContext(RefrigeratorSensorDispatchContext);

const RefrigeratorSensor = ({ children, widgetInfo, ...restProps }) => {
    return (
        <ContextProvider
            StateContext={RefrigeratorSensorStateContext}
            DispatchContext={RefrigeratorSensorDispatchContext}
            slice={refrigeratorSensorSlice}
        >
            <RefrigeratorSensorContainer widgetInfo={widgetInfo} children={children} {...restProps} />
        </ContextProvider>
    );
};

export default RefrigeratorSensor;
