import React from 'react';
import { CardHeaderProps, InnerItemCardProps } from '../../type';

const DEFAULT_HEADER_PROPS: CardHeaderProps = {
    action: undefined,
    className: '',
    subTitle: '',
    title: '',
};
const InnerItemCard = ({
    className = '',
    bodyClassName = '',
    header = DEFAULT_HEADER_PROPS,
    children,
}: InnerItemCardProps): JSX.Element => {
    const { action, className: headerClassName, subTitle, title } = header;

    return (
        <div className={`inner-item ${className}`}>
            {title && (
                <div className="item-header">
                    <div className={`item-header__title ${headerClassName}`}>
                        {subTitle && <p>{subTitle}</p>}
                        <h3>{title}</h3>
                    </div>
                    {action && <div className={'item-header__function'}>{action}</div>}
                </div>
            )}

            <div className={`item-body ${bodyClassName}`}>{children}</div>
        </div>
    );
};

export default InnerItemCard;
