export const SIGNAL_STATUS = {
    NORMAL: 'Normal',
    OUT_OF_AUTHORIZED_ZONE: 'Out of Authorized Zone',
    LOST_SIGNAL: 'Lost Signal',
    LOW_BATTERY: 'Low Battery',
    NO_RESPONSE: 'No Response',
};
export const TITLES = {
    ASSET: 'Asset List',
    PATIENT: 'Patient List',
    PROTECTOR: 'Protector List',
};

export const TRUE_FLAG = 'Y';
export const AREA_KEY = 'area';
export const DEFAULT_PARAMETERS = {
    pageSize: 15,
    page: 1,
    // 자산 태그 매핑 포함 여부 (포함 : Y, 미포함 : N)
    isIncludeConfig: TRUE_FLAG,
};

export const DEFAULT_IoT_COUNT = {
    totalCount: 0,
    restrictedCount: 0,
    lostSignalCount: 0,
    lowBatteryCount: 0,
    neverDetectedCount: 0,
};

export const DEFAULT_IoT_ITEM_LIST = {
    pageSize: 15,
    totalCount: 0,
    totalPage: 1,
    rows: [],
};

export const ASSET_CODE = 'ASSET';
