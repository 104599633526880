import React, { Dispatch, SetStateAction, useState } from 'react';
import { DEFAULT_CHART_OPTIONS, LINE_CHART_COLOR, WHITE_COLOR } from '../../constants';
import { mergeObjects } from '@util/common/util';
import getVitalSingsTooltip from '../../../../util/chart/plugins/vitalSingsTooltip';
import { LineChart, LoadingBlock } from '@components';
import { useAppSelector, useTranslation } from '@hooks';
import { ChartData, ChartLoading } from './VitalSignsView';
import { Chart } from 'react-chartjs-2';
import styles from '@asset/pnt/widgets/sh/ExamPatientsMonitoring.module.scss';
import PartialBackgroundColor from '@components/Charts/Plugins/PartialBackgroundColor';
import NoDataBlock from '../../../../Components/NoDataBlock';

type VitalSignsChartProps = {
    chartData: ChartData;
    isLoading?: boolean;
    setLoading?: Dispatch<SetStateAction<ChartLoading>>;
};

const VitalSignsChart = ({ chartData, isLoading, setLoading }: VitalSignsChartProps) => {
    const t = useTranslation('ExamPatientsMonitoring');
    const colorScheme = useAppSelector(state => state.ThemeOptions.colorScheme);
    const noData = !chartData.data.length && !chartData.activeTimes.length;

    return (
        <div>
            <h5 className={'pnt-txt txt-bold s-6 mb-1'}>{`${chartData.name} ${
                chartData?.unit ? '(' + chartData?.unit + ')' : ''
            }`}</h5>
            <div className={styles['line-chart-area']}>
                <LoadingBlock blocking={isLoading && !noData}>
                    {noData ? (
                        <NoDataBlock className={'no-data'} />
                    ) : (
                        <LineChart
                            data={{
                                datasets: [
                                    {
                                        data: chartData.data,
                                        label: chartData.name,
                                        forcedPointBackgroundColor: WHITE_COLOR,
                                        forcedBorderColor: LINE_CHART_COLOR,
                                        forcedPointBorderColor: LINE_CHART_COLOR,
                                        forcedBackgroundColor: LINE_CHART_COLOR,
                                    },
                                ],
                                labels: chartData.labels,
                            }}
                            options={mergeObjects(DEFAULT_CHART_OPTIONS, {
                                plugins: {
                                    ...getVitalSingsTooltip(colorScheme, t),
                                    loading: {
                                        afterDatasetsUpdate: (chart: Chart) => {
                                            if (chart.data.datasets[0]?.label) {
                                                if (typeof setLoading === 'function') {
                                                    setLoading((prev: ChartLoading) => {
                                                        return { ...prev, [chartData.sensorType]: false };
                                                    });
                                                }
                                            }
                                        },
                                    },
                                    partialBackgroundColor: chartData.activeTimes.map(activeTime => {
                                        return {
                                            axis: 'x',
                                            color: '#caf0ff',
                                            arrange: [activeTime.startTime * 1000, activeTime.endTime * 1000],
                                        };
                                    }),
                                },
                                scales: {
                                    y:
                                        chartData.min === 0 && chartData.max === 0
                                            ? { min: 0, max: 1 }
                                            : {
                                                  min: chartData.min - (chartData.max - chartData.min) * 0.2,
                                                  max: chartData.max + (chartData.max - chartData.min) * 0.2,
                                              },
                                },
                            })}
                            plugins={[PartialBackgroundColor]}
                        />
                    )}
                </LoadingBlock>
            </div>
        </div>
    );
};

export default React.memo(VitalSignsChart);
