import { getInstructionDownlaodApi } from '@api/sh/waste';
import { Button } from '@components';
import { useAsync, useTranslation } from '@hooks';
import React from 'react';
import { MEDICAL_WASTE } from '../../constants';

const InstructionDownloadButton = () => {
    const t = useTranslation(MEDICAL_WASTE);

    const { promise: downloadInstruction } = useAsync({
        promise: getInstructionDownlaodApi,
        resolve: res => {
            if (typeof res === 'string') {
                const url = res;
                window.open(url, '_blank');
                return;
            }
            alert('다운로드할 설명서가 없습니다.');
            return;
        },
        reject: err => console.error(err),
    });

    const handleInstructionsDownloadClick = () => {
        downloadInstruction();
    };

    return (
        <Button className="btn-lightgray btn-icon" onClick={handleInstructionsDownloadClick}>
            {t('Download Instruction')}
        </Button>
    );
};

export default InstructionDownloadButton;
