import React from 'react';
import useTranslation from '@hooks/useTranslation';
import cx from 'classnames';
import { checkLostSignal } from '@util/mappInfo';

const STATUS_COLOR = {
    OUTOFRANGE: 'color-secondary',
    CRITICAL: 'color-danger',
    WARNING: 'color-warning',
    NORMAL: 'color-success',
    LOSTSIGNAL: 'color-info',
    null: 'color-gray',
};
const DEFAULT_VALUE = 'N/A';
const LOST_SIGNAL = 'Lost signal';

const SensorInfo = ({ value, status, lostSignal }) => {
    const t = useTranslation('RefrigeratorSensor');
    const temp = value == null ? DEFAULT_VALUE : value.toFixed(2);
    const color = checkLostSignal(lostSignal) ? STATUS_COLOR.LOSTSIGNAL : STATUS_COLOR[status];
    const statusText = checkLostSignal(lostSignal) ? LOST_SIGNAL : status;
    return (
        <div className="flx-row flx-sb w-100">
            <span className={cx('pnt-txt txt-bold s-4', !status && 'color-gray')}>{temp}</span>
            <span className={`pnt-txt s-7 ${color}`}>{t(statusText)}</span>
        </div>
    );
};

export default SensorInfo;
