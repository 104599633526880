import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import useTranslation from '@hooks/useTranslation';
import Label from '@components/Label';
import { useVitalSignsRealTimePartronContext } from '../../index';

const DEFAULT_VALUE = '-';
const SideInfo = ({ data }) => {
    const t = useTranslation('VitalSignsRealTimePartron');
    const { wardNum } = useVitalSignsRealTimePartronContext();
    const { targetId, targetName, deviceId, lat, lng, properties, regDate } = data;

    const { room, bed } = properties;

    const targetInfo = [
        { key: 'Patient Name', value: targetName },
        { key: 'Patient Number', value: targetId },
        { key: 'Ward/Department', value: wardNum || null },
        { key: 'Room', value: room || null },
        { key: 'Bed', value: bed || null },
    ];

    const sensorInfo = [
        { key: 'Sensor Name', value: deviceId || null },
        {
            key: 'Location',
            value: `${lat || DEFAULT_VALUE} / ${lng || DEFAULT_VALUE}`,
        },
        { key: 'Received Time', value: regDate ? moment(regDate * 1000).format('YYYY-MM-DD') : null },
    ];

    return (
        <Container>
            <DetailInfo>
                {targetInfo.map(({ key, value }) => (
                    <Label
                        key={key}
                        name={t(key)}
                        labelGroupClassName="mb-2"
                        labelValueClassName="label-dot"
                        value={value || DEFAULT_VALUE}
                    />
                ))}
            </DetailInfo>

            <DetailInfo>
                {sensorInfo.map(({ key, value }) => (
                    <Label
                        key={key}
                        name={t(key)}
                        labelGroupClassName="mb-2"
                        labelValueClassName="label-dot"
                        value={value || DEFAULT_VALUE}
                    />
                ))}
            </DetailInfo>
        </Container>
    );
};

const Container = styled.div`
    width: 16rem;
`;

const DetailInfo = styled.div`
    width: max-content;
    margin-bottom: 1.5rem;
`;

export default SideInfo;
