import React, { useEffect, useState } from 'react';
import NoDataBlock from '../../Components/NoDataBlock';
import { PieChart } from '@components/Charts';

const IotItemCountStatusChart = ({ data }) => {
    const [chartData, setChartData] = useState({ datasets: [] });
    useEffect(() => {
        setChartData({
            labels: data.map(({ name }) => name),
            datasets: [{ data: data.map(({ value }) => value) }],
        });
    }, [data]);
    return !data.length ? <NoDataBlock /> : <PieChart data={chartData} />;
};

export default IotItemCountStatusChart;
