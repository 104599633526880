import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useSettings = config => {
    const [settings, setSettings] = useState({ ...config.defaultSettings, ...config.settings });
    const { globalIntervalTime } = useSelector(state => state.GlobalIntervalTime);
    useEffect(() => {
        const mergedSetting = { ...config.defaultSettings, ...config.settings };
        // interval time 설정이 존재 할 경우 => global time 설정 적용
        if (!!mergedSetting.intervalTime) {
            mergedSetting.intervalTime = globalIntervalTime;
        }

        setSettings(mergedSetting);
    }, [config, globalIntervalTime]);
    return settings;
};
