import React, { createContext, useEffect, useReducer, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAsync, useTranslation } from '@util/hooks';
import { setLanguage, setOauthInfo } from '@reducer/UserInfo';
import { fetchApiLogin, fetchOAuthToken } from '@api/common/login';
import Button from '../../Common/Button';
import language from '@util/staticData/language.json';
import { initialState, reducer, setShowErrorMsg } from './reducer';
import cx from 'classnames';
import Select from '@components/Select';

// For Further CSS
import { decodeInfo, checkExpire } from '@util/common/util';
import LoginForm from './Components/LoginForm';
import FindForm from './Components/FindForm';
import ChangeForm from './Components/ChangeForm';
import CompleteForm from './Components/CompleteForm';
import useMoreUserInfo from './hooks/useMoreUserInfo';
import { A_PATH_LOGIN, A_PATH_ROOT } from '../Components/Router/path';
import { getApiURL } from '@api/index';
import VersionLabel from '../Components/VersionLabel';

export const LoginStateContext = createContext();
export const LoginDispatchContext = createContext();

const FormByStep = ({ step }) => {
    switch (step) {
        case 'LOGIN':
            return <LoginForm />;
        case 'FIND':
            return <FindForm />;
        case 'CHANGE':
            return <ChangeForm />;
        case 'COMPLETE':
            return <CompleteForm />;
        default:
            return <LoginForm />;
    }
};

const Login = props => {
    const storeDispatch = useDispatch();
    const t = useTranslation('Login');
    const [state, dispatch] = useReducer(reducer, initialState);
    const { step } = state;
    const [showMobileLogin, setShowMobileLogin] = useState(false);

    const history = useHistory();
    const location = useLocation();

    const { oAuthInfo: ssoOAuthInfo } = useParams();

    const { userInfo, lang } = useSelector(state => state.UserInfo);

    const { promise: initialLogin } = useAsync({
        promise: fetchApiLogin,
        resolve: res => {
            // 2. 로그인 인포가 보내지고 access token이 받아져왔을때
            if (res.scope) {
                storeDispatch(setOauthInfo(res));
                getMoreUserInfo();
            }
        },
        reject: err => {
            dispatch(setShowErrorMsg(true));
        },
    });

    const { promise: oAuthLogin } = useAsync({
        promise: fetchOAuthToken,
        resolve: res => {
            // 2. 로그인 인포가 보내지고 access token이 받아져왔을때
            if (res.scope) {
                storeDispatch(setOauthInfo(res));
                getMoreUserInfo();
            }
        },
        reject: err => {
            dispatch(setShowErrorMsg(true));
        },
    });

    const getMoreUserInfo = useMoreUserInfo();

    // 5. 유저 이름, 유저가 속한 회사 정보가 받아와진 경우 실행
    useEffect(() => {
        if (userInfo && userInfo.companyInfo && userInfo.companyInfo.timeZone) {
            // 대쉬보드의 홈설정이 되어있는경우
            if (userInfo.home) {
                history.replace(`/dashboards/${userInfo.home}`);
            } else if (location.state?.redirectPath) {
                history.replace(location.state.redirectPath);
            }
            // 대쉬보드의 홈설정이 되어있지 않은경우
            else {
                history.replace(A_PATH_ROOT);
            }
        } else {
            history.replace({ pathname: A_PATH_LOGIN, state: location.state });
        }
    }, [userInfo]);

    useEffect(() => {
        getApiURL().then(key => {
            if (ssoOAuthInfo) {
                try {
                    const decodedInfo = decodeInfo(atob(ssoOAuthInfo));
                    if (key.hasOwnProperty('companyUUID') && key.hasOwnProperty('companyToken')) {
                        initialLogin({
                            refreshToken: decodedInfo.refresh_token,
                        });
                    } else {
                        oAuthLogin({
                            grant_type: 'refresh_token',
                            refresh_token: decodedInfo.refresh_token,
                        });
                    }
                } catch (e) {
                    console.log(e);
                }
            } else {
                // 추가 인증요청 없이 로그인 페이지 진입 시,
                // localStorage 인증정보가 유효하다면 바로 앱 설정 정보 호출 후 메인 페이지로 이동
                if (checkExpire()) {
                    getMoreUserInfo();
                }
            }
        });
    }, [ssoOAuthInfo]);

    // 모바일 창에서 로그인 버튼 클릭시 아이디 패스워드를 입력할 수 있는 창으로 변경
    const handleMobileLoginClick = e => {
        e.preventDefault();
        setShowMobileLogin(!showMobileLogin);
    };

    // 언어 변환
    const handleLangChange = selectedOption => {
        storeDispatch(setLanguage(selectedOption.value));
    };

    return (
        <LoginDispatchContext.Provider value={dispatch}>
            <LoginStateContext.Provider value={state}>
                <div className="page-landering">
                    <div className={`page-landering__cont-wrap ${showMobileLogin && 'login_clicked'}`}>
                        {/* 모바일용 헤더 */}
                        <div className={`lander-mobile-header`}>
                            <div className="mobile-header__wrap">
                                <div className="logo-box">
                                    <div className="pnt-logo logo-o-w" />
                                </div>
                                <div className="language-box">
                                    <Select
                                        value={language.find(v => v.value === lang)}
                                        options={language}
                                        onChange={handleLangChange}
                                        iconName="language"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* 데스크용 */}
                        <div className={`lander-info`}>
                            <div className="lander-info__wrap">
                                <div className="info--logo">
                                    <div className="pnt-logo logo-b-b" />
                                </div>
                                <div className="info--txt">
                                    <h2 className="txt__main">Smart Hospital</h2>
                                    <b className="txt__sub">{t('Efficiency starts with knowing where things are.')}</b>
                                    <p className="txt__desc">
                                        {t(
                                            "IndoorPlus+ provides you objective data for your next decision and action. Upon customer's request, customized dashboards are created to fit even more detailed customers' requirements and this is how our customers design their own way to manage their assets. Looking for something? Let IndoorPlus+ assist you.",
                                        )}
                                    </p>
                                </div>
                                <div className="info--language">
                                    <div className={'mt-3'}></div>
                                    <Select
                                        value={language.find(v => v.value === lang)}
                                        options={language}
                                        onChange={handleLangChange}
                                        iconName="language"
                                    />
                                </div>
                                <div className="info--login-btn">
                                    <Button
                                        className={'btn-brand btn-icon btn-animation-move'}
                                        iconName="arrow_forward_ios"
                                        onClick={handleMobileLoginClick}
                                    >
                                        {t('Login')}
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className={`lander-login`}>
                            <div
                                className={cx(
                                    'lander-login__wrap flx-col',
                                    step === 'CHANGE' && 'flx-full pnt-label-6 label-col-mobile',
                                )}
                            >
                                <FormByStep step={step} />
                            </div>
                        </div>
                    </div>
                    <div className="page-landering__bg" />
                    <VersionLabel className={'pnt-txt position-lb m-2'} />
                </div>
            </LoginStateContext.Provider>
        </LoginDispatchContext.Provider>
    );
};

export default Login;
