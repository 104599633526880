import { reqDelete, reqFormDataPost, reqGet, reqPost } from '../index';

// 의료폐기물 대시보드 조회
export const getWasteDashboardApi = param => reqGet('/v3/api/sh01/waste/dashboard', param);

// 의료폐기물 처리 현황
export const getWasteTreatmentStatusApi = param => reqGet('/v3/api/sh01/waste/disposal-status', param);
// 의료폐기물 처리 현황 엑셀 다운로드
export const postTreatmentExcelApi = ({ columnMetas, zipFileName, fileName, isDateFormat, ...restParam }) =>
    reqPost(
        `/v3/api/sh01/waste/disposal-status/export`,
        {
            columnMetas,
            zipFileName,
            fileName,
        },
        restParam,
    );

// 설명서 관리 조회
export const getInstructionListApi = param => reqGet('/v3/api/sh01/waste/manual', param);
// 설명서 관리 등록
export const postInstructionApi = param => reqFormDataPost('/v3/api/sh01/waste/manual', param);
// 설명서 관리 삭제
export const deleteInstructionListApi = param => reqDelete(`/v3/api/sh01/waste/manual/${param.fileNumList}`);

// 수거 및 수거 가능 수량 조회
export const getCollectionStatusListApi = param => reqGet('/v3/api/sh01/waste/collection-status', param);

// 리딩상태 조회
export const getReadingStatusApi = param => reqGet('/v3/api/sh01/waste/reading-status', param);
// 리딩상태 엑셀 다운로드
export const postReadingExcelApi = ({ columnMetas, zipFileName, fileName, isDateFormat, ...restParam }) =>
    reqPost(
        `/v3/api/sh01/waste/reading-status/export`,
        {
            columnMetas,
            zipFileName,
            fileName,
        },
        restParam,
    );

// 격리 및 일반 환자 현황 조회
export const getPatientStatusApi = param => reqGet('/v3/api/sh01/waste/patient-status', param);
// 격리 및 일반 환자 현황 엑셀 다운로드
export const postPatientStatusExcelApi = ({ columnMetas, zipFileName, fileName, isDateFormat, ...restParam }) =>
    reqPost(
        '/v3/api/sh01/waste/patient-status/export',
        {
            columnMetas,
            zipFileName,
            fileName,
        },
        restParam,
    );

// 설명서 다운로드
export const getInstructionDownlaodApi = param => reqGet('/v3/api/sh01/waste/manual/download', param);
