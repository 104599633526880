import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    selectedOutpatientCareStatus: null,
    keyword: undefined,
    selectedOutpatient: null,
    outpatientList: {
        totalCount: 0,
        totalPage: 1,
        rows: [],
    },
    outpatientBloodListParam: {
        pageSize: 15,
        page: 1,
        appointmentOption: null,
        // department: null,
        keyword: undefined,
    },
    outpatientCareCount: {
        total: 0,
        nonProcessingStat: {
            waitingInspect: 0,
            nonArrival: 0,
        },
        inspectCountStat: {
            doneInspect: 0,
            nonDoneInspect: 0,
        },
    },
};

const outpatientBloodDrawStatusSlice = createSlice({
    name: 'outpatientBloodDrawStatus',
    initialState,
    reducers: {
        setSelectedOutpatientStatus: (state, action) => {
            const selectedStatus = action.payload;
            if (state.selectedOutpatientCareStatus === selectedStatus) {
                return;
            }
            state.selectedOutpatientCareStatus = selectedStatus;
            state.outpatientBloodListParam = {
                ...state.outpatientBloodListParam,
                appointmentOption: state.selectedOutpatientCareStatus,
            };
        },
        setSearchOutpatient: (state, action) => {
            const keyword = action.payload ? action.payload : undefined;

            state.keyword = keyword;
            state.outpatientBloodListParam = {
                ...state.outpatientBloodListParam,
                keyword: state.keyword ?? undefined,
            };
        },
        setSelectedOutpatient: (state, action) => {
            const selectedOutpatient = action.payload;
            if (!selectedOutpatient) {
                state.selectedOutpatient = null;
                return;
            }
            state.selectedOutpatient = selectedOutpatient;
        },
        setOutpatientListParam: (state, action) => {
            state.outpatientBloodListParam = action.payload;
        },
        clearOutpatientCare: (state, action) => {
            state.keyword = '';
            state.selectedOutpatientCareStatus = null;
            state.outpatientBloodListParam = {
                ...state.outpatientBloodListParam,
                keyword: undefined,
                appointmentOption: null,
            };
        },
    },
});

export const {
    setSearchOutpatient,
    setSelectedOutpatientStatus,
    setOutpatientListParam,
    setSelectedOutpatient,
    clearOutpatientCare,
} = outpatientBloodDrawStatusSlice.actions;

export default outpatientBloodDrawStatusSlice;
