import React from 'react';
import { useTranslation } from '@hooks';
import CountUp from 'react-countup';
import { setSelectedOutpatientStatus } from '../../OutpatientEquipmentStatusSlice';
import { useOutpatientEquipmentContext, useOutpatientEquipmentDispatch } from '../../index';

const DATA_LABEL = {
    total: {
        value: null,
        label: 'All Equipments',
    },
    normalEquipment: { value: 'normalEquipment', label: 'Normal Equipments' },
    abnormalEquipment: { value: 'abnormalEquipment', label: 'Abnormal Equipments' },
};

const ItemCount = ({ start = 0, end = 0, label }) => {
    const t = useTranslation('OutpatientEquipmentStatus');
    const { selectedOutpatientStatus } = useOutpatientEquipmentContext();
    const dispatch = useOutpatientEquipmentDispatch();

    const handleLabelClick = () => {
        dispatch(setSelectedOutpatientStatus(DATA_LABEL[label].value));
    };

    const selectedStyleClass = selectedOutpatientStatus === DATA_LABEL[label].value ? 'selected' : 'nonSelected';

    return (
        <div className={`flex-center col ${selectedStyleClass}`} onClick={handleLabelClick}>
            <CountUp
                className="item-num"
                decimals={0}
                start={start}
                end={end}
                decimal=","
                duration="1"
                useEasing={false}
                separator=""
                prefix=""
                suffix=""
            />
            <div className={'flx-row'}>
                {t(DATA_LABEL[label].label)}
                {selectedOutpatientStatus === DATA_LABEL[label].value ? (
                    <span className="material-icons-round">arrow_drop_down</span>
                ) : (
                    <span className="material-icons-round">arrow_right</span>
                )}
            </div>
        </div>
    );
};

export default ItemCount;
