import React, { useEffect, useRef } from 'react';
import { useColumns } from '@hooks';
import * as column from '@util/grid/column';
import useTranslation from '@hooks/useTranslation';
import { ASSET_MANAGEMENT } from '../../constants';
import Table from '@components/Table';
import NumberInput from '@components/Input/NumberInput';
import moment from 'moment';
import { DEFAULT_VALUE } from './constants';
import Quantity from './Quantity';
import { DATE_FORMAT } from '@util/times';

const QuantityList = ({ data, handleRefresh, currentWorkTimeType, workTimeSet, selectedDuty, isToday, targetDate }) => {
    const t = useTranslation(ASSET_MANAGEMENT);
    const columnT = useTranslation('CommonColumn');

    const changedRowsRef = useRef({});

    // table column data
    const columns = useColumns(
        [
            column.index({ width: 50 }),
            column.categoryName({ width: 270 }),
            column.wardName(),
            {
                Header: `${selectedDuty?.toLowerCase()} quantity`,
                headerClassName: 'text-ellipsis',
                classname: 'text-ellipsis py-0',
                width: 120,
                accessor: selectedDuty,
                Cell: ({ row }) => {
                    const original = row.original;
                    const { categoryCode, ward, count, totalCount, regDate, added } = original;
                    const isFirstEmptyValue = !changedRowsRef.current[categoryCode] && count == null;
                    const changedRowKey = categoryCode + ward;

                    // 최초 결과 값이 없을때
                    if (isFirstEmptyValue) {
                        changedRowsRef.current[changedRowKey] = {
                            categoryCode,
                            ward,
                            count: totalCount,
                            totalCount,
                            regDate,
                            added,
                        };
                    }

                    const handleQuantity = event => {
                        const value = event.target.value !== '' ? Number(event.target.value) : null;

                        // input value null 일 때
                        if (value == null) {
                            changedRowsRef.current[changedRowKey] && delete changedRowsRef.current[changedRowKey];
                        } else if (!changedRowsRef.current[changedRowKey]) {
                            // 담당 외의 점검 수량을 처음 수정할 때
                            changedRowsRef.current[changedRowKey] = {
                                categoryCode,
                                ward,
                                count: value,
                                totalCount,
                                regDate,
                                added,
                            };
                        } else if (value === original.count) {
                            // 점검 수량이 이전 점검 수량과 동일할 때
                            delete changedRowsRef.current[changedRowKey];
                        } else {
                            changedRowsRef.current[changedRowKey] = {
                                categoryCode,
                                ward,
                                count: value,
                                totalCount: totalCount,
                                regDate,
                                added,
                            };
                        }
                    };

                    return (
                        <div className="d-flex w-100 h-100 justify-content-center align-center p-0">
                            <NumberInput
                                inputGroupClassName={'w-50 min-w-none h-auto'}
                                className={'text-center py-1 pr-0'}
                                defaultValue={isFirstEmptyValue ? original.totalCount : original.count ?? 0}
                                min={0}
                                handleChange={handleQuantity}
                            />
                            <span className="w-10 px-1">/</span>
                            <div className="w-40">{original.totalCount ?? 0}</div>
                        </div>
                    );
                },
            },
            column.inspector({ accessor: 'checkerName' }),
            column.inspectorUsername({
                accessor: 'checkerId',
                width: 120,
                Cell: ({ value }) => {
                    if (!value) {
                        return DEFAULT_VALUE;
                    }
                    if (value.indexOf('@') < 0) {
                        return value;
                    }
                    return value.substring(0, value.indexOf('@'));
                },
            }),
            column.inspectionDate({
                accessor: 'checkDate',
                className: 'text-nowrap',
                Cell: ({ value }) => (value ? moment.unix(value).format(DATE_FORMAT.YMD_HMS) : DEFAULT_VALUE),
            }),
            {
                Header: t('Quantity lent'),
                accessor: 'rentalCount',
            },
        ],
        columnT,
        [data, currentWorkTimeType, selectedDuty],
    );

    const getChangedRows = () => changedRowsRef.current;

    useEffect(() => {
        // 데이터 정보 변경될 경우 초기화
        changedRowsRef.current = {};
    }, [data]);

    return (
        <Quantity
            data={data}
            targetDate={targetDate}
            isToday={isToday}
            workTimeSet={workTimeSet}
            selectedDuty={selectedDuty}
            getChangedRows={getChangedRows}
            handleRefresh={handleRefresh}
        >
            <Table columns={columns} data={{ rows: data }} paging={false} noDataMessage={t('No items to check.')} />
        </Quantity>
    );
};

export default QuantityList;
