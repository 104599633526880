import React, { useState } from 'react';
import styled from 'styled-components';
import { ModalFooter } from 'reactstrap';
import { Button, Label, Modal } from '@components';
import { useAsync, useTranslation } from '@hooks';
import { MEDICAL_WASTE } from '../../constants';
import useModal from '../../util/useModal';
import { postInstructionApi } from '@api/sh/waste';

const RegisterModal = ({ isOpen, toggleModal, getList }) => {
    const t = useTranslation(MEDICAL_WASTE);
    const [file, setFile] = useState(null);
    const [text, setText] = useState('');
    const [isConfirmOpen, handleConfirmToggle, handleConfirmOpen] = useModal(); // 설명서 등록
    const [isAlertOpen, handleAlertToggle, handleAlertmOpen] = useModal(); // 알림
    const [isCompletedOpen, handleCompletedToggle, handleCompletedOpen] = useModal(); // 설명서 등록 완료

    const handleFileChange = e => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const { promise: postInstruction } = useAsync({
        promise: postInstructionApi,
        resolve: () => {
            handleConfirmToggle();
            handleCompletedOpen();
            setFile(null);
            setText('');
            getList();
        },
        reject: error => {
            console.error(error);
        },
    });

    const handleRegisterClick = () => {
        if (!file) {
            handleAlertmOpen();
            return;
        }
        handleConfirmOpen();
    };

    const handleRegisterOkClick = () => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('note', text);

        postInstruction({ formData });
    };

    return (
        <>
            <Modal
                initModal={isOpen}
                toggleModal={toggleModal}
                headerTitle={t('Register Instructions')}
                modalFooter={
                    <ModalFooter>
                        <Button className={'btn-gray'} onClick={toggleModal}>
                            {t('Cancel', 'Button')}
                        </Button>
                        <Button className={'btn-secondary'} onClick={handleRegisterClick}>
                            {t('Register')}
                        </Button>
                    </ModalFooter>
                }
            >
                <div className="modal-body">
                    <div className={'flx-col gap-3 pnt-label-5'}>
                        <Label
                            name={t('Instructions(*)')}
                            value={
                                <div className="pnt-file--group w-100">
                                    <input type="file" id="dashboardFile" accept=".pdf" onChange={handleFileChange} />
                                    <label htmlFor="dashboardFile">
                                        <span className="material-icons-round">folder_copy</span>
                                        {t('Upload File', 'Button')}
                                    </label>
                                </div>
                            }
                        />
                        <Label
                            name={t('Note')}
                            value={
                                <Textarea
                                    className="w-100 border border-depth-7 bg-depth-3 p-2"
                                    value={text}
                                    rows={3}
                                    onChange={e => setText(e.target.value)}
                                    spellCheck={false}
                                />
                            }
                        />
                    </div>
                </div>
            </Modal>
            {isConfirmOpen && (
                <Modal
                    initModal={isConfirmOpen}
                    headerTitle={t('Register Instructions')}
                    toggleModal={handleConfirmToggle}
                    modalFooter={
                        <ModalFooter>
                            <Button className={'btn-gray'} onClick={handleConfirmToggle}>
                                {t('Cancel', 'Button')}
                            </Button>
                            <Button className={'btn-secondary'} onClick={handleRegisterOkClick}>
                                {t('Ok', 'Button')}
                            </Button>
                        </ModalFooter>
                    }
                >
                    <div className="modal-body">{t('Would you like to register the instructions file?')}</div>
                </Modal>
            )}
            {isAlertOpen && (
                <Modal initModal={isAlertOpen} toggleModal={handleAlertToggle} headerTitle={t('Notice')} removeCancel>
                    <div className="modal-body">{t('Instructions file is required.')}</div>
                </Modal>
            )}
            {isCompletedOpen && (
                <Modal
                    initModal={isCompletedOpen}
                    toggleModal={handleCompletedToggle}
                    headerTitle={t('Register Instructions Completed')}
                    cancelCallback={() => {
                        handleCompletedToggle();
                        toggleModal();
                    }}
                    modalFooter={
                        <ModalFooter>
                            <Button
                                className={'btn-secondary'}
                                onClick={() => {
                                    handleCompletedToggle();
                                    toggleModal();
                                }}
                            >
                                {t('Ok', 'Button')}
                            </Button>
                        </ModalFooter>
                    }
                >
                    <div className="modal-body">{t('The instructions file has been registered successfully.')}</div>
                </Modal>
            )}
        </>
    );
};

// TODO: 공통 Textarea 필요
const Textarea = styled.textarea`
    resize: none;
    outline: none;
`;

export default RegisterModal;
