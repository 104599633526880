import React, { useMemo, useRef, useState } from 'react';
import WidgetCard from '../../Components/WidgetCard';

import useMultiOptionsByRole from '../../am/util/useMultiOptionsByRole';
import Filter from '../../am/Components/Filter';
import { useAsync, useTranslation } from '@hooks';
import { DatePicker, SearchableSelect } from '@components';
import moment from 'moment';
import { getUsagesSensorApi, postUsagesSensorExcelApi } from '@api/common/log';
import UsageSensorList from './Components/UsageSensorList';
import { useAsyncExcel } from '../../util/useAsyncExcel';
import { columMetas, SENSOR_OPTION } from './constants';
import { WidgetProps } from '../../staticInfo';
import { OptionType } from '@components/type';
import { SensorReport, SensorReportType } from './types';
import { UnixTimestamp } from '@util/type/util';

const UsageSensorReport = ({ children, widgetInfo, ...restProps }: WidgetProps) => {
    const widgetRef = useRef(null);
    const { selectedWardList, options: wardOptions, handleWardChange } = useMultiOptionsByRole();
    const t = useTranslation('UsageSensorReport');
    const [selectSensorCategory, setSelectSensorCategory] = useState<any>(SENSOR_OPTION.map(v => v.value));
    const [date, setDate] = useState(moment().startOf('year').unix());
    const departments =
        selectedWardList.length === 0
            ? wardOptions?.map((v: OptionType) => v.value).toString()
            : selectedWardList.toString();

    const sensorCategoryOption = useMemo<OptionType[]>(
        () =>
            SENSOR_OPTION.map(v => ({
                label: t(v.label),
                value: v.value,
            })),
        [t],
    );

    const handleSensorCategory = (selected: any) => {
        setSelectSensorCategory(selected.map((v: OptionType) => v.value));
    };
    const handleChangeYear = (selected: UnixTimestamp) => {
        setDate(selected);
    };

    const filterParam = useMemo(() => {
        return {
            modelCodes:
                selectSensorCategory.length === 0
                    ? sensorCategoryOption.map(v => v.value).toString()
                    : selectSensorCategory.toString(),
            depts: departments,
            startDate: moment.unix(date).startOf('year').unix(),
            endDate: moment.unix(date).endOf('year').unix(),
        };
    }, [selectedWardList, selectSensorCategory, date, departments]);

    const { state } = useAsync<SensorReportType>({
        promise: getUsagesSensorApi,
        fixedParam: filterParam,
        reject: err => console.log(err),
        immediate: true,
        deps: [filterParam],
    });

    const triggerDownload = useAsyncExcel({
        promise: postUsagesSensorExcelApi,
        param: {
            sensorUsagesStatisticsRequest: {
                modelCodes:
                    selectSensorCategory.length === 0
                        ? sensorCategoryOption.map(v => v.value).toString()
                        : selectSensorCategory.toString(),
                depts: selectedWardList.length === 0 ? wardOptions.map((v: OptionType) => v.value) : selectedWardList,
                startDate: moment.unix(date).startOf('year').unix(),
                endDate: moment.unix(date).endOf('year').unix(),
            },
            excelDownloadRequest: {
                zipFileName: 'Usage Sensor Report',
                fileName: `Sensor usage statistics_${moment().format('YYMMDD')}`,
                columnMetas: columMetas.map(v => ({ key: v.key, name: t(v.name) })),
            },
        },
    });

    const usageSensorList = state.response
        ? {
              ...state.response,
              rows: state.response?.rows.sort((a: SensorReport, b: SensorReport) =>
                  a.groupName.localeCompare(b.groupName),
              ),
          }
        : { rows: [] };

    return (
        <WidgetCard
            ref={widgetRef}
            widgetInfo={widgetInfo}
            searchFilter={
                <Filter>
                    <SearchableSelect
                        data={wardOptions}
                        title={t('Ward', 'CommonColumn')}
                        selected={selectedWardList}
                        onChange={handleWardChange}
                    />

                    <SearchableSelect
                        data={sensorCategoryOption}
                        title={t('Device Group')}
                        selected={selectSensorCategory}
                        onChange={handleSensorCategory}
                    />

                    <DatePicker
                        dateFormat={'yyyy'}
                        value={date}
                        handleChange={handleChangeYear}
                        showYearPicker
                        placeholderText={'----'}
                        maxDate={moment.now()}
                        shouldCloseOnSelect
                        withoutTime
                    />
                </Filter>
            }
            {...restProps}
        >
            <UsageSensorList date={date} data={usageSensorList} handleExcelDownload={triggerDownload} />
            {children}
        </WidgetCard>
    );
};

export default UsageSensorReport;
