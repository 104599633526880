import { useState } from 'react';
import moment from 'moment';

export const MAXIMUN_DIFF_DAY = 30;
export const MAXIMUN_DIFF_MONTH = 11;

// 일별: 시작일의 00:00:00 ~ 종료일 23:59:59
// 월별: 시작달의 1일의 00:00:00 ~ 종료달 마지막날의 23:59:59
// 연도별: 시작연도의 1월의 1일의 00:00:00 ~ 종료연도의 12월 마지막날의 23:59:59

const formatStartUnixTime = (unixTime, dateType = 'daily') => {
    if (dateType === 'daily') {
        return moment.unix(unixTime).startOf('day').unix();
    }
    if (dateType === 'monthly') {
        return moment.unix(unixTime).startOf('month').unix();
    }
    if (dateType === 'yearly') {
        return moment.unix(unixTime).startOf('year').unix();
    }
    return unixTime;
};

const formatEndUnixTime = (unixTime, dateType = 'daily') => {
    if (dateType === 'daily') {
        return moment.unix(unixTime).endOf('day').unix();
    }
    if (dateType === 'monthly') {
        return moment.unix(unixTime).endOf('month').unix();
    }
    if (dateType === 'yearly') {
        return moment.unix(unixTime).endOf('year').unix();
    }
    return unixTime;
};

const useRangeDate = (dateType = 'daily') => {
    // seconds 단위
    const [startDate, setStartDate] = useState(moment().unix());
    const [endDate, setEndDate] = useState(moment().unix());

    const handleStartChange = date => {
        const unixTime = moment(date).unix();
        setStartDate(unixTime);

        // 시작 날짜를 끝 날짜보다 크게 바꿀 때 동일하게 세팅
        if (unixTime > endDate) {
            setEndDate(unixTime);
            return;
        }

        const unixTimeMs = unixTime * 1000;
        const endDateMs = endDate * 1000;
        // 일별: startDate를 endDate의 30일보다 더 전으로 바꿨을 때
        // 끝 날짜도 바뀔 e의 +30일로 변경
        if (dateType === 'daily' && moment(endDateMs).diff(unixTimeMs, 'days') > MAXIMUN_DIFF_DAY) {
            setEndDate(moment(unixTimeMs).add(MAXIMUN_DIFF_DAY, 'days').unix());
            return;
        }
        // 월별: 12개월보다 전일 때 끝 날짜 변경
        if (dateType === 'monthly' && moment(endDateMs).diff(unixTimeMs, 'months') > MAXIMUN_DIFF_MONTH) {
            setEndDate(moment(unixTimeMs).add(MAXIMUN_DIFF_MONTH, 'months').unix());
            return;
        }
    };

    const handleEndChange = date => {
        const unixTime = moment(date).unix();
        setEndDate(unixTime);
    };

    return {
        startDate: formatStartUnixTime(startDate, dateType),
        handleStartChange,
        endDate: formatEndUnixTime(endDate, dateType),
        handleEndChange,
    };
};

export default useRangeDate;
