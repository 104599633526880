import React, { useMemo } from 'react';
import { useAsync, useTranslation } from '@util/hooks';
import { Modal, Table } from '@components';
import styled from 'styled-components';
import { Row } from '../hooks/useAmountList';
import { getOutstandingAmountDetail } from '@api/so/outpatient';
import { formatDateByColumn } from '../../../util/format';

export interface ModalRow extends Pick<Row, 'patientName' | 'amount'> {
    sendingDate: number;
}

interface Props {
    isOpen: boolean;
    toggleModal: () => void;
    patientInfo: Row;
}

const HistoryModal = ({ isOpen, toggleModal, patientInfo }: Props) => {
    const t = useTranslation('OutpatientStorage');

    const { state } = useAsync({
        promise: getOutstandingAmountDetail,
        param: {
            unpaidAmountNum: patientInfo.patientId,
        },
        immediate: true,
        reject: err => console.error(err),
    });
    const rows = state.response ? state.response.rows : [];

    const columns = useMemo(
        () => [
            {
                Header: t('Notification count'),
                accessor: 'count',
                Cell: ({ row: { index } }: { row: { index: number } }) => {
                    return (
                        <span>
                            {index + 1}
                            {t(' times')}
                        </span>
                    );
                },
            },
            {
                Header: t('Sent date'),
                accessor: 'date',
                Cell: ({ row }: { row: { original: ModalRow } }) => {
                    return <span>{formatDateByColumn(row, 'sendingDate')}</span>;
                },
            },
            {
                Header: t('Patient name'),
                accessor: 'patientName',
            },
        ],
        [t],
    );

    return (
        <Modal
            initModal={isOpen}
            toggleModal={toggleModal}
            className=""
            headerClassName=""
            bodyClassName=""
            footerClassName=""
            headerTitle={t('Notification Send History')}
            bodyText=""
            modalHeader=""
            modalBody=""
            modalFooter=""
            removeCancel={true}
        >
            <div className={'modal-body'}>
                <TableWrapper>
                    <Table columns={columns} data={{ rows }} onPageChange={() => {}} paging={false} />
                </TableWrapper>
            </div>
        </Modal>
    );
};

const TableWrapper = styled.div`
    height: 400px;
`;

export default HistoryModal;
