export const DATE_FORMAT_YMD_HMS = 'YYYY.MM.DD HH:mm:ss';

export const DUMMY_DATA = {
    rows: [
        {
            date: '2023-10-29~2023-11-04',
            billed: Math.round(Math.random() * 20),
            total: Math.round(Math.random() * 50),
            isolatedMedical: Math.round(Math.random() * 10),
            normalMedical: Math.round(Math.random() * 10),
            tissue: Math.round(Math.random() * 10),
            damage: Math.round(Math.random() * 10),
            pathology: Math.round(Math.random() * 10),
            biochemistry: Math.round(Math.random() * 10),
            bloodContamination: Math.round(Math.random() * 10),
            humanTissue: Math.round(Math.random() * 10),
            weight: Math.round(Math.random() * 50),
        },
        {
            date: '2023-10-22~2023-10-28',
            billed: Math.round(Math.random() * 20),
            total: Math.round(Math.random() * 50),
            isolatedMedical: Math.round(Math.random() * 10),
            normalMedical: Math.round(Math.random() * 10),
            tissue: Math.round(Math.random() * 10),
            damage: Math.round(Math.random() * 10),
            pathology: Math.round(Math.random() * 10),
            biochemistry: Math.round(Math.random() * 10),
            bloodContamination: Math.round(Math.random() * 10),
            humanTissue: Math.round(Math.random() * 10),
            weight: Math.round(Math.random() * 50),
        },
        {
            date: '2023-10-15~2023-10-21',
            billed: Math.round(Math.random() * 20),
            total: Math.round(Math.random() * 50),
            isolatedMedical: Math.round(Math.random() * 10),
            normalMedical: Math.round(Math.random() * 10),
            tissue: Math.round(Math.random() * 10),
            damage: Math.round(Math.random() * 10),
            pathology: Math.round(Math.random() * 10),
            biochemistry: Math.round(Math.random() * 10),
            bloodContamination: Math.round(Math.random() * 10),
            humanTissue: Math.round(Math.random() * 10),
            weight: Math.round(Math.random() * 50),
        },
        {
            date: '2023-10-08~2023-10-14',
            billed: Math.round(Math.random() * 20),
            total: Math.round(Math.random() * 50),
            isolatedMedical: Math.round(Math.random() * 10),
            normalMedical: Math.round(Math.random() * 10),
            tissue: Math.round(Math.random() * 10),
            damage: Math.round(Math.random() * 10),
            pathology: Math.round(Math.random() * 10),
            biochemistry: Math.round(Math.random() * 10),
            bloodContamination: Math.round(Math.random() * 10),
            humanTissue: Math.round(Math.random() * 10),
            weight: Math.round(Math.random() * 50),
        },
    ],
};
