import { getRentalHistoryList, postRentalHistoryExcel } from '@api/am/management';
import { useAsync, useTranslation } from '@util/hooks';
import React, { useRef, useState } from 'react';
import WidgetCard from '../../Components/WidgetCard';
import { WidgetProps } from '../../staticInfo';
import { useAsyncExcel } from '../../util/useAsyncExcel';
import Filter from '../Components/Filter';
import useRangeDate from '../RentalManagement/hooks/useRangeDate';
import useCategoryList from '../util/useCategoryList';
import useMultiOptionsByRole from '../util/useMultiOptionsByRole';
import HistoryList from './HistoryList';

const RentalHistory = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    const widgetRef = useRef(null);
    const t = useTranslation('AssetManagement');

    const {
        selectedWardList,
        options,
        handleWardChange,
        handleWardReset,
        selectedRentalWardList,
        handleRentalWardChange,
        handleRentalWardReset,
    } = useMultiOptionsByRole();

    const {
        initialCategoryList,
        selectedCategoryList,
        categoryOptions,
        handleCategoryChange,
        handleCategoryReset,
    } = useCategoryList();
    const { startDate, handleStartChange, endDate, handleEndChange } = useRangeDate();
    const [keyword, setKeyword] = useState('');
    const [page, setPage] = useState(1);

    const { state } = useAsync({
        promise: getRentalHistoryList,
        immediate: selectedRentalWardList,
        param: {
            pageSize: 15,
            page,
            ownWardList: selectedWardList,
            usingWardList: selectedRentalWardList,
            searchWord: keyword.length === 0 ? undefined : keyword,
            applyStartDate: startDate,
            applyEndDate: endDate,
            categoryCodes:
                selectedCategoryList.length === 0 ? initialCategoryList.toString() : selectedCategoryList.toString(),
        },
        deps: [keyword, selectedWardList, selectedCategoryList, selectedRentalWardList, startDate, endDate, page],
    });
    const data = state.response ?? {
        page: 1,
        rows: [],
        totalCount: 0,
        totalPage: 0,
    };

    const triggerDownload = useAsyncExcel({
        promise: postRentalHistoryExcel,
        param: {
            am01AssetRentalLogRequest: {
                page,
                pageSize: 15,
                categoryCodes:
                    selectedCategoryList.length === 0
                        ? initialCategoryList.toString()
                        : selectedCategoryList.toString(),
                ownWardList: selectedWardList,
                usingWardList: selectedRentalWardList,
                searchWord: keyword.length === 0 ? undefined : keyword,
                applyStartDate: startDate,
                applyEndDate: endDate,
            },
            excelDownloadRequest: {
                fileName: 'AssetRentalLogExcel',
                zipFileName: 'AssetRentalLogZip',
                columnMetas: [
                    {
                        key: 'categoryName',
                        name: t('Category Name'),
                    },
                    {
                        key: 'targetName',
                        name: t('Asset Name'),
                    },
                    {
                        key: 'targetID',
                        name: t('Target ID'),
                    },
                    {
                        key: 'ownDepName',
                        name: t('Owner Ward'),
                    },
                    {
                        key: 'applyDepName',
                        name: t('Rent Ward'),
                    },
                    {
                        key: 'applyUserName',
                        name: t('Applicant'),
                    },
                    {
                        key: 'applyUserID',
                        name: t('Applicant ID'),
                    },
                    {
                        key: 'applyDate',
                        name: t('Request Date'),
                    },
                    {
                        key: 'requestStartDate',
                        name: t('Request Rental Period Start'),
                    },
                    {
                        key: 'requestEndDate',
                        name: t('Request Rental Period End'),
                    },
                    {
                        key: 'status',
                        name: t('Status'),
                    },
                    {
                        key: 'completeDate',
                        name: t('Approve/Reject/Cancel Date'),
                    },
                    {
                        key: 'description',
                        name: t('Description'),
                    },
                    {
                        key: 'giveUserName',
                        name: t('Supplier'),
                    },
                    {
                        key: 'giveUserID',
                        name: t('Supplier ID'),
                    },
                    {
                        key: 'giveDate',
                        name: t('Receive Date'),
                    },
                    {
                        key: 'takeUserName',
                        name: t('Receiver'),
                    },
                    {
                        key: 'takeUserID',
                        name: t('Receiver ID'),
                    },
                    {
                        key: 'returnUserName',
                        name: t('Returner'),
                    },
                    {
                        key: 'returnUserID',
                        name: t('Returner ID'),
                    },
                    {
                        key: 'returnDate',
                        name: t('Return Date'),
                    },
                    {
                        key: 'giveDate',
                        name: t('Lending Period Start'),
                    },
                    {
                        key: 'returnDate',
                        name: t('Lending Period End'),
                    },
                ],
            },
        },
    });

    const handleKeywordChange = (keyword: string) => {
        setKeyword(keyword);
        setPage(1);
    };

    const handleResetClick = () => {
        setKeyword('');
        handleWardReset();
        handleRentalWardReset();
        handleCategoryReset();
        setPage(1);
    };

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            ref={widgetRef}
            searchFilter={
                <Filter>
                    <Filter.SearchableSelect
                        options={{
                            title: t('Ward', 'CommonColumn'),
                            data: options,
                            selected: selectedWardList,
                            onChange: (selected: number) => {
                                setPage(1);
                                handleWardChange(selected);
                            },
                        }}
                    />
                    <Filter.SearchableSelect
                        options={{
                            title: t('Category'),
                            data: categoryOptions,
                            selected: selectedCategoryList,
                            onChange: (selected: any) => {
                                setPage(1);
                                handleCategoryChange(selected);
                            },
                        }}
                    />
                    <Filter.SearchableSelect
                        options={{
                            title: t('Rental Ward'),
                            data: options,
                            selected: selectedRentalWardList,
                            onChange: (selected: any) => {
                                setPage(1);
                                handleRentalWardChange(selected);
                            },
                        }}
                    />
                    <Filter.Label text={t('Request Date')} className="ml-2" />
                    <Filter.DatePickerGroup
                        options={{
                            startDate,
                            endDate,
                            handleStartChange: (date: Date) => {
                                setPage(1);
                                handleStartChange(date);
                            },
                            handleEndChange: (date: Date) => {
                                setPage(1);
                                handleEndChange(date);
                            },
                        }}
                    />
                </Filter>
            }
            {...restProps}
        >
            <HistoryList
                data={data}
                onKeywordChange={handleKeywordChange}
                onResetClick={handleResetClick}
                onDownloadClick={() => triggerDownload()}
                onPageChange={pageIndex => setPage(pageIndex)}
            />
            {children}
        </WidgetCard>
    );
};

export default RentalHistory;
