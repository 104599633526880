import { useEffect, useReducer, useRef } from 'react';
import { checkResponseErr } from '../common/util';
import { PayloadAction } from '@reduxjs/toolkit';
import { Abort, RequestHandlerCreator, ApiResponse } from '@api/util';
import axios from 'axios';

type UseAsyncParam<P, R> = {
    promise: RequestHandlerCreator<R, P> | RequestHandlerCreator<R, P>[];
    param?: P;
    fixedParam?: object | any[];
    deps?: any[];
    immediate?: boolean;
    resolve?: (response: R, request: P) => void;
    reject?: (error: Error) => void;
    keepState?: boolean;
};

type RequestParam = any;
type ResponseData = any;
type Error = any;

type AsyncState<R = ResponseData> = {
    keepState: boolean;
    isLoading: boolean;
    request: RequestParam;
    response: R | null;
    error: Error;
};

type AsyncHandler<P, R> = {
    state: AsyncState<R>;
    promise: (param?: P) => Promise<any>;
    abort: Abort;
};

type AbortRef = Abort | Abort[] | null;

const initialState: AsyncState = {
    keepState: false,
    isLoading: false,
    request: null,
    response: null,
    error: null,
};

// createSlice 사용하여 리듀서 생성할 경우, 상태값의 불변성 유지를 위해 확장 불가능한 객체가 되어버림.
// useAsync 를 사용하여 API 응답값을 받는 경우 기존의 사용방식을 유지하기 위해서는 확장 가능해야 하므로 createSlice를 사용하지 않았음
function asyncReducer(state: AsyncState, action: PayloadAction<any>) {
    switch (action.type) {
        case 'LOADING':
            return {
                keepState: state.keepState,
                isLoading: true,
                request: action.payload.request,
                response: state.keepState ? state.response : null,
                error: state.keepState ? state.error : null,
            };
        case 'SUCCESS':
            return {
                keepState: state.keepState,
                isLoading: false,
                request: state.request,
                response: action.payload.response.data || action.payload.response,
                error: null,
            };
        case 'ERROR':
            /* @ToDO
             *  현재 abort 경우에는 응답이 오기전 같은 API를 호출했을 때 사용하기에 loading을 true로 유지
             *  abort를 수동 사용 시에는 개선이 필요함
             * */
            const isAborted = axios.isCancel(action.payload.error);
            return {
                keepState: state.keepState,
                isLoading: isAborted ? state.isLoading : false,
                request: state.request,
                response: null,
                error: action.payload.error,
            };
        default:
            throw new Error(`Unknown action type: ${action.type}`);
    }
}

const useAsync = <P, R = ResponseData>({
    promise,
    param,
    fixedParam = {},
    deps = [],
    immediate = false,
    resolve,
    reject,
    keepState = false,
}: UseAsyncParam<P, R>): AsyncHandler<P, R> => {
    const [state, dispatch] = useReducer(asyncReducer, { ...initialState, keepState });
    const abortRef = useRef<AbortRef>(null);

    const asyncPromise = async (newParam?: P) => {
        try {
            if (Array.isArray(promise)) {
                let thisParam: any[] = [];
                if (Array.isArray(fixedParam)) {
                    thisParam = [...fixedParam];
                }
                const dynamicParam = newParam || param;
                if (Array.isArray(dynamicParam)) {
                    thisParam = [...thisParam, ...dynamicParam];
                }
                dispatch({ type: 'LOADING', payload: { request: thisParam } });
                const responseArr = [];
                const errResponseArr = [];
                const abortArr = [];
                for (let i = 0, len = promise.length; i < len; i++) {
                    const tempParam = thisParam && thisParam[i];
                    const { fetch, abort } = promise[i](tempParam);
                    abortArr.push(abort);
                    const response: ApiResponse<R> = await fetch();
                    if (checkResponseErr(response)) {
                        // Error 이지만 현재 솔루션에서는 AxiosResponse 타입이 옴.
                        errResponseArr.push(response);
                    } else {
                        responseArr.push(response.data);
                    }
                }
                abortRef.current = abortArr;
                if (errResponseArr.length) {
                    dispatch({ type: 'ERROR', payload: { error: errResponseArr } });
                } else {
                    dispatch({ type: 'SUCCESS', payload: { response: responseArr } });
                }
            } else {
                const thisParam = { ...fixedParam, ...(newParam || param) };
                dispatch({ type: 'LOADING', payload: { request: thisParam } });
                const { fetch, abort } = promise(thisParam);
                abortRef.current = abort;
                const response: ApiResponse<R> = await fetch();
                if (checkResponseErr(response)) {
                    // Error 이지만 현재 솔루션에서는 AxiosResponse 타입이 옴.
                    dispatch({ type: 'ERROR', payload: { error: response } });
                } else {
                    dispatch({ type: 'SUCCESS', payload: { response: response.data } });
                }
            }
        } catch (e) {
            dispatch({ type: 'ERROR', payload: { error: e } });
        }
    };

    const abort = () => {
        const tempAbort = abortRef.current;
        if (tempAbort) {
            // 배열은 사실상 의미 없음. await 로 모두 실행 후, 동기적으로 ref에 담기 때문.
            // Promise.all, Promise.allSettled 등을 사용하여 개선 작업필요
            if (Array.isArray(tempAbort)) {
                tempAbort.forEach(abort => {
                    abort();
                });
            } else {
                tempAbort();
            }
        }
    };

    useEffect(() => {
        if (immediate) {
            asyncPromise();
        }
        return () => {
            abort();
        };
    }, deps);

    useEffect(() => {
        if (!state.isLoading) {
            if (state.response && typeof resolve === 'function') {
                resolve(state.response, state.request);
            } else if (state.error !== null && typeof reject === 'function') {
                reject(state.error);
            }
        }
    }, [state]);

    return {
        state,
        promise: asyncPromise,
        abort,
    };
};

export default useAsync;
