import React, { useCallback, useState } from 'react';
import { useTranslation, useConfirm } from '@hooks';
import { ConfirmModalProps } from '@components/type';

interface ValidationModalProps<D> extends Partial<Omit<ConfirmModalProps, 'toggleModal'>> {
    targetData: D;
    validate(data: D): string[];
}

const ValidationMsg = ({ validationMsg }: { validationMsg: string[] }) => {
    return (
        <>
            {validationMsg.map((msg, i) => {
                return (
                    <li key={`validation_${i}`}>
                        <span className="pnt-label--group">
                            <div className="label-main w-100" style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                                {msg}
                            </div>
                        </span>
                    </li>
                );
            })}
        </>
    );
};

const useValidation = <D,>({ initModal, targetData, validate, ...modalProps }: ValidationModalProps<D>) => {
    const t = useTranslation('ConfirmModal');
    const [validationMsg, setValidationMsg] = useState<string[]>([]);

    const isPassValidation = useCallback(() => {
        let result: string[] = [];
        if (typeof validate === 'function' && typeof targetData === 'object') {
            result = validate(targetData) || [];
        }
        setValidationMsg(result);
        return !result.length;
    }, [targetData, validate]);

    const confirmProps = useConfirm({
        initModal,
        header: { title: t('Data Validation') },
        confirmText: <ValidationMsg validationMsg={validationMsg} />,
        ...modalProps,
    });

    return { ...confirmProps, isPassValidation };
};

export default useValidation;
