import React, { useRef } from 'react';
import { Button } from '@components';
import { useAsync, useConfirmModal, useTranslation } from '@hooks';
import { fetchExcelLogApi, fetchScannerStateExcelLogExport } from '@api/common/log';
import moment from 'moment';
import { excelExport } from '../../../util/excelExport';
import { useScannerStatusLogContext } from '../index';
import { makeParameter } from '../../../util/commons';

const INTERVAL_TIME = 1000;

/**
 *
 * 스캐너 상태 로그를 엑셀파일로 출력하는 컴포넌트
 *
 * @param {date} startDate 검색 시작 일자
 * @param {date} endDate  검색 마지막 일자
 * @param {number} listCount 리스트 카운트
 *
 * @author jinbeom-jung
 * */

const ExcelExport = ({ startDate, endDate, listCount }) => {
    const { floorIds, floorIdPath, scannerNums } = useScannerStatusLogContext();
    const t = useTranslation('Scanner State Log');
    const excelTimerRef = useRef({});

    const { promise: createNewLogFile } = useAsync({
        promise: fetchScannerStateExcelLogExport,
        fixedParam: {
            startDate: startDate ? moment(startDate).unix() : 0,
            endDate: endDate ? moment(endDate).unix() : moment().unix(),
            zipFileName: 'Scanner_State_Log',
            fileName: 'Scanner_State_Log',
            isDateFormat: 'Y',
            columnMetas: [
                {
                    key: 'logNum',
                    name: t('No.', 'CommonColumn'),
                },
                {
                    key: 'scannerName',
                    name: t('Scanner Name', 'CommonColumn'),
                },
                {
                    key: 'macAddr',
                    name: t('Mac Address', 'CommonColumn'),
                },
                {
                    key: 'startDate',
                    name: t('Start Date', 'CommonColumn'),
                    isDateFormat: 'Y',
                },
                {
                    key: 'endDate',
                    name: t('End Date', 'CommonColumn'),
                    isDateFormat: 'Y',
                },
            ],
        },
        resolve: res => {
            const { filePath: url, reqNum } = res;
            if (url) {
                downloadWithURL({ reqNum });
            }
        },
        reject: err => console.error(err),
    });

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            const { dataSettingStatusType, filePath: url, fileName, reqNum } = res;
            if (dataSettingStatusType === 'COMPLETED') {
                excelExport(url, fileName);
            } else if (dataSettingStatusType === 'FAILED') {
                toggleExcelDownloadResultModal();
            }
            clearExcelTimer({ reqNum });
        },
        reject: err => console.error(err),
    });

    const downloadWithURL = ({ reqNum }) => {
        excelTimerRef.current[reqNum] = setInterval(() => {
            fetchExcelLog({ reqNum });
        }, INTERVAL_TIME);
    };

    const clearExcelTimer = ({ reqNum }) => {
        clearInterval(excelTimerRef.current[reqNum]);
        excelTimerRef.current[reqNum] = null;
    };

    const handleExportFileClick = () => {
        if (listCount) {
            const result = makeParameter({
                searchRequirement: {
                    scannerNums: scannerNums.join(','),
                    floor: floorIds.floorId,
                    floorPath: floorIdPath,
                },
                useIsAll: true,
            });
            createNewLogFile(result);
        } else {
            toggleExcelConfirmModal();
        }
    };

    const { Modal: ExcelDownloadResultModal, toggleModal: toggleExcelDownloadResultModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Result', 'Report') },
        confirmText: t('Failed to download the excel.', 'Report'),
    });

    const { Modal: ExcelConfirmModal, toggleModal: toggleExcelConfirmModal } = useConfirmModal({
        initModal: false,
        header: {
            title: t('Excel Download', 'Report'),
        },
        confirmText: t('No data', 'Report'),
        removeCancel: true,
    });

    return (
        <>
            <Button className="btn-lightgray btn-icon" onClick={handleExportFileClick}>
                <span className={'material-icons-round'}>file_upload</span>
                {t('Export', 'Button')}
            </Button>
            <ExcelDownloadResultModal />
            <ExcelConfirmModal />
        </>
    );
};

export default ExcelExport;
