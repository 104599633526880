import { reqGet } from '@api/index';
import { RequestHandlerCreator, PageInfoType, PageInfoParam } from '@api/util';
import { NullableNumber, YN, UnixTimestamp, NullableString } from '@util/type/util';

export interface GeofenceInfo {
    comNum: number;
    fcNum: number;
    fcGroupNum: NullableNumber;
    fcName: string;
    floor: string;
    evtEnter: number;
    evtLeave: number;
    numEnter: number;
    numLeave: number;
    isNodeEnable: YN;
    fcGroupMoveRestrict: YN;

    // S: 머무름, L: 나감
    handlingLostSignal: 'S' | 'L';
    modDate: UnixTimestamp;
    regDate: UnixTimestamp;
    latLngList: LatLngInfo[];
}

interface LatLngInfo {
    fcNum: number;
    lat: number;
    lng: number;
    orderSeq: number;
}

interface GeofenceListParam extends PageInfoParam {
    fcName?: NullableString;
    fcNum?: NullableNumber;
    floor?: NullableString;
    floors?: NullableString;
}

export interface GeofenceGroupInfo {
    comNum: number;
    fcGroupNum: number;
    fcGroupName: string;
    fcCount: number;
    modDate: UnixTimestamp;
    regDate: UnixTimestamp;
}

interface GeofenceGroupListParam extends PageInfoParam {
    opt?: string;
    keyword?: NullableString;
}

export const fetchGeofenceList: RequestHandlerCreator<PageInfoType<GeofenceInfo>, GeofenceListParam> = param =>
    reqGet('/v3/api/common/geofence', { ...param, isAll: 'Y' });

export const fetchGeofenceGroupList: RequestHandlerCreator<
    PageInfoType<GeofenceGroupInfo>,
    GeofenceGroupListParam
> = param => reqGet('/v3/api/common/geofence/group', param);
