import { createSlice } from '@reduxjs/toolkit';
import { BATTERY_CHARGING } from './constants';

export const initialState = {
    vitalSignsList: {},
    monitoringTargetNums: [],
    originWorkTimeList: [],
    isDetail: false,
    targetId: null,
    wardNum: null,
};

const { actions, reducer } = createSlice({
    name: 'vitalSignsOfPatronPoc',
    initialState,
    reducers: {
        storeVitalSignList: (state, action) => {
            if (!action.payload) {
                return;
            }

            const monitoringTargetNums = [];

            state.vitalSignsList = (function loop(entryValues) {
                return Object.fromEntries(
                    Object.keys(entryValues).map(key => {
                        if (!Array.isArray(entryValues[key])) {
                            return [key, loop(entryValues[key])];
                        } else {
                            let person = {};
                            if (entryValues[key][0]) {
                                monitoringTargetNums.push(entryValues[key][0].targetNum);
                            }
                            entryValues[key].forEach(personData => {
                                person = {
                                    ...person,
                                    ...personData,
                                    [personData.sensingType]: {
                                        value: personData.currentValue,
                                        status: personData.status,
                                        regDate: personData.regDate,
                                    },
                                };
                            });
                            return [key, person];
                        }
                    }),
                );
            })(action.payload);

            state.monitoringTargetNums = monitoringTargetNums;
        },
        eachUpdateVitalSignList: (state, action) => {
            if (!action.payload) {
                return;
            }

            const vitalSigns = action.payload;

            state.vitalSignsList = (function loop(entryValues) {
                return Object.fromEntries(
                    Object.keys(entryValues).map(key => {
                        if (!entryValues[key].deviceId) {
                            return [key, loop(entryValues[key])];
                        }
                        if (entryValues[key].deviceId === vitalSigns.deviceId) {
                            if (vitalSigns.sensingValue?.BATTERY?.value === BATTERY_CHARGING) {
                                let person = {
                                    ...entryValues[key],
                                    ...vitalSigns,
                                    BATTERY: { ...vitalSigns.sensingValue.BATTERY },
                                };
                                return [key, person];
                            }

                            let person = { ...entryValues[key], ...vitalSigns, ...vitalSigns.sensingValue };
                            return [key, person];
                        } else {
                            return [key, entryValues[key]];
                        }
                    }),
                );
            })(state.vitalSignsList);
        },
        setIsDetail: (state, action) => {
            state.isDetail = action.payload;
        },
        setTargetNum: (state, action) => {
            if (!action.payload) {
                return;
            }
            state.targetId = action.payload;
        },
        setWardNum: (state, action) => {
            if (!action.payload) {
                return;
            }
            state.wardNum = action.payload;
        },
    },
});
export const { storeVitalSignList, eachUpdateVitalSignList, setIsDetail, setTargetNum, setWardNum } = actions;
export default reducer;
