import { useAsync, useTranslation } from '@util/hooks';
import { Row } from './useAmountList';
import { postOutstandingAmountNotification } from '@api/so/outpatient';

interface Param {
    unpaidAmountDetails: Array<Pick<Row, 'unpaidAmountNum' | 'patientId' | 'patientName' | 'phoneNum' | 'amount'>>;
}

interface Response {
    result: 'success' | 'fail';
    code: number;
}

const useNotification = ({ onSuccess }: { onSuccess: () => void }) => {
    const t = useTranslation('OutpatientStorage');
    const { promise: postNotification } = useAsync<Param, Response>({
        promise: postOutstandingAmountNotification,
        resolve: () => {
            alert(t('Notification has been sent successfully.'));
            onSuccess();
        },
        reject: err => console.error(err),
    });

    return { postNotification };
};

export default useNotification;
