import React from 'react';
import { Button, DatePicker } from '@components';
import moment from 'moment';

const DatePickerGroupSO = ({
    dateType = 'daily', // daily, weekly, monthly
    startDate,
    handleStartChange,
    handleStartLeftClick,
    handleStartRightClick,
    endDate,
    handleEndChange,
    handleEndLeftClick,
    handleEndRightClick,
}) => {
    const startDateMs = startDate * 1000;

    return (
        <>
            {dateType === 'daily' ? (
                <>
                    <DatePicker
                        value={startDate}
                        onChange={handleStartChange}
                        withoutTime
                        valueType="s"
                        disabledKeyboardNavigation
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_left"
                        onClick={handleStartLeftClick}
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_right"
                        onClick={handleStartRightClick}
                    />
                    <span className={'ml-2 mr-2 font-weight-bold'}>~</span>
                    <DatePicker
                        value={endDate}
                        onChange={handleEndChange}
                        withoutTime
                        valueType="s"
                        minDate={startDateMs}
                        disabledKeyboardNavigation
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_left"
                        onClick={handleEndLeftClick}
                        disabled={moment.unix(endDate).startOf('day').unix() <= startDate}
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_right"
                        onClick={handleEndRightClick}
                    />
                </>
            ) : dateType === 'weekly' ? (
                <>
                    <DatePicker
                        value={startDate}
                        onChange={handleStartChange}
                        withoutTime
                        withoutDay
                        valueType="s"
                        dateFormat="R/I"
                        showWeekNumbers
                        showWeekPicker
                        disabledKeyboardNavigation
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_left"
                        onClick={handleStartLeftClick}
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_right"
                        onClick={handleStartRightClick}
                    />
                    <span className={'ml-2 mr-2 font-weight-bold'}>~</span>
                    <DatePicker
                        value={endDate}
                        onChange={handleEndChange}
                        withoutTime
                        withoutDay
                        valueType="s"
                        dateFormat="R/I"
                        showWeekNumbers
                        showWeekPicker
                        minDate={startDateMs}
                        disabledKeyboardNavigation
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_left"
                        onClick={handleEndLeftClick}
                        disabled={moment.unix(endDate).startOf('week').add(1, 'days').unix() <= startDate}
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_right"
                        onClick={handleEndRightClick}
                    />
                </>
            ) : (
                <>
                    <DatePicker
                        value={startDate}
                        onChange={handleStartChange}
                        withoutTime
                        withoutDay
                        valueType="s"
                        showMonthYearPicker
                        dateFormat="yyyy-MM"
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_left"
                        onClick={handleStartLeftClick}
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_right"
                        onClick={handleStartRightClick}
                    />
                    <span className={'ml-2 mr-2 font-weight-bold'}>~</span>
                    <DatePicker
                        value={endDate}
                        onChange={handleEndChange}
                        withoutTime
                        withoutDay
                        valueType="s"
                        showMonthYearPicker
                        dateFormat="yyyy-MM"
                        minDate={startDateMs}
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_left"
                        onClick={handleEndLeftClick}
                        disabled={moment.unix(endDate).startOf('month').unix() <= startDate}
                    />
                    <Button
                        className="btn-icon-only btn-gray ml-1"
                        iconName="keyboard_arrow_right"
                        onClick={handleEndRightClick}
                    />
                </>
            )}
        </>
    );
};

export default DatePickerGroupSO;
