import React, { useMemo } from 'react';
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import InnerItemCard from '@components/Card/InnerItemCard';
import Search from './Search';
import { Table } from '@components';
import moment from 'moment';
import { setOutpatientListParam } from '../OutpatientEquipmentStatusSlice';
import { useOutpatientEquipmentDispatch } from '../index';

//차후에 코드값이 확정되면 변경될 수 있음
const KIOSK_STATUS = {
    POWER_OFF: 'POWER_OFF',
    NORMAL: 'NORMAL',
    OUT_OF_PAPER: 'OUT_OF_PAPER',
    LOW_INK: 'LOW_INK',
};

const StatusList = ({ outpatientList }) => {
    const t = useTranslation('OutpatientEquipmentStatus');
    const dispatch = useOutpatientEquipmentDispatch();

    const columns = useMemo(
        () => [
            {
                Header: t('Category'),
                accessor: 'category',
                Cell: ({ row: { original } }) => <span>{original.category}</span>,
            },
            {
                Header: t('Equipment Code'),
                accessor: 'kioskId',
            },
            {
                Header: t('Equipment Name'),
                accessor: 'kioskName',
            },
            {
                Header: t('Floor'),
                accessor: 'floor',
            },
            {
                Header: t('Installed Location'),
                accessor: 'department',
            },
            {
                Header: t('Installed Date'),
                accessor: 'regDate',
                Cell: ({ value }) => {
                    return <span>{moment.unix(value).format('YYYY-MM-DD HH:mm:ss')}</span>;
                },
            },
            {
                Header: t('Installed Version'),
                accessor: 'version',
            },
            {
                Header: t('Status'),
                accessor: '',
                Cell: ({ row: { original } }) => (
                    <span className={original.status !== KIOSK_STATUS.NORMAL ? 'color-danger' : ''}>
                        {original.status !== KIOSK_STATUS.NORMAL ? t('Abnormal') : t('Normal')}
                    </span>
                ),
            },
            {
                Header: t('Note'),
                accessor: 'status',
                Cell: ({ row: { original } }) => (
                    <span className={original.status !== KIOSK_STATUS.NORMAL ? 'color-danger' : ''}>
                        {original.status !== KIOSK_STATUS.NORMAL ? t(original.status) : ''}
                    </span>
                ),
            },
        ],
        [t],
    );

    const handlePageChange = pageIndex => {
        dispatch(
            setOutpatientListParam(prevState => {
                return { ...prevState, page: pageIndex };
            }),
        );
    };

    return (
        <Container>
            <InnerItemCard
                className={'h-100'}
                bodyClassName={'h-100'}
                header={{
                    title: t('Outpatient Equipment Status List'),
                }}
            >
                <Search />
                <TableContainer className={'h-90'}>
                    <Table columns={columns} data={outpatientList} onPageChange={handlePageChange} onTrClick={false} />
                </TableContainer>
            </InnerItemCard>
        </Container>
    );
};

const Container = styled.div`
    height: 550px;
    margin-top: 5px;
`;

const TableContainer = styled.div`
    height: 250px;
    margin-bottom: 5px;
`;

export default StatusList;
