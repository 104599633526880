/*
 * prefix PV_ path variable
 * prefix A_ absolute
 * */
export const A_PATH_ROOT = '/';

export const PV_MENU_CODE = '/:menuCode([a-z|A-Z|0-9]+)';
export const PV_SCREEN_CODE = '/:screenCode([a-z|A-Z|0-9]+)';
export const PV_OAUTH_INFO = '/:oAuthInfo';
export const PV_FILTER_NUM = '/:filterNum([0-9]+)';
export const PV_GROUP_NUM = '/:groupNum([0-9]+)';

export const PATH_LOGIN = 'login';
export const PATH_LOGIN_OAUTH = `login${PV_OAUTH_INFO}`;
export const PATH_MAIN = 'main';
export const PATH_SETTING = 'setting';
export const PATH_NO_AUTHORITY = 'no-authority';
export const PATH_MODULES = 'modules';
// 웹뷰용 화면
export const PATH_EMBED = 'embed';

export const A_PATH_LOGIN = `${A_PATH_ROOT}${PATH_LOGIN}`;
export const A_PATH_LOGIN_OAUTH = `${A_PATH_ROOT}${PATH_LOGIN_OAUTH}`;
export const A_PATH_MAIN = `${A_PATH_ROOT}${PATH_MAIN}`;
export const A_PATH_SETTING = `${A_PATH_ROOT}${PATH_SETTING}`;
export const A_PATH_NO_AUTHORITY = `${A_PATH_ROOT}${PATH_NO_AUTHORITY}`;
export const A_PATH_MODULES = `${A_PATH_ROOT}${PATH_MODULES}`;
export const A_PATH_EMBED = `${A_PATH_ROOT}${PATH_EMBED}`;

export const PATH_MENU = '/menu';
export const PATH_SCREEN = '/screen';
export const PATH_CUSTOM_SCREEN = `${PV_MENU_CODE}${PV_SCREEN_CODE}`;
export const PATH_SCREEN_EDIT = `/edit${PV_SCREEN_CODE}`;
export const PATH_FILTER = '/filter';
export const PATH_AUTHORIZATION_GROUP = '/authorization-group';

export const A_PATH_MENU = `${A_PATH_SETTING}${PATH_MENU}`;
export const A_PATH_MENU_LIST = `${A_PATH_SETTING}${PATH_MENU}/list`;
export const A_PATH_MENU_DETAIL = `${A_PATH_SETTING}${PATH_MENU}/edit${PV_MENU_CODE}`;
export const A_PATH_MENU_CREATE = `${A_PATH_SETTING}${PATH_MENU}/create`;

export const A_PATH_FILTER = `${A_PATH_SETTING}${PATH_FILTER}`;
export const A_PATH_FILTER_LIST = `${A_PATH_SETTING}${PATH_FILTER}/list`;
export const A_PATH_FILTER_DETAIL = `${A_PATH_SETTING}${PATH_FILTER}${PV_FILTER_NUM}`;
export const A_PATH_FILTER_CREATE = `${A_PATH_SETTING}${PATH_FILTER}/create`;

export const A_PATH_SCREEN = `${A_PATH_SETTING}${PATH_SCREEN}`;
export const A_PATH_CUSTOM_SCREEN = `${A_PATH_MAIN}${PATH_CUSTOM_SCREEN}`;
export const A_PATH_EMBED_CUSTOM_SCREEN = `${A_PATH_EMBED}${PATH_CUSTOM_SCREEN}`;
export const A_PATH_SCREEN_EDIT = `${A_PATH_SETTING}${PATH_SCREEN}${PATH_SCREEN_EDIT}`;

export const A_PATH_AUTHORIZATION_GROUP = `${A_PATH_SETTING}${PATH_AUTHORIZATION_GROUP}`;
export const A_PATH_AUTHORIZATION_GROUP_DETAIL = `${A_PATH_SETTING}${PATH_AUTHORIZATION_GROUP}${PV_GROUP_NUM}`;
export const A_PATH_AUTHORIZATION_GROUP_CREATE = `${A_PATH_SETTING}${PATH_AUTHORIZATION_GROUP}/create`;
