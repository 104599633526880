import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { errorPath } from '../../../ErrorPages';
import PageNotFound from '../../../ErrorPages/Pages/PageNotFound';
import { checkExpire } from '@util/common/util';
import Login from '../../Login';
import { useDispatch } from 'react-redux';
import { logOut } from '@reducer/UserInfo';
import CustomScreen from '../../Components/Screen/CustomScreen';
import Settings from '../../Settings';
import Modules from '@components/Modules';
import NotExistScreen from '../Screen/NotExistScreen';
import { A_PATH_LOGIN, A_PATH_LOGIN_OAUTH, A_PATH_MODULES, A_PATH_NO_AUTHORITY, A_PATH_SETTING } from './path';
import useAppSelector from '@hooks/useAppSelector';

export const AuthRoute = ({ component: Component, rest }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    return (
        <Route
            {...rest}
            render={() => {
                if (checkExpire()) {
                    return <Component />;
                } else {
                    dispatch(logOut());
                    return <Redirect to={{ pathname: A_PATH_LOGIN, state: { redirectPath: location.pathname } }} />;
                }
            }}
        />
    );
};

export const TopSwitch = ({ MainComponent }) => {
    return (
        <Switch>
            <Route exact path={[A_PATH_LOGIN, A_PATH_LOGIN_OAUTH]} component={Login} />
            <Route exact path={errorPath.pageNotFound} component={PageNotFound} />
            <AuthRoute path="/" component={MainComponent} />
        </Switch>
    );
};

// Redirect Four zero Four
export const ErrorHandleSwitch = ({ children }) => {
    return (
        <Switch>
            {children}
            <Route render={() => <Redirect to={errorPath.pageNotFound} />} />
        </Switch>
    );
};

export const MainRouter = ({ embedPath }) => {
    const { screenList } = useAppSelector(state => state.CustomMenu);
    const {
        userInfo: { settingAuth },
    } = useAppSelector(state => state.UserInfo);
    const homePath = screenList[0]?.url ?? A_PATH_NO_AUTHORITY;

    return (
        <ErrorHandleSwitch>
            {embedPath ? (
                <EmbedRoute path={embedPath} />
            ) : (
                <>
                    <Route
                        exact
                        path={'/'}
                        render={() => {
                            return <Redirect to={homePath} />;
                        }}
                    />
                    {screenList.map(screen => (
                        <Route key={screen.id} path={screen.url} component={CustomScreen} />
                    ))}
                    {settingAuth && <Route path={A_PATH_SETTING} component={Settings} />}
                    <Route exact path={A_PATH_NO_AUTHORITY} component={NotExistScreen} />
                    <Route exact path={A_PATH_MODULES} component={Modules} />
                </>
            )}
        </ErrorHandleSwitch>
    );
};

const EmbedRoute = ({ path }) => {
    const { screenList } = useAppSelector(state => state.CustomMenu);
    return screenList.find(screen => screen.url === path) ? <Route path={path} component={CustomScreen} /> : null;
};
