import React, { HTMLAttributes } from 'react';
import cx from 'classnames';

type SimpleTableCommonProps = {
    className?: string;
    children?: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

interface SimpleTableProps extends SimpleTableCommonProps {}

export const Table = ({ className, style = {}, children }: SimpleTableProps) => {
    return (
        <div role={'table'} className={cx('pnt-table', className)} style={{ height: '100%', ...style }}>
            <div className={'border-box'}>{children}</div>
        </div>
    );
};

interface SimpleTableHeadProps extends SimpleTableCommonProps {}

export const TableHead = ({ className, children }: SimpleTableHeadProps) => {
    return <div className={cx('thead', className)}>{children}</div>;
};

interface SimpleTableColumnHeadProps extends SimpleTableCommonProps {}

export const Th = ({ className, children }: SimpleTableColumnHeadProps) => {
    return (
        <div role="columnheader" className={cx('th', className)}>
            {children}
        </div>
    );
};

interface SimpleTableBodyProps extends SimpleTableCommonProps {}

export const TableBody = ({ className, style, children }: SimpleTableBodyProps) => {
    return (
        <div role={'rowgroup'} className={cx('tbody', className)} style={style}>
            <div>
                <div>
                    <div>{children}</div>
                </div>
            </div>
        </div>
    );
};

interface SimpleTableRowProps extends SimpleTableCommonProps {}

export const Tr = ({ className, children }: SimpleTableRowProps) => {
    return (
        <div role={'row'} className={cx('tr', className)}>
            {children}
        </div>
    );
};

interface SimpleTableCellProps extends SimpleTableCommonProps {}

export const Td = ({ className, children }: SimpleTableCellProps) => {
    return (
        <div role={'cell'} className={cx('td', className)}>
            {children}
        </div>
    );
};
