import React, { useEffect, useState } from 'react';
import InfoTable from './InfoTable';
import moment from 'moment';
import useAsync from '@hooks/useAsync';
import { getEnvironmentalSensorDetailGraphApi } from '@api/monitoring';
import useTranslation from '@hooks/useTranslation';
import Search from './Search';
import NoDataBlock from '../../../../Components/NoDataBlock';
import styles from '@asset/pnt/widgets/VitalSignsMonitoring.module.scss';
import cx from 'classnames';
import {
    ADD_SENSOR_ITEM_NAME,
    DARK_THEME,
    DEFAULT_CHART_OPTIONS,
    DUMMY_GRAPH,
    GRAPH_AREA,
    GRAPH_SORT,
    LINE_CHART_COLOR,
    TOOLTIP_BG_COLOR_DARK,
    TOOLTIP_BG_COLOR_NAVY,
    TOOLTIP_TEXT_COLOR,
    WHITE_COLOR,
    TICK_LIMITS,
} from '../../constants';
import { useAppSelector } from '@hooks';
import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';
import { mergeObjects } from '@util/common/util';
import { LineChart, LoadingBlock } from '@components';

const formatSensorList = (
    {
        SOUND_NOISE: soundNoiseList = [],
        CO2: co2List = [],
        TEMPERATURE: temperatureList = [],
        HUMIDITY: humidityList = [],
    },
    t,
) => {
    const lists = [soundNoiseList, co2List, temperatureList, humidityList];

    return lists.map((list, index) => {
        let name;
        let unit;
        let min = Number.MAX_SAFE_INTEGER;
        let max = Number.MIN_SAFE_INTEGER;
        const stepSize = Math.min(TICK_LIMITS, list.length);
        const startTime = list[0]?.regDate * 1000;
        const endTime = list[list.length - 1]?.regDate * 1000;
        const offset = Math.floor((endTime - startTime) / stepSize);

        const data = list.reduce(
            (acc, value, index) => {
                const { currentValue, regDate, sensingType, measurementUnit } = value;
                name = sensingType;
                unit = measurementUnit;
                min = Math.min(min, currentValue);
                max = Math.max(max, currentValue);

                const datasetValue =
                    name === ADD_SENSOR_ITEM_NAME.TEMPERATURE || name === ADD_SENSOR_ITEM_NAME.HUMIDITY
                        ? Number(currentValue).toFixed(1)
                        : Number(currentValue);

                if (index < TICK_LIMITS && !isNaN(startTime) && !isNaN(endTime)) {
                    acc['labels'].push(startTime + index * offset);
                }
                acc['datasets'][0].data.push({ x: regDate * 1000, y: datasetValue });
                return acc;
            },
            {
                labels: [],
                datasets: [
                    {
                        label: t(Object.values(ADD_SENSOR_ITEM_NAME)[index]),
                        data: [],
                        forcedPointBackgroundColor: WHITE_COLOR,
                        forcedBorderColor: LINE_CHART_COLOR,
                        forcedPointBorderColor: LINE_CHART_COLOR,
                        forcedBackgroundColor: LINE_CHART_COLOR,
                    },
                ],
            },
        );

        return { name, unit, data, min, max };
    });
};

const customTooltip = (colorScheme, t) => ({
    tooltip: {
        backgroundColor: colorScheme === DARK_THEME ? TOOLTIP_BG_COLOR_DARK : TOOLTIP_BG_COLOR_NAVY,
        titleColor: colorScheme === DARK_THEME ? WHITE_COLOR : TOOLTIP_TEXT_COLOR,
        bodyColor: colorScheme === DARK_THEME ? WHITE_COLOR : TOOLTIP_TEXT_COLOR,
        callbacks: {
            title: tooltipItem => {
                return `${t('Recognition Time', 'RealTimeEnvironmentalSensor')} ${tooltipItem[0].label}`;
            },
            label: tooltipItem => {
                let tooltipValue = tooltipItem.formattedValue;
                // Floating Bar Chart 에서 최대 최소 툴팁 표기
                if (tooltipValue.includes('[')) {
                    tooltipValue = JSON.parse(tooltipValue)
                        .sort((l, r) => l - r)
                        .join(' ~ ');
                }
                return `${t(tooltipItem.dataset.label)} ${tooltipValue}`;
            },
        },
    },
});

const MainView = ({ targetId }) => {
    const t = useTranslation('CommonColumn');
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);
    const { mode } = useAppSelector(state => state.ScreenInfo);
    const [sensorInfo, setSensorInfo] = useState({});
    const [sensorList, setSensorList] = useState([]);

    const [date, setDate] = useState(moment().unix());
    const handleDate = selected => {
        setDate(selected);
    };

    // 센서 그래프 조회
    const {
        promise: getEnvironmentalSensorDetailGraph,
        state: { isLoading },
    } = useAsync({
        promise: getEnvironmentalSensorDetailGraphApi,
        resolve: ({ recentEcoSignSensor, ecoSignSensorList }) => {
            setSensorInfo(recentEcoSignSensor);
            setSensorList(formatSensorList(ecoSignSensorList, t));
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        if (mode === SCREEN_MODE_EDIT || mode === SCREEN_MODE_PREVIEW) {
            setSensorInfo(DUMMY_GRAPH.recentEcoSignSensor);
            setSensorList(formatSensorList(DUMMY_GRAPH.ecoSignSensorList, t));
            return;
        }
        getEnvironmentalSensorDetailGraph({ searchDate: date, targetId });
    }, [date]);

    return (
        <>
            <InfoTable sensorInfo={sensorInfo} loading={isLoading} />
            <Search date={date} onChange={handleDate} loading={isLoading} />

            {/* 맥박-체온 순서 보장 */}
            {GRAPH_SORT.map((key, index) => {
                const data = sensorList[index]?.data;
                const unit = sensorList[index]?.unit;
                return (
                    <div key={`${ADD_SENSOR_ITEM_NAME[key]}_${index}`} className="mb-5">
                        <div className="w-100 d-flex align-items-end mb-1">
                            <span className="pnt-txt txt-bold s-6">{t(ADD_SENSOR_ITEM_NAME[key])}</span>
                            {unit && <span className="pnt-txt ml-1 mb-1">({unit})</span>}
                        </div>
                        <LoadingBlock blocking={isLoading}>
                            <div style={{ height: `${GRAPH_AREA.HEIGHT}px` }}>
                                {data && data.labels.length > 0 ? (
                                    <LineChart
                                        data={data}
                                        options={mergeObjects(DEFAULT_CHART_OPTIONS, {
                                            plugins: customTooltip(colorScheme, t),
                                            scales: {
                                                y: {
                                                    min: sensorList[index].min - 10,
                                                    max: sensorList[index].max + 10,
                                                },
                                            },
                                        })}
                                    />
                                ) : (
                                    <NoDataBlock className={cx(styles.detail_graph, 'border-size-1 border-depth-7')} />
                                )}
                            </div>
                        </LoadingBlock>
                    </div>
                );
            })}
        </>
    );
};

export default MainView;
