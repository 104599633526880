import React from 'react';
import { Button, DatePicker, Select } from '@components';
import { useTranslation } from '@hooks';
import useAdminCheck from '../util/useAdminCheck';
import { MEDICAL_WASTE } from '../../constants';
import { postReadingExcelApi } from '@api/sh/waste';
import { useAsyncExcel } from '../../util/useAsyncExcel';
import InstructionDownloadButton from '../Components/InstructionDownloadButton';

const Header = ({
    options,
    selectedWard,
    setSelectedWard,
    startDate,
    handleStartChange,
    endDate,
    handleEndChange,
    getReadingStatus,
}) => {
    const t = useTranslation(MEDICAL_WASTE);
    const isAdmin = useAdminCheck();

    const triggerDownload = useAsyncExcel({
        promise: postReadingExcelApi,
        param: {
            ward: selectedWard.value,
            startDate,
            endDate,
            columnMetas: [
                {
                    key: 'wardName',
                    name: '부서',
                },
                {
                    key: 'wasteType',
                    name: '폐기물종류',
                },
                {
                    key: 'readCase',
                    name: '현재 상태',
                },
                {
                    key: 'readTimeAStr',
                    name: '보관시작일자',
                },
                {
                    key: 'storageTime',
                    name: '보관시간',
                },
                {
                    key: 'readTimeAStr',
                    name: '부착전 시간',
                },
                {
                    key: 'readTimeBStr',
                    name: '수집 시간',
                },
                {
                    key: 'readTimeCStr',
                    name: '이송 시간',
                },
                {
                    key: 'readTimeDStr',
                    name: '수거 시간',
                },
                {
                    key: 'kkrCode',
                    name: 'KKR코드',
                },
                {
                    key: 'rfidCode',
                    name: 'RFID코드',
                },
                {
                    key: 'ward',
                    name: 'DEPTCODE',
                },
            ],
            zipFileName: 'reading_status',
            fileName: 'reading_status',
        },
    });

    const handleSearchClick = () => {
        getReadingStatus();
    };

    const handleDownloadClick = () => {
        triggerDownload();
    };

    return (
        <div className="d-flex justify-content-between">
            <div className="d-flex gap-2">
                <div className="d-flex gap-2 align-items-center">
                    <span className="fw-bold">{t('Ward')}</span>
                    <Select
                        value={selectedWard}
                        options={options}
                        onChange={selected => {
                            setSelectedWard(selected);
                        }}
                        // disabled={!isAdmin}
                    />
                </div>
                <div className="d-flex gap-2 align-items-center">
                    <span className="fw-bold">{t('Storage Start Date')}</span>
                    <>
                        <DatePicker
                            value={startDate}
                            onChange={handleStartChange}
                            withoutTime
                            valueType="s"
                            disabledKeyboardNavigation
                        />
                        <span>~</span>
                        <DatePicker
                            value={endDate}
                            onChange={handleEndChange}
                            withoutTime
                            valueType="s"
                            disabledKeyboardNavigation
                            minDate={startDate * 1000}
                        />
                    </>
                </div>
                <Button className="btn-secondary" onClick={handleSearchClick}>
                    {t('Search', 'Search')}
                </Button>
            </div>
            <div className="d-flex gap-2">
                <Button className="btn-lightgray btn-icon" onClick={handleDownloadClick}>
                    {t('Download')}
                </Button>
                <InstructionDownloadButton />
            </div>
        </div>
    );
};

export default Header;
