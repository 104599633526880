import React, { useContext, useMemo, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { Table, Modal } from '@components';
import { useColumns, useTranslation } from '@hooks';
import Bookmark from '../../../Components/Bookmark';
import { RealTimeVitalSignsDispatchContext } from '../index';
import { setIsDetail, setTargetNum } from '../vitalSignsRealTimePartronPoCReducer';
import {
    TABLE_HEADER_HEIGHT,
    TABLE_ROW_HEIGHT,
    TABLE_BORDER_WIDTH,
    STATUS_COLOR,
    DEFAULT_DISPLAY_VALUE,
} from '../constants';
import { getBatteryDisplayValue, getBatteryIconClassName } from '../util';
import { BatteryError } from './BatteryError';

/**
 * 병상 리스트 컴포넌트
 *
 * @param bedList 병상 목록
 * @param handleRefreshData 환자별 활력징후 데이터 새로고침 handler
 **/
const VitalSignsBedList = ({ bedList, handleRefreshData }) => {
    const t = useTranslation('VitalSignMonitoring');
    // 렌더링을 위한 Entry
    const bedEntries = Object.entries(bedList);
    const dispatch = useContext(RealTimeVitalSignsDispatchContext);

    const flattenData = useMemo(() => {
        return [].concat(
            ...bedEntries.map(([_, patientList]) => Object.values(patientList).map(patient => ({ ...patient }))),
        );
    }, [bedEntries]);

    const handleViewDetail = targetId => {
        dispatch(setTargetNum(targetId));
        dispatch(setIsDetail(true));
    };

    // 북마크 관련 모달
    const [bookmarkErrorModal, setBookmarkErrorModal] = useState(false);
    const toggleBookmarkErrorModal = () => setBookmarkErrorModal(!bookmarkErrorModal);
    const bookmarkErrorModalOkCallback = () => handleRefreshData();
    const handleBookmarkSuccess = () => handleRefreshData();
    const handleBookmarkFailure = () => setBookmarkErrorModal(true);

    const columns = useColumns(
        [
            {
                Header: 'Bed',
                accessor: 'properties.bed',
                width: 20,
                Cell: ({ value }) => {
                    return <span>{value || '-'}</span>;
                },
                className: 'text-ellipsis',
            },
            { Header: 'Patient Name', accessor: 'targetName', width: 40, className: 'text-ellipsis' },
            { Header: 'Patient ID', accessor: 'targetId', width: 40, className: 'text-ellipsis' },
            {
                Header: 'HB',
                accessor: 'HEARTBEAT.value',
                width: 60,
                headerClassName: 'text-ellipsis bg-depth-9',
                className: 'bg-depth-3 p-0',
                Cell: ({ row: { original } }) => {
                    const HB = original?.HEARTBEAT;
                    let value = HB?.value;

                    // 최초 등록 후 값을 한번도 받지 않았을 때의 처리 (N/A)
                    if (!HB || (value === 0 && !HB.status)) {
                        value = DEFAULT_DISPLAY_VALUE;
                    }

                    return (
                        <div
                            className={cx(
                                'd-flex justify-content-center align-items-center h-100 pnt-txt txt-bold s-7',
                                STATUS_COLOR[HB?.status]?.BG,
                                STATUS_COLOR[HB?.status]?.COLOR,
                            )}
                        >
                            {value === DEFAULT_DISPLAY_VALUE ? value : Math.round(value)}
                        </div>
                    );
                },
            },
            {
                Header: 'Battery',
                accessor: 'BATTERY.value',
                headerClassName: 'text-ellipsis',
                width: 20,
                Cell: ({ value }) => {
                    const remainsBattery = Math.round(value);

                    return (
                        <div className="d-flex justify-content-between gap-2 align-items-center pnt-txt s-9 text-ellipsis">
                            {!remainsBattery ? (
                                <BatteryError />
                            ) : (
                                <span
                                    className={cx(
                                        'material-icons-round md-20 rotate-2',
                                        remainsBattery && remainsBattery <= 10 && 'color-danger',
                                    )}
                                >
                                    {getBatteryIconClassName(remainsBattery)}
                                </span>
                            )}
                            <span className={cx(remainsBattery && remainsBattery <= 10 && 'color-danger')}>
                                {t(getBatteryDisplayValue(remainsBattery))}
                            </span>
                        </div>
                    );
                },
            },
            {
                Header: 'Recognition Time',
                accessor: 'regDate',
                width: 40,
                headerClassName: 'text-ellipsis',
                className: 'text-ellipsis',
                Cell: ({ value }) => (
                    <span title={moment.unix(value).format('YYYY-MM-DD HH:mm:ss')}>
                        {value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : '-'}
                    </span>
                ),
            },
            {
                Header: 'Sensor Name',
                accessor: 'sensorName',
                width: 40,
                headerClassName: 'text-ellipsis',
                className: 'text-ellipsis',
            },
            { Header: 'Classification', accessor: 'deviceName', width: 30, headerClassName: 'text-ellipsis' },
            {
                Header: 'Bookmark',
                accessor: 'isBookmark',
                width: 10,
                headerClassName: 'text-ellipsis',
                className: 'd-flex flx-center',
                Cell: ({ row: { original } }) => {
                    return (
                        <Bookmark
                            isBookmark={original.isBookmark}
                            targetNum={original.targetNum}
                            successCallback={handleBookmarkSuccess}
                            failureCallback={handleBookmarkFailure}
                        />
                    );
                },
            },
        ],
        t,
        [],
    );

    return (
        <>
            <div
                style={{
                    height: `${TABLE_HEADER_HEIGHT + flattenData.length * TABLE_ROW_HEIGHT + TABLE_BORDER_WIDTH}px`,
                }}
            >
                <Table
                    columns={columns}
                    data={{ rows: flattenData }}
                    paging={false}
                    onTrClick={({ targetId }) => handleViewDetail(targetId)}
                />
            </div>
            <Modal
                initModal={bookmarkErrorModal}
                toggleModal={toggleBookmarkErrorModal}
                headerTitle={t('Bookmark')}
                bodyText={t('Something has changed.', 'ConfirmModal')}
                removeCancel
                okCallback={bookmarkErrorModalOkCallback}
            />
        </>
    );
};

export default VitalSignsBedList;
