import React, { useEffect, useRef, useState } from 'react';
import { GeofenceLayer, Map, RotatedImageOverlay } from '@components';
import Control from 'react-leaflet-control';
import { useSelector } from 'react-redux';
import useAsync from '@hooks/useAsync';
import { fetchGeofenceList } from '@api/common/geofence';
import { useSettings } from '../../util/useSettings';
import L from 'leaflet';
import GeofenceInfoPopup from './Component/GeofenceInfoPopup';
import WidgetCard from '../../Components/WidgetCard';
import SingleTreeSelect from '@components/Select/SingleTreeSelect';
import { selectFirstFloor } from '@reducer/Common/FloorInfo';

const GeofenceStatus = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { hiddenMapTile } = settings;
    const confirmedTile = hiddenMapTile !== undefined ? !hiddenMapTile : true;
    const mapRef = useRef();
    const widgetRef = useRef();
    const [floorInfo, setFloorInfo] = useState({});
    const [geofenceInfo, setGeofenceInfo] = useState([]);
    const [selectedGeofence, setSelectedGeofence] = useState({});
    const { geofenceInOutState } = useSelector(state => state.TagInfo);
    const { floorList } = useSelector(state => state.FloorInfo);
    const { floor } = selectFirstFloor(floorList);
    const { promise: getGeofenceList, state: getGeofenceInfo } = useAsync({ promise: fetchGeofenceList });
    const handleFloorChange = selects => {
        if (selects) {
            setFloorInfo(selects);
        }
    };

    useEffect(() => {
        if (floorInfo?.floorId) {
            getGeofenceList({ floor: floorInfo.floorId });
            const map = mapRef.current.leafletElement;
            if (map) {
                map.eachLayer(layer => {
                    if (layer instanceof L.ImageOverlay) {
                        map.fitBounds(layer.getBounds());
                        return false;
                    }
                });
            }
        } else {
            const currentFloor = settings?.floor ? floorList.find(e => e.floorId === settings.floor) : floor;
            setFloorInfo(currentFloor);
        }
    }, [floorInfo]);

    useEffect(() => {
        const { response } = getGeofenceInfo;
        if (response) {
            setGeofenceInfo(
                response.rows.map(geofence => {
                    const inOutInfo = geofenceInOutState[geofence.fcNum] || {};
                    return {
                        ...geofence,
                        bounds: [geofence.latLngList.map(({ lat, lng }) => [lat, lng])],
                        authorized: inOutInfo.authorized,
                        unAuthorized: inOutInfo.unAuthorized,
                    };
                }),
            );
        }
    }, [getGeofenceInfo, geofenceInOutState]);

    return (
        <WidgetCard widgetInfo={widgetInfo} bodyClassName={'p-1'} {...restProps} ref={widgetRef}>
            <Map tile={confirmedTile} ref={mapRef} rotation={floorInfo?.rotation}>
                <Control position="topleft">
                    <SingleTreeSelect
                        data={floorList}
                        title={'Category'}
                        valueKey={'floorId'}
                        labelKey={'floorName'}
                        parentKey={'upperFloorId'}
                        selected={floorInfo?.floorId}
                        onChange={handleFloorChange}
                        isMap
                    />
                </Control>
                {floorInfo?.imgURL && (
                    <RotatedImageOverlay
                        key={floorInfo?.floorId}
                        url={floorInfo.imgURL}
                        deg={floorInfo.deg}
                        bounds={floorInfo.bounds}
                        onLoad={e => {
                            const { target: layer } = e;
                            layer._map.fitBounds(layer.getBounds());
                        }}
                    />
                )}
                {geofenceInfo.length > 0 && (
                    <GeofenceLayer
                        geofenceList={geofenceInfo}
                        authState
                        handleClick={geofence => setSelectedGeofence(geofence)}
                    />
                )}

                <GeofenceInfoPopup
                    widgetRef={widgetRef}
                    mapRef={mapRef}
                    modal={Object.keys(selectedGeofence).length > 0}
                    toggleModal={() => setSelectedGeofence({})}
                    selectedEvent={selectedGeofence}
                />
            </Map>
            {children}
        </WidgetCard>
    );
};

export default GeofenceStatus;
