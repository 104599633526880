import React from 'react';
import ItemCount from './ItemCount';
import InnerItemCard from '@components/Card/InnerItemCard';
import { SUMMARY_LIST } from '../../constants';
import NoDataBlock from '../../../../Components/NoDataBlock';
import useTranslation from '@hooks/useTranslation';
import { ASSET_MANAGEMENT } from '../../../../constants';
import { LoadingBlock } from '@components';

const CountByAssetStatus = ({ countByAssetStatus, selectedStatusType, handleSelectedStatusType, isLoading }) => {
    const t = useTranslation(ASSET_MANAGEMENT);
    return (
        <InnerItemCard>
            <LoadingBlock blocking={isLoading}>
                {countByAssetStatus ? (
                    <div className="widget-item-nums">
                        {SUMMARY_LIST.map((summaryItem, idx) => (
                            <ItemCount
                                key={`${summaryItem.key}_${idx}`}
                                label={t(summaryItem.label)}
                                end={countByAssetStatus[summaryItem.key] ?? 0}
                                isSelected={selectedStatusType === summaryItem.value}
                                handleLabelClick={() => handleSelectedStatusType(summaryItem.value)}
                                bubbleText={summaryItem.bubbleText}
                            />
                        ))}
                    </div>
                ) : (
                    <NoDataBlock />
                )}
            </LoadingBlock>
        </InnerItemCard>
    );
};

export default CountByAssetStatus;
