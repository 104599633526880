import React from 'react';
import cx from 'classnames';
import styles from '@asset/pnt/widgets/RealTimeSensorMonitoring.module.scss';
import { SENSOR, STATUS } from '../constants';

const STATUS_SUB_COLOR = {
    CRITICAL: 'd-2',
    NORMAL: 'dark',
    OUTOFRANGE: 'd-3',
    WARNING: 'dark',
};

/**
 * Sensor List 의 Status Item Image
 *
 * @param sensorItem
 */
const SensorStatusImage = ({ sensorItem }) => {
    const { status, sensingType } = sensorItem;

    return (
        <div className={cx(styles.sensor_img, status ? `bg-${STATUS[status].color}` : 'bg-depth-9')}>
            {/* Sensor icon */}
            <span className={SENSOR[sensingType] ?? SENSOR.COMMON} />

            {/* Sensor status */}
            <div
                className={cx(
                    styles.status,
                    status ? `bg-${STATUS[status].color} bg-${STATUS_SUB_COLOR[status]}` : 'bg-depth-10',
                )}
            >
                <span className="material-icons-round">{STATUS[status].iconName}</span>
            </div>
        </div>
    );
};
export default SensorStatusImage;
