import L from 'leaflet';

// rotate popup
L.Popup.include({
    onAdd: function (map) {
        L.DivOverlay.prototype.onAdd.call(this, map);

        this._zoomAnimated = true;

        map.fire('popupopen', { popup: this });

        if (this._source) {
            this._source.fire('popupopen', { popup: this }, true);
            if (!(this._source instanceof L.Path)) {
                this._source.on('preclick', L.DomEvent.stopPropagation);
            }
        }
    },
});

// mapPane이 이동하면서 틀어진 좌표를 다시 원래 좌표로 이동시키는 기능
export const setRotatedCanvasTransform = (map, canvas) => {
    const translateRegExp = /translate3d\((-?\.?\d*\.?\d+)px, (-?\.?\d*\.?\d+)px, (-?\.?\d*\.?\d+)px\)/i;
    const prevTransform = map.getPane('mapPane').style[L.DomUtil.TRANSFORM];
    const matches = prevTransform.match(translateRegExp);
    if (prevTransform && matches?.length === 4) {
        canvas.style[L.DomUtil.TRANSFORM] = `translate3d(${0 - Number(matches[1])}px, ${0 - Number(matches[2])}px, ${
            0 - Number(matches[3])
        }px)`;
    } else {
        canvas.style[L.DomUtil.TRANSFORM] = 'unset';
    }
};
