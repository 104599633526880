import { ChartData, ChartType } from 'chart.js';
import { useMemo } from 'react';

const useChartData = <TType extends ChartType>(data: ChartData<TType>): ChartData<TType> => {
    return useMemo<ChartData<TType>>(() => {
        return { labels: data.labels ?? [], datasets: data.datasets ?? [] };
    }, [data]);
};

export default useChartData;
