import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import useTranslation from '@hooks/useTranslation';
import { useAsync } from '@hooks';
import { fetchScannerListApi, fetchScannerStateLogList } from '@api/common/log';
import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';

import { setScannerList, setScannerNums } from './scannerStatusLogSlice';
import WidgetCard from '../../Components/WidgetCard';
import ExcelExport from './Component/ExcelExport';
import Search from './Component/Search';
import List from './Component/List';
import { useScannerStatusLogDispatch } from './index';

export const DEFAULT_SELECT_VALUE = 0;

const ScannerStatusLogContainer = ({ widgetInfo, children, ...restProps }) => {
    const t = useTranslation('Scanner State Log');
    const { mode } = useSelector(state => state.ScreenInfo);
    const dispatch = useScannerStatusLogDispatch();
    const { selectableFloorList } = useSelector(state => state.FloorInfo);
    const [startDate, setStartDate] = useState(moment().startOf('day').valueOf());
    const [endDate, setEndDate] = useState(moment().startOf('day').valueOf());
    const handleDateCallback = ({ selected, type }) => {
        switch (type) {
            case 'startDate':
                setStartDate(selected);
                break;
            case 'endDate':
                setEndDate(selected);
                break;
            case 'yesterday':
                const yesterday = moment().startOf('day').subtract(1, 'day');
                setStartDate(yesterday.valueOf());
                setEndDate(yesterday.add(23, 'hours').add(59, 'm').add(59, 'second').valueOf());
                break;
            case 'reset':
                setStartDate(moment().startOf('day').valueOf());
                setEndDate(moment().startOf('day').valueOf());
                break;
        }
    };

    useAsync({
        promise: fetchScannerListApi,
        immediate: true,
        resolve: res => {
            const { rows } = res;
            dispatch(
                setScannerList(rows.map(({ scannerName, scannerNum }) => ({ label: scannerName, value: scannerNum }))),
            );
            dispatch(setScannerNums(rows.map(({ scannerNum }) => scannerNum)));
        },
        reject: err => {
            dispatch(setScannerList([{ label: t('Not Select'), value: DEFAULT_SELECT_VALUE }]));
            dispatch(setScannerNums([DEFAULT_SELECT_VALUE]));
            console.error(err);
        },
    });

    const [list, setList] = useState({
        rows: [],
        totalPage: 1,
        totalCount: 0,
    });

    const { promise: getScannerStateLogList, state: scannerStateLogListInfo } = useAsync({
        promise: fetchScannerStateLogList,
        fixedParam: { pageSize: 15 },
        resolve: res => {
            const { rows } = res;
            const data = rows.map(log => {
                return {
                    logNum: log.logNum,
                    scannerName: log.scannerName,
                    macAddr: log.macAddr,
                    startDate: log.startDate,
                    endDate: log.endDate,
                    ...log,
                };
            });
            setList({ ...res, rows: data });
        },
        reject: err => console.error(err),
    });
    useEffect(() => {
        getScannerStateLogList({
            startDate: moment(startDate).unix(),
            endDate: moment(endDate).unix(),
            floorIds: selectableFloorList[0]?.floorId,
            floorIdPath: selectableFloorList[0]?.floorIdPath,
        });
    }, []);

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            headerAction={
                mode !== SCREEN_MODE_EDIT &&
                mode !== SCREEN_MODE_PREVIEW && (
                    <ExcelExport startDate={startDate} endDate={endDate} listCount={list.rows.length} />
                )
            }
            {...restProps}
        >
            <Search
                startDate={startDate}
                endDate={endDate}
                handleDateCallback={handleDateCallback}
                promise={getScannerStateLogList}
            />

            <List list={list} state={scannerStateLogListInfo} promise={getScannerStateLogList} />
            {children}
        </WidgetCard>
    );
};

export default ScannerStatusLogContainer;
