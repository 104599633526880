import React from 'react';
import cx from 'classnames';
import Label from '@components/Label';
import useTranslation from '@hooks/useTranslation';

/**
 * @param {Array} option  // 테마 컬러값
 * @param {function} onClick  // 테마 컬러 핸들링
 * @param {string} selectedColorScheme // 선택한 테마 컬러
 *
 @author created bu 정진범 2022-12-08
 */

const ThemeSwatch = ({ option, onClick, selectedColorScheme }) => {
    const t = useTranslation('UserBox');
    return (
        <Label
            name={t('Choose color scheme')}
            value={
                <div className="theme-settings-swatches">
                    {option.map((className, idx) => {
                        return (
                            <div
                                key={idx}
                                className={cx('swatch-holder cursor-pointer', className, {
                                    active: selectedColorScheme === className,
                                })}
                                onClick={() => onClick(className)}
                            />
                        );
                    })}
                </div>
            }
        />
    );
};
export default ThemeSwatch;
