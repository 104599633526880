import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    selectedIotItemStatus: null,
    target: '',

    selectedIotItem: null,
    iotItemList: {
        totalCount: 0,
        totalPage: 1,
        rows: [],
    },
    iotItemListParam: {
        pageSize: 15,
        page: 1,
        // 자산 태그 매핑 포함 여부 (포함 : Y, 미포함 : N)
        isIncludeConfig: 'Y',
    },
    iotItemCount: {
        totalCount: 0,
        restrictedCount: 0,
        lostSignalCount: 0,
        lowBatteryCount: 0,
        neverDetectedCount: 0,
    },
};

const { actions, reducer } = createSlice({
    name: 'iotItemStatus',
    initialState,
    reducers: {
        // handle count label click
        setSelectedIotItemStatus: (state, action) => {
            const selectItem = action.payload;
            if (state.selectedIotItemStatus === selectItem) {
                return;
            }
            state.selectedIotItemStatus = selectItem;
        },
        // handle search target input value
        setSearchTarget: (state, action) => {
            const target = action.payload;
            if (!target) {
                state.target = '';
                return;
            }
            state.target = target;
        },
        clearIotItemStatusWidget: (state, action) => {
            // state.iotItemListParam = { pageSize: 15, page: 1, isIncludeConfig: 'Y', ...action.payload };
            state.target = null;
            state.selectedIotItemStatus = null;
        },
    },
});

export const { setSelectedIotItemStatus, setSearchTarget, clearIotItemStatusWidget } = actions;

export default reducer;
