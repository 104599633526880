import React, { useEffect, useMemo, useRef } from 'react';
import { useRealTimeSensorMonitoringContext, useRealTimeSensorMonitoringDispatch } from '../index';
import { updateSensingData, updateMonitoringState } from '../realTimeSensorMonitoringSlice';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_NUMERIC_SENSING } from '@reducer/SocketInfo';
import SensorItemCard from './SensorItemCard';

/**
 * RealTimeSensorMonitoring 의 Content, Sensor 목록을 보여줌
 */
const SensorList = () => {
    const intersectionRootRef = useRef();
    const dispatch = useRealTimeSensorMonitoringDispatch();
    const { monitoringState, sensorList, sensorDataList } = useRealTimeSensorMonitoringContext();

    const { createObserver, observeAll, unObserveAll, destroy } = useIntersectionObserver({
        handleIntersect: intersectionItems => {
            dispatch(updateMonitoringState(intersectionItems));
        },
    });

    // Socket
    const filterConfig = useMemo(() => {
        const findSensorNum = Object.values(monitoringState).reduce((acc, itemState) => {
            if (itemState.monitoring) {
                acc.add(itemState.sensorNum);
            }
            return acc;
        }, new Set());
        return { sensor: { sensorNum: { $in: [...findSensorNum] } } };
    }, [monitoringState]);

    useSocketEvent({
        name: EVENT_TYPE_NUMERIC_SENSING,
        handler: dataList => {
            dispatch(updateSensingData(dataList));
        },
        filterConfig,
        enableBuffer: true,
    });

    useEffect(() => {
        createObserver();
        return () => {
            destroy();
        };
    }, []);

    useEffect(() => {
        observeAll(intersectionRootRef.current);
        return () => {
            unObserveAll();
        };
    }, [sensorList]);

    return (
        <div ref={intersectionRootRef} className="w-100 h-100 overflow-auto grid-container item-grid-250">
            {sensorDataList.map((sensorItem, i) => (
                <div
                    key={`${sensorItem.sensorNum}_${sensorItem.sensingType}_${i}`}
                    data-item-key={sensorItem.sensorNum ? `${sensorItem.sensorNum}_${sensorItem.sensingType}` : ''}
                    data-page={sensorItem.page ?? 0}
                >
                    <SensorItemCard sensorItem={sensorItem} />
                </div>
            ))}
        </div>
    );
};

// target이 0.1 만큼 보일 때
const INTERSECTION_OPTION = { threshold: 0.1 };
const INTERSECTION_DEBOUNCE_TIME = 400;

// intersection Observer
const useIntersectionObserver = ({ handleIntersect, intersectionOption, intersectionDebounceTime }) => {
    const observer = useRef();

    const debounceTimeoutRef = useRef();
    const clearDebounce = () => {
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
            debounceTimeoutRef.current = null;
        }
    };

    // 관찰 아이템 등록
    const observe = element => {
        if (observer.current && element) {
            observer.current.observe(element);
        }
    };

    // 모든 자식 엘리먼트를 관찰 아이템으로 등록
    const observeAll = parent => {
        if (observer.current && parent) {
            for (const item of parent.children) {
                observe(item);
            }
        }
    };

    // 관찰 아이템 해제
    const unObserve = element => {
        if (observer.current && element) {
            observer.current.unobserve(element);
        }
    };

    // 등록된 모든 관찰 아이템 해제
    const unObserveAll = () => {
        if (observer.current) {
            observer.current.disconnect();
        }
    };

    // 관찰자 제거
    const destroy = () => {
        if (observer.current) {
            clearDebounce();
            unObserveAll();
            observer.current = null;
        }
    };

    // 교차 확인 관찰자 생성
    const createObserver = () => {
        let monitoringState = [];
        observer.current = new IntersectionObserver(entries => {
            entries.forEach(({ target, isIntersecting }) => {
                monitoringState.push({ ...target.dataset, isIntersecting });
            });

            clearDebounce();
            debounceTimeoutRef.current = setTimeout(() => {
                if (typeof handleIntersect === 'function') {
                    handleIntersect(monitoringState.slice());
                }
                monitoringState = [];
            }, intersectionDebounceTime ?? INTERSECTION_DEBOUNCE_TIME);
        }, intersectionOption ?? INTERSECTION_OPTION);
    };

    return { createObserver, observer: observer.current, observe, observeAll, unObserve, unObserveAll, destroy };
};

export default SensorList;
