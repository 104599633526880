import moment from 'moment';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import useTranslation from '@hooks/useTranslation';
import Select from '@components/Select';

const CLOCK_VALUE_SET = {
    HOURS: Array.from({ length: 24 }, (_, i) => ({ value: i, label: String(i).padStart(2, '0') })),
    MINUTES: Array.from({ length: 60 }, (_, i) => ({ value: i, label: String(i).padStart(2, '0') })),
    SECONDS: Array.from({ length: 60 }, (_, i) => ({ value: i, label: String(i).padStart(2, '0') })),
};

const TimeSelect = ({ className, targetDate, handleTimeChange }) => {
    const t = useTranslation('DatePicker');
    const [targetDateMoment, setTargetDateMoment] = useState(moment.unix(targetDate));

    const handleTime = ({ hour, min, sec }) => {
        const resultDate = moment.unix(targetDateMoment.unix());
        if (hour != null) {
            resultDate.hour(hour);
        }
        if (min != null) {
            resultDate.minute(min);
        }
        if (sec != null) {
            resultDate.second(sec);
        }

        setTargetDateMoment(resultDate);
        handleTimeChange(resultDate.unix());
    };

    const handleHour = ({ value }) => handleTime({ hour: value });
    const handleMinute = ({ value }) => handleTime({ min: value });
    const handleSecond = ({ value }) => handleTime({ sec: value });

    useEffect(() => {
        if (Number.isNaN(targetDate)) {
            return;
        }
        const targetMoment = moment.unix(targetDate);
        const convertedDate = moment().year(targetMoment.year()).month(targetMoment.month()).date(targetMoment.date());
        setTargetDateMoment(convertedDate);
        handleTimeChange(convertedDate.unix());
    }, [targetDate]);

    return (
        <div className={cx('w-100 d-flex justify-content-center align-center', className)}>
            <Select
                className="w-20"
                isModalSelect
                value={CLOCK_VALUE_SET.HOURS[targetDateMoment?.hour()]}
                options={CLOCK_VALUE_SET.HOURS}
                onChange={handleHour}
            />
            <span className="mx-2">{t('hour')}</span>
            <Select
                className="w-20"
                isModalSelect
                value={CLOCK_VALUE_SET.MINUTES[targetDateMoment?.minute()]}
                options={CLOCK_VALUE_SET.MINUTES}
                onChange={handleMinute}
            />
            <span className="mx-2">{t('min')}</span>
            <Select
                className="w-20"
                isModalSelect
                value={CLOCK_VALUE_SET.SECONDS[targetDateMoment?.second()]}
                options={CLOCK_VALUE_SET.SECONDS}
                onChange={handleSecond}
            />
            <span className="mx-2">{t('sec')}</span>
        </div>
    );
};

export default TimeSelect;
