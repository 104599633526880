import { useColumns, useTranslation } from '@hooks';
import * as mainCols from '../../../MainPages/util/grid/column';
import * as globalCols from '@util/grid/column';
import { DeleteActionCell, FileNameCell } from './Components/Cells';
import { useAssetManualManagementStateContext } from './slice';

export const useAssetManualManagementColumns = () => {
    const t = useTranslation('AssetManualManagement');
    const { managementMode } = useAssetManualManagementStateContext();

    const commonColumns = [
        globalCols.categoryName({ width: 150 }),
        mainCols.fileName({ className: 'text-start text-ellipsis', width: 250, Cell: FileNameCell }),
        mainCols.description({
            Header: 'Description',
            className: 'text-start text-ellipsis',
            width: 300,
        }),
        mainCols.registeredDate({ width: 150 }),
    ];

    return useColumns(
        managementMode
            ? [
                  ...commonColumns,
                  {
                      Header: '',
                      accessor: 'manualNum',
                      className: 'd-flex justify-content-center',
                      Cell: DeleteActionCell,
                      width: 50,
                  },
              ]
            : commonColumns,
        null,
        [managementMode],
    );
};
