import React, { useState } from 'react';
import { Button, TextInput } from '@components';
import { useTranslation } from '@hooks';

const Search = ({ handleSearchClick, handleReset }) => {
    const t = useTranslation('EmergencyAlertHistory');
    const [input, setInput] = useState('');
    const handleSearchInput = e => {
        const { value } = e.target;
        setInput(value);
    };

    const handleSearch = e => {
        e.preventDefault();
        handleSearchClick(input);
    };

    const handleRefresh = () => {
        handleReset();
        setInput('');
    };

    return (
        <form className="flx-row" onSubmit={handleSearch}>
            <TextInput
                type="text"
                name="target"
                value={input}
                handleChange={handleSearchInput}
                placeholder={t("Enter the patient's name or ID.")}
            />
            <Button type="submit" className="btn-secondary">
                {t('Search', 'Search')}
            </Button>
            <Button className="btn-line btn-icon-only ml-2" iconName="refresh" onClick={handleRefresh} />
        </form>
    );
};

export default Search;
