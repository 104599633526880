import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import { isDanger } from '@util/mappInfo';
import cx from 'classnames';
import 'react-toastify/dist/ReactToastify.css';
import { setToastLimit } from '@reducer/Notification';
import useThrottle from '@hooks/useThrottle';

// Toast Body child component
const Toast = ({ closeToast, data, toastProps }) => {
    const { targetName, interfaceCommandType, notificationDate } = data;
    const danger = isDanger(interfaceCommandType);
    return (
        <>
            <div className="d-flex mb-1">
                <span className={cx('pnt-txt txt-bold s-9', danger ? 'toast-danger' : 'toast-primary')}>
                    {interfaceCommandType}
                </span>
            </div>
            <div className="toast-contents">
                <div className="text-ellipsis">
                    <span className="pnt-txt s-8">{targetName}</span>
                </div>
                <div className="flex-shrink-0 ml-2">
                    <span className={'pnt-txt color-depth-9'}>{notificationDate}</span>
                </div>
            </div>
        </>
    );
};

const NotificationToast = () => {
    const dispatch = useDispatch();
    const {
        notificationContents,
        toastConfig: { useToast, autoClose, toastLimit },
    } = useSelector(state => state.Notification);

    const handleResize = () => {
        dispatch(setToastLimit(Math.floor((document.documentElement.clientHeight - 120) / 64)));
    };

    const throttleFunc = useThrottle(handleResize, 1000);

    useEffect(() => {
        window.addEventListener('resize', throttleFunc);
        return () => {
            window.removeEventListener('resize', throttleFunc);
        };
    }, []);

    useEffect(() => {
        // 알림 사용안함 또는 알림 대상 없는 경우
        if (!useToast || !notificationContents) {
            return;
        }
        // Toast call
        toast(Toast, { data: notificationContents });
    }, [notificationContents, useToast]);

    if (!useToast) {
        return null;
    }

    return (
        <ToastContainer
            newestOnTop
            position="bottom-right"
            rtl={false}
            limit={toastLimit}
            autoClose={autoClose}
            hideProgressBar
            closeOnClick={false}
            pauseOnHover={false}
            pauseOnFocusLoss={false}
            draggable={false}
            transition={Bounce}
        />
    );
};

export default NotificationToast;
