export const ROWS_DUMMY = [
    {
        categoryCode: 'wheelchair',
        targetName: '휠체어',
        comNum: 1,
        categoryName: '휠체어',
        parentCategoryCode: 'ASSET',
        parentCategoryName: 'Asset',
        ward: 9,
        wardName: '소화기내과',
        memo: '메모 테스트1',
        dayCount: null,
        dayRTLSCount: null,
        dayCheckerId: null,
        dayCheckerName: null,
        dayCheckDate: null,
        eveningCount: 1,
        eveningRTLSCount: 1,
        eveningCheckerId: 'pntbiz@pntbiz.com',
        eveningCheckerName: '강동경희대병원 관리자',
        eveningCheckDate: 1701507758,
        nightCount: null,
        nightRTLSCount: null,
        nightCheckerId: null,
        nightCheckerName: null,
        nightCheckDate: null,
        resRTLSCount: 8,
        dayCheckDateStr: null,
        eveningCheckDateStr: '2023-12-02 18:02:38',
        nightCheckDateStr: null,
    },
    {
        categoryCode: 'anesthesiamachine',
        targetName: '제세동기',
        comNum: 1,
        categoryName: '제세동기',
        parentCategoryCode: 'ASSET',
        parentCategoryName: 'Asset',
        ward: 9,
        wardName: '소화기내과',
        memo: '메모 테스트1',
        dayCount: 1,
        dayRTLSCount: 1,
        dayCheckerId: null,
        dayCheckerName: null,
        dayCheckDate: null,
        eveningCount: null,
        eveningRTLSCount: null,
        eveningCheckerId: 'pntbiz@pntbiz.com',
        eveningCheckerName: '강동경희대병원 관리자',
        eveningCheckDate: 1701507758,
        nightCount: null,
        nightRTLSCount: null,
        nightCheckerId: null,
        nightCheckerName: null,
        nightCheckDate: null,
        resRTLSCount: 16,
        dayCheckDateStr: null,
        eveningCheckDateStr: '2023-12-02 18:02:38',
        nightCheckDateStr: null,
    },
];

export const DEFAULT_VALUE = '-';

export const PREFIX_LIST = ['day', 'evening', 'night'];
