export const CHART_COLORS = [
    '#0088fe',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#9241ef',
    '#D024C9',
    '#F43865',
    '#FD7FF0',
    '#21C8FD',
    '#85DEDE',
    '#81BD00',
    '#249983',
    '#0081AA',
    '#634882',
    '#8E0689',
    '#4A31E5',
    '#8785FF',
    '#BA9953',
    '#FF5400',
    '#FF9BB3',
];

export const getColor = idx => {
    return CHART_COLORS[idx % CHART_COLORS.length];
};
