import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';
import { useAsync } from '@hooks';
import { getIotItemInfoLogListApi } from '@api/common/log';

import ExcelExport from './Component/ExcelExport';
import Search from './Component/Search';
import List from './Component/List';
import WidgetCard from '../../Components/WidgetCard';

const IotItemInfoLogContainer = ({ widgetInfo, children, ...restProps }) => {
    const { mode } = useSelector(state => state.ScreenInfo);
    const [list, setList] = useState({ totalCount: 0, totalPage: 1, rows: [] });

    const { promise: getIotItemInfoLogList, state: iotItemLogListInfo } = useAsync({
        promise: getIotItemInfoLogListApi,
        immediate: true,
        fixedParam: { pageSize: 15 },
        resolve: res => setList(res),
        reject: err => console.error(err),
    });

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            headerAction={
                mode !== SCREEN_MODE_EDIT &&
                mode !== SCREEN_MODE_PREVIEW && <ExcelExport listCount={list.rows.length} />
            }
            {...restProps}
        >
            <Search promise={getIotItemInfoLogList} />
            <List list={list} state={iotItemLogListInfo} promise={getIotItemInfoLogList} />
            {children}
        </WidgetCard>
    );
};

export default IotItemInfoLogContainer;
