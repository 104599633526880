import { Chart } from 'react-chartjs-2';
import { Color } from 'chart.js';

// Doughnut Inner Text Plugin Type
export interface DoughnutInnerTextOptions {
    text?: string;
    font?: { size?: string; color?: Color };
}

export default {
    id: 'doughnutInnerText',
    beforeDraw(chart: Chart, args: object, options: DoughnutInnerTextOptions): boolean | void {
        const { ctx } = chart;
        const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
        const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

        const theme = document.getElementsByClassName('app-theme-pnt-dark');

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = `${options?.font?.size || '14px'} pretendard`;
        ctx.fillStyle = `${options?.font?.color || (!!theme.length ? 'white' : 'black')}`;
        ctx.fillText(options?.text || '', centerX, centerY);
    },
    defaults: {
        text: '',
        font: { size: '14px', color: 'black' },
    },
};
