import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import TIMES from '@util/times';

interface GlobalIntervalTime {
    monitoring: boolean;
    useGlobalIntervalTime: boolean;
    globalIntervalTime: number;
}

const initialState: GlobalIntervalTime = {
    monitoring: true,
    useGlobalIntervalTime: true,
    globalIntervalTime: TIMES.SEC_10,
};

const { actions, reducer } = createSlice({
    name: 'globalIntervalTime',
    initialState,
    reducers: {
        startGlobalIntervalMonitoring: (state, action: PayloadAction<number>) => {
            if (!state.monitoring) {
                state.monitoring = !state.monitoring;
            }
            state.globalIntervalTime = action.payload;
        },
        stopGlobalMonitoring: state => {
            state.monitoring = false;
        },
        resetGlobalIntervalTimeDot: state => {
            state.useGlobalIntervalTime = true;
        },
        showGlobalIntervalTimeDot: state => {
            state.useGlobalIntervalTime = true;
        },
        hideGlobalIntervalTimeDot: state => {
            state.useGlobalIntervalTime = false;
        },
    },
});

export const {
    startGlobalIntervalMonitoring,
    stopGlobalMonitoring,
    resetGlobalIntervalTimeDot,
    showGlobalIntervalTimeDot,
    hideGlobalIntervalTimeDot,
} = actions;
export default reducer;
