import React, { useCallback, useContext, useEffect, useState } from 'react';
import Draggable from '@components/Draggable';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { useTranslation } from '@hooks';
import { RealTimeLocationTrackingContext } from '../index';
import styled from 'styled-components';
import * as column from '@util/grid/column';
import Table from '@components/Table';
import useColumns from '@hooks/useColumns';
import useAsync from '@hooks/useAsync';
import { getIotItemApi } from '@api/common/asset';
import { ASSET_MANAGEMENT } from '../../../constants';
import moment from 'moment';
import { DATE_FORMAT_YMD_HMS } from '../../MedicalWasteDashboard/constants';
import { ASSET_CODE } from '../../AssetRentalStatus/constants';
import GeofenceDetailPopupAssetDetail from './GeofenceDetailPopupAssetDetail';
import useCategoryProperties from '../../../util/useCategoryProperties';
import { convertProperties } from '../../../util/commons';

const WIDTH = { width: 20 };
const DEFAULT_VALUE = '-';

const GeofenceDetailPopup = ({ modal, toggleModal, widgetRef, mapRef }) => {
    const t = useTranslation('RealTimeLocationTracking');
    const amTs = useTranslation(ASSET_MANAGEMENT);
    const categoryProperties = useCategoryProperties({ categoryCode: ASSET_CODE });
    const { selectedGeofenceGroupData } = useContext(RealTimeLocationTrackingContext);
    const data = selectedGeofenceGroupData.data;
    const name = selectedGeofenceGroupData.name ?? DEFAULT_VALUE;

    const [position, setPosition] = useState({
        x: 0,
        y: 0,
    });
    const [popupSize, setPopupSize] = useState({
        x: 0,
        y: 0,
    });

    const [selectedTargetInfo, setSelectedTargetInfo] = useState(null);
    const [selectedTagInfo, setSelectedTagInfo] = useState(null);

    const popupRef = useCallback(node => {
        if (node !== null) {
            const { width, height } = node.getBoundingClientRect();

            setPopupSize({
                width,
                height,
            });
        }
    }, []);

    const getPosition = (el, position = { top: 0, left: 0 }) => {
        if (el) {
            position.top += el.offsetTop;
            position.left += el.offsetLeft;
            getPosition(el.offsetParent, position);
        }
        return position;
    };

    const getWidgetPosition = () => {
        const widgetPosition = { x: 0, y: 0 };

        if (widgetRef.current) {
            const widget = widgetRef.current;

            const { offsetWidth: width, offsetHeight: height } = widget;

            widgetPosition.width = width;
            widgetPosition.height = height;
        }
        return widgetPosition;
    };

    useEffect(() => {
        const widgetPosition = getWidgetPosition();

        const x = (widgetPosition.width - popupSize.width) / 2 - 20;
        const y = widgetPosition.height / 2 - widgetPosition.height / 4 - 100;
        setPosition({
            x: Math.ceil(x),
            y: Math.ceil(y),
        });
    }, [popupSize]);

    const handlerAssetDetail = assetInfo => {
        // 자산 정보가 없는 경우
        if (!assetInfo) {
            setSelectedTargetInfo(null);
            setSelectedTagInfo(null);
            return;
        }

        // 태그의 지오펜스 위치
        const location = assetInfo?.geofences.map(({ fcName }) => fcName).toString();
        // 대상 상세 정보 호출을 위한 대상 NumKey
        const targetNum = assetInfo?.target?.targetNum;
        // 태그 신호 상태
        const tagStatusList = Object.entries(assetInfo?.targetState ?? {}).reduce(
            (prevState, [currentKey, currentState]) => {
                if (currentState?.toUpperCase().includes('_ON')) {
                    prevState.push(currentKey);
                }
                return prevState;
            },
            [],
        );
        const tagStatus = tagStatusList.length !== 0 ? tagStatusList.toString() : DEFAULT_VALUE;
        // 마지막 수신 시간
        const recognitionDate = assetInfo.unixTime
            ? moment.unix(assetInfo.unixTime).format(DATE_FORMAT_YMD_HMS)
            : DEFAULT_VALUE;

        // 태그정보 취합
        const tagInfo = { ...(assetInfo?.tag ?? {}), location, tagStatus, recognitionDate };
        setSelectedTagInfo(tagInfo);
        // 대상 상세정보 조회 후 set
        getIotItem({ targetNum });
    };

    // table column data
    const columns = useColumns(
        [
            column.categoryName({
                ...WIDTH,
                Cell: ({ value }) => value || DEFAULT_VALUE,
            }),
            column.assetName({ ...WIDTH, Cell: ({ value }) => value || DEFAULT_VALUE }),
            column.targetId({
                ...WIDTH,
                style: { textTransform: 'uppercase' },
                Cell: ({ value }) => <LinkContainer>{value || DEFAULT_VALUE}</LinkContainer>,
            }),
            column.ownerWard({
                ...WIDTH,
                Cell: ({ value }) => value || DEFAULT_VALUE,
            }),
            column.assetStatus({
                ...WIDTH,
                Cell: ({ value }) => value || DEFAULT_VALUE,
            }),
        ],
        amTs,
        [data],
    );

    const convertRows = data.map(value => {
        return {
            categoryName: value.targetCategory?.categoryName,
            targetName: value.target?.targetName,
            targetId: value.target?.targetId,
            properties: convertProperties(value.target?.properties, categoryProperties),
            ...value,
        };
    });

    const { promise: getIotItem } = useAsync({
        promise: getIotItemApi,
        resolve: res => {
            const currentProperties = res?.properties;
            const properties = convertProperties(currentProperties, categoryProperties);
            setSelectedTargetInfo({ ...res, properties });
        },
    });

    const handleModal = () => {
        setSelectedTargetInfo(null);
        setSelectedTagInfo(null);
        toggleModal();
    };

    useEffect(() => {
        setSelectedTargetInfo(null);
        setSelectedTagInfo(null);
    }, [data]);

    return (
        <Draggable
            key={JSON.stringify(position)}
            bounds="parent"
            defaultPosition={position}
            backgroundMapElement={mapRef.current?.leafletElement}
        >
            <Toast
                isOpen={modal}
                className={'position-absolute floorDetail bg-depth-3 w-50'}
                style={{ zIndex: 9999, maxWidth: 'none' }}
                innerRef={popupRef}
            >
                <ToastHeader className={'bg-depth-4'} toggle={handleModal}>
                    <span>
                        {`${t('Detail info by zone')}: `}
                        <span className={'color-secondary'}>{name}</span>
                    </span>
                </ToastHeader>
                <ToastBody className={'bg-depth-4'}>
                    <BodyContainer>
                        {
                            <Table
                                columns={columns}
                                data={{ rows: convertRows }}
                                paging={false}
                                onTrClick={handlerAssetDetail}
                            />
                        }
                    </BodyContainer>
                    {selectedTargetInfo && (
                        <GeofenceDetailPopupAssetDetail
                            selectedTargetInfo={selectedTargetInfo}
                            selectedTagInfo={selectedTagInfo}
                        />
                    )}
                </ToastBody>
            </Toast>
        </Draggable>
    );
};

const BodyContainer = styled.div`
    height: 11.6rem;
`;

const LinkContainer = styled.span`
    color: #0d6efd !important;

    &:hover {
        text-decoration: #0d6efd underline;
    }
`;

export default GeofenceDetailPopup;
