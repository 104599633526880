import React from 'react';
import InnerItemCard from '@components/Card/InnerItemCard';
import { Button } from '@components';
import cx from 'classnames';
import styles from '@asset/pnt/widgets/RealTimeSensorMonitoring.module.scss';
import { useRealTimeSensorMonitoringDispatch } from '../index';
import SensorStatusImage from './SensorStatusImage';
import { STATUS } from '../constants';
import moment from 'moment';
import { setSelectedSensor } from '../realTimeSensorMonitoringSlice';
import { useTranslation } from '@hooks';

const SensorItemCard = ({ sensorItem }) => {
    const t = useTranslation('RealTimeSensorMonitoring');

    const dispatch = useRealTimeSensorMonitoringDispatch();
    const handleLocationClick = sensorItem => dispatch(setSelectedSensor(sensorItem));

    return sensorItem.sensorNum ? (
        <InnerItemCard
            className={'m-0'}
            header={{
                title: sensorItem.targetName,
                subTitle: sensorItem.sensorName,
                action: (
                    <Button
                        className={`btn-trans btn-icon-only`}
                        iconClassName={'material-icons-round color-gray'}
                        iconName={'location_on'}
                        onClick={() => handleLocationClick(sensorItem)}
                    />
                ),
            }}
        >
            <div className={'flx-row'}>
                {/* Sensor image */}
                <SensorStatusImage sensorItem={sensorItem} />

                {/* Sensor info */}
                <div className="ml-2 flx-1 flex-column flx-sb align-start">
                    {/* Type */}
                    <div className={'pnt-txt s-9'}>
                        {`${t(sensorItem.sensingType)} ${
                            sensorItem.measurementUnit && `(${sensorItem.measurementUnit})`
                        }`}
                    </div>
                    <div className="flx-row flx-sb w-100">
                        {/* Current value */}
                        <span className={cx('pnt-txt txt-bold s-4', !sensorItem.currentValue && 'color-gray')}>
                            {sensorItem.currentValue
                                ? Number.isInteger(sensorItem.currentValue)
                                    ? sensorItem.currentValue
                                    : sensorItem.currentValue.toFixed(2)
                                : 'N/A'}
                        </span>
                        {/* Status */}
                        <span
                            className={cx(
                                'pnt-txt txt-bold s-7',
                                STATUS[sensorItem.status].color && `color-${STATUS[sensorItem.status].color}`,
                            )}
                        >
                            {t(sensorItem.status)}
                        </span>
                    </div>
                    {/* Last received time */}
                    <div className={'pnt-txt s-10 opacity-7'}>
                        {sensorItem.currentValue && sensorItem.modDate
                            ? moment.unix(sensorItem.modDate).format('YYYY-MM-DD ( HH:mm:ss )')
                            : '-'}
                    </div>
                </div>
            </div>
        </InnerItemCard>
    ) : (
        <SkeletonSensorItemCard />
    );
};

const SkeletonSensorItemCard = () => {
    return (
        <InnerItemCard
            className={'m-0'}
            header={{
                title: (
                    <span className={'skeleton-loading d-block'} style={{ width: '60px' }}>
                        &nbsp;
                    </span>
                ),
                subTitle: (
                    <span className={'skeleton-loading d-block'} style={{ width: '50px' }}>
                        &nbsp;
                    </span>
                ),
                action: (
                    <Button
                        className={`btn-trans btn-icon-only`}
                        iconClassName={'material-icons-round color-gray'}
                        iconName={'location_on'}
                        onClick={() => {}}
                    />
                ),
            }}
        >
            <div className={'flx-row'}>
                <div className={cx(styles.sensor_img, 'skeleton-loading')}>
                    {/* Sensor status */}
                    <div className={cx(styles.status, 'bg-depth-10')} />
                </div>
                <div className="ml-2 flx-1 flex-column flx-sb align-start skeleton-loading">
                    {/* Type */}
                    <div className={'pnt-txt s-9'}>&nbsp;</div>
                    <div className="flx-row flx-sb w-100">
                        {/* Current value */}
                        <span className={cx('pnt-txt txt-bold s-4')}>&nbsp;</span>
                        {/* Status */}
                        <span className={cx('pnt-txt txt-bold s-7')}>&nbsp;</span>
                    </div>
                    {/* Last received time */}
                    <div className={'pnt-txt s-10 opacity-7'}>&nbsp;</div>
                </div>
            </div>
        </InnerItemCard>
    );
};

export default SensorItemCard;
