import React from 'react';
import DetailsInfo from './DetailsInfo';
import VitalSignsView from './VitalSignsView';

const PatientDetails = () => {
    return (
        <>
            <DetailsInfo />
            <VitalSignsView />
        </>
    );
};

export default PatientDetails;
