import React, { createContext, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ScreenDetailPopup from './Components/ScreenDetailPopup';
import { ScreenSubMenu } from './Components/ScreenListColumns';
import { useAsync, useTranslation } from '@hooks';
import { Button, Card, LoadingBlock, Page, Table } from '@components';
import { fetchScreenList } from '@api/common/screen';
import reducer, { initialState, selectScreen, setScreenListInfo } from './reducer';
import { setScreenList } from '@reducer/CustomMenu';
import * as commonCol from '../../../util/grid/column';
import useColumns from '@hooks/useColumns';

export const ScreenListContext = createContext();
export const ScreenListDispatchContext = createContext();

const initScreenInfo = { totalPage: 1, page: 1, rows: [] };

const ScreenList = () => {
    const storeDispatch = useDispatch();
    const t = useTranslation('Screen');
    const { originScreenList } = useSelector(state => state.CustomMenu);
    const { userInfo } = useSelector(state => state.UserInfo);
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        screenListInfo: { ...initScreenInfo, rows: originScreenList },
    });
    const { selectedScreen, screenListInfo } = state;

    const [modal, setModal] = useState(false);
    const columns = useColumns(
        [
            {
                Header: 'Menu Name',
                className: 'text-ellipsis',
                accessor: 'menuName',
                width: 150,
            },
            {
                Header: 'Screen Name',
                className: 'text-ellipsis',
                accessor: 'screenName',
                width: 150,
            },
            {
                Header: 'Screen Description',
                className: 'text-ellipsis text-start',
                accessor: 'description',
                width: 300,
            },
            {
                Header: 'Sort Order',
                className: 'd-flex flx-center',
                accessor: 'order',
                width: 80,
            },
            commonCol.modDate({ width: 180 }),
            {
                Header: 'Action',
                width: 70,
                className: 'flex-center overflow-visible',
                Cell: ScreenSubMenu,
            },
        ],
        t,
    );

    const { state: screenListReqState, promise: getScreenList } = useAsync({
        promise: fetchScreenList,
        fixedParam: { isAll: 'Y', groupNum: userInfo.authGroupNum },
        resolve: response => {
            storeDispatch(setScreenList(response.rows ?? []));
        },
    });

    const handleModalOpen = () => {
        setModal(true);
    };

    const handleModalClose = () => {
        setModal(false);
        dispatch(selectScreen(null));
    };

    useEffect(() => {
        getScreenList({ isIndividual: 'Y' });
    }, []);

    useEffect(() => {
        if (selectedScreen) {
            setModal(true);
        } else {
            setModal(false);
        }
        return () => {
            setModal(false);
        };
    }, [selectedScreen]);

    useEffect(() => {
        dispatch(setScreenListInfo({ ...initScreenInfo, rows: originScreenList }));
    }, [originScreenList]);

    return (
        <ScreenListDispatchContext.Provider value={dispatch}>
            <ScreenListContext.Provider value={state}>
                <Page className="h-100" menuPath={[t('Screen Management', 'Menu'), t('Screen List', 'Menu')]}>
                    <Card
                        className="h-100 mb-0"
                        header={{
                            title: t('Screen List'),
                            action: (
                                <Button className={'btn-icon btn-secondary'} iconName="add" onClick={handleModalOpen}>
                                    {t('Create a Screen')}
                                </Button>
                            ),
                        }}
                    >
                        <LoadingBlock blocking={screenListReqState.isLoading}>
                            <Table columns={columns} data={screenListInfo} paging={false} />
                        </LoadingBlock>
                    </Card>
                </Page>
                <ScreenDetailPopup initOpen={modal} toggleModal={handleModalClose} screenInfo={selectedScreen} />
            </ScreenListContext.Provider>
        </ScreenListDispatchContext.Provider>
    );
};

export default ScreenList;
