import React from 'react';
import { Modal } from '@components';
import { useTranslation } from '@util/hooks';
import imgSrc from '@asset/images/notification_image.png';

interface Props {
    isOpen: boolean;
    toggleModal: () => void;
}

const ExampleModal = ({ isOpen, toggleModal }: Props) => {
    const t = useTranslation('OutpatientStorage');

    return (
        <Modal
            initModal={isOpen}
            toggleModal={toggleModal}
            headerTitle={t('Outstanding Amount Notification Example')}
            bodyText={<img alt="" src={imgSrc} width="100%" />}
            removeCancel={true}
        />
    );
};

export default ExampleModal;
