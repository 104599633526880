export const VISIT_COUNT = 'visitCount';
export const UNIQUE_COUNT = 'uniqueCount';
export const TOTAL_ENTRY = 'totalEntry';
export const UNIQUE_ENTRY = 'uniqueEntry';

export const entryCountFakeDataByDate = [
    {
        date: '31-16',
        visitCount: 8,
        uniqueCount: 5,
    },
    {
        date: '31-17',
        visitCount: 4,
        uniqueCount: 2,
    },
    {
        date: '09-08',
        visitCount: 3,
        uniqueCount: 1,
    },
];

export const entryCountFakeDataByHour = [
    {
        time: '0',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '1',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '2',
        totalEntry: 10,
        uniqueEntry: 5,
    },
    {
        time: '3',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '4',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '5',
        totalEntry: 6,
        uniqueEntry: 3,
    },
    {
        time: '6',
        totalEntry: 11,
        uniqueEntry: 3,
    },
    {
        time: '7',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '8',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '9',
        totalEntry: 17,
        uniqueEntry: 9,
    },
    {
        time: '10',
        totalEntry: 9,
        uniqueEntry: 4,
    },
    {
        time: '11',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '12',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '13',
        totalEntry: 8,
        uniqueEntry: 2,
    },
    {
        time: '14',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '15',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '16',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '17',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '18',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '19',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '20',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '21',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '22',
        totalEntry: 0,
        uniqueEntry: 0,
    },
    {
        time: '23',
        totalEntry: 0,
        uniqueEntry: 0,
    },
];
