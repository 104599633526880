import React from 'react';
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import Label from '@components/Label';
import { useAppSelector } from '@hooks';
import { getBatteryDisplayValue } from '../../util';

const DEFAULT_VALUE = '-';
const SideInfo = ({ data }) => {
    const t = useTranslation('VitalSignsRealTimePartron');
    const wardMapInfo = useAppSelector(state => state.DepartmentsInfo.wardMapInfo);
    const { targetName, targetId, deviceName, properties, sensingValue } = data;

    const { ward, room, bed } = properties;

    const targetInfo = [
        { key: 'Patient Name', value: targetName },
        { key: 'Patient ID', value: targetId },
        { key: 'Ward', value: wardMapInfo[ward] || null },
        { key: 'Room', value: room || null },
        { key: 'Bed', value: bed || null },
    ];

    const sensorInfo = [
        { key: 'Device Name', value: 'Partron' },
        { key: 'Sensor Name', value: deviceName || null },
        { key: 'Battery', value: getBatteryDisplayValue(Math.round(sensingValue?.BATTERY)) },
    ];

    return (
        <Container>
            <DetailInfo>
                {targetInfo.map(({ key, value }) => (
                    <Label
                        key={`${key}_${value}`}
                        name={t(key)}
                        labelGroupClassName="mb-2"
                        labelValueClassName="label-dot"
                        value={value || DEFAULT_VALUE}
                    />
                ))}
            </DetailInfo>

            <div className="pnt-divider horizon-line" />

            <DetailInfo>
                {sensorInfo.map(({ key, value }) => (
                    <Label
                        key={`${key}_${value}`}
                        name={t(key)}
                        labelGroupClassName="mb-2"
                        labelValueClassName="label-dot"
                        value={value || DEFAULT_VALUE}
                    />
                ))}
            </DetailInfo>
        </Container>
    );
};

const Container = styled.div`
    width: 16rem;
`;

const DetailInfo = styled.div`
    margin-bottom: 1.5rem;
`;

export default SideInfo;
