import React, { useEffect, useState } from 'react';

import { BarChart } from '@components';

import useTranslation from '@hooks/useTranslation';
import NoDataBlock from '../../../Components/NoDataBlock';

const PermissionBarChart = ({ congestedGeofenceInfo }) => {
    const t = useTranslation('TopFiveCongestedGeofence');
    const [chartData, setChartData] = useState({ datasets: [] });

    useEffect(() => {
        setChartData({
            labels: congestedGeofenceInfo.map(({ fcName }) => fcName),
            datasets: congestedGeofenceInfo.reduce((acc, info) => {
                ['permitted', 'restricted'].forEach(label => {
                    const existData = acc.find(data => data.label === t(label));
                    if (existData) {
                        existData.data.push(info[label]);
                    } else {
                        acc.push({
                            label: t(label),
                            data: [info[label]],
                        });
                    }
                });
                return acc;
            }, []),
        });
    }, [congestedGeofenceInfo, t]);

    return congestedGeofenceInfo.length ? <BarChart data={chartData} type={'stackedBar'} /> : <NoDataBlock />;
};

export default PermissionBarChart;
