import { useTranslation } from '@hooks';
import { useState } from 'react';
import { ASSET_MANAGEMENT, DUTY_LIST } from '../../constants';

// 위험도 Select
const useDutyOptions = currentDuty => {
    const t = useTranslation(ASSET_MANAGEMENT);
    const options = [
        { value: DUTY_LIST.DAY, label: t('Day') },
        { value: DUTY_LIST.EVENING, label: t('Evening') },
        { value: DUTY_LIST.NIGHT, label: t('Night') },
    ];

    const [duty, setDuty] = useState({ value: null, label: '' });
    if (!duty.value && currentDuty) {
        setDuty(options.find(ele => ele.value === currentDuty));
    }

    const handleDutyChange = selected => {
        setDuty(selected);
    };

    return {
        dutyOptions: options,
        duty,
        handleDutyChange,
    };
};
export default useDutyOptions;
