import { useAppSelector } from '@util/hooks';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import WidgetCard from '../../Components/WidgetCard';
import { WidgetProps } from '../../staticInfo';
import RentalRequestComponent from '../RentalManagement/Borrow/RentalRequest';

const RentalRequest = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    const widgetRef = useRef(null);
    const { originScreenList, screenList } = useAppSelector(state => state.CustomMenu);
    const history = useHistory();

    const targetWidget = originScreenList.find(screen => {
        const widgetInfo = JSON.parse(screen.widgetInfo ?? '[]')[0];
        if (widgetInfo && widgetInfo.config.settings?.categoryCode === 'borrow') {
            return true;
        }
        return false;
    });
    const { screenName } = targetWidget || {};
    const { url } = screenList.find(screen => screen.label === screenName) || {};

    const handleSuccess = () => {
        history.push(url ?? '/');
    };

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <RentalRequestComponent onSuccess={handleSuccess} />
            {children}
        </WidgetCard>
    );
};

export default RentalRequest;
