import { useColumns, useTranslation } from '@hooks';
import { UnixTimestamp } from '@util/type/util';
import moment from 'moment';
import {
    BatteryCell,
    ElapsedTimeCell,
    HeartbeatValueCell,
    ProgressTypeCell,
    SensorNameCell,
    SpO2ValueCell,
    TemperatureValueCell,
} from './Components/PatientsTable/Cells';

// import * as globalCols from '@util/grid/column';
import * as mainCols from '../../../MainPages/util/grid/column';

export const subjectNum = ({ ...restProps } = {}) => {
    return {
        Header: 'Study Number',
        accessor: 'subjectNum',
        width: 90,
        ...restProps,
    };
};

export const elapsedTime = ({ ...restProps } = {}) => {
    return {
        Header: 'Progress Time',
        accessor: 'elapsedTime',
        ...restProps,
    };
};

export const measures = ({ ...restProps } = {}) => {
    return {
        Header: 'Device measurements',
        accessor: 'measures',
        ...restProps,
    };
};

export const progressType = ({ ...restProps } = {}) => {
    return {
        Header: 'Measurement',
        accessor: 'progressType',
        ...restProps,
    };
};

export const spo2 = ({ ...restProps } = {}) => {
    return {
        Header: 'SpO2',
        accessor: 'spo2',
        ...restProps,
    };
};

export const range = ({ ...restProps } = {}) => {
    return {
        Header: 'Range',
        accessor: 'range',
        ...restProps,
    };
};

export const mapping = ({ ...restProps } = {}) => {
    return {
        Header: 'Mapping',
        accessor: 'mapping',
        ...restProps,
    };
};

export const usePatientsTableColumns = () => {
    const t = useTranslation('ExamPatientsMonitoring');

    return useColumns(
        [
            subjectNum({ headerClassName: 'justify-content-center', width: 60 }),
            mainCols.geofenceName({
                Header: t('Stabilization Room'),
                headerClassName: 'justify-content-center',
                width: 60,
            }),
            measures({
                Header: t('Device measurements'),
                headerClassName: 'bg-depth-9',
                columns: [
                    {
                        Header: 'HR',
                        headerClassName: 'bg-depth-9',
                        className: 'p-0',
                        accessor: 'recentSensorValues.HEARTBEAT',
                        width: 60,
                        Cell: HeartbeatValueCell,
                    },
                    {
                        Header: 'SpO2',
                        headerClassName: 'bg-depth-9',
                        className: 'p-0',
                        accessor: 'recentSensorValues.SPO2',
                        width: 60,
                        Cell: SpO2ValueCell,
                    },
                    {
                        Header: 'BT',
                        headerClassName: 'bg-depth-9',
                        className: 'p-0',
                        accessor: 'recentSensorValues.TEMPERATURE',
                        width: 60,
                        Cell: TemperatureValueCell,
                    },
                ],
            }),
            mainCols.registeredDate({
                Header: 'Recognition Time',
                headerClassName: 'justify-content-center',
                width: 120,
                formatTitle: ({ value }: { value: UnixTimestamp }) => {
                    return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '';
                },
                Cell: ({ value }: { value: UnixTimestamp }) => {
                    return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '';
                },
            }),
            mainCols.sensorName({
                Header: 'Device Name',
                headerClassName: 'justify-content-center',
                className: 'flex-center justify-content-center disable-hover',
                width: 90,
                Cell: SensorNameCell,
            }),
            mainCols.battery({
                headerClassName: 'justify-content-center',
                className: 'justify-content-center',
                width: 60,
                accessor: 'battery',
                Cell: BatteryCell,
            }),
            elapsedTime({ Cell: ElapsedTimeCell, headerClassName: 'justify-content-center', width: 70 }),
            progressType({
                headerClassName: 'justify-content-center',
                className: 'flex-center justify-content-center disable-hover',
                width: 80,
                Cell: ProgressTypeCell,
            }),
        ],
        t,
    );
};
