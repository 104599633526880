import React, { Context, Dispatch, ReactNode, useReducer } from 'react';

export type ActionType = {
    type: string;
    payload: any;
};

interface Slice<S> {
    reducer: (state: S, action: ActionType) => S;
    getInitialState: () => S;
}

interface ContextProviderProps<S> {
    StateContext: Context<S>;
    DispatchContext: Context<Dispatch<ActionType> | null>;
    slice: Slice<S>;
    initializer?: (state: S) => S;
    children: ReactNode;
}

function createReducerArgs<S>(
    slice: Slice<S>,
    initializer?: (state: S) => S,
): [(state: S, action: ActionType) => S, S, any?] {
    if (initializer) {
        return [slice.reducer, slice.getInitialState(), initializer];
    } else {
        return [slice.reducer, slice.getInitialState()];
    }
}

const ContextProvider = <S,>({
    StateContext,
    DispatchContext,
    slice,
    children,
    initializer,
}: ContextProviderProps<S>) => {
    const reducerArgs = createReducerArgs(slice, initializer);
    const [state, dispatch] = useReducer(...reducerArgs);

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
        </StateContext.Provider>
    );
};

export default ContextProvider;
