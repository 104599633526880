import React, { useState } from 'react';
import styled from 'styled-components';
import ContentView from './ContentView';
import useAsync from '@hooks/useAsync';
import { getVitalSignsDetailOfPatronApi } from '@api/monitoring';
import SideInfo from './SideInfo';
import { LoadingBlock } from '@components/LoadingBlock';

const Index = ({ targetId }) => {
    const [info, setInfo] = useState(null);

    const { state: vitalSignsDetail } = useAsync({
        promise: getVitalSignsDetailOfPatronApi,
        param: { targetId },
        immediate: true,
        resolve: res => {
            setInfo(res);
        },
        reject: err => {
            console.error(err);
        },
    });

    return (
        <LoadingBlock blocking={vitalSignsDetail.isLoading}>
            <Container>
                <ContentsContainer $width={20}>{!!info && <SideInfo data={info} />}</ContentsContainer>
                <ContentsContainer $width={80}>{!!info && <ContentView data={info} />}</ContentsContainer>
            </Container>
        </LoadingBlock>
    );
};

const Container = styled.div`
    display: flex;
    height: 100%;
`;

const ContentsContainer = styled.div`
    flex: ${props => (props.$width ? (1 * props.$width) / 100 : 1)};
    :not(&:first-child) {
        margin-left: 1rem;
    }
`;

export default Index;
