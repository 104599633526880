import { createSlice } from '@reduxjs/toolkit';
import { ALL_TYPE, PARAMETER_DEFAULT_PAGE } from './constants';

export const initialState = {
    sensorFilterInfo: {},
    pageInfo: {
        ...PARAMETER_DEFAULT_PAGE,
        totalCount: 0,
        totalPage: 0,
    },
    sensorType: ALL_TYPE.value,
    floorInfo: {},
    selectedFloor: [],
    selectedSensor: null,
    sensorList: [],
    sensorDataList: [],
    monitoringState: {},
    selectedSensorType: [],
    selectedSensingTypes: {
        Environment: [],
        Health: [],
        Movement: [],
        Chemistry: [],
        Other: [],
    },
};

const realTimeSensorMonitoringSlice = createSlice({
    name: 'realTimeSensorMonitoring',
    initialState,
    reducers: {
        setSensorType: (state, action) => {
            state.sensorType = action.payload;
        },
        setFloorInfo: (state, action) => {
            state.floorInfo = { ...action.payload };
        },
        setSelectedFloor: (state, action) => {
            state.selectedFloor = action.payload;
        },
        setSensorList: (state, action) => {
            const response = action.payload;
            const { pageSize } = state.pageInfo;
            const { rows: updatedSensorList, page, totalPage, totalCount } = response;

            state.pageInfo.page = page;

            const nextCount =
                totalPage === page
                    ? 0
                    : totalCount - page * pageSize > pageSize
                    ? pageSize
                    : totalCount - page * pageSize;
            const nextItems = new Array(nextCount).fill({ targetNum: null, sensorNum: null, page: page + 1 });

            const newSensorList = updatedSensorList.reduce((sensorItemAcc, sensor) => {
                if (sensor.targetId && sensor.sensorItems) {
                    sensor.sensorItems.forEach(sensorItem => {
                        sensorItemAcc.push({ ...sensor, ...sensorItem });
                    });
                }
                return sensorItemAcc;
            }, []);

            const pagableSensorList = newSensorList.concat(nextItems);

            let beforeSensorList = [];
            if (page !== 1) {
                beforeSensorList = state.sensorList.filter(sensor => !!sensor.sensorNum);
            }
            const totalList = beforeSensorList.concat(pagableSensorList);

            state.sensorList = totalList;
            state.sensorDataList = totalList;
            state.monitoringState = {
                ...state.monitoringState,
                ...newSensorList.reduce((acc, { sensorNum, sensingType }) => {
                    if (sensorNum) {
                        acc[`${sensorNum}_${sensingType}`] = { sensorNum, monitoring: false };
                    }
                    return acc;
                }, {}),
            };
        },
        updateSensingData: (state, action) => {
            const updatedSensorList = action.payload || [];
            updatedSensorList.forEach(sensorData => {
                if (sensorData.sensingValues) {
                    const { sensor, sensingValues, sensingState, unixTime } = sensorData;
                    const { sensorNum } = sensor;
                    state.sensorDataList = state.sensorDataList.map(sensorItem => {
                        if (sensorItem.sensorNum === sensorNum) {
                            const key = Object.keys(sensingValues).find(type => sensorItem.sensingType === type);
                            if (sensingValues[key] !== null && sensingValues[key] !== undefined) {
                                return {
                                    ...sensorItem,
                                    currentValue: sensingValues[key],
                                    status: sensingState[key],
                                    modDate: unixTime,
                                };
                            }
                        }
                        return sensorItem;
                    });
                }
            });
        },
        setSelectedSensor: (state, action) => {
            state.selectedSensor = action.payload;
        },
        setSelectedSensorType: (state, action) => {
            state.selectedSensorType = action.payload;
        },
        setSelectedSensingTypes: (state, action) => {
            const { name, value } = action.payload;
            state.selectedSensingTypes = { ...state.selectedSensingTypes, [name]: [...value] };
        },
        clearSensingTypes: (state, action) => {
            state.selectedSensingTypes = {
                Environment: [],
                Health: [],
                Movement: [],
                Chemistry: [],
                Other: [],
            };
        },
        updateMonitoringState: (state, action) => {
            const updateState = action.payload;

            updateState.forEach(({ itemKey, page, isIntersecting }) => {
                const { sensorNum } = state.monitoringState[itemKey] ?? {};
                if (sensorNum) {
                    state.monitoringState[itemKey] = {
                        sensorNum,
                        monitoring: isIntersecting,
                    };
                }
                const pageNum = Number(page);
                if (isIntersecting && pageNum && state.pageInfo.page !== pageNum) {
                    state.pageInfo.page = pageNum;
                }
            });
        },
        setSensorFilter: (state, action) => {
            state.sensorFilterInfo = action.payload;
            state.pageInfo.page = 1;
        },
    },
});

export const {
    setSensorType,
    setFloorInfo,
    setSelectedFloor,
    setSensorList,
    updateSensingData,
    setSelectedSensor,
    setSelectedSensorType,
    setSelectedSensingTypes,
    clearSensingTypes,
    updateMonitoringState,
    setSensorFilter,
} = realTimeSensorMonitoringSlice.actions;

export default realTimeSensorMonitoringSlice;
