import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { useAsync, useTranslation } from '@hooks';
import { fetchMostCongestedGeofenceApi } from '@api/statistics';
import Button from '@components/Button';
import { SCREEN_MODE_EDIT } from '@reducer/ScreenInfo';

import { setEndDate, setSelectedCategory, setSelectedFloor, setStartDate } from './congestedGeofenceSlice';
import WidgetCard from '../../Components/WidgetCard';
import CongestedGeofenceFilterGroup from './Component/TopFiveCongestedGeofenceFilterGroup';
import CategoryBarChart from './Component/CategoryBarChart';
import PermissionBarChart from './Component/PermissionBarChart';
import { useTopFiveCongestedGeofenceContext, useTopFiveCongestedGeofenceDispatch } from './index';
import { geofenceFakeDataByCategory, geofenceFakeDataByPermission } from './constants';

const TopFiveCongestedGeofenceContainer = ({ widgetInfo, children, ...restProps }) => {
    const t = useTranslation('TopFiveCongestedGeofence');
    const { config } = widgetInfo;
    const { defaultSettings, settings } = config;
    const [showSearchArea, setShowSearchArea] = useState(false);
    const [congestedGeofenceInfo, setCongestedGeofenceInfo] = useState([]);
    const [option, setOption] = useState('category');
    const [filterParam, setFilterParam] = useState({});
    const { floorList } = useSelector(state => state.FloorInfo);
    const { mode } = useSelector(state => state.ScreenInfo);
    const { selectedCategory, selectedFloor, startDate, endDate } = useTopFiveCongestedGeofenceContext();
    const dispatch = useTopFiveCongestedGeofenceDispatch();

    const handleRefreshClick = e => {
        dispatch(setSelectedCategory(''));
        dispatch(setSelectedFloor(floorList[0] ? floorList[0].floorId : ''));
        dispatch(setStartDate(moment().startOf('day').valueOf()));
        dispatch(setEndDate(moment().endOf('day').valueOf()));
    };

    const handleSearchAreaClick = e => {
        setShowSearchArea(!showSearchArea);
    };

    const handleSearchClick = e => {
        if (selectedFloor) {
            getCongestedGeofence({
                ...filterParam,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
        }
        setShowSearchArea(false);
    };

    const { promise: getCongestedGeofence } = useAsync({
        promise: fetchMostCongestedGeofenceApi,
        fixedParam: { opt: settings && settings.type ? settings.type : defaultSettings.type },
        resolve: res => {
            setCongestedGeofenceInfo(res.rows);
        },
    });

    useEffect(() => {
        if (settings && settings.type) {
            setOption(settings.type);
        } else {
            setOption(defaultSettings.type);
        }
    }, [widgetInfo]);

    useEffect(() => {
        if (option && floorList.length > 0) {
            getCongestedGeofence({
                floorId: floorList[0].floorId,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
        }
    }, [option]);

    useEffect(() => {
        if (selectedFloor) {
            getCongestedGeofence({
                ...filterParam,
                startDate: startDate ? moment(startDate).unix() : null,
                endDate: endDate ? moment(endDate).unix() : null,
            });
        }
    }, [filterParam]);

    useEffect(() => {
        const paramInfo = {};
        if (selectedCategory) {
            paramInfo.categoryCode = selectedCategory;
        }
        if (selectedFloor) {
            paramInfo.floorId = selectedFloor;
        }
        setFilterParam(paramInfo);
    }, [selectedCategory, selectedFloor]);

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            {...restProps}
            headerAction={
                <>
                    <Button className={'btn-darkgray'} onClick={handleSearchAreaClick}>
                        {t('Search', 'Button')}
                    </Button>
                    <div className="pnt-border border-h" />
                    <Button className="btn-lightgray btn-icon-only" iconName="refresh" onClick={handleRefreshClick} />
                </>
            }
            subTitle={settings && settings.type ? t(settings.type) : t(defaultSettings.type)}
            searchFilter={showSearchArea && <CongestedGeofenceFilterGroup handleSearchClick={handleSearchClick} />}
        >
            {option === 'category' ? (
                <CategoryBarChart
                    congestedGeofenceInfo={
                        mode === SCREEN_MODE_EDIT ? geofenceFakeDataByCategory : congestedGeofenceInfo
                    }
                />
            ) : (
                <PermissionBarChart
                    congestedGeofenceInfo={
                        mode === SCREEN_MODE_EDIT ? geofenceFakeDataByPermission : congestedGeofenceInfo
                    }
                />
            )}
            {children}
        </WidgetCard>
    );
};

export default TopFiveCongestedGeofenceContainer;
