import React, { useEffect, useState } from 'react';
import { SearchableSelect, Button, TreeSelect } from '@components';
import { useAppSelector, useAsync, useTranslation } from '@hooks';
import { useRealTimeSensorMonitoringContext, useRealTimeSensorMonitoringDispatch } from '../index';
import { fetchSensingTypes } from '@api/monitoring';
import {
    setSensorType,
    setSelectedFloor,
    setSelectedSensorType,
    setSelectedSensingTypes,
    setSensorFilter,
} from '../realTimeSensorMonitoringSlice';
import { ALL_TYPE, FIXED_TYPE, MOBILE_TYPE } from '../constants';
import { FilterList } from '../../../../MainPages/Components/FilterSearchGroup/Components/Part';

const SensingFilter = () => {
    const t = useTranslation('RealTimeSensorMonitoring');

    const { floorList } = useAppSelector(state => state.FloorInfo);
    const {
        sensorType,
        selectedFloor,
        selectedSensingTypes,
        selectedSensorType,
    } = useRealTimeSensorMonitoringContext();
    const dispatch = useRealTimeSensorMonitoringDispatch();

    const [filterData, setFilterData] = useState({});

    useAsync({
        promise: fetchSensingTypes,
        immediate: true,
        resolve: res => {
            const { rows } = res;
            let types = {
                Environment: [],
                Health: [],
                Movement: [],
                Chemistry: [],
                Other: [],
            };

            for (let i = 0; i < rows.length; i++) {
                switch (rows[i].cCD) {
                    case 'A':
                        types['Environment'] = [
                            ...types['Environment'],
                            {
                                value: rows[i].sCD,
                                label: rows[i].sCD,
                                type: 'Environment',
                            },
                        ];
                        break;
                    case 'B':
                        types['Health'] = [
                            ...types['Health'],
                            {
                                value: rows[i].sCD,
                                label: rows[i].sCD,
                                type: 'Health',
                            },
                        ];
                        break;
                    case 'C':
                        types['Movement'] = [
                            ...types['Movement'],
                            {
                                value: rows[i].sCD,
                                label: rows[i].sCD,
                                type: 'Movement',
                            },
                        ];
                        break;
                    case 'D':
                        types['Chemistry'] = [
                            ...types['Chemistry'],
                            {
                                value: rows[i].sCD,
                                label: rows[i].sCD,
                                type: 'Chemistry',
                            },
                        ];
                        break;
                    case 'Z':
                        types['Other'] = [
                            ...types['Other'],
                            {
                                value: rows[i].sCD,
                                label: rows[i].sCD,
                                type: 'Other',
                            },
                        ];
                        break;
                    default:
                        break;
                }
            }
            setFilterData(types);
        },
    });

    const sensorTypeData = [
        { value: FIXED_TYPE.value, label: t(FIXED_TYPE.label) },
        { value: MOBILE_TYPE.value, label: t(MOBILE_TYPE.label) },
    ];
    useEffect(() => {
        let type = ALL_TYPE.value;

        if (selectedSensorType.length > 0) {
            if (selectedSensorType.length === 2) {
                type = ALL_TYPE.value;
            } else if (selectedSensorType[0] === FIXED_TYPE.value) {
                type = FIXED_TYPE.value;
            } else if (selectedSensorType[0] === MOBILE_TYPE.value) {
                type = MOBILE_TYPE.value;
            }
        }
        dispatch(setSensorType(type));
    }, [selectedSensorType]);

    const handleFloorChange = floorList => {
        dispatch(setSelectedFloor(floorList));
    };

    const handleSensorTypeChange = typeList => {
        dispatch(setSelectedSensorType(typeList.map(v => v.value)));
    };

    const handleSelectChange = (value, e) => {
        dispatch(
            setSelectedSensingTypes({
                name: e.name,
                value: value.map(v => v.value),
            }),
        );
    };

    const handleSearchClick = () => {
        let posMode = '';
        let sensingTypes = '';
        let floorIds = '';

        if (selectedSensorType.length === 1) {
            posMode = selectedSensorType[0];
        }

        sensingTypes = [
            ...selectedSensingTypes['Environment'],
            ...selectedSensingTypes['Health'],
            ...selectedSensingTypes['Movement'],
            ...selectedSensingTypes['Chemistry'],
            ...selectedSensingTypes['Other'],
        ].join(',');

        floorIds = selectedFloor.join(',');

        if (floorIds === '') {
            dispatch(setSensorFilter({ posMode, sensingTypes }));
        } else {
            dispatch(setSensorFilter({ posMode, sensingTypes, floorIds }));
        }
    };

    return (
        <FilterList>
            {(sensorType === ALL_TYPE.value || sensorType === FIXED_TYPE.value) && (
                <TreeSelect
                    data={floorList}
                    title={t('Floor')}
                    labelKey={'floorName'}
                    valueKey={'floorId'}
                    parentKey={'upperFloorId'}
                    selected={selectedFloor}
                    onChange={handleFloorChange}
                />
            )}
            <SearchableSelect
                title={t('Sensor Type')}
                selected={selectedSensorType}
                data={sensorTypeData}
                onChange={handleSensorTypeChange}
                translator={t}
            />
            <SearchableSelect
                title={t('Environment')}
                selected={selectedSensingTypes['Environment']}
                data={filterData.Environment}
                name={'Environment'}
                onChange={handleSelectChange}
                translator={t}
            />
            <SearchableSelect
                title={t('Health')}
                selected={selectedSensingTypes['Health']}
                data={filterData.Health}
                name={'Health'}
                onChange={handleSelectChange}
                translator={t}
            />
            <SearchableSelect
                title={t('Movement')}
                selected={selectedSensingTypes['Movement']}
                data={filterData.Movement}
                name={'Movement'}
                onChange={handleSelectChange}
                translator={t}
            />
            <SearchableSelect
                title={t('Chemistry')}
                selected={selectedSensingTypes['Chemistry']}
                data={filterData.Chemistry}
                name={'Chemistry'}
                onChange={handleSelectChange}
                translator={t}
            />
            <SearchableSelect
                title={t('Other')}
                selected={selectedSensingTypes['Other']}
                data={filterData.Other}
                name={'Other'}
                onChange={handleSelectChange}
                translator={t}
            />

            <Button className="btn-icon btn-secondary" onClick={handleSearchClick}>
                {t('Search', 'AssetCountStatus')}
            </Button>
        </FilterList>
    );
};

export default SensingFilter;
