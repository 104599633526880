import React, { useReducer, useRef, useEffect } from 'react';
import reducer, { initialState, setIsDetail, setWardMapInfo } from './vitalSignsMonitoringForHiCardiPlusReducer';
import VitalSignsCardList from './Components/VitalSignsList';
import ViewDetail from './Components/ViewDetail';
import Button from '@components/Button';
import useTranslation from '@hooks/useTranslation';
import WidgetCard from '../../Components/WidgetCard';
import { useAppSelector } from '@hooks';
import { createSelector } from '@reduxjs/toolkit';
import styled from 'styled-components';

/**
 * 활력징후 모니터링 위젯
 *
 * @since 2022-12-16
 * @author kunwoong-kim
 * */
export const VitalSignsMonitoringForHiCardiPlusStateContext = React.createContext();
export const VitalSignsMonitoringDispatchContext = React.createContext();
const VitalSignsMonitoringForHiCardiPlus = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation();
    const { config } = widgetInfo;
    const widgetRef = useRef();
    const wardMapInfo = useAppSelector(state => state.DepartmentsInfo.wardMapInfo);
    const [state, dispatch] = useReducer(reducer, { ...initialState, wardMapInfo });

    useEffect(() => {
        dispatch(setWardMapInfo(wardMapInfo));
    }, [wardMapInfo]);

    return (
        <VitalSignsMonitoringDispatchContext.Provider value={dispatch}>
            <VitalSignsMonitoringForHiCardiPlusStateContext.Provider value={state}>
                <WidgetCard
                    ref={widgetRef}
                    widgetInfo={widgetInfo}
                    headerAction={
                        state.isDetail && (
                            <Button className="btn-lightgray" onClick={() => dispatch(setIsDetail(false))}>
                                {t('Back', 'Button')}
                            </Button>
                        )
                    }
                    className={'p-0'}
                    bodyClassName={'pr-0'}
                    {...restProps}
                >
                    {state.isDetail ? (
                        <ViewDetail targetNum={state.targetNum} />
                    ) : (
                        <ListWrapper>
                            <VitalSignsCardList config={config} />
                        </ListWrapper>
                    )}
                    {children}
                </WidgetCard>
            </VitalSignsMonitoringForHiCardiPlusStateContext.Provider>
        </VitalSignsMonitoringDispatchContext.Provider>
    );
};

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: hidden auto;
    height: 100%;
    padding-right: 1rem;
    margin-top: -0.5rem;
    //border-top: 1px solid #dcd9e1;
`;

export default VitalSignsMonitoringForHiCardiPlus;
