import { useState } from 'react';
import { useAsync } from '@hooks';
import { getVitalSignsWorkTimeApi } from '@api/monitoring';
import moment from 'moment';
import { DUTY_LIST } from '../../constants';

const useWorkTime = () => {
    const [workTime, setWorkTime] = useState({
        currentWorkTimeType: null,
        workTimeSet: {
            [DUTY_LIST.DAY]: { startDate: null, endDate: null },
            [DUTY_LIST.EVENING]: { startDate: null, endDate: null },
            [DUTY_LIST.NIGHT]: { startDate: null, endDate: null },
        },
    });
    // 담당필터 초기 호출
    useAsync({
        promise: getVitalSignsWorkTimeApi,
        immediate: true,
        resolve: res => {
            const { rows } = res;

            // 서버에서 받은 최초 담당시간 현재시간 기준으로 생성
            const initWorkTimeList = rows.map(item => {
                const { workTimeType, startDate, endDate } = item;
                // 서버로부터 받은 시작시간
                const responseStartDate = moment.unix(startDate);
                // 서버로부터 받은 종료시간
                const responseEndDate = moment.unix(endDate);
                // 현재 시작 시간
                const currentStartDate = moment()
                    .hours(responseStartDate.hours())
                    .minutes(responseStartDate.minutes())
                    .startOf('minutes');
                // 현재 종료 시간
                const currentEndDate = moment()
                    .hours(responseEndDate.hours())
                    .minutes(responseEndDate.minutes())
                    .endOf('minutes')
                    .add(workTimeType === DUTY_LIST.NIGHT ? 1 : 0, 'day');

                return [
                    workTimeType,
                    {
                        startDate: currentStartDate.startOf('minutes').unix(),
                        endDate: currentEndDate.endOf('minutes').unix(),
                    },
                ];
            });

            // 담당별 시간 범위값
            const workTimeSet = Object.fromEntries(initWorkTimeList);

            // 현재 접속 시간이 오늘 자정 ~ 오늘 Day 근무 시작 시간 이후 인지 확인
            // 서버에서 받은 담당 시간이 접속시간 기준으로 생성되므로 현재가 Night 근무의 익일 시간인지 확인해야 함
            const now = moment();
            const startOfDay = moment().add(-1, 'day').endOf('day');
            const startOfWorkTime = moment.unix(workTimeSet[DUTY_LIST.DAY].startDate);

            // isNight: 접속 시간이 Night 근무시간일 경우 -1day
            // 전일 23:59:59:999 < now < 오늘 day start work time
            if (now.isBetween(startOfDay, startOfWorkTime)) {
                Object.keys(workTimeSet).forEach(key => {
                    workTimeSet[key].startDate = moment.unix(workTimeSet[key].startDate).add(-1, 'day').unix();
                    workTimeSet[key].endDate = moment.unix(workTimeSet[key].endDate).add(-1, 'day').unix();
                });
            }

            // 접속 시간이 담당 범위에 있는 경우의 담당 코드(기본값: DAY)
            let currentWorkTimeType = DUTY_LIST.DAY;
            Object.keys(workTimeSet).forEach(key => {
                const { startDate, endDate } = workTimeSet[key];
                const start = moment.unix(startDate);
                const end = moment.unix(endDate);
                const today = moment();

                // 현재 시간이 담당 범위에 있을 경우
                if (today.isBetween(start, end)) {
                    currentWorkTimeType = key;
                }
            });

            setWorkTime({ currentWorkTimeType, workTimeSet });
        },
        reject: () => {},
    });

    return workTime;
};

export default useWorkTime;
