import React, { useContext } from 'react';
import { components } from 'react-select';
import { SearchableSelectContext } from '@components/Select/SearchableSelect';
import useTranslation from '@util/hooks/useTranslation';
import { CustomSearchableSelectDispatchContext, CustomSearchableSelectContext } from './index';
import cx from 'classnames';
import { setEditable } from '../reducer';
import { FilterGeneratorContext } from '../../../FilterGenerator';
import { MenuListInput } from '@components/Select/SearchableSelect/Parts';

export const MenuList = ({ children, getValue, setValue, options, ...restProps }) => {
    const selected = getValue();
    const t = useTranslation('TreeSelect');
    const filterT = useTranslation('Filter');
    const { valueKey, labelKey } = useContext(SearchableSelectContext);
    const dispatch = useContext(CustomSearchableSelectDispatchContext);
    const { filterEditMode } = useContext(FilterGeneratorContext);
    const { isEditable } = useContext(CustomSearchableSelectContext);
    const handleChange = e => {
        if (isEditable !== 'Y') {
            return;
        }
        const { checked } = e.currentTarget;
        setValue(checked ? options : []);
    };

    const {
        selectProps: { showSearchInput, onMenuInputChange, onMenuInputFocusToggle },
    } = restProps;
    return (
        <div>
            {showSearchInput && !!onMenuInputChange && !!onMenuInputFocusToggle && (
                <MenuListInput onMenuInputChange={onMenuInputChange} onMenuInputFocusToggle={onMenuInputFocusToggle} />
            )}
            <components.MenuList className="select__options" {...restProps} setValue={setValue}>
                <div
                    className={cx(
                        'flx-row tl p-1 border-bottom bg-depth-6',
                        isEditable !== 'Y' && !filterEditMode && 'disabled-select-option',
                    )}
                >
                    <label className={cx('pnt-checkbox form-h-small')} htmlFor={'Selected'}>
                        <input
                            id={'Selected'}
                            type="checkbox"
                            onChange={() => setValue([], 'deselect-option')}
                            checked={options.length === selected.length}
                        />
                        <span className="checkbox-effect" />
                    </label>
                    <p className="pnt-txt txt-bold color-trans-black">{t('Selected')}</p>
                </div>
                <div className={'select__options-list'}>
                    {selected.length ? (
                        selected.map((v, index) => (
                            <div
                                key={`${v[valueKey]}_${index}`}
                                className={cx(
                                    'styled-option select__option react-select__option',
                                    isEditable !== 'Y' && 'disabled-select-option',
                                )}
                                onClick={() => {
                                    if (isEditable !== 'Y') {
                                        return;
                                    }
                                    setValue(
                                        selected.filter(selectedOption => selectedOption[valueKey] !== v[valueKey]),
                                    );
                                }}
                            >
                                <label className="pnt-checkbox form-h-small check-checked" htmlFor={v[labelKey]}>
                                    <input
                                        id={v[labelKey]}
                                        type="checkbox"
                                        style={{ cursor: 'pointer' }}
                                        value={v[valueKey]}
                                        checked
                                        onChange={() => null}
                                    />
                                    <span className="checkbox-effect" />
                                </label>
                                <span className="overflow-hidden text-ellipsis">{v[labelKey]}</span>
                            </div>
                        ))
                    ) : (
                        <div className={'styled-option-label'}>{t('Not Selected')}</div>
                    )}
                </div>
                <div className="pnt-divider horizon-line border-gray opacity-6 mb-0" />
                <div
                    className={cx(
                        'flx-row tl p-1 border-bottom bg-depth-5',
                        isEditable !== 'Y' && !filterEditMode && 'disabled-select-option',
                    )}
                >
                    <label className={cx('pnt-checkbox form-h-small')} htmlFor={'All'} title={t('All Items', 'Select')}>
                        <input
                            id={'All'}
                            type="checkbox"
                            onChange={handleChange}
                            checked={options.length === selected.length}
                        />
                        <span className="checkbox-effect" />
                    </label>
                    <p className="pnt-txt txt-bold color-trans-black">{t('All')}</p>
                </div>
                <div className={cx(isEditable !== 'Y' && !filterEditMode && 'disabled-select-option')}>{children}</div>
                <div className="pnt-divider horizon-line border-gray opacity-6 mb-0" />
                <div className={cx(!filterEditMode && 'disabled-option')}>
                    <div
                        className={cx('flx-row tl p-1 border-bottom bg-depth-6', !filterEditMode && 'disabled-option')}
                    >
                        <p className="pnt-txt txt-bold color-trans-black">{filterT('Option')}</p>
                    </div>
                    <div className={'styled-option flx-row'}>
                        <label
                            className={cx('pnt-checkbox form-h-small gap-1')}
                            htmlFor={'Permission'}
                            title={filterT('Permission to change')}
                        >
                            <input
                                id={'Permission'}
                                type="checkbox"
                                onChange={e => dispatch(setEditable(e.currentTarget.checked ? 'Y' : 'N'))}
                                checked={isEditable === 'Y'}
                            />
                            <span className="checkbox-effect" />
                            <span className="pnt-txt">{filterT('Permission to change')}</span>
                        </label>
                    </div>
                </div>
            </components.MenuList>
        </div>
    );
};

export const Option = function ({ label, isSelected, innerProps, ...restProps }) {
    const { isEditable } = useContext(CustomSearchableSelectContext);

    return (
        <components.Option
            className={cx('styled-option select__option', isEditable !== 'Y' && 'disabled-select-option')}
            {...restProps}
            innerProps={{
                ...innerProps,
                onClick: e => {
                    if (isEditable !== 'Y') {
                        return;
                    }
                    e.stopPropagation();
                    e.preventDefault();
                    innerProps.onClick(e);
                },
            }}
        >
            <label className={'pnt-checkbox form-h-small'} htmlFor={label} title={label}>
                <input id={label} type="checkbox" checked={isSelected} onChange={() => null} />
                <span className="checkbox-effect" />
            </label>
            <span className="overflow-hidden text-ellipsis">{label}</span>
        </components.Option>
    );
};
