import React, { useEffect, useMemo, useState, useRef } from 'react';
import WidgetCard from '../../Components/WidgetCard';
import { useSettings } from '../../util/useSettings';
import { useTranslation, useAppSelector } from '@hooks';
import { SCREEN_MODE_EDIT } from '@reducer/ScreenInfo';
import { DUMMY_DATA, INIT_CATEGORY_STATUS, INIT_DATA } from './constants';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_LOCATION } from '@reducer/SocketInfo';
import { convertState } from '@reducer/TagInfo/processSocketData';
import CategoryPieChart from './CategoryPieChart';

const CategoryChart = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('CategoryChart');
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const categoryStatusRef = useRef(INIT_CATEGORY_STATUS);
    const { mode } = useAppSelector(state => state.ScreenInfo);
    const { topCategoryList, categoryList } = useAppSelector(state => state.CategoryInfo);
    const [status, setStatus] = useState(INIT_DATA);

    const filterConfig = useMemo(() => {
        if (settings.categoryType?.length) {
            const allCategoryCode = settings.categoryType.reduce((categoryCodeAcc, topCategoryCode) => {
                const childrenCodes = categoryList.reduce((childrenCodes, categoryInfo) => {
                    if (categoryInfo.parentCode === topCategoryCode) {
                        childrenCodes.push(categoryInfo.categoryCode);
                    }
                    return childrenCodes;
                }, []);
                categoryCodeAcc.push(topCategoryCode, ...childrenCodes);
                return categoryCodeAcc;
            }, []);
            return { targetCategory: { categoryCode: { $in: allCategoryCode } } };
        }
        return {};
    }, [settings, categoryList]);

    const subTitle = useMemo(() => {
        if (settings.categoryType && settings.categoryType.length !== 0) {
            const { categoryType } = settings;
            const displayCategoryName = topCategoryList?.find(({ value }) => value === categoryType[0])?.label;

            if (categoryType.length === 1) {
                return displayCategoryName;
            } else if (categoryType.length < topCategoryList.length) {
                return `${displayCategoryName} ${t('and')} ${categoryType.length - 1} ${t('more')}`;
            }
        }
        return t('All Category Type');
    }, [t, settings, topCategoryList]);

    useSocketEvent({
        name: EVENT_TYPE_LOCATION,
        filterConfig,
        handler: dataList => {
            const categoryStatus = categoryStatusRef.current;
            dataList.forEach(data => {
                const { target, targetState, targetCategory } = data;
                const { targetNum } = target;
                const { lostSignal } = convertState(targetState);
                const tagCategory =
                    categoryList.find(categoryInfo => categoryInfo.categoryCode === targetCategory.categoryCode)
                        ?.parentCode || targetCategory.categoryCode;

                for (let key in categoryStatus) {
                    const status = categoryStatus[key];
                    categoryStatus[key] = status.filter(existTargetNum => existTargetNum !== targetNum);
                }
                if (!lostSignal) {
                    if (categoryStatus[tagCategory]) {
                        categoryStatus[tagCategory].push(targetNum);
                    } else {
                        categoryStatus[tagCategory] = [targetNum];
                    }
                }
            });

            setStatus(
                Object.entries(categoryStatus).map(([categoryCode, status]) => {
                    return {
                        key: categoryCode,
                        name: categoryList.find(categoryInfo => categoryInfo.categoryCode === categoryCode)
                            ?.categoryName,
                        value: status.length,
                    };
                }),
            );
        },
        enableBuffer: true,
        intervalTime: 2000,
    });

    useEffect(() => {
        if (mode === SCREEN_MODE_EDIT) {
            setStatus(DUMMY_DATA);
        }
    }, [mode]);

    return (
        <WidgetCard widgetInfo={widgetInfo} subTitle={subTitle} {...restProps}>
            <div className={'scrollbar-container'}>
                <div className={'flex-center h-100'}>
                    <CategoryPieChart data={status} settings={settings} />
                </div>
            </div>
            {children}
        </WidgetCard>
    );
};

export default CategoryChart;
