import React, { useRef, useState } from 'react';
import { WidgetProps } from '../../staticInfo';
import WidgetCard from '../../Components/WidgetCard';
import { useAsync, useTranslation } from '@util/hooks';
import { ASSET_MANAGEMENT } from '../../constants';
import useMultiOptionsByRole from '../util/useMultiOptionsByRole';
import useCategoryList from '../util/useCategoryList';
import Filter from '../Components/Filter';
import Lend from './Lend';
import Borrow from './Borrow';
import useAssetStatusOptions from './hooks/useAssetStatusOptions';
import { getBorrowingAssetList, getLendingAssetList } from '@api/am/management';

const RentalManagement = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    const widgetRef = useRef(null);
    const t = useTranslation(ASSET_MANAGEMENT);
    const rentalType = widgetInfo.config.settings?.categoryCode; // undefined | "lend" | "borrow"
    const [isRequestShow, setIsRequestShow] = useState(false);

    // 필터 정보: 병동, 카테고리, 대여상태, 검색어, 페이지
    const { selectedWardList, options: wardOptions, handleWardChange, handleWardReset } = useMultiOptionsByRole();
    const {
        initialCategoryList,
        selectedCategoryList,
        categoryOptions,
        handleCategoryChange,
        handleCategoryReset,
    } = useCategoryList();
    const { status, statusOptions, handleStatusChange, handleOptionReset } = useAssetStatusOptions();
    const [keyword, setKeyword] = useState('');
    const [page, setPage] = useState(1);

    const handleKeywordChange = (keyword: string) => {
        setKeyword(keyword);
        setPage(1);
    };

    const handleResetClick = () => {
        setKeyword('');
        handleWardReset();
        handleCategoryReset();
        handleOptionReset();
        setPage(1);
    };

    const { state, promise: getRentalList } = useAsync({
        promise: rentalType === 'lend' ? getLendingAssetList : getBorrowingAssetList,
        immediate: true,
        param: {
            wardList: selectedWardList,
            categoryCodes:
                selectedCategoryList.length === 0 ? initialCategoryList.toString() : selectedCategoryList.toString(),
            rentalStatus: status.value,
            page,
            pageSize: 15,
            targetName: keyword.length === 0 ? undefined : keyword,
        },
        deps: [keyword, status.value, selectedWardList, selectedCategoryList],
        reject: err => console.error(err),
    });
    const data = state.response ?? {
        page: 1,
        rows: [],
        totalCount: 0,
        totalPage: 0,
    };

    let content = null;
    if (rentalType === 'lend') {
        content = (
            <Lend
                data={data}
                getRentalList={getRentalList}
                onKeywordChange={handleKeywordChange}
                onResetClick={handleResetClick}
                onPageChange={(pageIndex: number) => setPage(pageIndex)}
            />
        );
    } else if (rentalType === 'borrow') {
        content = (
            <Borrow
                data={data}
                getRentalList={getRentalList}
                isRequestShow={isRequestShow}
                onRequestShowChange={() => setIsRequestShow(!isRequestShow)}
                onKeywordChange={handleKeywordChange}
                onResetClick={handleResetClick}
                onPageChange={(pageIndex: number) => setPage(pageIndex)}
            />
        );
    } else {
        content = <div className="d-flex flex-center h-90 fw-bold fs-5">{t('Please set the rental type.')}</div>;
    }

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            ref={widgetRef}
            searchFilter={
                !isRequestShow && (
                    <Filter>
                        <Filter.SearchableSelect
                            options={{
                                title: t('Ward', 'CommonColumn'),
                                data: wardOptions,
                                selected: selectedWardList,
                                onChange: (selected: any) => {
                                    setPage(1);
                                    handleWardChange(selected);
                                },
                            }}
                        />
                        <Filter.SearchableSelect
                            options={{
                                title: t('Category'),
                                data: categoryOptions,
                                selected: selectedCategoryList,
                                onChange: (selected: any) => {
                                    setPage(1);
                                    handleCategoryChange(selected);
                                },
                            }}
                        />
                        <Filter.Select
                            options={{
                                value: status,
                                options: statusOptions,
                                onChange: (selected: any) => {
                                    setPage(1);
                                    handleStatusChange(selected);
                                },
                            }}
                        />
                    </Filter>
                )
            }
            {...restProps}
        >
            {content}
            {children}
        </WidgetCard>
    );
};

export default RentalManagement;
