import {
    getDailyInspectionLogListApi,
    postDailyHistoryCheckApi,
    postDailyHistoryOutputTestApi,
} from '@api/am/management';
import { Button, Table } from '@components';
import InnerItemCard from '@components/Card/InnerItemCard';
import { useAsync, useTranslation } from '@hooks';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import SearchGroupAM from '../Components/SearchGroupAM';
import WidgetCard from '../../Components/WidgetCard';
import { ASSET_MANAGEMENT } from '../../constants';
import { useAsyncExcel } from '../../util/useAsyncExcel';
import useCategoryList from '../util/useCategoryList';
import useCheckTypeOptions from '../util/useCheckTypeOptions';
import useModal from '../../util/useModal';
import useMultiOptionsByRole from '../util/useMultiOptionsByRole';
import useRangeDateAM from '../util/useRangeDateAM';
import useRiskLevelOptions from '../util/useRiskLevelOptions';
import HighRiskModal from './HighRiskModal';
import NormalModal from './NormalModal';
import TableSearchBox from './TableSearchBox';
import { COLUMN_METAS, DEFAULT_RESPONSE, DEFAULT_VALUE, FIXED_PARAMS, ROWS_DUMMY } from './constants';
import { SCREEN_MODE_MONITORING } from '@reducer/ScreenInfo';
import moment from 'moment';
import useAppSelector from '@hooks/useAppSelector';
import { DATE_FORMAT } from '@util/times';
import * as column from '@util/grid/column';
import Modal from '@components/Modal';
import { Trans } from 'react-i18next';
import TimeSelect from '../Components/TimeSelect';

const DailyInspectionHistoryCheck = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();
    const t = useTranslation(ASSET_MANAGEMENT);
    const { mode } = useAppSelector(state => state.ScreenInfo);

    const [isHighRiskOpen, handleHighRiskToggle, handleHighRiskOpen] = useModal();
    const [isNormalOpen, handleNormalToggle, handleNormalOpen] = useModal();
    const [rowInfo, setRowInfo] = useState();
    const [searchResultInput, setSearchResultInput] = useState('');

    const wardListValues = useMultiOptionsByRole();
    const categoryValues = useCategoryList();
    const rangeDateValues = useRangeDateAM();
    const riskLevelValues = useRiskLevelOptions();
    const checkTypeValues = useCheckTypeOptions(); // 점검구분은 수량 점검에는 없음

    const { state, promise: getList } = useAsync({
        promise: getDailyInspectionLogListApi,
        immediate: mode === SCREEN_MODE_MONITORING,
        deps: [
            wardListValues.selectedWardList.length,
            categoryValues.selectedCategoryList.length,
            rangeDateValues.startDate,
            rangeDateValues.endDate,
            checkTypeValues.checkTypeList.length,
            riskLevelValues.riskLevelList.length,
            searchResultInput,
        ],
        param: {
            ...FIXED_PARAMS,
            ward:
                wardListValues.selectedWardList.length !== 0
                    ? wardListValues.selectedWardList.toString()
                    : wardListValues.options.map(v => v.value).toString(),
            categoryCode: categoryValues.selectedCategoryList.toString(),
            startCheckDate: rangeDateValues.startDate,
            endCheckDate: rangeDateValues.endDate,
            checkType:
                checkTypeValues.checkTypeList.length !== 0
                    ? checkTypeValues.checkTypeList.toString()
                    : checkTypeValues.checkTypeOptions.map(({ value }) => value).toString(),
            riskLevel:
                riskLevelValues.riskLevelList.length !== 0
                    ? riskLevelValues.riskLevelList.toString()
                    : riskLevelValues.riskLevelOptions.map(({ value }) => value).toString(),
            targetIdOrName: searchResultInput,
        },
        reject: err => console.error(err),
        keepState: true,
    });

    const filteredData =
        mode !== SCREEN_MODE_MONITORING
            ? { ...DEFAULT_RESPONSE, rows: ROWS_DUMMY }
            : {
                  ...DEFAULT_RESPONSE,
                  ...state.response,
                  rows: state.response?.rows || [],
              };

    const handleCheckResultClick = useCallback(
        rowInfo => {
            setRowInfo(rowInfo);
            if (rowInfo.riskLevel === 'high') {
                handleHighRiskOpen();
                return;
            }
            handleNormalOpen();
        },
        [handleHighRiskOpen, handleNormalOpen],
    );

    const handleSearchClick = input => {
        setSearchResultInput(input);
        // getList();
    };

    const handleRefreshClick = () => {
        setSearchResultInput('');
        getList();
    };

    const columns = useMemo(
        () => [
            column.index({ width: 60 }),
            {
                Header: t('Date'),
                className: 'text-nowrap',
                Cell: ({ row }) =>
                    row.original?.regDate ? moment.unix(row.original?.regDate).format(DATE_FORMAT.YMD) : DEFAULT_VALUE,
            },
            {
                Header: t('Check Date'),
                Cell: ({ row }) =>
                    row.original?.checkDate
                        ? moment.unix(row.original?.checkDate).format(DATE_FORMAT.HMS)
                        : DEFAULT_VALUE,
            },
            {
                Header: t('Ward/Dept'),
                accessor: 'wardName',
                className: 'text-ellipsis',
            },
            {
                Header: t('Risk Level'),
                accessor: 'riskLevel',
                className: 'text-ellipsis',
                Cell: ({ value }) => t(value),
            },
            {
                Header: t('Category'),
                accessor: 'categoryName',
                className: 'text-ellipsis',
            },
            {
                Header: t('Asset Name'),
                accessor: 'targetName',
                className: 'text-ellipsis',
            },
            {
                Header: t('Asset ID'),
                accessor: 'targetId',
                className: 'text-ellipsis text-uppercase',
            },
            {
                Header: t('Check Type'),
                accessor: 'checkType',
                Cell: ({ value }) => t(`${value} inspection`),
            },
            {
                Header: t('Check Result'),
                accessor: 'checkResult',
                Cell: ({ value, row }) =>
                    value ? (
                        <span
                            className="text-decoration-underline text-primary cursor-pointer"
                            onClick={() => handleCheckResultClick(row.original)}
                        >
                            {t(value)}
                        </span>
                    ) : (
                        DEFAULT_VALUE
                    ),
            },
            {
                Header: t('Checker Name'),
                accessor: 'checkerName',
                className: 'text-ellipsis',
            },
            {
                Header: t('Username'),
                accessor: 'checkerId',
                className: 'text-ellipsis',
                Cell: ({ value }) => {
                    if (!value) {
                        return DEFAULT_VALUE;
                    }
                    if (value.indexOf('@') < 0) {
                        return value;
                    }
                    return value.substring(0, value.indexOf('@'));
                },
            },
        ],
        [handleCheckResultClick, t],
    );

    const triggerDownload = useAsyncExcel({
        promise: postDailyHistoryCheckApi,
        param: {
            dailyCheckAssetLogSearchParam: {
                ...state.request,
            },
            excelDownloadRequest: {
                zipFileName: 'dailyCheckLogsZip',
                fileName: `dailyCheckLog_${moment().format(DATE_FORMAT.FLAT_YMD_HMS)}`,
                columnMetas: COLUMN_METAS,
            },
        },
    });

    const triggerOutputTestDownload = useAsyncExcel({
        promise: postDailyHistoryOutputTestApi,
        param: {
            startDate: rangeDateValues.startDate,
            endDate: rangeDateValues.endDate,
            searchRequests: filteredData.rows
                .filter(v => v.checkResult && v.riskLevel === 'high')
                .map(v => ({ targetName: v.targetName, dailyCheckNum: v.dailyCheckNum })),
        },
    });

    const handleDownloadClick = () => {
        triggerDownload();
    };

    const [isOpen, handleToggle, handleOpen] = useModal();

    const handleOutputTestClick = () => {
        const imgCheckData = filteredData.rows
            .filter(v => v.checkResult && v.riskLevel === 'high')
            .map(v => ({ targetName: v.targetName, dailyCheckNum: v.dailyCheckNum }));
        if (imgCheckData && imgCheckData.length) {
            triggerOutputTestDownload();
        } else {
            handleOpen();
        }
    };

    const handlePagination = page => {
        getList({ ...state.request, page });
    };

    return (
        <>
            <WidgetCard
                ref={widgetRef}
                widgetInfo={widgetInfo}
                searchFilter={
                    <SearchGroupAM
                        wardListValues={wardListValues}
                        categoryValues={categoryValues}
                        rangeDateValues={rangeDateValues}
                        riskLevelValues={riskLevelValues}
                        checkTypeValues={checkTypeValues}
                        onSearchClick={handleSearchClick}
                    />
                }
                bodyClassName="pt-0"
                {...restProps}
            >
                <InnerItemCard
                    header={{
                        title: t('Daily Inspection History Check'),
                        action: (
                            <>
                                <Button className="btn-darkgray" iconName={'download'} onClick={handleOutputTestClick}>
                                    {t('Output Test Download')}
                                </Button>
                                <Button className="btn-darkgray" iconName={'download'} onClick={handleDownloadClick}>
                                    {t('Download')}
                                </Button>
                            </>
                        ),
                    }}
                    className="mt-2"
                >
                    <div className="d-flex justify-content-between">
                        <TableSearchBox onSearchClick={handleSearchClick} onRefreshClick={handleRefreshClick} />
                    </div>
                    <TableWrapper>
                        <Table
                            columns={columns}
                            loading={state.isLoading}
                            data={filteredData}
                            onPageChange={handlePagination}
                            noDataMessage={t('No check history results.')}
                        />
                    </TableWrapper>
                </InnerItemCard>
                {children}
            </WidgetCard>
            {isHighRiskOpen && (
                <HighRiskModal isOpen={isHighRiskOpen} toggleModal={handleHighRiskToggle} rowInfo={rowInfo} />
            )}
            {isNormalOpen && <NormalModal isOpen={isNormalOpen} toggleModal={handleNormalToggle} rowInfo={rowInfo} />}
            <Modal
                removeCancel
                initModal={isOpen}
                toggleModal={handleToggle}
                okCallback={handleToggle}
                headerTitle={t('Alert')}
            >
                <div className="modal-body">{t('OutputTest has nothing to download.')}</div>
            </Modal>
        </>
    );
};

const TableWrapper = styled.div`
    height: 38rem;
`;

export default DailyInspectionHistoryCheck;
