import React, { useState, useEffect } from 'react';
import { useAsync, useTranslation } from '@hooks';
import { fetchRealTimeSensorMonitoring } from '@api/monitoring';
import { setSelectedSensor, setSensorList } from './realTimeSensorMonitoringSlice';
import { ALL_TYPE, FIXED_TYPE, MOBILE_TYPE, PARAMETER_DEFAULT_PAGE } from './constants';
import SensorType from './Components/SensorType';
import SelectedSensor from './Components/SelectedSensor';
import { Button, LoadingBlock } from '@components';
import NoDataBlock from '../../Components/NoDataBlock';
import SensorList from './Components/SensorList';
import WidgetCard from '../../Components/WidgetCard';
import { useSettings } from '../../util/useSettings';
import { useRealTimeSensorMonitoringContext, useRealTimeSensorMonitoringDispatch } from './index';

const RealTimeSensorMonitoringContainer = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('RealTimeSensorMonitoring');
    const { config } = widgetInfo;
    const settings = useSettings(config);

    const { sensorType, sensorList, selectedSensor, pageInfo, sensorFilterInfo } = useRealTimeSensorMonitoringContext();
    const dispatch = useRealTimeSensorMonitoringDispatch();

    // 검색 조건 버튼을 위한 상태
    const [showSearchArea, setShowSearchArea] = useState(false);

    // data fetch
    const { promise: getSensorList, state: sensorsRequestState } = useAsync({
        promise: fetchRealTimeSensorMonitoring,
        fixedParam: PARAMETER_DEFAULT_PAGE,
        resolve: res => {
            dispatch(setSensorList(res));
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        getSensorList({ ...sensorFilterInfo, page: pageInfo.page });
    }, [sensorFilterInfo, pageInfo.page]);

    const handleSearchAreaClick = () => setShowSearchArea(prev => !prev);
    const handleGoBackClick = () => dispatch(setSelectedSensor(null));

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            {...restProps}
            subTitle={
                sensorType === ALL_TYPE.value
                    ? t(ALL_TYPE.label)
                    : sensorType === FIXED_TYPE.value
                    ? t(FIXED_TYPE.label)
                    : t(MOBILE_TYPE.label)
            }
            headerAction={
                !selectedSensor && (
                    <Button className={'btn-darkgray'} onClick={handleSearchAreaClick}>
                        {t('Search', 'Button')}
                    </Button>
                )
            }
            searchFilter={showSearchArea && <SensorType searchPromise={getSensorList} />}
            handleGoBackClick={selectedSensor && handleGoBackClick}
        >
            {selectedSensor ? (
                <SelectedSensor settings={settings} />
            ) : (
                <LoadingBlock blocking={sensorsRequestState.request?.page === 1 && sensorsRequestState.isLoading}>
                    {!Array.isArray(sensorList) || !sensorList.length ? <NoDataBlock /> : <SensorList />}
                </LoadingBlock>
            )}
            {children}
        </WidgetCard>
    );
};

export default RealTimeSensorMonitoringContainer;
