export const categoryExam = ({ ...restProps } = {}) => {
    return {
        Header: 'Classification',
        headerClassName: 'text-ellipsis',
        accessor: 'category',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const testName = ({ ...restProps } = {}) => {
    return {
        Header: 'Test name',
        headerClassName: 'text-ellipsis',
        accessor: 'examName',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const payment = ({ ...restProps } = {}) => {
    return {
        Header: 'Payment',
        headerClassName: 'text-ellipsis',
        accessor: 'storage',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const singleTest = ({ ...restProps } = {}) => {
    return {
        Header: 'Single',
        headerClassName: 'text-ellipsis',
        accessor: 'isSole',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const specimen = ({ ...restProps } = {}) => {
    return {
        Header: 'Specimen',
        headerClassName: 'text-ellipsis',
        accessor: 'specimen',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const container = ({ ...restProps } = {}) => {
    return {
        Header: 'Container',
        headerClassName: 'text-ellipsis',
        accessor: 'containers',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const category = ({ ...restProps } = {}) => {
    return {
        Header: 'Category',
        headerClassName: 'text-ellipsis',
        accessor: 'category',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const ward = ({ ...restProps } = {}) => {
    return {
        Header: 'Ward',
        headerClassName: 'text-ellipsis',
        accessor: 'department',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const speciality = ({ ...restProps } = {}) => {
    return {
        Header: 'Speciality',
        headerClassName: 'text-ellipsis',
        accessor: 'specialties',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const doctor = ({ ...restProps } = {}) => {
    return {
        Header: 'Doctor',
        headerClassName: 'text-ellipsis',
        accessor: 'doctor',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const id = ({ ...restProps } = {}) => {
    return {
        Header: 'Patient ID',
        headerClassName: 'text-ellipsis',
        accessor: 'patientRegNum',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const patientName = ({ ...restProps } = {}) => {
    return {
        Header: 'Patient Name',
        headerClassName: 'text-ellipsis',
        accessor: 'patientName',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const reservedDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Reserved Date',
        headerClassName: 'text-ellipsis',
        accessor: 'appointmentDate',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const reservedTime = ({ ...restProps } = {}) => {
    return {
        Header: 'Reserved Time',
        headerClassName: 'text-ellipsis',
        accessor: 'appointmentTime',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const arrivedTime = ({ ...restProps } = {}) => {
    return {
        Header: 'Arrived Time',
        headerClassName: 'text-ellipsis',
        accessor: 'arrivalTime',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const basicTest = ({ ...restProps } = {}) => {
    return {
        Header: 'Basic Inspection',
        headerClassName: 'text-ellipsis',
        accessor: 'isFoundationExam',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const waitingCare = ({ ...restProps } = {}) => {
    return {
        Header: 'Waiting for care',
        headerClassName: 'text-ellipsis',
        accessor: 'isWaiting',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const care = ({ ...restProps } = {}) => {
    return {
        Header: 'Care Status',
        headerClassName: 'text-ellipsis',
        accessor: 'isCare',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const examCode = ({ ...restProps } = {}) => {
    return {
        Header: 'Inspection Code',
        headerClassName: 'text-ellipsis',
        accessor: 'examCode',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const examName = ({ ...restProps } = {}) => {
    return {
        Header: 'Inspection Name',
        headerClassName: 'text-ellipsis',
        accessor: 'examName',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const arrivedNumber = ({ ...restProps } = {}) => {
    return {
        Header: 'Arrival number',
        headerClassName: 'text-ellipsis',
        accessor: 'arrivalNum',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
export const inspectOrNot = ({ ...restProps } = {}) => {
    return {
        Header: 'Inspect or not',
        headerClassName: 'text-ellipsis',
        accessor: 'isInspect',
        className: 'd-flex flx-center text-ellipsis',
        width: 100,
        ...restProps,
    };
};
