import { useEffect, useMemo, useRef } from 'react';
import {
    NOTIFICATION_PARAMETER_KEY,
    SENSOR_SCOPE_KEY,
    setEmergencyAlertContents,
    clearAlertContents,
} from '@reducer/EmergencyAlert';
import { useDispatch, useSelector } from 'react-redux';
import { EVENT_TYPE_SENSOR_ITEM_STATUS_EVENT, EVENT_TYPE_SENSOR_STATUS_EVENT } from '@reducer/SocketInfo';
import useSocket from '@util/socket/hooks/useSocket';
import useVisibility from '@hooks/useVisibility';
import useActivationFilter from '@hooks/EmergencyAlert/useActivationFilter';

const LOST_SIGNAL_COMMAND_TYPE = 'LOST_SIGNAL';

const convertSensorInterfaceCommandListToSensorSocketFilterCondition = (sensorInterfaceCommandList, target) => {
    if (!sensorInterfaceCommandList) {
        return null;
    }
    if (!sensorInterfaceCommandList.length) {
        return null;
    }

    return sensorInterfaceCommandList.reduce((acc, notificationSettings) => {
        const { scope, interfaceCommandType, modelCode, sensingType } = notificationSettings;
        const isAll = scope === SENSOR_SCOPE_KEY.ALL;
        // sensor All / Item 구분 초기값 설정
        if (!acc) {
            acc = isAll ? {} : new Map();
        }
        // 전체 센서 타입의 경우
        if (isAll) {
            if (!interfaceCommandType.includes(LOST_SIGNAL_COMMAND_TYPE)) {
                acc = {
                    ...acc,
                    sensorItemList: {
                        target,
                        event: {
                            interfaceCommandType: {
                                $in: !acc.sensorItemList?.event?.interfaceCommandType
                                    ? [interfaceCommandType]
                                    : [...acc.sensorItemList.event.interfaceCommandType.$in, interfaceCommandType],
                            },
                        },
                    },
                };
            } else {
                acc = {
                    ...acc,
                    status: {
                        target,
                        event: {
                            interfaceCommandType,
                        },
                    },
                };
            }
            return acc;
        }

        if (!acc.has(modelCode)) {
            if (!interfaceCommandType.includes(LOST_SIGNAL_COMMAND_TYPE)) {
                acc.set(modelCode, {
                    sensorItemList: [
                        {
                            target,
                            sensor: { modelCode },
                            event: {
                                interfaceCommandType,
                                sensingItem: {
                                    sensingType,
                                },
                            },
                        },
                    ],
                });
                return acc;
            } else {
                acc.set(modelCode, {
                    sensorItemList: [],
                    status: {
                        target,
                        sensor: { modelCode },
                        event: {
                            interfaceCommandType,
                        },
                    },
                });
                return acc;
            }
        } else {
            const config = acc.get(modelCode);
            if (!interfaceCommandType.includes(LOST_SIGNAL_COMMAND_TYPE)) {
                const sensorItemList = config.sensorItemList;
                const findSensorItemIndex = sensorItemList.findLastIndex(
                    ({ sensor, event }) =>
                        sensor?.modelCode === modelCode && event?.sensingItem?.sensingType === sensingType,
                );
                if (findSensorItemIndex !== -1) {
                    sensorItemList[findSensorItemIndex] = {
                        ...sensorItemList[findSensorItemIndex],
                        event: {
                            ...sensorItemList[findSensorItemIndex].event,
                            interfaceCommandType: {
                                $in: sensorItemList[findSensorItemIndex].event.interfaceCommandType?.$in
                                    ? [
                                          ...sensorItemList[findSensorItemIndex].event.interfaceCommandType.$in,
                                          interfaceCommandType,
                                      ]
                                    : [
                                          sensorItemList[findSensorItemIndex].event.interfaceCommandType,
                                          interfaceCommandType,
                                      ],
                            },
                        },
                    };
                } else {
                    sensorItemList.push({
                        target,
                        sensor: { modelCode },
                        event: {
                            interfaceCommandType,
                            sensingItem: {
                                sensingType,
                            },
                        },
                    });
                }
                acc.set(modelCode, {
                    ...config,
                    sensorItemList,
                });
            } else {
                acc.set(modelCode, {
                    ...config,
                    status: {
                        target,
                        sensor: { modelCode },
                        event: {
                            interfaceCommandType,
                        },
                    },
                });
            }
            return acc;
        }
    }, null);
};

const SOCKET_INTERVAL_TIME = 5000;

// TODO: 긴급 알림 설정 추가 기획 후 변경 예정
const useEmergencyAlert = () => {
    const dispatch = useDispatch();
    const { emergencySocketFilterConfig } = useSelector(state => state.EmergencyAlert);
    const { oAuthInfo } = useSelector(state => state.UserInfo);
    const { hidden } = useVisibility();

    const socketBuffer = useRef({
        interval: null,
        buffer: [],
    });

    const flushData = () => {
        handleAlert(socketBuffer.current.buffer.splice(0));
    };

    const flushDataInterval = () => {
        if (socketBuffer.current.interval) {
            clearInterval(socketBuffer.current.interval);
        }
        socketBuffer.current.interval = window.setInterval(() => {
            flushData();
        }, SOCKET_INTERVAL_TIME);
    };

    const stopFlushDataInterval = () => {
        flushData();
        if (socketBuffer.current.interval) {
            clearInterval(socketBuffer.current.interval);
        }
        socketBuffer.current.interval = null;
    };

    // 소켓 메세지 핸들러
    const handleAlert = data => {
        dispatch(setEmergencyAlertContents(data));
    };

    const { socket, setSocketEvent, removeSocketEvent } = useSocket();

    // 기기 유형별(tag, sensor) 구분하여 소켓 채널 생성
    const provideSocketChannelByDeviceType = ({ sensorConfigList }) => {
        const filterInfos = [];
        if (sensorConfigList && sensorConfigList instanceof Map) {
            sensorConfigList.forEach(sensorConfig => {
                const { status, sensorItemList } = sensorConfig;
                // Sensor 기기 신호 상태 이벤트 채널
                if (status) {
                    filterInfos.push({
                        messageType: EVENT_TYPE_SENSOR_STATUS_EVENT,
                        filterId: setSocketEvent(EVENT_TYPE_SENSOR_STATUS_EVENT, null, status),
                    });
                }
                // Sensor item 신호 상태 이벤트 채널
                if (sensorItemList && sensorItemList.length !== 0) {
                    sensorItemList.forEach(sensorItemFilterConfig => {
                        filterInfos.push({
                            messageType: EVENT_TYPE_SENSOR_ITEM_STATUS_EVENT,
                            filterId: setSocketEvent(EVENT_TYPE_SENSOR_ITEM_STATUS_EVENT, null, sensorItemFilterConfig),
                        });
                    });
                }
            });
            // Sensor 조건이 존재하면서 객체의 경우
        } else if (sensorConfigList && !(sensorConfigList instanceof Map)) {
            const { status, sensorItemList } = sensorConfigList;
            // Sensor 기기 신호 상태 이벤트 채널
            filterInfos.push({
                messageType: EVENT_TYPE_SENSOR_STATUS_EVENT,
                filterId: setSocketEvent(EVENT_TYPE_SENSOR_STATUS_EVENT, null, status),
            });
            // Sensor item 신호 상태 이벤트 채널
            filterInfos.push({
                messageType: EVENT_TYPE_SENSOR_ITEM_STATUS_EVENT,
                filterId: setSocketEvent(EVENT_TYPE_SENSOR_ITEM_STATUS_EVENT, null, sensorItemList),
            });
        }
        return filterInfos;
    };

    const removeSocketChannelByDeviceType = filters => {
        filters.forEach(({ messageType, filterId }) => {
            removeSocketEvent(messageType, null, filterId);
        });
    };

    useActivationFilter();

    // Group socket filter config
    const socketFilterConfig = useMemo(() => {
        if (!emergencySocketFilterConfig || emergencySocketFilterConfig.length === 0) {
            return [];
        }
        return emergencySocketFilterConfig.map(curr => {
            const target = {
                properties: {
                    [curr.propertyId]: {
                        $in: curr.loginGroups,
                    },
                },
            };
            return {
                sensorConfigList: convertSensorInterfaceCommandListToSensorSocketFilterCondition(
                    curr[NOTIFICATION_PARAMETER_KEY.sensorAlertInterfaceCommandList],
                    target,
                ),
            };
        });
    }, [emergencySocketFilterConfig]);

    useEffect(() => {
        if (hidden) {
            dispatch(clearAlertContents({ keepOpenedModal: true }));
            return;
        }

        let emergencyAlertFilters = [];
        if (socketFilterConfig && socketFilterConfig.length !== 0) {
            emergencyAlertFilters = socketFilterConfig.map(groupFilterConfig =>
                provideSocketChannelByDeviceType(groupFilterConfig),
            );
        }

        const appendBuffer = data => {
            if (
                emergencyAlertFilters.some(filters =>
                    filters.some(filter => data.hooksMetadata.filterIds.includes(filter.filterId)),
                )
            ) {
                socketBuffer.current.buffer.push(data);
            }
        };

        // Socket 채널 설정
        const eventTypeList = [EVENT_TYPE_SENSOR_STATUS_EVENT, EVENT_TYPE_SENSOR_ITEM_STATUS_EVENT];
        eventTypeList.forEach(eventType => setSocketEvent(eventType, appendBuffer));

        flushDataInterval();
        return () => {
            // Socket 채널 해지
            eventTypeList.forEach(eventType => removeSocketEvent(eventType, appendBuffer));
            emergencyAlertFilters.forEach(filters => removeSocketChannelByDeviceType(filters));
            stopFlushDataInterval();
        };
    }, [socketFilterConfig, socket, hidden]);
};

export default useEmergencyAlert;
