import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BookmarkedItem {
    loaded: boolean;
    bookmarkedList: number[];
}

const initialState: BookmarkedItem = {
    // 로딩이 한 번 이라도 됐는지 판단
    loaded: false,
    bookmarkedList: [],
};

const { actions, reducer } = createSlice({
    name: 'common/bookmarkedItem',
    initialState,
    reducers: {
        setBookmarkedList: (state, action: PayloadAction<number[]>) => {
            state.loaded = true;
            state.bookmarkedList = action.payload;
        },
    },
});

export const { setBookmarkedList } = actions;
export default reducer;
