export const layout = {
    spacerLg: '3rem',
    spacerSm: '1.5rem',
    layoutSpacerLg: '3rem',
    layoutSpacerX: '1.5rem',
    appSidebarWidth: '280px',
    appSidebarWidthCollapsed: '80px',
    logoHeight: '23px',
    logoWidth: '97px',
    appHeaderHeight: '60px',
    layoutShadow: '0 0 1rem rgba(30,10,10,0.15)',
};

export const color = {
    white: '#fff',
    gray100: '#EBEBEB',
    gray200: '#e2e2e2',
    gray300: '#dbdbdf',
    gray400: '#cfcfd3', //disable-bg
    gray500: '#b3b3b6', //form-border
    gray600: '#929294',
    gray700: '#585252',
    gray800: '#423c3c',
    gray900: '#2c2828',
    black: '#000',
};

export const form = {
    formHeight: '38px',
    formRadius: '5px',
    formGap: '5px',
    formBgColor: `${color.white}`,
    formTxtColor: `${color.gray700}`,
    tableTxtColor: `${color.gray600}`,
    formBorderColor: `${color.gray300}`,
    formBorderColorHover: `${color.gray600}`,
    formDisableBg: `${color.gray100}`,
    formDisableFont: `${color.gray500}`,
    formPlaceholderTxt: `${color.gray600}`,
    formShadow: `0px 0px 10px rgba(0,0,0,0.2)`,
    cardShadow: `0rem 0.47rem 2.19rem rgba(8,10,37,0.03), 0rem 0.94rem 1.41rem rgba(8,10,37,0.03), 0rem 0.25rem 0.53rem rgba(8,10,37,0.05), 0rem 0.13rem 0.19rem rgba(8,10,37,0.03)`,
    formActiveShadow: `0px 0px 10px rgba(255,50,50,0.2)`,
};

// For later Further CSS
export const pntInput = {
    backgroundColor: '#000',
};

// For Select
export const selectControl = {};

export const selectOption = {};

/////////////////////////////////////////////////////////////////////
// For Login Error Message
export const loginErrorMsg = {
    visibility: 'hidden',
};

export const breakpoint = {
    mobile: '570px',
    tablet: '758px',
    labtop: '1024px',
    desktop: '1440px',
    widemonitor: '1680px',
};
