import { useCallback, useState } from 'react';

const useModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    const handleOpen = useCallback(() => {
        setIsOpen(true);
    }, []);

    return [isOpen, handleToggle, handleOpen] as const;
};

export default useModal;
