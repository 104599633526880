import React from 'react';
import { useTranslation } from '@hooks';

const NotExistScreen = () => {
    const t = useTranslation('Screen');
    return (
        <div
            className="flex-center"
            style={{
                flexDirection: 'column',
                width: '100%',
                height: 'calc(100vh - 80px)',
                fontSize: '1.8rem',
                color: '#777',
                textAlign: 'center',
            }}
        >
            {t('Failed to load initial settings.')}
            <br />
            {t('Please contact the administrator.')}
        </div>
    );
};

export default NotExistScreen;
