import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    isEditable: 'Y',
    options: [],
};

const { actions, reducer } = createSlice({
    name: 'filterSelect',
    initialState,
    reducers: {
        setInitState: (state, action) => {
            const initData = action.payload || {};
            state.isEditable = initData.isEditable;
        },
        setEditable: (state, action) => {
            state.isEditable = action.payload;
        },
        setOptions: (state, action) => {
            state.options = action.payload;
        },
    },
});

export const { setInitState, setEditable, setOptions } = actions;
export default reducer;
