import { useCallback } from 'react';
import ApiError, {
    DEFAULT_ERRORS,
    HTTP_ERRORS,
    INDOORPLUS_ERROR_HANDLE_KEY,
    INDOORPLUS_ERRORS,
    OAUTH_ERRORS,
} from '@util/errorCode';

/**
 * 에러 발생시 공통적으로 실행 되는 에러내용
 * (당장은 공통으로 처리할 내용은 없음 추후 업데이트시 추가)
 * @param err ApiError.ResponseData
 */
const commonHandler = (err?: any) => {
    console.error('공통으로 처리되는 에러 내용', err);
};

/**
 * 어디에서도 정의되지않은 에러의 경우 실행
 * @param err ApiError.ResponseData
 */
const defaultHandler = (err?: any) => {
    console.error('기본 에러 내용', err);
};

/* http status default error handler */

const handler400 = (err?: any) => {
    console.error('HTTP STATUS 400 에러', err);
};
const handler401 = (err?: any) => {
    console.error('HTTP STATUS 401 에러', err);
};
const handler403 = (err?: any) => {
    console.error('HTTP STATUS 403 에러', err);
};
const handler404 = (err?: any) => {
    console.error('HTTP STATUS 404 에러', err);
};
const handler500 = (err?: any) => {
    console.error('HTTP STATUS 500 에러', err);
};

// http status 200 error handler (Indoorplus api error 에서만 쓰임)
const defaultIndoorplusHandler = (err?: any) => {
    console.error('Indoorplus api 에러에서 정의되지 않은 에러 내용', err);
};

/* Indoorplus api default error handler */

const handler1101 = (err?: any) => {
    console.error('Indoorplus api 에러: 요청된 데이터가 이미 등록되어 있을 경우 발생 ', err);
};
const handler1102 = (err?: any) => {
    console.error('Indoorplus api 에러: 요청된 데이터가 존재하지 않을 경우 발생', err);
};
const handler1104 = (err?: any) => {
    console.error('Indoorplus api 에러: 요청된 데이터 유효성 검사, 데이터가 양식에 맞지 않아서 발생', err);
};
const handler1201 = (err?: any) => {
    console.error(
        'Indoorplus api 에러: 요청한 데이터가 실제 데이터의 규격이나 구조, 패턴에 일치하지 않을 경우 발생',
        err,
    );
};
const handler1301 = (err?: any) => {
    console.error('Indoorplus api 에러: 요청된 데이터에 필수로 입력해야 할 값이 비어있을 때 발생', err);
};
const handler4011 = (err?: any) => {
    console.error('Indoorplus api 에러: 로그인 실패. 로그인 정보가 없을 경우 발생', err);
};
const handler4012 = (err?: any) => {
    console.error('Indoorplus api 에러: 요청된 token이 인증되지 않았을 경우 발생', err);
};
const handler5011 = (err?: any) => {
    console.error('Indoorplus api 에러: 서버에서 스캐너 배포를 진행하는 중에 문제가 발생함', err);
};
const handler5101 = (err?: any) => {
    console.error('Indoorplus api 에러: HTTP header에 필요한 값이 없을 경우 발생', err);
};
const handler6011 = (err?: any) => {
    console.error('Indoorplus api 에러: Open API 중 업체 인증으로는 작업을 수행할 수 없는 경우 발생', err);
};

/* OAuth api  default error handler */

const handlerInvalidRequest = (err?: any) => {
    console.error('OAuth api 에러: 요청시 데이터가 누락되어 요청한 경우', err);
};
const handlerInvalidGrant = (err?: any) => {
    console.error(
        'OAuth api 에러: \n잘못된 authorization_code를 사용한 경우\n 잘못되거나 만료된 refresh_token 을 요청한 경우\nrefresh_token 사용횟수를 오버한 경우 (default :300)',
        err,
    );
};
const handlerUnsupportedResponseType = (err?: any) => {
    console.error('OAuth api 에러: response_type 값이 누락되거나 "code"가 아닐 경우', err);
};
const handlerUnsupportedGrantType = (err?: any) => {
    console.error('OAuth api 에러: grant_type 값이 누락되는 경우', err);
};

const handlerInvalidScope = (err?: any) => {
    console.error('OAuth api 에러: scope가 누락되거나, 잘못된 형식인 경우', err);
};
const handlerInvalidClient = (err?: any) => {
    console.error(
        'OAuth api 에러: \nclient_id/ client_secret 값을 누락한 경우\n잘못된 client_id, client_secret로 요청한 경우',
        err,
    );
};

/**
 * 에러 핸들러 기본값
 *
 * @author kunwoong-kim
 */
const DEFAULT_ERROR_HANDLERS: ApiError.DefaultHandler = {
    [DEFAULT_ERRORS.COMMON]: commonHandler,
    [DEFAULT_ERRORS.DEFAULT]: defaultHandler,
    [HTTP_ERRORS.BAD_REQUEST]: handler400,
    [HTTP_ERRORS.UNAUTHORIZED]: handler401,
    [HTTP_ERRORS.FORBIDDEN]: handler403,
    [HTTP_ERRORS.NOT_FOUND]: handler404,
    [HTTP_ERRORS.INTERNAL_SERVER_ERROR]: handler500,
    [INDOORPLUS_ERROR_HANDLE_KEY]: defaultIndoorplusHandler,
    [INDOORPLUS_ERRORS.EXISTED_RESOURCE]: handler1101,
    [INDOORPLUS_ERRORS.NOT_EXISTED_RESOURCE]: handler1102,
    [INDOORPLUS_ERRORS.NOT_JSON_STRING_TYPE]: handler1104,
    [INDOORPLUS_ERRORS.NOT_MATCHED]: handler1201,
    [INDOORPLUS_ERRORS.MUST_BE_ENTERED]: handler1301,
    [INDOORPLUS_ERRORS.LOGIN_FAIL]: handler4011,
    [INDOORPLUS_ERRORS.UNAUTHORIZED_TOKEN]: handler4012,
    [INDOORPLUS_ERRORS.DEPLOY_FAIL]: handler5011,
    [INDOORPLUS_ERRORS.NOT_EXISTED_HEADER]: handler5101,
    [INDOORPLUS_ERRORS.FUNCTION_FAIL]: handler6011,
    [OAUTH_ERRORS.INVALID_REQUEST]: handlerInvalidRequest,
    [OAUTH_ERRORS.INVALID_GRANT]: handlerInvalidGrant,
    [OAUTH_ERRORS.UNSUPPORTED_RESPONSE_TYPE]: handlerUnsupportedResponseType,
    [OAUTH_ERRORS.UNSUPPORTED_GRANT_TYPE]: handlerUnsupportedGrantType,
    [OAUTH_ERRORS.INVALID_SCOPE]: handlerInvalidScope,
    [OAUTH_ERRORS.INVALID_CLIENT]: handlerInvalidClient,
};

/* indoorplus api error handler custom type guard*/
// function isIndoorplusErrorHandler(handler: any): handler is ApiError.IndoorplusErrorHandlerType {
//     return !!(handler as ApiError.IndoorplusErrorHandlerType);
// }

/* OAuth api error handler custom type guard */
// function isOAuthErrorHandler(handler: any): handler is ApiError.IndoorplusErrorHandlerType {
//     return !!(handler as ApiError.OAuthErrorHandlerType);
// }

const useApiError: ApiError.ApiErrorHook = (
    handlers?: ApiError.OAuthErrorHandlerType | ApiError.IndoorplusErrorHandlerType | Function,
) => {
    const handleError = useCallback(
        (responseError: ApiError.ResponseData) => {
            /****************** typescript type guard 사용법 예제 *********************/
            // let mergeHandler;
            // if (isIndoorplusErrorHandler(handlers)) {
            //     mergeHandler = { ...DEFAULT_ERROR_HANDLERS, ...handlers };
            // }
            // if (isOAuthErrorHandler(handlers)) {
            //     mergeHandler = { ...DEFAULT_ERROR_HANDLERS, ...handlers };
            // }

            // 모든 타입의 에러를 단일 핸들러로 처리하고자 하는 경우
            if (typeof handlers === 'function') {
                handlers(responseError);
                return;
            }
            const httpStatus = responseError.status.toString();
            const serviceErrorCode = responseError.code;
            // 우선순위에 따른 핸들러 선택 후 실행
            switch (true) {
                // @ts-ignore
                case !!handlers && !!handlers[serviceErrorCode]:
                    // @ts-ignore
                    // 우선순위 1. Component에서 (서비스 표준 에러 code) Key로 정의한 Handler
                    handlers[serviceErrorCode](responseError);
                    break;
                // @ts-ignore
                case !!handlers && !!handlers[httpStatus]:
                    // @ts-ignore
                    // 우선순위 2. Component에서 (HTTP Status) Key로 정의한 Handler
                    handlers[httpStatus](responseError);
                    break;
                // @ts-ignore
                case !!DEFAULT_ERROR_HANDLERS[serviceErrorCode]:
                    // @ts-ignore
                    // 우선순위 3. Hook에서 (서비스 표준 에러 Code) Key로 정의한 Handler
                    DEFAULT_ERROR_HANDLERS[serviceErrorCode](responseError);
                    break;
                // @ts-ignore
                case !!DEFAULT_ERROR_HANDLERS[httpStatus]:
                    // @ts-ignore
                    // 우선순위 4. Hook에서 (HTTP Status) Key로 정의한 Handler
                    DEFAULT_ERROR_HANDLERS[httpStatus](responseError);
                    break;
                default:
                    // @ts-ignore
                    // 5. 어디에서도 정의되지 못한 에러를 처리하는 핸들러
                    DEFAULT_ERROR_HANDLERS.default(responseError);
            }
            // @ts-ignore
            // 항상 실행되는 에러 핸들러
            if (handlers[DEFAULT_ERRORS.COMMON]) {
                // @ts-ignore
                handlers[DEFAULT_ERRORS.COMMON]();
            }
        },
        [handlers],
    );

    return { handleError };
};

export default useApiError;
