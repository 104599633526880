import React from 'react';

const BATTERY_ICON = {
    UNKNOWN: 'battery_unknown',
    FULL: 'battery_full',
    VALUE_90: 'battery_6_bar',
    VALUE_75: 'battery_5_bar',
    VALUE_60: 'battery_4_bar',
    VALUE_45: 'battery_3_bar',
    VALUE_30: 'battery_2_bar',
    VALUE_15: 'battery_1_bar',
    EMPTY: 'battery_0_bar',
};

const Battery = ({ value, unit }) => {
    const batteryIcon = (() => {
        if (!value) {
            return BATTERY_ICON.UNKNOWN;
        }
        if (value >= 90) {
            return BATTERY_ICON.FULL;
        } else if (value >= 75) {
            return BATTERY_ICON.VALUE_90;
        } else if (value >= 60) {
            return BATTERY_ICON.VALUE_75;
        } else if (value >= 45) {
            return BATTERY_ICON.VALUE_60;
        } else if (value >= 30) {
            return BATTERY_ICON.VALUE_45;
        } else if (value >= 15) {
            return BATTERY_ICON.VALUE_30;
        } else if (value >= 1) {
            return BATTERY_ICON.VALUE_15;
        } else {
            return BATTERY_ICON.EMPTY;
        }
    })();

    return (
        <div className="flx-row align-items-end gap-1">
            <span className="material-icons-round md-18">{batteryIcon}</span>
            <p className="pnt-txt s-10">
                {value ? Math.floor(value) : '-'}
                {unit}
            </p>
        </div>
    );
};

export default Battery;
