import { createContext, Dispatch, useContext } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { ActionType } from '@components/ContextProvider';
import { Patient, PatientInfo, SensorItems } from './types';
import { Nullable, NullableNumber } from '@util/type/util';

const MAPPING_INIT_STATE: PatientInfo = {
    subjectNum: null,
    sensorInfo: {
        deviceNum: null,
        sensorName: '',
        battery: null,
    },
    sensorItems: {
        minControlLimit: {
            sensingValues: {
                TEMPERATURE: 0,
                SPO2: 0,
                HEARTBEAT: 0,
            },
        },
        maxControlLimit: { sensingValues: { TEMPERATURE: 0, SPO2: 0, HEARTBEAT: 0 } },
        minRangeLimit: { sensingValues: { TEMPERATURE: 0, SPO2: 0, HEARTBEAT: 0 } },
        maxRangeLimit: { sensingValues: { TEMPERATURE: 0, SPO2: 0, HEARTBEAT: 0 } },
        minRiskLimit: { sensingValues: { TEMPERATURE: 0, SPO2: 0, HEARTBEAT: 0 } },
        maxRiskLimit: { sensingValues: { TEMPERATURE: 0, SPO2: 0, HEARTBEAT: 0 } },
    },
};

export const initialState: {
    selectedSubject: Nullable<Patient>;
    mappingInfo: typeof MAPPING_INIT_STATE;
    selectedMappingSubjectNum: NullableNumber;
    selectedMappingSubjectStarted: Nullable<boolean>;
    mappingModalOpen: boolean;
} = {
    selectedSubject: null,
    mappingInfo: MAPPING_INIT_STATE,
    selectedMappingSubjectNum: null,
    selectedMappingSubjectStarted: null,
    mappingModalOpen: false,
};

export const ExamPatientsMonitoringStateContext = createContext(initialState);
export const ExamPatientsMonitoringDispatchContext = createContext<Dispatch<ActionType> | null>(null);

export const useExamPatientsMonitoringStateContext = () => useContext(ExamPatientsMonitoringStateContext);
export const useExamPatientsMonitoringDispatchContext = () => useContext(ExamPatientsMonitoringDispatchContext)!;

const examPatientsMonitoringSlice = createSlice({
    name: 'sh/examPatientsMonitoring',
    initialState,
    reducers: {
        setSelectedSubject: (state, action) => {
            state.selectedSubject = action.payload;
        },
        setSelectedMappingSubjectNum: (state, action) => {
            const { subjectNum, started } = action.payload;
            state.selectedMappingSubjectNum = subjectNum;
            state.selectedMappingSubjectStarted = started;
        },
        setResetSelectedMappingSubjectNum: (state, action) => {
            state.selectedMappingSubjectNum = null;
            state.selectedMappingSubjectStarted = null;
        },
        setInitMappingInfo: (state, action) => {
            state.mappingInfo = action.payload;
        },
        setMappingInfo: (state, action) => {
            const { labelKey, valueKey, value } = action.payload;
            state.mappingInfo = {
                ...state.mappingInfo,
                sensorItems: {
                    ...state.mappingInfo.sensorItems,
                    [labelKey]: {
                        sensingValues: {
                            ...state.mappingInfo.sensorItems[labelKey as keyof SensorItems].sensingValues,
                            [valueKey]: value,
                        },
                    },
                },
            };
        },
        setResetMappingInfo: (state, action) => {
            state.mappingInfo = MAPPING_INIT_STATE;
        },
        setToggleMappingModal: (state, action) => {
            state.mappingModalOpen = !state.mappingModalOpen;
        },
    },
});

export const {
    setSelectedSubject,
    setSelectedMappingSubjectNum,
    setResetSelectedMappingSubjectNum,
    setResetMappingInfo,
    setInitMappingInfo,
    setMappingInfo,
    setToggleMappingModal,
} = examPatientsMonitoringSlice.actions;

export default examPatientsMonitoringSlice;
