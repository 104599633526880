import React from 'react';
import { useTranslation } from '@hooks';
import moment from 'moment';
import FilterSearchGroup from '../../../../MainPages/Components/FilterSearchGroup';
import SearchGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import { SelectGroup } from '../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import { Button, DatePicker, SearchableSelect } from '@components';
import { ButtonGroup } from 'reactstrap';
import TIMES from '@util/times';

const OutpatientDatePicker = ({ startDate, endDate, handleStartDate, handleEndDate, departmentValues }) => {
    const t = useTranslation('OutpatientUnattendedReceivingStatus');
    const { departmentOptions, department, handleDepartmentChange } = departmentValues;

    const handleStartDateYesterdayButtonClick = () => {
        handleStartDate(startDate - TIMES.DAY_1_PER_SEC);
    };
    const handleEndDateYesterdayButtonClick = () => {
        handleEndDate(endDate - TIMES.DAY_1_PER_SEC);
    };
    const handleStartDateTomorrowButtonClick = () => {
        handleStartDate(startDate + TIMES.DAY_1_PER_SEC);
    };
    const handleEndDateTomorrowButtonClick = () => {
        handleEndDate(endDate + TIMES.DAY_1_PER_SEC);
    };
    const handleTodayButtonClick = () => {
        handleStartDate(moment().startOf('day').unix());
        handleEndDate(moment().startOf('day').add(23, 'hours').add(59, 'm').add(59, 'second').unix());
    };

    const handleStartChange = selected => {
        handleStartDate(selected);
    };
    const handleEndChange = selected => {
        handleEndDate(selected);
    };

    return (
        <FilterSearchGroup className={'p-0'}>
            <SearchGroup>
                <div className={'text-center'}>{t('Department')}</div>
                <SearchableSelect selected={department} onChange={handleDepartmentChange} data={departmentOptions} />
                <div className={'text-center'}>{t('Receiving Date')}</div>
                <SelectGroup className={'w-10'}>
                    <div className="datePicker-container">
                        <DatePicker
                            key={`dp_start_${startDate}_${endDate}`}
                            value={startDate}
                            handleChange={handleStartChange}
                            valueType="s"
                            maxDate={moment.unix(endDate).valueOf()}
                            showTimeInput={false}
                            withoutTime
                        />
                    </div>
                </SelectGroup>
                <ButtonGroup>
                    <Button className="btn-gray mr-2 min-w-none" onClick={handleStartDateYesterdayButtonClick}>
                        {'<'}
                    </Button>
                    <Button
                        className="btn-gray mr-2 min-w-none"
                        onClick={handleStartDateTomorrowButtonClick}
                        disabled={moment.unix(startDate).diff(moment.unix(endDate), 'days') >= 0}
                    >
                        {'>'}
                    </Button>
                </ButtonGroup>
                <div className="text-center">~</div>
                <SelectGroup className={'w-10'}>
                    <div className="datePicker-container">
                        <DatePicker
                            key={`dp_end_${startDate}_${endDate}`}
                            value={endDate}
                            valueType="s"
                            minDate={moment.unix(startDate).valueOf()}
                            maxDate={moment().valueOf()}
                            readOnly={!startDate}
                            handleChange={handleEndChange}
                            showTimeInput={false}
                            withoutTime
                        />
                    </div>
                </SelectGroup>
                <ButtonGroup>
                    <Button
                        className="btn-gray mr-2 min-w-none"
                        disabled={moment.unix(endDate).diff(moment.unix(startDate), 'days') <= 0}
                        onClick={handleEndDateYesterdayButtonClick}
                    >
                        {'<'}
                    </Button>
                    <Button
                        className="btn-gray mr-2 min-w-none"
                        disabled={
                            endDate === moment().startOf('day').add(23, 'hours').add(59, 'm').add(59, 'second').unix()
                        }
                        onClick={handleEndDateTomorrowButtonClick}
                    >
                        {'>'}
                    </Button>
                    <Button className="btn-secondary mr-2" onClick={handleTodayButtonClick}>
                        {t('Today')}
                    </Button>
                </ButtonGroup>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default OutpatientDatePicker;
