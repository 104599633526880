import React from 'react';
import { AssetManual, deleteAssetManualApi } from '@api/am/assetManual';
import Button from '@components/Button';
import useAsync from '@hooks/useAsync';
import { useConfirmModal, useTranslation } from '@hooks';
import { reqGet } from '@api/index';
import { base64ToBlob } from '@util/common/util';
import { refreshManualList, useAssetManualManagementDispatchContext } from '../slice';

export const FileNameCell = ({ value, row: { original } }: { value: string; row: { original: AssetManual } }) => {
    const t = useTranslation('AssetManualManagement');
    const {
        promise: downloadPDF,
        state: { isLoading },
    } = useAsync({
        promise: () => reqGet(original.fileDownloadUrl),
        resolve: async response => {
            try {
                const blob = await base64ToBlob(response);
                const blobURL = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = blobURL;
                a.style.display = 'none';
                if (value) {
                    a.download = value;
                }
                document.body.appendChild(a);
                a.click();
                a.remove();
            } catch (err) {
                toggleDownloadFailureModal();
            }
        },
        reject: () => {
            toggleDownloadFailureModal();
        },
    });

    const { Modal: DownloadFailureModal, toggleModal: toggleDownloadFailureModal } = useConfirmModal({
        initModal: false,
        confirmText: t('The delete failed, please try again in a moment'),
        removeCancel: true,
    });

    return (
        <>
            <Button
                disabled={isLoading}
                className={'btn-trans text-decoration-underline'}
                onClick={e => {
                    e.stopPropagation();
                    downloadPDF();
                }}
            >
                {isLoading ? t('Downloading...') : value}
            </Button>
            <DownloadFailureModal />
        </>
    );
};

export const DeleteActionCell = ({ value }: { value: number }) => {
    const t = useTranslation('AssetManualManagement');
    const dispatch = useAssetManualManagementDispatchContext()!;

    const { promise: deleteAssetManual } = useAsync({
        promise: deleteAssetManualApi,
        fixedParam: { manualNum: value },
        resolve: () => {
            dispatch(refreshManualList());
        },
        reject: () => {
            toggleDeleteFailureModal();
        },
    });
    const { Modal: DeleteConfirmModal, toggleModal: toggleDeleteConfirmModal } = useConfirmModal({
        initModal: false,
        confirmText: t('The manual file is deleted. Are you sure you want to delete it?'),
        okCallback: () => {
            deleteAssetManual();
        },
    });

    const { Modal: DeleteFailureModal, toggleModal: toggleDeleteFailureModal } = useConfirmModal({
        initModal: false,
        confirmText: t('The delete failed, please try again in a moment'),
        removeCancel: true,
    });

    return (
        <>
            <Button
                className={'btn-trans btn-icon-only'}
                iconClassName={'material-icons-outlined'}
                iconName={'delete'}
                onClick={e => {
                    e.stopPropagation();
                    toggleDeleteConfirmModal();
                }}
            />
            <DeleteConfirmModal />
            <DeleteFailureModal />
        </>
    );
};
