import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cx from 'classnames';
import styled from 'styled-components';
import { useTranslation } from '@hooks';
import Button from '../Button';
import { useDispatch } from 'react-redux';
import { setSelectedNotificationLogInfo } from '@reducer/Notification';

type AlarmInfo = {
    level: string;
    type: string;
    typeClassName: string;
    dateStr: string;
    targetName: string;
    location: string;
    targetNum: number;
    interfaceCommandType: string;
    latLng: [number, number];
    floorId: string;
    timeStr?: string;
};

interface AlarmInfoProps {
    event: AlarmInfo;
}

interface TimeLineProps {
    events: AlarmInfo[];
    onClick?: (event: AlarmInfo) => void;
    ItemComponent?: (itemProps: AlarmInfoProps) => JSX.Element;
    withDate?: boolean;
}

const WrappedAlertInfo = styled.div<{ clickable: boolean }>`
    display: flex;
    & > div {
        cursor: ${props => (props.clickable ? 'pointer' : 'default')};
    }
`;

const DefaultItemComponent = ({ event }: AlarmInfoProps) => {
    const t = useTranslation('TimeLine');
    const storeDispatch = useDispatch();

    const handleSeeLocationClick = () => {
        storeDispatch(setSelectedNotificationLogInfo(event));
    };

    return (
        <>
            <div className={'alarm-list'}>
                <b className={event.typeClassName}>{event.type}</b>
                <h4 className="timeline-title mr-2" style={{ display: 'inline-block' }}>
                    {event.targetName}
                </h4>

                {event.location && (
                    <p>
                        {t('Location')} : {event.location}
                    </p>
                )}
            </div>
            <div className={'alarm-tracing-button'}>
                <Button className="btn-secondary" onClick={handleSeeLocationClick}>
                    {t('Location')}
                </Button>
            </div>
        </>
    );
};

const TimeLine = ({ events = [], onClick, ItemComponent, withDate = true }: TimeLineProps) => {
    const t = useTranslation('TimeLine');
    return events.length ? (
        <PerfectScrollbar>
            <VerticalTimeline layout="1-column">
                {events.map((event, i) => (
                    <VerticalTimelineElement
                        key={i}
                        style={{
                            cursor: 'default',
                        }}
                        className="vertical-timeline-item"
                        icon={
                            <i
                                className={cx(
                                    'badge badge-dot badge-dot-xl',
                                    event.level ? `badge-${[event.level]}` : 'badge-primary',
                                )}
                            >
                                {' '}
                            </i>
                        }
                        date={withDate ? event.dateStr : event.timeStr}
                    >
                        <WrappedAlertInfo
                            clickable={typeof onClick === 'function'}
                            onClick={() => {
                                if (typeof onClick === 'function') {
                                    onClick(event);
                                }
                            }}
                        >
                            {ItemComponent ? <ItemComponent event={event} /> : <DefaultItemComponent event={event} />}
                        </WrappedAlertInfo>
                    </VerticalTimelineElement>
                ))}
            </VerticalTimeline>
        </PerfectScrollbar>
    ) : (
        <div
            style={{
                color: '#666',
                textAlign: 'center',
                paddingTop: '1.5rem',
            }}
        >
            {t('No new notifications')}
        </div>
    );
};

export default TimeLine;
