import { BoxPlotChart, Button } from '@components';
import InnerItemCard from '@components/Card/InnerItemCard';
import { useTranslation } from '@hooks';
import React from 'react';
import styled from 'styled-components';
import { OUTPATIENT_REPORT, OUTPATIENT_WAIT_CHART_TITLE } from '../../constants';

const Chart = ({ data, onDownloadClick }) => {
    const t = useTranslation(OUTPATIENT_REPORT);

    const boxPlotData = {
        labels: [''],
        datasets: data.map(({ departmentName, wait }) => ({
            label: departmentName,
            data: [wait],
        })),
    };

    return (
        <InnerItemCard
            className={'m-2 h-90'}
            bodyClassName={'h-100'}
            header={{ title: t(OUTPATIENT_WAIT_CHART_TITLE) }}
        >
            <div className={'h-90 flx-row position-relative'}>
                <div className={'w-100 h-100 '}>
                    <BoxPlotChart
                        data={boxPlotData}
                        options={{
                            scales: {
                                y: {
                                    beginAtZero: true,
                                    suggestedMax: 10,
                                },
                            },
                        }}
                    />
                </div>
                <DownloadButton className={'btn-secondary'} onClick={onDownloadClick}>
                    {t('Download')}
                </DownloadButton>
            </div>
        </InnerItemCard>
    );
};

const DownloadButton = styled(Button)`
    position: absolute;
    top: 0;
    right: 0;
`;

export default Chart;
