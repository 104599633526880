import React from 'react';
import Table from '@components/Table';
import { useAssetManualManagementColumns } from '../columns';
import { useAppSelector, useAsync } from '@hooks';
import { getAssetManualListSampleApi } from '../sampleData';
import { getAssetManualListApi } from '@api/am/assetManual';
import { isMonitoring } from '@reducer/ScreenInfo';
import {
    setManualCategoryList,
    useAssetManualManagementDispatchContext,
    useAssetManualManagementStateContext,
} from '../slice';

const ManualTable = () => {
    const columns = useAssetManualManagementColumns();
    const mode = useAppSelector(state => state.ScreenInfo.mode);
    const dispatch = useAssetManualManagementDispatchContext()!;
    const { refreshFlag } = useAssetManualManagementStateContext();

    const {
        state: { response },
    } = useAsync({
        promise: isMonitoring(mode) ? getAssetManualListApi : getAssetManualListSampleApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        keepState: true,
        deps: [refreshFlag],
        resolve: response => {
            if (response.rows) {
                dispatch(setManualCategoryList(response.rows.map(manual => manual.categoryCode)));
            }
        },
    });

    return <Table columns={columns} data={{ rows: [], ...response }} paging={false} dynamicRowHeight />;
};

export default ManualTable;
