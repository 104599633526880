import { getOutpatientWaitDept, postOutpatientWaitDeptExcel } from '@api/so/outpatient';
import { useAsync, useTranslation } from '@hooks';
import React, { useRef } from 'react';
import InsightBox, { BoxBodyText, HighlightedText } from '../Components/InsightBox';
import SearchGroupSO from '../Components/SearchGroupSO';
import WidgetCard from '../../Components/WidgetCard';
import { useAsyncExcel } from '../../util/useAsyncExcel';
import useRangeDateSO from '../util/useRangeDateSO';
import Chart from './Chart';
import { Trans } from 'react-i18next';
import { isValidData } from '../util/checkData';
import NoDataBlock from '../../Components/NoDataBlock';
import moment from 'moment';

const OutpatientWaitAnalysisByDepartment = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();
    const t = useTranslation('OutpatientReport');

    const dateValues = useRangeDateSO();

    const { state, promise: getData } = useAsync({
        promise: getOutpatientWaitDept,
        immediate: true,
        param: {
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
        },
        reject: err => console.error(err),
    });
    const data = state.response ? state.response.rows : [];
    const { insightWaitAvg = 0, insightMaxDepartmentName = '', insightMaxWaitAvg = 0 } = state.response
        ? state.response.additionalDatas
        : {};

    const triggerDownload = useAsyncExcel({
        promise: postOutpatientWaitDeptExcel,
        param: {
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
            columnMetas: [
                {
                    key: 'departmentName',
                    name: t('Department name'),
                },
                {
                    key: 'departmentCode',
                    name: t('Department code'),
                },
                {
                    key: 'waitMax',
                    name: t('Max'),
                },
                {
                    key: 'waitAvg',
                    name: t('Avg'),
                },
                {
                    key: 'waitMin',
                    name: t('Min'),
                },
            ],
            fileName:
                'so_kioskData_' +
                moment.unix(dateValues.startDate).format('YYYYMMDD') +
                '_' +
                moment.unix(dateValues.endDate).format('YYYYMMDD'),
            zipFileName: 'so_wait_by_department',
        },
        reject: err => console.error(err),
    });

    const handleDownloadClick = () => {
        triggerDownload();
    };

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <SearchGroupSO dateValues={dateValues} getData={getData} />
            {isValidData(data) ? (
                <>
                    <InsightBox>
                        <BoxBodyText>
                            <Trans
                                t={t}
                                values={{ insightWaitAvg, insightMaxDepartmentName, insightMaxWaitAvg }}
                                components={[<HighlightedText />]}
                            >
                                {`The overall outpatient waiting time during the period you viewed averaged around <0>{{insightWaitAvg}} minutes</0>, and by department, the <0>{{insightMaxDepartmentName}}</0> had the longest waiting time, <0>averaging over {{insightMaxWaitAvg}} minutes</0>.`}
                            </Trans>
                        </BoxBodyText>
                    </InsightBox>
                    <Chart data={data} onDownloadClick={handleDownloadClick} />
                </>
            ) : (
                <NoDataBlock text={t('No data')} />
            )}
            {children}
        </WidgetCard>
    );
};

export default OutpatientWaitAnalysisByDepartment;
