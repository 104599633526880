import React from 'react';
import { ConfirmModal } from '@components';
import useTranslation from '@hooks/useTranslation';

interface Props {
    initModal?: boolean;
    toggleModal?: () => void;
}

const WidgetSettingValidationModal = ({ initModal, toggleModal }: Props) => {
    const t = useTranslation('Widget');

    return (
        <ConfirmModal
            initModal={initModal}
            toggleModal={toggleModal}
            header={{ title: t('Validation Fail') }}
            confirmText={t("Please enter a widget's name")}
            removeCancel
        />
    );
};

export default WidgetSettingValidationModal;
