import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';

interface VersionLabelProps {
    className?: string;
}

const VersionLabel = ({ className }: VersionLabelProps) => {
    return <VersionText className={cx(className)}>v{process.env.REACT_APP_VERSION}</VersionText>;
};

const VersionText = styled.span`
    &.position-lb {
        position: absolute;
        bottom: 0;
        right: 0;
    }
    letter-spacing: normal;
`;

export default VersionLabel;
