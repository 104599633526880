import React, { useEffect } from 'react';
import { useAppSelector, useColumns, useTranslation } from '@hooks';
import useMonitor from '../../../../util/useMonitor';
import { fetchRealTimeSensorMonitoring } from '@api/monitoring';
import { useSensorStatusContext, useSensorStatusDispatch } from '../../index';
import { setReportType, setSelectedSensor, setSensorList, setSensorListParam } from '../../sensorStatusSlice';
import * as column from '../../../../../MainPages/util/grid/column';
import { useSelector } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import BookmarkIcon from './Components/BookmarkIcon';
import Search from './Components/Search';
import defaultData from './defaultData.json';
import useFilter from '@hooks/useFilter';
import { Table } from '@components';
import InnerItemCard from '@components/Card/InnerItemCard';
import cx from 'classnames';

// 센서 목록
const SensorList = ({ config }) => {
    const t = useTranslation('SensorStatus');
    const commonColumnT = useTranslation('CommonColumn');
    const { mode } = useSelector(state => state.ScreenInfo);
    const { sensorListParam, sensorList, sensingTypeOptions } = useSensorStatusContext();
    const dispatch = useSensorStatusDispatch();
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);
    const { floorList } = useSelector(state => state.FloorInfo);

    const columns = useColumns(
        [
            column.targetName({ width: 80 }),
            column.targetId({
                Header: 'Target Id',
                width: 50,
            }),
            column.sensorMacAddress({
                width: 80,
            }),
            column.floor({
                width: 60,
                className: 'd-flex flx-center',
                Cell: ({ row: { original } }) => {
                    const { floorId } = original;
                    const floorName = floorList.find(e => e.floorId === floorId)?.floorName;
                    return <span>{floorName || ''}</span>;
                },
            }),
            column.registeredDate({
                width: 80,
            }),
            column.sensorItems({
                width: 200,
                Cell: ({ value, row: { original } }) => {
                    if (value && value.length && sensingTypeOptions.length) {
                        return (
                            <div className={'d-flex align-items-center flex-wrap'}>
                                <div
                                    className={cx(
                                        'pnt-txt txt-border txt-bold mr-1 item_count mb-1',
                                        colorScheme === 'pnt-navy' ? 'bg-depth-7' : 'bg-depth-base',
                                    )}
                                >
                                    {value?.length}
                                </div>
                                {value.map((sensorItem, index) => {
                                    const sensorType = sensingTypeOptions.find(
                                        sensingType => sensingType.sensingType === sensorItem.sensingType,
                                    );

                                    if (sensorType) {
                                        const {
                                            currentValue,
                                            measurementUnit,
                                            status,
                                            minRangeLimit,
                                            minRiskLimit,
                                            minControlLimit,
                                            maxControlLimit,
                                            maxRiskLimit,
                                            maxRangeLimit,
                                        } = sensorItem;
                                        const { sensingType, language: sensingName } = sensorType;
                                        const sensingTypeArr = [
                                            { key: 'currentValue', value: currentValue },
                                            { key: 'measurementUnit', value: measurementUnit },
                                            { key: 'status', value: status },
                                            { key: 'minRangeLimit', value: minRangeLimit },
                                            { key: 'minRiskLimit', value: minRiskLimit },
                                            { key: 'minControlLimit', value: minControlLimit },
                                            { key: 'maxControlLimit', value: maxControlLimit },
                                            { key: 'maxRiskLimit', value: maxRiskLimit },
                                            { key: 'maxRangeLimit', value: maxRangeLimit },
                                        ];
                                        return (
                                            <div
                                                id={sensingType + index + original.targetNum}
                                                key={sensingType + index + original.targetNum}
                                                className={cx(
                                                    'item_badge bg-l-1 mb-1',
                                                    colorScheme === 'pnt-navy' ? 'bg-depth-7' : 'bg-depth-base',
                                                )}
                                            >
                                                <span
                                                    className={cx(
                                                        'material-icons-round',
                                                        colorScheme === 'pnt-dark' && 'color-secondary',
                                                    )}
                                                >
                                                    sensors
                                                </span>
                                                <div
                                                    className={cx(
                                                        'pnt-txt txt-bold',
                                                        colorScheme === 'pnt-dark' && 'color-secondary',
                                                    )}
                                                >
                                                    {sensingName ? sensingName : '-'}
                                                </div>
                                                {!!sensingType && (
                                                    <UncontrolledTooltip
                                                        hideArrow
                                                        placement="auto"
                                                        target={sensingType + index + original.targetNum}
                                                    >
                                                        {sensingTypeArr.map(({ key, value }) => (
                                                            <div key={key}>{`${t(key)}: ${value ? value : '-'}`}</div>
                                                        ))}
                                                    </UncontrolledTooltip>
                                                )}
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        );
                    } else {
                        return <span>-</span>;
                    }
                },
            }),
            column.bookmark({
                width: 25,
                className: 'flx-center d-flex ',
                Cell: BookmarkIcon,
            }),
        ],
        commonColumnT,
        [sensingTypeOptions, colorScheme],
    );

    const mergedParam = useFilter({ preParam: sensorListParam });
    const { monitor: sensorListMonitor, stop: sensorListMonitorStop } = useMonitor({
        config,
        defaultData,
        dynamicParam: mergedParam,
        fetchData: fetchRealTimeSensorMonitoring,
        makeData: ({ newData }) => {
            if (newData.rows) {
                dispatch(setSensorList(newData));
            }
        },
    });

    useEffect(() => {
        sensorListMonitorStop();
        if (sensorListParam.pageSize && mode === 'monitoring') {
            sensorListMonitor();
        }
        return () => {
            sensorListMonitorStop();
        };
    }, [sensorListParam]);

    const handlePageChange = pageIndex => {
        dispatch(setSensorListParam({ page: pageIndex }));
    };

    const handleSensorClick = sensor => {
        dispatch(setSelectedSensor(sensor));
        dispatch(setReportType('daily'));
    };

    return (
        <InnerItemCard
            className={'h-100'}
            bodyClassName={'h-100'}
            header={{
                titleIcon: 'assignment',
                title: t('Sensor List'),
            }}
        >
            <Search />
            <div className={'h-90'}>
                <Table
                    data={{ ...sensorList, pageSize: 15 }}
                    columns={columns}
                    onTrClick={handleSensorClick}
                    onPageChange={handlePageChange}
                    dynamicRowHeight={true}
                />
            </div>
        </InnerItemCard>
    );
};

export default SensorList;
