import React, { useState } from 'react';
import VitalSignsCardBody from './VitalSignsCardBody';
import useTranslation from '@hooks/useTranslation';
import Bookmark from '../../../../Components/Bookmark';
import Modal from '@components/Modal';

/**
 * 병상, 환자 상태 카드 리스트 컴포넌트
 *
 * @param roomNum 병실 번호
 * @param bedNum 병상 번호
 * @param people 병상 환자 정보
 * @param handleRefreshData 환자별 활력징후 데이터 새로고침 handler
 *
 * @since 2023-02-07
 * @author kunwoong-kim
 * */
const VitalSignsCard = ({ roomNum, bedNum, people, handleRefreshData }) => {
    const t = useTranslation('VitalSignMonitoring');
    // bookmark error modal
    const [bookmarkErrorModal, setBookmarkErrorModal] = useState(false);
    const toggleBookmarkErrorModal = () => setBookmarkErrorModal(!bookmarkErrorModal);
    const bookmarkErrorModalOkCallback = () => handleRefreshData();
    // bookmark 호출 후 event handler
    const handleBookmarkSuccess = () => handleRefreshData();
    const handleBookmarkFailure = () => {
        setBookmarkErrorModal(true);
    };

    // 렌더링을 위한 Entry
    const peopleEntries = Object.entries(people);

    return peopleEntries.map(([person, personData], idx) => (
        <div key={`${bedNum}_${person}_${idx}`}>
            <div className="bg-depth-2 border-size-1 border-depth-7 transition-card">
                <div className="flx-row justify-content-between bg-depth-7 border-bottom border-depth-7">
                    {/* 병상 */}
                    <div className="pnt-txt txt-bold px-2">{`${roomNum} ${bedNum}`}</div>
                    {/* bookmark */}
                    <div className="px-1 border-start border-depth-8">
                        <Bookmark
                            className={'btn-trans btn-icon-only'}
                            successCallback={handleBookmarkSuccess}
                            failureCallback={handleBookmarkFailure}
                            targetNum={personData.targetNum}
                            isBookmark={personData.isBookmark}
                        />
                    </div>
                </div>
                <VitalSignsCardBody personData={personData} />
            </div>
            <Modal
                initModal={bookmarkErrorModal}
                toggleModal={toggleBookmarkErrorModal}
                headerTitle={t('Bookmark')}
                bodyText={t('Something has changed.', 'Modal')}
                removeCancel
                okCallback={bookmarkErrorModalOkCallback}
            />
        </div>
    ));
};

export default VitalSignsCard;
