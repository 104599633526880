import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '@hooks';
import useTranslation from '@hooks/useTranslation';
import { ASSET_MANAGEMENT } from '../../constants';
import useAsync from '@hooks/useAsync';
import { setQuantityCheckListApi } from '@api/am/management';
import { QuestionTooltip } from '@components';
import styled from 'styled-components';
import Button from '@components/Button';
import useModal from '../../util/useModal';
import Modal from '@components/Modal';
import moment from 'moment';
import { SCREEN_MODE_MONITORING } from '@reducer/ScreenInfo';
import { DATE_FORMAT } from '@util/times';
import { Trans } from 'react-i18next';
import TimeSelect from '../Components/TimeSelect';

const Quantity = ({
    data,
    handleRefresh,
    getChangedRows,
    isToday,
    targetDate,
    selectedDuty,
    workTimeSet,
    children,
}) => {
    const t = useTranslation(ASSET_MANAGEMENT);
    const { mode } = useAppSelector(state => state.ScreenInfo);

    const [isSubmitOpen, handleSubmitToggle, handleSubmitOpen] = useModal();
    const [isDateSettingOpen, handleDateSettingToggle, handleDateSettingOpen] = useModal();

    const [textareaValue, setTextareaValue] = useState();
    const handleTextareaValue = e => {
        setTextareaValue(e.target.value);
    };

    const formattedDate = moment.unix(targetDate).format(DATE_FORMAT.YMD);
    const checkDateRef = useRef();

    const { promise: setQuantityCheckList } = useAsync({
        promise: setQuantityCheckListApi,
        resolve: () => {
            handleRefresh();
            setTextareaValue(null);
        },
    });

    const handleSubmitCallback = () => {
        // 점검 변경 값
        const changedRows = getChangedRows();
        const now = moment().unix();

        Object.keys(changedRows).forEach(key => {
            // 오늘이 아니고 점검시간 선택 값이 있는 경우 판별하여 점검 시간 결정
            changedRows[key].checkDate = !isToday && checkDateRef.current ? checkDateRef.current : now;
        });

        // 메모 수정 여부
        if (data[0]?.memo !== textareaValue) {
            const assetCountCheck = data.map(({ categoryCode, ward, regDate, added }) => {
                const changedRowKey = categoryCode + ward;
                return {
                    categoryCode,
                    ward,
                    ...changedRows[changedRowKey],
                    added,
                    memo: textareaValue,
                    regDate,
                };
            });
            setQuantityCheckList({ workTimeType: selectedDuty, assetCountCheck });
            return;
        }

        const assetCountCheck = Object.values(changedRows);
        if (assetCountCheck.length === 0) {
            handleRefresh();
            return;
        }
        setQuantityCheckList({ workTimeType: selectedDuty, assetCountCheck });
    };

    const handleChangeCheckerDate = unixTime => {
        checkDateRef.current = unixTime;
    };

    const onClick = () => {
        if (isToday) {
            return handleSubmitOpen();
        }
        if (Object.keys(getChangedRows()).length <= 0) {
            return handleSubmitOpen();
        }
        return handleDateSettingOpen();
    };

    useEffect(() => {
        setTextareaValue(data[0]?.memo);
    }, [data]);

    return (
        <div className="d-flex flex-column h-100">
            <div className="d-flex align-center mt-2 mb-2">
                <div className="pnt-txt txt-bold s-5 mr-2">{t('Asset Quantity Check', 'Widget')}</div>
            </div>
            {children}
            <TextContainer
                className="border border-depth-7 bg-depth-3 p-2 mt-3"
                rows={3}
                maxLength={1000}
                placeholder={t('Notes (up to 1,000 characters)')}
                disabled={data.length === 0}
                value={textareaValue ?? ''}
                onChange={handleTextareaValue}
            />
            <span className={'pnt-txt s-9 mt-1'}>
                {t('* Notes are stored on a per-day basis, regardless of shift.')}
            </span>
            <div className="d-block mt-3">
                <Button
                    className="btn-secondary"
                    onClick={onClick}
                    disabled={mode !== SCREEN_MODE_MONITORING || data.length === 0}
                >
                    {t('Save', 'Button')}
                </Button>
            </div>
            <Modal
                initModal={isSubmitOpen}
                toggleModal={handleSubmitToggle}
                okCallback={handleSubmitCallback}
                headerTitle={t('Confirm submit')}
            >
                <div className={'modal-body'}>
                    <div>{t('Do you want to save?')}</div>
                </div>
            </Modal>
            {!isToday && (
                <Modal
                    initModal={isDateSettingOpen}
                    toggleModal={handleDateSettingToggle}
                    okCallback={handleSubmitCallback}
                    headerTitle={t('Inspection time setting')}
                >
                    <div className="modal-body">
                        <div className="text-center pnt-txt s-7">
                            <Trans
                                t={t}
                                values={{ selectedDuty: selectedDuty, formattedDate: formattedDate }}
                                components={[<strong className={'color-danger'}></strong>]}
                            >
                                {`The result of the <0>{{selectedDuty}}</0> assetCount check on <0>{{formattedDate}}</0> is saved as the time below.`}
                            </Trans>
                        </div>
                        <div className="text-center pnt-txt s-7">
                            {t(
                                'If you modify the scorecard individually, only the results for that asset will change.',
                            )}
                        </div>
                        <TimeSelect
                            className="mt-3"
                            targetDate={targetDate}
                            handleTimeChange={handleChangeCheckerDate}
                        />
                    </div>
                </Modal>
            )}
        </div>
    );
};

const TextContainer = styled.textarea`
    resize: none;
`;

export default Quantity;
