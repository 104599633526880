import { reqGet } from '@api/index';
import { RequestHandlerCreator } from '@api/util';
import { UnixTimestamp, YN } from '@util/type/util';

export interface CompanyInfo {
    comBizNum: string;
    comName: string;
    comNum: number;
    lat: number;
    lng: number;

    // 'C': Circle, 'S': Square
    markerBorderRadius: 'C' | 'S';

    markerPulseActive: YN;

    // 'S': Slow, 'U': Usually, 'F': Fast
    markerPulseAnimation: 'S' | 'U' | 'F';

    markerPulseColor: string;
    markerPulseRssi: number;
    markerTransitionActive: YN;
    regDate: UnixTimestamp;
    ssoActive: YN;
    ssoKey: string;
    timeZone: string;
    uuid: string;
}

export const fetchCompany: RequestHandlerCreator<CompanyInfo> = param => reqGet('/v3/api/common/company/info', param);
