import { createSlice } from '@reduxjs/toolkit';
import { convertState } from '@reducer/TagInfo/processSocketData';
import { isIn } from '@util/mappInfo';

const initGeofenceInfo = { name: null, count: 0, data: [] };

export const initialState = {
    selectedFloor: null,
    selectedTopFloor: null,
    selectedCategory: [],

    // 화면에 카운트를 보여줄 지오펜스 정보( 지오펜스 그룹 + 지오펜스 )
    geofenceDisplayList: [],
    // 지오펜스/그룹 매핑 정보 (geofence => geofenceGroup | geofence)
    fenceToDisplayFence: {},
    // 지오펜스별 타겟 매핑
    tagListByGeofence: {},
    // 현재 층에 있는 tag 정보
    realTimeLog: {},

    // 지오펜스 그룹 태그 갯수 선택
    selectedGeofenceGroupData: initGeofenceInfo,

    geofenceGroupDataPopup: false,
};

const { actions, reducer } = createSlice({
    name: 'realTimeLocationTracking',
    initialState,
    reducers: {
        // 최상위 층 선택 handler
        setSelectedTopFloor: (state, action) => {
            const { topFloor, floor } = action.payload;
            state.selectedTopFloor = topFloor;
            state.selectedFloor = floor;
        },
        // 하위 층 선택 handler
        setSelectedFloor: (state, action) => {
            state.selectedFloor = action.payload;
        },
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload;

            const selectedCategory = state.selectedCategory.map(({ value }) => value);
            const excludeTargetList = Object.entries(state.realTimeLog).reduce(
                (
                    acc,
                    [
                        targetNum,
                        {
                            targetCategory: { categoryCode },
                        },
                    ],
                ) => {
                    if (!selectedCategory.includes(categoryCode)) {
                        acc.push(Number(targetNum));

                        state.realTimeLog[targetNum] = null;
                        delete state.realTimeLog[targetNum];
                    }
                    return acc;
                },
                [],
            );

            for (let fcNum in state.tagListByGeofence) {
                if (state.tagListByGeofence.hasOwnProperty(fcNum)) {
                    const targetList = state.tagListByGeofence[fcNum];
                    state.tagListByGeofence[fcNum] = targetList.filter(
                        targetNum => !excludeTargetList.includes(targetNum),
                    );
                }
            }
        },
        // 선택 층 지오펜스 정보
        setGeofenceList: (state, action) => {
            const { geofenceList, geofenceGroupList, floorId } = action.payload;
            const filteredGeofenceGroupList = geofenceGroupList.filter(
                geofenceGroup => geofenceGroup.floorId === floorId,
            );
            const filteredGeofenceList = geofenceList.filter(
                geofence => geofence.floor === floorId && !geofence.fcGroupNum,
            );
            const displayList = [];
            filteredGeofenceGroupList.forEach(geofenceGroup => {
                displayList.push({
                    ...geofenceGroup,
                    fcNum: geofenceGroup.fcGroupNum,
                    fcName: geofenceGroup.fcGroupName,
                });
            });
            filteredGeofenceList.forEach(geofence => {
                displayList.push(geofence);
            });
            state.geofenceDisplayList = displayList;
            state.tagListByGeofence = displayList.reduce((acc, fcInfo) => {
                acc[fcInfo.fcNum] = [];
                return acc;
            }, {});
            state.fenceToDisplayFence = geofenceList.reduce((acc, fcInfo) => {
                acc[fcInfo.fcNum] = fcInfo.fcGroupNum ? fcInfo.fcGroupNum : fcInfo.fcNum;
                return acc;
            }, {});
            state.realTimeLog = {};
        },
        updateRealTimeLog: (state, action) => {
            const dataList = action.payload;
            dataList.forEach(data => {
                const {
                    target: { targetNum, ...target },
                    targetState,
                    currentFloor,
                    targetCategory: { categoryCode },
                    latlng: { lat, lng },
                    geofences,
                } = data;

                state.realTimeLog[targetNum] = null;
                delete state.realTimeLog[targetNum];

                const tagListByGeofence = state.tagListByGeofence;
                for (let fcNum in tagListByGeofence) {
                    if (tagListByGeofence.hasOwnProperty(fcNum)) {
                        const tempSet = new Set(state.tagListByGeofence[fcNum]);
                        tempSet.delete(targetNum);
                        state.tagListByGeofence[fcNum] = [...tempSet];
                    }
                }

                const { lostSignal } = convertState(targetState);

                const targetData = {
                    ...data,
                    geofences,
                    target: { ...target, categoryCode, targetNum },
                    location: {
                        lat,
                        lng,
                        latLng: [lat, lng],
                    },
                    floorInOutState: {
                        floorId: currentFloor.floorId,
                        floorName: currentFloor.floorName,
                        isIn: isIn(currentFloor.inOutState),
                        inTime: currentFloor.inUnixTime,
                        stayTime: currentFloor.stayUnixTime,
                        outTime: currentFloor.outUnixTime,
                    },
                };

                // 선택 층 정보
                const selectedFloorId = state.selectedFloor
                    ? state.selectedFloor.floorId
                    : state.selectedTopFloor?.floorId;
                // 선택 category 정보
                const selectedCategoryValues = state.selectedCategory.map(({ value }) => value);

                if (
                    !lostSignal &&
                    selectedFloorId === currentFloor.floorId &&
                    selectedCategoryValues.includes(categoryCode)
                ) {
                    state.realTimeLog[targetNum] = targetData;
                    const fenceToDisplayFence = state.fenceToDisplayFence;
                    geofences.forEach(({ fcNum, inOutState }) => {
                        if (isIn(inOutState)) {
                            const tempSet = new Set(state.tagListByGeofence[fenceToDisplayFence[fcNum]]);
                            tempSet.add(targetNum);
                            state.tagListByGeofence[fenceToDisplayFence[fcNum]] = [...tempSet];
                        }
                    });
                }
            });
        },
        toggleGeofenceTagListPopup: (state, action) => {
            const fcNum = action.payload;
            state.geofenceGroupDataPopup = !!fcNum;
            const fcInfo = state.geofenceDisplayList.find(geofence => geofence.fcNum === fcNum);
            if (fcInfo) {
                const tagList = state.tagListByGeofence[fcNum];
                state.selectedGeofenceGroupData = {
                    name: fcInfo.fcName,
                    count: tagList.length,
                    data: tagList.map(targetNum => state.realTimeLog[targetNum]),
                };
            } else {
                state.selectedGeofenceGroupData = initGeofenceInfo;
            }
        },
    },
});

export const {
    initGeofenceGroupList,
    setSelectedTopFloor,
    setSelectedFloor,
    setSelectedCategory,
    setGeofenceList,
    updateRealTimeLog,
    toggleGeofenceTagListPopup,
} = actions;
export default reducer;
