import React, { useCallback, useRef, useState } from 'react';
import { WidgetProps } from '../../staticInfo';
import WidgetCard from '../../Components/WidgetCard';
import { useAsync } from '@util/hooks';
import Filter from '../Components/Filter';
import RentalAvailabilityTable from './RentalAvailabilityTable';
import useSingleWardOptions from './hooks/useSingleWardOptions';
import { getRentalAvailabilityList } from '@api/am/management';

export interface Row {
    ward: number;
    count: number;
    rentalMaxCount: number;
    categoryCode: string;
    categoryName: string;
}

const RentalQuantity = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    const widgetRef = useRef(null);

    const wardValues = useSingleWardOptions();

    const [rows, setRows] = useState<Row[]>();
    const { state, promise: getList } = useAsync({
        promise: getRentalAvailabilityList,
        immediate: !!wardValues.value,
        deps: [wardValues.value],
        param: {
            ward: wardValues.value?.value,
        },
        resolve: ({ rows }) => {
            setRows(rows);
        },
        reject: err => console.error(err),
    });
    const responseRows = state.response?.rows;

    // 대여가능수량 cell change
    const handleCellChange = useCallback((rowIndex: number, columnId: string, value: string | number) => {
        setRows(prev =>
            prev?.map((row: Row, index: number) => {
                if (index === rowIndex) {
                    return {
                        ...prev[rowIndex],
                        [columnId]: value,
                    };
                }
                return row;
            }),
        );
    }, []);

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            ref={widgetRef}
            searchFilter={
                <Filter>
                    <Filter.Select options={wardValues} />
                </Filter>
            }
            {...restProps}
        >
            <RentalAvailabilityTable
                rows={rows ?? []}
                originRows={responseRows ?? []}
                onCellChange={handleCellChange}
                getList={getList}
            />
            {children}
        </WidgetCard>
    );
};

export default RentalQuantity;
