import { createSlice } from '@reduxjs/toolkit';
import { INDOORPLUS_ERRORS } from '@util/errorCode';

const defaultState = {
    error: [],
    licenseError: false,
};

const { actions, reducer } = createSlice({
    name: 'errorHandler',
    initialState: defaultState,
    reducers: {
        setError: (state, action) => {
            const { data } = action.payload;
            if (data.code === INDOORPLUS_ERRORS.LICENSE_VERIFICATION_FAIL) {
                state.licenseError = true;
            }

            if (!state.error.find(err => err.code === data.code && err.message === data.message)) {
                let message;
                if (data.errorResponse) {
                    message = data.message.substr(0, data.message.indexOf(' ('));
                } else {
                    message = data.message;
                }
                state.error.push({
                    ...data,
                    message: message,
                });
            }
        },
        deleteError: (state, action) => {
            const { index } = action.payload;
            state.error.splice(index, 1);
        },
        checkLicense: state => {
            state.licenseError = false;
        },
    },
});

export const { setError, deleteError, checkLicense } = actions;
export default reducer;
