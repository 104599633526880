import React, { useContext, useRef } from 'react';
import WidgetCard from '../../Components/WidgetCard';
import OutpatientCareItemContainer from './OutpatientCareItemContainer';
import ContextProvider from '@components/ContextProvider';
import outpatientCareStatusMonitoringSlice from './OutpatientCareStatusMonitoringSlice';

export const OutpatientCareStatusStateContext = React.createContext();
export const OutpatientCareStatusDispatchContext = React.createContext();
export const useOutpatientCareStatusContext = () => useContext(OutpatientCareStatusStateContext);
export const useOutpatientCareStatusDispatch = () => useContext(OutpatientCareStatusDispatchContext);

const OutpatientCareStatus = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();

    return (
        <ContextProvider
            StateContext={OutpatientCareStatusStateContext}
            DispatchContext={OutpatientCareStatusDispatchContext}
            slice={outpatientCareStatusMonitoringSlice}
        >
            <WidgetCard ref={widgetRef} widgetInfo={widgetInfo} {...restProps}>
                <OutpatientCareItemContainer />
                {children}
            </WidgetCard>
        </ContextProvider>
    );
};

export default OutpatientCareStatus;
