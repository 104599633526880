import React, { useState } from 'react';
import { WidgetProps } from '../../staticInfo';
import WidgetCard from '../../Components/WidgetCard';
import { Table } from '@components';
import { useAsync, useColumns, useTranslation } from '@hooks';
import * as col from './columns';
import moment from 'moment';
import { convertSecondsToMinutes } from './util';
import ExcelExport from './Components/ExcelExport';
import { HistoryData } from './types';
import { getTestTargetMonitoringHistoryApi } from '@api/sh/examPatientMonitoring';

const PAGE_SIZE = 20;

const ExamMonitoringHistory = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    const t = useTranslation('ExamMonitoringHistory');
    const [data, setData] = useState({ rows: [] });
    const columns = useColumns(
        [
            col.measurementDate({
                Cell: ({ row: { original } }: { row: { original: HistoryData } }) => {
                    const { startDate } = original;
                    return startDate ? <div>{moment.unix(startDate).format('YYYY-MM-DD')}</div> : '-';
                },
            }),
            col.subjectNum(),
            col.stabilizationRoom(),
            col.deviceName(),
            col.progressTime({
                Cell: ({ value }: { value: number }) => {
                    return (
                        <div>
                            {convertSecondsToMinutes(value)}
                            {t('Minutes')}
                        </div>
                    );
                },
            }),
            col.measurementTime({
                Cell: ({ row: { original } }: { row: { original: HistoryData } }) => {
                    const { startDate, endDate } = original;
                    return startDate || endDate ? (
                        <div className={'d-flex justify-content-center gap-1'}>
                            <span>{moment.unix(startDate).format('HH:mm:ss')}</span>
                            <span>~</span>
                            <span>{moment.unix(endDate).format('HH:mm:ss')}</span>
                        </div>
                    ) : (
                        '-'
                    );
                },
            }),
            col.detailDownload({
                Cell: ({ row: { original } }: { row: { original: HistoryData } }) => {
                    const { subjectNum, startDate, endDate } = original;

                    return startDate && endDate ? <ExcelExport subjectNum={subjectNum} /> : '-';
                },
            }),
        ],
        t,
    );

    const { promise } = useAsync({
        promise: getTestTargetMonitoringHistoryApi,
        fixedParam: {
            pageSize: PAGE_SIZE,
            endDate: moment().startOf('day').unix(),
        },
        resolve: res => {
            if (res) {
                setData(res);
            }
        },
        immediate: true,
        deps: [],
    });

    const handleChange = (page: number) => {
        promise({ page });
    };

    return (
        <WidgetCard widgetInfo={widgetInfo} {...restProps}>
            <Table columns={columns} data={{ ...data, pageSize: PAGE_SIZE }} onPageChange={handleChange} />
            {children}
        </WidgetCard>
    );
};

export default ExamMonitoringHistory;
