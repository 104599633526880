import React from 'react';
import { BarChart } from '@components';
import InnerItemCard from '@components/Card/InnerItemCard';
import { useTranslation } from '@util/hooks';

interface Row {
    month: number;
    talkCount: number;
    smsCount: number;
}
interface Props {
    data: Row[];
}

const MONTH_LIST = [
    'Jan.',
    'Feb.',
    'Mar.',
    'Apr.',
    'May',
    'Jun.',
    'Jul.',
    'Aug.',
    'Sep.',
    'Oct.',
    'Nov.',
    'Dec.',
] as const;

const barChartOptions = {
    scales: {
        x: {
            display: true,
        },
        y: {
            ticks: {
                callback: (val: string | number) => (Number.isInteger(val) ? val : null),
            },
        },
    },
};

const Chart = ({ data }: Props) => {
    const t = useTranslation('OutpatientStorage');

    const barData = {
        labels: MONTH_LIST.map(ele => t(ele)),
        datasets: [
            {
                label: t('Notification Talk'),
                data: data.map(ele => ele.talkCount),
            },
            {
                label: t('Message(SMS)'),
                data: data.map(ele => ele.smsCount),
            },
        ],
    };

    return (
        <InnerItemCard
            className={'m-2 h-90'}
            bodyClassName={'h-100'}
            header={{ title: t('Monthly Notification Talk/SMS Count') }}
        >
            <div className={'h-90 flx-row position-relative'}>
                <div className="w-100 h-100">
                    <BarChart data={barData} options={barChartOptions} />
                </div>
            </div>
        </InnerItemCard>
    );
};

export default Chart;
