import { NullableString, UnixTimestamp, Nullable, ServiceCode, NullableUnixTimestamp } from '@util/type/util';
import { RequestHandlerCreator, PageInfoType, PageInfoParam } from '@api/util';
import { reqGet, reqPost, reqPatch, reqDelete } from '@api/index';

interface DisplayScreenInfo {
    menuName: string;
    screenName: string;
}

export interface GroupInfo {
    comNum: number;
    groupNum: number;
    groupName: string;
    description: NullableString;
    modDate: UnixTimestamp;
    regDate: UnixTimestamp;
    screenList: Nullable<DisplayScreenInfo[]>;
    serviceCode: ServiceCode;
}

interface LoginGroupInfo {
    comNum: number;
    groupName: string;
    groupNum: number;
    regDate: NullableUnixTimestamp;
    userNum: number;
}

interface AuthUserInfo {
    userId: string;
    userName: string;
    userNum: number;
    work: null | any;
    loginGroupList: LoginGroupInfo[];
}

// Group API Response
interface SingleGroupInfo extends Omit<GroupInfo, 'screenList'> {
    authUserList: Nullable<AuthUserInfo[]>;
}

interface GroupParam {
    groupNum: number;
}

interface CreateGroupParam {
    groupName: string;
    description?: string;
    authUserNumList?: number[];
}

interface UpdateGroupParam {
    groupNum: number;
    groupName?: string;
    description?: string;
    authUserNumList?: number[];
}

interface DeleteGroupParam {
    groupNum: number;
}

// 권한 그룹
export const fetchAuthGroupList: RequestHandlerCreator<PageInfoType<GroupInfo>, PageInfoParam> = param =>
    reqGet('/v3/api/common/group/auth', param);
export const fetchAuthGroup: RequestHandlerCreator<SingleGroupInfo, GroupParam> = param =>
    reqGet(`/v3/api/common/group/auth/${param.groupNum}`);
export const createAuthGroupApi: RequestHandlerCreator<{}, CreateGroupParam> = param =>
    reqPost('/v3/api/common/group/auth', param);
export const updateAuthGroupApi: RequestHandlerCreator<{}, UpdateGroupParam> = param =>
    reqPatch(`/v3/api/common/group/auth/${param.groupNum}`, param);
export const deleteAuthGroupApi: RequestHandlerCreator<{}, DeleteGroupParam> = param =>
    reqDelete(`/v3/api/common/group/auth/${param.groupNum}`);

export const fetchGroupUserList: RequestHandlerCreator<{}> = param => reqGet('/v3/api/common/group/auth/user', param);
