import React from 'react';
import SensingFilter from './SensingFilter';
import FilterSearchGroup from '../../../../MainPages/Components/FilterSearchGroup';
import FilterGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/FilterGroup';
import FilterButtonArea from '../../../../MainPages/Components/FilterSearchGroup/Components/FilterButtonArea';

const SensorType = ({ searchPromise }) => {
    return (
        <FilterSearchGroup className="card absolute-filter">
            <FilterGroup>
                <SensingFilter searchPromise={searchPromise} />
                <FilterButtonArea />
            </FilterGroup>
        </FilterSearchGroup>
    );
};
export default SensorType;
