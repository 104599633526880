import React, { useEffect, useRef } from 'react';
import WidgetCard from '../../Components/WidgetCard';
import MedicalWasteSummary from './Components/MedicalWasteSummary';
import MedicalWasteList from './Components/MedicalWasteList';
import { DATE_FORMAT_YMD_HMS } from './constants';
import { useAppSelector, useAsync } from '@hooks';
import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';
import TIMES from '@util/times';
import { LoadingBlock } from '@components';
import ChartList from './Components/ChartList';
import moment from 'moment';
import useTranslation from '@hooks/useTranslation';
import { MEDICAL_WASTE } from '../../constants';
import useAdminCheck from '../util/useAdminCheck';
import { getWasteDashboardApi } from '@api/sh/waste';

const MedicalWasteDashboard = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();
    const t = useTranslation(MEDICAL_WASTE);
    const { mode } = useAppSelector(state => state.ScreenInfo);
    const editMode = mode === SCREEN_MODE_EDIT || mode === SCREEN_MODE_PREVIEW;
    const isAdmin = useAdminCheck();
    const { userInfo } = useAppSelector(state => state.UserInfo);
    const { groupName, groupId } = userInfo;
    const wardText = isAdmin ? t('Kyung Hee University Hospital at Gangdong') : groupName;

    const searchDateRef = useRef(moment().format(DATE_FORMAT_YMD_HMS));

    useEffect(() => {
        let intervalId = -1;
        if (editMode) {
            return;
        }
        intervalId = setInterval(() => {
            searchDateRef.current = moment().format(DATE_FORMAT_YMD_HMS);
            getMedicalWaste();
        }, TIMES.MIN_1 * 3);

        return () => {
            if (intervalId !== -1) {
                clearInterval(intervalId);
            }
        };
    }, [editMode]);

    const { state, promise: getMedicalWaste } = useAsync({
        promise: getWasteDashboardApi,
        immediate: true,
        param: { ward: isAdmin ? null : groupId },
        reject: err => console.error(err),
    });

    const data = state.response ? state.response.rows : [];

    return (
        <WidgetCard ref={widgetRef} widgetInfo={widgetInfo} {...restProps}>
            <LoadingBlock blocking={state.isLoading}>
                <p className="pnt-txt txt-bold s-7 mb-2">
                    {`${wardText ?? '-'} ${t('Time viewed')}: ${searchDateRef.current}`}
                </p>
                <MedicalWasteSummary data={data} />
                <ChartList data={data} />
                <MedicalWasteList data={data} />
            </LoadingBlock>
            {children}
        </WidgetCard>
    );
};

export default MedicalWasteDashboard;
