import { reqGet, reqPost } from '../index';

// WMS -> 정보 -> 서비스 설정 -> 알람관련 설정 값
export const getNotificationConfigApi = param => reqGet('/v3/api/common/service/config/alert', param);
// 읽지 않은 알림 갯수 호출
export const getUnreadNotificationCountApi = param => reqPost('/v3/api/common/period/alert/log/count', param);
export const getNotificationLogsApi = param => reqPost('/v3/api/common/period/alert/log/append', param);
export const getUserWideNotificationSettingsApi = param =>
    reqGet(`/v3/api/common/alert/allAlertInterfaceCommandEvents`);
// 응급상황 알림 이력
export const getEmergencyAlertHistory = param => reqGet(`/v3/api/sh01/report`, param);
