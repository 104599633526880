import React, { useEffect, useMemo, useState } from 'react';
import { useVitalSignsMonitoringContext, useVitalSignsMonitoringDispatch } from '../index';
import NoDataBlock from '../../../Components/NoDataBlock';
import { convertToWorkTimeCheckList, storeVitalSignList, updateVitalSignList } from '../vitalSignsMonitoringSlice';
import useFilter from '@hooks/useFilter';
import useAsync from '@hooks/useAsync';
import { getVitalSignsMonitoringListApi } from '@api/monitoring';
import VitalSignsRoomList from './VitalSignsRoomList';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_NUMERIC_SENSING } from '@reducer/SocketInfo';
import { useAppSelector } from '@hooks';

// 선택한 담당시간 서버 요청 파라메터로 변경
const convertToWorkTimeFilterParams = workTimeCheckList => {
    const currentWorkTimeParams = {};
    workTimeCheckList.forEach((item, idx) => {
        if (!item.checked) {
            return;
        }
        currentWorkTimeParams[`worktimeList[${idx}].workTimeType`] = item.workTimeType;
        currentWorkTimeParams[`worktimeList[${idx}].startDate`] = item.startDate;
        currentWorkTimeParams[`worktimeList[${idx}].endDate`] = item.endDate;
    });
    return currentWorkTimeParams;
};

/**
 * 병실/병상, 환자 상태 리스트 컴포넌트
 *
 * @param config useMonitor config
 *
 * @since 2023-02-07
 * @author kunwoong-kim
 * */
const VitalSignsList = ({ config }) => {
    const { vitalSignsList, workTimeList } = useVitalSignsMonitoringContext();
    const dispatch = useVitalSignsMonitoringDispatch();
    const wardMapInfo = useAppSelector(state => state.DepartmentsInfo.wardMapInfo);

    const filterParams = useFilter();
    const [workTimeFilterParams, setWorkTimeFilterParams] = useState({});

    const mergedParams = useMemo(() => {
        const nowWorkTimeCheckList = convertToWorkTimeCheckList(workTimeList.origin).map((item, idx) => {
            return {
                ...item,
                checked: workTimeList.current[idx].checked,
            };
        });
        return {
            ...filterParams,
            ...convertToWorkTimeFilterParams(nowWorkTimeCheckList),
        };
    }, [workTimeFilterParams, filterParams]);

    // 활력징후 List 조회
    const { promise: getVitalSignsMonitoringList } = useAsync({
        promise: getVitalSignsMonitoringListApi,
        param: mergedParams,
        resolve: res => {
            dispatch(storeVitalSignList(res));
        },
    });

    const socketFilterConfig = useMemo(() => {
        return {};
    }, []);
    // socket 요청
    useSocketEvent({
        name: EVENT_TYPE_NUMERIC_SENSING,
        filterConfig: socketFilterConfig,
        handler: data => {
            dispatch(updateVitalSignList(data));
        },
    });

    // 최신 담당 시간 및 필터 변경시 담당시간 요청 파라메터 갱신
    useEffect(() => {
        setWorkTimeFilterParams(convertToWorkTimeFilterParams(workTimeList.current));
    }, [filterParams, workTimeList.current]);

    useEffect(() => {
        // filter, 담당 시간 값 없는 경우
        if (Object.keys(mergedParams).length === 0 || Object.keys(workTimeFilterParams).length === 0) {
            return;
        }
        getVitalSignsMonitoringList();
    }, [mergedParams]);

    // 활력징후 데이터 없는 경우
    if (vitalSignsList && !Object.entries(vitalSignsList).length) {
        return <NoDataBlock />;
    }
    // 렌더링을 위한 Entry
    const vitalSignsEntries = Object.entries(vitalSignsList);

    return (
        <div className="grid-container item-grid-300">
            {vitalSignsEntries.map(([wardNum, rooms], idx) => (
                <VitalSignsRoomList
                    key={`${wardNum}_${idx}`}
                    wardNum={wardMapInfo[wardNum]}
                    roomList={rooms}
                    handleRefreshData={() => getVitalSignsMonitoringList(mergedParams)}
                />
            ))}
        </div>
    );
};

export default VitalSignsList;
