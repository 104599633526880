import React, { createContext, useContext } from 'react';
import ContextProvider from '@components/ContextProvider';
import realTimeSensorMonitoringSlice from './realTimeSensorMonitoringSlice';
import RealTimeSensorMonitoringContainer from './RealTimeSensorMonitoringContainer';

const RealTimeSensorMonitoringStateContext = createContext(null);
const RealTimeSensorMonitoringDispatchContext = createContext(null);

export const useRealTimeSensorMonitoringContext = () => useContext(RealTimeSensorMonitoringStateContext);
export const useRealTimeSensorMonitoringDispatch = () => useContext(RealTimeSensorMonitoringDispatchContext);

const RealTimeSensorMonitoring = ({ children, widgetInfo, ...restProps }) => {
    return (
        <ContextProvider
            StateContext={RealTimeSensorMonitoringStateContext}
            DispatchContext={RealTimeSensorMonitoringDispatchContext}
            slice={realTimeSensorMonitoringSlice}
        >
            <RealTimeSensorMonitoringContainer widgetInfo={widgetInfo} children={children} {...restProps} />
        </ContextProvider>
    );
};

export default RealTimeSensorMonitoring;
