import React, { useEffect, useState } from 'react';
import moment from 'moment';
import cx from 'classnames';

import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';
import { useAppSelector, useAsync, useTranslation } from '@hooks';
import { getVitalSignsDetailGraphApi } from '@api/monitoring';
import { LineChart, LoadingBlock } from '@components';
import { mergeObjects } from '@util/common/util';

import Search from './Search';
import NoDataBlock from '../../../../../Components/NoDataBlock';
import InfoTable from './InfoTable';

import styles from '@asset/pnt/widgets/VitalSignsMonitoring.module.scss';
import {
    ADD_SENSOR_ITEM_NAME,
    DUMMY_GRAPH,
    GRAPH_AREA,
    GRAPH_SORT,
    VITAL_SIGN_FIXED_PARAM,
    LINE_CHART_COLOR,
    WHITE_COLOR,
    TOOLTIP_BG_COLOR_DARK,
    TOOLTIP_BG_COLOR_NAVY,
    TOOLTIP_TEXT_COLOR,
    DARK_THEME,
    DEFAULT_CHART_OPTIONS,
    TICK_LIMITS,
} from '../../../constants';

const formatSensorList = (
    {
        HEARTBEAT: heartbeatList = [],
        RESPIRATION_RATE: respirationRateList = [],
        TEMPERATURE: temperatureList = [],
        // TODO: step seonsor item
        // STEP: stepList = [],
    },
    t,
) => {
    const lists = [
        heartbeatList,
        respirationRateList,
        temperatureList,
        // stepList
    ];

    return lists.map(list => {
        let name;
        let unit;
        let min = Number.MAX_SAFE_INTEGER;
        let max = Number.MIN_SAFE_INTEGER;
        const stepSize = Math.min(TICK_LIMITS, list.length);
        const startTime = list[0]?.regDate * 1000;
        const endTime = list[list.length - 1]?.regDate * 1000;
        const offset = Math.floor((endTime - startTime) / stepSize);

        const data = list.reduce(
            (acc, value, index) => {
                const { currentValue, regDate, sensingType, measurementUnit } = value;
                name = ADD_SENSOR_ITEM_NAME[sensingType];
                unit = measurementUnit;
                min = Math.min(min, currentValue);
                max = Math.max(max, currentValue);

                if (!acc['datasets'][0]?.label) {
                    acc['datasets'][0].label = t(name);
                }

                if (index < TICK_LIMITS && !isNaN(startTime) && !isNaN(endTime)) {
                    acc['labels'].push(startTime + index * offset);
                }
                acc['datasets'][0].data.push({ x: regDate * 1000, y: Number(currentValue) });
                // TODO: step seonsor item
                // ADD_SENSOR_ITEM_NAME.STEP
                return acc;
            },
            {
                labels: [],
                datasets: [
                    {
                        data: [],
                        forcedPointBackgroundColor: WHITE_COLOR,
                        forcedBorderColor: LINE_CHART_COLOR,
                        forcedPointBorderColor: LINE_CHART_COLOR,
                        forcedBackgroundColor: LINE_CHART_COLOR,
                    },
                ],
            },
        );

        return { name, unit, data, min, max };
    });
};

// custom tooltip 으로 기존 recharts 툴팁과 유사하게 설정
const customTooltip = (colorScheme, t) => ({
    tooltip: {
        backgroundColor: colorScheme === DARK_THEME ? TOOLTIP_BG_COLOR_DARK : TOOLTIP_BG_COLOR_NAVY,
        titleColor: colorScheme === DARK_THEME ? WHITE_COLOR : TOOLTIP_TEXT_COLOR,
        bodyColor: colorScheme === DARK_THEME ? WHITE_COLOR : TOOLTIP_TEXT_COLOR,
        callbacks: {
            title: tooltipItem => {
                return `${t('Recognition Time')} ${tooltipItem[0].label}`;
            },
            label: tooltipItem => {
                let tooltipValue = tooltipItem.formattedValue;
                // Floating Bar Chart 에서 최대 최소 툴팁 표기
                if (tooltipValue.includes('[')) {
                    tooltipValue = JSON.parse(tooltipValue)
                        .sort((l, r) => l - r)
                        .join(' ~ ');
                }
                return `${t(tooltipItem.dataset.label)} ${tooltipValue}`;
            },
        },
    },
});

const MainView = ({ targetId }) => {
    const t = useTranslation('VitalSignMonitoring');
    const { mode } = useAppSelector(state => state.ScreenInfo);
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);
    const [vitalSensor, setVitalSensor] = useState({});
    const [sensorList, setSensorList] = useState([]);
    const [chartLoading, setChartLoading] = useState({ HEARTBEAT: true, RESPIRATION_RATE: true, TEMPERATURE: true });

    const [date, setDate] = useState(moment().unix());
    const handleDate = selected => {
        setDate(selected);
        // 날짜 변경시 차트 로딩 true
        setChartLoading({ HEARTBEAT: true, RESPIRATION_RATE: true, TEMPERATURE: true });
    };

    // 센서 그래프 조회
    const {
        state: { isLoading, response },
        promise: getVitalSignsDetailGraph,
    } = useAsync({
        promise: getVitalSignsDetailGraphApi,
        fixedParam: VITAL_SIGN_FIXED_PARAM,
        resolve: ({ recentVitalSensor, vitalSensorList }) => {
            setVitalSensor(recentVitalSensor);
            setSensorList(formatSensorList(vitalSensorList, t));
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        if (mode === SCREEN_MODE_EDIT || mode === SCREEN_MODE_PREVIEW) {
            setVitalSensor(DUMMY_GRAPH.recentVitalSensor);
            setSensorList(formatSensorList(DUMMY_GRAPH.vitalSensorList, t));
            return;
        }
        getVitalSignsDetailGraph({ searchDate: date, targetId });
    }, [date]);

    // API 로딩 이후 데이터가 없으면 차트 로딩 false
    useEffect(() => {
        if (!isLoading && response) {
            GRAPH_SORT.forEach(key => {
                // response로 내려온 값에 해당하는 key 값이 없을 때 동작
                if (!Object.keys(response.vitalSensorList).includes(key)) {
                    setChartLoading(prev => ({ ...prev, [key]: false }));
                }
            });
        }
    }, [response, isLoading, chartLoading]);

    return (
        <>
            <InfoTable vitalSensor={vitalSensor} loading={isLoading} />
            <Search date={date} onChange={handleDate} loading={isLoading} />

            {/* 맥박-체온 순서 보장 */}
            {GRAPH_SORT.map((key, index) => {
                const data = sensorList[index]?.data;
                const unit = sensorList[index]?.unit;
                return (
                    <div key={`${ADD_SENSOR_ITEM_NAME[key]}_${index}`} className="mb-5">
                        <div className="w-100 d-flex align-items-end mb-1">
                            <span className="pnt-txt txt-bold s-6">{t(ADD_SENSOR_ITEM_NAME[key])}</span>
                            {unit && <span className="pnt-txt ml-1">({unit})</span>}
                        </div>
                        {/* API 로딩 또는 차트 로딩이면 로딩 */}
                        <LoadingBlock blocking={isLoading || chartLoading[key]}>
                            {data && data?.datasets[0].data.length > 0 && !isLoading ? (
                                <div style={{ height: `${GRAPH_AREA.HEIGHT}px` }}>
                                    <LineChart
                                        data={data}
                                        options={mergeObjects(DEFAULT_CHART_OPTIONS, {
                                            plugins: {
                                                ...customTooltip(colorScheme, t),
                                                loading: {
                                                    afterLoad: loading =>
                                                        setChartLoading(prev => ({ ...prev, [key]: loading })),
                                                },
                                            },
                                            scales: {
                                                y: {
                                                    min: sensorList[index].min - 10,
                                                    max: sensorList[index].max + 10,
                                                },
                                            },
                                        })}
                                    />
                                </div>
                            ) : (
                                <NoDataBlock className={cx(styles.detail_graph, 'border-size-1 border-depth-7')} />
                            )}
                        </LoadingBlock>
                    </div>
                );
            })}
        </>
    );
};

export default MainView;
