import { getRentalHistoryDetail } from '@api/am/management';
import { Label, Modal, Table } from '@components';
import { useAsync, useTranslation } from '@util/hooks';
import moment from 'moment';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { checkHistoryRentalStatus } from '../RentalManagement/utils/check';

const DetailModal = ({ initModal, toggle, logNum }) => {
    const t = useTranslation('AssetManagement');

    const { state } = useAsync({
        promise: getRentalHistoryDetail,
        immediate: true,
        param: {
            rentalCompleteNum: logNum,
        },
    });
    const {
        categoryName,
        targetName,
        targetID,
        ownDepName,
        applyDepName,
        giveUserName,
        giveUserID,
        applyUserName,
        applyUserID,
        applyDate,
        requestStartDate,
        requestEndDate,
        giveDate,
        takeUserName,
        takeUserID,
        status,
        completeDate,
        description,
        returnUserName,
        returnUserID,
        returnDate,
    } = state.response ?? {};
    const rows = [
        {
            categoryName,
            targetName,
            targetID,
        },
    ];

    const columns = useMemo(
        () => [
            {
                Header: t('Category Name'),
                accessor: 'categoryName',
                Cell: ({ value }) => <div>{value ?? '-'}</div>,
            },
            {
                Header: t('Asset Name'),
                accessor: 'targetName',
                Cell: ({ value }) => <div>{value ?? '-'}</div>,
            },
            {
                Header: t('Asset ID'),
                accessor: 'targetID',
                Cell: ({ value }) => <div>{value ?? '-'}</div>,
            },
        ],
        [t],
    );

    return (
        <Modal
            className="modal-lg"
            headerTitle={t('Rental Detail')}
            removeCancel
            initModal={initModal}
            toggleModal={toggle}
            bodyText={
                <div>
                    <TableWrapper className="mb-3">
                        <Table
                            data={{
                                rows,
                            }}
                            columns={columns}
                            paging={false}
                        />
                    </TableWrapper>
                    <div className="d-flex">
                        <div className="w-50">
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Owner Ward')}
                                value={<span>{ownDepName}</span>}
                            />
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Rental Ward')}
                                value={<span>{applyDepName}</span>}
                            />
                        </div>
                        <div className="w-50">
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Supplier')}
                                value={<span>{giveUserName ?? '-'}</span>}
                            />
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Supplier ID')}
                                value={<span>{giveUserID ?? '-'}</span>}
                            />
                        </div>
                    </div>
                    <div className="pnt-divider horizon-line border-gray opacity-2 mb-2" />
                    <div className="d-flex">
                        <div className="w-50">
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Applicant')}
                                value={<span>{applyUserName}</span>}
                            />
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Applicant ID')}
                                value={<span>{applyUserID ?? '-'}</span>}
                            />
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Request Date')}
                                value={<span>{moment.unix(applyDate).format('YYYY-MM-DD HH:mm:ss')}</span>}
                            />
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Request Rental Period')}
                                value={
                                    <span>
                                        {moment.unix(requestStartDate).format('YYYY-MM-DD')} ~{' '}
                                        {moment.unix(requestEndDate).format('YYYY-MM-DD')}
                                    </span>
                                }
                            />
                        </div>
                        <div className="w-50">
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Receive Date')}
                                value={
                                    <span>{giveDate ? moment.unix(giveDate).format('YYYY-MM-DD HH:mm:ss') : '-'}</span>
                                }
                            />
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Receiver')}
                                value={<span>{takeUserName ?? '-'}</span>}
                            />
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Receiver ID')}
                                value={<span>{takeUserID ?? '-'}</span>}
                            />
                        </div>
                    </div>
                    <div className="pnt-divider horizon-line border-gray opacity-2 mb-2" />
                    <div className="d-flex">
                        <div className="w-50">
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Rental Status')}
                                value={<span>{t(checkHistoryRentalStatus(status))}</span>}
                            />
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Approve/Reject/Cancel Date')}
                                value={
                                    <span>
                                        {status === 'check_in'
                                            ? t('Approve')
                                            : status === 'refusal'
                                            ? t('Reject')
                                            : t('Cancel')}
                                        : {moment.unix(completeDate).format('YYYY-MM-DD HH:mm:ss')}
                                    </span>
                                }
                            />
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Description')}
                                value={<span>{description ?? '-'}</span>}
                            />
                        </div>
                        <div className="w-50">
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Returner')}
                                value={<span>{returnUserName ?? '-'}</span>}
                            />
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Returner ID')}
                                value={<span>{returnUserID ?? '-'}</span>}
                            />
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Return Date')}
                                value={
                                    <span>
                                        {returnDate ? moment.unix(returnDate).format('YYYY-MM-DD HH:mm:ss') : '-'}
                                    </span>
                                }
                            />
                            <Label
                                labelGroupClassName="pnt-label-6"
                                name={t('Lending Period')}
                                value={
                                    <span>
                                        {giveDate ? moment.unix(giveDate).format('YYYY-MM-DD HH:mm:ss') : '-'} ~{' '}
                                        {returnDate ? moment.unix(returnDate).format('YYYY-MM-DD HH:mm:ss') : '-'}
                                    </span>
                                }
                            />
                        </div>
                    </div>
                </div>
            }
        />
    );
};

const TableWrapper = styled.div`
    height: 4.3rem;
`;

export default DetailModal;
