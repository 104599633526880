import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { A_PATH_EMBED, A_PATH_MAIN } from '../Components/MainPages/Components/Router/path';
import { MenuInfo, DetailGroupInfo } from '@api/common/menu';
import { ScreenInfo, DisplayGroupInfo } from '@api/common/screen';
import { Nullable, YN, NullableString } from '@util/type/util';

interface CustomMenu {
    loadedMenu: boolean;
    originMenuList: MenuInfo[];
    originScreenList: OriginScreenInfo[];
    menuList: DisplayMenuInfo[];
    screenList: DisplayScreenInfo[];
}

interface OriginScreenInfo extends ScreenInfo<DetailGroupInfo> {
    groupNumList: Nullable<number[]>;
}

interface DisplayMenuInfo {
    id: string;
    label: string;
    icon: string;
    content: DisplayScreenInfo[];
}

interface DisplayScreenInfo extends Omit<DisplayMenuInfo, 'icon'> {
    to: string;
    url: NullableString;
    isWebView: YN;
}

const initialState: CustomMenu = {
    // 메뉴 로딩 여부
    loadedMenu: false,

    // 메뉴 원본 리스트
    originMenuList: [],

    // 화면을 그리기 위한 위젯 정보를 포함한 화면 리스트
    originScreenList: [],

    // 메뉴 랜더링을 위한 리스트
    menuList: [],

    // 라우팅을 위한 화면 리스트, embed 화면은 유효성 체크에 사용
    screenList: [],
};

// UI 랜더링에 필요한 메뉴 데이터 생성
// @ts-ignore
const makeMenuData = (menuList, parentMenuPath = A_PATH_MAIN) => {
    return (
        menuList
            // @ts-ignore
            .filter(menu => {
                return menu.screenCode || !!menu.screenList?.length;
            })
            // @ts-ignore
            .map(menu => {
                const tempMenuData = { ...menu, screenList: [...(menu.screenList ?? [])] };
                const tempMenuPath = parentMenuPath + `/${menu.screenCode ?? menu.menuCode}`;
                tempMenuData.screenList = makeMenuData(
                    // @ts-ignore
                    tempMenuData.screenList.sort((a, b) => a.order - b.order),
                    tempMenuPath,
                );

                let menuPath = tempMenuPath;
                if (tempMenuData.isWebView === 'Y' && parentMenuPath.indexOf(A_PATH_MAIN) === 0) {
                    menuPath = tempMenuPath.replace(A_PATH_MAIN, A_PATH_EMBED);
                }
                const menuData = {
                    id: menuPath,
                    label: tempMenuData.screenName ?? tempMenuData.menuName,
                    icon: tempMenuData.icon,
                    content: tempMenuData.screenList,
                };
                if (tempMenuData.screenCode) {
                    // @ts-ignore
                    menuData.to = `#${menuPath}`;
                    // @ts-ignore
                    menuData.url = menuPath;
                    // @ts-ignore
                    menuData.isWebView = tempMenuData.isWebView;
                }
                return menuData;
            })
    );
};

const makeOriginScreenList = (originMenuList: MenuInfo[] = []) => {
    const initialList: OriginScreenInfo[] = [];
    return originMenuList.reduce((acc, curr) => {
        if (curr.screenList) {
            acc = acc.concat(
                curr.screenList
                    .map(screen => ({
                        ...screen,
                        groupNumList: (screen.authGroups || []).map(group => group.groupNum),
                    }))
                    .sort((a, b) => a.order - b.order),
            );
        }
        return acc;
    }, initialList);
};

// 메뉴 목록 중 화면 목록만 배열로 리턴
// @ts-ignore
const extractScreenList = (menuList, screenList = []) => {
    // @ts-ignore
    menuList.forEach(menu => {
        if (menu.to) {
            // @ts-ignore
            screenList.push(menu);
        }
        if (menu.content && menu.content[0]) {
            extractScreenList(menu.content, screenList);
        }
    });
    return screenList;
};

// @ts-ignore
const excludeWebView = menuList => {
    // @ts-ignore
    return menuList.reduce((accMenuList, menu) => {
        if (menu.isWebView !== 'Y') {
            const tempMenu = { ...menu };
            if (tempMenu.content?.[0]) {
                tempMenu.content = excludeWebView(tempMenu.content);
            }
            // url 있거나 웹뷰화면을 제외하고 자식메뉴가 있는 메뉴만 추가
            if (tempMenu.url || tempMenu.content.length) {
                accMenuList.push(tempMenu);
            }
        }
        return accMenuList;
    }, []);
};

const { actions, reducer } = createSlice({
    name: 'customMenu',
    initialState,
    reducers: {
        setMenuList: (state, action: PayloadAction<MenuInfo[]>) => {
            const originMenuList = action.payload;
            const sortedMenuList = [...originMenuList].sort((a, b) => a.order - b.order);
            state.originMenuList = sortedMenuList;
            state.originScreenList = makeOriginScreenList(sortedMenuList);
            const tempMenuList = makeMenuData(sortedMenuList);
            state.menuList = excludeWebView(tempMenuList);
            state.screenList = extractScreenList(tempMenuList);
            state.loadedMenu = true;
        },
        setScreenList: (state, action: PayloadAction<ScreenInfo<DisplayGroupInfo>[]>) => {
            const newScreenList = action.payload;
            state.originMenuList = state.originMenuList.map(menu => {
                // @ts-ignore
                menu.screenList = newScreenList.filter(screen => screen.menuCode === menu.menuCode);
                return menu;
            });
            state.originScreenList = makeOriginScreenList(state.originMenuList);
            const tempMenuList = makeMenuData(state.originMenuList);
            state.menuList = excludeWebView(tempMenuList);
            state.screenList = extractScreenList(tempMenuList);
        },
        deleteMenuScreen: (state, action: PayloadAction<OriginScreenInfo>) => {
            const { menuCode, screenCode } = action.payload;
            if (menuCode && screenCode) {
                state.originMenuList = state.originMenuList.map(menu => {
                    // @ts-ignore
                    menu.screenList = menu.screenList.filter(
                        screen => screen.menuCode !== menuCode || screen.screenCode !== screenCode,
                    );
                    return menu;
                });
                state.originScreenList = makeOriginScreenList(state.originMenuList);
                const tempMenuList = makeMenuData(state.originMenuList);
                state.menuList = excludeWebView(tempMenuList);
                state.screenList = extractScreenList(tempMenuList);
            }
        },
    },
});

// @ts-ignore
export const { setMenuList, setScreenList, deleteMenuScreen } = actions;
// @ts-ignore
export default reducer;
