import React, { useContext } from 'react';
import { useTranslation } from '@hooks';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { RealTimeLocationTrackingContext, RealTimeLocationTrackingDispatchContext } from '../index';
import Label from '@components/Label';
import cx from 'classnames';
import { toggleGeofenceTagListPopup } from '../realTimeLocationTrackingReducer';

const DEFAULT_VALUE = '-';
const RealTimeLocationTracking = ({ className }) => {
    const t = useTranslation('RealTimeLocationTracking');
    const dispatch = useContext(RealTimeLocationTrackingDispatchContext);
    const {
        selectedFloor,
        selectedTopFloor,
        selectedCategory,
        realTimeLog,
        geofenceDisplayList,
        tagListByGeofence,
    } = useContext(RealTimeLocationTrackingContext);

    const buildingName = selectedTopFloor?.floorName;
    const floorName = selectedFloor?.floorName ?? DEFAULT_VALUE;
    const categoryName =
        selectedCategory?.length !== 0 ? selectedCategory?.map(({ label }) => label).toString() : DEFAULT_VALUE;
    const totalItemCount = Object.keys(realTimeLog).length ?? DEFAULT_VALUE;

    const handlePopUp = fcNum => {
        dispatch(toggleGeofenceTagListPopup(fcNum));
    };

    return (
        <div className={cx('p-4 border border-depth-7 overflow-hidden d-flex flex-column', className)}>
            <div className="pnt-txt s-5 txt-bold">{t('Detail')}</div>
            <Label labelGroupClassName="pnt-label-6 mt-3" name={t('Building')} value={<span>{buildingName}</span>} />
            <Label labelGroupClassName="pnt-label-6 mt-3" name={t('Floor')} value={<span>{floorName}</span>} />
            <Label labelGroupClassName="pnt-label-6 mt-3" name={t('Category')} value={categoryName} />
            <Label
                labelGroupClassName="pnt-label-6 mt-3"
                name={t('Asset status')}
                value={<span>{DEFAULT_VALUE}</span>}
            />
            <Label
                labelGroupClassName="pnt-label-6 mt-3"
                name={t('Total asset count')}
                value={<span>{`${totalItemCount} ${t('pieces')}`}</span>}
            />

            <div className="pnt-divider horizon-line my-4" />

            <div className="pnt-txt s-5 txt-bold">{t('Detail info by zone')}</div>
            <PerfectScrollbar component={'div'}>
                {geofenceDisplayList.length ? (
                    geofenceDisplayList.map(({ fcNum, fcName }) => {
                        const count = tagListByGeofence[fcNum].length;
                        return (
                            <Label
                                key={fcNum}
                                labelGroupClassName="pnt-label-6 mt-3"
                                name={fcName}
                                value={
                                    count === 0 ? (
                                        <span>{`${count} ${t('pieces')}`}</span>
                                    ) : (
                                        <span
                                            className={'color-primary cursor-pointer'}
                                            onClick={() => handlePopUp(fcNum)}
                                        >
                                            {count} {t('pieces')}
                                        </span>
                                    )
                                }
                            />
                        );
                    })
                ) : (
                    <span className="pnt-txt s-8">{t('No data', 'Filter')}</span>
                )}
            </PerfectScrollbar>
        </div>
    );
};

export default RealTimeLocationTracking;
