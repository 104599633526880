import React, { useState } from 'react';
import { ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import { Button, Label, Modal, TextInput } from '@components';
import { advancedGenerateFakeData } from '@util/common/util';
import { useAsync, useTranslation } from '@hooks';
import { MEDICAL_WASTE } from '../../constants';
import useModal from '../../util/useModal';

const postRequestApi = advancedGenerateFakeData({
    result: 'success',
    data: 'success',
});

const RegisterModal = ({ isOpen, toggleModal }) => {
    const t = useTranslation(MEDICAL_WASTE);
    const [billingText, setBillingText] = useState('');
    const [noteText, setNoteText] = useState('');
    const [isConfirmOpen, handleConfirmToggle, handleConfirmOpen] = useModal(); // 설명서 등록
    const [isAlertOpen, handleAlertToggle, handleAlertmOpen] = useModal(); // 알림
    const [isCompletedOpen, handleCompletedToggle, handleCompletedOpen] = useModal(); // 설명서 등록 완료

    const { promise: postRequest } = useAsync({
        promise: postRequestApi,
        param: {},
        resolve: () => {
            handleConfirmToggle();
            handleCompletedOpen();
        },
        reject: error => {
            console.error(error);
        },
    });

    const handleRegisterClick = () => {
        if (!billingText) {
            handleAlertmOpen();
            return;
        }
        handleConfirmOpen();
    };

    const handleRegisterOkClick = () => {
        // console.log(billingText);
        // console.log(noteText);
        postRequest();
    };

    return (
        <>
            <Modal
                initModal={isOpen}
                toggleModal={toggleModal}
                headerTitle={t('Register Billing Amount')}
                modalFooter={
                    <ModalFooter>
                        <Button className={'btn-gray'} onClick={toggleModal}>
                            {t('Cancel', 'Button')}
                        </Button>
                        <Button className={'btn-secondary'} onClick={handleRegisterClick}>
                            {t('Register')}
                        </Button>
                    </ModalFooter>
                }
            >
                <div className="modal-body">
                    <div className={'flx-col gap-3 pnt-label-5'}>
                        <Label
                            name={t('Billing Amount(Isolated Medical)(*)')}
                            value={
                                <TextInput
                                    value={billingText}
                                    handleChange={e => setBillingText(e.target.value)}
                                    inputGroupClassName="w-100"
                                />
                            }
                        />
                        <Label
                            name={t('Note')}
                            value={
                                <Textarea
                                    value={noteText}
                                    onChange={e => setNoteText(e.target.value)}
                                    spellCheck={false}
                                />
                            }
                        />
                    </div>
                </div>
            </Modal>
            {isConfirmOpen && (
                <Modal
                    initModal={isConfirmOpen}
                    headerTitle={t('Register Billing Amount')}
                    toggleModal={handleConfirmToggle}
                    modalFooter={
                        <ModalFooter>
                            <Button className={'btn-gray'} onClick={handleConfirmToggle}>
                                {t('Cancel', 'Button')}
                            </Button>
                            <Button className={'btn-secondary'} onClick={handleRegisterOkClick}>
                                {t('Ok', 'Button')}
                            </Button>
                        </ModalFooter>
                    }
                >
                    <div className="modal-body">{t('Would you like to register the billing amount data?')}</div>
                </Modal>
            )}
            {isAlertOpen && (
                <Modal initModal={isAlertOpen} toggleModal={handleAlertToggle} headerTitle={t('Notice')} removeCancel>
                    <div className="modal-body">{t('Billing amount is a required field.')}</div>
                </Modal>
            )}
            {isCompletedOpen && (
                <Modal
                    initModal={isCompletedOpen}
                    toggleModal={handleCompletedToggle}
                    headerTitle={t('Register Billing Amount Completed')}
                    cancelCallback={() => {
                        handleCompletedToggle();
                        toggleModal();
                    }}
                    modalFooter={
                        <ModalFooter>
                            <Button
                                className={'btn-secondary'}
                                onClick={() => {
                                    handleCompletedToggle();
                                    toggleModal();
                                }}
                            >
                                {t('Ok', 'Button')}
                            </Button>
                        </ModalFooter>
                    }
                >
                    <div className="modal-body">{t('The billing amount data has been registered successfully.')}</div>
                </Modal>
            )}
        </>
    );
};

const Textarea = styled.textarea`
    background-color: #f7f6f8;
    color: #3e3d5d;
    border: 1px solid #e8e6ef;
    width: 100%;
    height: 9rem;
    border-radius: 2px;
    padding: 0.5rem;
    box-sizing: border-box;
    transition: all 0.2s;
    letter-spacing: 0 !important;

    resize: none;

    &:hover {
        filter: brightness(0.98);
        transition: all 0.2s;
    }

    &:focus {
        border: 1px solid #565672;
        outline: none;
        box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
    }
`;

export default RegisterModal;
