import React from 'react';
import { createBookmarkApi, deleteBookmarkApi } from '@api/common/common';
import { useAsync } from '@hooks';
import { debounce } from 'lodash';
import Button from '@components/Button';

// default bookmark 값
export const BOOKMARK_FLAG = { FALSE: 'N', TRUE: 'Y' };

/**
 * Bookmark 토글 icon
 * * 기본 버튼 크기 문제로 Button component 사용 안함
 *
 * @param iconClassName icon classname (기본값: 'star')
 * @param iconColorClassName icon color classname (기본값: 'color-brand')
 * @param targetNum 북마크 설정 대상의 타겟 고유번호
 * @param isBookmark 북마크 여부 판단값 (기본값: 'N')
 * @param successCallback 북마크 설정/해제 성공 후 호출 함수
 * @param failureCallback 북마크 설정/해제 실패 후 호출 함수
 */
const Bookmark = ({
    iconClassName = 'star',
    iconColorClassName = 'color-brand',
    targetNum,
    isBookmark = BOOKMARK_FLAG.FALSE,
    successCallback = () => {},
    failureCallback = () => {},
}) => {
    // bookmark 등록 api
    const { promise: createBookmark } = useAsync({
        promise: createBookmarkApi,
        resolve: successCallback,
        reject: err => {
            console.error(err);
            failureCallback(err);
        },
    });

    // bookmark 삭제 api
    const { promise: deleteBookmark } = useAsync({
        promise: deleteBookmarkApi,
        resolve: successCallback,
        reject: err => {
            console.error(err);
            failureCallback(err);
        },
    });

    // bookmark handling event
    const debounceClick = debounce(() => {
        if (!targetNum) {
            failureCallback();
        }
        if (isBookmark === BOOKMARK_FLAG.TRUE) {
            deleteBookmark({ targetNum });
        } else {
            createBookmark({ targetNum });
        }
    }, 150);

    // 기본 아이콘 색상 설정
    const iconColorClass = isBookmark === BOOKMARK_FLAG.TRUE ? iconColorClassName : 'color-gray';

    return (
        <Button
            className="btn-icon-only btn-trans"
            iconName={iconClassName}
            iconClassName={`material-icons-round ${iconColorClass}`}
            onClick={e => {
                e.stopPropagation();
                debounceClick();
            }}
        />
    );
};

export default Bookmark;
