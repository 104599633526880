import React from 'react';
import moment from 'moment';
import { setIsDetail, setTargetId } from '../../Context/slice';
import cx from 'classnames';
import { DEFAULT_DATE, SIGNAL_STATUS_NORMAL } from '../../constants';
import useTranslation from '@hooks/useTranslation';
import { useRealTimeEnvironmentalSensorDispatch } from '../../Context';

/**
 * card body
 *
 * @param info 센서 정보
 */
const SensorCardBody = ({ info }) => {
    const t = useTranslation('CommonColumn');
    const dispatch = useRealTimeEnvironmentalSensorDispatch();

    const { targetId, SOUND_NOISE: soundNoise, CO2, TEMPERATURE: temperature, HUMIDITY: humidity } = info;

    const handleViewDetail = targetId => {
        dispatch(setTargetId(targetId));
        dispatch(setIsDetail(true));
    };

    const convertDate = unixTime => (unixTime ? moment.unix(unixTime).format('MM-DD HH:mm') : DEFAULT_DATE);
    const checkSNWarning = soundNoise?.status !== SIGNAL_STATUS_NORMAL;
    const checkCO2Warning = CO2?.status !== SIGNAL_STATUS_NORMAL;
    const checkTempWarning = temperature?.status !== SIGNAL_STATUS_NORMAL;
    const checkHumidityWarning = humidity?.status !== SIGNAL_STATUS_NORMAL;

    return (
        <div className="w-100 cursor-pointer" onClick={() => handleViewDetail(targetId)}>
            {/* 소음 */}
            <div className={cx('flx-row border-bottom border-depth-7', checkSNWarning && 'bg-warning')}>
                <div className="w-25 pnt-txt text-center px-2 py-1">{t('Noise')}</div>
                <div className="d-flex flx-1 justify-content-between align-items-center px-2 py-1 border-start border-depth-7">
                    <span className={cx('pnt-txt txt-bold s-7', checkSNWarning && 'color-danger')}>
                        {soundNoise?.value ? Math.round(soundNoise?.value) : 'N/A'} {soundNoise.measurementUnit}
                    </span>
                    <span className={'pnt-txt opacity-5'}>{convertDate(soundNoise?.regDate)}</span>
                </div>
            </div>

            {/* CO2 */}
            <div className={cx('flx-row border-bottom border-depth-7', checkCO2Warning && 'bg-warning')}>
                <div className="w-25 pnt-txt text-center px-2 py-1">{t('Co2')}</div>
                <div className="d-flex flx-1 justify-content-between align-items-center px-2 py-1 border-start border-depth-7">
                    <div>
                        <span className={cx('pnt-txt txt-bold s-7', checkCO2Warning && 'color-danger')}>
                            {CO2?.value ? Math.floor(CO2?.value) : 'N/A'} {CO2.measurementUnit}
                        </span>
                    </div>
                    <span className={'pnt-txt opacity-5'}>{convertDate(CO2?.regDate)}</span>
                </div>
            </div>

            {/* 온도 */}
            <div className={cx('flx-row border-bottom border-depth-7', checkTempWarning && 'bg-warning')}>
                <div className="w-25 pnt-txt text-center px-2 py-1">{t('Temperature')}</div>
                <div className="d-flex flx-1 justify-content-between align-items-center px-2 py-1 border-start border-depth-7">
                    <span className={cx('pnt-txt txt-bold s-7 mb-0', checkTempWarning && 'color-danger')}>
                        {temperature?.value ? temperature?.value.toFixed(1) : 'N/A'} {temperature.measurementUnit}
                    </span>
                    <span className={'pnt-txt opacity-5 mb-0'}>{convertDate(temperature?.regDate)}</span>
                </div>
            </div>

            {/* 습도 */}
            <div className={cx('flx-row', checkHumidityWarning && 'bg-warning')}>
                <div className="w-25 pnt-txt text-center px-2 py-1">{t('Humidity')}</div>
                <div className="d-flex flx-1 justify-content-between align-items-center px-2 py-1 border-start border-depth-7">
                    <span className={cx('pnt-txt txt-bold s-7 mb-0', checkHumidityWarning && 'color-danger')}>
                        {humidity?.value ? humidity?.value.toFixed(1) : 'N/A'} {humidity.measurementUnit}
                    </span>
                    <span className={'pnt-txt opacity-5 mb-0'}>{convertDate(humidity?.regDate)}</span>
                </div>
            </div>
        </div>
    );
};

export default SensorCardBody;
