import ThemeOptions from './ThemeOptions';
import CustomMenu from './CustomMenu';
import UserInfo from './UserInfo';
import TagInfo from './TagInfo';
import FloorInfo from './Common/FloorInfo';
import GeofenceInfo from './Common/GeofenceInfo';
import CategoryInfo from './Common/CategoryInfo';
import AppConfig from './Common/AppConfig';
import SocketInfo from './SocketInfo';
import ErrorHandler from './ErrorHandler';
import Notification from './Notification';
import EmergencyAlert from './EmergencyAlert';
import BookmarkedItem from './Common/BookmarkedItem';
import AppInfo from './Common/AppInfo';
import FilterInfo from './FilterInfo';
import GlobalIntervalTime from './GlobalIntervalTime';
import ScreenInfo from './ScreenInfo';
import DepartmentsInfo from './Common/DepartmentsInfo';

export default {
    ThemeOptions,
    CustomMenu,
    UserInfo,
    TagInfo,
    FloorInfo,
    GeofenceInfo,
    CategoryInfo,
    AppConfig,
    AppInfo,
    SocketInfo,
    ErrorHandler,
    Notification,
    EmergencyAlert,
    BookmarkedItem,
    FilterInfo,
    GlobalIntervalTime,
    ScreenInfo,
    DepartmentsInfo,
};
