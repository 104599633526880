import React, { useEffect, useState } from 'react';

import { useTranslation } from '@hooks';
import NoDataBlock from '../../Components/NoDataBlock';
import { PieChart } from '@components/Charts';

const ScannerStatusChart = ({ data }) => {
    const t = useTranslation('ScannerStatus');

    const [chartData, setChartData] = useState({ datasets: [] });

    useEffect(() => {
        setChartData({
            labels: data.map(({ name }) => t(name)),
            datasets: [{ data: data.map(({ value }) => value) }],
        });
    }, [data, t]);

    return !data.length ? <NoDataBlock /> : <PieChart data={chartData} />;
};

export default ScannerStatusChart;
