import React from 'react';
import { Route } from 'react-router-dom';
import PageNotFound from './Pages/PageNotFound';

export const errorPath = {
    pageNotFound: '/error/pageNotFound',
};

export const ErrorPages = props => {
    return <Route exact path={errorPath.pageNotFound} component={PageNotFound} />;
};
