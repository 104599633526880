export const DUMMY_LIST_DATA = {
    병동: {
        '01': {
            '01': {
                b00313: [
                    {
                        dailyHealthNum: null,
                        targetNum: 17,
                        targetName: 'B00313_환자',
                        targetId: 'b00313',
                        sensorNum: 10,
                        sensingType: 'HEARTBEAT',
                        measurementUnit: 'bpm',
                        currentValue: 0,
                        status: 'OUTOFRANGE',
                        regDate: 1700101029,
                        modDate: null,
                        isBookmark: 'N',
                        properties: {
                            status: 'IN',
                            ward: '2',
                            room: '01',
                            bed: '01',
                            clndeptname: '성형외과',
                            clndrname: '김신',
                            chosgb: 'I',
                            area: '',
                            team: '',
                        },
                    },
                    {
                        dailyHealthNum: null,
                        targetNum: 17,
                        targetName: 'B00313_환자',
                        targetId: 'b00313',
                        sensorNum: 10,
                        sensingType: 'RESPIRATION_RATE',
                        measurementUnit: 'times',
                        currentValue: 0,
                        status: 'OUTOFRANGE',
                        regDate: 1700101029,
                        modDate: null,
                        isBookmark: 'N',
                        properties: {
                            status: 'IN',
                            ward: '2',
                            room: '01',
                            bed: '01',
                            clndeptname: '성형외과',
                            clndrname: '김신',
                            chosgb: 'I',
                            area: '',
                            team: '',
                        },
                    },
                    {
                        dailyHealthNum: null,
                        targetNum: 17,
                        targetName: 'B00313_환자',
                        targetId: 'b00313',
                        sensorNum: 10,
                        sensingType: 'TEMPERATURE',
                        measurementUnit: '℃',
                        currentValue: 33.4,
                        status: 'CRITICAL',
                        regDate: 1700101029,
                        modDate: null,
                        isBookmark: 'N',
                        properties: {
                            status: 'IN',
                            ward: '2',
                            room: '01',
                            bed: '01',
                            clndeptname: '성형외과',
                            clndrname: '김신',
                            chosgb: 'I',
                            area: '',
                            team: '',
                        },
                    },
                ],
            },
        },
        '02': {
            '02': {
                b00316: [
                    {
                        dailyHealthNum: null,
                        targetNum: 18,
                        targetName: 'B00316_환자',
                        targetId: 'b00316',
                        sensorNum: 9,
                        sensingType: 'HEARTBEAT',
                        measurementUnit: 'bpm',
                        currentValue: 47.18,
                        status: 'WARNING',
                        regDate: 1700784334,
                        modDate: null,
                        isBookmark: 'N',
                        properties: {
                            status: 'IN',
                            ward: '2',
                            room: '02',
                            bed: '02',
                            clndeptname: '산부인과',
                            clndrname: '신김',
                            chosgb: 'I',
                            area: '',
                            team: '',
                        },
                    },
                    {
                        dailyHealthNum: null,
                        targetNum: 18,
                        targetName: 'B00316_환자',
                        targetId: 'b00316',
                        sensorNum: 9,
                        sensingType: 'RESPIRATION_RATE',
                        measurementUnit: 'times',
                        currentValue: 53.52,
                        status: 'CRITICAL',
                        regDate: 1700784334,
                        modDate: null,
                        isBookmark: 'N',
                        properties: {
                            status: 'IN',
                            ward: '2',
                            room: '02',
                            bed: '02',
                            clndeptname: '산부인과',
                            clndrname: '신김',
                            chosgb: 'I',
                            area: '',
                            team: '',
                        },
                    },
                    {
                        dailyHealthNum: null,
                        targetNum: 18,
                        targetName: 'B00316_환자',
                        targetId: 'b00316',
                        sensorNum: 9,
                        sensingType: 'TEMPERATURE',
                        measurementUnit: '℃',
                        currentValue: 55.73,
                        status: 'OUTOFRANGE',
                        regDate: 1700784334,
                        modDate: null,
                        isBookmark: 'N',
                        properties: {
                            status: 'IN',
                            ward: '2',
                            room: '02',
                            bed: '02',
                            clndeptname: '산부인과',
                            clndrname: '신김',
                            chosgb: 'I',
                            area: '',
                            team: '',
                        },
                    },
                ],
            },
        },
        '03': {
            '02': {
                b00319: [
                    {
                        dailyHealthNum: null,
                        targetNum: 19,
                        targetName: 'B00319_환자',
                        targetId: 'b00319',
                        sensorNum: 8,
                        sensingType: 'HEARTBEAT',
                        measurementUnit: 'bpm',
                        currentValue: 46.96,
                        status: 'WARNING',
                        regDate: 1700787186,
                        modDate: null,
                        isBookmark: 'N',
                        properties: {
                            status: 'IN',
                            ward: '2',
                            room: '03',
                            bed: '02',
                            clndeptname: '소아과',
                            clndrname: '주치의명',
                            chosgb: 'I',
                            area: '',
                            team: '',
                        },
                    },
                    {
                        dailyHealthNum: null,
                        targetNum: 19,
                        targetName: 'B00319_환자',
                        targetId: 'b00319',
                        sensorNum: 8,
                        sensingType: 'RESPIRATION_RATE',
                        measurementUnit: 'times',
                        currentValue: 52.15,
                        status: 'OUTOFRANGE',
                        regDate: 1700787799,
                        modDate: null,
                        isBookmark: 'N',
                        properties: {
                            status: 'IN',
                            ward: '2',
                            room: '03',
                            bed: '02',
                            clndeptname: '소아과',
                            clndrname: '주치의명',
                            chosgb: 'I',
                            area: '',
                            team: '',
                        },
                    },
                    {
                        dailyHealthNum: null,
                        targetNum: 19,
                        targetName: 'B00319_환자',
                        targetId: 'b00319',
                        sensorNum: 8,
                        sensingType: 'TEMPERATURE',
                        measurementUnit: '℃',
                        currentValue: 53.31,
                        status: 'OUTOFRANGE',
                        regDate: 1700787727,
                        modDate: null,
                        isBookmark: 'N',
                        properties: {
                            status: 'IN',
                            ward: '2',
                            room: '03',
                            bed: '02',
                            clndeptname: '소아과',
                            clndrname: '주치의명',
                            chosgb: 'I',
                            area: '',
                            team: '',
                        },
                    },
                ],
            },
        },
    },
};

export const DUMMY_DETAIL_INFO = {
    targetName: 'B00316_환자',
    targetId: 'b00316',
    categoryName: '환자',
    ward: '2',
    room: '02',
    bed: '02',
    beaconName: null,
    floor: null,
    location: '0.0,0.0',
    status: null,
    lastDate: null,
};

export const DUMMY_GRAPH = {
    recentVitalSensor: {
        RESPIRATION_RATE: '52.15',
        TEMPERATURE: '53.31',
        HEARTBEAT: '46.96',
    },
    vitalSensorList: {
        TEMPERATURE: [
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '51.08',
                regDate: 1700786639,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '51.29',
                regDate: 1700786645,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '49.61',
                regDate: 1700786652,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '50.79',
                regDate: 1700786658,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '51.02',
                regDate: 1700786664,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '51.73',
                regDate: 1700786670,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '51.49',
                regDate: 1700786675,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '49.63',
                regDate: 1700786892,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '49.05',
                regDate: 1700786898,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '48.48',
                regDate: 1700786904,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '50.07',
                regDate: 1700786910,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '51.52',
                regDate: 1700786916,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '51.52',
                regDate: 1700786916,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '51.52',
                regDate: 1700786916,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '51.52',
                regDate: 1700786916,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '53.72',
                regDate: 1700786938,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.59',
                regDate: 1700786944,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '55.8',
                regDate: 1700786950,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '55.8',
                regDate: 1700786950,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '55.8',
                regDate: 1700786950,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '55.8',
                regDate: 1700786950,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '55.8',
                regDate: 1700786950,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '55.8',
                regDate: 1700786950,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '55.8',
                regDate: 1700786950,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '55.8',
                regDate: 1700786950,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '55.8',
                regDate: 1700786950,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '55.8',
                regDate: 1700786950,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '55.8',
                regDate: 1700786950,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '55.8',
                regDate: 1700786950,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '55.8',
                regDate: 1700786950,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '55.8',
                regDate: 1700786950,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '55.8',
                regDate: 1700786950,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '55.8',
                regDate: 1700786950,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '55.8',
                regDate: 1700786950,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '55.8',
                regDate: 1700786950,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.5',
                regDate: 1700787067,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.5',
                regDate: 1700787067,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.5',
                regDate: 1700787067,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.5',
                regDate: 1700787067,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.5',
                regDate: 1700787067,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.5',
                regDate: 1700787067,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.5',
                regDate: 1700787067,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.5',
                regDate: 1700787067,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.5',
                regDate: 1700787067,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.5',
                regDate: 1700787067,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.5',
                regDate: 1700787067,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.5',
                regDate: 1700787067,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.5',
                regDate: 1700787067,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.5',
                regDate: 1700787067,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.5',
                regDate: 1700787067,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.5',
                regDate: 1700787067,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.5',
                regDate: 1700787067,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.5',
                regDate: 1700787067,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.5',
                regDate: 1700787067,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.5',
                regDate: 1700787067,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.15',
                regDate: 1700787247,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.15',
                regDate: 1700787247,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.86',
                regDate: 1700787367,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.86',
                regDate: 1700787367,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.65',
                regDate: 1700787487,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.65',
                regDate: 1700787487,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.65',
                regDate: 1700787487,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '54.65',
                regDate: 1700787487,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '53.31',
                regDate: 1700787727,
                measurementUnit: '℃',
            },
            {
                targetId: 'b00319',
                sensingType: 'TEMPERATURE',
                currentValue: '53.31',
                regDate: 1700787727,
                measurementUnit: '℃',
            },
        ],
        RESPIRATION_RATE: [
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '50.23',
                regDate: 1700786639,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53',
                regDate: 1700786645,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '51.68',
                regDate: 1700786652,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '52.49',
                regDate: 1700786658,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.72',
                regDate: 1700786664,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.46',
                regDate: 1700786670,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '53.57',
                regDate: 1700786675,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '52.2',
                regDate: 1700787307,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '52.2',
                regDate: 1700787307,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '51.67',
                regDate: 1700787427,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '51.67',
                regDate: 1700787427,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '52.14',
                regDate: 1700787547,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '51.37',
                regDate: 1700787607,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '50.73',
                regDate: 1700787667,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '50.73',
                regDate: 1700787667,
                measurementUnit: 'times',
            },
            {
                targetId: 'b00319',
                sensingType: 'RESPIRATION_RATE',
                currentValue: '52.15',
                regDate: 1700787799,
                measurementUnit: 'times',
            },
        ],
        HEARTBEAT: [
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '50.58',
                regDate: 1700786639,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '52.55',
                regDate: 1700786645,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '51.29',
                regDate: 1700786652,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '52.16',
                regDate: 1700786658,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '49.89',
                regDate: 1700786664,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '49.94',
                regDate: 1700786670,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '47.01',
                regDate: 1700786675,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '47.01',
                regDate: 1700786675,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '47.01',
                regDate: 1700786675,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '47.01',
                regDate: 1700786675,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '47.01',
                regDate: 1700786675,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '47.01',
                regDate: 1700786675,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '50.54',
                regDate: 1700786921,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '50.9',
                regDate: 1700786926,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '50.32',
                regDate: 1700786931,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '50.32',
                regDate: 1700786931,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '50.32',
                regDate: 1700786931,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '50.32',
                regDate: 1700786931,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '50.23',
                regDate: 1700786956,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '51.05',
                regDate: 1700786961,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '51.63',
                regDate: 1700786966,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '50.18',
                regDate: 1700786976,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '50.88',
                regDate: 1700786981,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '51',
                regDate: 1700786986,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '51.02',
                regDate: 1700786991,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '50.04',
                regDate: 1700786996,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '50.87',
                regDate: 1700787001,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '51.25',
                regDate: 1700787006,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '50.9',
                regDate: 1700787016,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '50.72',
                regDate: 1700787021,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '51.06',
                regDate: 1700787031,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '50.98',
                regDate: 1700787041,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '50.04',
                regDate: 1700787046,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '50.6',
                regDate: 1700787056,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '50.83',
                regDate: 1700787061,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '50.83',
                regDate: 1700787061,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '50.25',
                regDate: 1700787076,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '50',
                regDate: 1700787081,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '49.59',
                regDate: 1700787086,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '48.71',
                regDate: 1700787091,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '48.28',
                regDate: 1700787096,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '47.26',
                regDate: 1700787106,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '46.83',
                regDate: 1700787111,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '47.36',
                regDate: 1700787116,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '48.24',
                regDate: 1700787121,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '48.7',
                regDate: 1700787126,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '49.36',
                regDate: 1700787131,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '48.59',
                regDate: 1700787141,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '49.1',
                regDate: 1700787146,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '48.58',
                regDate: 1700787156,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '49.45',
                regDate: 1700787161,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '49.48',
                regDate: 1700787166,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '48.84',
                regDate: 1700787171,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '47.89',
                regDate: 1700787181,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '46.96',
                regDate: 1700787186,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '46.96',
                regDate: 1700787186,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '46.96',
                regDate: 1700787186,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '46.96',
                regDate: 1700787186,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '46.96',
                regDate: 1700787186,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '46.96',
                regDate: 1700787186,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '46.96',
                regDate: 1700787186,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '46.96',
                regDate: 1700787186,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '46.96',
                regDate: 1700787186,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '46.96',
                regDate: 1700787186,
                measurementUnit: 'bpm',
            },
            {
                targetId: 'b00319',
                sensingType: 'HEARTBEAT',
                currentValue: '46.96',
                regDate: 1700787186,
                measurementUnit: 'bpm',
            },
        ],
    },
    vitalBpSensorList: {
        BLOODPRESSURE: [],
    },
};

// 활력 징후 경고값
export const VITAL_SIGN_RANGE = {
    // 최고 혈압(SBP)
    H_BLOOD_PRESSURE: {
        MIN: 90,
        MAX: 149,
    },
    // 최저 혈압(DBP)
    L_BLOOD_PRESSURE: {
        MAX: 99,
    },
    // 맥박(PR)
    PULSE_RATE: {
        MIN: 55,
        MAX: 110,
    },
    // 체온(BT)
    TEMPERATURE: {
        MIN: 35,
        MAX: 37.5,
    },
    // 호흡 수(RR)
    RESPIRATORY_RATE: {
        MIN: 9,
        MAX: 30,
    },
};

// 암센터 poc 용
export const HICADI_PLUS_CODE = 'mezoo';
export const DEFAULT_DATE = '--/-- --:--';

export const VITAL_SIGN_FIXED_PARAM = { vitalityType: HICADI_PLUS_CODE };

export const ADD_SENSOR_ITEM_NAME = {
    HEARTBEAT: 'HB',
    RESPIRATION_RATE: 'RR',
    TEMPERATURE: 'ST',
    // TODO: step seonsor item
    // STEP: 'STEP',
};

export const SIGNAL_STATUS_NORMAL = 'NORMAL';

export const GRAPH_AREA = {
    WIDTH: 1250,
    HEIGHT: 300,
    MARGIN_TOP: 5,
    MARGIN_RIGHT: 20,
    MARGIN_BOTTOM: 40,
    MARGIN_LEFT: 0,
};
export const LABEL_AREA = {
    TOP: 32,
};

export const GRAPH_SORT = Object.keys(ADD_SENSOR_ITEM_NAME);

export const STATUS_COLOR = {
    WARNING: { BG: 'bg-warning', COLOR: '' },
    CRITICAL: { BG: 'bg-warning', COLOR: '' },
    OUTOFRANGE: { BG: 'bg-secondary bg-l-4', COLOR: '' },
    undefined: { BG: 'bg-gray bg-light', COLOR: '' },
};

// 테이블 높이
export const TABLE_HEADER_HEIGHT = 32;
export const TABLE_ROW_HEIGHT = 35;
export const TABLE_BORDER_WIDTH = 2;

export const OUT_OF_RANGE = 'OUTOFRANGE';

export const LINE_CHART_COLOR = '#82ca9d';
export const WHITE_COLOR = '#fff';
export const TOOLTIP_BG_COLOR_DARK = '#4c4c52';
export const TOOLTIP_BG_COLOR_NAVY = '#e8e6ef';
export const TOOLTIP_TEXT_COLOR = '#565672';
export const DARK_THEME = 'pnt-dark';
const GRID_GRAY_COLOR = '#ccc';
const TEXT_BOLD = 'bold';

export const DEFAULT_CHART_OPTIONS = {
    animation: false,
    parser: false,
    spanGaps: true,
    layout: {
        padding: {
            top: GRAPH_AREA.MARGIN_TOP,
            left: GRAPH_AREA.MARGIN_LEFT,
            right: GRAPH_AREA.MARGIN_RIGHT,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            mode: 'index',
            intersect: false,
            animation: { duration: 0 },
            displayColors: false,
            padding: 12,
            titleFont: {
                weight: TEXT_BOLD,
            },
            bodyFont: {
                weight: TEXT_BOLD,
            },
        },
    },
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'second',
                tooltipFormat: 'HH:mm:ss',
                displayFormats: { second: 'HH:mm:ss' },
            },
            display: true,
            grid: {
                borderDash: [4, 4],
                display: true,
                tickLength: LABEL_AREA.TOP,
                offset: false,
                color: GRID_GRAY_COLOR,
            },
            ticks: {
                minRotation: 45,
                source: 'labels',
            },
        },
        y: {
            grid: {
                borderWidth: 1,
                borderDash: [4, 4],
                color: GRID_GRAY_COLOR,
            },
            ticks: {
                maxTicksLimit: 5,
            },
        },
    },
    datasets: {
        line: {
            pointRadius: 0,
            borderWidth: 1,
            offset: true,
        },
    },
};

export const BATTERY_CHARGING = 255;
export const BATTERY_LEAD_OFF = 250;

export const DEFAULT_DISPLAY_VALUE = 'N/A';
export const TICK_LIMITS = 16;
