import React, { createContext, useCallback, useEffect, useReducer, useState } from 'react';

import { useRefrigeratorSensorContext, useRefrigeratorSensorDispatch } from '../../index';
import useTranslation from '@hooks/useTranslation';
import DatePicker from '@components/DatePicker';
import DailyTable from './Components/DailyTable';
import useAsync from '@hooks/useAsync';
import {
    deleteRefrigeratorSensorDailyLogApi,
    getRefrigeratorSensorDailyLogApi,
    updateRefrigeratorSensorDailyLogApi,
} from '@api/monitoring';
import reducer, {
    addRefrigeratorSensorDailyLog,
    changeRefrigeratorSensorDailyLog,
    deleteRefrigeratorSensorDailyLog,
    initialState,
    setRefrigeratorSensorDailyLogList,
} from './refrigeratorSensorLogReducer';
import TableInput from './Components/TableInput';
import Button from '@components/Button';
import { prefixTime } from '../../../../util/commons';
import { initUUIDv4 } from '@util/common/util';
import moment from 'moment';
import { debounce } from 'lodash';
import { ConfirmModal } from '@components';
import useFilter from '@hooks/useFilter';
import Modal from '@components/Modal';
import { stopUpdatingData } from '../../refrigeratorSensorSlice';
import SearchGroup from '../../../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import { SearchWrap, SelectGroup } from '../../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '../../../../../MainPages/Components/FilterSearchGroup';
import InnerItemCard from '@components/Card/InnerItemCard';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from '@asset/pnt/widgets/RefrigeratorSensor.module.scss';

export const RefrigeratorSensorDailyLogStateContext = createContext();
export const RefrigeratorSensorDailyLogDispatchContext = createContext();
const DAY_WITH_UNIX = 86400;

const RefrigeratorSensorDailyLog = () => {
    const t = useTranslation('RefrigeratorSensor');
    const { refrigeratorSensorList, isUpdatingData } = useRefrigeratorSensorContext();
    const parentDispatch = useRefrigeratorSensorDispatch();
    const [state, dispatch] = useReducer(reducer, initialState);
    const filterParams = useFilter({
        preParam: {
            startDate: moment().startOf('date').unix(),
            endDate: moment().endOf('date').unix(),
        },
    });

    const [submitModal, setSubmitModal] = useState(false);
    const toggleSubmitModal = () => {
        setSubmitModal(!submitModal);
    };
    const cancelSubmitModal = () => {
        parentDispatch(stopUpdatingData());
    };

    // alert modal
    const [alertModal, setAlertModal] = useState({
        init: false,
        removeCancel: true,
        bodyTextKey: '',
        okCallback: () => {},
    });
    const toggleAlertModal = () =>
        setAlertModal(prevState => {
            return {
                ...prevState,
                init: !alertModal.init,
            };
        });
    const handleAlertModal = ({ init = false, bodyTextKey = '', removeCancel = true, okCallback = () => {} }) =>
        setAlertModal({ init, bodyTextKey, removeCancel, okCallback });

    // 일간대장 리스트 api 호출
    const { promise: getRefrigeratorSensorDailyLog } = useAsync({
        promise: getRefrigeratorSensorDailyLogApi,
        param: { ...filterParams },
        immediate: true,
        resolve: res => {
            dispatch(setRefrigeratorSensorDailyLogList(res));
        },
        reject: err => console.error(err),
    });

    // 일간대장 data 수정 api 호출
    const { promise: updateDailyLog } = useAsync({
        promise: updateRefrigeratorSensorDailyLogApi,
        resolve: res => {},
        reject: err => console.error(err),
    });

    // 일간대장 data 삭제 api 호출
    const { promise: deleteDailyLog } = useAsync({
        promise: deleteRefrigeratorSensorDailyLogApi,
        resolve: res => {},
        reject: err => console.error(err),
    });

    // 날짜 변경
    const [selectedDate, setSelectedDate] = useState(moment().unix());
    const today = moment().format('YYYY-MM-DD');
    const getDate = moment(selectedDate * 1000).format('YYYY-MM-DD');
    const handleChangeDate = debounce(date => {
        setSelectedDate(date);
        const fullUnix = date * 1000;
        getRefrigeratorSensorDailyLog({
            ...filterParams,
            startDate: moment(fullUnix).startOf('date').unix(),
            endDate: moment(fullUnix).endOf('date').unix(),
        });
    }, 150);

    // 사용자 입력 시간 행 utc 기준으로 변경
    const checkTimeValue = inputTimeValue => {
        // 사용자 입력 정규식 체크
        if (!inputTimeValue.match(/^([01][0-9]|2[0-3]):?([0-5][0-9])$/)) {
            handleAlertModal({ init: true, bodyTextKey: 'Please enter a valid time value' });
            // window.alert(`${t('Please enter a valid time value')}\nex) 09:00 / 0900`);
            return moment().utc(true).startOf('date').unix();
        }
        // 시간 형태 -> 00:00 변환
        let time = inputTimeValue.match(/:/g) ? inputTimeValue : inputTimeValue.replace(/(.{2})/, '$1:');
        // ':' 문자 기준으로 시, 분 가져오기
        const splitTime = time.split(':');
        // 입력한 local timezone 기준으로 UTC unixTime 생성
        // const UTCUnixTime = new Date(selectedDateToUnixTime).setHours(Number(splitTime[0]), Number(splitTime[1]), 0, 0);
        const UTCUnixTime = moment(selectedDate * 1000)
            .hours(Number(splitTime[0]))
            .minutes(Number(splitTime[1]))
            .seconds(0)
            .milliseconds(0);
        return UTCUnixTime / 1000; // m초 자르기
    };

    // 사용자 입력 온도
    const checkTemperatureValue = inputTemperatureValue => {
        // 숫자가 아닌값이 들어올때
        if (isNaN(inputTemperatureValue) || inputTemperatureValue === null) {
            return null;
        }
        // 소수점 2자리까지
        return Number(inputTemperatureValue).toFixed(2);
    };

    // 행 삭제
    const handleDeleteRow = payload => {
        dispatch(deleteRefrigeratorSensorDailyLog(payload));
        if (payload.logNum > 0) {
            deleteDailyLog({ logNum: payload.logNum });
        }
    };

    // 행 추가
    const handleAddRow = useCallback(
        ({ sensorNum, targetNum, targetName, dept }) => {
            dispatch(
                addRefrigeratorSensorDailyLog({
                    sensorNum,
                    targetNum,
                    targetName,
                    dept,
                    regDate: moment(selectedDate * 1000).unix(),
                }),
            );
        },
        [selectedDate],
    );

    // 일간 대장 수정 저장
    const submit = () => {
        const updateDailyLogValue = Object.entries(state.refrigeratorSensorDailyLogList).map(([key, values]) => {
            return { deviceNum: key, values: values };
        });
        updateDailyLog({ deviceList: updateDailyLogValue }).then(() => {
            handleAlertModal({
                init: true,
                bodyTextKey: 'Save is complete',
                okCallback: () => {
                    parentDispatch(stopUpdatingData());
                    getRefrigeratorSensorDailyLog({
                        ...filterParams,
                        startDate: moment(selectedDate * 1000)
                            .startOf('date')
                            .unix(),
                        endDate: moment(selectedDate * 1000)
                            .endOf('date')
                            .unix(),
                    });
                },
            });
        });
    };

    // 일간대장 테이블 Column 정보
    const initColumns = React.useMemo(
        () => [
            { header: 'Time', accessor: 'regDate', width: 50 },
            { header: 'Temperature', accessor: 'currentValue', width: 50 },
            // { header: 'Remarks', accessor: 'contents', width: 35 },
            // 서울의료원 기준 기능 삭제
            // {
            //     header: null,
            //     accessor: 'deleteRow',
            //     width: 20,
            // },
        ],
        [],
    );

    /**
     * 냉장고별 일간대장 표 데이터 생성
     *
     * @param sensorNum 냉장고 고유번호
     * @param minControlLimit 최소 허용값
     * @param maxControlLimit 최대 허용값
     * @param sensorDailyLogs 냉장고 일간기록
     */
    const initTableRows = ({ sensorNum, minControlLimit, maxControlLimit }, sensorDailyLogs) => {
        return sensorDailyLogs.map(
            ({ contents, currentValue, dept, logNum, regDate, status, targetName, targetNum }, itemIdx) => {
                const parseDate = moment(regDate * 1000);
                const viewDate = parseDate.format('HH:mm');
                const temperature = checkTemperatureValue(currentValue);
                return {
                    outOfControl:
                        temperature === null ? false : minControlLimit > temperature || maxControlLimit < temperature,
                    // 측정시간
                    regDate: (
                        <TableInput
                            initValue={viewDate}
                            className="custom-bg-daily-report"
                            handleChange={inputValue => {
                                const updateData = checkTimeValue(inputValue);
                                dispatch(
                                    changeRefrigeratorSensorDailyLog({
                                        sensorNum: sensorNum,
                                        idx: itemIdx,
                                        logNum: logNum,
                                        data: { regDate: updateData },
                                    }),
                                );
                            }}
                        />
                    ),
                    // 온도 값
                    currentValue: (
                        <TableInput
                            initValue={temperature}
                            className="custom-bg-daily-report"
                            handleChange={inputValue => {
                                const toFixedTemperature = checkTemperatureValue(inputValue);
                                dispatch(
                                    changeRefrigeratorSensorDailyLog({
                                        sensorNum: sensorNum,
                                        idx: itemIdx,
                                        logNum: logNum,
                                        data: { currentValue: toFixedTemperature },
                                    }),
                                );
                            }}
                        />
                    ),
                    // 비고
                    contents: (
                        <TableInput
                            initValue={contents}
                            className="custom-bg-daily-report text-ellipsis text-start px-2"
                            title={contents}
                            maxLength={50}
                            handleChange={inputValue => {
                                dispatch(
                                    changeRefrigeratorSensorDailyLog({
                                        sensorNum: sensorNum,
                                        idx: 0,
                                        logNum: sensorDailyLogs[0].logNum,
                                        data: { contents: inputValue },
                                    }),
                                );
                            }}
                        />
                    ),
                    // 행 삭제 버튼
                    deleteRow: (
                        <div
                            className="d-flex justify-content-center align-items-center w-100 h-100 cursor-pointer custom-bg-daily-report"
                            onClick={() => {
                                handleAlertModal({
                                    init: true,
                                    bodyTextKey: 'Do you want to delete?',
                                    removeCancel: false,
                                    okCallback: () =>
                                        handleDeleteRow({
                                            sensorNum: sensorNum,
                                            idx: itemIdx,
                                            logNum: logNum,
                                        }),
                                });
                            }}
                        >
                            <span className="icon-close icon-small opacity-4" />
                        </div>
                    ),
                };
            },
        );
    };

    useEffect(() => {
        if (isUpdatingData) {
            toggleSubmitModal();
        }
    }, [isUpdatingData]);

    useEffect(() => {
        return () => {
            setSubmitModal(false);
        };
    }, []);

    return (
        <RefrigeratorSensorDailyLogDispatchContext.Provider value={dispatch}>
            <RefrigeratorSensorDailyLogStateContext.Provider value={state}>
                <div className="h-100 d-flex flex-column">
                    <FilterSearchGroup className="p-0 mb-3">
                        <SearchGroup label={t('Daily Report')}>
                            <SearchWrap className="pt-0">
                                <SelectGroup>
                                    <DatePicker
                                        value={selectedDate}
                                        valueType={'s'}
                                        handleChange={selected => handleChangeDate(selected)}
                                        maxDate={moment.now()}
                                        withoutTime
                                    />
                                </SelectGroup>
                                <Button
                                    className="btn-icon-only btn-gray ml-2"
                                    iconName="keyboard_arrow_left"
                                    onClick={() => handleChangeDate(selectedDate - DAY_WITH_UNIX)}
                                />
                                <Button
                                    className={'btn-icon-only btn-gray ml-1'}
                                    iconName="keyboard_arrow_right"
                                    onClick={() => handleChangeDate(selectedDate + DAY_WITH_UNIX)}
                                    disabled={getDate === today}
                                />
                            </SearchWrap>
                        </SearchGroup>
                    </FilterSearchGroup>
                    <PerfectScrollbar className="flx-1">
                        <div className="grid-container item-grid-250">
                            {refrigeratorSensorList.length !== 0 &&
                                refrigeratorSensorList.map(
                                    (
                                        {
                                            sensorNum,
                                            sensorName,
                                            targetName,
                                            targetNum,
                                            metadata,
                                            minControlLimit,
                                            maxControlLimit,
                                        },
                                        index,
                                    ) => {
                                        const dept = JSON.parse(metadata).ward;
                                        const sensorDailyLogs =
                                            state.refrigeratorSensorDailyLogList[sensorNum.toString()] || [];
                                        const dailyLogRows = initTableRows(
                                            { sensorNum, minControlLimit, maxControlLimit },
                                            sensorDailyLogs,
                                        );
                                        return (
                                            <InnerItemCard
                                                key={`${targetNum}_${sensorNum}`}
                                                header={{
                                                    title: targetName,
                                                    subTitle: sensorName,
                                                }}
                                                bodyClassName="p-0"
                                            >
                                                {dailyLogRows.length ? (
                                                    <DailyTable
                                                        key={`${initUUIDv4()}_${index}`}
                                                        maxHeight={180}
                                                        actionRow
                                                        columns={initColumns}
                                                        rows={dailyLogRows}
                                                        maxRowLength={4}
                                                        handleActionRow={() =>
                                                            handleAddRow({ sensorNum, targetNum, targetName, dept })
                                                        }
                                                    />
                                                ) : (
                                                    <div className={styles.empty}>{t('No data', 'Table')}</div>
                                                )}
                                            </InnerItemCard>
                                        );
                                    },
                                )}
                        </div>
                    </PerfectScrollbar>
                </div>
                <ConfirmModal
                    initModal={submitModal}
                    toggleModal={toggleSubmitModal}
                    header={{ title: t('Daily Report') }}
                    confirmText={t('Do you want to save?')}
                    cancelCallback={cancelSubmitModal}
                    okCallback={submit}
                />
                <Modal
                    initModal={alertModal.init}
                    toggleModal={toggleAlertModal}
                    headerTitle={t('Daily Report')}
                    bodyText={t(alertModal.bodyTextKey)}
                    removeCancel={alertModal.removeCancel}
                    okCallback={alertModal.okCallback}
                />
            </RefrigeratorSensorDailyLogStateContext.Provider>
        </RefrigeratorSensorDailyLogDispatchContext.Provider>
    );
};

export default React.memo(RefrigeratorSensorDailyLog);
