import React, { useEffect, useState } from 'react';
import NoDataBlock from '../../../Components/NoDataBlock';
import { ChartData as ChartDataType } from 'chart.js';
import { PieChart } from '@components/Charts';

interface ChartData {
    ranking: number;
    key: string;
    name: string;
    value: number;
}

interface Props {
    data: ChartData[];
}
const GeofenceCongestionPieChart = ({ data }: Props) => {
    const [chartData, setChartData] = useState<ChartDataType<'pie'>>({ datasets: [] });
    useEffect(() => {
        setChartData({
            labels: data.map(({ name }) => name),
            datasets: [{ data: data.map(({ value }) => value) }],
        });
    }, [data]);

    return !data.length ? (
        <NoDataBlock />
    ) : (
        <PieChart data={chartData} options={{ responsive: true, maintainAspectRatio: false }} />
    );
};

export default GeofenceCongestionPieChart;
