import React from 'react';
import realTimeEnvironmentalSensorSlice from './Context/slice';
import ContextProvider from '@components/ContextProvider';
import { RealTimeEnvironmentalSensorDispatchContext, RealTimeEnvironmentalSensorStatus } from './Context';
import RealTimeEnvironmentalSensorContainer from './RealTimeEnvironmentalSensorContainer';

/**
 * 실시간 병실 환경 센서 위젯
 * */
const RealTimeEnvironmentalSensor = ({ children, widgetInfo, ...restProps }) => {
    return (
        <ContextProvider
            StateContext={RealTimeEnvironmentalSensorStatus}
            DispatchContext={RealTimeEnvironmentalSensorDispatchContext}
            slice={realTimeEnvironmentalSensorSlice}
        >
            <RealTimeEnvironmentalSensorContainer widgetInfo={widgetInfo} children={children} {...restProps} />
        </ContextProvider>
    );
};

export default RealTimeEnvironmentalSensor;
