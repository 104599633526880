import React, { useState } from 'react';
import { Portal } from 'react-overlays';
import { useAsync, useTranslation } from '@hooks';
import { Dropdown, DropdownItem, DropdownMenu } from 'reactstrap';
import ConfirmModal from '@components/Modal/ConfirmModal';
import { deleteTestTargetPatientApi } from '@api/sh/examPatientMonitoring';
import styles from '@asset/pnt/widgets/sh/ExamPatientsMonitoring.module.scss';
import cx from 'classnames';

const Contextmenu = ({
    open,
    x,
    y,
    row,
    toggle,
}: {
    open: boolean;
    x: number;
    y: number;
    row: any;
    toggle(): void;
}) => {
    const t = useTranslation('ExamPatientsMonitoring');

    const { promise: deletePatient } = useAsync({
        promise: deleteTestTargetPatientApi,
        resolve: res => {
            setSuccessModalOpen(true);
        },
        reject: err => {
            console.error(err);
            setFailureModalOpen(true);
        },
    });

    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
    const [preventDeleteModalOpen, setPreventDeleteModalOpen] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [failureModalOpen, setFailureModalOpen] = useState(false);

    // 한번이라도 시작 안한 대상
    const deletable =
        row &&
        row.original &&
        (row.original.elapsedTime === null || row.original.elapsedTime === 0) &&
        row.original.progressType === 'N';

    return (
        <>
            <Portal container={document.body}>
                <Dropdown
                    className={'position-absolute'}
                    isOpen={open}
                    direction={'right'}
                    style={{
                        left: x + 'px',
                        top: y + 'px',
                    }}
                    toggle={toggle}
                >
                    <DropdownMenu className={'grid-sub-menu pe-auto'} style={{ width: '5rem' }}>
                        <DropdownItem
                            className={cx('justify-content-center', !deletable && styles['disabled-dropdown-item'])}
                            onClick={e => {
                                if (deletable) {
                                    setConfirmDeleteModalOpen(true);
                                } else {
                                    setPreventDeleteModalOpen(true);
                                }
                            }}
                        >
                            {t('Delete')}
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </Portal>
            <ConfirmModal
                confirmText={t('Are you sure you want to delete it?')}
                initModal={confirmDeleteModalOpen}
                toggleModal={() => {
                    setConfirmDeleteModalOpen(prev => !prev);
                }}
                okCallback={() => {
                    deletePatient({ subjectNum: row.original.subjectNum });
                }}
            />
            <ConfirmModal
                confirmText={t("You can't delete a research subject because the measurement has already started.")}
                initModal={preventDeleteModalOpen}
                toggleModal={() => {
                    setPreventDeleteModalOpen(prev => !prev);
                }}
                removeCancel
            />
            <ConfirmModal
                confirmText={t('The patient deletion is complete.')}
                initModal={successModalOpen}
                toggleModal={() => {
                    setSuccessModalOpen(prev => !prev);
                }}
                removeCancel
            />
            <ConfirmModal
                confirmText={t('The delete failed. Please try again in a moment.')}
                initModal={failureModalOpen}
                toggleModal={() => {
                    setFailureModalOpen(prev => !prev);
                }}
                removeCancel
            />
        </>
    );
};

export const useContextmenu = () => {
    const [contextmenu, setContextmenu] = useState<{ open: boolean; x: number; y: number; row: any }>({
        open: false,
        x: 0,
        y: 0,
        row: {},
    });

    const toggleContextmenu = () => {
        setContextmenu(prevState => {
            return {
                ...prevState,
                open: !prevState.open,
            };
        });
    };

    return { contextmenu, setContextmenu, toggleContextmenu };
};

export default Contextmenu;
