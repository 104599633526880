import React, { useState } from 'react';
import { useAsync } from '@hooks';
import OutpatientDatePicker from './OutpatientDatePicker';
import { useOutpatientProofContext } from './index';
import { getOutpatientProofIssuanceStatusCountApi, getOutpatientProofIssuanceStatusListApi } from '@api/monitoring';
import moment from 'moment';
import OutpatientProofStatusCount from './Components/OutpatientProofStatusCount';
import OutpatientProofStatusList from './Components/OutpatientProofStatusList';
import useDepartmentOptions from '../util/useDepartmentOptions';

const DEFAULT_PROOF_COUNT = {
    total: 0,
    certificateTypeStat: {
        dischargeCertificate: 0,
        medicalBill: 0,
        medicalBillDetail: 0,
        medicalPaymentCertificate: 0,
        publishFail: 0,
        medicalYearEndSettlement: 0,
    },
};
const DEFAULT_PROOF_LIST = {
    pageSize: 15,
    totalCount: 0,
    totalPage: 1,
    rows: [],
};
const OutpatientProofIssuanceItemContainer = () => {
    const [startDate, setStartDate] = useState(moment().startOf('day').unix());
    const [endDate, setEndDate] = useState(
        moment().startOf('day').add(23, 'hours').add(59, 'm').add(59, 'second').unix(),
    );
    const { selectedOutpatientStatus: status, keyword, outpatientListParam } = useOutpatientProofContext();

    const departmentValues = useDepartmentOptions();
    const { department } = departmentValues;

    const { state: outpatientProofCountState } = useAsync({
        promise: getOutpatientProofIssuanceStatusCountApi,
        immediate: true,
        param: {
            startDate: startDate,
            endDate: endDate,
            departmentCodes: department.toString(),
            ...outpatientListParam,
        },
        reject: err => {
            console.error(err);
        },
        deps: [startDate, endDate, keyword, department],
    });

    const { state: outpatientProofListState } = useAsync({
        promise: getOutpatientProofIssuanceStatusListApi,
        immediate: true,
        param: {
            ...outpatientListParam,
            startDate: startDate,
            endDate: endDate,
            departmentCodes: department.toString(),
        },
        reject: err => {
            console.error(err);
        },
        deps: [startDate, endDate, status, keyword, department],
    });

    const handleStartDate = date => {
        setStartDate(date);
    };
    const handleEndDate = date => {
        setEndDate(date);
    };

    const outpatientProofCount = outpatientProofCountState.response ?? DEFAULT_PROOF_COUNT;
    const outpatientProofList = outpatientProofListState.response ?? DEFAULT_PROOF_LIST;

    return (
        <div className={'w-100 h-100'}>
            <OutpatientProofStatusCount outpatientCount={outpatientProofCount} />
            <OutpatientDatePicker
                startDate={startDate}
                endDate={endDate}
                handleStartDate={handleStartDate}
                handleEndDate={handleEndDate}
                departmentValues={departmentValues}
            />
            <OutpatientProofStatusList outpatientList={outpatientProofList} />
        </div>
    );
};

export default OutpatientProofIssuanceItemContainer;
