import React, { useContext } from 'react';
import { RealTimeVitalSignsStateContext } from '../index';
import NoDataBlock from '../../../Components/NoDataBlock';
import VitalSignsRoomList from './VitalSignsRoomList';
import { getSingleValueAsc } from '../util';

/**
 * 병실/병상, 환자 상태 카드 리스트 컴포넌트
 *
 * @since 2022-12-20
 * @author kunwoong-kim
 * */
const VitalSignsCardList = ({ handleRefreshData }) => {
    const { vitalSignsList } = useContext(RealTimeVitalSignsStateContext);

    // 활력징후 데이터 없는 경우
    if (vitalSignsList && !Object.entries(vitalSignsList).length) {
        return <NoDataBlock />;
    }
    // 렌더링을 위한 Entry
    const vitalSignsEntries = getSingleValueAsc(Object.entries(vitalSignsList));

    return vitalSignsEntries.map(([wardNum, rooms]) => (
        <VitalSignsRoomList key={wardNum} wardNum={wardNum} roomList={rooms} handleRefreshData={handleRefreshData} />
    ));
};

export default VitalSignsCardList;
