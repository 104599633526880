import React from 'react';
import { Button, Card, Label, Page, Table } from '@components';
import { useAppSelector, useColumns, useTranslation } from '@hooks';
import * as col from '../../../util/grid/column/menu';
import * as commonCol from '../../../util/grid/column';
import MenuListSubMenu from './MenuListSubMenu';
import { useHistory } from 'react-router-dom';

const MenuList = () => {
    const t = useTranslation('MenuManagement');
    const history = useHistory();
    const { originMenuList } = useAppSelector(state => state.CustomMenu);

    const menuColumns = useColumns(
        [
            col.menuIcon(),
            col.menuName(),
            col.menuDescription(),
            {
                Header: 'Sort Order',
                className: 'd-flex flx-center',
                accessor: 'order',
                width: 50,
            },
            {
                Header: 'Screen List',
                className: 'pr-3 pl-3 text-ellipsis',
                accessor: 'screenList',
                formatTitle: ({ value }) =>
                    value[0]
                        ? `${value[0].menuName} > ${value[0].screenName}` +
                          (value[1] ? ` ${t('other')} ${value.length - 1}` : '')
                        : '',
                Cell: ({ value }) =>
                    value.map((data, idx) => (
                        <Label
                            key={`${data.menuName}_${data.screenName}_${idx}`}
                            name={`${data.menuName} > ${data.screenName}`}
                            labelValueClassName={`pnt-txt txt-dot txt-bold ${value.length !== idx + 1 ? 'mb-2' : ''}`}
                        />
                    )),
                style: {
                    maxHeight: '150px',
                    overflow: 'auto',
                },
            },
            commonCol.modDate(),
            {
                Header: 'Action',
                id: 'subMenu',
                width: 50,
                className: 'flex-center overflow-visible',
                Cell: ({ row }) => <MenuListSubMenu row={row} />,
            },
        ],
        t,
    );

    return (
        <Page className="h-100" menuPath={[t('Menu Management', 'Menu'), t('Menu List', 'Menu')]}>
            <Card
                className="h-100"
                header={{
                    title: t('Menu List'),
                    action: (
                        <Button
                            className="btn-icon btn-secondary"
                            iconName="add"
                            onClick={() => history.push('/setting/menu/create')}
                        >
                            {t('Create Menu')}
                        </Button>
                    ),
                }}
            >
                <Table
                    textAlign={'center'}
                    data={{ rows: originMenuList }}
                    columns={menuColumns}
                    paging={false}
                    dynamicRowHeight
                />
            </Card>
        </Page>
    );
};

export default MenuList;
