import React, { useEffect, useState } from 'react';
import Label from '@components/Label';
import { getVitalSignsDetailInfoApi } from '@api/monitoring';
import useAsync from '@hooks/useAsync';
import MainView from './Components/MainView';
import moment from 'moment';
import useTranslation from '@hooks/useTranslation';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useAppSelector } from '@hooks';

const ViewDetail = ({ targetNum }) => {
    const t = useTranslation('VitalSignMonitoring');
    const [info, setInfo] = useState({});
    const { targetName, targetId, categoryName, ward, room, bed, beaconName, floor, location, status, lastDate } = info;
    const wardMapInfo = useAppSelector(state => state.DepartmentsInfo.wardMapInfo);

    const { promise: getVitalSignsDetailInfo } = useAsync({
        promise: getVitalSignsDetailInfoApi,
        resolve: res => {
            setInfo(res);
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        getVitalSignsDetailInfo({ targetId: targetNum });
    }, []);

    return (
        <div className="d-flex h-100">
            <PerfectScrollbar className="w-25 p-3 border border-depth-7">
                <Label name={t('Name')} labelGroupClassName="mb-2" value={targetName ? targetName : '-'} />
                <Label name={t('ID')} labelGroupClassName="mb-2" value={targetId ? targetId : '-'} />
                <Label name={t('Category')} value={categoryName ? categoryName : '-'} />

                <div className="pnt-divider horizon-line"></div>

                <Label name={t('Ward/Department')} labelGroupClassName="mb-2" value={ward ? wardMapInfo[ward] : '-'} />
                <Label name={t('Room')} labelGroupClassName="mb-2" value={room ? room : '-'} />
                <Label name={t('Bed')} value={bed ? bed : '-'} />

                <div className="pnt-divider horizon-line"></div>

                <Label name={t('Tag Name')} labelGroupClassName="mb-2" value={beaconName ? beaconName : '-'} />
                <Label name={t('Floor')} labelGroupClassName="mb-2" value={floor ? floor : '-'} />
                <Label name={t('Location')} labelGroupClassName="mb-2" value={location ? location : '-'} />
                <Label name={t('Signal Status')} labelGroupClassName="mb-2" value={status ? t(status) : '-'} />
                <Label
                    name={t('Recognition Time')}
                    value={lastDate ? moment(lastDate * 1000).format('YYYY-MM-DD') : '-'}
                />
            </PerfectScrollbar>
            <PerfectScrollbar className={'w-75 ml-3 p-3 border border-depth-7'}>
                <MainView targetId={targetNum} />
            </PerfectScrollbar>
        </div>
    );
};

export default ViewDetail;
