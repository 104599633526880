import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import useTranslation from '@hooks/useTranslation';
import useFilter from '@hooks/useFilter';
import useAsync from '@hooks/useAsync';
import { getRefrigeratorSensorListApi } from '@api/monitoring';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_NUMERIC_SENSING } from '@reducer/SocketInfo';
import { hideGlobalIntervalTimeDot, resetGlobalIntervalTimeDot } from '@reducer/GlobalIntervalTime';
import Button from '@components/Button';

import {
    setRefrigeratorSensorList,
    startUpdatingData,
    updateEachRefrigeratorSensorList,
} from './refrigeratorSensorSlice';
import ViewDetail from './Components/ViewDetail/ViewDetail';
import RefrigeratorSensorDailyLog from './Components/RefrigeratorSensorLog/RefrigeratorSensorDailyLog';
import RefrigeratorSensorMonthlyLog from './Components/RefrigeratorSensorLog/RefrigeratorSensorMonthlyLog';
import RefrigeratorSensorCard from './Components/RefrigeratorSensorCard/RefrigeratorSensorCard';
import WidgetCard from '../../Components/WidgetCard';
import { useRefrigeratorSensorContext, useRefrigeratorSensorDispatch } from './index';

export const REFRIGERATOR_DASHBOARD_TYPE = {
    LIST: 'LIST',
    DETAIL: 'DETAIL',
    DAILY: 'DAILY',
    MONTHLY: 'MONTHLY',
};
const CATEGORY_CODE = 'refrigerator';

const RefrigeratorSensorContainer = ({ widgetInfo, children, ...restProps }) => {
    const t = useTranslation('RefrigeratorSensor');
    const buttonT = useTranslation('Button');
    const filterParams = useFilter();
    const storeDispatch = useDispatch();
    const widgetRef = useRef();

    const state = useRefrigeratorSensorContext();
    const dispatch = useRefrigeratorSensorDispatch();

    const { promise: getRefrigeratorSensorList } = useAsync({
        promise: getRefrigeratorSensorListApi,
        resolve: res => {
            const { rows } = res;
            dispatch(setRefrigeratorSensorList(rows));
        },
        reject: err => console.error(err),
    });

    // 냉장고 센서 리스트 새로고침
    const handleRefresh = () => {
        getRefrigeratorSensorList({
            ...filterParams,
            categoryCodes: CATEGORY_CODE,
        });
    };
    const socketFilterConfig = useMemo(() => {
        return {
            targetCategory: {
                categoryCode: {
                    $in: [CATEGORY_CODE],
                },
            },
        };
    }, []);

    useSocketEvent({
        name: EVENT_TYPE_NUMERIC_SENSING,
        filterConfig: socketFilterConfig,
        handler: data => {
            dispatch(updateEachRefrigeratorSensorList(data));
        },
    });

    // 대시보드 타입 handling
    const [dashboardType, setDashboardType] = useState(REFRIGERATOR_DASHBOARD_TYPE.LIST);
    const handleDashboardType = dashboardType => {
        setDashboardType(dashboardType);
    };

    // dashboard type에 따른 컴포넌트 분기
    const widgetContent = {
        [REFRIGERATOR_DASHBOARD_TYPE.DETAIL]: <ViewDetail handleRefresh={handleRefresh} />,
        [REFRIGERATOR_DASHBOARD_TYPE.DAILY]: <RefrigeratorSensorDailyLog />,
        [REFRIGERATOR_DASHBOARD_TYPE.MONTHLY]: <RefrigeratorSensorMonthlyLog />,
        [REFRIGERATOR_DASHBOARD_TYPE.LIST]: (
            <RefrigeratorSensorCard handleDashboardType={handleDashboardType} refreshCallback={handleRefresh} />
        ),
    };
    // 필터정보 변경 후 api 호출
    useEffect(() => {
        getRefrigeratorSensorList({
            ...filterParams,
            categoryCodes: CATEGORY_CODE,
        });
    }, [filterParams]);

    // 전역 타이머 사용 여부
    useEffect(() => {
        storeDispatch(hideGlobalIntervalTimeDot()); // interval time dot 미사용
        return () => {
            storeDispatch(resetGlobalIntervalTimeDot()); // interval time dot default
        };
    }, []);

    return (
        <WidgetCard
            ref={widgetRef}
            widgetInfo={widgetInfo}
            headerAction={
                <>
                    {dashboardType === REFRIGERATOR_DASHBOARD_TYPE.LIST ? (
                        <Button
                            className="btn-line"
                            onClick={() => handleDashboardType(REFRIGERATOR_DASHBOARD_TYPE.DAILY)}
                        >
                            {t('Daily Report')}
                        </Button>
                    ) : (
                        <>
                            <Button
                                className="btn-gray"
                                onClick={() => handleDashboardType(REFRIGERATOR_DASHBOARD_TYPE.LIST)}
                            >
                                {buttonT('Back')}
                            </Button>
                            {[
                                REFRIGERATOR_DASHBOARD_TYPE.DETAIL,
                                REFRIGERATOR_DASHBOARD_TYPE.DAILY,
                                REFRIGERATOR_DASHBOARD_TYPE.MONTHLY,
                            ].includes(dashboardType) && (
                                <Button
                                    className={'btn-brand'}
                                    disabled={state.isUpdatingData}
                                    onClick={() => dispatch(startUpdatingData())}
                                >
                                    {buttonT('Save')}
                                </Button>
                            )}
                        </>
                    )}
                </>
            }
            {...restProps}
        >
            {widgetContent[dashboardType]}
            {children}
        </WidgetCard>
    );
};

export default RefrigeratorSensorContainer;
