import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CATEGORY_CODE } from '@util/mappInfo';

const useCategoryList = ({ parentCode = CATEGORY_CODE.ASSET } = {}) => {
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const initialCategoryList = categoryList
        .filter(category => category.parentCode === parentCode)
        .map(ele => ele.categoryCode);
    const categoryOptions = useMemo(
        () =>
            categoryList
                .filter(category => category.parentCode === parentCode) // 최상위의 하위 카테고리 추출
                .map(category => ({
                    label: category.categoryName,
                    value: category.categoryCode,
                })),
        [categoryList, parentCode],
    );

    const [selectedCategoryList, setSelectedCategoryList] = useState(initialCategoryList);

    const handleCategoryChange = selected => {
        setSelectedCategoryList(selected.map(v => v.value));
    };

    const handleCategoryReset = () => {
        setSelectedCategoryList(initialCategoryList);
    };

    return {
        initialCategoryList,
        selectedCategoryList,
        categoryOptions,
        handleCategoryChange,
        handleCategoryReset,
    };
};

export default useCategoryList;
