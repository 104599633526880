import React from 'react';
import { Col, Row } from 'reactstrap';
import useTranslation from '@util/hooks/useTranslation';

/**
 * @param {function} toggleProfileModal // 프로필 설정 모달 함수
 * @param {function} togglePersonalizationModal // 개인 설정 모달 함수
 *
 * @author created bu 정진범 2022-12-08
 * **/

const Setting = ({ toggleProfileModal, togglePersonalizationModal }) => {
    const t = useTranslation('UserBox');

    return (
        <div className="profile-setting">
            <Row className="no-gutters">
                <Col sm onClick={toggleProfileModal} className="setting-item">
                    <span className="material-icons-round">manage_accounts</span>
                    <div className="setting-name">{t('Profile')}</div>
                </Col>
                <Col sm onClick={togglePersonalizationModal} className="setting-item">
                    <span className="material-icons-round">app_settings_alt</span>
                    <div className="setting-name">{t('Personalization')}</div>
                </Col>
            </Row>
        </div>
    );
};
export default Setting;
