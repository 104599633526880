import useAppSelector from '@hooks/useAppSelector';
import { ASSET_CODE } from '../am/AssetRentalStatus/constants';

const useCategoryProperties = ({ categoryCode = ASSET_CODE } = {}) => {
    const { categoryPropertiesList } = useAppSelector(state => state.CategoryInfo);
    return categoryPropertiesList
        .filter(({ categoryCode: propertyCategoryCode }) => propertyCategoryCode === categoryCode)
        .sort((a, b) => a.sortOrder - b.sortOrder);
};

export default useCategoryProperties;
