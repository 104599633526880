import moment from 'moment';
import { useState } from 'react';

const formatStartUnixTime = unixTime => {
    return moment.unix(unixTime).startOf('day').unix();
};

const formatEndUnixTime = unixTime => {
    return moment.unix(unixTime).endOf('day').unix();
};

export const MAXIMUN_DIFF_DAY = 365;

const useRangeDateAM = () => {
    const [startDate, setStartDate] = useState(moment().subtract(7, 'days').unix());
    const [endDate, setEndDate] = useState(moment().unix());

    const handleStartChange = date => {
        const unixTime = moment(date).unix();
        setStartDate(unixTime);

        if (unixTime > endDate) {
            setEndDate(unixTime);
            return;
        }

        const unixTimeMs = unixTime * 1000;
        const endDateMs = endDate * 1000;

        if (moment(endDateMs).diff(unixTimeMs, 'days') > MAXIMUN_DIFF_DAY) {
            setEndDate(moment(unixTimeMs).add(MAXIMUN_DIFF_DAY, 'days').unix());
        }
    };

    const handleEndChange = date => {
        const unixTime = moment(date).unix();
        setEndDate(unixTime);
    };

    return {
        startDate: formatStartUnixTime(startDate),
        handleStartChange,
        endDate: formatEndUnixTime(endDate),
        handleEndChange,
    };
};

export default useRangeDateAM;
