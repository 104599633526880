import { getOwnershipListApi } from '@api/common/asset';
import { useAsync, useTranslation } from '@hooks';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { formatSingleOption, formatToSelectOptions } from '../../util/format';
import useAdminCheck from './useAdminCheck';

const MEDICAL_WASTE = 'MedicalWaste';

// Select에 들어갈 옵션(options), 선택한 부서와 set부서 반환
// Select의 value가 groupId가 아닌 groupNum일 땐 false
const useOptionsByRole = ({ isGroupId = true } = {}) => {
    const t = useTranslation(MEDICAL_WASTE);
    const { userInfo } = useSelector(state => state.UserInfo);
    const { groupInfoList } = userInfo;
    const userGroupInfo = groupInfoList[0];

    const isAdmin = useAdminCheck();

    // 관리자일 땐 모든 부서 중 선택
    const { state: wardState } = useAsync({
        promise: getOwnershipListApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        reject: err => console.error(err),
    });
    const rows = wardState.response ? wardState.response.rows : [];

    const key = isGroupId ? 'groupId' : 'groupNum';
    const adminWardOptions = formatToSelectOptions(rows, key);
    const userWardOptions = [formatSingleOption(userGroupInfo, key)];

    // 관리자, 일반 사용자 분기
    const options = isAdmin ? [{ value: null, label: t('All') }, ...adminWardOptions] : userWardOptions;

    const [selectedWard, setSelectedWard] = useState(options[0]);

    return {
        options,
        selectedWard,
        setSelectedWard,
    };
};

export default useOptionsByRole;
