import React from 'react';
import useTranslation from '@hooks/useTranslation';
import WidgetSettingModal from '../../Components/WidgetSettingModal';

const VitalSignMonitoringSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('VitalSignMonitoring');
    return (
        <WidgetSettingModal headerTitle={t('Vital Signs Monitoring Setting')} widgetInfo={widgetInfo} {...restProps} />
    );
};

export default VitalSignMonitoringSetting;
