import React, { useContext, useState } from 'react';
import { CategoryCode } from '../../Components/WidgetSettingOptions';
import { useSettings } from '../../util/useSettings';
import useTranslation from '@hooks/useTranslation';
import WidgetSettingModal from '../../Components/WidgetSettingModal';
import { editWidgetSettings } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import { ScreenEditDispatchContext } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit';

const ItemDetailStatusSetting = ({ widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { settings: customSettings } = config;
    const dispatch = useContext(ScreenEditDispatchContext);
    const t = useTranslation('ItemDetailStatus');

    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    const handleCategoryCodeChange = selectedCategoryCode => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, categoryCode: selectedCategoryCode };
        });
    };

    const handleSubmitClick = data => {
        const checkedSettings = customSettings
            ? { ...customSettings, ...data, ...updatedWidgetInfo }
            : { ...data, ...updatedWidgetInfo };

        const updatedWidgetSettings = {
            id: widgetInfo.id,
            settings: checkedSettings,
        };

        dispatch(editWidgetSettings(updatedWidgetSettings));
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    return (
        <WidgetSettingModal
            headerTitle={t('Iot Item Detail Status Setting')}
            widgetInfo={widgetInfo}
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <CategoryCode
                value={updatedWidgetInfo.categoryCode ? updatedWidgetInfo.categoryCode : settings.categoryCode}
                onChange={handleCategoryCodeChange}
            />
        </WidgetSettingModal>
    );
};

export default ItemDetailStatusSetting;
