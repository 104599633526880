import React from 'react';
import styled from 'styled-components';
import OutpatientAllProofCounts from './Components/OutpatientAllProofCounts';
import OutpatientDetailProofCounts from './Components/OutpatietDetailProofCounts';

const OutpatientProofStatusCount = ({ outpatientCount }) => {
    const { total, certificateTypeStat } = outpatientCount;
    return (
        <Container>
            <OutpatientAllProofCounts total={total} />
            <OutpatientDetailProofCounts certificateTypeStat={certificateTypeStat} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    width: 100%;
    & > div {
        &:nth-child(1) {
            flex: 1;
        }
        flex: 6;
    }
    margin-bottom: 5px;
`;

export default OutpatientProofStatusCount;
