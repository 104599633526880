import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { useAsync, useTranslation } from '@hooks';
import { fetchRealTimeSensorMonitoring, fetchSensingTypeMetas } from '@api/monitoring';
import { getMappedSensorStatusApi } from '@api/common/asset';
import Button from '@components/Button';

import SensorCount from './Components/SensorCount';
import SensorList from './Components/SensorList/SensorList';
import SensorDetailInfo from './Components/SensorDetailInfo';
import LocationPopup from './Components/SensorDetailInfo/Components/SensorReport/Components/LocationPopup';
import WidgetCard from '../../Components/WidgetCard';
import { useSettings } from '../../util/useSettings';
import { setSelectedSensor, setSelectedSensorStatusList, setSensingTypeOptions } from './sensorStatusSlice';
import useMonitor from '../../util/useMonitor';
import { useSensorStatusContext, useSensorStatusDispatch } from './index';

const defaultData = {
    sensorStatusCountStat: {
        normal: 11,
        warning: 11,
        critical: 18,
        outOfRange: 78,
        undefine: 50,
        total: 168,
    },
    sensorTargetCountStat: {
        mappedSensorCount: 52,
        mappedTargetCount: 43,
    },
};

const SensorStatusContainer = ({ widgetInfo, children, ...restProps }) => {
    const t = useTranslation('SensorStatus');
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const widgetRef = useRef();
    const { lang } = useSelector(state => state.UserInfo);
    const { selectedStatus, selectedSensor } = useSensorStatusContext();
    const dispatch = useSensorStatusDispatch();

    useEffect(() => {
        if (selectedStatus) {
            getRealTimeSensorMonitoring({ status: selectedStatus });
        }
    }, [selectedStatus]);

    const { promise: getSensingTypeMetas } = useAsync({
        promise: fetchSensingTypeMetas,
        resolve: ({ rows }) => {
            const filterRows = rows.filter(({ sensingType, language }) => sensingType && language);
            dispatch(setSensingTypeOptions(filterRows));
        },
        reject: error => console.error(error),
    });

    useEffect(() => {
        getSensingTypeMetas({ localType: lang });
    }, [lang]);

    const { promise: getRealTimeSensorMonitoring } = useAsync({
        promise: fetchRealTimeSensorMonitoring,
        fixedParam: { isAll: 'Y' },
        resolve: res => {
            dispatch(setSelectedSensorStatusList(res));
        },
    });

    const [statusCountData, setStatusCountData] = useState({
        prev: defaultData.sensorStatusCountStat,
        curr: defaultData.sensorStatusCountStat,
    });

    const [targetCountData, setTargetCountData] = useState({
        prev: { ...defaultData.sensorTargetCountStat },
        curr: { ...defaultData.sensorTargetCountStat },
    });

    useMonitor({
        config,
        defaultData,
        fetchData: getMappedSensorStatusApi,
        makeData: ({ newData }) => {
            const { sensorTargetCountStat, sensorStatusCountStat } = newData;
            setTargetCountData(prev => ({
                prev: { ...prev.curr },
                curr: { ...sensorTargetCountStat },
            }));

            setStatusCountData(prev => ({
                prev: { ...prev.curr },
                curr: { ...sensorStatusCountStat },
            }));
        },
    });

    const handleGoBackClick = () => {
        dispatch(setSelectedSensor(null));
    };

    return (
        <WidgetCard
            ref={widgetRef}
            widgetInfo={widgetInfo}
            bodyClassName="sensor-status-container"
            headerAction={
                <>
                    {selectedSensor && (
                        <Button className={'btn-gray'} onClick={handleGoBackClick}>
                            {t('Back', 'Button')}
                        </Button>
                    )}
                </>
            }
            {...restProps}
        >
            {!selectedSensor ? (
                <>
                    <Container className="grid-container">
                        <SensorCount targetCountData={targetCountData} statusCountData={statusCountData} />
                        <SensorList config={config} />
                    </Container>
                </>
            ) : (
                <>
                    <Container id="printArea" className={cx(selectedSensor && 'sensor-report-view')}>
                        <SensorDetailInfo />
                    </Container>
                    <LocationPopup cardRef={widgetRef} settings={settings} />
                </>
            )}

            {children}
        </WidgetCard>
    );
};

export default SensorStatusContainer;

const Container = styled.div`
    height: 100%;
    grid-template-rows: min-content;

    & > div {
        &:nth-child(1) {
            grid-column: 1 / 2;
        }

        &:nth-child(2) {
            grid-column: 2 / 4;
        }

        &:nth-child(3) {
            grid-column: 1 / 4;
            grid-row-end: fill;
        }
    }
`;
