import React, { useEffect, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { Label, LineChart } from '@components';

import { useTranslation } from '@hooks';
import NoDataBlock from '../../../Components/NoDataBlock';
import { UNIQUE_COUNT, VISIT_COUNT } from '../constants';

const LineChartByDate = ({ dataForEntryCount }) => {
    const t = useTranslation('HourlyCongestionStatus');
    const [chartData, setChartData] = useState({ datasets: [] });

    useEffect(() => {
        setChartData({
            labels: dataForEntryCount.map(({ date }) => date),
            datasets: [
                {
                    label: t(VISIT_COUNT),
                    data: dataForEntryCount.map(({ visitCount }) => visitCount),
                },
                {
                    label: t(UNIQUE_COUNT),
                    data: dataForEntryCount.map(({ uniqueCount }) => uniqueCount),
                },
            ],
        });
    }, [dataForEntryCount, t]);

    return (
        <div className={'flx-col'}>
            <Label name={t('Hourly entries count')} labelValueClassName={'pnt-txt txt-bold txt-dot'} />
            <div className={'h-100 bg-depth-2'}>
                {!!dataForEntryCount && dataForEntryCount.length ? (
                    <ReactResizeDetector refreshMode="debounce" refreshRate={100} handleHeight handleWidth>
                        <LineChart
                            data={chartData}
                            options={{
                                scales: {
                                    x: {
                                        offset: true,
                                    },
                                    y: {
                                        min: 0,
                                        ticks: { padding: 8 },
                                    },
                                },
                            }}
                        />
                    </ReactResizeDetector>
                ) : (
                    <NoDataBlock />
                )}
            </div>
        </div>
    );
};
export default LineChartByDate;
