import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { useTranslation } from '@hooks';
import { Button, DatePicker } from '@components';
import { Select } from '@components';
import SingleTreeSelect from '@components/Select/SingleTreeSelect';

import FilterSearchGroup from '../../../../MainPages/Components/FilterSearchGroup';
import { setEndDate, setSelectedCategory, setSelectedFloor, setStartDate } from '../congestedGeofenceSlice';
import { useTopFiveCongestedGeofenceContext, useTopFiveCongestedGeofenceDispatch } from '../index';
import SearchGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import SearchButtonArea from '../../../../MainPages/Components/FilterSearchGroup/Components/SearchButtonArea';
import { FilterList, SelectGroup } from '../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import FilterGroup from '../../../../MainPages/Components/FilterSearchGroup/Components/FilterGroup';

const CongestedGeofenceFilterGroup = ({ handleSearchClick }) => {
    const t = useTranslation('CongestionStatus');
    const [categoryListWithAllOption, setCategoryListWithAllOption] = useState([]);
    const { floorList } = useSelector(state => state.FloorInfo);
    const { categoryList } = useSelector(state => state.CategoryInfo);
    const { selectedCategory, selectedFloor, startDate, endDate } = useTopFiveCongestedGeofenceContext();
    const dispatch = useTopFiveCongestedGeofenceDispatch();

    const handleSelectCategoryChange = e => {
        dispatch(setSelectedCategory(e.categoryCode));
    };

    const handleYesterdayBtnClick = () => {
        dispatch(setStartDate(moment().subtract(1, 'days').startOf('day').valueOf()));
        dispatch(setEndDate(moment().subtract(1, 'days').endOf('day').valueOf()));
    };

    const handleTodayBtnClick = () => {
        dispatch(setStartDate(moment().startOf('day').valueOf()));
        dispatch(setEndDate(moment().endOf('day').valueOf()));
    };

    const handleFloorChange = selects => {
        if (selects) {
            dispatch(setSelectedFloor(selects.floorId));
        }
    };

    useEffect(() => {
        setCategoryListWithAllOption([{ categoryCode: '', categoryName: t('All') }, ...categoryList]);
    }, [categoryList]);

    // 시작날짜(시간)이 종료날짜(시간)를 넘어가는 경우 종료날짜를 시작날짜로 초기화
    useEffect(() => {
        if (startDate > endDate) {
            dispatch(setEndDate(startDate));
        }
    }, [startDate, endDate]);

    return (
        <FilterSearchGroup className={'card absolute-filter'}>
            <FilterGroup label={t('Filter')}>
                <SelectGroup>
                    <Select
                        placeholder={t('All')}
                        value={
                            categoryListWithAllOption.length === 0
                                ? []
                                : categoryListWithAllOption.find(category => category.categoryCode === selectedCategory)
                        }
                        onChange={handleSelectCategoryChange}
                        options={categoryListWithAllOption}
                        valueKey={'categoryCode'}
                        labelKey={'categoryName'}
                        listStyle={{ height: '200px', overflow: 'auto' }}
                    />
                </SelectGroup>
                <SelectGroup>
                    <SingleTreeSelect
                        data={floorList}
                        title={'Category'}
                        valueKey={'floorId'}
                        labelKey={'floorName'}
                        parentKey={'upperFloorId'}
                        selected={selectedFloor}
                        onChange={handleFloorChange}
                    />
                </SelectGroup>
            </FilterGroup>
            <SearchGroup label={t('Search')}>
                <FilterList>
                    <SelectGroup>
                        <DatePicker
                            value={startDate}
                            handleChange={selected => dispatch(setStartDate(selected))}
                            valueType={'ms'}
                            maxDate={endDate || moment().valueOf()}
                            showTimeInput
                        />
                    </SelectGroup>
                    <div> ~ </div>
                    <SelectGroup>
                        <DatePicker
                            value={endDate}
                            handleChange={selected => dispatch(setEndDate(selected))}
                            valueType={'ms'}
                            minDate={startDate}
                            maxDate={moment().valueOf()}
                            showTimeInput
                        />
                    </SelectGroup>
                    <SearchButtonArea>
                        <div className={'flx-row gap-1'}>
                            <Button className={'btn-gray btn-yesterday'} onClick={handleYesterdayBtnClick}>
                                {t('Yesterday', 'Button')}
                            </Button>
                            <Button className={'btn-gray btn-yesterday'} onClick={handleTodayBtnClick}>
                                {t('Today', 'Button')}
                            </Button>
                            <Button className="pnt-btn btn-secondary" onClick={handleSearchClick}>
                                {t('Search')}
                            </Button>
                        </div>
                    </SearchButtonArea>
                </FilterList>
            </SearchGroup>
        </FilterSearchGroup>
    );
};
export default CongestedGeofenceFilterGroup;
