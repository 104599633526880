import { useColumns, useTranslation } from '@hooks';
import React from 'react';

export const useBatteryStatusColumns = () => {
    const t = useTranslation('TagBatteryStatus');
    return useColumns(
        [
            {
                Header: 'Total',
                accessor: '0',
                className: 'text-ellipsis ',
                headerClassName: 'text-ellipsis',
                width: 50,
                Cell: ({ row: { original } }) => {
                    return (
                        <span>
                            {original[80] +
                                original[60] +
                                original[30] +
                                original[10] +
                                original[0] +
                                original['unknown']}
                        </span>
                    );
                },
            },
            {
                Header: 'Normal',
                accessor: '10',
                className: 'text-ellipsis',
                headerClassName: 'text-ellipsis',
                width: 50,
                Cell: ({ row: { original } }) => {
                    return <span>{original[80] + original[60]} </span>;
                },
            },
            {
                Header: 'Warning',
                accessor: '30',
                className: 'text-ellipsis',
                headerClassName: 'text-ellipsis',
                width: 50,
                Cell: ({ row: { original } }) => {
                    return <span>{original[30] + original[10]}</span>;
                },
            },
            {
                Header: 'Danger',
                accessor: '60',
                className: 'text-ellipsis',
                headerClassName: 'text-ellipsis',
                width: 50,
                Cell: ({ row: { original } }) => {
                    return <span>{original[0]}</span>;
                },
            },
            {
                Header: 'Unknown',
                accessor: 'Unknown',
                className: 'text-ellipsis',
                headerClassName: 'text-ellipsis',
                width: 50,
                Cell: ({ row: { original } }) => {
                    return <span className={'table-for-viewer'}>{original['unknown']}</span>;
                },
            },
        ],
        t,
    );
};
