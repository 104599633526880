import React from 'react';
import { LoadingBlock, Table } from '@components';
import { useColumns } from '@hooks';
import styles from '@asset/pnt/widgets/VitalSignsMonitoring.module.scss';
import cx from 'classnames';
import useTranslation from '@hooks/useTranslation';
import { DEFAULT_VALUE } from '../../constants';

const InfoTable = ({ sensorInfo, loading }) => {
    const t = useTranslation('CommonColumn');
    const {
        SOUND_NOISE = DEFAULT_VALUE,
        CO2 = DEFAULT_VALUE,
        TEMPERATURE = DEFAULT_VALUE,
        HUMIDITY = DEFAULT_VALUE,
    } = sensorInfo;

    const data = [
        {
            soundNoise: SOUND_NOISE,
            CO2: CO2,
            temperature: TEMPERATURE === DEFAULT_VALUE ? DEFAULT_VALUE : Number(TEMPERATURE).toFixed(1),
            humidity: HUMIDITY === DEFAULT_VALUE ? DEFAULT_VALUE : Number(HUMIDITY).toFixed(1),
        },
    ];

    const columns = useColumns(
        [
            {
                Header: t('Noise'),
                accessor: 'soundNoise',
                className: 'text-ellipsis',
                headerClassName: 'text-ellipsis',
                // width: 250,
            },
            {
                Header: t('Co2'),
                accessor: 'CO2',
                className: 'text-ellipsis',
                headerClassName: 'text-ellipsis',
                // width: 250,
            },
            {
                Header: t('Temperature'),
                accessor: 'temperature',
                className: 'text-ellipsis',
                headerClassName: 'text-ellipsis',
                // width: 250,
            },
            {
                Header: t('Humidity'),
                accessor: 'humidity',
                className: 'text-ellipsis',
                headerClassName: 'text-ellipsis',
                // width: 250,
            },
        ],
        t,
        [],
    );

    const isValidData = data => {
        return !!Object.values(...data).every(el => el === '-');
    };

    return (
        <div className={cx(styles.detail_info_table, 'mb-3')}>
            <LoadingBlock blocking={loading}>
                <Table data={{ rows: isValidData(data) ? [] : data }} columns={columns} paging={false} />
            </LoadingBlock>
        </div>
    );
};

export default InfoTable;
