import { useRef, useEffect, useMemo, useCallback } from 'react';
import { isDeepEqual } from '@util/common/util';

// 의존성 배열에 넣을 상태로 사용 가능한 타입
type AnyState = object | boolean | number | string | Function | undefined | null;
type DeepCompareDependencies = ReadonlyArray<AnyState>;

function deepCompareEquals(prevDep: AnyState, currDep: AnyState): boolean {
    if (prevDep === null || prevDep === undefined) {
        return !currDep;
    }
    switch (typeof currDep) {
        case 'object':
            if (typeof prevDep === 'object') {
                return isDeepEqual(prevDep, currDep);
            }
        case 'boolean':
        case 'number':
        case 'string':
            return prevDep === currDep;
        case 'function':
            return prevDep.toString() === currDep.toString();
        default:
            return false;
    }
}

function useDeepCompareMemoize(dependency: AnyState): AnyState {
    const ref = useRef<AnyState>();

    if (!deepCompareEquals(dependency, ref.current)) {
        ref.current = dependency;
    }

    return ref.current;
}

export const useDeepCompareEffect = (callback: () => void, dependencies?: DeepCompareDependencies) => {
    useEffect(callback, dependencies?.map(useDeepCompareMemoize));
};

export const useDeepCompareMemo = <T>(callback: () => T, dependencies: DeepCompareDependencies | undefined) => {
    return useMemo<T>(callback, dependencies?.map(useDeepCompareMemoize));
};

export const useDeepCompareCallback = <T extends (...args: any[]) => any>(
    callback: T,
    dependencies: DeepCompareDependencies,
) => {
    return useCallback<T>(callback, dependencies.map(useDeepCompareMemoize));
};
