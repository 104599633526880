import React, { useContext, useState } from 'react';
import { editWidgetSettings } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import { useSettings } from '../../util/useSettings';
import useTranslation from '@hooks/useTranslation';
import Label from '@components/Label';
import WidgetSettingModal from '../../Components/WidgetSettingModal';
import { ScreenEditDispatchContext } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit';
import { Select } from '@components';
import { HiddenMapTile } from '../../Components/WidgetSettingOptions';

const IotItemStatusSetting = ({ widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const { settings: customSettings } = config;
    const dispatch = useContext(ScreenEditDispatchContext);
    const settings = useSettings(config);

    const t = useTranslation('IotItemStatus');

    const categoryOptions = [
        { value: 'ASSET', label: t('asset') },
        { value: 'PATIENT', label: t('patient') },
        { value: 'PROTECTOR', label: t('protector') },
    ];

    const handleSubmitClick = data => {
        const checkedSettings = customSettings
            ? { ...customSettings, ...data, ...updatedWidgetInfo }
            : { ...data, ...updatedWidgetInfo };

        const updatedWidgetSettings = {
            id: widgetInfo.id,
            settings: checkedSettings,
        };

        dispatch(editWidgetSettings(updatedWidgetSettings));
    };

    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});
    const handleCategoryChange = selectedCategory => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, categoryCode: selectedCategory.value };
        });
    };

    const handleHiddenMapTile = checkedHidden => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, hiddenMapTile: checkedHidden };
        });
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    const CategoryValue = categoryOptions.find(e =>
        updatedWidgetInfo.categoryCode ? e.value === updatedWidgetInfo.categoryCode : e.value === settings.categoryCode,
    );
    return (
        <WidgetSettingModal
            headerTitle={t('Iot Item Status Setting')}
            widgetInfo={widgetInfo}
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <HiddenMapTile
                updateWidgetInfo={updatedWidgetInfo}
                customSettings={customSettings}
                onChange={handleHiddenMapTile}
            />
            <Label
                name={t('Type of Category', 'CategoryChart')}
                labelGroupClassName={'w-100'}
                value={
                    <Select
                        options={categoryOptions}
                        value={CategoryValue}
                        onChange={handleCategoryChange}
                        isModalSelect
                    />
                }
            />
        </WidgetSettingModal>
    );
};

export default IotItemStatusSetting;
