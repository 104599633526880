import React from 'react';
import { Table } from '@components';
import { useColumns, useTranslation } from '@hooks';
import * as column from '@util/grid/column';
import styled from 'styled-components';
import NoDataBlock from '../../../Components/NoDataBlock';
import { MEDICAL_WASTE } from '../../../constants';
import moment from 'moment';

const WIDTH = { width: 100 };

const MedicalWasteList = ({ data }) => {
    const t = useTranslation(MEDICAL_WASTE);

    const columns = useColumns(
        [
            column.date({
                Cell: ({ row }) => (
                    <div>
                        {moment.unix(row.original.startDate).format('YYYY-MM-DD')}~
                        {moment.unix(row.original.endDate).format('YYYY-MM-DD')}
                    </div>
                ),
            }),
            column.billed({ ...WIDTH }),
            column.total({ ...WIDTH }),
            column.isolateMedical({ ...WIDTH }),
            column.normalMedical({ ...WIDTH }),
            column.tissue({ ...WIDTH }),
            column.damage({ ...WIDTH }),
            column.pathology({ ...WIDTH }),
            column.biochemistry({ ...WIDTH }),
            column.bloodContamination({ ...WIDTH }),
            column.humanTissue({ ...WIDTH }),
            column.weight({ ...WIDTH }),
        ],
        t,
        [data],
    );

    if (!data) {
        return (
            <BodyContainer className="mt-3">
                <NoDataBlock />
            </BodyContainer>
        );
    }

    return (
        <BodyContainer className="mt-3">
            <Table columns={columns} data={{ ...data, rows: data }} paging={false} />
        </BodyContainer>
    );
};

const BodyContainer = styled.div`
    height: 11.6rem;
`;

export default MedicalWasteList;
