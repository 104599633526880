import React, { useMemo } from 'react';
import { Table } from '@components';
import { useTranslation } from '@hooks';
import styled from 'styled-components';
import NoDataBlock from '../../../Components/NoDataBlock';
import { MEDICAL_WASTE, WASTE_TYPE_LABEL } from '../../../constants';

const MedicalWasteList = ({ rows }) => {
    const t = useTranslation(MEDICAL_WASTE);

    const columns = useMemo(
        () => [
            {
                Header: t('Ward'),
                accessor: 'wardName',
            },
            {
                Header: t('Waste type'),
                Cell: ({ row }) => <div>{t(WASTE_TYPE_LABEL[row.original.wasteType])}</div>,
            },
            {
                Header: t('Collectible quantity'),
                Cell: ({ row }) => <div>{t(row.original.collectables)}</div>,
            },
            {
                Header: t('Quantity collected'),
                Cell: ({ row }) => <div>{t(row.original.collectCompleted)}</div>,
            },
        ],
        [t],
    );

    if (!rows) {
        return (
            <BodyContainer className="mt-3">
                <NoDataBlock />
            </BodyContainer>
        );
    }

    return <BodyContainer className="mt-3">{<Table columns={columns} data={{ rows }} paging={false} />}</BodyContainer>;
};

const BodyContainer = styled.div`
    height: 27.5rem;
`;

export default MedicalWasteList;
