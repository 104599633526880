export const DEFAULT_COUNT = 0;
export const DEFAULT_LABEL_VALUE = '-';
export const TAG_MAPPED_STATUS = 'isTagMapped';

export const DEFAULT_PARAMETERS = {
    pageSize: 15,
    page: 1,
    // 자산 태그 매핑 포함 여부 (포함 : Y, 미포함 : N)
    isIncludeConfig: 'Y',
};

export const DUMMY_COUNT_RESPONSE = {
    totalCount: 0,
    beaconMappedCount: 0,
    restrictedCount: 0,
    lostSignalCount: 0,
    lowBatteryCount: 0,
    neverDetectedCount: 0,
};

export const DUMMY_LIST_RESPONSE = {
    pageSize: 15,
    totalCount: 0,
    totalPage: 1,
    rows: [],
};

export const SUMMARY_LIST = [
    {
        value: null,
        label: 'Total Asset',
        key: 'totalCount',
        bubbleText: 'Total registered assets',
    },
    {
        value: 'isTagMapped',
        label: 'Asset with tag',
        key: 'beaconMappedCount',
        bubbleText: 'Assets that can be tracked',
    },
    {
        value: 'isUnPermitted',
        label: 'Out of Authorized Zone',
        key: 'restrictedCount',
        bubbleText: 'Assets outside of permitted areas',
    },
    {
        value: 'isLostSignal',
        label: 'Lost Signal',
        key: 'lostSignalCount',
        bubbleText: 'Assets temporarily without signals',
    },
    {
        value: 'isLowBattery',
        label: 'Low Battery',
        key: 'lowBatteryCount',
        bubbleText: 'Assets requiring battery replacement',
    },
    {
        value: 'isNeverDetected',
        label: 'No Response',
        key: 'neverDetectedCount',
        bubbleText: 'Assets for which no signal has ever been collected',
    },
];

export const SIGNAL_STATUS = {
    NORMAL: 'Normal',
    OUT_OF_AUTHORIZED_ZONE: 'Out of Authorized Zone',
    LOST_SIGNAL: 'Lost Signal',
    LOW_BATTERY: 'Low Battery',
    NO_RESPONSE: 'No Response',
};

export const SPECIFIC_COLUMNS = ['model_nu', 'risk_level', 'ward', 'status'];
export const ASSET_CODE = 'ASSET';
