import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal, TextInput } from '@components';
import { useAppSelector, useAsync, useTranslation } from '@hooks';
import { updatePassword } from '@api/common/login';
import dangerTriangle from '@asset/images/dangerTriangle.svg';
import useMoreUserInfo from '../../Login/hooks/useMoreUserInfo';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import cx from 'classnames';

const pwUpperCaseRegExp = /[A-Z]/;
const pwLowerCaseRegExp = /[a-z]/;
const pwNumberRegExp = /[0-9]/;
const pwSpecialSymbolRegExp = /[!@#$%^&*]/;
const pwLengthRegExp = /^.{8,30}$/;

const PW_MAX_LENGTH = 30;

const VALIDATIONS = [
    { label: 'English uppercase letters', regExp: pwUpperCaseRegExp, result: false },
    { label: 'English lowercase letters', regExp: pwLowerCaseRegExp, result: false },
    { label: 'Number', regExp: pwNumberRegExp, result: false },
    { label: 'Special Character(!@#$%^&*)', regExp: pwSpecialSymbolRegExp, result: false },
    { label: '8 ~ 30 characters', regExp: pwLengthRegExp, result: false },
];

const ValidationCheck = ({ checked, label, key }) => {
    return (
        <div className={cx('d-flex pnt-txt gap-2', checked ? 'color-secondary' : 'color-gray')} key={key}>
            <div className={cx('material-icons-round', checked ? 'color-secondary' : 'color-gray')}>check</div>
            <div className={cx('d-flex align-items-center', checked ? 'color-secondary' : 'color-gray')}>{label}</div>
        </div>
    );
};

const TemporaryPasswordModal = () => {
    const t = useTranslation('TemporaryPasswordModal');
    const { userInfo } = useAppSelector(state => state.UserInfo);
    const [initModal, setInitModal] = useState(false);
    const [password, setPassword] = useState({ new: '', check: '' });
    const [passwordValidation, setPasswordValidation] = useState(VALIDATIONS);
    const [changeSuccessModal, setChangeSuccessModal] = useState(false);
    const updateUserInfo = useMoreUserInfo();

    const handlePasswordChange = event => {
        const { name, value } = event.currentTarget;
        setPassword({ ...password, [name]: value });
    };

    // 새 비밀번호 와 확인 비밀번호가 같음을 확인 (확인 비밀번호가 입력 된 이후 확인 시작)
    const checkNotEqual = useMemo(() => {
        if (password.check !== '') {
            return password.new !== password.check;
        }
        return false;
    }, [password.new, password.check]);

    useEffect(() => {
        // 비밀번호 유효성 검사
        setPasswordValidation(prev =>
            prev.map(value => {
                if (value.regExp.test(password.new)) {
                    return { ...value, result: true };
                }
                return { ...value, result: false };
            }),
        );
    }, [password.new]);

    const { promise: changePassword } = useAsync({
        promise: updatePassword,
        resolve: () => setChangeSuccessModal(prev => !prev),
        reject: err => console.error(err),
    });

    // 새로고침 시 모달 오픈
    useEffect(() => {
        setInitModal(userInfo?.tempPW === 'Y');
    }, [userInfo]);

    return (
        <>
            <Modal
                size={'md'}
                initModal={initModal}
                toggleModal={() => setInitModal(prev => !prev)}
                keyboard={false}
                modalHeader={
                    <ModalHeader className={'bg-depth-4 flx-col border-0 bg-white'}>
                        <img src={dangerTriangle} alt={'/'} />
                        <div className={'fw-bold py-2'}>{t('Change the password.')}</div>
                        <div className={'flx-col pnt-txt gap-1'}>
                            <div>{t('You logged in with a temporarily password.')}</div>
                            <div>{t('Please change it according to the rules below and use it.')}</div>
                            <div>
                                {t(
                                    '(8 ~ 30 characters of a combination of English uppercase, lowercase letters, number and Special Character(!@#$%^&*))',
                                )}
                            </div>
                        </div>
                    </ModalHeader>
                }
                modalFooter={
                    <ModalFooter>
                        <Button
                            className={cx('p-3 btn-secondary')}
                            onClick={() => changePassword({ userID: userInfo.userID, changePW: password.check })}
                            disabled={
                                !!checkNotEqual ||
                                !passwordValidation.every(({ result }) => result) ||
                                password.check === ''
                            }
                            iconClassName={cx('material-icons-round')}
                            iconName={'check'}
                        >
                            {t('Save')}
                        </Button>
                    </ModalFooter>
                }
            >
                <ModalBody className={'flx-col gap-4'}>
                    <div className={'flx-col'}>
                        <div className={'fw-bold fs-6'}>{t('Password')}</div>
                        <TextInput
                            type={'password'}
                            name={'new'}
                            inputGroupClassName={'w-100'}
                            placeholder={t('Enter password.')}
                            value={password.new}
                            handleChange={handlePasswordChange}
                            maxlength={PW_MAX_LENGTH}
                        />
                        <div className={'flx-col gap-0'}>
                            {passwordValidation.map((validation, index) => (
                                <ValidationCheck
                                    label={t(validation.label)}
                                    checked={validation.result}
                                    key={`${index}_${validation.label}`}
                                />
                            ))}
                        </div>
                    </div>
                    <div className={'flx-col mb-4'}>
                        <div className={'d-flex align-items-center'}>
                            <div className={'fw-bold fs-6'}>{t('Confirm Password')}</div>
                            {checkNotEqual && (
                                <div className={'ml-2 fw-bold color-danger'}>* {t('Enter password again.')}</div>
                            )}
                        </div>
                        <TextInput
                            type={'password'}
                            name={'check'}
                            inputGroupClassName={'w-100'}
                            placeholder={t('Enter password.')}
                            value={password.check}
                            handleChange={handlePasswordChange}
                            maxlength={PW_MAX_LENGTH}
                        />
                    </div>
                </ModalBody>
            </Modal>
            {/* 변경 성공 */}
            <Modal
                initModal={changeSuccessModal}
                toggleModal={() => setChangeSuccessModal(prev => !prev)}
                okCallback={() => {
                    setInitModal(prev => !prev);
                    updateUserInfo();
                }}
                bodyText={t('Password has been changed successfully.')}
                modalHeader={<ModalHeader className={'bg-depth-4'}>{t('Alert')}</ModalHeader>}
                removeCancel
                zIndex={1051}
                keyboard={false}
            />
        </>
    );
};

export default TemporaryPasswordModal;
