import React, { useRef } from 'react';
import { useAsync, useConfirmModal, useTranslation } from '@hooks';
import { createIotItemMappingExcelApi, fetchExcelLogApi } from '@api/common/log';
import moment from 'moment';
import { Button } from '@components';
import { useIotItemMappingLogContext } from '../index';
import { makeParameter } from '../../../util/commons';
import { excelExport } from '../../../util/excelExport';

const INTERVAL_TIME = 1000;

/**
 * Iot 아이템 맵핑 로그 엑셀 내보내기 기능
 *
 * @param {number} listCount 리스트 카운트
 *
 * @author jinbeom-jing
 *
 * */

const ExcelExport = ({ listCount }) => {
    const { keyword, searchOption } = useIotItemMappingLogContext();
    const t = useTranslation('Iot Item Mapping Log');
    const excelTimerRef = useRef({});

    const { promise: createIotItemMappingExcel } = useAsync({
        promise: createIotItemMappingExcelApi,
        fixedParam: {
            zipFileName: 'targetDevice_history',
            isAll: 'Y',
            startDate: 0,
            endDate: moment().unix(),
            fileName: 'targetDevice_history',
            columnMetas: [
                {
                    key: 'logNum',
                    name: t('No.', 'CommonColumn'),
                },
                {
                    key: 'manipulationType',
                    name: t('Map/Unmap', 'CommonColumn'),
                },
                {
                    key: 'targetNum',
                    name: t('IoT Item No.', 'CommonColumn'),
                },
                {
                    key: 'targetId',
                    name: t('IoT Item ID', 'CommonColumn'),
                },
                {
                    key: 'targetName',
                    name: t('IoT Item Name', 'CommonColumn'),
                },
                {
                    key: 'deviceNum',
                    name: t('Device No.', 'CommonColumn'),
                },
                {
                    key: 'deviceId',
                    name: t('Device ID', 'CommonColumn'),
                },
                {
                    key: 'deviceName',
                    name: t('Device Name', 'CommonColumn'),
                },
                {
                    key: 'deviceType',
                    name: t('Device Type', 'CommonColumn'),
                },
                {
                    key: 'regDate',
                    name: t('Map/Unmap Date', 'CommonColumn'),
                    isDateFormat: 'Y',
                },
            ],
        },
        resolve: res => {
            const { filePath: url, reqNum } = res;
            if (url) {
                downloadWithURL({ url, reqNum });
            }
        },
    });
    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            const { dataSettingStatusType, reqNum, filePath, fileName } = res;
            if (dataSettingStatusType === 'COMPLETED') {
                excelExport(filePath, fileName);
            } else if (dataSettingStatusType === 'FAILED') {
                toggleExcelDownloadResultModal();
            }
            clearExcelTimer({ reqNum });
        },
        reject: err => console.error(err),
    });

    const handleExportFileClick = () => {
        if (listCount) {
            const result = makeParameter({
                searchRequirement: {
                    keyword,
                    opt: searchOption.value,
                },
            });
            createIotItemMappingExcel(result);
        } else {
            toggleExcelConfirmModal();
        }
    };

    const downloadWithURL = ({ reqNum }) => {
        excelTimerRef.current[reqNum] = setInterval(() => {
            fetchExcelLog({ reqNum });
        }, INTERVAL_TIME);
    };

    const clearExcelTimer = ({ reqNum }) => {
        clearInterval(excelTimerRef.current[reqNum]);
        excelTimerRef.current[reqNum] = null;
    };

    const { Modal: ExcelDownloadResultModal, toggleModal: toggleExcelDownloadResultModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Result', 'Report') },
        confirmText: t('Failed to download the excel.', 'Report'),
    });

    const { Modal: ExcelConfirmModal, toggleModal: toggleExcelConfirmModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        header: { title: t('Excel Download', 'Report') },
        confirmText: t('No data', 'Report'),
    });

    return (
        <>
            <Button className="btn-lightgray btn-icon" onClick={handleExportFileClick}>
                <span className={'material-icons-round'}>file_upload</span>
                {t('Export', 'Button')}
            </Button>
            <ExcelDownloadResultModal />
            <ExcelConfirmModal />
        </>
    );
};

export default ExcelExport;
