import { useSelector } from 'react-redux';

const ADMIN_ROLE = 'A';

const useAdminCheck = () => {
    const { userInfo } = useSelector(state => state.UserInfo);
    const { serviceProductRoleList } = userInfo;
    const isAdmin = serviceProductRoleList.some(ele => ele.serviceRole === ADMIN_ROLE);

    return isAdmin;
};

export default useAdminCheck;
