import React, { useRef } from 'react';
import { getOutpatientUsageDept, postOutpatientUsageDeptExcel } from '@api/so/outpatient';
import { useAsync, useTranslation } from '@hooks';
import InsightBox, { BoxBodyText, HighlightedText } from '../Components/InsightBox';
import WidgetCard from '../../Components/WidgetCard';
import useDateTypeOptions from '../util/useDateTypeOptions';
import Chart from './Chart';
import { useAsyncExcel } from '../../util/useAsyncExcel';
import useRangeDateSO from '../util/useRangeDateSO';
import SearchGroupSO from '../Components/SearchGroupSO';
import { isValidData } from '../util/checkData';
import NoDataBlock from '../../Components/NoDataBlock';
import { Trans } from 'react-i18next';
import moment from 'moment';

const OutpatientUsageAnalysisByDepartment = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();
    const t = useTranslation('OutpatientReport');

    const dateTypeValues = useDateTypeOptions();
    const dateValues = useRangeDateSO(dateTypeValues.dateType.value);

    const { state, promise: getData } = useAsync({
        promise: getOutpatientUsageDept,
        immediate: true,
        param: {
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
        },
        reject: err => console.error(err),
    });
    const data = state.response ? state.response.rows : [];
    const { insightDepartments = [] } = state.response ? state.response.additionalDatas : {};
    const [dept1, dept2] = insightDepartments;

    const triggerDownload = useAsyncExcel({
        promise: postOutpatientUsageDeptExcel,
        param: {
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
            columnMetas: [
                {
                    key: 'departmentName',
                    name: t('Department name'),
                },
                {
                    key: 'departmentCode',
                    name: t('Department code'),
                },
                {
                    key: 'patientCount',
                    name: t('Patient count'),
                },
                {
                    key: 'kioskCount',
                    name: t('Kiosk count'),
                },
            ],
            fileName:
                'so_kioskData_' +
                moment.unix(dateValues.startDate).format('YYYYMMDD') +
                '_' +
                moment.unix(dateValues.endDate).format('YYYYMMDD'),
            zipFileName: 'so_usage_by_department',
        },
        reject: err => console.error(err),
    });

    const handleDownloadClick = () => {
        triggerDownload();
    };

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <SearchGroupSO showDateType dateTypeValues={dateTypeValues} dateValues={dateValues} getData={getData} />
            {isValidData(data) ? (
                <>
                    <InsightBox>
                        <BoxBodyText>
                            <Trans t={t} values={{ dept1, dept2 }} components={[<HighlightedText />]}>
                                {`Smart outpatient utilization, such as arrival notification using kiosks during the period you viewed, was most utilized by department, in that order, <0>{{dept1}}</0> and then <0>{{dept2}}</0>.`}
                            </Trans>
                        </BoxBodyText>
                    </InsightBox>
                    <Chart data={data} onDownloadClick={handleDownloadClick} />
                </>
            ) : (
                <NoDataBlock text={t('No data')} />
            )}
            {children}
        </WidgetCard>
    );
};

export default OutpatientUsageAnalysisByDepartment;
