import React from 'react';
import { CheckboxInputProps } from '@components/Input/Checkbox';
import { RequiredKeys } from '@util/type/util';
import cx from 'classnames';

interface ToggleCheckboxInputProps extends RequiredKeys<CheckboxInputProps, 'name'> {}

const ToggleCheckbox = ({
    name,
    id,
    className,
    disabled,
    checked,
    handleChecked,
    inputName,
    ...restProps
}: ToggleCheckboxInputProps) => {
    return (
        <label
            className={cx('pnt-toggle-checkbox', checked && 'check-checked', disabled && 'form-disable', className)}
            htmlFor={id}
        >
            <input type="checkbox" id={id} name={inputName} onChange={handleChecked} checked={checked} {...restProps} />
            <p>{name}</p>
        </label>
    );
};

export default ToggleCheckbox;
