import React, { useEffect, useState } from 'react';
import DatePicker from '@components/DatePicker';
import Button from '@components/Button';
import moment from 'moment';
import FilterSearchGroup from '../../../../../../MainPages/Components/FilterSearchGroup';
import { SearchWrap, SelectGroup } from '../../../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import SearchGroup from '../../../../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';

const DAY_WITH_UNIX = 86400;

const Search = ({ date, onChange, loading }) => {
    const [today, setToday] = useState(moment().format('YYYY-MM-DD'));
    const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
    useEffect(() => {
        setToday(moment().format('YYYY-MM-DD'));
        setSelectedDate(moment.unix(date).format('YYYY-MM-DD'));
    }, [date]);

    return (
        <FilterSearchGroup className="p-0 mb-3">
            <SearchGroup>
                <SearchWrap>
                    <SelectGroup>
                        <DatePicker
                            value={date}
                            handleChange={selected => onChange(selected)}
                            maxDate={moment.now()}
                            withoutTime
                        />
                    </SelectGroup>
                    <Button
                        className="btn-icon-only btn-gray ml-2"
                        iconName="keyboard_arrow_left"
                        onClick={() => onChange(date - DAY_WITH_UNIX)}
                        disabled={loading}
                    />
                    <Button
                        className={'btn-icon-only btn-gray ml-1'}
                        iconName="keyboard_arrow_right"
                        onClick={() => onChange(date + DAY_WITH_UNIX)}
                        disabled={loading || selectedDate === today}
                    />
                </SearchWrap>
            </SearchGroup>
        </FilterSearchGroup>
    );
};

export default Search;
