import React from 'react';
import Button from '@components/Button';
import { useColumns, useTranslation } from '@hooks';
import { Modal, Table } from '@components';
import Label from '@components/Label';
import styled from 'styled-components';
import * as column from './Components/column';
import { useOutpatientCareStatusContext } from './index';
import moment from 'moment';

const MonitoringModal = ({ modal, setModal }) => {
    const t = useTranslation('OutpatientCareStatus');
    const { selectedOutpatient } = useOutpatientCareStatusContext();
    const { careStatus, todayPatientVitality, previousPatientVitality } = selectedOutpatient;

    const data = [
        {
            name: 'Highest Blood Pressure',
            today: todayPatientVitality.maxBloodPressure,
            before: previousPatientVitality.maxBloodPressure,
        },
        {
            name: 'Lowest Blood Pressure',
            today: todayPatientVitality.minBloodPressure,
            before: previousPatientVitality.minBloodPressure,
        },
        {
            name: 'Heart Rate(bpm)',
            today: todayPatientVitality.heartbeat,
            before: previousPatientVitality.heartbeat,
        },
        {
            name: 'Height(cm)',
            today: todayPatientVitality.height,
            before: previousPatientVitality.height,
        },
        {
            name: 'Weight(kg)',
            today: todayPatientVitality.weight,
            before: previousPatientVitality.weight,
        },
    ];

    const toggleModal = () => {
        setModal(!modal);
    };
    const handleCloseModal = () => {
        toggleModal();
    };

    const columns = useColumns(
        [
            column.patientInfoName({ width: 170, Cell: ({ value }) => <span>{t(value)}</span> }),
            column.todayPatientInfo(),
            column.previousPatientInfo(),
        ],
        t,
        [],
    );

    return (
        <>
            <Modal
                initModal={modal}
                toggleModal={toggleModal}
                headerTitle={t('Outpatient')}
                modalFooter={
                    <div className="modal-footer" style={{ justifyContent: 'center' }}>
                        <Button className="btn-secondary" onClick={handleCloseModal}>
                            {t('OK', 'ConfirmModal')}
                        </Button>
                    </div>
                }
            >
                <div className="modal-body">
                    <CareInfoContainer>
                        <LowCaseContainer className="flx-col gap-3 pnt-label-8">
                            <Label
                                name={t('Date of appointment')}
                                value={
                                    <span className="pnt-txt txt-bold">
                                        {selectedOutpatient &&
                                            `${moment.unix(careStatus.appointmentDate).format('YYYY-MM-DD')} 
                                             ${moment.unix(careStatus.appointmentTime).format('hh:mm')}시`}
                                    </span>
                                }
                            />
                        </LowCaseContainer>
                        <LowCaseContainer className="flx-col gap-3 pnt-label-8">
                            <Label
                                name={t('Medical specialty')}
                                value={
                                    <span className="pnt-txt txt-bold">
                                        {selectedOutpatient && careStatus.department}
                                    </span>
                                }
                            />
                        </LowCaseContainer>
                        <LowCaseContainer className="flx-col gap-3 pnt-label-8">
                            <Label
                                name={t('Doctor')}
                                value={
                                    <span className="pnt-txt txt-bold">{selectedOutpatient && careStatus.doctor}</span>
                                }
                            />
                        </LowCaseContainer>
                    </CareInfoContainer>
                    <PatientInfoContainer>
                        <LowCaseContainer className="flx-col gap-3 pnt-label-8">
                            <Label
                                name={t('Patient name (registration number)')}
                                value={
                                    <span className="pnt-txt txt-bold">
                                        {selectedOutpatient &&
                                            `${careStatus.patientName} (${careStatus.patientRegNum})`}
                                    </span>
                                }
                            />
                        </LowCaseContainer>
                        <LowCaseContainer className="flx-col gap-3 pnt-label-8">
                            <Label
                                name={t('Age')}
                                value={<span className="pnt-txt txt-bold">{selectedOutpatient && careStatus.age}</span>}
                            />
                        </LowCaseContainer>
                        <LowCaseContainer className="flx-col gap-3 pnt-label-8">
                            <Label
                                name={t('Sex')}
                                value={
                                    <span className="pnt-txt txt-bold">
                                        {selectedOutpatient && careStatus.sex === 'M' ? t('Male') : t('Female')}
                                    </span>
                                }
                            />
                        </LowCaseContainer>
                    </PatientInfoContainer>
                    <TableInfoContainer>
                        <Table data={{ rows: data }} columns={columns} paging={false} />
                    </TableInfoContainer>
                </div>
            </Modal>
        </>
    );
};
const LowCaseContainer = styled.div`
    & * {
        text-transform: unset !important;
    }
`;

const CareInfoContainer = styled.div`
    border-bottom: 1px solid black;
    padding: 10px 0;
`;
const PatientInfoContainer = styled.div`
    padding: 10px 0;
`;

const TableInfoContainer = styled.div`
    margin-top: 10px;
    //width: 1000px;
    height: 260px;
`;
export default MonitoringModal;
