import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@hooks';
import InfoTable from './InfoTable';
import moment from 'moment';
import useAsync from '@hooks/useAsync';
import { getVitalSignsDetailGraphApi } from '@api/monitoring';
import useTranslation from '@hooks/useTranslation';
import { BarChart, LineChart } from '@components';
import Search from './Search';
import NoDataBlock from '../../../../../Components/NoDataBlock';
import styles from '@asset/pnt/widgets/VitalSignsMonitoring.module.scss';
import { mergeObjects } from '@util/common/util';
import cx from 'classnames';

const SENSOR_TYPE = {
    BLOODPRESSURE: 'BP',
    AVERAGE: 'AVG',
};
const GUARANTEED_SENSOR_TYPE = ['PR', 'BT'];
const GRAPH_AREA = {
    WIDTH: 1250,
    HEIGHT: 300,
    MARGIN_TOP: 5,
    MARGIN_RIGHT: 20,
    MARGIN_BOTTOM: 40,
    MARGIN_LEFT: 0,
};
const LABEL_AREA = {
    TOP: 32,
};
const BAR_CHART_COLOR = '#8884d8';
const LINE_CHART_COLOR = '#82ca9d';
const WHITE_COLOR = '#fff';
const GRID_GRAY_COLOR = '#ccc';
const TOOLTIP_BG_COLOR_DARK = '#4c4c52';
const TOOLTIP_BG_COLOR_NAVY = '#e8e6ef';
const TOOLTIP_TEXT_COLOR = '#565672';
const TEXT_BOLD = 'bold';
const DARK_THEME = 'pnt-dark';
const TICK_LIMITS = 16;

const formatBpSensorList = (list = [], t) => {
    let name;
    let unit;
    let min = Number.MAX_SAFE_INTEGER;
    let max = Number.MIN_SAFE_INTEGER;
    const dataList = list.sort((a, b) => a.regDate - b.regDate);
    const stepSize = Math.min(TICK_LIMITS, dataList.length);
    const startTime = dataList[0]?.regDate * 1000;
    const endTime = dataList[dataList.length - 1]?.regDate * 1000;
    const offset = Math.floor((endTime - startTime) / stepSize);

    const data = dataList.reduce(
        (acc, value, index) => {
            const { low, mid, high, sensingType, regDate, measurementUnit } = value;
            name = sensingType;
            unit = measurementUnit;
            min = Math.min(low, mid, high, min);
            max = Math.max(low, mid, high, max);

            if (index < TICK_LIMITS && !isNaN(startTime) && !isNaN(endTime)) {
                acc['labels'].push(startTime + index * offset);
            }
            // bar data
            acc['datasets'][0].data.push({ x: regDate * 1000, y: [Number(low), Number(high)] });
            // line data
            acc['datasets'][1].data.push({ x: regDate * 1000, y: Number(mid) });
            return acc;
        },
        {
            labels: [],
            datasets: [
                {
                    label: t(SENSOR_TYPE.BLOODPRESSURE),
                    data: [],
                    type: 'bar',
                    forcedBorderColor: BAR_CHART_COLOR,
                    forcedBackgroundColor: BAR_CHART_COLOR,
                    order: 1,
                },
                {
                    label: t(SENSOR_TYPE.AVERAGE),
                    data: [],
                    type: 'line',
                    forcedPointBackgroundColor: WHITE_COLOR,
                    forcedBorderColor: LINE_CHART_COLOR,
                    forcedPointBorderColor: LINE_CHART_COLOR,
                    forcedBackgroundColor: LINE_CHART_COLOR,
                    order: 0,
                },
            ],
        },
    );

    return { name, unit, data, min, max };
};
const formatSensorList = ({ PULSE_RATE: pulseRateList = [], TEMPERATURE: temperatureList = [] }, t) => {
    const lists = [pulseRateList, temperatureList]; // 맥박-체온 순서 보장

    return lists.map((list, index) => {
        let name;
        let unit;
        let min = Number.MAX_SAFE_INTEGER;
        let max = Number.MIN_SAFE_INTEGER;
        const stepSize = Math.min(TICK_LIMITS, list.length);
        const startTime = list[0]?.regDate * 1000;
        const endTime = list[list.length - 1]?.regDate * 1000;
        const offset = Math.floor((endTime - startTime) / stepSize);

        const data = list.reduce(
            (acc, value, listIndex) => {
                const { currentValue, regDate, sensingType, measurementUnit } = value;
                name = sensingType;
                unit = measurementUnit;
                min = Math.min(min, currentValue);
                max = Math.max(max, currentValue);

                if (listIndex < TICK_LIMITS && !isNaN(startTime) && !isNaN(endTime)) {
                    acc['labels'].push(startTime + listIndex * offset);
                }
                acc['datasets'][0].data.push({ x: regDate * 1000, y: Number(currentValue) });
                return acc;
            },
            {
                labels: [],
                datasets: [
                    {
                        label: t(GUARANTEED_SENSOR_TYPE[index]),
                        data: [],
                        forcedPointBackgroundColor: WHITE_COLOR,
                        forcedBorderColor: LINE_CHART_COLOR,
                        forcedPointBorderColor: LINE_CHART_COLOR,
                        forcedBackgroundColor: LINE_CHART_COLOR,
                    },
                ],
            },
        );

        return { name, unit, data, min, max };
    });
};

const DEFAULT_CHART_OPTIONS = {
    animation: false,
    parser: false,
    spanGaps: true,
    layout: {
        padding: {
            top: GRAPH_AREA.MARGIN_TOP,
            left: GRAPH_AREA.MARGIN_LEFT,
            right: GRAPH_AREA.MARGIN_RIGHT,
        },
    },
    plugins: {
        legend: {
            position: 'top',
            align: 'end',
        },
        tooltip: {
            mode: 'index',
            intersect: false,
            animation: { duration: 0 },
            displayColors: false,
            padding: 12,
            titleFont: {
                weight: TEXT_BOLD,
            },
            bodyFont: {
                weight: TEXT_BOLD,
            },
        },
    },
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'second',
                tooltipFormat: 'HH:mm:ss',
                displayFormats: { second: 'HH:mm:ss' },
            },
            display: true,
            grid: {
                borderDash: [4, 4],
                display: true,
                tickLength: LABEL_AREA.TOP,
                offset: false,
                color: GRID_GRAY_COLOR,
            },
            ticks: {
                minRotation: 45,
                source: 'labels',
            },
        },
        y: {
            grid: {
                borderWidth: 1,
                borderDash: [4, 4],
                color: GRID_GRAY_COLOR,
            },
            ticks: {
                maxTicksLimit: 5,
            },
        },
    },
    datasets: {
        line: {
            pointRadius: 0,
            borderWidth: 1,
            offset: true,
        },
    },
};

// custom tooltip 으로 기존 recharts 툴팁과 유사하게 설정
const customTooltip = (colorScheme, t) => ({
    tooltip: {
        backgroundColor: colorScheme === DARK_THEME ? TOOLTIP_BG_COLOR_DARK : TOOLTIP_BG_COLOR_NAVY,
        titleColor: colorScheme === DARK_THEME ? WHITE_COLOR : TOOLTIP_TEXT_COLOR,
        bodyColor: colorScheme === DARK_THEME ? WHITE_COLOR : TOOLTIP_TEXT_COLOR,
        callbacks: {
            title: tooltipItem => {
                return `${t('Recognition Time')} ${tooltipItem[0].label}`;
            },
            label: tooltipItem => {
                let tooltipValue = tooltipItem.formattedValue;
                // Floating Bar Chart 에서 최대 최소 툴팁 표기
                if (tooltipValue.includes('[')) {
                    tooltipValue = JSON.parse(tooltipValue)
                        .sort((l, r) => l - r)
                        .join(' ~ ');
                }
                return `${t(tooltipItem.dataset.label)} ${tooltipValue}`;
            },
        },
    },
});

const MainView = ({ targetId }) => {
    const t = useTranslation('VitalSignMonitoring');
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);
    const [vitalSensor, setVitalSensor] = useState({});
    const [bpSensorList, setBpSensorList] = useState({}); // 백엔드 구조상 sensorList에 넣어 보낼 수 없음
    const [sensorList, setSensorList] = useState([]);

    const [date, setDate] = useState(moment().unix());
    const handleDate = selected => {
        setDate(selected);
    };

    // 센서 그래프 조회
    const { promise: getVitalSignsDetailGraph } = useAsync({
        promise: getVitalSignsDetailGraphApi,
        resolve: ({ recentVitalSensor, vitalBpSensorList, vitalSensorList }) => {
            setVitalSensor(recentVitalSensor);
            setBpSensorList(formatBpSensorList(vitalBpSensorList.BLOODPRESSURE, t));
            setSensorList(formatSensorList(vitalSensorList, t));
        },
        reject: err => {
            console.error(err);
        },
    });

    useEffect(() => {
        getVitalSignsDetailGraph({ searchDate: date, targetId });
    }, [date, t]);

    return (
        <>
            <InfoTable vitalSensor={vitalSensor} />
            <Search date={date} onChange={handleDate} />

            <div className="mb-5">
                <div className="w-100 d-flex align-items-end mb-1">
                    <span className="pnt-txt txt-bold s-6">{t(SENSOR_TYPE.BLOODPRESSURE)}</span>
                    {bpSensorList.unit && <span className="pnt-txt ml-1">({bpSensorList.unit})</span>}
                </div>
                <div style={{ height: `${GRAPH_AREA.HEIGHT}px` }}>
                    {bpSensorList.data && bpSensorList.data.labels.length > 0 ? (
                        <BarChart
                            data={bpSensorList.data}
                            options={mergeObjects(DEFAULT_CHART_OPTIONS, {
                                plugins: customTooltip(colorScheme, t),
                                scales: {
                                    y: {
                                        min: bpSensorList.min - 10,
                                        max: bpSensorList.max + 10,
                                    },
                                },
                            })}
                        />
                    ) : (
                        <NoDataBlock className={cx(styles.detail_graph, 'border-size-1 border-depth-7')} />
                    )}
                </div>
            </div>

            {/* 맥박-체온 순서 보장 */}
            {GUARANTEED_SENSOR_TYPE.map((value, index) => {
                const data = sensorList[index]?.data;
                const unit = sensorList[index]?.unit;
                return (
                    <div key={`${value}_${index}`} className="mb-5">
                        <div className="w-100 d-flex align-items-end mb-1">
                            <span className="pnt-txt txt-bold s-6">{t(value)}</span>
                            {unit && <span className="pnt-txt ml-1">({unit})</span>}
                        </div>
                        <div style={{ height: `${GRAPH_AREA.HEIGHT}px` }}>
                            {data && data.labels.length > 0 ? (
                                <LineChart
                                    data={data}
                                    options={mergeObjects(DEFAULT_CHART_OPTIONS, {
                                        plugins: customTooltip(colorScheme, t),
                                        scales: {
                                            y: {
                                                min: sensorList[index].min - 10,
                                                max: sensorList[index].max + 10,
                                            },
                                        },
                                    })}
                                />
                            ) : (
                                <NoDataBlock className={cx(styles.detail_graph, 'border-size-1 border-depth-7')} />
                            )}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default MainView;
