import React from 'react';
import cx from 'classnames';
import { useAppSelector, useTranslation } from '@hooks';
import { getBatteryIconClassName, getBatteryDisplayValue } from './util';

const RemainsBattery = ({
    value,
    chargingValue,
    leadOffValue,
}: {
    value: number;
    chargingValue?: number;
    leadOffValue?: number;
}) => {
    const t = useTranslation('Battery');
    return (
        <div className="d-flex justify-content-evenly align-items-center pnt-txt s-9 text-ellipsis px-1">
            {!value ? (
                <BatteryError />
            ) : (
                <span className={cx('material-icons-round md-20 rotate-2', value && value <= 10 && 'color-danger')}>
                    {getBatteryIconClassName(value, chargingValue)}
                </span>
            )}

            <span className={cx(value && value <= 10 && 'color-danger')}>
                {t(getBatteryDisplayValue(value, chargingValue, leadOffValue))}
            </span>
        </div>
    );
};

export const BatteryError = () => {
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);

    return (
        <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.3333 11.6666H8.33325C7.41658 11.6666 6.66658 12.4166 6.66658 13.3333V16.6666H4.99992C4.08325 16.6666 3.33325 17.4166 3.33325 18.3333V21.6666C3.33325 22.5833 4.08325 23.3333 4.99992 23.3333H6.66658V26.6666C6.66658 27.5833 7.41658 28.3333 8.33325 28.3333H10.6666L13.9999 24.9999H9.99992V14.9999L23.9999 14.9999L27.3333 11.6666ZM30.8688 11.6666L34.9999 11.6666C35.9166 11.6666 36.6666 12.4166 36.6666 13.3333V26.6666C36.6666 27.5833 35.9166 28.3333 34.9999 28.3333L14.2021 28.3333L17.5355 24.9999H33.3333V14.9999H27.5355L30.8688 11.6666Z"
                fill={colorScheme === 'pnt-dark' ? 'white' : 'black'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00006 33.9998L36.0001 5.99981L37.7678 7.76758L9.76782 35.7676L8.00006 33.9998Z"
                fill={colorScheme === 'pnt-dark' ? 'white' : 'black'}
            />
        </svg>
    );
};

export default RemainsBattery;
