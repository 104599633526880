import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Select from '@components/Select';
import useTopFloorGroup from '../useTopFloorGroup';
import { setSelectedFloor, setSelectedTopFloor } from '../realTimeLocationTrackingReducer';
import { RealTimeLocationTrackingContext, RealTimeLocationTrackingDispatchContext } from '../index';

const DEFAULT_LABEL = '-';

const FloorGroupSelect = ({}) => {
    // 사용자가 속한 그룹의 층 정보
    const { representationGroupInfo } = useSelector(state => state.UserInfo.userInfo);
    // 전체 층 정보
    const { floorList: allFloorList } = useSelector(state => state.FloorInfo);

    const { selectedFloor, selectedTopFloor } = useContext(RealTimeLocationTrackingContext);
    const dispatch = useContext(RealTimeLocationTrackingDispatchContext);

    const buildingList = allFloorList.filter(floor => floor.levelNo === 0);
    const floorList = allFloorList.filter(floor => floor.upperFloorId === selectedTopFloor?.floorId);

    const { topFloor: initBuilding, floor: initBuildingFloor } = useTopFloorGroup({
        targetFloorId: representationGroupInfo?.floorId,
    });

    const handleTopFloorChange = selected => {
        dispatch(
            setSelectedTopFloor({
                topFloor: selected,
                floor: allFloorList.find(floor => floor.upperFloorId === selected?.floorId),
            }),
        );
    };
    const handleFloorChange = selected => {
        dispatch(setSelectedFloor(selected));
    };

    useEffect(() => {
        dispatch(setSelectedTopFloor({ topFloor: initBuilding, floor: initBuildingFloor ?? initBuilding }));
    }, [dispatch, initBuilding, initBuildingFloor]);

    return (
        <div className="d-flex">
            <Select
                options={buildingList}
                labelKey={'floorName'}
                valueKey={'floorId'}
                value={selectedTopFloor}
                onChange={handleTopFloorChange}
            />
            <Select
                className="ml-1"
                valueKey={'floorId'}
                labelKey={'floorName'}
                options={floorList}
                value={selectedFloor}
                onChange={handleFloorChange}
                placeholder={floorList.length === 0 && DEFAULT_LABEL}
                disabled={floorList.length === 0}
            />
        </div>
    );
};

export default FloorGroupSelect;
