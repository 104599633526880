import React, { Fragment, useMemo } from 'react';

const MobileMenuPath = ({ menuPath }) => {
    const breadCrumb = useMemo(() => {
        return [...menuPath].reduce(
            (acc, curr, idx) => {
                if (idx === menuPath.length - 1) {
                    acc.destination = curr;
                } else {
                    acc.paths.push(curr);
                }
                return acc;
            },
            { paths: [], destination: '' },
        );
    }, [menuPath]);
    return (
        <div className={'mobile-page-title'}>
            {breadCrumb.paths.map(v => (
                <Fragment key={v}>
                    <span className={'menu-sub'}>{v}</span>
                    <span className={'icon-next icon-small'} />
                </Fragment>
            ))}
            <span className={'menu-main color-secondary'}>{breadCrumb.destination}</span>
        </div>
    );
};

export default MobileMenuPath;
