import React, { useContext, useState } from 'react';
import { ChartType } from '../../Components/WidgetSettingOptions';
import useTranslation from '@hooks/useTranslation';
import WidgetSettingModal from '../../Components/WidgetSettingModal';
import { editWidgetSettings } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import { useSettings } from '../../util/useSettings';
import { useSelector } from 'react-redux';
import { SearchableSelect, Label } from '@components';
import { ScreenEditDispatchContext } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit';

const CategoryChartSetting = ({ widgetInfo, ...restProps }) => {
    const dispatch = useContext(ScreenEditDispatchContext);
    const { topCategoryList } = useSelector(state => state.CategoryInfo);
    const t = useTranslation('CategoryChart');
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { settings: customSettings } = config;

    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    const handleSubmitClick = data => {
        const checkedSettings = customSettings
            ? { ...customSettings, ...data, ...updatedWidgetInfo }
            : { ...data, ...updatedWidgetInfo };

        const updatedWidgetSettings = {
            id: widgetInfo.id,
            settings: checkedSettings,
        };

        dispatch(editWidgetSettings(updatedWidgetSettings));
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    const handleChartTypeChange = selectedChartType => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, chartType: selectedChartType.value };
        });
    };

    const handleCategoryTypeChange = selectedCategoryType => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, categoryType: selectedCategoryType.map(v => v.value) };
        });
    };

    return (
        <WidgetSettingModal
            headerTitle={t('Category Chart Setting')}
            widgetInfo={widgetInfo}
            socketWidget
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <ChartType
                value={updatedWidgetInfo.chartType ? updatedWidgetInfo.chartType : settings.chartType}
                onChange={handleChartTypeChange}
            />

            <Label
                name={t('Type of Category')}
                value={
                    <SearchableSelect
                        isModalSelect
                        data={topCategoryList}
                        selected={
                            updatedWidgetInfo.categoryType
                                ? updatedWidgetInfo.categoryType
                                : customSettings && customSettings.categoryType
                                ? customSettings.categoryType
                                : null
                        }
                        onChange={handleCategoryTypeChange}
                        title={t('Category')}
                    />
                }
            />
        </WidgetSettingModal>
    );
};

export default CategoryChartSetting;
