import React, { useRef, useState } from 'react';
import SearchGroup from './Components/SearchGroup';
import moment from 'moment';
import Chart from './Components/Chart';
import { WidgetProps } from '../../staticInfo';
import { getNotificationStatistics, postNotificationStatisticsExcel } from '@api/so/outpatient';
import { useAsyncExcel } from '../../util/useAsyncExcel';
import WidgetCard from '../../Components/WidgetCard';
import { useAsync, useTranslation } from '@util/hooks';

const NotificationStatistics = ({ children, widgetInfo, ...restProps }: WidgetProps) => {
    const widgetRef = useRef(null);
    const t = useTranslation('OutpatientStorage');

    const [date, setDate] = useState(moment().startOf('year').format('YYYY'));

    const handleDateChange = (selected: Date) => {
        const date = moment(selected).format('YYYY');
        setDate(date);
    };

    const { state } = useAsync({
        promise: getNotificationStatistics,
        param: { year: date },
        immediate: true,
        deps: [date],
        reject: err => console.error(err),
    });
    const data = state.response ? state.response.rows : [];

    const triggerDownload = useAsyncExcel({
        promise: postNotificationStatisticsExcel,
        param: {
            year: date,
            columnMetas: [
                {
                    key: 'month',
                    name: t('month'),
                },
                {
                    key: 'talkCount',
                    name: t('Notification Talk'),
                },
                {
                    key: 'smsCount',
                    name: t('Message(SMS)'),
                },
            ],
            zipFileName: 'statistics',
            fileName: 'statistics',
        },
    });

    const handleDownloadClick = () => {
        triggerDownload();
    };

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <SearchGroup
                date={date}
                onChange={handleDateChange}
                onDownloadClick={handleDownloadClick}
                onRefreshClick={() => setDate(moment().startOf('year').format('YYYY'))}
            />
            <Chart data={data} />
            {children}
        </WidgetCard>
    );
};

export default NotificationStatistics;
