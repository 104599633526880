import React, { useRef } from 'react';
import { useAsync, useConfirmModal, useTranslation } from '@hooks';
import { createIotItemExcelApi, fetchExcelLogApi } from '@api/common/log';
import moment from 'moment';
import { Button } from '@components';
import { makeParameter } from '../../../util/commons';
import { useIotItemInfoLogContext } from '../index';
import { excelExport } from '../../../util/excelExport';

const INTERVAL_TIME = 1000;

/**
 * Iot 아이템 정보 로그 정보를 엑셀 파일로 출력하는 컴포넌트
 *
 * @param {number} listCount 리스트 카운트
 *
 * @author jinbeom-jung
 * */
const ExcelExport = ({ listCount }) => {
    const t = useTranslation('Iot Item Information Log');
    const commonColumn = useTranslation('CommonColumn');
    const { keyword, searchOption, manipulationType } = useIotItemInfoLogContext();
    const excelTimerRef = useRef({});

    const { promise: createIotItemExcel } = useAsync({
        promise: createIotItemExcelApi,
        fixedParam: {
            zipFileName: 'IotItem_Log',
            fileName: 'IotItem_Log',
            startDate: 0,
            endDate: moment().unix(),
            columnMetas: [
                {
                    key: 'logNum',
                    name: commonColumn('No.'),
                },
                {
                    key: 'manipulationType',
                    name: commonColumn('Type of Data Revision'),
                },
                {
                    key: 'targetNum',
                    name: commonColumn('IoT Item No.'),
                },
                {
                    key: 'targetId',
                    name: commonColumn('IoT Item ID'),
                },
                {
                    key: 'targetName',
                    name: commonColumn('IoT Item Name'),
                },
                {
                    key: 'categoryCodePath',
                    name: commonColumn('Category Code Path'),
                },
                {
                    key: 'categoryNamePath',
                    name: commonColumn('Category Name Path'),
                },
                {
                    key: 'regDate',
                    name: commonColumn('Data Revision Date'),
                    isDateFormat: 'Y',
                },
                {
                    key: 'metadata',
                    name: commonColumn('Meta Data'),
                },
            ],
        },
        resolve: res => {
            const { filePath: url, reqNum } = res;
            if (url) {
                downloadWithURL({ reqNum });
            }
        },
    });

    const { promise: fetchExcelLog } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            const { dataSettingStatusType, reqNum, filePath, fileName } = res;
            if (dataSettingStatusType === 'COMPLETED') {
                excelExport(filePath, fileName);
            } else if (dataSettingStatusType === 'FAILED') {
                toggleExcelDownloadResultModal();
            }
            clearExcelTimer({ reqNum });
        },
    });

    const handleExportFileClick = () => {
        if (listCount) {
            const result = makeParameter({
                searchRequirement: {
                    keyword,
                    opt: searchOption.value,
                    manipulationType: manipulationType.value,
                },
                useIsAll: true,
            });
            createIotItemExcel(result);
        } else {
            toggleExcelConfirmModal();
        }
    };

    const downloadWithURL = ({ reqNum }) => {
        excelTimerRef.current[reqNum] = setInterval(() => {
            fetchExcelLog({ reqNum: reqNum });
        }, INTERVAL_TIME);
    };

    const clearExcelTimer = ({ reqNum }) => {
        clearInterval(excelTimerRef.current[reqNum]);
        excelTimerRef.current[reqNum] = null;
    };

    const { Modal: ExcelDownloadResultModal, toggleModal: toggleExcelDownloadResultModal } = useConfirmModal({
        initModal: false,
        header: { title: t('Result', 'Report') },
        confirmText: t('Failed to download the excel.', 'Report'),
    });

    const { Modal: ExcelConfirmModal, toggleModal: toggleExcelConfirmModal } = useConfirmModal({
        initModal: false,
        removeCancel: true,
        header: { title: t('Excel Download', 'Report') },
        confirmText: t('No data', 'Report'),
    });

    return (
        <>
            <Button className="btn-lightgray btn-icon" onClick={handleExportFileClick}>
                <span className={'material-icons-round'}>file_upload</span>
                {t('Export', 'Button')}
            </Button>
            <ExcelDownloadResultModal />
            <ExcelConfirmModal />
        </>
    );
};

export default ExcelExport;
