import React, { useContext, useRef } from 'react';
import WidgetCard from '../../Components/WidgetCard';
import OutpatientUnattendedReceivingItemContainer from './OutpatientUnattendedReceivingItemContainer';
import outpatientUnattendedReceivingStatusSlice from './OutpatientUnattendedReceivingStatusSlice';
import ContextProvider from '@components/ContextProvider';

export const OutpatientUnattendedReceivingStatusStateContext = React.createContext();
export const OutpatientUnattendedReceivingStatusDispatchContext = React.createContext();
export const useOutpatientUnattendedReceivingContext = () =>
    useContext(OutpatientUnattendedReceivingStatusStateContext);
export const useOutpatientUnattendedReceivingDispatch = () =>
    useContext(OutpatientUnattendedReceivingStatusDispatchContext);

const OutpatientUnattendedReceivingStatus = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();

    return (
        <ContextProvider
            StateContext={OutpatientUnattendedReceivingStatusStateContext}
            DispatchContext={OutpatientUnattendedReceivingStatusDispatchContext}
            slice={outpatientUnattendedReceivingStatusSlice}
        >
            <WidgetCard ref={widgetRef} widgetInfo={widgetInfo} {...restProps}>
                <OutpatientUnattendedReceivingItemContainer widgetInfo={widgetInfo} />
                {children}
            </WidgetCard>
        </ContextProvider>
    );
};

export default OutpatientUnattendedReceivingStatus;
