const SECOND = 1000;
const MINUTE = 60 * 1000;
const HOUR = 60 * 60 * 1000;
const DAY = 24 * 60 * 60 * 1000;

const TIMES = {
    ZERO: 0,
    SEC_1: SECOND,
    SEC_5: 5 * SECOND,
    SEC_10: 10 * SECOND,
    SEC_15: 15 * SECOND,
    SEC_20: 20 * SECOND,
    SEC_30: 30 * SECOND,
    SEC_40: 40 * SECOND,
    SEC_50: 50 * SECOND,
    MIN_1: MINUTE,
    MIN_5: 5 * MINUTE,
    MIN_10: 10 * MINUTE,
    MIN_15: 15 * MINUTE,
    MIN_20: 20 * MINUTE,
    MIN_30: 30 * MINUTE,
    MIN_40: 40 * MINUTE,
    MIN_50: 50 * MINUTE,
    HOUR_1: HOUR,
    DAY_TO_SEC: 86400,
    DAY_1: DAY,
    DAY_1_PER_SEC: DAY / 1000,
} as const;

export default TIMES;

export const DATE_FORMAT = {
    FOUR_DIGITS_YEAR: 'YYYY',
    TWO_DIGITS_YEAR: 'YY',
    MONTH_NAME: 'MMMM',
    OMITTED_MONTH_NAME: 'MMM',
    TWO_DIGITS_MONTH: 'MM',
    MONTH: 'M',
    TWO_DIGITS_DAY: 'DD',
    DAY: 'D',
    HOUR_24: 'HH',
    HOUR_12: 'hh',
    TWO_DIGITS_MIN: 'mm',
    MIN: 'm',
    TWO_DIGITS_SEC: 'ss',
    SEC: 's',
    MILLISECOND: 'ms',
    YMD_HMS: 'YYYY-MM-DD HH:mm:ss',
    YMD_HM: 'YYYY-MM-DD HH:mm',
    YMD_H: 'YYYY-MM-DD HH',
    YMD: 'YYYY-MM-DD',
    YM: 'YYYY-MM',
    MD: 'MM-DD',
    HMS: 'HH:mm:ss',
    HM: 'HH:mm',
    FLAT_YMD: 'YYYYMMDD',
    FLAT_YMD_HMS: 'YYYYMMDDHHmmss',
};
