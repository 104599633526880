import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useExamPatientsMonitoringStateContext } from '../../slice';
import { useAsync, useTranslation, useAppSelector } from '@hooks';
import cx from 'classnames';
import styles from '@asset/pnt/widgets/sh/ExamPatientsMonitoring.module.scss';
import { ScatterDataPoint } from 'chart.js';
import { SENSOR_TYPE_ORDER, SENSOR_TYPE_TO_NAME, TICK_LIMITS } from '../../constants';
import VitalSignsChart from './VitalSignsChart';
import { ChartSensorType } from '../../types';
import { Timestamp, UnixTimestamp, YN } from '@util/type/util';
import { getProgressHistoryApi, getWatchVitalSignsApi } from '@api/sh/examPatientMonitoring';
import { getStartOfDate } from '@util/date';
import moment from 'moment';
import { isMonitoring } from '@reducer/ScreenInfo';
import { getPatientActiveTimeApi, getPatientVitalSingsApi } from '../../sampleData';

type ChartDot = {
    currentValue: number;
    measurementUnit: string;
    regDate: number;
    sensingType: string;
    targetId: string;
};

export type ChartData = {
    data: (number | ScatterDataPoint | null)[];
    labels: number[];
    sensorType: ChartSensorType;
    name: string;
    unit: string;
    min: number;
    max: number;
    activeTimes: { startTime: Timestamp; endTime: Timestamp }[];
};

export type ChartLoading = {
    HEARTBEAT: boolean;
    SPO2: boolean;
    TEMPERATURE: boolean;
};

const getInitialChartData = (sensorType: ChartSensorType, name: string) => ({
    data: [],
    labels: [getStartOfDate() * 1000, moment().valueOf()],
    sensorType: sensorType,
    name: name,
    unit: '',
    min: Infinity,
    max: -Infinity,
    activeTimes: [],
});

const getProgressInfo = (progressInfo: { progressType: YN; time: UnixTimestamp }) => {
    if (progressInfo.progressType === 'Y') {
        return { startTime: progressInfo.time };
    } else {
        return { endTime: progressInfo.time };
    }
};

const VitalSignsView = () => {
    const t = useTranslation('ExamPatientsMonitoring');
    const mode = useAppSelector(state => state.ScreenInfo.mode);
    const { selectedSubject } = useExamPatientsMonitoringStateContext();
    const [chartData, setChartData] = useState<{
        HEARTBEAT: ChartData;
        SPO2: ChartData;
        TEMPERATURE: ChartData;
    }>({
        HEARTBEAT: getInitialChartData('HEARTBEAT', t(SENSOR_TYPE_TO_NAME.HEARTBEAT)),
        SPO2: getInitialChartData('SPO2', t(SENSOR_TYPE_TO_NAME.SPO2)),
        TEMPERATURE: getInitialChartData('TEMPERATURE', t(SENSOR_TYPE_TO_NAME.TEMPERATURE)),
    });
    const [chartLoading, setChartLoading] = useState<ChartLoading>({
        HEARTBEAT: true,
        SPO2: true,
        TEMPERATURE: true,
    });

    const {
        state: { isLoading },
    } = useAsync({
        promise: isMonitoring(mode)
            ? [getWatchVitalSignsApi, getProgressHistoryApi]
            : [getPatientVitalSingsApi, getPatientActiveTimeApi],
        fixedParam: [
            { targetNum: selectedSubject?.targetNum },
            { subjectNum: selectedSubject?.subjectNum, isAll: 'Y' },
        ],
        immediate: true,
        deps: [selectedSubject],
        resolve: ([
            {
                data: { sensingList, measurementUnits },
            },
            { rows: activeTimeRows },
        ]: [any, { rows: { progressType: YN; time: UnixTimestamp }[] }]) => {
            const activeTimes = activeTimeRows.reduce((acc, curr) => {
                if (acc[acc.length - 1]) {
                    const currActiveTimes = acc[acc.length - 1];
                    if (currActiveTimes.startTime && currActiveTimes.endTime) {
                        acc.push(getProgressInfo(curr));
                    } else {
                        acc[acc.length - 1] = { ...currActiveTimes, ...getProgressInfo(curr) };
                    }
                } else {
                    acc.push(getProgressInfo(curr));
                }
                return acc;
            }, [] as { startTime?: UnixTimestamp; endTime?: UnixTimestamp }[]);

            if (sensingList) {
                const dateList = sensingList.regDate ?? [];
                SENSOR_TYPE_ORDER.forEach(sensorType => {
                    const list = sensingList[sensorType] ?? [];
                    const stepSize = Math.min(TICK_LIMITS, list.length);
                    const startTime = dateList[0] * 1000;
                    const endTime = dateList[dateList.length - 1] * 1000;
                    const offset = Math.floor((endTime - startTime) / stepSize);
                    const labels: Timestamp[] = [];
                    if (list.length) {
                        labels.push(startTime);
                        for (let i = 0; i < stepSize; i++) {
                            labels.push(startTime + offset * i);
                        }
                        labels.push(endTime);
                    } else {
                        labels.push(getStartOfDate() * 1000, moment().valueOf());
                    }

                    setChartData(prev => {
                        return {
                            ...prev,
                            [sensorType]: list.reduce(
                                (acc: ChartData, currentValue: number, idx: number) => {
                                    acc.data.push({
                                        x: dateList[idx] * 1000,
                                        y: currentValue,
                                    });
                                    acc.min = Math.min(acc.min, currentValue);
                                    acc.max = Math.max(acc.max, currentValue);
                                    return acc;
                                },
                                {
                                    data: [],
                                    labels,
                                    sensorType,
                                    name: t(SENSOR_TYPE_TO_NAME[sensorType]),
                                    unit: measurementUnits[sensorType] ?? '',
                                    min: Infinity,
                                    max: -Infinity,
                                    activeTimes: activeTimes,
                                },
                            ),
                        };
                    });
                });
            }
        },
        reject: err => {
            setChartLoading({ HEARTBEAT: false, SPO2: false, TEMPERATURE: false });
            console.error(err);
        },
    });

    return (
        <div className={cx('p-3 border border-depth-7', styles['vitalsings-area'])}>
            <PerfectScrollbar className={styles['chart-container']}>
                {SENSOR_TYPE_ORDER.map(sensorType => (
                    <VitalSignsChart
                        key={sensorType}
                        chartData={chartData[sensorType]}
                        isLoading={isLoading || chartLoading[sensorType]}
                        setLoading={setChartLoading}
                    />
                ))}
            </PerfectScrollbar>
        </div>
    );
};

export default VitalSignsView;
