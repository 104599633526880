import React from 'react';
import { PntChart, PntChartType } from '@components/Charts/types';
import { Bar } from 'react-chartjs-2';
import useChartData from '@components/Charts/util/useChartData';
import useChartOptions from '@components/Charts/util/useChartOptions';
import { mergeObjects } from '@util/common/util';

const defaultOptions = {
    scales: {
        x: { grid: { display: false }, display: false },
    },
};

const stackBarOptions = mergeObjects(defaultOptions, {
    scales: {
        x: { stacked: true, display: true },
        y: { stacked: true },
    },
});

const multiBarOptions = mergeObjects(defaultOptions, {
    scales: {
        x: { display: true },
    },
});

const getBarOptions = (type: PntChartType) => {
    switch (type) {
        case 'stackedBar':
            return stackBarOptions;
        case 'multiBar':
            return multiBarOptions;
        default:
            return defaultOptions;
    }
};

const BarChart = ({ data, options, plugins, type = 'bar' }: PntChart<'bar'>) => {
    const chartData = useChartData<'bar'>(data);
    const chartOptions = useChartOptions(getBarOptions(type));
    return <Bar data={chartData} options={mergeObjects(chartOptions, options)} plugins={plugins} />;
};

export default BarChart;
