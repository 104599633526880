import { useTranslation } from '@hooks';
import { useState } from 'react';
import { METHOD_OPTIONS, OUTPATIENT_REPORT } from '../../constants';

const useMethodOptions = () => {
    const t = useTranslation(OUTPATIENT_REPORT);
    const methodOptions = METHOD_OPTIONS.map(({ value, label }) => ({
        value,
        label: t(label),
    }));

    const [method, setMethod] = useState(methodOptions[0]); // 0 개별, 1 합산

    const handleMethodChange = selected => {
        setMethod(selected);
    };

    return {
        methodOptions,
        method,
        handleMethodChange,
    };
};

export default useMethodOptions;
