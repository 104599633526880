import { Chart } from 'react-chartjs-2';
import { Color } from 'chart.js';

// Grid Line Plugin Type

interface PlotLine {
    value?: number;
    text?: string;
    color?: Color;
    font?: { size?: string; color?: Color };
}

export type PlotLinesOptions = PlotLine[];

export default {
    id: 'plotLines',
    afterDraw(chart: Chart, args: object, options: PlotLinesOptions) {
        const {
            ctx,
            chartArea: { left, width },
            scales: { y },
        } = chart;

        ctx.save();
        if (!options.length) {
            return;
        }
        options.forEach(opt => {
            const value = opt?.value || 0;
            // 차트 영역 외 생기는 Plot line 미 생성으로 분기처리
            if (y.min > value || y.max < value) {
                return;
            }
            ctx.strokeStyle = `${opt?.color || 'black'}`;
            ctx.strokeRect(left, y.getPixelForValue(value), width, 0);
            const theme = document.getElementsByClassName('app-theme-pnt-dark').length;

            ctx.font = `${opt?.font?.size} pretendard`;
            ctx.fillStyle = `${opt?.font?.color || (theme ? 'white' : 'black')} `;
            ctx.fillText(opt?.text || '', width / 2 + left, y.getPixelForValue(value) - 12);
            ctx.textAlign = 'center';
            ctx.restore();
        });
    },
};
