import React, { useCallback, useEffect, useRef, useState } from 'react';
import Draggable from '@components/Draggable';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { useSensorStatusContext, useSensorStatusDispatch } from '../../../../../index';
import { closeLocationPopup } from '../../../../../sensorStatusSlice';
import useTranslation from '@hooks/useTranslation';
import { Map, RotatedImageOverlay, TextInput } from '@components';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import { useSelector } from 'react-redux';

/**
 * 월별 리포트에서 센서 정보에 위치보기를 눌렀을 때 나타나는 팝업 컴포넌트
 *
 * @param cardRef  팝업 위치를 지정하기위한 위젯의 위치 값
 * @param settings 위젯 설정 값
 *
 * @author jinbeom-jung
 * */
const LocationPopup = ({ cardRef, settings }) => {
    const t = useTranslation('SensorStatus');
    const dispatch = useSensorStatusDispatch();
    const { locationPopup, selectedSensor, floorId } = useSensorStatusContext();
    const { floorList } = useSelector(state => state.FloorInfo);
    const { hiddenMapTile } = settings;
    const confirmedTile = hiddenMapTile !== undefined ? !hiddenMapTile : true;
    const [popupSize, setPopupSize] = useState({ width: 0, height: 0 });
    const popupRef = useCallback(node => {
        if (node !== null) {
            const { width, height } = node.getBoundingClientRect();
            setPopupSize({ width, height });
        }
    }, []);

    const [position, setPosition] = useState({ x: 0, y: 0 });
    useEffect(() => {
        const widgetPosition = getWidgetPosition();
        const x = (widgetPosition.width - popupSize.width) / 2 - 20;
        const y = -widgetPosition.height + 60;
        setPosition({ x, y });
    }, [popupSize]);

    const [floorInfo, setFloorInfo] = useState(null);
    useEffect(() => {
        if (floorId) {
            setFloorInfo(floorList.find(floorInfo => floorInfo.floorId === floorId));
        }
    }, [floorId]);

    const mapRef = useRef();
    useEffect(() => {
        if (mapRef && mapRef.current) {
            const map = mapRef.current.leafletElement;
            map.setView([selectedSensor.lat, selectedSensor.lng]);
        }
    }, [mapRef.current]);

    const getWidgetPosition = () => {
        const widgetPosition = { x: 0, y: 0 };
        if (cardRef.current) {
            const searchContainer = cardRef.current;
            const { offsetWidth: width, offsetHeight: height } = searchContainer;

            widgetPosition.width = width;
            widgetPosition.height = height;
        }
        return widgetPosition;
    };

    return (
        <Draggable key={JSON.stringify(position)} bounds=".sensor-status-container" defaultPosition={position}>
            <Toast isOpen={locationPopup} className={'popup-open bg-depth-3'} innerRef={popupRef}>
                <ToastHeader toggle={() => dispatch(closeLocationPopup())} className={'bg-depth-4'}>
                    {t('Location')}
                </ToastHeader>
                <ToastBody className={'h-100 bg-depth-4'}>
                    <div className={'flx-row row-space-around mb-2'}>
                        <div>
                            {t('Lat')}/{t('Lng')}
                        </div>
                        <div className={'flx-row'}>
                            <TextInput name={'lat'} value={selectedSensor.lat} readonly />
                            <span> / </span>
                            <TextInput name={'lng'} value={selectedSensor.lng} readonly />
                        </div>
                    </div>
                    <Map className={'p-0 h-90'} tile={confirmedTile} ref={mapRef} rotation={floorInfo?.rotation}>
                        {floorInfo && floorInfo.imgURL && floorInfo.bounds.length && (
                            <RotatedImageOverlay
                                key={floorInfo.floorId}
                                url={floorInfo.imgURL}
                                deg={floorInfo.deg}
                                bounds={floorInfo.bounds}
                            />
                        )}
                        <Marker
                            key={selectedSensor.targetNum}
                            position={[selectedSensor.lat, selectedSensor.lng]}
                            icon={L.divIcon({
                                className: 'category-marker',
                                html: `<div class="marker-img replace-img"><div></div></div>`,
                            })}
                        />
                    </Map>
                </ToastBody>
            </Toast>
        </Draggable>
    );
};

export default React.memo(LocationPopup);
