import React, { useEffect, useRef } from 'react';
import { Map, RotatedImageOverlay } from '@components';
import styled from 'styled-components';
import { useAppSelector, useTranslation } from '@hooks';
import { useRealTimeSensorMonitoringContext, useRealTimeSensorMonitoringDispatch } from '../index';
import { setFloorInfo } from '../realTimeSensorMonitoringSlice';
import LocationLabel from './LocationLabel';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import moment from 'moment';
import { breakpoint } from '@components/style';

const SelectedSensor = ({ settings }) => {
    const t = useTranslation('RealTimeSensorMonitoring');
    const { selectedSensor, floorInfo } = useRealTimeSensorMonitoringContext();
    const dispatch = useRealTimeSensorMonitoringDispatch();
    const { selectableFloorList } = useAppSelector(state => state.FloorInfo);
    const { hiddenMapTile } = settings;
    const confirmedTile = hiddenMapTile !== undefined ? !hiddenMapTile : true;

    const mapRef = useRef();

    useEffect(() => {
        dispatch(setFloorInfo(selectableFloorList.find(floor => floor.floorId === selectedSensor.floorId)));
        const map = mapRef.current.leafletElement;
        if (map) {
            map.setView([selectedSensor.lat, selectedSensor.lng]);
        }
    }, []);

    return (
        <MapContainer>
            <div className={'detail-info-box'}>
                <div className={'pnt-txt txt-bold'}>{t('Location Information')}</div>
                <div className="pnt-divider horizon-line border-bold-2 mb-3" />
                <div className={'pnt-label-7 overflow-auto'}>
                    <LocationLabel name={t('Target Name')} value={selectedSensor.targetName || '-'} />
                    <LocationLabel name={t('Sensor Name')} value={selectedSensor.sensorName || '-'} />
                    <LocationLabel name={t('Current Floor')} value={floorInfo.floorName || '-'} />
                    <LocationLabel
                        name={t('Measuring Time')}
                        value={
                            selectedSensor.lastDate === 0
                                ? '-'
                                : moment.unix(selectedSensor.lastDate).format('YYYY-MM-DD HH:mm:ss')
                        }
                    />
                </div>
            </div>

            <div className={'border rounded-1'}>
                <Map tile={confirmedTile} ref={mapRef} rotation={floorInfo?.rotation}>
                    {floorInfo && floorInfo.imgURL && floorInfo.bounds.length && (
                        <RotatedImageOverlay
                            key={floorInfo.floorId}
                            url={floorInfo.imgURL}
                            deg={floorInfo.deg}
                            bounds={floorInfo.bounds}
                            onLoad={() => {
                                const map = mapRef.current.leafletElement;
                                if (map) {
                                    map.setView([selectedSensor.lat, selectedSensor.lng]);
                                }
                            }}
                        />
                    )}
                    <Marker
                        key={selectedSensor.targetId}
                        position={[selectedSensor.lat, selectedSensor.lng]}
                        icon={L.divIcon({
                            className: 'custom-asset-marker',
                            html: `<div></div>`,
                        })}
                    />
                </Map>
            </div>
        </MapContainer>
    );
};

const MapContainer = styled.div`
    display: grid;
    height: 100%;
    width: 100%;
    gap: 10px;
    grid-template-columns: 30% auto;
    @media (max-width: ${breakpoint.tablet}) {
        grid-template-columns: auto;
        grid-template-rows: 35% auto;
    }
    .detail-info-box {
        height: 100%;
        overflow: hidden;
        display: grid;
        grid-template-rows: min-content min-content auto;
    }
`;

export default SelectedSensor;
