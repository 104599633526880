import React, { useEffect, useRef, useState } from 'react';
import WidgetCard from '../../Components/WidgetCard';
import useAsync from '@hooks/useAsync';
import { getDailyInspectionCalendarApi, getDailyInspectionListApi } from '@api/am/management';
import useTranslation from '@hooks/useTranslation';
import { useColumns } from '@hooks';
import * as column from '@util/grid/column';
import { ASSET_MANAGEMENT, DUTY_LIST, INSPECTION_TYPE, PROPERTY_ID } from '../../constants';
import { SCREEN_MODE_MONITORING } from '@reducer/ScreenInfo';
import NormalRiskInspectionList from './NormalRiskInspectionList';
import Table from '@components/Table';
import { DEFAULT_VALUE, ROWS_DUMMY } from './constants';
import { LoadingBlock, QuestionTooltip } from '@components';
import useMultiOptionsByRole from '../util/useMultiOptionsByRole';
import useModal from '../../util/useModal';
import HighRiskModal from './HighRiskModal';
import SearchGroupAM from '../Components/SearchGroupAM';
import moment from 'moment';
import useCategoryList from '../util/useCategoryList';
import { ASSET_CODE } from '../AssetRentalStatus/constants';
import useAppSelector from '@hooks/useAppSelector';
import useDateAM from '../util/useDateAM';
import useWorkTime from '../util/useWorkTime';
import TIMES, { DATE_FORMAT } from '@util/times';
import styled from 'styled-components';
import cx from 'classnames';
import { CATEGORY_CODE } from '@util/mappInfo';
import useTimeout from '../util/useTimeout';

const DailyInspection = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation(ASSET_MANAGEMENT);
    const columnT = useTranslation('CommonColumn');
    const widgetRef = useRef();
    const { mode } = useAppSelector(state => state.ScreenInfo);
    const workTime = useWorkTime();

    const inspectingStart = workTime.workTimeSet.DAY.startDate;
    const inspectingEnd = inspectingStart + TIMES.HOUR_1 / 1000;
    const isInspectingTime = useTimeout(inspectingStart, inspectingEnd);

    // 필터 정보
    const wardListValues = useMultiOptionsByRole();
    const categoryValues = useCategoryList();
    const dateValues = useDateAM(workTime);

    // 오늘을 검증하기 위한 정보
    const adjustSelectedDate =
        workTime.currentWorkTimeType !== DUTY_LIST.NIGHT ||
        moment.unix(dateValues?.startDate).format(DATE_FORMAT.YMD) !==
            moment.unix(workTime.workTimeSet[DUTY_LIST.NIGHT]?.startDate).format(DATE_FORMAT.YMD)
            ? moment
                  .unix(dateValues.startDate)
                  .hour(moment().hour())
                  .minute(moment().minute())
                  .second(moment().second())
            : moment();
    const validationToday = adjustSelectedDate.isBetween(
        moment.unix(workTime.workTimeSet[workTime.currentWorkTimeType]?.startDate).add(-1, 's'),
        moment.unix(workTime.workTimeSet[workTime.currentWorkTimeType]?.endDate).add(1, 's'),
    );
    const isToday = validationToday ? 'Y' : 'N';

    // 고위험 점검 모달
    const [isHighRiskOpen, handleHighRiskToggle] = useModal();
    const [selectedHighRisk, setSelectedHighRisk] = useState(null);

    const handleToggleHighLiskModal = () => {
        getHighRiskDailyInspectionList();
        handleHighRiskToggle();
    };

    const { promise: getHighRiskDailyInspectionList, state: highRiskState } = useAsync({
        promise: getDailyInspectionListApi,
        fixedParam: { checkType: INSPECTION_TYPE.HIGH },
        param: {
            categoryCodes:
                categoryValues.selectedCategoryList.length !== 0
                    ? categoryValues.selectedCategoryList.toString()
                    : categoryValues.categoryOptions.map(v => v.value).toString(),
            targetPropertiesSearch: {
                categoryCode: ASSET_CODE,
                propertySearchs: [
                    ...(wardListValues?.selectedWardList.length !== 0
                        ? wardListValues?.selectedWardList.map(value => ({
                              propertyId: PROPERTY_ID.ASSET.USING_DEP_ID,
                              values: [value],
                          }))
                        : wardListValues?.options.map(({ value }) => ({
                              propertyId: PROPERTY_ID.ASSET.USING_DEP_ID,
                              values: [value],
                          }))),
                ],
            },
            startDate: dateValues.startDate, // NaN 예외 처리 필요 ex) NaN이면 오늘의 startDate
            endDate: dateValues.endDate, // NaN 예외 처리 필요
            isToday,
        },
    });

    const { promise: getDailyInspectionList, state: normalRiskState } = useAsync({
        promise: getDailyInspectionListApi,
        fixedParam: { checkType: INSPECTION_TYPE.NORMAL },
        param: {
            categoryCodes:
                categoryValues.selectedCategoryList.length !== 0
                    ? categoryValues.selectedCategoryList.toString()
                    : categoryValues.categoryOptions.map(v => v.value).toString(),
            targetPropertiesSearch: {
                categoryCode: ASSET_CODE,
                propertySearchs: [
                    ...(wardListValues?.selectedWardList.length !== 0
                        ? wardListValues?.selectedWardList.map(value => ({
                              propertyId: PROPERTY_ID.ASSET.USING_DEP_ID,
                              values: [value],
                          }))
                        : wardListValues?.options.map(({ value }) => ({
                              propertyId: PROPERTY_ID.ASSET.USING_DEP_ID,
                              values: [value],
                          }))),
                ],
            },
            startDate: dateValues.startDate, // NaN 예외 처리 필요 ex) NaN이면 오늘의 startDate
            endDate: dateValues.endDate, // NaN 예외 처리 필요
            isToday,
        },
    });

    const { promise: getDailyCalendarList, state: normalRiskCalendarState } = useAsync({
        promise: getDailyInspectionCalendarApi,
        fixedParam: {
            targetPropertiesSearch: {
                categoryCode: CATEGORY_CODE.ASSET,
                propertySearchs: [
                    ...(wardListValues?.selectedWardList.length !== 0
                        ? wardListValues?.selectedWardList.map(value => ({
                              propertyId: PROPERTY_ID.ASSET.USING_DEP_ID,
                              values: [value],
                          }))
                        : wardListValues?.options.map(({ value }) => ({
                              propertyId: PROPERTY_ID.ASSET.USING_DEP_ID,
                              values: [value],
                          }))),
                ],
            },
        },
    });

    useEffect(() => {
        if (mode !== SCREEN_MODE_MONITORING) {
            return;
        }

        // 필수 값 체크
        if (!workTime.currentWorkTimeType && !dateValues.startDate && !dateValues.endDate) {
            return;
        }

        getHighRiskDailyInspectionList();
        getDailyInspectionList();
        getDailyCalendarList({
            startDate: moment.unix(dateValues.startDate).startOf('months').unix(),
            endDate: moment.unix(dateValues.endDate).endOf('months').unix(),
        });
    }, [wardListValues.selectedWardList.length, categoryValues.selectedCategoryList.length, dateValues.startDate]);

    // 새로고침
    const handleRefresh = () => {
        getDailyInspectionList();
    };

    // 고위험 점검 모달 handler
    const handleSelectedRow = row => {
        handleHighRiskToggle();
        setSelectedHighRisk(row);
    };

    const renderDayContents = (day, date) => {
        const dayOfUnix = moment(date).startOf('days').unix();
        const selectedDate = dateValues.startDate;
        const calendarCheckList = normalRiskCalendarState.response?.rows ?? [];

        if (normalRiskCalendarState.isLoading) {
            return <DateSkeleton />;
        }
        //선택된 날짜일 경우
        if (dayOfUnix === selectedDate) return day;

        const targetDate = calendarCheckList.find(({ date }) => date === dayOfUnix);

        if (targetDate && targetDate.date === dayOfUnix) {
            return (
                <DateContents
                    className={cx(targetDate.isChecked === 'Y' ? 'bg-secondary color-white' : 'bg-lightgray')}
                >
                    {day}
                </DateContents>
            );
        }

        return day;
    };
    const onMonthChange = date => {
        getDailyCalendarList({
            startDate: moment(date).startOf('months').unix(),
            endDate: moment(date).endOf('months').unix(),
        });
    };

    // 화면 편집모드 여부에 따라 예제 데이터 생성
    const highRiskRows = mode !== SCREEN_MODE_MONITORING ? ROWS_DUMMY : highRiskState.response?.rows || [];
    const normalRiskRows = mode !== SCREEN_MODE_MONITORING ? ROWS_DUMMY : normalRiskState.response?.rows || [];

    // 고위험 점검 column data
    const columns = useColumns(
        [
            column.index({ width: 40 }),
            column.inspectionResult({
                width: 100,
                Cell: ({ value, row }) => {
                    return value ? (
                        <span
                            className="text-primary text-decoration-underline cursor-pointer"
                            onClick={() => handleSelectedRow(row.original)}
                        >
                            {t(value)}
                        </span>
                    ) : (
                        <span className="d-flex justify-content-center align-center h-100">{DEFAULT_VALUE}</span>
                    );
                },
            }),
            column.categoryName(),
            column.assetName({ width: 150 }),
            column.targetId({ Header: t('Asset ID'), style: { textTransform: 'uppercase' }, width: 150 }),
            column.modelNo({ Header: t('modelNo'), accessor: 'modelName' }),
            {
                Header: t('Ward/Dept.'),
                accessor: 'usingDepName',
            },
            column.inspectionDate({
                accessor: 'checkDate',
                headerClassName: 'text-nowrap',
                className: 'text-nowrap text-ellipsis',
                Cell: ({ value }) =>
                    value ? (
                        <span title={moment.unix(value).format('YYYY-MM-DD HH:mm:ss')}>
                            {moment.unix(value).format('YYYY-MM-DD HH:mm:ss')}
                        </span>
                    ) : (
                        DEFAULT_VALUE
                    ),
            }),
            column.inspector(),
            column.inspectorUsername({
                Cell: ({ value }) => {
                    if (!value) {
                        return DEFAULT_VALUE;
                    }
                    if (value.indexOf('@') < 0) {
                        return value;
                    }
                    return value.substring(0, value.indexOf('@'));
                },
            }),
            {
                Header: t('Owner Ward'),
                accessor: 'wardName',
            },
        ],
        columnT,
        [highRiskRows],
    );

    return (
        <WidgetCard
            ref={widgetRef}
            widgetInfo={widgetInfo}
            subTitle={
                <QuestionTooltip
                    isFitContent
                    contents={
                        <div className={'text-start p-2'}>
                            <div>{t('During daily inspection, rented assets are also subject to inspection.')}</div>
                            <div>
                                {t(
                                    'Daily inspection and memo are reset at {{startTime}} every day.',
                                    ASSET_MANAGEMENT,
                                    {
                                        startTime: moment
                                            .unix(workTime?.workTimeSet?.DAY.startDate)
                                            .format(DATE_FORMAT.HM),
                                    },
                                )}
                            </div>
                            <div>
                                {t(
                                    'Modifications can be made even after the inspection is completed, and past date inspections can',
                                )}
                            </div>
                            <div>{t('be done through the calendar.')}</div>
                            <div>
                                {t(
                                    "'Today' is from {{startTime}} business days to {{endTime}} the next day.",
                                    ASSET_MANAGEMENT,
                                    {
                                        startTime: moment
                                            .unix(workTime?.workTimeSet?.DAY.startDate)
                                            .format(DATE_FORMAT.HM),
                                        endTime: moment
                                            .unix(workTime?.workTimeSet.NIGHT.endDate)
                                            .format(DATE_FORMAT.HM),
                                    },
                                )}
                            </div>
                        </div>
                    }
                />
            }
            searchFilter={
                !isInspectingTime && (
                    <SearchGroupAM
                        wardListValues={wardListValues}
                        categoryValues={categoryValues}
                        dateValues={dateValues}
                        renderDayContents={renderDayContents}
                        onMonthChange={onMonthChange}
                    />
                )
            }
            bodyClassName="pt-0"
            {...restProps}
        >
            {!isInspectingTime ? (
                <LoadingBlock blocking={highRiskState.isLoading || normalRiskState.isLoading}>
                    <div className="pnt-txt txt-bold s-5">{t('High risk inspection')}</div>
                    <div className={'d-flex flex-column h-30'}>
                        <Table
                            columns={columns}
                            data={{ rows: highRiskRows }}
                            paging={false}
                            noDataMessage={t('No items to check.')}
                        />
                        <div>
                            {`* ${t(
                                'High-risk inspections can only be registered on PDAs, and you can view the detailed inspection results by clicking on the inspection result.',
                            )}`}
                        </div>
                    </div>
                    <div className="d-flex align-center mt-2">
                        <div className="pnt-txt txt-bold s-5 mr-2">{t('Normal inspection')}</div>
                    </div>
                    <div className="d-flex flex-column h-60">
                        <NormalRiskInspectionList
                            data={normalRiskRows}
                            handleRefresh={handleRefresh}
                            isToday={validationToday}
                            targetDate={dateValues.startDate}
                        />
                    </div>
                    {isHighRiskOpen && (
                        <HighRiskModal
                            toggleModal={handleToggleHighLiskModal}
                            isOpen={isHighRiskOpen}
                            rowInfo={selectedHighRisk}
                        />
                    )}
                </LoadingBlock>
            ) : (
                <div className="fs-4 fw-bold d-flex flex-column justify-content-center align-items-center h-100">
                    <div>
                        {t('It is currently data inspection time.')}({moment.unix(inspectingStart).format('HH:mm')})~(
                        {moment.unix(inspectingEnd).format('HH:mm')})
                    </div>
                    <div>{t('It can be used after inspection time.')}</div>
                </div>
            )}
            {children}
        </WidgetCard>
    );
};

export default DailyInspection;

const DateContents = styled.span`
    display: flex;
    justify-content: center;
    align-content: center;
    min-width: 1.7rem;
    min-height: 1.7rem;
    border-radius: 50%;
    position: relative;
`;

const DateSkeleton = styled.div`
    min-width: 1.7rem;
    min-height: 1.7rem;
    background: #f0f0f0;
    border-radius: 8px;
    animation: pulse 1.5s infinite;
    pointer-events: none;
`;
