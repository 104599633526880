import { createSlice } from '@reduxjs/toolkit';
import { BATTERY_CHARGING, BATTERY_LEAD_OFF } from './constants';

export const initialState = {
    vitalSignsList: {},
    monitoringTargetNums: [],
    workTimeList: {
        origin: [],
        current: [],
    },
    isDetail: false,
    targetNum: null,
    wardMapInfo: {},
};

const { actions, reducer } = createSlice({
    name: 'vitalSignsMonitoringForHiCardiPlus',
    initialState,
    reducers: {
        setWardMapInfo: (state, action) => {
            state.wardMapInfo = action.payload || {};
        },
        storeVitalSignList: (state, action) => {
            const data = action.payload;
            if (!data) {
                return;
            }

            const monitoringTargetNums = [];

            state.vitalSignsList = (function loop(entryValues) {
                return Object.fromEntries(
                    Object.keys(entryValues).map(key => {
                        if (!Array.isArray(entryValues[key])) {
                            return [key, loop(entryValues[key])];
                        } else {
                            let person = {};
                            if (entryValues[key][0]) {
                                monitoringTargetNums.push(entryValues[key][0].targetNum);
                            }
                            entryValues[key].forEach(personData => {
                                person = {
                                    ...person,
                                    ...personData,
                                    properties: personData.properties,
                                    sensorNum: personData.sensorNum,
                                    [personData.sensingType]: {
                                        value: personData.currentValue,
                                        status: personData.status,
                                        measurementUnit: personData.measurementUnit,
                                        regDate: personData.regDate,
                                    },
                                    status: personData.status,
                                    targetId: personData.targetId,
                                    targetName: personData.targetName,
                                    targetNum: personData.targetNum,
                                    isBookmark: personData.isBookmark,
                                    modDate: personData.modDate,
                                };
                            });
                            return [key, person];
                        }
                    }),
                );
            })(data);

            state.monitoringTargetNums = monitoringTargetNums;
        },
        updateVitalSignList: (state, action) => {
            const wardMapInfo = state.wardMapInfo;

            const vitalSignList = action.payload;

            vitalSignList.forEach(vitalSignData => {
                if (!vitalSignData) {
                    return;
                }
                try {
                    const { target, sensingState, sensingValues, unixTime, status } = vitalSignData;
                    const { targetId, properties } = target;
                    const { ward, room, bed } = properties;

                    const wardName = wardMapInfo[ward];
                    if (!wardName) {
                        return;
                    }

                    const {
                        RESPIRATION_RATE: respirationRateState,
                        TEMPERATURE: temperatureState,
                        HEARTBEAT: heartbeatState,
                        BATTERY: batteryState,
                    } = sensingState;
                    const {
                        RESPIRATION_RATE: respirationRateValue,
                        TEMPERATURE: temperatureValue,
                        HEARTBEAT: heartbeatValue,
                        BATTERY: batteryValue,
                    } = sensingValues;

                    const socketRR =
                        (respirationRateValue || respirationRateValue === 0) &&
                        respirationRateState &&
                        batteryValue !== BATTERY_CHARGING
                            ? {
                                  value: respirationRateValue,
                                  status: respirationRateState,
                                  regDate: unixTime,
                              }
                            : {};
                    const socketBT =
                        (temperatureValue || temperatureValue === 0) &&
                        temperatureState &&
                        batteryValue !== BATTERY_CHARGING
                            ? {
                                  value: temperatureValue,
                                  status: temperatureState,
                                  regDate: unixTime,
                              }
                            : {};
                    const socketHB =
                        (heartbeatValue || heartbeatValue === 0) && heartbeatState && batteryValue !== BATTERY_CHARGING
                            ? {
                                  value: heartbeatValue,
                                  status: heartbeatState,
                                  regDate: unixTime,
                              }
                            : {};
                    const socketBattery =
                        batteryState && (batteryValue || batteryValue === 0)
                            ? {
                                  value: batteryValue,
                                  state: batteryState,
                                  regDate: unixTime,
                              }
                            : {};

                    if (state.vitalSignsList?.[ward]?.[room]?.[bed]?.[targetId] && batteryValue !== BATTERY_LEAD_OFF) {
                        const prevData = state.vitalSignsList[ward][room][bed][targetId];
                        const duplicatePrevData = { ...prevData };
                        if (duplicatePrevData.regDate <= unixTime) {
                            state.vitalSignsList[ward][room][bed][targetId] = {
                                ...prevData,
                                RESPIRATION_RATE: {
                                    ...prevData.RESPIRATION_RATE,
                                    ...socketRR,
                                },
                                HEARTBEAT: {
                                    ...prevData.HEARTBEAT,
                                    ...socketHB,
                                },
                                TEMPERATURE: {
                                    ...prevData.TEMPERATURE,
                                    ...socketBT,
                                },
                                BATTERY: {
                                    ...prevData.BATTERY,
                                    ...socketBattery,
                                },
                                status,
                                regDate: unixTime,
                            };
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            });
        },
        setIsDetail: (state, action) => {
            state.isDetail = action.payload;
        },
        setTargetNum: (state, action) => {
            state.targetNum = action.payload;
        },
    },
});
export const { setWardMapInfo, storeVitalSignList, updateVitalSignList, setIsDetail, setTargetNum } = actions;
export default reducer;
