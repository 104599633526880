import React, { useRef } from 'react';
import { setIsDetail } from './Context/slice';
import EnvironmentalSensorList from './Components/EnvironmentalSensorList';
import ViewDetail from './Components/ViewDetail';
import Button from '@components/Button';
import useTranslation from '@hooks/useTranslation';
import WidgetCard from '../../Components/WidgetCard';
import { useRealTimeEnvironmentalSensorContext, useRealTimeEnvironmentalSensorDispatch } from './Context';

/**
 * 실시간 환경 센서 위젯
 * */
const RealTimeEnvironmentalSensorContainer = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation();
    const { config } = widgetInfo;
    const widgetRef = useRef();
    const { isDetail, targetId } = useRealTimeEnvironmentalSensorContext();
    const dispatch = useRealTimeEnvironmentalSensorDispatch();

    return (
        <WidgetCard
            ref={widgetRef}
            widgetInfo={widgetInfo}
            headerAction={
                isDetail && (
                    <Button className="btn-lightgray" onClick={() => dispatch(setIsDetail(false))}>
                        {t('Back', 'Button')}
                    </Button>
                )
            }
            {...restProps}
        >
            {isDetail ? <ViewDetail targetId={targetId} /> : <EnvironmentalSensorList config={config} />}
            {children}
        </WidgetCard>
    );
};

export default RealTimeEnvironmentalSensorContainer;
