import Widget, { SERVICE_CODE, WIDGET_CATEGORY } from '../staticInfo';
import TIMES from '@util/times';
import AssetRentalStatus from './AssetRentalStatus';
import RealTimeLocationTracking from './RealTimeLocationTracking';
import RealTimeLocationTrackingSetting from './RealTimeLocationTracking/RealTimeLocationTrackingSetting';
import AssetDashboard from './AssetDashboard';
import AssetDashboardSetting from './AssetDashboard/AssetDashboardSetting';
import DailyInspection from './DailyInspection';
import DailyInspectionSetting from './DailyInspection/DailyInspectionSetting';
import AssetRentalStatusSetting from './AssetRentalStatus/AssetRentalStatusSetting';
import DailyInspectionHistoryCheck from './DailyInspectionHistoryCheck';
import AssetQuantityCheck from './AssetQuantityCheck';
import AssetQuantityCheckSetting from './AssetQuantityCheck/AssetQuantityCheckSetting';
import AssetQuantityCheckHistory from './AssetQuantityCheckHistory';
import AssetQuantityCheckHistorySetting from './AssetQuantityCheckHistory/AssetQuantityCheckHistorySetting';
import InstructionsManagement from './InstructionsManagement';
import MedicalWasteDashboard from './MedicalWasteDashboard';
import MedicalWasteDashboardSetting from './MedicalWasteDashboard/MedicalWasteDashboardSetting';
import MedicalWasteReadingStatus from './MedicalWasteReadingStatus';
import PatientCountStatus from './PatientCountStatus';
import MedicalWasteCollectionStatus from './MedicalWasteCollectionStatus';
import MedicalWasteCollectionStatusSetting from './MedicalWasteCollectionStatus/MedicalWasteCollectionStatusSetting';
import MedicalWasteTreatmentStatus from './MedicalWasteTreatmentStatus';
import BillingAmountManagement from './BillingAmountManagement';
import RentalManagement from './RentalManagement';
import RentalManagementSetting from './RentalManagement/RentalManagementSetting';
import RentalQuantity from './RentalAvailability';
import RentalHistory from './RentalHistory';
import RentalRequest from './RentalRequest';
import AssetManualManagement from './AssetManualManagement';
import AssetManualManagementSetting from './AssetManualManagement/AssetManualManagementSetting';

// [AM] 자산 대시보드
export const assetDashboard: Widget = {
    type: 'assetDashboard',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Asset Dashboard',
    desc: 'Asset Status Dashboard',
    thumbnailClassName: 'w_dashboard',
    Component: AssetDashboard,
    SettingComponent: AssetDashboardSetting,
    config: { defaultSettings: { name: 'Asset Dashboard', intervalTime: 10000 } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 23,
        h: 32,
        maxH: 46,
    },
};

// [AM] 자산 현황
export const assetRentalStatus: Widget = {
    type: 'assetRentalStatus',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Asset Rental Status',
    desc: "Check the status of the hospital's entire assets.",
    thumbnailClassName: 'w_search_table',
    Component: AssetRentalStatus,
    SettingComponent: AssetRentalStatusSetting,
    config: { defaultSettings: { name: 'Asset Status', intervalTime: 10000 } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};

// [AM] 실시간 위치 추적
export const realTimeLocationTracking: Widget = {
    type: 'realTimeLocationTracking',
    category: WIDGET_CATEGORY.MONITORING,
    name: 'Realtime Location Tracking',
    desc:
        'The map shows the targets that are located in the selected floor or in the geofence. You can also search a specific target and get detailed information about that specific target.',
    thumbnailClassName: 'w_filter_map',
    Component: RealTimeLocationTracking,
    SettingComponent: RealTimeLocationTrackingSetting,
    config: { defaultSettings: { name: 'Realtime Location Tracking', floor: '' } },
    layout: {
        minW: 10,
        w: 12,
        maxW: 12,
        minH: 23,
        h: 41,
        maxH: 45,
    },
};

// [AM] 일상 점검
export const dailyInspection: Widget = {
    type: 'dailyInspection',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Daily Inspection',
    desc: 'It shows the daily inspection check.',
    thumbnailClassName: 'w_search_table',
    Component: DailyInspection,
    SettingComponent: DailyInspectionSetting,
    config: { defaultSettings: { name: 'Daily Inspection' } },
    layout: {
        minW: 10,
        w: 12,
        maxW: 12,
        minH: 23,
        h: 41,
        maxH: 45,
    },
};

// [AM] 일상 점검 이력 조회
export const dailyInspectionHistoryCheck: Widget = {
    type: 'dailyInspectionHistoryCheck',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Daily Inspection History Check',
    desc: 'It shows the daily inspection history check.',
    thumbnailClassName: 'w_search_table',
    Component: DailyInspectionHistoryCheck,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Daily Inspection History Check' } },
    layout: {
        minW: 10,
        w: 12,
        maxW: 12,
        minH: 23,
        h: 41,
        maxH: 45,
    },
};

// [AM] 수량 점검
export const assetQuantityCheck: Widget = {
    type: 'assetQuantityCheck',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Asset Quantity Check',
    desc: 'It shows the asset quantity check.',
    thumbnailClassName: 'w_search_table',
    Component: AssetQuantityCheck,
    SettingComponent: AssetQuantityCheckSetting,
    config: { defaultSettings: { name: 'Asset Quantity Check' } },
    layout: {
        minW: 10,
        w: 12,
        maxW: 12,
        minH: 41,
        h: 42,
        maxH: 45,
    },
};

// [AM] 수량 점검 이력 조회
export const assetQuantityCheckHistory: Widget = {
    type: 'assetQuantityCheckHistory',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Asset Quantity History Check',
    desc: 'It shows the asset quantity history check.',
    thumbnailClassName: 'w_search_table',
    Component: AssetQuantityCheckHistory,
    SettingComponent: AssetQuantityCheckHistorySetting,
    config: { defaultSettings: { name: 'Asset Quantity History Check' } },
    layout: {
        minW: 10,
        w: 12,
        maxW: 12,
        minH: 23,
        h: 41,
        maxH: 45,
    },
};

// 의료폐기물 대시보드
export const medicalWasteDashboard: Widget = {
    type: 'medicalWasteDashboard',
    category: WIDGET_CATEGORY.MONITORING,
    name: 'Medical Waste Dashboard',
    desc: 'Shows a summary of medical waste generation information.',
    thumbnailClassName: 'w_chart_table',
    Component: MedicalWasteDashboard,
    SettingComponent: MedicalWasteDashboardSetting,
    config: { defaultSettings: { name: 'Medical Waste Dashboard', intervalTime: TIMES.HOUR_1, categoryCode: 'ASSET' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};

// 의료폐기물 리딩 상태 및 보관시간
export const medicalWasteReadingStatus: Widget = {
    type: 'medicalWasteReadingStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Medical Waste Reading Status',
    desc: 'It shows the medical waste reading status.',
    thumbnailClassName: 'w_chart_table',
    Component: MedicalWasteReadingStatus,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Medical Waste Reading Status' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 42,
        h: 42,
        maxH: 54,
    },
};

// 격리 및 일반환자 수 현황
const patientCountStatus: Widget = {
    type: 'patientCountStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Isolated and Normal Patient Count Status',
    desc: 'It shows the count status for isolated and normal patient.',
    thumbnailClassName: 'w_chart_table',
    Component: PatientCountStatus,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Isolated and Normal Patient Count Status' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 42,
        h: 42,
        maxH: 54,
    },
};

// 의료폐기물 수거 및 수거가능 수량
export const medicalWasteCollectionStatus: Widget = {
    type: 'medicalWasteCollectionStatus',
    category: WIDGET_CATEGORY.MONITORING,
    name: 'Medical Waste Collection Status',
    desc: 'See what can be collected and how much by medical waste type.',
    thumbnailClassName: 'w_chart_table',
    Component: MedicalWasteCollectionStatus,
    SettingComponent: MedicalWasteCollectionStatusSetting,
    config: { defaultSettings: { name: 'Medical Waste Collection Status', intervalTime: TIMES.MIN_1 * 3 } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 42,
        h: 42,
        maxH: 54,
    },
};

// 의료폐기물 처리 현황
const medicalWasteTreatmentStatus: Widget = {
    type: 'medicalWasteTreatmentStatus',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Medical Waste Treatment Status',
    desc: 'It shows the status for medical waste treatment status.',
    thumbnailClassName: 'w_chart_table',
    Component: MedicalWasteTreatmentStatus,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Medical Waste Treatment Status' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 42,
        h: 42,
        maxH: 54,
    },
};

// 설명서 관리
const instructionsManagement: Widget = {
    type: 'instructionsManagement',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Instructions Management',
    desc: 'It shows the management screen for instructions.',
    thumbnailClassName: 'w_chart_table',
    Component: InstructionsManagement,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Instructions Management' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 42,
        h: 42,
        maxH: 54,
    },
};

// 대여 관리
const rentalManagement: Widget = {
    type: 'rentalManagement',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Rental Management',
    desc: 'It shows the management screen for rental.',
    thumbnailClassName: 'w_search_table',
    Component: RentalManagement,
    SettingComponent: RentalManagementSetting,
    config: { defaultSettings: { name: 'Rental Management' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 42,
        h: 42,
        maxH: 54,
    },
};

// 대여 여부 설정
const rentalQuantity: Widget = {
    type: 'rentalQuantity',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Rental Quantity',
    desc: 'It shows the management screen for rental quantity.',
    thumbnailClassName: 'w_search_table',
    Component: RentalQuantity,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Rental Quantity' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 42,
        h: 42,
        maxH: 54,
    },
};

// 대여 이력 조회
const rentalHistory: Widget = {
    type: 'rentalHistory',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Rental History',
    desc: 'It shows the management screen for rental history.',
    thumbnailClassName: 'w_search_table',
    Component: RentalHistory,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Rental History' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 42,
        h: 42,
        maxH: 54,
    },
};

// 대여 신청
const rentalRequest: Widget = {
    type: 'rentalRequest',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Rental Request',
    desc: 'It shows the screen for rental request.',
    thumbnailClassName: 'w_search_table',
    Component: RentalRequest,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Rental Request' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 42,
        h: 42,
        maxH: 54,
    },
};

// 청구량 관리
const billingAmountManagement: Widget = {
    type: 'billingAmountManagement',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Billing Amount Management',
    desc: 'It shows the management screen for billing amount management.',
    thumbnailClassName: 'w_chart_table',
    Component: BillingAmountManagement,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Billing Amount Management' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 42,
        h: 42,
        maxH: 54,
    },
};

export const assetManualManagement: Widget = {
    type: 'assetManualManagement',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    name: 'Asset Manual Management',
    desc: 'It views or manages asset manuals',
    thumbnailClassName: 'w_search_table',
    Component: AssetManualManagement,
    SettingComponent: AssetManualManagementSetting,
    config: { defaultSettings: { name: 'Asset Manual Management', managementMode: true } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 42,
        maxH: 54,
    },
};

const amWidgetList: Record<string, Widget> = {
    /* [AM] 자산관리 */
    assetDashboard,
    assetRentalStatus,
    realTimeLocationTracking,
    dailyInspection,
    dailyInspectionHistoryCheck,
    assetQuantityCheck,
    assetQuantityCheckHistory,
    rentalManagement,
    rentalQuantity,
    rentalHistory,
    rentalRequest,

    /* [강동 경희대학병원 폐기물] */
    medicalWasteDashboard,
    medicalWasteReadingStatus,
    patientCountStatus,
    medicalWasteCollectionStatus,
    medicalWasteTreatmentStatus,
    instructionsManagement,
    // billingAmountManagement, // 2024.03 출시 예정

    assetManualManagement,
};

for (const widget in amWidgetList) {
    if (amWidgetList.hasOwnProperty(widget)) {
        amWidgetList[widget].serviceCode = SERVICE_CODE.AM;
    }
}

export default amWidgetList;
