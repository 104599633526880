import { editWidgetSettings } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import React, { useContext, useState } from 'react';
import useTranslation from '@hooks/useTranslation';
import WidgetSettingModal from '../../Components/WidgetSettingModal';
import { ScreenEditDispatchContext } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit';
import { ASSET_MANAGEMENT } from '../../constants';

const DailyInspectionSetting = ({ widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const dispatch = useContext(ScreenEditDispatchContext);
    const { settings: customSettings } = config;
    const t = useTranslation(ASSET_MANAGEMENT);

    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    const handleSubmitClick = data => {
        const checkedSettings = customSettings
            ? { ...customSettings, ...data, ...updatedWidgetInfo }
            : { ...data, ...updatedWidgetInfo };

        const updatedWidgetSettings = {
            id: widgetInfo.id,
            settings: checkedSettings,
        };

        dispatch(editWidgetSettings(updatedWidgetSettings));
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    return (
        <WidgetSettingModal
            headerTitle={t('Setting For Daily Inspection')}
            widgetInfo={widgetInfo}
            socketWidget
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        ></WidgetSettingModal>
    );
};

export default DailyInspectionSetting;
