import React from 'react';
import widgetList from '../../../../Widgets';
import { SCREEN_MODE_EDIT } from '@reducer/ScreenInfo';

export const getUsableWidgetInfo = type => widgetList[type] || widgetList.temp;

export const widgetDiscriminator = widgetInfo => {
    const { id, type } = widgetInfo;
    const props = { key: id, widgetInfo: widgetInfo };
    const { Component } = getUsableWidgetInfo(type);
    return Component ? <Component {...props} /> : null;
    // switch (config.type) {
    //     case 'tagStatus':
    //         return <TagStatus {...props} />;
    //     case 'temp':
    //     default:
    //         return <TemporaryWidget {...props} />;
    // }
};

export const makeLayoutsData = (layout, mode) => {
    const looseLayout = mode === SCREEN_MODE_EDIT ? layout : layout.map(v => ({ ...v, minW: 0, minH: 0 }));
    return {
        lg: looseLayout,
        md: looseLayout,
        sm: looseLayout,
        xs: looseLayout,
        xxs: looseLayout,
    };
};

export const getMaxY = layouts => {
    let max = 0;
    layouts.forEach(v => {
        max = Math.max(max, v.y + v.x);
    });
    return max;
};

// widgetInfo 값을 json 파일로 만들어서 export 한다.
//3번째 인자 exportWidgetInfo의  데이터는  JSON.parse 되어있어야한다.
export const widgetInfoExport = ({ typeOf = 'application/json', filename = 'widgetInfo.json', exportWidgetInfo }) => {
    let body = document.body; // body 변수 선언
    const a = document.createElement('a'); // a 태그 생성
    a.href = URL.createObjectURL(
        new Blob([JSON.stringify(exportWidgetInfo, null, 2)], {
            type: typeOf,
        }),
    );
    a.setAttribute('download', filename); // a 태그에 다운로드 속성 추가
    body.appendChild(a); // body에 a 태그 추가
    a.click(); // 클릭 이벤트를 발생시켜 다운로드
    body.removeChild(a); // body에서 제거
};
