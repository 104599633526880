import React from 'react';
import InnerItemCard from '@components/Card/InnerItemCard';
import { useTranslation } from '@hooks';
import { MEDICAL_WASTE } from '../../../constants';
import { Bar, Line } from 'react-chartjs-2';
import { CHART_COLORS } from '@components/Charts/config';

const barChartOptions = {
    plugins: {
        legend: {
            position: 'bottom',
        },
    },
    scales: {
        y: {
            ticks: {
                stepSize: 10,
            },
        },
    },
};

const lineChartOptions = {
    plugins: {
        legend: {
            position: 'bottom',
            labels: {
                boxHeight: 1,
            },
        },
    },
    scales: {
        x: {
            offset: true,
        },
        y: {
            beginAtZero: true,
            ticks: {
                stepSize: 10,
            },
        },
    },
};

const LABEL_LIST = [
    {
        labelName: 'Isolated patient',
        dataName: 'isolatedPatientCount',
    },
    {
        labelName: 'Normal patient',
        dataName: 'normalPatientCount',
    },
];

const ChartList = ({ chartData }) => {
    const t = useTranslation(MEDICAL_WASTE);

    const barChartData = {
        labels: chartData.map(ele => ele.date),
        datasets: LABEL_LIST.map(({ labelName, dataName }, i) => ({
            label: t(labelName),
            data: chartData.map(ele => ele[dataName]),
            backgroundColor: CHART_COLORS[i],
        })),
    };

    const lineChartData = {
        labels: chartData.map(ele => ele.date),
        datasets: [
            {
                label: t('Waste count'),
                data: chartData.map(ele => ele.wasteCount),
                borderColor: CHART_COLORS[0],
                backgroundColor: CHART_COLORS[0],
            },
        ],
    };

    return (
        <div className="d-flex gap-3">
            <InnerItemCard header={{ title: t('Isolated and Normal Patient Status') }} className="w-50">
                <Bar options={barChartOptions} data={barChartData} />
            </InnerItemCard>
            <InnerItemCard header={{ title: t('Waste Status') }} className="w-50">
                <Line options={lineChartOptions} data={lineChartData} />
            </InnerItemCard>
        </div>
    );
};

export default ChartList;
