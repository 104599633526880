import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const initialState = {
    selectedFloor: [],
    selectedFloorInfo: {},
    startDate: moment().subtract(1, 'days').startOf('day').unix(),
    endDate: moment().subtract(1, 'days').endOf('day').unix(),
    playTime: null,
    isPlaying: false,
    speed: 1,
    congestionData: [],
};

const outpatientCongestionSlice = createSlice({
    name: 'outpatientCongestion',
    initialState,
    reducers: {
        setSelectedFloorInfo: (state, action) => {
            const floorInfo = action.payload;
            return { ...initialState, selectedFloorInfo: floorInfo, selectedFloor: floorInfo.floorId };
        },
        setStartDate: (state, action) => {
            state.isPlaying = false;
            state.playTime = null;
            state.speed = 1;
            state.startDate = action.payload;
        },
        setEndDate: (state, action) => {
            state.isPlaying = false;
            state.playTime = null;
            state.speed = 1;
            state.endDate = action.payload;
        },
        setPlayTime: (state, action) => {
            if (action.payload >= state.endDate) {
                state.isPlaying = false;
                state.playTime = state.startDate;
                return;
            }
            state.playTime = action.payload;
        },
        setCongestionData: (state, action) => {
            state.congestionData = action.payload;
        },
        setIsPlaying: (state, action) => {
            state.isPlaying = action.payload;
        },
        setSpeed: (state, action) => {
            state.speed = action.payload;
        },
    },
});

export const {
    setStartDate,
    setEndDate,
    setPlayTime,
    setMethod,
    setDepartment,
    setCongestionData,
    setGeofenceInfo,
    setSelectedFloorInfo,
    setIsPlaying,
    setSpeed,
} = outpatientCongestionSlice.actions;
export default outpatientCongestionSlice;
