import React from 'react';
import { Highlighter } from 'react-bootstrap-typeahead';
import useTargetTitle from './useTargetTitle';

const MenuItem = ({ option, searchText }) => {
    const title = useTargetTitle(option);

    return (
        <span className={'text-ellipsis'} title={title}>
            <Highlighter search={searchText}>{title}</Highlighter>
        </span>
    );
};

export default MenuItem;
