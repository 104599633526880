import React, { useMemo, useRef } from 'react';

import useTranslation from '@hooks/useTranslation';
import useAsync from '@hooks/useAsync';
import { getVitalSignsListOfPatronApi } from '@api/monitoring';
import Button from '@components/Button';
import { LoadingBlock } from '@components';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_NUMERIC_SENSING } from '@reducer/SocketInfo';

import { eachUpdateVitalSignList, setIsDetail, storeVitalSignList } from './vitalSignsRealTimePartronSlice';
import WidgetCard from '../../Components/WidgetCard';
import ViewDetail from './Components/ViewDetail';
import VitalSignList from './Components/VitalSignsCardList';
import { useVitalSignsRealTimePartronContext, useVitalSignsRealTimePartronDispatch } from './index';

const BATTERY_CHARGING = 255;

const VitalSignsRealTimePartronContainer = ({ widgetInfo, children, ...restProps }) => {
    const buttonT = useTranslation('Button');
    const widgetRef = useRef();
    const state = useVitalSignsRealTimePartronContext();
    const dispatch = useVitalSignsRealTimePartronDispatch();
    const { monitoringTargetNums } = state;

    // 활력징후 센서 데이터 초기 호출
    const { state: vitalSignsList } = useAsync({
        promise: getVitalSignsListOfPatronApi,
        immediate: true,
        resolve: res => {
            dispatch(storeVitalSignList(res));
        },
        reject: () => {},
    });

    const filterConfig = useMemo(() => {
        return monitoringTargetNums.length
            ? { target: { targetNum: { $in: monitoringTargetNums } } }
            : { target: { targetNum: 'noneTarget' } };
    }, [monitoringTargetNums]);

    useSocketEvent({
        name: EVENT_TYPE_NUMERIC_SENSING,
        handler: data => {
            const { sensor, unixTime, sensingValues } = data;
            const sensingValue = {};
            let infoValue = { handOff: 1, sos: 0 };
            Object.keys(sensingValues).forEach(key => {
                sensingValue[key] = sensingValues[key];
                // 충전중 === 미착용
                if (key === 'BATTERY' && sensingValues[key] === BATTERY_CHARGING) {
                    infoValue = { handOff: 0, sos: 0 };
                }
            });
            dispatch(
                eachUpdateVitalSignList({
                    infoValue,
                    deviceId: sensor.sensorId,
                    sensingValue,
                    regDate: unixTime,
                }),
            );
        },
        filterConfig,
    });

    return (
        <WidgetCard
            ref={widgetRef}
            widgetInfo={widgetInfo}
            headerAction={
                state.isDetail && (
                    <Button className="btn-gray border-h" onClick={() => dispatch(setIsDetail(false))}>
                        {buttonT('Back')}
                    </Button>
                )
            }
            {...restProps}
        >
            <LoadingBlock blocking={vitalSignsList.isLoading}>
                {state.isDetail ? <ViewDetail targetId={state.targetId} /> : <VitalSignList />}
            </LoadingBlock>
            {children}
        </WidgetCard>
    );
};

export default VitalSignsRealTimePartronContainer;
