import React, { useEffect, useState } from 'react';
import { Table } from '@components';
import { useAsync, useColumns } from '@hooks';
import * as column from '../../../../MainPages/util/grid/column';
import { fetchSensingTypeMetas } from '@api/monitoring';
import { useSelector } from 'react-redux';
import useTranslation from '@hooks/useTranslation';

/**
 *
 * 센서 로그 테이블 생성 컴포넌트
 *
 * @param {Object} list 센서 로그 리스트 데이터
 * @param {Object} state 센서 아이템 정보 로그 리스트 API 호출 상태`값
 * @param {function} promise 센서 아이템 정보 로그 리스트 호출 callback
 *
 * @author jinbeom-jung
 * */

const List = ({ list, state, promise }) => {
    const t = useTranslation('CommonColumn');
    const secondT = useTranslation('');
    const [sensorColumns, setSensorColumns] = useState([]);
    const { lang } = useSelector(state => state.UserInfo);
    const { promise: getSensingTypeMetas, state: columnsState } = useAsync({
        promise: fetchSensingTypeMetas,
        resolve: res => {
            const { rows } = res;
            const result = rows.map(({ sensingType, language }, idx) => {
                const header = language ? language : '-';
                const accessor = sensingType ? sensingType : `-${idx}`;
                return {
                    Header: header,
                    accessor: accessor,
                    className: 'text-ellipsis',
                    headerClassName: 'text-ellipsis',
                    Cell: ({ value }) => {
                        return value ? <span title={value}>{value}</span> : '-';
                    },
                    t: secondT,
                };
            });
            setSensorColumns(result);
        },
        reject: err => console.error(err),
    });

    useEffect(() => {
        getSensingTypeMetas({ localType: lang });
    }, [lang]);

    const columns = useColumns(
        [
            column.number(),
            column.sensorId(),
            column.sensorName(),
            column.sensorType(),
            ...sensorColumns,
            column.registeredDate(),
        ],
        t,
        [sensorColumns],
    );

    const handlePageChange = pageIndex => {
        const param = state.request;
        let result = {};
        for (let key in param) {
            if (param[key]) {
                result[key] = param[key];
            }
        }
        promise({ ...result, page: pageIndex });
    };

    return (
        <div style={{ height: '35rem' }}>
            <Table
                data={{ ...list, pageSize: 15 }}
                columns={columns}
                onPageChange={handlePageChange}
                loading={state.isLoading || columnsState.isLoading}
                className={'table-for-viewer'}
            />
        </div>
    );
};

export default List;
