import { OptionType } from '@components/type';

export const columMetas: { key: string; name: string }[] = [
    {
        key: 'groupName',
        name: 'Ward/Dept',
    },
    {
        key: 'modelGroupName',
        name: 'Device Group Name',
    },
    {
        key: 'sensorName',
        name: 'Device name',
    },
    {
        key: 'january',
        name: 'January',
    },
    {
        key: 'february',
        name: 'February',
    },
    {
        key: 'march',
        name: 'March',
    },
    {
        key: 'april',
        name: 'April',
    },
    {
        key: 'may',
        name: 'May',
    },
    {
        key: 'june',
        name: 'June',
    },
    {
        key: 'july',
        name: 'July',
    },
    {
        key: 'august',
        name: 'August',
    },
    {
        key: 'september',
        name: 'September',
    },
    {
        key: 'october',
        name: 'October',
    },
    {
        key: 'november',
        name: 'November',
    },
    {
        key: 'december',
        name: 'December',
    },
    {
        key: 'total',
        name: 'Total usage by device',
    },
];

export const SENSOR_OPTION: OptionType[] = [
    {
        label: 'Sphygmomanometers',
        value: 'BLOOD_PRESSURE_MONITOR',
    },
    {
        label: 'Contactless thermometer',
        value: 'NON_CONTACT_THERMOMETER',
    },
    {
        label: 'Contact thermometer',
        value: 'CONTACT_THERMOMETER',
    },
    {
        label: 'Blood glucose meter',
        value: 'GLUCOSE_MONITOR',
    },
    {
        label: 'IV',
        value: 'IV_DRIP',
    },
    {
        label: 'Scale',
        value: 'SCALE',
    },
    {
        label: 'Height scale',
        value: 'HEIGHT_MEASURING_DEVICE',
    },
];
