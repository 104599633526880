import { Range } from 'rc-slider';
import React, { forwardRef, useEffect, useState } from 'react';
import useTranslation from '@hooks/useTranslation';
import cx from 'classnames';
import styles from '@asset/pnt/widgets/RefrigeratorSensor.module.scss';

const Slider = ({ defaultValue, min, max, measurementUnit }, ref) => {
    const t = useTranslation('RefrigeratorSensor');
    const [range, setRange] = useState({});
    const { minWarning, minCaution, maxCaution, maxWarning } = range;

    const createMarks = () => {
        let marks = {};

        for (const value of defaultValue) {
            marks[value] = value + measurementUnit;
        }

        marks[min] = min + measurementUnit;
        marks[max] = max + measurementUnit;

        return marks;
    };

    function handleChange(value) {
        const [minWarning, minCaution, maxCaution, maxWarning] = value;

        setRange(prev => {
            const currentMinWarning = min >= minWarning ? minWarning + 0.1 : minWarning;
            const currentMaxWarning = max <= maxWarning ? maxWarning - 0.1 : maxWarning;
            return {
                ...prev,
                minWarning: currentMinWarning,
                minCaution,
                maxCaution,
                maxWarning: currentMaxWarning,
            };
        });
    }

    useEffect(() => {
        const [minWarning, minCaution, maxCaution, maxWarning] = defaultValue;
        setRange({ min, minWarning, minCaution, maxCaution, maxWarning, max });
    }, []);

    return (
        <>
            <div className="d-flex mb-2">
                <div className={cx(styles.slider_label, 'bg-depth-7')}>
                    <div>{t('Minimum')}</div>
                    <div>{min}</div>
                </div>
                <div className={cx(styles.slider_label, 'bg-depth-7')}>
                    <div>{t('WARNING')}</div>
                    <div>{minWarning}</div>
                </div>
                <div className={cx(styles.slider_label, 'bg-depth-7')}>
                    <div>{t('CAUTION')}</div>
                    <div>{minCaution}</div>
                </div>
                <div className={cx(styles.slider_label, 'bg-depth-7')}>
                    <div>{t('CAUTION')}</div>
                    <div>{maxCaution}</div>
                </div>
                <div className={cx(styles.slider_label, 'bg-depth-7')}>
                    <div>{t('WARNING')}</div>
                    <div>{maxWarning}</div>
                </div>
                <div className={cx(styles.slider_label, 'bg-depth-7')}>
                    <div>{t('Maximum')}</div>
                    <div>{max}</div>
                </div>
            </div>
            <div className="mx-2">
                <Range
                    ref={ref}
                    min={min}
                    max={max}
                    marks={createMarks()}
                    defaultValue={defaultValue}
                    pushable={1}
                    allowCross={false}
                    trackStyle={[
                        { backgroundColor: 'orange' },
                        { backgroundColor: 'green' },
                        { backgroundColor: 'orange' },
                    ]}
                    handleStyle={[
                        { backgroundColor: 'red', marginLeft: '0', border: '1px solid red' },
                        { backgroundColor: 'orange', marginLeft: '0', border: '1px solid orange' },
                        { backgroundColor: 'orange', marginLeft: '0', border: '1px solid orange' },
                        { backgroundColor: 'red', marginLeft: '0', border: '1px solid red' },
                    ]}
                    railStyle={{ backgroundColor: 'red' }}
                    onChange={handleChange}
                    step={0.1}
                />
            </div>
        </>
    );
};

export default forwardRef(Slider);
