import { getPermittedState, isIn, OCCUR_STATE_INFO, TAG_STATE_LABEL } from '@util/mappInfo';

export const convertState = (state, mappInfo = OCCUR_STATE_INFO) => {
    const convertedState = {};
    for (let key in state) {
        if (mappInfo[key]) {
            convertedState[key] = mappInfo[key][state[key]];
        }
    }
    return convertedState;
};

export const setTagStatus = (tagStatus, tagInfo) => {
    const targetNum = tagInfo.target.targetNum;
    const state = tagInfo.state;
    for (let key in tagStatus) {
        const status = tagStatus[key];
        const existIdx = status.indexOf(targetNum);
        if (existIdx > -1) {
            status.splice(existIdx, 1);
        }
    }
    for (let key in state) {
        const isOn = state[key];
        if (isOn) {
            if (tagStatus[key]) {
                tagStatus[key].push(targetNum);
            } else {
                tagStatus[key] = [targetNum];
            }
        }
    }
};
export const calcCategoryStatus = (categoryStatus, tagInfo) => {
    const targetNum = tagInfo.target.targetNum;
    const target = tagInfo.target;
    const lostSignal = tagInfo.state.lostSignal;
    const tagCategory = target.parentCode || target.categoryCode;
    for (let key in categoryStatus) {
        const status = categoryStatus[key];
        const existIdx = status.indexOf(targetNum);
        if (existIdx > -1) {
            status.splice(existIdx, 1);
        }
    }
    if (!lostSignal) {
        if (categoryStatus[tagCategory]) {
            categoryStatus[tagCategory].push(targetNum);
        } else {
            categoryStatus[tagCategory] = [targetNum];
        }
    }
};
export const setTagAlarmList = (tagAlarmList, tagInfo) => {
    const targetNum = tagInfo.target.targetNum;
    const { state, recovery, target } = tagInfo;
    const alarmRowId = createAlarmId(state, targetNum);
    const recoveryRowId = createAlarmId(recovery, targetNum);
    alarmRowId.forEach(alarmId => {
        const existIdx = tagAlarmList.findIndex(v => v.alarmId === alarmId);
        if (existIdx > -1) {
            tagAlarmList.splice(existIdx, 1);
        }
        const tagState = alarmId.split('_')[0];
        tagAlarmList.unshift({
            ...tagInfo,
            alarmId,
            targetId: target.targetId,
            targetName: target.targetName,
            tagState,
            tagStateLabel: TAG_STATE_LABEL[tagState],
            categoryName: target.categoryName,
        });
    });
    recoveryRowId.forEach(alarmId => {
        const existIdx = tagAlarmList.findIndex(v => v.alarmId === alarmId);
        if (existIdx > -1) {
            tagAlarmList.splice(existIdx, 1);
        }
    });
};
export const createAlarmId = (state, targetNum) => {
    const rowId = [];
    for (let key in state) {
        if (state[key]) {
            rowId.push(`${key}_${targetNum}`);
        }
    }
    return rowId;
};
export const setCompactGeofenceInOutState = stateMap => {
    const compactState = {};
    for (let fcNum in stateMap) {
        const state = stateMap[fcNum];
        compactState[fcNum] = {
            fcNum: Number(fcNum),
            fcName: state.name,
            isIn: isIn(state.state),
            inTime: state.inTime,
            stayTime: state.stayTime,
            outTime: state.outTime,
            permitted: getPermittedState(state.permitted),
            fcGroupNum: state.fcGroupNum,
            fcGroupName: state.fcGroupName,
        };
    }
    return compactState;
};
export const setFloorInOutState = (floorInOutState, tagInfo) => {
    const targetNum = tagInfo.target.targetNum;
    const tagFloorInOutState = tagInfo.floorInOutState;

    // 기존에 있는 정보에서 태그 정보를 찾아서 지운다
    for (let floorId in floorInOutState) {
        const floorInList = floorInOutState[floorId];
        for (let auth in floorInList) {
            const floorInAuthList = floorInList[auth];
            const existIdx = floorInAuthList.indexOf(targetNum);
            if (~existIdx) {
                floorInAuthList.splice(existIdx, 1);
            }
        }
    }

    // 새로운 태그 정보가 신호상실 상태가 아니고 층에 진입한 경우 정보를 넣어준다
    const { lostSignal } = tagInfo.state;
    const { isIn, floorId, permitted } = tagFloorInOutState;
    if (!lostSignal && isIn) {
        if (!floorInOutState[floorId]) {
            floorInOutState[floorId] = { authorized: [], unAuthorized: [] };
        }
        floorInOutState[floorId][permitted].push(targetNum);
    }
    // for (let floorId in tagFloorInOutState) {
    //     const inOutState = tagFloorInOutState[floorId];
    //     if (inOutState.isIn) {
    //         if (!floorInOutState[floorId]) {
    //             floorInOutState[floorId]= [];
    //         }
    //         floorInOutState[floorId].push({ [targetNum]: inOutState.permitted });
    //     }
    // }
};

export const setGeofenceInOutState = (geofenceInOutState, tagInfo) => {
    const targetNum = tagInfo.target.targetNum;
    const tagGeofenceInOutState = tagInfo.geofenceInOutState;

    // 기존에 있는 정보에서 태그 정보를 찾아서 지운다
    for (let fcNum in geofenceInOutState) {
        const geofenceInList = geofenceInOutState[fcNum];
        for (let auth in geofenceInList) {
            const geofenceInAuthList = geofenceInList[auth];
            const existIdx = geofenceInAuthList.indexOf(targetNum);
            if (~existIdx) {
                geofenceInAuthList.splice(existIdx, 1);
            }
        }
    }

    // 새로운 태그 정보가 신호상실 상태가 아니고 지오펜스에 진입한 경우 정보를 넣어준다
    // 여러개의 지오펜스 동시에 in일 경우가 있다
    const { lostSignal } = tagInfo.state;
    for (let fcNum in tagGeofenceInOutState) {
        const tagOneGeofenceInOutState = tagGeofenceInOutState[fcNum];
        const { isIn, permitted } = tagOneGeofenceInOutState;
        if (!lostSignal && isIn) {
            if (!geofenceInOutState[fcNum]) {
                geofenceInOutState[fcNum] = { authorized: [], unAuthorized: [] };
            }
            geofenceInOutState[fcNum][permitted].push(targetNum);
        }
    }
};

export const setTagListByFloor = state => {
    const tagList = state.tagList;
    const newTagListByFloor = {};
    for (let targetNum in tagList) {
        if (tagList.hasOwnProperty(targetNum)) {
            const tagInfo = tagList[targetNum];
            const { lostSignal } = tagInfo.state;
            if (!lostSignal) {
                const byFloor = newTagListByFloor[tagInfo.location.floor];
                if (byFloor) {
                    byFloor.push(tagInfo);
                } else {
                    newTagListByFloor[tagInfo.location.floor] = [tagInfo];
                }
            }
        }
    }
    state.tagListByFloor = newTagListByFloor;
};

export const setTagList = (tagList, tagInfo) => {
    const {
        target: { targetNum },
        state: { lostSignal },
    } = tagInfo;
    if (!lostSignal) {
        tagList[targetNum] = tagInfo;
    } else {
        tagList[targetNum] = null;
        delete tagList[targetNum];
    }
};
