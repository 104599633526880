import React, { useEffect, useState } from 'react';
import useTranslation from '@hooks/useTranslation';
import useAsync from '@hooks/useAsync';
import { updateUnmapIVInjectionMonitoringApi } from '@api/monitoring';
import { Button, ConfirmModal } from '@components';

const RESULT_CONFIRM_MESSAGE_KEY = {
    SUCCESS: 'Unmapping succeeded.',
    FAIL: 'Unmapping failed.',
};

const UnmapButton = ({ className, IVInjection, handleCallbackApi }) => {
    const t = useTranslation('IVInjectionMonitoring');
    // 수액 unmapping 확인 모달 toggle
    const [confirmModal, setConfirmModal] = useState(false);
    const toggleUpdateConfirmModal = () => {
        setConfirmModal(prev => !prev);
    };
    // 수액 unmapping 결과 확인 모달 toggle
    const [resultUnmap, setResultUnmap] = useState(RESULT_CONFIRM_MESSAGE_KEY.FAIL);
    const [resultConfirmModal, setResultConfirmModal] = useState(false);
    const toggleUpdateResultConfirmModal = () => {
        setResultConfirmModal(prev => !prev);
    };

    // 수액 센서 언맵핑 Api
    const { promise: updateUnmapIVInjectionMonitoring } = useAsync({
        promise: updateUnmapIVInjectionMonitoringApi,
        resolve: () => {
            setResultUnmap(RESULT_CONFIRM_MESSAGE_KEY.SUCCESS);
            toggleUpdateResultConfirmModal();
            // 언맵핑 후 수액 리스트 갱신
            handleCallbackApi();
        },
        reject: () => {
            setResultUnmap(RESULT_CONFIRM_MESSAGE_KEY.FAIL);
            toggleUpdateResultConfirmModal();
        },
    });

    useEffect(() => {
        return () => {
            setConfirmModal(false);
            setResultConfirmModal(false);
        };
    }, []);

    return (
        <>
            <Button
                className="btn-icon-only btn-trans"
                iconName="link_off"
                onClick={e => {
                    e.stopPropagation();
                    toggleUpdateConfirmModal();
                }}
            />
            <ConfirmModal
                initModal={confirmModal}
                toggleModal={toggleUpdateConfirmModal}
                confirmText={t('Are you sure you want to unmap the selected IV sensor?', 'ConfirmModal')}
                okCallback={updateUnmapIVInjectionMonitoring}
                callbackParam={IVInjection}
            />
            <ConfirmModal
                header={{ title: t('Result', 'ConfirmModal') }}
                initModal={resultConfirmModal}
                toggleModal={toggleUpdateResultConfirmModal}
                confirmText={t(resultUnmap, 'ConfirmModal')}
            />
        </>
    );
};

export default UnmapButton;
