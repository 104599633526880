import React, { useState } from 'react';
import { Button, TextInput } from '@components';
import { useTranslation } from '@hooks';
import SearchGroup from '../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import { InputGroup, InputWrap, SearchWrap } from '../../../MainPages/Components/FilterSearchGroup/Components/Part';
import SearchButtonArea from '../../../MainPages/Components/FilterSearchGroup/Components/SearchButtonArea';
import cx from 'classnames';

const Search = ({ handleReset, handleSearchClick, isLoading, placeholder }) => {
    const t = useTranslation('AssetManagement');

    const [input, setInput] = useState('');
    const handleSearchInput = e => {
        const { value } = e.target;
        setInput(value);
    };

    const handleSearch = () => {
        handleSearchClick(input);
    };

    const handleRefresh = () => {
        handleReset();
        setInput('');
    };

    const handleKeyUp = e => {
        if (e.key?.toUpperCase() === 'ENTER') {
            handleSearch(input);
        }
    };

    return (
        <SearchGroup>
            <SearchWrap className={cx(isLoading && 'disabled')}>
                <InputWrap>
                    <InputGroup>
                        <TextInput
                            type="text"
                            name="target"
                            value={input}
                            handleKeyUp={handleKeyUp}
                            handleChange={handleSearchInput}
                            placeholder={placeholder ?? t('Enter the name or ID of the asset.')}
                        />
                    </InputGroup>
                </InputWrap>
                <SearchButtonArea>
                    <Button className="btn-secondary" onClick={handleSearch}>
                        {t('Search', 'Search')}
                    </Button>
                </SearchButtonArea>
                <Button className="btn-line btn-icon-only ml-2" iconName="refresh" onClick={handleRefresh} />
            </SearchWrap>
        </SearchGroup>
    );
};

export default Search;
