import React from 'react';
import Control from 'react-leaflet-control';
import { Button, GeofenceLayer } from '../../../../Common';
import { LayersControl } from 'react-leaflet';
import FloorDetailPopup from './FloorDetailPopup';
import FilteringPopup from './FilteringPopup';
import { useRealTimeLocationStatusContext, useRealTimeLocationStatusDispatch } from '../index';
import { setFloorInfo, setPopup, setSelectedBuilding, setSelectedFloor } from '../realTimeLocationSlice';
import { useTranslation } from '@hooks';
import FloorGroupSelect from './FloorGroupSelect';

const AllTarget = ({ widgetRef, mapRef }) => {
    const t = useTranslation('RealTimeLocationStatus');

    const { selectedFloor, selectedBuilding, geofenceInfo, popup, realTimeLog } = useRealTimeLocationStatusContext();
    const dispatch = useRealTimeLocationStatusDispatch();

    const handleFloorChange = selected => {
        if (selected) {
            dispatch(setSelectedFloor(selected.floorId));
            dispatch(setFloorInfo(selected));
        }
    };

    const handleBuildingChange = selected => {
        dispatch(setSelectedBuilding(selected.floorId));

        dispatch(setSelectedFloor(selected.floorId));
        dispatch(setFloorInfo(selected));
    };

    const handlePopupClick = (name, value) => {
        dispatch(setPopup({ [name]: !value }));
    };

    const handleModalToggle = value => {
        dispatch(setPopup({ [value]: !popup[value] }));
    };
    return (
        <>
            <Control position="topleft" className={'control-container'}>
                <Button
                    className={'leaflet-map-btn btn-icon'}
                    iconName="info_outline"
                    onClick={() => handlePopupClick('floorDetailPopup', popup.floorDetailPopup)}
                >
                    {t('Detail')}
                </Button>

                <Button
                    className={'leaflet-map-btn btn-icon'}
                    iconName="tune"
                    onClick={() => handlePopupClick('filteringPopup', popup.filteringPopup)}
                >
                    {t('Filter')}
                </Button>
                <FloorGroupSelect
                    buildingId={selectedBuilding}
                    floorId={selectedFloor}
                    handleBuildingChange={handleBuildingChange}
                    handleFloorChange={handleFloorChange}
                />
            </Control>

            <Control position="bottomleft" className="control-container">
                <div className="pnt-nameplate leaflet-map-nameplate mw-100">
                    <span className="material-icons-round">info_outline</span>
                    <div>{`${t('Total Number of Items Detected')} : ${realTimeLog.count}`}</div>
                </div>
            </Control>

            <LayersControl position={'topright'}>
                <LayersControl.Overlay checked name={t('Geofence')}>
                    <GeofenceLayer geofenceList={geofenceInfo} />
                </LayersControl.Overlay>
            </LayersControl>

            <FloorDetailPopup
                modal={popup.floorDetailPopup}
                toggleModal={() => handleModalToggle('floorDetailPopup')}
                widgetRef={widgetRef}
                mapRef={mapRef}
            />
            <FilteringPopup
                modal={popup.filteringPopup}
                toggleModal={() => handleModalToggle('filteringPopup')}
                widgetRef={widgetRef}
                mapRef={mapRef}
            />
        </>
    );
};

export default AllTarget;
