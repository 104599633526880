import React, { useState } from 'react';
import styled from 'styled-components';

const TableInput = ({ initValue = '', handleChange = () => {}, ...otherProps }) => {
    const [value, setValue] = useState(initValue ? initValue : '');

    const innerHandleChange = e => {
        const currentValue = e.target.value;
        setValue(currentValue);
    };

    const handleBlur = e => {
        handleChange(value);
    };

    return <StyledInput type="text" value={value} onChange={innerHandleChange} onBlur={handleBlur} {...otherProps} />;
};

const StyledInput = styled.input`
    width: 100%;
    height: 100%;
    text-align: center;
    border: none;
    background-color: transparent;

    #root .app-container &:focus {
        outline: 2px #c6c6ff solid;
        background-color: white;
        border: solid #c6c6ff;
        color: black;
    }
`;

export default TableInput;
