import React, { useContext, useMemo, useState } from 'react';
import { components, ControlProps, MenuListProps, OptionProps } from 'react-select';
import cx from 'classnames';

import { useTranslation } from '@hooks';
import { ICON_CLASS } from '@components/Select';
import { getChildrenTypeNode } from '@components/Select/utils';
import { OptionType } from '@components/type';
import { Control } from '@components/Select/Components/Common';

import { SingleTreeSelectContext } from './index';

export const SingleTreeControl = ({ children, selectProps, ...rest }: ControlProps<OptionType>) => {
    const { isMap } = selectProps;
    const { targetChildren: indicatorChild, otherChildren } = getChildrenTypeNode(children, 'IndicatorsContainer');

    return (
        <Control selectProps={selectProps} {...rest}>
            <button
                className={cx('select__btn', !isMap && 'bg-depth-4 border border-depth-7')}
                onClick={selectProps.onMenuOpen}
            >
                <div className="cont-wrap">{otherChildren}</div>
                {indicatorChild}
            </button>
        </Control>
    );
};

export const SingleTreeMenuList = ({ children, ...restProps }: MenuListProps<OptionType>) => {
    const { selectProps, getValue, options } = restProps;
    const t = useTranslation('TreeSelect');
    const selected = getValue();

    return (
        <components.MenuList
            className={cx(
                !selectProps.isMap && 'bg-depth-7',
                'container_tree_item',
                selectProps.isMap ? 'react_map_menu_tree' : 'react_menu_tree',
                restProps.className,
            )}
            {...restProps}
        >
            {!selected.length && !options.length ? (
                <div className={'styled-option-label color-black'}>{t('No matches found')}</div>
            ) : (
                <div>{children}</div>
            )}
        </components.MenuList>
    );
};

// 들여쓰기
const INDENT_VALUE = 6;

interface SingleTreeOptionProps extends OptionProps<OptionType> {
    depth?: number;
}
export const SingleTreeOption = function ({
    label,
    isSelected,
    getValue,
    setValue,
    data,
    depth = 0,
    ...restProps
}: SingleTreeOptionProps) {
    const [showChildItem, setShowChildItem] = useState(false);
    const { selectProps } = restProps;
    const selected = getValue();
    const { valueKey } = useContext(SingleTreeSelectContext);

    const handleOption = () => {
        if (!!data?.children) {
            setShowChildItem(prev => !prev);
            return;
        }
        setValue(data, 'select-option');
        selectProps.onMenuClose();
    };

    const treeDepth = useMemo(() => depth + 1, [depth]);
    return (
        <>
            <components.Option
                className={cx(selectProps.isMap ? `map-depth-${treeDepth * 2}` : `bg-depth-${treeDepth * 2}`)}
                {...restProps}
                label={label}
                isSelected={isSelected}
                getValue={getValue}
                setValue={setValue}
                data={data}
            >
                <div className={'d-flex flex-column'}>
                    <div className={'d-flex justify-content-between align-center'} onClick={handleOption}>
                        <span
                            className={cx('overflow-hidden text-ellipsis', selectProps.isMap && 'color-black')}
                            style={{ paddingLeft: `${INDENT_VALUE * (treeDepth - 1)}px` }}
                        >
                            {label}
                        </span>
                        {!!data?.children && (
                            <span className={cx(`${ICON_CLASS} p-0`, selectProps.isMap && 'color-black')}>
                                expand_more
                            </span>
                        )}
                    </div>
                </div>
            </components.Option>
            {showChildItem &&
                data.children.map((child: OptionType) => {
                    return (
                        <SingleTreeOption
                            {...restProps}
                            data={child}
                            label={child.label}
                            isSelected={selected.length > 0 && child[valueKey] === selected[0][valueKey]}
                            getValue={getValue}
                            setValue={setValue}
                            depth={treeDepth}
                            children={null}
                        />
                    );
                })}
        </>
    );
};
