import { useAsync } from '@util/hooks';
import { useCallback, useState } from 'react';
import { Filter } from '..';
import { getOutstandingAmountList } from '@api/so/outpatient';

export interface Row {
    checked: boolean;
    unpaidAmountNum: number;
    patientId: string;
    patientName: string;
    amount: number;
    historyCount: number;
    lastSendingDate: number;
    phoneNum: string;
}

interface Response {
    page: number;
    totalCount: number;
    totalPage: number;
    rows: Omit<Row, 'checked'>[];
}

interface Param {
    keyword: string;
    sortOption: string;
    page: number;
    pageSize: number;
}

export interface Data extends Omit<Response, 'rows'> {
    rows: Row[];
    pageSize: number;
}

export const useAmountList = (filter: Filter, defaultOptionValue: string) => {
    const [data, setData] = useState<Data>();

    const { promise: getList } = useAsync<Param, Response>({
        promise: getOutstandingAmountList,
        immediate: true,
        param: {
            keyword: filter.keyword,
            sortOption: filter.sort ? filter.sort.value : defaultOptionValue,
            page: filter.page,
            pageSize: 20,
        },
        deps: [filter],
        resolve: (response, request) => {
            const { pageSize } = request;
            const { rows } = response;
            const newRows = rows.map(ele => ({
                ...ele,
                checked: true,
            }));
            setData({ ...response, pageSize, rows: newRows });
        },
        reject: err => console.error(err),
    });

    // 전체 선택
    const handleSelectAllChange = useCallback(
        (checked: boolean) => {
            if (!data) {
                return;
            }

            const newRows = data.rows.map(ele => ({
                ...ele,
                checked,
            }));
            setData({ ...data, rows: newRows });
        },
        [data],
    );

    // 행 1개 선택
    const handleRowCheckedChange = useCallback(
        (id: string) => {
            if (!data) {
                return;
            }

            const newRows = data.rows.map(ele => {
                if (ele.patientId === id) {
                    return {
                        ...ele,
                        checked: !ele.checked,
                    };
                }
                return ele;
            });
            setData({ ...data, rows: newRows });
        },
        [data],
    );

    return { data, setData, getList, handleSelectAllChange, handleRowCheckedChange };
};
