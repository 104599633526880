import React from 'react';
import moment from 'moment';
import { useVitalSignsMonitoringDispatch } from '../../index';
import { setIsDetail, setTargetNum } from '../../vitalSignsMonitoringSlice';
import cx from 'classnames';

export const DEFAULT_DATE = '--/-- --:--';

// 활력 징후 경고값
const VITAL_SIGN_RANGE = {
    // 최고 혈압(SBP)
    H_BLOOD_PRESSURE: {
        MIN: 90,
        MAX: 149,
    },
    // 최저 혈압(DBP)
    L_BLOOD_PRESSURE: {
        MAX: 99,
    },
    // 맥박(PR)
    PULSE_RATE: {
        MIN: 55,
        MAX: 110,
    },
    // 체온(BT)
    TEMPERATURE: {
        MIN: 35,
        MAX: 37.5,
    },
    // 호흡 수(RR)
    RESPIRATORY_RATE: {
        MIN: 9,
        MAX: 30,
    },
};

/**
 * 환자 활력징후 정보 card body
 *
 * @param personData 환자 센서 정보
 *
 * @since 2022-12-13
 * @author kunwoong-kim
 */
const VitalSignsCardBody = ({ personData }) => {
    const dispatch = useVitalSignsMonitoringDispatch();
    const {
        targetId,
        targetName,
        H_BLOODPRESSURE: highBloodPressure,
        L_BLOODPRESSURE: lowBloodPressure,
        TEMPERATURE: temperature,
        PULSE_RATE: pulseRate,
    } = personData;

    const handleViewDetail = targetId => {
        dispatch(setTargetNum(targetId));
        dispatch(setIsDetail(true));
    };

    const convertDate = unixTime => (unixTime ? moment.unix(unixTime).format('YYYY-MM-DD HH:mm') : DEFAULT_DATE);
    const checkSBPWarning =
        highBloodPressure?.value <= VITAL_SIGN_RANGE.H_BLOOD_PRESSURE.MIN ||
        highBloodPressure?.value >= VITAL_SIGN_RANGE.H_BLOOD_PRESSURE.MAX;
    const checkDBPWarning = lowBloodPressure?.value >= VITAL_SIGN_RANGE.L_BLOOD_PRESSURE.MAX;
    const checkPRWarning =
        pulseRate?.value <= VITAL_SIGN_RANGE.PULSE_RATE.MIN || pulseRate?.value >= VITAL_SIGN_RANGE.PULSE_RATE.MAX;
    const checkBPWarning =
        temperature?.value <= VITAL_SIGN_RANGE.TEMPERATURE.MIN ||
        temperature?.value >= VITAL_SIGN_RANGE.TEMPERATURE.MAX;

    return (
        <div className="w-100 d-flex cursor-pointer" onClick={() => handleViewDetail(targetId)}>
            <div
                className={'flx-col justify-content-center align-items-center w-25'}
                style={{ wordBreak: 'break-word' }}
            >
                <span className={'pnt-txt txt-bold s-7'}>{targetId}</span>
                <span className={'pnt-txt'}>{targetName}</span>
            </div>
            <div className="w-75">
                {/* 혈압 */}
                <div
                    className={cx(
                        'flx-row border-bottom border-start border-depth-7',
                        (checkSBPWarning || checkDBPWarning) && 'bg-warning',
                    )}
                >
                    <div className="pnt-txt px-2 py-1">BP</div>
                    <div className="d-flex flx-1 justify-content-between align-items-center px-2 py-1 border-start border-depth-7">
                        <div>
                            <span className={cx('pnt-txt txt-bold s-7', checkSBPWarning && 'color-danger')}>
                                {highBloodPressure?.value ? Math.floor(highBloodPressure?.value) : '-'}
                            </span>
                            <span className="pnt-txt s-7"> / </span>
                            <span className={cx('pnt-txt txt-bold s-7', checkDBPWarning && 'color-danger')}>
                                {lowBloodPressure?.value ? Math.floor(lowBloodPressure?.value) : '-'}
                            </span>
                        </div>
                        <span className={'pnt-txt opacity-5'}>
                            {convertDate(highBloodPressure?.regDate || lowBloodPressure?.regDate)}
                        </span>
                    </div>
                </div>
                {/* 맥박 */}
                <div
                    className={cx(
                        'flx-row border-bottom border-start border-depth-7',
                        checkPRWarning && 'color-danger',
                    )}
                >
                    <div className="pnt-txt px-2 py-1">PR</div>
                    <div className="d-flex flx-1 justify-content-between align-items-center px-2 py-1 border-start border-depth-7">
                        <span className={cx('pnt-txt txt-bold s-7', checkPRWarning && 'color-danger')}>
                            {pulseRate?.value ? Math.round(pulseRate?.value) : 'N/A'}
                        </span>
                        <span className={'pnt-txt opacity-5'}>{convertDate(pulseRate?.regDate)}</span>
                    </div>
                </div>
                {/* 체온 */}
                <div className={cx('flx-row border-start border-depth-7', checkBPWarning && 'bg-warning')}>
                    <div className="pnt-txt px-2 py-1">BT</div>
                    <div className="d-flex flx-1 justify-content-between align-items-center px-2 py-1 border-start border-depth-7">
                        <span className={cx('pnt-txt txt-bold s-7 mb-0', checkBPWarning && 'color-danger')}>
                            {temperature?.value ? temperature?.value.toFixed(2) : 'N/A'}
                        </span>
                        <span className={'pnt-txt opacity-5 mb-0'}>{convertDate(temperature?.regDate)}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VitalSignsCardBody;
