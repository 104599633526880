import { useTranslation } from '@util/hooks';
import { useState } from 'react';

const useAssetStatusOptions = () => {
    const t = useTranslation('AssetManagement');

    const options = [
        { value: undefined, label: t('All') },
        { value: 'apply', label: t('Rental Request') }, // 대여신청
        { value: 'checkOut', label: t('Lending') }, // 대여중
        { value: 'approve', label: t('Wait hand over') }, // 인계대기
    ];

    const [value, setValue] = useState(options[0]);

    const handleChange = selected => {
        setValue(selected);
    };

    const handleOptionReset = () => {
        setValue(options[0]);
    };

    return {
        statusOptions: options,
        status: value,
        handleStatusChange: handleChange,
        handleOptionReset,
    };
};

export default useAssetStatusOptions;
